import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const tableMapData = state => state.models[name].data.mapData;
export const tableByFields = state => state.models[name].data.byFields;
export const tableTotalByFields = state => state.models[name].data.totalByFields;
export const tableByCulture = state => state.models[name].data.byCulture;
export const tableTotalByCulture = state => state.models[name].data.totalByCulture;
export const tableByAgroPeriod = state => state.models[name].data.byAgroPeriod;
export const tableByAgroFields = state => state.models[name].data.byAgroFields;
export const isPivotLoading = state => state.models[name].loading;
