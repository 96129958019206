import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../logo';
import VMenu from '../verticalmenu';
import VMenuMordovia from '../verticalmenu-mordovia';

import cn, { useClassName } from '../../utils/cn';
import './style.less';

const SideBar = ({ isMordovia = false, username = '' }) => {
    const { t, i18n } = useTranslation('side-bar');
    const cn = useClassName('side-bar');

    return (
        <div className={cn()}>
            <Logo />
            {isMordovia ? <VMenuMordovia username={username} providedI={i18n} /> : <VMenu providedI={i18n} />}
        </div>
    );
}

export default SideBar;