import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import './style.less';
import { getCurrentCalculatorMordovia } from '../../../../models/mordovia/selectors';
import { getLeafFieldPoints } from '../../../../models/leaf-diagnostics/actions';
import moment from 'moment';

const elementsArray = [
    'k2', 'n', 'p', 'ks', 'kcl', 'k3', 'ca', 'mg', 'b',
    'cu', 'k4', 'zn', 'mn', 'fe', 'mo', 'k5', 'co', 'i', 'k6'
];

const LeafResults = ({

}) => {
    const { t } = useTranslation('leaf diagnostics');
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const currentCalculator = useSelector(state => getCurrentCalculatorMordovia(state));

    const [selectedFedPoints, setSelectedFedPoints] = useState([]);

    useEffect(() => {
        dispatch(getLeafFieldPoints({
            vega_key: match.params.id
        }));
    }, []);
    

    useEffect(() => {
        const fieldYears = currentCalculator?.year_group?.years;
        const selectedYear = fieldYears?.filter(x => x?.year?.toString() === match?.params?.year?.toString())[0];
        if (selectedYear?.fed_points) {
            setSelectedFedPoints(selectedYear?.fed_points);
        }
    }, [currentCalculator]);

    const columns = [
        {
            title: t('point'),
            key: 'point_number',
            dataIndex: 'point_number'
        },
        {
            title: t('productivity zone'),
            key: 'productivity_zone',
            dataIndex: 'productivity_zone',
            render: val => val ? t(`zone select ${val}`) : ''
        },
        {
            title: t('date'),
            key: 'date',
            dataIndex: 'date',
            render: val => val ? moment(val)?.format('DD.MM.YYYY') : '-'
        },
        ...elementsArray?.map(elmnt => {
            return {
                title: t(`table element ${elmnt}`),
                key: elmnt,
                children: [
                    {
                        title: t('before lighting'),
                        key: `c_${elmnt}`,
                        dataIndex: `c_${elmnt}`
                    },
                    {
                        title: t('after lighting'),
                        key: `d_${elmnt}`,
                        dataIndex: `d_${elmnt}`
                    }
                ]
            }
        })
    ];

    return (
        <div>
            <Table dataSource={selectedFedPoints} bordered columns={columns} scroll={{ x: 1500 }} />
        </div>
    );
};

export default LeafResults;