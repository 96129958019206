import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private exact
        key="fertilizers-route"
        path="/fertilizers"
        component={Page} />
];

export default Routes;