import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getCalculate = id => request.get({
    url: urlPath(id),
    id
});

export const removeCalculate = id => request.remove({
    url: urlPath(id)
});

export const getCalculates = (filter) => request.get({
    url: urlPath(filter)
});

export const addCalculate = body => request.post({
    url,
    body
});

export const updateCalculate = body => request.put({
    url: urlPath(`${body.id}/`),
    body: body.values
});
