import moment from 'moment';

export const makeRequest = (inputVals) => {
    const collectionDate = inputVals?.seed_collection_date?.format('YYYY-MM-DD');
    const returnValues = {
        name: inputVals?.name,
        year: inputVals?.year,
        weight: inputVals?.weight,
        address: inputVals?.address,
        pickling: inputVals?.pickling,
        seed_collection_date: collectionDate,
        sample_weight: inputVals?.sample_weight,
        germination: inputVals?.germination,
        purity: inputVals?.purity,
        humidity: inputVals?.humidity,
        hundred_seeds_weight: inputVals?.hundred_seeds_weight,
        culture: inputVals?.culture,
        sort: inputVals?.sort,
        reproduction: inputVals?.reproduction,
        region: inputVals?.region,
        others: inputVals?.others,
        protocol_number: inputVals?.protocol_number || 1,
        sample_date: inputVals?.sample_date || collectionDate,
        analysis_date: inputVals?.analysis_date || collectionDate,
        sowing_suitability: inputVals?.sowing_suitability,
        germination_desc: inputVals?.germination_desc,
        purity_desc: inputVals?.purity_desc,
        humidity_desc: inputVals?.humidity_desc,
        germinating_seeds_plan: inputVals?.germinating_seeds_plan,
        calc_seeding_rate: inputVals?.calc_seeding_rate,
        field_germination: inputVals?.field_germination,
        seeding_rate_germination: inputVals?.seeding_rate_germination,
        seed_purpose: inputVals?.seed_purpose
    };

    const isPickling = inputVals?.pickling;
    if (isPickling) {
        returnValues.plant_protection = inputVals?.plant_protection;
    }

    return returnValues;
};

export const makeResponse = (inputVals) => {
    const percentagesObj = {};

    inputVals?.lab_germination?.percentages?.forEach((perc, idx) => {
        percentagesObj[`lab_germination/percentage/${idx + 1}`] = perc;
    });

    inputVals?.germination_power?.percentages?.forEach((perc, idx) => {
        percentagesObj[`germination_power/percentage/${idx + 1}`] = perc;
    });

    const returnValues = {
        seeds_number: inputVals?.seeds_number,
        total_pathogens_percentage: inputVals?.total_pathogens_percentage,
        name: inputVals?.name,
        year: inputVals?.year,
        weight: inputVals?.weight,
        address: inputVals?.address,
        pickling: inputVals?.pickling,
        seed_collection_date: inputVals?.seed_collection_date ? moment(inputVals?.seed_collection_date) : null,
        sample_weight: inputVals?.sample_weight,
        germination: inputVals?.germination,
        purity: inputVals?.purity,
        internal_pathogens: inputVals?.internal_pathogens,
        external_pathogens: inputVals?.external_pathogens,
        humidity: inputVals?.humidity,
        plant_protection: inputVals?.plant_protection,
        hundred_seeds_weight: inputVals?.hundred_seeds_weight,
        culture: inputVals?.culture?.id,
        sort: inputVals?.sort?.id,
        reproduction: inputVals?.reproduction?.id,
        region: inputVals?.region?.id,
        protocol_number: inputVals?.protocol_number || 1,
        sample_date: inputVals?.sample_date ? moment(inputVals?.sample_date) : null,
        analysis_date: inputVals?.analysis_date ? moment(inputVals?.analysis_date) : null,
        sowing_suitability: inputVals?.sowing_suitability,
        germination_desc: inputVals?.germination_desc,
        purity_desc: inputVals?.purity_desc,
        humidity_desc: inputVals?.humidity_desc,
        germinating_seeds_plan: inputVals?.germinating_seeds_plan,
        calc_seeding_rate: inputVals?.calc_seeding_rate,
        field_germination: inputVals?.field_germination,
        others: inputVals?.others,
        seeding_rate_germination: inputVals?.seeding_rate_germination,
        seed_purpose: inputVals?.seed_purpose,
    };

    returnValues.internal_pathogen_ids = returnValues?.internal_pathogens?.map(x => {
        returnValues[`internal/percentage/${x.id}`] = x?.pathogens_percentage;
        returnValues[`internal/pcs/${x.id}`] = x?.pathogens_number;
        return x.id;
    });

    returnValues.external_pathogen_ids = returnValues?.external_pathogens?.map(x => {
        returnValues[`external/percentage/${x.id}`] = x?.pathogens_percentage;
        return x.id
    });

    if (returnValues?.germination_power) {
        returnValues.germination_power.calculation_date = returnValues?.germination_power?.calculation_date ? moment(returnValues.germination_power.calculation_date) : moment(null);
    }

    return returnValues;
};