import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useClassName } from 'utils/cn';

import '../style.less';
import {
    Button, Col, Collapse, InputNumber, Row, Form
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toFixed } from '../../../utils/utils';
import QualityCard from './quality-card';
import {
    currentCulturesPhyto,
    currentVarietiesPhyto
} from '../../../models/calculators-phyto/selectors';

const { Panel } = Collapse;

const SeedQualityIndicator = ({ form, culturesList, varietiesList }) => {
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');

    const [cultureName, setCultureName] = useState('-');
    const [sortName, setSortName] = useState('-');

    useEffect(() => {
        const formSort = form.getFieldValue('sort');
        const formCulture = form.getFieldValue('culture');

        if (formSort && formSort > 0 && varietiesList?.length > 0) {
            const selectedSort = varietiesList?.filter(srt => srt?.id === parseInt(formSort, 10))[0];
            setSortName(selectedSort?.sort_name);
        }

        if (formCulture && formCulture > 0 && culturesList?.length > 0) {
            const selectedCulture = culturesList?.filter(clt => clt.id === parseInt(formCulture, 10))[0];
            setCultureName(selectedCulture?.name);
        }
    }, [form, culturesList, varietiesList]);

    const removeFormFields = () => {
        form.setFieldsValue({
            germinating_seeds_plan: undefined,
            purity: undefined,
            germination: undefined,
            sowing_suitability: undefined,
            hundred_seeds_weight: undefined,
            calc_seeding_rate: undefined,
            field_germination: undefined,
            seeding_rate_germination: undefined
        });
    };

    return (
        <>
            <Row className="mt-25">
                <Col span={7}>
                    <QualityCard
                        cardHeader={t('seed quality card header 1')}
                        cardText={form?.getFieldValue('germination_desc')}
                        cardColor="blue" />
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <QualityCard
                        cardHeader={t('seed quality card header 2')}
                        cardText={form?.getFieldValue('purity_desc')}
                        cardColor="red" />
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <QualityCard
                        cardHeader={t('seed quality card header 3')}
                        cardText={form?.getFieldValue('humidity_desc')}
                        cardColor="blue" />
                </Col>
            </Row>

            <Collapse
                style={{ backgroundColor: 'white' }}
                bordered={false}
                defaultActiveKey={['quality']}
                className={cn('collapse')}>
                <Panel
                    showArrow={false}
                    disabled
                    header={<div className="mt-25"><b>{t('calculator quality collapse header')}</b></div>}
                    key="quality">
                    <Row className="seed-quality-table">
                        <Col span={11}>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header agriculture')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {cultureName}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header variety')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {sortName}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header purity')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('purity') ? toFixed(form?.getFieldValue('purity'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header germination')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('germination') ? toFixed(form?.getFieldValue('germination'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header goodness')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('sowing_suitability') ? toFixed(form?.getFieldValue('sowing_suitability'), 1) : '-'}
                                </Col>
                            </Row>
                        </Col>

                        <Col span={2} />

                        <Col span={11}>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header mass')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('hundred_seeds_weight') ? toFixed(form?.getFieldValue('hundred_seeds_weight'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header planning')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="germinating_seeds_plan">
                                        <InputNumber
                                            // value={form?.getFieldValue('germinating_seeds_plan') ? toFixed(form?.getFieldValue('germinating_seeds_plan'), 1) : '-'}
                                            onChange={(newValue) => form?.setFieldsValue({ germinating_seeds_plan: newValue })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header seeding norm')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('calc_seeding_rate') ? toFixed(form?.getFieldValue('calc_seeding_rate'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header delta')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('field_germination') ? toFixed(form?.getFieldValue('field_germination'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header delta norm')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('seeding_rate_germination') ? toFixed(form?.getFieldValue('seeding_rate_germination'), 1) : '-'}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={4} />
                        <Col span={16}>
                            <div className="quality-notification">
                                <ExclamationCircleOutlined style={{ marginRight: '16px' }} />
                                {t('quality notification')}
                            </div>
                        </Col>
                        <Col span={4} />
                    </Row>

                    <Row>
                        <Button type="secondary" className="mt-25" onClick={removeFormFields}>
                            {t('clear')}
                        </Button>
                    </Row>

                    <Row>
                        <Col span={10} />
                        <Col span={4}>
                            <Button type="primary" className="mt-25">
                                {t('finish calculation')}
                            </Button>
                        </Col>
                        <Col span={10} />
                    </Row>
                </Panel>
            </Collapse>
        </>
    );
};

export default SeedQualityIndicator;
