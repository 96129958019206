import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.updateInternalCalculator.success: {
            return action.payload;
        }

        case constants.updateInternalCalculator.error: {
            return { error: action.payload };
        }

        case constants.updateInternalCalculator.try: {
            return {};
        }

        default:
            return state;
    }
}