import * as constants from '../constants';

const initialState = {
    results: [],
    total: 0,
    isLoading: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getMycologicalRegistries.success: {
            return { results: action.payload.results, total: action.payload.count, isLoading: false };
        }
        case constants.getMycologicalRegistries.try: {
            return { results: [], total: 0, isLoading: true };
        }
        case constants.getMycologicalRegistries.error: {
            return initialState;
        }

        default:
            return state;
    }
}
