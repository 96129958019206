import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Icon from '@ant-design/icons';
import {
    Rate, Row, Col, Collapse
} from 'antd';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import moment from 'moment';
import { rus2Latin } from '../../../utils/utils';
import cn from '../../../utils/cn';
import Button from '../../../components/button';
import '../style.less';
import * as constants from '../../../constants';
import { selectors, actions } from '../../../models';
import { Title } from '../../../components';

const { Panel } = Collapse;

const {
    getHarmfulObject
} = actions;
const {
    currentHarmfulObject,
    getCurrentPesticide,
    curPesticideProductApplication
} = selectors;

const mstp = (state) => ({
    harmfulObject: currentHarmfulObject(state),
    pesticide: getCurrentPesticide(state),
    application: curPesticideProductApplication(state)
});
const mdtp = ({
    getHarmfulObject
});


@withRouter
@connect(mstp, mdtp)
@withTranslation('harmful objects')
@cn('disease-detail')
export default class ObjectDetail extends Component {
    componentDidMount() {
        const { getHarmfulObject, match } = this.props;
        const { id } = match.params;
        getHarmfulObject(id);
    }

    jsPdfGenerator = () => {
        const { t, harmfulObject } = this.props;
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const text = doc.splitTextToSize(harmfulObject?.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        // const mainDrug = pesticide.related_main_drugs ? pesticide.related_main_drugs.map(item => (
        //     item && item.main_drug.name
        // )) : [];
        // const price = pesticide.related_sellers ? pesticide.related_sellers.map(item => (
        //     item && item.price
        // )) : [];
        // const chemicalClass = pesticide.related_chemical_classes ? pesticide.related_chemical_classes.map(item => (
        //     item && item.chemical_class.name
        // )) : [];
        // const registrant = pesticide.related_registrations ? pesticide.related_registrations.map(item => (
        //     item && item.registrant.name
        // )) : [];
        // const expDate = pesticide.related_registrations ? pesticide.related_registrations.map(item => (
        //     item && item.expiration_date
        // )) : [];
        // const registrationNumber = pesticide?.related_registrations?.map(item => (item?.registration_number)) || [];
        // const applicationArea = pesticide.related_application_areas ? pesticide.related_application_areas.map(item => (
        //     item && item.application_area.name
        // )) : [];

        // const body = [
        //     [t('group pesticides'), pesticide.group.name],
        //     [t('group culture'), cultivatedCultures.join(', ')],
        //     [t('harmful object'), harmfulObjects.join(', ')],
        //     [t('main drug'), mainDrug.join(', ')],
        //     [t('main drug content'), pesticide.main_drug_content],
        //     [t('type of processing'), application.treatment_type && application.treatment_type.name],
        //     [t('price'), price.join(', ')],
        //     [t('units'), application.unit_of_measurement],
        //     [t('preparative form'), pesticide.preparative_form],
        //     [t('chemical class'), chemicalClass.join(', ')],
        //     [t('application type'), application.application_features],
        //     [t('danger class'), pesticide.hazard_class.name],
        //     [t('registrant'), registrant.join(', ')],
        //     [t('registration number'), registrationNumber.join(', ')],
        //     [t('norm'), normOfUse],
        //     [t('application area'), applicationArea.join(', ')],
        //     [t('fluid flow rate'), fluidFlowRate],
        //     [t('waiting time'), pesticide.waiting_period + '(' + pesticide.multiplicity_of_treatments + ')'],
        //     [t('release dates'), pesticide.exit_dates_for_manual_works],
        //     [t('end date'), expDate.join(', ')]
        // ];
        // doc.autoTable({
        //     startY: finalY + 40,
        //     columnStyles: {
        //         0: { font: 'Arial', cellPadding: 10 },
        //         1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
        //     },
        //     body
        // });
        // const fileName = rus2Latin(pesticide.name);
        // doc.save(`${fileName}.pdf`);
    };

    goBack = () => {
        const { history } = this.props;
        history.push('/harmful-objects');
    };

    render() {
        const { cn } = this;
        const {
            t, pesticide,
            application,
            onTabChange,
            harmfulObject
        } = this.props;

        if (!harmfulObject) return <div />;

        return (

            <div className={cn()}>
                <div className={cn('content')}>
                    <span className={cn('back')} onClick={this.goBack}>
                        {t('back')}
                    </span>

                    <Row className="item-cards">
                        <div className="item-card">
                            <span>{t('object card name')}</span>
                            <div>
                                <span>
                                    <b>{harmfulObject?.name}</b>
                                </span>
                            </div>
                        </div>
                        <div className="item-card">
                            <span>{t('object card division')}</span>
                            <div>
                                <span>
                                    <b>{harmfulObject?.order?.name}</b>
                                </span>
                            </div>
                        </div>
                        <div className="item-card item-card__big">
                            <span>{t('object card latin')}</span>
                            <div>
                                <span>
                                    <b>{harmfulObject?.latin_name}</b>
                                </span>
                            </div>
                        </div>
                    </Row>

                    <Row className="row-in-development">
                        <div className="text-in-development">
                            {t('in development')}
                        </div>
                    </Row>

                    {/* <Row className={cn('information')}>
                        <Col span={10} className={cn('symptoms')}>
                            <h3>{t('object info area')}</h3>
                            <Collapse>
                                    <Panel key={0} header={t('area tab leaves')}>
                                        ...
                                    </Panel>
                                    <Panel key={1} header={t('area tab stems')}>
                                        ...
                                    </Panel>
                            </Collapse>
                        </Col>
                        <Col span={14} className={cn('catalog')}>
                            <h3>{t('object info information')}</h3>
                            <Collapse>
                                    <Panel key={0} header={t('information tab morphology')}>
                                        ...
                                    </Panel>
                                    <Panel key={1} header={t('information tab biology')}>
                                        ...
                                    </Panel>
                                    <Panel key={2} header={t('information tab ecology')}>
                                        ...
                                    </Panel>
                                    <Panel key={3} header={t('information tab harmfulness')}>
                                        ...
                                    </Panel>
                                    <Panel key={4} header={t('information tab methods')}>
                                        ...
                                    </Panel>
                                    <Panel key={5} header={t('information tab epv')}>
                                        ...
                                    </Panel>
                            </Collapse>
                        </Col>
                    </Row> */}
                </div>

                <div className={cn('footer')}>
                    {/* <Button
                        onClick={this.jsPdfGenerator}
                        type="link">
                        <Icon type="download" />
                        {t('generate')}
                    </Button> */}
                    { /* <Button
                        onClick={() => onTabChange('3')}
                        disabled
                        type="default">
                        {t('send review')}
                    </Button> */ }
                </div>
            </div>
        );
    }
}
