import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row, Collapse } from 'antd';
import { withTranslation } from 'react-i18next';

import cn from '../../../utils/cn';
import * as constants from '../../../constants';

import {
    Button, CustomFilter as DTFilter, InfoMessage, RoundButton
} from '../../../components';

import { actions, selectors } from '../../../models';
import Images from '../../../components/images';

const { Panel } = Collapse;

const { getDisease } = actions;
const { getCurrentDeterminant, getCurrentQuestions, getCurrentDisease } = selectors;

const mstp = (state) => ({
    determinant: getCurrentDeterminant(state),
    questions: getCurrentQuestions(state),
    disease: getCurrentDisease(state)
});
const mdtp = ({ getDisease });


@withRouter
@connect(mstp, mdtp)
@withTranslation('determinant data complete')
@cn('determinant-complete-data')
export default class DeterminantDataComplete extends Component {
    state = {
        visible: false
    };

    componentDidMount() {
        const { determinant, getDisease } = this.props;
        const { disease } = determinant;
        getDisease(disease.id);
    }

    closeDeterminant = () => {
        const { history } = this.props;
        history.push('/determinants/');
    };

    hideModal = () => {
        this.setState({ visible: false });
    };

    showModal = () => {
        this.setState({ visible: true });
    };

    render() {
        const { cn } = this;
        const {
            t, determinant, diseases, questions, disease
        } = this.props;

        const { visible } = this.state;

        const images = (disease && disease.related_images) ? disease.related_images.map(item => item.image) : [];

        return (
            <Row className={cn('complete-data')}>
                <div>
                    <div className={cn('header')}>
                        <div className={cn('culture-name')}>
                            <span className={cn('header-title')}>
                                {t('determinant name')}
                            </span>
                            <div className={cn('header-value')}>
                                { determinant.name }
                            </div>
                        </div>
                    </div>
                    <Row className={cn('close')}>
                        <span className="label">
                            {t('questions answered')}: <b>{questions.length}</b>
                        </span>
                        <Button
                            onClick={this.showModal}
                            type="default">
                            {t('close determinant')}
                        </Button>
                    </Row>
                    <div className={cn('disease-list')}>
                        { disease.name && (
                            <div className="disease-block">
                                <span>{t('disease name')}:</span>
                                <span>
                                    <b>{ disease.name }</b>
                                </span>
                            </div>
                        )}
                        {disease.type && (
                            <div className="disease-block">
                                <span>{t('disease type')}:</span>
                                <span><b>{disease.type}</b></span>
                            </div>
                        )}
                        {disease.causative_agent && (
                            <div className="disease-block">
                                <span>{t('disease cause')}:</span>
                                <span><b>{disease.causative_agent.name}</b></span>
                            </div>
                        )}
                    </div>
                    <Row className={cn('information')}>
                        <Col span={10} className={cn('symptoms')}>
                            <h3>{t('disease area and symptoms')}</h3>
                            <Collapse>
                                {disease.manifestations_shoots && (
                                    <Panel key={0} header={t('seedlings sings')}>
                                        <p>{disease.manifestations_shoots}</p>
                                    </Panel>
                                )}
                                {disease.manifestations_leaves && (
                                    <Panel key={1} header={t('leaves')}>
                                        <p>{disease.manifestations_leaves}</p>
                                    </Panel>
                                )}
                                {disease.manifestations_stems && (
                                    <Panel key={2} header={t('stalks')}>
                                        <p>{disease.manifestations_stems}</p>
                                    </Panel>
                                )}
                                {disease.manifestations_roots && (
                                    <Panel key={3} header={t('root crop signs')}>
                                        <p>{disease.manifestations_roots}</p>
                                    </Panel>
                                )}
                                {disease.manifestations_root_system && (
                                    <Panel key={4} header={t('root system')}>
                                        <p>{disease.manifestations_root_system}</p>
                                    </Panel>
                                )}
                                {disease.manifestations_reproductive_organs && (
                                    <Panel key={5} header={t('reproductive organs')}>
                                        <p>{disease.manifestations_reproductive_organs}</p>
                                    </Panel>
                                )}
                            </Collapse>
                        </Col>
                        <Col span={14} className={cn('catalog')}>
                            <h3>{t('catalog information about disease')}</h3>
                            <Collapse>
                                {disease.causes_of_occurrence && (
                                    <Panel key={0} header={t('causes of occurrence')}>
                                        <p>{disease.causes_of_occurrence}</p>
                                    </Panel>
                                )}

                                {disease.pathogen_morphology && (
                                    <Panel key={1} header={t('pathogen morphology')}>
                                        <p>{disease.pathogen_morphology}</p>
                                    </Panel>
                                )}
                                {disease.biology_spread_pathogen && (
                                    <Panel key={2} header={t('pathogen distribution biology')}>
                                        <p>{disease.biology_spread_pathogen}</p>
                                    </Panel>
                                )}
                                {disease.ecology_of_disease && (
                                    <Panel key={3} header={t('diseases ecology')}>
                                        <p>{disease.ecology_of_disease}</p>
                                    </Panel>
                                )}
                                {disease.disease_harmfulness && (
                                    <Panel key={4} header={t('disease harmfulness')}>
                                        <p>{disease.disease_harmfulness}</p>
                                    </Panel>
                                )}
                                {disease.fight_ways && (
                                    <Panel key={5} header={t('fight ways')}>
                                        <p>{disease.fight_ways}</p>
                                    </Panel>
                                )}
                                {disease.economic_threshold_of_harmfulness && (
                                    <Panel key={6} header={t('economic threshold of harmfulness')}>
                                        <p>{disease.economic_threshold_of_harmfulness}</p>
                                    </Panel>
                                )}
                            </Collapse>
                        </Col>
                    </Row>
                    {
                        images.length > 0 && (
                            <Row className={cn('photos')}>
                                <Images images={images} onRemove={this.onRemove} />
                            </Row>
                        )
                    }
                </div>
                {/* <Row className={cn('buttons')}>
                    <RoundButton type="danger">
                        {t('call phytopatologyst')}
                    </RoundButton>
                    <RoundButton>
                        {t('remedies calculator')}
                    </RoundButton>
                </Row> */}
                <InfoMessage
                    visible={visible}
                    title={t('close determinant results')}
                    text={t('you will return to the previous screen')}
                    okBtn={t('return')}
                    cancelBtn={t('cancel')}
                    onOk={this.closeDeterminant}
                    onCancel={this.hideModal} />
            </Row>
        );
    }
}