export default {
    name: 'pesticides',
    serverName: '/api/v1/plant_protection/plant_protection_product/',
    apiUrl: '/api/v1/plant_protection/plant_protection_product/',
    apiApplicationAreaUrl: '/api/v1/plant_protection/application_area/',
    apiChemicalClassUrl: '/api/v1/plant_protection/chemical_class/',
    apiHarmfulObjectUrl: '/api/v1/plant_protection/harmful_object/',
    apiHazardClassUrl: '/api/v1/plant_protection/hazard_class/',
    apiPlaceOfProductionUrl: '/api/v1/plant_protection/place_of_production/',
    apiProtectionGroupUrl: '/api/v1/plant_protection/plant_protection_group/',
    apiMainDrugUrl: '/api/v1/plant_protection/plant_protection_main_drug/',
    apiApplicationUrl: '/api/v1/plant_protection/plant_protection_product_application/',
    apiPreparativeFormUrl: '/api/v1/plant_protection/preparative_form/',
    apiTreatmentTypeUrl: '/api/v1/plant_protection/treatment_type/'
};
