import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private exact
        // key="mordovia-fields-route"
        // path="/mordovia-fields"
        key="representation-fields-route"
        path="/representation-fields"
        component={Page} />
];

export default Routes;