import { all, takeLatest } from 'redux-saga/effects';
import { defaultSaga } from '../utils';

import * as constants from './constants';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getUsers.toString(),
            defaultSaga({
                constants: constants.getUsers,
                api: api.getUsers
            })
        ),
        yield takeLatest(
            constants.resetPassword.toString(),
            defaultSaga({
                constants: constants.resetPassword,
                api: api.resetPassword
            })
        ),
        yield takeLatest(
            constants.savePassword.toString(),
            defaultSaga({
                constants: constants.savePassword,
                api: api.savePassword
            })
        )
    ]);
}