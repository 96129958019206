import * as constants from '../constants';

const initialState = {
    results: [],
    count: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
