export const dayNames = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
];
export const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

// CHART TOOLTIP
export const tooltipTextColor = '#000000A6';
export const tooltipBorderColor = '#EDEDED';
export const tooltipBackgroundColor = 'white';
export const tooltipBorderWidth = 1;

// CHART COLORS
export const analysisFactLineColor = '#1FCD75';
export const analysisForecastLineColor = '#F2C94C';
export const analysisPrecipitationLineColor = '#B9E6FE';
export const effectiveLineColor = '#F2C94C';
export const precipitationLineColor = '#B9E6FE';

// CHART PLUGINS
export const chartLegendSettings = {
    position: 'top',
    align: 'end',
    labels: {
        usePointStyle: true,
        boxWidth: 100
    }
};