import * as constants from './constants';

export const getPesticides = payload => ({
    type: constants.getPesticides.toString(),
    payload
});

export const getPesticide = payload => ({
    type: constants.getPesticide.toString(),
    payload
});

export const getPesticideApplicationArea = payload => ({
    type: constants.getPesticideApplicationArea.toString(),
    payload
});

export const getPesticideApplicationAreaItem = payload => ({
    type: constants.getPesticideApplicationAreaItem.toString(),
    payload
});

export const getPesticideChemicalClasses = payload => ({
    type: constants.getPesticideChemicalClasses.toString(),
    payload
});

export const getPesticideChemicalClassesItem = payload => ({
    type: constants.getPesticideChemicalClassesItem.toString(),
    payload
});

export const getPesticideHarmfulObject = payload => ({
    type: constants.getPesticideHarmfulObject.toString(),
    payload
});

export const getPesticideHarmfulObjectItem = payload => ({
    type: constants.getPesticideHarmfulObjectItem.toString(),
    payload
});

export const getPesticideHazardClass = payload => ({
    type: constants.getPesticideHazardClass.toString(),
    payload
});

export const getPesticideHazardClassItem = payload => ({
    type: constants.getPesticideHazardClassItem.toString(),
    payload
});

export const getPesticidePlaceOfProduction = payload => ({
    type: constants.getPesticidePlaceOfProduction.toString(),
    payload
});

export const getPesticidePlaceOfProductionItem = payload => ({
    type: constants.getPesticidePlaceOfProductionItem.toString(),
    payload
});

export const getPesticideGroup = payload => ({
    type: constants.getPesticideGroup.toString(),
    payload
});

export const getPesticideGroupItem = payload => ({
    type: constants.getPesticideGroupItem.toString(),
    payload
});

export const getPesticideMainDrug = payload => ({
    type: constants.getPesticideMainDrug.toString(),
    payload
});

export const getPesticideMainDrugItem = payload => ({
    type: constants.getPesticideMainDrugItem.toString(),
    payload
});

export const getPesticideProductApplication = payload => ({
    type: constants.getPesticideProductApplication.toString(),
    payload
});

export const getPesticideProductApplicationItem = payload => ({
    type: constants.getPesticideProductApplicationItem.toString(),
    payload
});

export const getPesticidePreparativeForm = payload => ({
    type: constants.getPesticidePreparativeForm.toString(),
    payload
});

export const getPesticidePreparativeFormItem = payload => ({
    type: constants.getPesticidePreparativeFormItem.toString(),
    payload
});

export const getPesticideTreatmentType = payload => ({
    type: constants.getPesticideTreatmentType.toString(),
    payload
});

export const getPesticideTreatmentTypeItem = payload => ({
    type: constants.getPesticideTreatmentTypeItem.toString(),
    payload
});