import * as constants from '../constants';

const initialState = {
    results: [],
    count: 0,
    removeId: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getDeterminants.success: {
            return action.payload;
        }

        case constants.removeDeterminant.try: {
            return {
                ...state,
                removeId: action.payload
            };
        }

        case constants.removeDeterminant.success: {
            const { removeId } = state;

            return {
                ...state,
                results: [...state.results.filter(item => item.id !== removeId)]
            };
        }

        default:
            return state;
    }
}
