import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getCalculatorsNormRegionCulture = filter => request.get({
    url: urlPath(options.apiNormUrl, filter)
});

export const getCalculatorNormRegionCultureItem = id => request.get({
    url: urlPath(options.apiNormUrl, `${id}/`)
});

export const getCalculatorsRBRegionCulture = filter => request.get({
    url: urlPath(options.apiRBUrl, filter)
});

export const getCalculatorRBRegionCultureItem = id => request.get({
    url: urlPath(options.apiRBUrl, `${id}/`)
});