import request from '../../utils/request';
import options from './options';

const urlAgroEvents = options.agroEventsApi;
const urlAccounting = options.eventsAccountingApi;
const urlField = options.eventsFieldApi;
const urlPath = (url, path) => `${url}${path}`;

export const getAgrotechnicalEventsField = ({ vega_key, year }) => request.get({
    url: urlPath(urlField, `${vega_key}/field/?year=${year}`)
});

export const getAgrotechnicalEventsAccounting = ({ userId }) => request.get({
    url: urlPath(options.eventsAccountingApi, `?filter={"user":${userId}}`)
});

export const updateAgrotechnicalEvents = body => request.post({
    url: urlAccounting,
    body
});

export const getVegetationPhases = ({ culture }) => request.get({
    url: urlPath(options.vegetationApi, `?filter={"culture":"${culture}"}`)
});

export const getAgrotechnicalEventsList = ({ page, pageSize }) => request.get({
    url: `${urlAgroEvents}/?page=${page}&page_size=${pageSize}`
});

export const patchAgrotechnicalEvent = ({ eventId, body }) => request.patch({
    url: urlPath(urlAccounting, `${eventId}/`),
    body
});

export const deleteAgrotechnicalEvent = ({ eventId }) => request.remove({
    url: urlPath(urlAccounting, `${eventId}/`)
});