import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getUsers.success: {
            return action.payload.reduce((previous, current) =>
                ({ ...previous, [current.id]: current }), {});
        }

        default:
            return state;
    }
}
