import ConverterClass from '../../utils/converter';
import { toFixed } from '../../utils/utils';

class Converter extends ConverterClass {
    toClient(data = {}) {
        return {
            id: data.id,
            user: data.user,
            created_at: data.created_at,
            updated_at: data.updated_at,
            region: data.region || {},
            culture: data.culture || {},
            preceding_culture: data.preceding_culture || {},
            mechanical_composition: data.mechanical_composition || {},
            ph_salt: data.ph_salt,
            no3: data.no3,
            k2o: data.k2o,
            p2o5: data.p2o5,
            analysis_method: data.analysis_method,
            planned_yield: data.planned_yield,
            recommended_n_main: toFixed(data.recommended_n_main, 1),
            recommended_p_main: toFixed(data.recommended_p_main, 1),
            recommended_k_main: toFixed(data.recommended_k_main, 1),
            recommended_n_sowing: toFixed(data.recommended_n_sowing, 1),
            recommended_p_sowing: toFixed(data.recommended_p_sowing, 1),
            recommended_k_sowing: toFixed(data.recommended_k_sowing, 1),
            recommended_n_feeding: toFixed(data.recommended_n_feeding, 1),
            recommended_p_feeding: toFixed(data.recommended_p_feeding, 1),
            recommended_k_feeding: toFixed(data.recommended_k_feeding, 1),
            expert_comment: data.expert_comment,
            expert_k_feeding: toFixed(data.expert_k_feeding, 1),
            expert_k_main: toFixed(data.expert_k_main, 1),
            expert_k_sowing: toFixed(data.expert_k_sowing, 1),
            expert_n_feeding: toFixed(data.expert_n_feeding, 1),
            expert_n_main: toFixed(data.expert_n_main, 1),
            expert_n_sowing: toFixed(data.expert_n_sowing, 1),
            expert_p_feeding: toFixed(data.expert_p_feeding, 1),
            expert_p_main: toFixed(data.expert_p_main, 1),
            expert_p_sowing: toFixed(data.expert_p_sowing, 1),
            nutrition_n_main: toFixed(data.nutrition_n_main, 1),
            nutrition_p_main: toFixed(data.nutrition_p_main, 1),
            nutrition_k_main: toFixed(data.nutrition_k_main, 1),
            nutrition_n_sowing: toFixed(data.nutrition_n_sowing, 1),
            nutrition_p_sowing: toFixed(data.nutrition_p_sowing, 1),
            nutrition_k_sowing: toFixed(data.nutrition_k_sowing, 1),
            nutrition_n_feeding: toFixed(data.nutrition_n_feeding, 1),
            nutrition_p_feeding: toFixed(data.nutrition_p_feeding, 1),
            nutrition_k_feeding: toFixed(data.nutrition_k_feeding, 1),
            expert_data: data.expert_k_feeding
                || data.expert_k_main
                || data.expert_k_sowing
                || data.expert_n_feeding
                || data.expert_n_main
                || data.expert_n_sowing
                || data.expert_p_feeding
                || data.expert_p_main
                || data.expert_p_sowing || false
        };
    }
}

export default new Converter();