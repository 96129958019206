import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    Col, Form, Input, InputNumber, Row, Select
} from 'antd';
import { useClassName } from 'utils/cn';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { actions, selectors } from '../../models';

import './style.less';
import {
    Button, InfoMessage, SaveMessage
} from '../../components';

const {
    curCalculate,
    getRegionsCatalog,
    getRegionRBCultureCatalog,
    getSoilCompositionCatalog,
    getDepthCatalog,
    getDegreeOfSoilCatalog,
    getPrecedingCatalog,
    getYearCatalog,
    curRB,
    isAuth
} = selectors;

const {
    getCalculatorsRBRegionCulture,
    addRB,
    updateRB
} = actions;

export default (props) => {
    const { type, isCompleted: isCompletedState = false } = props;
    const calculator = useSelector(state => curCalculate(state));
    const isAuthState = useSelector(state => isAuth(state));
    const rb = useSelector(state => curRB(state));
    const regions = useSelector(state => getRegionsCatalog(state));
    const cultures = useSelector(state => getRegionRBCultureCatalog(state));
    const compositionSoil = useSelector(state => getSoilCompositionCatalog(state));
    const depth = useSelector(state => getDepthCatalog(state));
    const degreeSoil = useSelector(state => getDegreeOfSoilCatalog(state));
    const preceding = useSelector(state => getPrecedingCatalog(state));
    const years = useSelector(state => getYearCatalog(state));
    const dispatch = useDispatch();
    const { t } = useTranslation('calculators');
    const cn = useClassName('calculator');
    const match = useRouteMatch();
    const { params: { id } } = match;
    const history = useHistory();
    const [form] = Form.useForm();
    const [isCompleted, setCompleted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isFull, setIsFull] = useState(true);
    const [isNotNeed, setIsNotNeed] = useState(false);
    const [valuesChanged, setValuesChanged] = useState(false);

    useEffect(() => {
        setCompleted(isCompletedState);
    }, [isCompletedState]);

    useEffect(() => {
        const weight = +rb.recommended_n_main
            + rb.recommended_n_sowing
            + rb.recommended_n_feeding
            + rb.recommended_p_main
            + rb.recommended_p_sowing
            + rb.recommended_p_feeding
            + rb.recommended_k_main
            + rb.recommended_k_sowing
            + rb.recommended_k_feeding;
        setIsNotNeed(!weight);
    }, [rb]);

    useEffect(() => {
        rb.region?.id
        && dispatch(getCalculatorsRBRegionCulture(`?filter={"region.id":${rb.region?.id}}`));
        isAuthState
            ? form.setFieldsValue({
                planned_yield: rb.planned_yield,
                report_n: rb.report_n,
                report_p: rb.report_p,
                report_k: rb.report_k,
                region: rb.region?.id,
                culture: rb.culture?.id,
                degree_of_soil_moisture: rb.degree_of_soil_moisture?.id,
                analysis_method: rb.analysis_method,
                soil_sampling_depth: rb.soil_sampling_depth,
                fertilizer_action_year: rb.fertilizer_action_year,
                depth_of_arable_layer: rb.depth_of_arable_layer,
                mechanical_composition: rb.mechanical_composition?.id

            })
            : form.setFieldsValue({
                planned_yield: rb.planned_yield,
                report_n: rb.report_n,
                report_p: rb.report_p,
                report_k: rb.report_k,
                region: rb.region?.name,
                culture: rb.culture?.name,
                degree_of_soil_moisture: rb.degree_of_soil_moisture?.name,
                analysis_method: rb.analysis_method,
                soil_sampling_depth: rb.soil_sampling_depth,
                fertilizer_action_year: rb.fertilizer_action_year,
                depth_of_arable_layer: rb.depth_of_arable_layer,
                mechanical_composition: rb.mechanical_composition?.name

            });
    }, [calculator]);

    const onFinish = (values) => {
        rb.id
            ? dispatch(updateRB({ id: rb.id, values }))
            : dispatch(addRB({
                calculate: {
                    id,
                    name: calculator.name,
                    calculator_type: calculator.calculator_type
                },
                values
            }));
    };

    const onFieldsChange = (field, fields) => {
        let isFull = true;
        fields.forEach(field => {
            if (!field.value) isFull = false;
        });
        setIsFull(isFull);
    };

    const onValuesChange = (value, values) => {
        if (JSON.stringify(values) !== JSON.stringify(isAuthState
            ? {
                planned_yield: rb.planned_yield,
                report_n: rb.report_n,
                report_p: rb.report_p,
                report_k: rb.report_k,
                region: rb.region?.id,
                culture: rb.culture?.id,
                degree_of_soil_moisture: rb.degree_of_soil_moisture?.id,
                analysis_method: rb.analysis_method,
                soil_sampling_depth: rb.soil_sampling_depth,
                fertilizer_action_year: rb.fertilizer_action_year,
                depth_of_arable_layer: rb.depth_of_arable_layer,
                mechanical_composition: rb.mechanical_composition?.id

            }
            : {
                planned_yield: rb.planned_yield,
                report_n: rb.report_n,
                report_p: rb.report_p,
                report_k: rb.report_k,
                region: rb.region?.name,
                culture: rb.culture?.name,
                degree_of_soil_moisture: rb.degree_of_soil_moisture?.name,
                analysis_method: rb.analysis_method,
                soil_sampling_depth: rb.soil_sampling_depth,
                fertilizer_action_year: rb.fertilizer_action_year,
                depth_of_arable_layer: rb.depth_of_arable_layer,
                mechanical_composition: rb.mechanical_composition?.name

            })) {
            setValuesChanged(true);
        } else {
            setValuesChanged(false);
        }
        if (Object.keys(value)[0] === 'region') {
            const filter = `?filter={"region.id":${value.region}}`;
            dispatch(getCalculatorsRBRegionCulture(filter));
            form.setFieldsValue({ culture: '' });
        }
    };

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();
        inputName = inputName.toLowerCase();
        return optionName.indexOf(inputName) !== -1;
    };

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const closeCalculator = () => {
        history.push('/calculators');
    };

    return (
        <div>
            <Form onFinish={onFinish}
                onFieldsChange={onFieldsChange}
                onValuesChange={onValuesChange}
                form={form}
                className={cn()}
                name="calculator-params">
                <div className={cn('params')}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {t('planned yield')}
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="planned_yield"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <InputNumber
                                            min={0}
                                            max={1000}
                                            disabled={isCompleted}
                                            decimalSeparator=","
                                            validator="float"
                                            placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {t('data of test n')}
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="report_n"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <InputNumber
                                            min={0}
                                            max={50}
                                            disabled={isCompleted}
                                            decimalSeparator=","
                                            validator="float"
                                            placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {t('data of test p')}
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="report_p"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <InputNumber
                                            min={0}
                                            max={50}
                                            disabled={isCompleted}
                                            decimalSeparator=","
                                            validator="float"
                                            placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {t('data of test k')}
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="report_k"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <InputNumber
                                            min={0}
                                            max={50}
                                            disabled={isCompleted}
                                            decimalSeparator=","
                                            validator="float"
                                            placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}><b>{t('fertilizer costs')}</b></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('region type')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="region"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <Select
                                            showSearch
                                            disabled={isCompleted}
                                            filterOption={filterOption}>
                                            {
                                                regions.map(
                                                    (item =>
                                                        (
                                                            <Select.Option value={item.id} key={item.id}>
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    )
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('culture choice')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="culture"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <Select
                                            showSearch
                                            disabled={isCompleted}
                                            filterOption={filterOption}>
                                            {
                                                cultures.map(
                                                    (item =>
                                                        (
                                                            <Select.Option value={item.culture?.id} key={item.culture?.id}>
                                                                {item.culture?.name}
                                                            </Select.Option>
                                                        )
                                                    )
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={24}><b>{t('correction factor')}</b></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('hydration choice')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="degree_of_soil_moisture"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <Select
                                            showSearch
                                            disabled={isCompleted}
                                            filterOption={filterOption}>
                                            {
                                                degreeSoil.map(
                                                    (item =>
                                                        (
                                                            <Select.Option value={item.id} key={item.id}>
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    )
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('method analysis')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="analysis_method"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <Select
                                            disabled={isCompleted}
                                            filterOption={filterOption}>
                                            <Select.Option value="kirsanov">{t('kirsanov')}</Select.Option>
                                            <Select.Option value="chirikov">{t('chirikov')}</Select.Option>
                                            <Select.Option value="machigin">{t('machigin')}</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('method analysis depth')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="soil_sampling_depth"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <InputNumber disabled={isCompleted}
                                            min={0}
                                            max={100}
                                            decimalSeparator=","
                                            placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('year choice')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="fertilizer_action_year"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <Select
                                            showSearch
                                            disabled={isCompleted}
                                            filterOption={filterOption}>
                                            {
                                                years.map(
                                                    (item =>
                                                        (
                                                            <Select.Option value={item.id} key={item.id}>
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    )
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('depth of layer')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="depth_of_arable_layer"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <Select
                                            showSearch
                                            disabled={isCompleted}
                                            filterOption={filterOption}>
                                            {
                                                depth.map(
                                                    (item =>
                                                        (
                                                            <Select.Option value={item.id} key={item.id}>
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    )
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>{t('soil texture choice')}</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="mechanical_composition"
                                        rules={[{ required: true, message: t('not empty') }]}>
                                        <Select
                                            showSearch
                                            disabled={isCompleted}
                                            filterOption={filterOption}>
                                            {
                                                compositionSoil.map(
                                                    (item =>
                                                        (
                                                            <Select.Option value={item.id} key={item.id}>
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    )
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    {!isFull && (
                        <Row className={cn('info')}>
                            <Col>
                                <div className={cn('info__inner')}>{t('info')}</div>
                            </Col>
                        </Row>
                    )}
                    {rb.id && !isNotNeed && (
                        <>
                            <div className="table-percent">
                                <div className="table-percent__title">{t('table title')}</div>
                                <Row className="table-percent__header">
                                    <Col span={4}>{t('table name')}</Col>
                                    <Col span={5}>{t('table autumn')}</Col>
                                    <Col span={5}>{t('table spring')}</Col>
                                    <Col span={5}>{t('table feeding')}</Col>
                                    <Col span={5}>{t('table total')}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table n')}</Col>
                                    <Col span={5}>{rb.recommended_n_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{rb.recommended_n_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{rb.recommended_n_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+rb.recommended_n_main + rb.recommended_n_sowing + rb.recommended_n_feeding)?.toLocaleString('ru-RU')}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table p')}</Col>
                                    <Col span={5}>{rb.recommended_p_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{rb.recommended_p_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{rb.recommended_p_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+rb.recommended_p_main + rb.recommended_p_sowing + rb.recommended_p_feeding)?.toLocaleString('ru-RU')}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table k')}</Col>
                                    <Col span={5}>{rb.recommended_k_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{rb.recommended_k_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{rb.recommended_k_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+rb.recommended_k_main + rb.recommended_k_sowing + rb.recommended_k_feeding)?.toLocaleString('ru-RU')}</Col>
                                </Row>
                            </div>
                            <Row className={cn('info')}>
                                <Col>
                                    <div className={cn('info__inner')}>{t('5 years')}</div>
                                </Col>
                            </Row>
                        </>
                    )}
                    {
                        rb.id && isNotNeed && (
                            <Row className={cn('info')}>
                                <Col>
                                    <div className={cn('info__inner')}>{t('info not need')}</div>
                                </Col>
                            </Row>
                        )
                    }
                </div>

                {valuesChanged && (
                    <Row className={cn('info')}>
                        <Col>
                            <div className={cn('info__inner')}>{t('recalculate important notification')}</div>
                        </Col>
                    </Row>
                )}

                <Row className={cn('button')}>
                    <Col>
                        <Button
                            type="primary"
                            disabled={isCompleted}
                            htmlType="submit"
                            size="large">
                            {t('submit')}
                        </Button>
                    </Col>
                </Row>
                <SaveMessage
                    visible={visible}
                    title="end title"
                    text="end text"
                    okBtn="end save"
                    cancelBtn="cancel save"
                    onOk={() => {}}
                    onCancel={hideModal} />
            </Form>
        </div>
    );
};