import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getSubgroupsPhyto = body => request.get({
    url: `/api/v1/disease/disease_subgroup/`
});
export const getSymptomsPhyto = body => request.get({
    url: `/api/v1/disease/symptoms/`
});
export const getVegPhasesPhyto = body => request.get({
    url: `/api/v1/field_forecast/vegetation_phase/${body?.filter?.culture ? `?filter={"culture": ${body?.filter?.culture}}` : ''}`
});
export const newKindPhyto = body => request.post({
    url: `/api/v1/disease/causative_agent_kind/`,
    body: body.values
});
export const newAgentPhyto = body => request.post({
    url: `/api/v1/disease/causative_agent/`,
    body: body.values
});
export const newDiseasePhyto = body => request.post({
    url: `/api/v1/disease/disease/`,
    body: body.values
});
export const newConnectionPhyto = body => request.post({
    url: `/api/v1/disease/culture_to_culture_disease/`,
    body: body.values
});

export const updatePhotoDescription = body => request.patch({
    url: `/api/v1/fito_calculator/fito_picture/${body.imageId}/`,
    body: body.values
});

export const updateInternalContamination = body => request.patch({
    url: `/api/v1/fito_calculator/internal_pathogen/${body?.pathogenId}/`,
    body: body?.values
});
export const updateExternalContamination = body => request.patch({
    url: `/api/v1/fito_calculator/external_pathogen/${body?.pathogenId}/`,
    body: body?.values
});
export const addExternalContamination = body => request.post({
    url: `/api/v1/fito_calculator/fito_calculator/${body?.calculatorId}/add_external_pathogens/`,
    body: body?.values
});
export const addInternalContamination = body => request.post({
    url: `/api/v1/fito_calculator/fito_calculator/${body?.calculatorId}/add_internal_pathogens/`,
    body: body?.values
});
export const getCalculatorPhyto = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});

export const getCausativeAgents = ({ page }) => request.get({
    url: `/api/v1/disease/causative_agent_kind/?page=${page}`
});

export const getCalculatorsPhyto = ({ page, pageSize, filterString = '' }) => request.get({
    url: `${options.apiUrl}?page=${page}&page_size=${pageSize}${filterString?.length > 0 ? filterString : ''}`
});

export const createCalculatorPhyto = body => request.post({
    url: options.apiUrl,
    body
});
export const createRegistryPhyto = body => request.post({
    url: '/api/v1/fito_calculator/fito_protocol/',
    body
});
export const updateRegistryPhyto = body => request.patch({
    url: `/api/v1/fito_calculator/fito_protocol/${body.calcId}/`,
    body: body.values
});

export const getReproductionsPhyto = ({ page }) => request.get({
    url: urlPath(options.reproductionUrl, `?page_size=1000&page=${page}`)
});

export const getVarietiesPhyto = ({ page, culturesList }) => request.get({
    url: urlPath(options.varietiesUrl, `?${culturesList?.length > 0 ? `filter={"culture": [${culturesList?.join(', ')}]}` : ''}&page_size=1000&page=${page}`)
});

export const getCausativeAgentItems = ({ page }) => request.get({
    url: `/api/v1/disease/causative_agent/?page=${page}`
});

export const removeCalculatorPhyto = id => request.remove({
    url: urlPath(options.apiUrl, `${id}/`)
});

export const getCulturesPhyto = ({ page }) => request.get({
    url: urlPath(options.culturesUrl, `?page_size=1000&page=${page}`)
});

export const getFertilizersPhyto = ({ page }) => request.get({
    url: urlPath(options.fertilizersApi, `?page_size=1000&page=${page}`)
});

export const getPurposesPhyto = ({ page }) => request.get({
    url: urlPath(options.purposesUrl, `?page_size=1000&page=${page}`)
});

export const removeProtoPhyto = ({ id }) => request.remove({
    url: urlPath(options.deleteProtocolUrl, `${id}/`)
});

export const deleteProtocolPhyto = ({ id }) => request.remove({
    // url: `/api/v1/fito_calculator/fito_protocol/${id}/`
    url: urlPath(options.apiUrl, `${id}/`)
});

export const getRegionsPhyto = ({ page }) => request.get({
    url: urlPath(options.regionsUrl, `?page_size=1000&page=${page}`)
});

export const updateCalculatorPhyto = body => request.patch({
    url: urlPath(options.apiUrl, `${body.calcId}/`),
    body: body.values
});

export const addCausativeAgent = body => request.post({
    url: '/api/v1/disease/causative_agent/',
    body
});
export const addDisease = body => request.post({
    url: '/api/v1/disease/disease/',
    body
});
export const uploadRegistryFile = body => request.post({
    url: '/api/v1/fito_calculator/fito_picture/',
    body: body.values,
    type: body.type
});
export const updateRegistryFiles = body => request.patch({
    url: `/api/v1/fito_calculator/fito_protocol/${body.protocolId}/`,
    body: body.values
});
export const getProtocolsPhyto = body => request.get({
    url: `/api/v1/fito_calculator/fito_protocol/?page=${body.page}&page_size=${body.pageSize}${body?.sorting?.length > 0 ? `${body?.sorting}` : ''}`
});

export const getProtocolPhyto = id => request.get({
    url: `/api/v1/fito_calculator/fito_protocol/${id}/`
});

export const getUsersPhyto = body => request.get({
    url: `/api/v1/teleagronom_user/owners/?page_size=1000&page=${body?.pageNum ? body?.pageNum : 1}`
});

export const getDrugsPhyto = body => request.get({
    url: `/api/v1/plant_protection/plant_protection_product/?page_size=250&page=${body?.pageNum ? body?.pageNum : 1}`
});

export const getDiseasesPhyto = body => request.get({
    url: `/api/v1/disease/disease/?page_size=1000&page=${body?.pageNum ? body?.pageNum : 1}`
});

export const removeInternalContamination = body => request.remove({
    url: `/api/v1/fito_calculator/internal_pathogen/${body.pathogenId}/`
});

export const removeExternalContamination = body => request.remove({
    url: `/api/v1/fito_calculator/external_pathogen/${body.pathogenId}/`
});