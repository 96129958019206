import * as constants from './constants';

export const getCalculatorsSoilDepth = payload => ({
    type: constants.getCalculatorsSoilDepth.toString(),
    payload
});

export const getCalculatorsSoilDepthItem = payload => ({
    type: constants.getCalculatorsSoilDepthItem.toString(),
    payload
});