import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import converter from './converter';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCarouselData.toString(),
            defaultSaga({
                constants: constants.getCarouselData,
                api: api.getCarouselData
            })
        ),
        yield takeLatest(
            constants.getCarouselCultures.toString(),
            defaultSaga({
                constants: constants.getCarouselCultures,
                api: api.getCarouselCultures
            })
        ),
        yield takeLatest(
            constants.getCarouselSymptoms.toString(),
            defaultSaga({
                constants: constants.getCarouselSymptoms,
                api: api.getCarouselSymptoms
            })
        )
    ]);
}