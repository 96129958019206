import { userActivation, resetPassword, resetPasswordConfirm } from '../constants';

export default function reducer(state = false, action) {
    switch (action.type) {
        case resetPassword.try:
        case resetPasswordConfirm.try:
        case userActivation.try: {
            return false;
        }
        
        case resetPassword.error:
        case resetPasswordConfirm.error:
        case userActivation.error: {
            return false;
        }
        case resetPassword.success:
        case resetPasswordConfirm.success:
        case userActivation.success: {
            return true;
        }
        default: return state;
    }
}
