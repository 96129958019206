import React, { Component } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import cn from '../../utils/cn';
import './style.less';

@cn('sort-icon')
class SortIcon extends Component {
    render() {
        const { cn } = this;
        const {
            title,
            sortType
        } = this.props;

        return (
            <div className={cn()}>
                <div>{title}</div>
                <div>
                    <CaretUpOutlined className={cn(`${sortType === 'asc' ? 'black' : 'grey'}`)} />
                    <CaretDownOutlined className={cn(`${sortType === 'desc' ? 'black' : 'grey'}`)} />
                </div>
            </div>
        );
    }
}

export default SortIcon;