import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import { Modal } from 'antd';
import LoginForm from '../../auth/components/login-form';
import RegForm from '../../auth/components/reg-form';


export default ({ active, onCancel, onSubmit }) => {
    const cn = useClassName('home-page');
    const { t } = useTranslation('home');
    

    const [isLogin, setIsLogin] = useState(true);
    
    const setReg = () => {
        setIsLogin(false);
    };

    const setLogin = () => {
        setIsLogin(true);
    };
    
    return (
        <Modal
            width={420}
            footer={null}
            closable={false}
            destroyOnClose
            visible={active}
            className={cn('modal')}
            onCancel={onCancel}>
            {isLogin
                ? <LoginForm setReg={setReg} onClose={onCancel} />
                : <RegForm setLogin={setLogin} />}
        </Modal>
    );
};
