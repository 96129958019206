import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private exact
        key="calculator-route"
        // path="/mordovia/:year/:id?"
        path="/representation/:year/:id?"
        component={Page} />
];

export default Routes;