import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import {
    Modal, Button, Dropdown, Menu
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoginForm from '../../auth/components/login-form';
import { getUserName, isAuth } from '../../../models/account/selectors';


export default ({ type, setPageType, isShow = true }) => {
    const cn = useClassName('home-page__breadcrumbs');
    const { t } = useTranslation('breadcrumb');
    const [active, setActive] = useState(false);

    const username = useSelector(state => getUserName(state));
    const isAuthState = useSelector(state => isAuth(state));

    const history = useHistory();

    const breadcrumbs = [
        { value: 'legal-entities', text: t('legal-entities') },
        { value: 'individuals', text: t('individuals') },
        { value: 'lab-research', text: t('lab research') }
    ];

    const onStart = () => {
        setActive(true);
    };

    const onCancel = () => {
        setActive(false);
    };

    const goToModule = (url) => {
        localStorage.setItem('prevPage', url);
        onStart();
    };

    const goToPage = () => {
        const path = localStorage.getItem('prevPage') || '/determinants';
        history.push(path);
    };

    const menu = (
        <Menu>
            {isAuthState && (
                <Menu.Item key="0" onClick={goToPage}>
                    {t('enter as')} {username}
                </Menu.Item>
            )}
            <Menu.Item key="1" onClick={() => goToModule('/determinants')}>
                {t('enter legal entities')}
            </Menu.Item>
            <Menu.Item key="2" onClick={() => window.open('https://t.me/TELEAGRONOM', '_blank')}>
                {t('enter individuals')}
            </Menu.Item>
        </Menu>
    );

    return (
        <div className={cn()}>
            <div onClick={() => setPageType('main')}>
                <img src="/images/logo_inner.svg" alt="TeleAgronom" />
            </div>
            <div>
                {breadcrumbs.map(item =>
                    (item.value === type
                        ? <div className={cn('active')} span={3} key={item.text}>{item.text}</div>
                        : <div onClick={() => setPageType(item.value)} span={3} key={item.text}>{item.text}</div>))}
                <div className={cn('buttons')}>
                    <Dropdown overlay={menu}>
                        <Button type="primary"
                            style={{ marginRight: '20px' }}
                            size="large">
                            {t('enter')} <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>

            <Modal
                width={420}
                footer={null}
                closable={false}
                destroyOnClose
                visible={active}
                className={cn('modal')}
                onCancel={onCancel}>
                <LoginForm setReg={() => {}} onClose={onCancel} />
            </Modal>
        </div>
    );
};
