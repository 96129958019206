import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.calculateDiagnostics.success: {
            return action?.payload || {};
        }

        case constants.calculateDiagnostics.try:
        case constants.getLeafFieldPoints.error: {
            return initialState;
        }

        default:
            return state;
    }
}