import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getNutrition.try:
        case constants.addNutrition.try:
        case constants.updateNutrition.try: {
            return true;
        }

        case constants.getNutrition.success:
        case constants.addNutrition.success:
        case constants.updateNutrition.success:
        case constants.getNutrition.error:
        case constants.addNutrition.error:
        case constants.updateNutrition.error: {
            return false;
        }

        default:
            return state;
    }
}