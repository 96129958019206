import { combineReducers } from 'redux';

import data from './data';
import list from './list';
import loading from './loading';
import feedings from './feedings';

export default combineReducers({
    data,
    list,
    loading,
    feedings
});
