import ConverterClass from '../../utils/converter';
import normative from '../item-normative/converter';
import nutrition from '../item-nutrition/converter';
import rb from '../item-rb/converter';
import distribution from '../item-distribution/converter';
import agrochemical from '../item-interpretation/converter';

class Converter extends ConverterClass {
    toClient(data) { // eslint-disable-line
        return {
            id: data.id,
            date: data.date,
            calculator_type: data.calculator_type,
            name: data.name,
            is_done: data.is_done || false,
            created_at: data.created_at,
            updated_at: data.updated_at,
            user: data.user,
            normative: normative.toClient(data.normative || {}),
            nutrition: nutrition.toClient(data.nutrition || {}),
            rb: rb.toClient(data.rb || {}),
            imap: data?.calculator_type === 13 ? data : data?.imap,
            distribution: distribution.toClient(data.distribution || {}),
            agrochemical: agrochemical.toClient(data.agrochemical || {}),
            type: data.type,
            recommendation_register: data?.recommendation_register,
            points: data.points,
            zones: data.zones,
            year: data.year
        };
    }
}

export default new Converter();