import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import PanelHeader from './panel-header';
import PanelFooter from './panel-footer';
import PanelContent from './panel-content';
import cn from '../../utils/cn';
import './style.less';

@cn('panel-layout')
class PanelLayout extends Component {
    render() {
        const { cn } = this;
        const {
            className,
            loading,
            children,
            ...rest
        } = this.props;

        return (
            <div className={`${cn()} ${className}`}>
                {loading && <Spin />}
                {children}
            </div>
        );
    }
}

PanelLayout.propTypes = {
    /** Дополнительное имя класса */
    className: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.any.isRequired
};

PanelLayout.defaultProps = {
    className: '',
    loading: false
};

PanelLayout.PanelFooter = PanelFooter;
PanelLayout.PanelHeader = PanelHeader;
PanelLayout.PanelContent = PanelContent;

export default PanelLayout;