import { combineReducers } from 'redux';

import protocolItem from './protocol-item';
import fedPoints from './fed-points';
import agroPoints from './agro-points';

export default combineReducers({
    protocolItem,
    fedPoints,
    agroPoints
});
