import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import converter from './converter';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getDiseases.toString(),
            defaultSaga({
                constants: constants.getDiseases,
                api: api.getDiseases
            })
        ),
        yield takeLatest(
            constants.getDisease.toString(),
            defaultSaga({
                constants: constants.getDisease,
                api: api.getDisease
            })
        ),
        yield takeLatest(
            constants.getDiseasesDamageArea.toString(),
            defaultSaga({
                constants: constants.getDiseasesDamageArea,
                api: api.getDiseasesDamageArea
            })
        ),
        yield takeLatest(
            constants.getDiseasesSubGroup.toString(),
            defaultSaga({
                constants: constants.getDiseasesSubGroup,
                api: api.getDiseasesSubGroup
            })
        ),
        yield takeLatest(
            constants.getDiseasesGroup.toString(),
            defaultSaga({
                constants: constants.getDiseasesGroup,
                api: api.getDiseasesGroup
            })
        ),
        yield takeLatest(
            constants.getDiseasesCulture.toString(),
            defaultSaga({
                constants: constants.getDiseasesCulture,
                api: api.getDiseasesCulture
            })
        ),
        yield takeLatest(
            constants.getDiseasesCausativeAgent.toString(),
            defaultSaga({
                constants: constants.getDiseasesCausativeAgent,
                api: api.getDiseasesCausativeAgent
            })
        )
    ]);
}