import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getPesticides = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getPesticide = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});

export const getPesticideApplicationArea = filter => request.get({
    url: urlPath(options.apiApplicationAreaUrl, filter)
});

export const getPesticideApplicationAreaItem = id => request.get({
    url: urlPath(options.apiApplicationAreaUrl, `${id}/`)
});

export const getPesticideChemicalClasses = filter => request.get({
    url: urlPath(options.apiChemicalClassUrl, filter)
});

export const getPesticideChemicalClassesItem = id => request.get({
    url: urlPath(options.apiChemicalClassUrl, `${id}/`)
});


export const getPesticideHarmfulObject = filter => request.get({
    url: urlPath(options.apiHarmfulObjectUrl, filter)
});

export const getPesticideHarmfulObjectItem = id => request.get({
    url: urlPath(options.apiHarmfulObjectUrl, `${id}/`)
});


export const getPesticideHazardClass = filter => request.get({
    url: urlPath(options.apiHazardClassUrl, filter)
});

export const getPesticideHazardClassItem = id => request.get({
    url: urlPath(options.apiHazardClassUrl, `${id}/`)
});


export const getPesticidePlaceOfProduction = filter => request.get({
    url: urlPath(options.apiPlaceOfProductionUrl, filter)
});

export const getPesticidePlaceOfProductionItem = id => request.get({
    url: urlPath(options.apiPlaceOfProductionUrl, `${id}/`)
});


export const getPesticideGroup = filter => request.get({
    url: urlPath(options.apiProtectionGroupUrl, filter)
});

export const getPesticideGroupItem = id => request.get({
    url: urlPath(options.apiProtectionGroupUrl, `${id}/`)
});


export const getPesticideMainDrug = filter => request.get({
    url: urlPath(options.apiMainDrugUrl, filter)
});

export const getPesticideMainDrugItem = id => request.get({
    url: urlPath(options.apiMainDrugUrl, `${id}/`)
});


export const getPesticideProductApplication = filter => request.get({
    url: urlPath(options.apiApplicationUrl, filter)
});

export const getPesticideProductApplicationItem = id => request.get({
    url: urlPath(options.apiApplicationUrl, `${id}/`)
});


export const getPesticidePreparativeForm = filter => request.get({
    url: urlPath(options.apiPreparativeFormUrl, filter)
});

export const getPesticidePreparativeFormItem = id => request.get({
    url: urlPath(options.apiPreparativeFormUrl, `${id}/`)
});


export const getPesticideTreatmentType = filter => request.get({
    url: urlPath(options.apiTreatmentTypeUrl, filter)
});

export const getPesticideTreatmentTypeItem = id => request.get({
    url: urlPath(options.apiTreatmentTypeUrl, `${id}/`)
});



export const getCalculatorsNormSeasonScheme = filter => request.get({
    url: urlPath(options.apiSeasonSchemeUrl, filter)
});

export const getCalculatorsNormSeasonSchemeItem = id => request.get({
    url: urlPath(options.apiSeasonSchemeUrl, `${id}/`)
});