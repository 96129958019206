import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getLeafFieldPoints = ({ vega_key }) => request.get({
    url: urlPath(options.fieldPointsApi, `${vega_key}/`)
});

export const calculateDiagnostics = ({ vega_key, body }) => request.post({
    url: urlPath(options.fieldPointsApi, `${vega_key}/fed_calculator/`),
    body
});