import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getNormative.try:
        case constants.addNormative.try:
        case constants.updateNormative.try: {
            return true;
        }

        case constants.getNormative.success:
        case constants.addNormative.success:
        case constants.updateNormative.success:
        case constants.getNormative.error:
        case constants.addNormative.error:
        case constants.updateNormative.error: {
            return false;
        }

        default:
            return state;
    }
}