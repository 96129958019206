import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsAcidity.toString(),
            defaultSaga({
                constants: constants.getCalculatorsAcidity,
                api: api.getCalculatorsAcidity
            })
        ),
        yield takeLatest(
            constants.getCalculatorsAcidityItem.toString(),
            defaultSaga({
                constants: constants.getCalculatorsAcidityItem,
                api: api.getCalculatorsAcidityItem
            })
        )
    ]);
}