import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getReviews.success: {
            return action.payload;
        }

        case constants.removeReview.success: {
            const { removedReviewId } = action.payload;

            return {
                ...state, content: state.content.filter(item => item.id !== removedReviewId)
            };
        }

        default:
            return state;
    }
}
