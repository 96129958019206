import * as constants from './constants';

export const getUnitMeasurements = payload => ({
    type: constants.getUnitMeasurements.toString(),
    payload
});

export const getUnitMeasurement = payload => ({
    type: constants.getUnitMeasurement.toString(),
    payload
});
