import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    Col, Collapse, Layout, Row, Spin
} from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import Icon from '@ant-design/icons';
import {
    Button, PanelLayout, Title
} from '../../components';
import Calculate from '../../containers/item-calculate';
import RB from '../../containers/item-rb';
import Normative from '../../containers/item-normative';
import Nutrition from '../../containers/item-nutrition';
import Interpretation from '../../containers/item-interpretation';
import Distribution from '../../containers/item-distribution';
import XLSXgenerator from '../../containers/xlsx-generator';
import { generatePdf } from '../../containers/pdf-generator';

import './style.less';
import getConstants from './scheme';
import { actions, selectors } from '../../models';

const {
    getCalculate,
    clearCalculate,
    getCalculatorsAcidity,
    getCalculatorsPrecedingCategory,
    getCalculatorsDegreeOfSoil,
    getCalculatorsDepth,
    getCalculatorsPreceding,
    getCalculatorsNormRegionCulture,
    getCalculatorsRBRegionCulture,
    getCalculatorsSoilComposition,
    getCalculatorsSoilDepth,
    getCalculatorsSoilType,
    getCalculatorsYear
} = actions;

const { curCalculate, isCalculateLoading } = selectors;

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;

const Calculator = () => {
    const cn = useClassName('calculators');
    const constants = getConstants();
    const match = useRouteMatch();
    const { params: { type, id } } = match;
    const calculatorType = Object.values(constants.scheme).find(item => item.id === Number(type)) || {};
    const calculator = useSelector(state => curCalculate(state));
    const isLoading = useSelector(state => isCalculateLoading(state));
    const dispatch = useDispatch();
    const { t } = useTranslation('calculators');

    const [activeKeysState, setActiveKeys] = useState([]);
    const [distributionType, setDistribution] = useState('rb');

    useEffect(() => {
        dispatch(getCalculatorsAcidity(''));
        dispatch(getCalculatorsPrecedingCategory(''));
        dispatch(getCalculatorsDegreeOfSoil(''));
        dispatch(getCalculatorsDepth(''));
        dispatch(getCalculatorsPreceding(''));
        dispatch(getCalculatorsRBRegionCulture(''));
        dispatch(getCalculatorsNormRegionCulture(''));
        dispatch(getCalculatorsSoilComposition(''));
        dispatch(getCalculatorsSoilDepth(''));
        dispatch(getCalculatorsSoilType(''));
        dispatch(getCalculatorsYear(''));
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(getCalculate(id));
        } else {
            setActiveKeys([]);
            dispatch(clearCalculate());
        }
    }, [id, type]);

    const jsPdfGenerator = () => {
        generatePdf(t, calculator);
    };

    const onCollapseChange = (value) => {
        setActiveKeys([...value]);
    };

    const isDisabled = (type) => {
        switch (type) {
            case 'calculate':
                return false;
            case 'normative':
                return !calculator.id;
            case 'nutrition':
                return !calculator.id;
            case 'rb':
                return !calculator.id;
            case 'interpretation':
                return !calculator.id;
            case 'distribution':
                return !(calculator?.rb?.id || calculator?.normative?.id || calculator?.nutrition?.id);
            default:
                return false;
        }
    };

    const setDistributionType = (type) => {
        setDistribution(type);
    };

    if (isLoading) {
        return (
            <Layout className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={calculatorType.title} />
                    </PanelHeader>
                    <PanelContent>
                        <Spin />
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    } else {
        return (
            <Layout className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={calculatorType.title} />
                    </PanelHeader>
                    <PanelContent>
                        <Collapse
                            activeKey={activeKeysState}
                            expandIconPosition="right"
                            onChange={onCollapseChange}
                            className={cn('collapse')}>
                            {
                                calculatorType.items?.map(item => (
                                    <Panel disabled={isDisabled(item)}
                                        header={<><Icon component={calculatorType.icon} />{t(`${item} tab`)}</>}
                                        key={t(`${item} tab`)}>
                                        <PanelItem
                                            item={item}
                                            type={calculatorType.type}
                                            setDistributionType={setDistributionType}
                                            distributionType={distributionType} />
                                    </Panel>
                                ))
                            }
                        </Collapse>
                        {id && (
                            <Row className={cn('button')} style={{ marginTop: '30px' }}>
                                <Col>
                                    <Button
                                        onClick={jsPdfGenerator}
                                        type="primary">
                                        <Icon type="download" />
                                        {t('generate')}
                                    </Button>
                                    <XLSXgenerator />
                                </Col>
                            </Row>
                        )}
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
};

const PanelItem = (props) => {
    const {
        item, type, setDistributionType, distributionType
    } = props;
    switch (item) {
        case 'calculate': { return (<Calculate type={type} />); }
        case 'rb': { return (<RB />); }
        case 'normative': { return (<Normative setDistributionType={setDistributionType} />); }
        case 'nutrition': { return (<Nutrition setDistributionType={setDistributionType} />); }
        case 'interpretation': { return (<Interpretation />); }
        case 'distribution': { return (<Distribution distributionType={distributionType} />); }
        default: { return null; }
    }
};

export default Calculator;
