import { combineReducers } from 'redux';

import determinant from './determinant';
import determinants from './determinants';
import question from './question';
import questions from './questions';
import loading from './loading';

export default combineReducers({
    determinant,
    determinants,
    question,
    questions,
    loading
});
