import React, { Component, useState } from 'react';
import {
    MapContainer,
    TileLayer,
    LayersControl,
    LayerGroup,
    WMSTileLayer
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import { CustomWMSLayer } from '../../components/layers/custom-wms';

const center = [56.12905517126139, 49.39052024178771];

export default (props) => {
    const { getData } = props;
    const { t } = useTranslation('map');
    const cn = useClassName('page');

    return (
        <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay checked name="NMDI">
                    <LayerGroup>
                        <CustomWMSLayer
                            name="NMDI"
                            checked
                            legend_url="ndvi_ndmi_base"
                            layer="teleagronom:ndmi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="NDVI">
                    <LayerGroup>
                        <CustomWMSLayer
                            name="NDVI"
                            checked
                            legend_url="ndvi_ndmi_base"
                            layer="teleagronom:ndvi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name={t('schema')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            getData={getData}
                            name="schema"
                            checked
                            layer="teleagronom:agricultural_field_agriculturalfield"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                {/* <LayersControl.Overlay name={t('2018_2020')}>
                    <LayerGroup>
                        <WMSTileLayer url="/geoserver/teleagronom/wms?"
                            params={{
                                layers: 'teleagronom:2018_2020',
                                format: 'image/png',
                                transparent: true
                            }} />
                    </LayerGroup>
                </LayersControl.Overlay> */}
                <LayersControl.Overlay name={t('MERGED_Uklon(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Uklon(1)"
                            legend_url="teleagronom:Uklon"
                            layer="teleagronom:MERGED_Uklon(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_Ekspoziciya(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Ekspoziciya(1)"
                            legend_url="teleagronom:Exposition"
                            layer="teleagronom:MERGED_Ekspoziciya(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_Visota(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Visota(1)"
                            legend_url="teleagronom:relief"
                            layer="teleagronom:MERGED_Visota(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name="Почвы">
                    <LayerGroup>
                        <WMSTileLayer url="/geoserver/teleagronom/wms?"
                            params={{
                                layers: 'teleagronom:RT_Pochvennij_sloj_Total',
                                format: 'image/png',
                                transparent: true
                            }} />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2018')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2018"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2018"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2019')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2019"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2019"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2020')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2020"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2020"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('rt_zones_2018(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="rt_zones_2018(1)"
                            layer="teleagronom:rt_zones_2018(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('tatar')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="tatar"
                            layer="teleagronom:tatar"
                            attributes={[t('plat_1')]}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('30km_mete_agro_fields')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="30km_mete_agro_fields"
                            layer="teleagronom:30km_mete_agro_fields"
                            attributes={['S ga']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Deleniepole_sab')}>
                    <LayerGroup>
                        <WMSTileLayer url="/geoserver/teleagronom/wms?"
                            params={{
                                layers: 'teleagronom:Deleniepole_sab',
                                format: 'image/png',
                                transparent: true
                            }} />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('ETALON_RT_precip')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="ETALON_RT_precip"
                            layer="teleagronom:ETALON_RT_precip"
                            legend_url="teleagronom:Precipitation"
                            attributes={['District', 'S ga', 'precmean', '_midTempme']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('predict')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="predict"
                            legend_url="teleagronom:predict_ozimie_2021_Arsk"
                            layer="teleagronom:ozimie_predict"
                            attributes={['predict', 'Loss_ga']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>

            </LayersControl>
        </MapContainer>
    );
};