export const CALCULATOR_TYPE = {
    RB: 1,
    NORMATIVE: 2,
    AGROCHEMICAL: 3,
    DISTRIBUTION: 4,
    RB_DISTRIBUTION: 5,
    NORMATIVE_DISTRIBUTION: 6,
    AGROCHEMICAL_NORMATIVE_DISTRIBUTION: 7,
    AGROCHEMICAL_RB_DISTRIBUTION: 8,
    AGROCHEMICAL_NORMATIVE: 9,
    AGROCHEMICAL_RB: 10,
    NUTRITION_DISTRIBUTION: 11
};

export const DISTRIBUTION_TYPE = {
    RB: 'rb',
    NORMATIVE: 'normative',
    AGROCHEMICAL: '',
    DISTRIBUTION: '',
    RB_DISTRIBUTION: 'rb',
    NORMATIVE_DISTRIBUTION: 'normative',
    AGROCHEMICAL_NORMATIVE_DISTRIBUTION: 'normative',
    AGROCHEMICAL_RB_DISTRIBUTION: 'rb',
    AGROCHEMICAL_NORMATIVE: 'normative',
    AGROCHEMICAL_RB: 'rb',
    NUTRITION_DISTRIBUTION: 'nutrition'
};