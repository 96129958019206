import React, { useEffect, useState } from 'react';
import Popover from './action-popover';
import { useClassName } from '../../../utils/cn';
import { Table, InfoMessage, TableFooter, Tooltip } from '../../../components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { removeCalculatorPhyto, getCalculatorsPhyto } from '../../../models/calculators-phyto/actions';
import { Spin, DatePicker, Space, Button } from 'antd';
import {
    curCalculatorsPhyto,
    deletionResult
} from '../../../models/calculators-phyto/selectors';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;

const { RangePicker } = DatePicker;

const CalculatorsTablePhyto = () => {
    const cn = useClassName('calculators-table');
    const { t } = useTranslation('calculators table');

    const dispatch = useDispatch();
    const history = useHistory();

    const [infoMessageVisible, setInfoMessageVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [deletableId, setDeletableId] = useState(null);
    const [filterDates, setFilterDates] = useState([]);
    const [sortingRules, setSortingRules] = useState([]);

    const calculatorsList = useSelector(state => curCalculatorsPhyto(state));

    const handleGetCalculators = (curPage) => {
        const filtersArray = [];
        let resultString;

        if (filterDates?.length > 0) {
            filtersArray.push(`"protocol_date.gte":"${filterDates[0]?.format('YYYY-MM-DD')}"`);
            filtersArray.push(`"protocol_date.lte":"${filterDates[1]?.format('YYYY-MM-DD')}"`);
        }

        if (filtersArray?.length > 0) {
            resultString = `&filter={${filtersArray?.join(',')}}`;
        }

        if (sortingRules?.length > 0) {
            if (!resultString || !resultString.length || resultString?.length < 1) {
                resultString = `&${sortingRules?.[1] === 'descend' ? '-' : ''}sort=["${sortingRules[0]}"]`;
            } else {
                resultString += `&${sortingRules?.[1] === 'descend' ? '-' : ''}sort=["${sortingRules[0]}"]`;
            }
        }

        dispatch(getCalculatorsPhyto({
            page: curPage,
            pageSize: pageSize,
            filterString: resultString?.length > 0 ? resultString : ''
        }));
    }

    useEffect(() => {
        handleGetCalculators(page);
    }, [page]);

    useEffect(() => {
        if (page === 1) {
            dispatch(getCalculatorsPhyto({
                page: page,
                pageSize: pageSize
            }));
        } else {
            setPage(1);
        }
    }, [pageSize]);
    

    const showDeleteModal = id => {
        setDeletableId(id);
        setInfoMessageVisible(true);
    };

    const hideDeleteModal = () => {
        setInfoMessageVisible(false);
    };

    const removeCalculator = () => {
        dispatch(removeCalculatorPhyto(deletableId));
        setTimeout(() => {
            dispatch(getCalculatorsPhyto({
                page: page,
                pageSize: pageSize
            }));
        }, 300);
        hideDeleteModal();
    };

    const handleApplyFilters = () => {
        if (page === 1) {
            handleGetCalculators(1);
        } else {
            setPage(1);
        }
    };

    const columns = [
        {
            title: t('title'),
            dataIndex: 'name',
            key: 'name',
            className: cn('title'),
            width: 100,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => (
                <Tooltip
                    onClick={() => history.push(`/calculator-phyto/${record.id}`)}
                    placement="bottomLeft"
                    title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: t('create date'),
            dataIndex: 'protocol_date',
            key: 'protocol_date',
            className: cn('createDate'),
            width: 100,
            sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (text) => text ? moment(text).format('DD.MM.YYYY') : '-',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
              }) => (
                <div style={{ padding: 8 }}>
                  <Space>
                    <RangePicker
                      format={"DD.MM.YYYY"}
                      onChange={(e) => {
                        setFilterDates(e);
                        setSelectedKeys(e);
                      }}
                      allowClear={true}
                    />

                    <Button
                      type="primary"
                      onClick={handleApplyFilters}
                      icon={<SearchOutlined />}
                      size="small"
                      style={{ width: 90 }}
                    >
                      {t('search')}
                    </Button>
                    <Button
                      onClick={() => {
                        setFilterDates([]);
                        handleApplyFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      {t('reset')}
                    </Button>
                  </Space>
                </div>
              ),
              filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
              ),
        },
        {
            title: t('status'),
            dataIndex: 'is_done',
            key: 'is_done',
            className: cn('status'),
            width: 100,
            render: (text) => (
                <>
                    {text && t('COMPLETED')}
                    {!text && t('IN_PROGRESS')}
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            // className: cn('action'),
            width: 100,
            fixed: 'right',
            render: (text, record) => (
                <div className={cn('button')}>
                    <Popover
                        status={record.status}
                        id={record.id}
                        placement="bottomRight"
                        trigger="click"
                        url="calculator-myco"
                        deleteClick={() => showDeleteModal(record.id)} />
                </div>
            )
        }
    ];

    const onTableChange = (pagination, filters, sorter, extra) => {
        const { columnKey, order } = sorter;
        if (columnKey?.length > 0 && order?.length > 0) {
            setSortingRules([columnKey, order]);
            handleApplyFilters();
        } else if (columnKey?.length > 0 && (!order || order?.length < 1)) {
            setSortingRules([]);
            handleApplyFilters();
        }
    };

    if (!calculatorsList?.results?.length) {
        return (
            <div className={cn('empty')}>
                {calculatorsList?.isLoading ? <Spin /> : t('no')}
            </div>
        );
    }

    return (
        <div className={cn()}>
            <Table
                onChange={onTableChange}
                dataSource={calculatorsList?.results}
                columns={columns}
                pagination={false} />
            {calculatorsList?.results?.length > 1 && (
                <TableFooter
                    filterItems={[
                        {
                            label: '15',
                            value: 15
                        },
                        {
                            label: '25',
                            value: 25
                        },
                        {
                            label: '50',
                            value: 50
                        }
                    ]}
                    showSizeChanger={false}
                    onSelect={setPageSize}
                    onChange={(page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                    }}
                    pageSize={pageSize}
                    current={page}
                    rowDivider={ROW_DIVIDER.toString()}
                    dataSize={calculatorsList?.total} />
            )}
            <InfoMessage
                visible={infoMessageVisible}
                title={t('delete title')}
                okBtn={t('delete')}
                cancelBtn={t('cancel')}
                onOk={removeCalculator}
                onCancel={hideDeleteModal} />
        </div>
    );
};

export default CalculatorsTablePhyto;