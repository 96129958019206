import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Checkbox,
    Col,
    DatePicker,
    Button,
    Form,
    Input,
    Row, Select,
    Slider,
    Space,
    Spin,
    Table,
    Tooltip,
    notification,
    InputNumber
} from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// import { Button } from '../../../components';

import '../style.less';
import { createSampleRow, editSampleRow, getUsersList } from '../../../models/sample-log/actions';

import StatusLowCircle from '../../../../public/images/circles/low.svg';
import StatusMediumCircle from '../../../../public/images/circles/medium.svg';
import StatusHighCircle from '../../../../public/images/circles/high.svg';
import { getUser } from '../../../models/account/selectors';
import { getCalculatorsMordoviaCatalog, getCalculatorsMordoviaObject } from '../../../models/mordovia/selectors';
import { getCalculatorsMordovia } from '../../../models/mordovia/actions';
import { usrCreationResult, usrListResponse } from '../../../models/sample-log/selectors';
import { toFixed } from '../../../utils/utils';
import { curMycoFields } from '../../../models/mycological-registry/selectors';

const methodNames = [
    {
        label: 'Ч',
        value: 'chirikov'
    },
    {
        label: 'K',
        value: 'kirsanov'
    },
    {
        label: 'М',
        value: 'machigin'
    }
];

// const { RangePicker } = DatePicker;

const zonesSorter = { low: 1, high: 3, middle: 2 };

const SampleTable = ({
    // filteredDataSet, setFilteredDataSet, setIsLoading,
    // columnOptions, addRow, setColumnOptions,
    tableData, clickRow,
    selectedRows, selectRows, activeColumnOptions,
    isFullScreen, exitFullScreen, setIsNewUserModalOpen,
    setUserList, newRecordEntity, setNewRecordEntity,
    currentPage, setCurrentPage, setActiveColumnOptions,
    setSelectedRecords, dataLength,
    pageSize, setPageSize,
    fullUsersList, setFullUsersList
}) => {
    const { t } = useTranslation('sample log');
    const dispatch = useDispatch();

    const usersNew = useSelector(state => usrListResponse(state));
    const userCreated = useSelector(state => usrCreationResult(state));

    const [newPNum, setNewPNum] = useState('');
    const [newPOwner, setNewPOwner] = useState(null);
    const [newPField, setNewPField] = useState('');
    const [newPFieldName, setNewPFieldName] = useState('');
    const [selectedK, setSelectedK] = useState(null);
    const [selectedA, setSelectedA] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [selectedPhWater, setSelectedPhWater] = useState(null);
    const [selectedPhSalt, setSelectedPhSalt] = useState(null);
    const [selectedElectricalConductivity, setSelectedElectricalConductivity] = useState(null);
    const [selectedOrganicSubstances, setSelectedOrganicSubstances] = useState(null);
    const [selectedProductivityZone, setSelectedProductivityZone] = useState(null);
    const [selectedProductivityZoneArea, setSelectedProductivityZoneArea] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedKapelDate, setSelectedKapelDate] = useState(null);
    const [selectedFNum, setSelectedFNum] = useState(null);
    const [selectedNH4, setSelectedNH4] = useState(null);
    const [selectedK2O, setSelectedK2O] = useState(null);
    const [selectedNa, setSelectedNa] = useState(null);
    const [selectedMg, setSelectedMg] = useState(null);
    const [selectedCa, setSelectedCa] = useState(null);
    const [selectedCl, setSelectedCl] = useState(null);
    const [selectedSO4, setSelectedSO4] = useState(null);
    const [selectedN, setSelectedN] = useState(null);
    const [selectedPO4, setSelectedPO4] = useState(null);
    const [usersPage, setUsersPage] = useState(1);
    const [tableDataState, setTableDataState] = useState([]);
    const [usersDictionary, setUsersDictionary] = useState({});

    const handleSave = (record, fieldName, newRow = false, newFieldNum = '') => {
        if (newRow) {
            setValueToNew(fieldName, record?.valueToUpdateNow);
            if (
                newPField && newPField > 0 && newPOwner && newPOwner > 0 && newPNum && newPNum > 0
                // && newPFieldName && newPFieldName?.length > 0
            ) {
                dispatch(editSampleRow({
                    sampleRowId: record.id,
                    body: {
                        [fieldName]: record.valueToUpdateNow
                    }
                }));
            }
            fieldName === 'owner' && setNewPOwner(record?.valueToUpdateNow);
            fieldName === 'num' && setNewPNum(record?.valueToUpdateNow);
            fieldName === 'point' && setNewPField(record?.valueToUpdateNow);
            fieldName === 'vega_key' && setNewPFieldName(record?.valueToUpdateNow);
        } else if ((record?.valueToUpdateNow?.length > 0 || record?.valueToUpdateNow || ((fieldName === 'K' || fieldName === 'A') && record?.valueToUpdateNow === 0)) && fieldName !== 'num') {
            const reqBody = {
                sampleRowId: record.id,
                body: {
                    [fieldName]: record.valueToUpdateNow
                }
            };

            if (fieldName === 'NH4_result' && parseFloat(record.valueToUpdateNow) !== record.NH4_result) {
                reqBody.body = {
                    NH4: record.valueToUpdateNow
                };
            }
            if (fieldName === 'K20_result' && parseFloat(record.valueToUpdateNow) !== record.K20_result) {
                reqBody.body = {
                    K20: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Na_result' && parseFloat(record.valueToUpdateNow) !== record.Na_result) {
                reqBody.body = {
                    Na: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Mg_result' && parseFloat(record.valueToUpdateNow) !== record.Mg_result) {
                reqBody.body = {
                    Mg: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Ca_result' && parseFloat(record.valueToUpdateNow) !== record.Ca_result) {
                reqBody.body = {
                    Ca: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Cl_result' && parseFloat(record.valueToUpdateNow) !== record.Cl_result) {
                reqBody.body = {
                    Cl: record.valueToUpdateNow
                };
            }
            if (fieldName === 'SO4_result' && parseFloat(record.valueToUpdateNow) !== record.SO4_result) {
                reqBody.body = {
                    SO4: record.valueToUpdateNow
                };
            }
            if (fieldName === 'N_result' && parseFloat(record.valueToUpdateNow) !== record.N_result) {
                reqBody.body = {
                    N: record.valueToUpdateNow
                };
            }
            if (fieldName === 'PO4_result' && parseFloat(record.valueToUpdateNow) !== record.PO4_result) {
                reqBody.body = {
                    PO4: record.valueToUpdateNow
                };
            }
            if (fieldName === 'owner') {
                // setNewPOwner(record?.valueToUpdateNow);
                reqBody.body = {
                    [fieldName]: record.valueToUpdateNow,
                    vega_key: null
                };
            }
            if (fieldName === 'vega_key') {
                reqBody.body.field_num = record?.field_num;
            }

            if (fieldName === 'productivity_zone' && record?.valueToUpdateNow === 'undefined') {
                reqBody.body = {
                    [fieldName]: null
                };
            }

            dispatch(editSampleRow(reqBody));
        }
    };

    const [columnsArray, setColumnsArray] = useState([
        {
            title: t('table header sample num'),
            dataIndex: 'num',
            key: 'num',
            textWrap: 'word-break',
            width: 100,
            // ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'num',
                inputType: 'text',
                record,
                editable: !record?.id,
                handleSave: (record) => handleSave(record, 'num', !record?.id)
            }),
            render: (val, record) => {
                return {
                    props: {
                        style: record?.deleted ? {
                            background: '#dcdcdc'
                        } : {
                            color: 'rgba(3, 181, 117, 1)',
                            background: 'rgba(31, 205, 117, 0.10)'
                        }
                    },
                    children: (<div onClick={() => clickRow(record?.id)}>{tooltipedValue(val)}</div>)
                };
            },
            sorter: (a, b) => a.num - b.num
        },
        {
            title: t('table header owner'),
            width: 160,
            // ellipsis: true,
            dataIndex: 'owner',
            key: 'owner',
            onCell: (record) => ({
                inputType: 'select',
                dataIndex: 'owner',
                selectOptions: [],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'owner', !record?.id)
            }),
            render: (val) => {
                return tooltipedValue(fullUsersList?.filter(x => parseInt(x?.id, 10) === parseInt(val, 10))[0]?.name || val);
            }
        },
        {
            title: t('table header field name'),
            width: 160,
            ellipsis: true,
            dataIndex: 'vega_key',
            key: 'vega_key',
            onCell: (record) => ({
                // inputType: 'text',
                inputType: 'select',
                dataIndex: 'vega_key',
                selectOptions: [],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'vega_key', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header field num'),
            width: 120,
            ellipsis: true,
            dataIndex: 'field_num',
            key: 'field_num',
            onCell: (record) => ({
                inputType: 'text',
                dataIndex: 'field_num',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'field_num', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header point num'),
            width: 110,
            ellipsis: true,
            dataIndex: 'point',
            key: 'point',
            onCell: (record) => ({
                dataIndex: 'point',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'point', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header status'),
            width: 140,
            ellipsis: true,
            dataIndex: 'public',
            key: 'public',
            render: (val) => val ? (
                <div className="status-message__public">
                    {t('status text public')}
                </div>
            ) : (
                <div className="status-message__notpublic">
                    {t('status text not public')}
                </div>
            )
        },
        {
            title: t('table header sample date'),
            width: 125,
            ellipsis: true,
            dataIndex: 'date',
            key: 'date',
            onCell: (record) => ({
                dataIndex: 'date',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'date', !record?.id)
            }),
            render: (val) => tooltipedValue(val, true),
            sorter: (a, b) => moment(a.date) - moment(b.date)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        Анализ на капель,
                    </Row>
                    <Row>
                        дата
                    </Row>
                </Col>
            ),
            width: 160,
            ellipsis: true,
            dataIndex: 'analysis_date',
            key: 'analysis_date',
            onCell: (record) => ({
                dataIndex: 'analysis_date',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'analysis_date', !record?.id)
            }),
            render: (val) => tooltipedValue(val, true),
            // ...getColumnSearchProps('analysis_date', searchedDates),
            sorter: (a, b) => moment(a.analysis_date) - moment(b.analysis_date)
        },
        {
            title: t('table header K'),
            width: 75,
            ellipsis: true,
            dataIndex: 'K',
            key: 'K',
            onCell: (record) => ({
                dataIndex: 'K',
                inputType: 'select',
                selectOptions: [
                    {
                        label: 0,
                        value: 0
                    },
                    {
                        label: 1,
                        value: 1
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'K', !record?.id)
            })
        },
        {
            title: t('table header A'),
            width: 75,
            ellipsis: true,
            dataIndex: 'A',
            key: 'A',
            onCell: (record) => ({
                dataIndex: 'A',
                inputType: 'select',
                selectOptions: [
                    {
                        label: 0,
                        value: 0
                    },
                    {
                        label: 1,
                        value: 1
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'A', !record?.id)
            })
        },
        {
            title: t('table header method'),
            width: 100,
            ellipsis: true,
            dataIndex: 'method',
            key: 'method',
            onCell: (record) => ({
                dataIndex: 'method',
                inputType: 'select',
                selectOptions: [
                    {
                        label: 'Ч',
                        value: 'chirikov'
                    },
                    {
                        label: 'К',
                        value: 'kirsanov'
                    },
                    {
                        label: 'М',
                        value: 'machigin'
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'method', !record?.id)
            }),
            render: (val) => methodNames?.filter(methodName => methodName.value === val)[0]?.label
        },
        {
            title: t('table header ph_water'),
            width: 100,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'ph_water',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'ph_water', !record?.id)
            }),
            dataIndex: 'ph_water',
            key: 'ph_water',
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header ph_salt'),
            width: 100,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'ph_salt',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'ph_salt', !record?.id)
            }),
            dataIndex: 'ph_salt',
            key: 'ph_salt',
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header E'),
            width: 75,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'electrical_conductivity',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'electrical_conductivity', !record?.id)
            }),
            dataIndex: 'electrical_conductivity',
            key: 'electrical_conductivity',
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header organic_substances'),
            width: 75,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'organic_substances',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'organic_substances', !record?.id)
            }),
            dataIndex: 'organic_substances',
            key: 'organic_substances',
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        Зона
                    </Row>
                    <Row>
                        Продуктивности
                    </Row>
                </Col>
            ),
            width: 150,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'productivity_zone',
                inputType: 'select',
                selectOptions: [
                    {
                        label: t('productivity zone low'),
                        value: 'low'
                    },
                    {
                        label: t('productivity zone middle'),
                        value: 'middle'
                    },
                    {
                        label: t('productivity zone high'),
                        value: 'high'
                    },
                    {
                        label: t('productivity zone not defined'),
                        value: 'undefined'
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'productivity_zone', !record?.id)
            }),
            dataIndex: 'productivity_zone',
            key: 'productivity_zone',
            render: (val, record) => {
                let childrenVal;
                if (val) {
                    childrenVal = (
                        <>
                            <div className="productivity_indicator">
                                {val === 'low' && (
                                    <div className={`productivity_indicator__${val}`}>
                                        <StatusLowCircle style={{ width: '6px', height: '6px' }} />
                                    </div>
                                )}
                                {val === 'middle' && (
                                    <div className={`productivity_indicator__${val}`}>
                                        <StatusMediumCircle style={{ width: '6px', height: '6px' }} />
                                    </div>
                                )}
                                {val === 'high' && (
                                    <div className={`productivity_indicator__${val}`}>
                                        <StatusHighCircle style={{ width: '6px', height: '6px' }} />
                                    </div>
                                )}
                                <div>
                                    { t(`productivity zone ${val}`) }
                                </div>
                            </div>
                        </>
                    );
                } else {
                    childrenVal = (
                        <></>
                    );
                }

                return {
                    props: {
                        style: record?.deleted ? {} : { background: 'rgba(31, 205, 117, 0.15)' }
                    },
                    children: childrenVal
                };
            },
            sorter: (a, b) => zonesSorter[a.productivity_zone] - zonesSorter[b.productivity_zone]
        },
        {
            title: () => (
                <Col>
                    <Row>
                        Площадь точки
                    </Row>
                    <Row>
                        га
                    </Row>
                </Col>
            ),
            width: 140,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'zone_area',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'zone_area', !record?.id)
            }),
            dataIndex: 'zone_area',
            key: 'zone_area',
            render: (val, record) => {
                return {
                    props: {
                        style: record?.deleted ? {} : { background: 'rgba(31, 205, 117, 0.15)' }
                    },
                    children: tooltipedValue(val)
                };
            },
            sorter: (a, b) => a.zone_area - b.zone_area
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header NO3')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 80,
            ellipsis: true,
            dataIndex: 'NO3',
            key: 'NO3',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'NO3',
                inputType: 'text',
                record,
                editable: !record?.from_file ? true : false,
                handleSave: (record) => handleSave(record, 'NO3', !record?.id)
            }),
            render: (val) => tooltipedValue(toFixed(val, 3))
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header P2O5')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 80,
            ellipsis: true,
            dataIndex: 'P2O5',
            key: 'P2O5',
            onCell: (record) => ({
                dataIndex: 'P2O5',
                inputType: 'text',
                record,
                editable: !record?.from_file ? true : false,
                handleSave: (record) => handleSave(record, 'P2O5', !record?.id)
            }),
            className: "filed-column",
            render: (val) => tooltipedValue(toFixed(val, 3))
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header K20')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'K20_result',
            key: 'K20',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'K20_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'K20_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header S')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 80,
            ellipsis: true,
            dataIndex: 'S',
            key: 'S',
            onCell: (record) => ({
                dataIndex: 'S',
                inputType: 'text',
                record,
                editable: !record?.from_file ? true : false,
                handleSave: (record) => handleSave(record, 'S', !record?.id)
            }),
            className: "filed-column",
            render: (val) => tooltipedValue(toFixed(val, 3))
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Ca')}
                    </Row>
                    <Row>
                        мг-экв/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'Ca_result',
            key: 'Ca',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Ca_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Ca_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Mg')}
                    </Row>
                    <Row>
                        мг-экв/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'Mg_result',
            key: 'Mg',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Mg_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Mg_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Na')}
                    </Row>
                    <Row>
                        ммоль/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'Na_result',
            key: 'Na',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Na_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Na_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header NH4')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'NH4_result',
            key: 'NH4',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'NH4_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'NH4_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header PO4')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'PO4_result',
            key: 'PO4',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'PO4_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'PO4_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header SO4')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'SO4_result',
            key: 'SO4',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'SO4_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'SO4_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Cl')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'Cl_result',
            key: 'Cl',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Cl_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Cl_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header N')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            width: 75,
            ellipsis: true,
            dataIndex: 'N_result',
            key: 'N',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'N_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'N_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header comment'),
            width: 140,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'comment',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'comment', !record?.id)
            }),
            dataIndex: 'comment',
            key: 'comment',
            render: (val) => tooltipedValue(val)
        }
    ]);
    

    const EditableContext = React.createContext(null);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    useEffect(() => {
        if (fullUsersList?.length > 0 && columnsArray?.length > 0) {
            const updatedColumns = columnsArray?.map(column => {
                if (column?.dataIndex === 'owner') {
                    return {
                        ...column,
                        render: (val) => {
                            return tooltipedValue(fullUsersList?.filter(x => parseInt(x?.id, 10) === parseInt(val, 10))[0]?.name || val);
                        },
                        onCell: (record) => ({
                            inputType: 'select',
                            dataIndex: 'owner',
                            selectOptions: fullUsersList?.map(usr => {
                                return {
                                    label: usr?.name,
                                    value: usr?.id
                                };
                            }),
                            record,
                            editable: true,
                            handleSave: (record) => handleSave(record, 'owner', !record?.id)
                        })
                    };
                }

                return column;
            });
            
            setColumnsArray(updatedColumns);
        }
    }, [fullUsersList]);

    const handleVegaSelect = (fieldName, fieldsMapping, record, isNewRecord) => {
        const selectedFieldNum = fieldsMapping[fieldName];
        if (isNewRecord) {
            setValueToNew('field_num', selectedFieldNum);
        }
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        inputType,
        selectOptions,
        ...restProps
    }) => {
        let childNode = children;

        const isHighlightedCell = record?.type === 'new record' && (dataIndex === 'num' || dataIndex === 'owner' || dataIndex === 'point');

        const [fieldNameMapping, setFieldNameMapping] = useState({});
        const [fieldedUser, setFieldedUser] = useState(null);
        const [fieldOptns, setFieldOptns] = useState([]);
        const [fieldOptionPage, setFieldOptionPage] = useState(1);
        const [inputWidth, setInputWidth] = useState(50);
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        const [reserveOptions, setReserveOptions] = useState([]);
        const [nextString, setNextString] = useState(null);
        const [additionalFields, setAdditionalFields] = useState([]);
        const [fieldsLoading, setFieldsLoading] = useState(false);

        const usrFields = useSelector(state => getCalculatorsMordoviaObject(state));

        useEffect(() => {
            if (fieldOptns?.length > 0 && additionalFields?.length > 0) {
                const varCpy = [...fieldOptns];
                additionalFields?.forEach(addFld => {
                    const srcRes = fieldOptns?.filter(x => x.label === addFld?.label);

                    if (srcRes?.length < 1) {
                        varCpy.push(addFld);
                    }
                });
                setAdditionalFields([]);
                setFieldOptns(varCpy);
            }
        }, [fieldOptns, additionalFields]);
        
        useEffect(() => {
            if (fieldOptns?.length > 0 && nextString?.length > 0) {
                setFieldOptionPage(fieldOptionPage + 1);
            }
        }, [fieldOptns, nextString]);
        
        useEffect(() => {
            if (fieldOptionPage > 1) {
                const ownerId = record?.owner;

                if (ownerId === fieldedUser) {
                    const urlArr = [`"owner_user":${ownerId}`];
                    dispatch(getCalculatorsMordovia(`?filter={${urlArr.join(',')}}&page=${fieldOptionPage}`));
                }

                // setFieldedUser(ownerId);
            }
        }, [fieldOptionPage]);

        useEffect(() => {
            const ownerId = record?.owner;

            if (ownerId === fieldedUser) {
                const { results, next } = usrFields;

                setNextString(next);

                if (next?.length < 1 || !next) {
                    setFieldsLoading(false);
                }

                if (results?.length > 0) {
                    const fieldsCopy = fieldOptns;

                    const copiedMapping = fieldNameMapping;
                    
                    results?.forEach(fieldEntity => {
                        copiedMapping[fieldEntity?.vega_key] = fieldEntity?.field_num;

                        const resObj = {
                            label: fieldEntity?.vega_key,
                            value: fieldEntity?.vega_key
                        };

                        const srcRes = fieldOptns?.filter(x => x?.value === resObj?.value);

                        if (srcRes?.length < 1) {
                            fieldsCopy.push(resObj);
                        }
                    });

                    setFieldNameMapping(copiedMapping);

                    if (fieldOptionPage === 1) {
                        setFieldOptns(fieldsCopy);
                    } else {
                        setAdditionalFields(fieldsCopy);
                    }
                }
            }
        }, [usrFields]);

        useEffect(() => {
            if (editing) {
                inputRef?.current?.focus();
            }
        }, [editing]);

        const getFilteredFieldsList = (userId, pageNum) => {
            const urlArr = [`"owner_user":${userId}`];
            dispatch(getCalculatorsMordovia(`?filter={${urlArr.join(',')}}&page=${pageNum}`));
        }

        const toggleEdit = (isOpening = false) => {
            if (record?.public) {return};
            const ownerId = record?.owner;

            if (dataIndex === 'vega_key' && ownerId && ownerId > 0) {
                if (ownerId !== fieldedUser) {
                    setFieldsLoading(true);
                    getFilteredFieldsList(ownerId, 1);
                }

                setFieldedUser(ownerId);
            }

            if (dataIndex === 'vega_key') {
                if (!editing) {
                    setFieldOptionPage(1);
                } else {
                    setFieldOptionPage(0);
                }
            }

            if (!editing && dataIndex === 'owner') {
                setEditing(!editing);
                setReserveOptions(fullUsersList?.map(usr => {
                    return {
                        label: usr?.name,
                        value: usr?.id
                    };
                }));
                form.setFieldsValue({
                    [dataIndex]: fullUsersList?.filter(x => x.id === record[dataIndex]) ? fullUsersList?.filter(x => x.id === record[dataIndex])[0]?.name : ''
                });
            } else {
                setEditing(!editing);
                if (!((dataIndex === 'date' || dataIndex === 'analysis_date') && isOpening)) {
                    form.setFieldsValue({
                        [dataIndex]: record[dataIndex]
                    });
                }
            }
        };

        const save = (newVals, isDate = false, isVegaKey = false, vegaMappings = {}) => {
            const dateSplit = isDate ? newVals?.split('.') : [];
            const dateAlternativeSplit = isDate ? newVals?.split('-') : [];
            const numberSplit = (typeof newVals === 'string' && !isNaN(newVals?.replace(/,/g, '.'))) ? newVals?.split(',') : [];
            toggleEdit();
            const recordToSave = { ...record };
            if (isDate) {
                if (isDate && dateSplit?.length !== 3 && dateAlternativeSplit?.length !== 3) {
                    notification.warning({
                        message: 'Неверный формат',
                        description: 'При форматировании даты возникла ошибка'
                    });
                } else if (dateSplit?.length === 3) {
                    recordToSave.valueToUpdateNow = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
                } else if (dateAlternativeSplit?.length === 3) {
                    recordToSave.valueToUpdateNow = `${dateAlternativeSplit[0]}-${dateAlternativeSplit[1]}-${dateAlternativeSplit[2]}`;
                }
            } else {
                recordToSave.valueToUpdateNow = numberSplit?.length > 1 ? newVals.replace(/,/g, '.') : newVals;
            }

            if (isVegaKey) {
                const selectedFieldName = vegaMappings[recordToSave.valueToUpdateNow];
                recordToSave.field_num = selectedFieldName;
            }
            handleSave(recordToSave);
        };

        const handleInputChange = (e) => {
            if (e?.target?.value?.length === 0) {
                setInputWidth(50);
            }
            if (inputWidth <= 100) {
                setInputWidth(75 + e?.target?.value?.length * 5);
            }
        };

        console.log('HERE 321', dataIndex)

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`
                        }
                    ]}>
                    {inputType === 'text' ? (
                        <Input
                            disabled={record?.public}
                            onChange={handleInputChange}
                            style={{ width: inputWidth }}
                            ref={inputRef}
                            onPressEnter={(evt) => save(evt.target.value, (dataIndex === 'date' || dataIndex === 'analysis_date'))}
                            onBlur={(evt) => save(evt.target.value, (dataIndex === 'date' || dataIndex === 'analysis_date'))} />
                    ) : dataIndex === 'vega_key' ? (
                        <Select
                            disabled={record?.public}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                            dropdownStyle={{
                                position: 'relative',
                                zIndex: '999999999999999999999'
                            }}
                            options={fieldOptns?.length > 0 ? [...fieldOptns] : [...selectOptions]}
                            ref={inputRef}
                            onBlur={toggleEdit}
                            onSelect={(val) => save(val, false, true, fieldNameMapping)}
                            onChange={(evt) => handleVegaSelect(evt, fieldNameMapping, record, record?.type === 'new record')} />
                    ) : dataIndex === 'owner' ? (
                        <Select
                            disabled={record?.public}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                            dropdownStyle={{
                                position: 'relative',
                                zIndex: '999999999999999999999'
                            }}
                            options={[...selectOptions]}
                            ref={inputRef}
                            // onBlur={toggleEdit}
                            onSelect={(val) => save(val)} />
                    ) : (
                        <Select
                            disabled={record?.public}
                            dropdownStyle={{
                                position: 'relative',
                                zIndex: '999999999999999999999'
                            }}
                            options={selectOptions?.length > 0 ? [...selectOptions] : [...reserveOptions]}
                            ref={inputRef}
                            onBlur={toggleEdit}
                            onSelect={(val) => save(val)} />
                    )}
                </Form.Item>
            ) : (
                <div
                    style={{
                        cursor: 'pointer',
                        padding: '16px 16px'
                    }}
                    onClick={() => {
                        if (dataIndex === 'date' || dataIndex === 'analysis_date') {
                            toggleEdit(true);
                        } else {
                            toggleEdit();
                        }
                    }}>
                    <div
                        className={isHighlightedCell ? 'highlighted-cell' : 'editable-cell-value-wrap'}
                        style={{
                            paddingRight: 24
                        }}>
                        {children}
                    </div>
                </div>
            );
        }

        if (editable && (
            dataIndex === 'P2O5' || dataIndex === 'NO3' || dataIndex === 'S'
        )) {
            console.log('HERE 123')
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`
                        }
                    ]}>
                    <InputNumber min={0} decimalSeparator="," />
                </Form.Item>
            ) : (
                <div
                    style={{
                        cursor: 'pointer',
                        padding: '16px 16px'
                    }}
                    onClick={() => {
                        if (dataIndex === 'date' || dataIndex === 'analysis_date') {
                            toggleEdit(true);
                        } else {
                            toggleEdit();
                        }
                    }}>
                    <div
                        className={isHighlightedCell ? 'highlighted-cell' : 'editable-cell-value-wrap'}
                        style={{
                            paddingRight: 24
                        }}>
                        {children}
                    </div>
                </div>
            );
        }

        return (
            <td {...restProps} style={editable ? { padding: '0' } : {}}>
                {dataIndex === 'vega_key' ? fieldsLoading ? (<Spin />) : childNode  : childNode}
            </td>
        );
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    };

    const handleRowSelectionChange = (ids, records) => {
        const deletedIds = tableData?.filter(x => x?.deleted === true)?.map(y => y.id);
        setSelectedRecords(records);
        selectRows(ids.filter((rowId) => !deletedIds.includes(rowId)));
    };

    const getCheckProp = (record) => {
        return {
            disabled: record?.deleted
        };
    };

    const getSelectedRows = () => [...selectedRows];

    const tooltipedValue = (val, isDate = false) => {
        let returnValue;
        if (val === null) {
            returnValue = (<div>{ val }</div>);
        }

        let answerRes = val;

        if (isDate) {
            const dateStr = val?.split('T')[0];
            const dateArray = dateStr ? dateStr.split('-') : [];
            answerRes = dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : val;
        }

        returnValue = (
            <Tooltip title={answerRes}>
                <span>{ answerRes }</span>
            </Tooltip>
        );
        return [returnValue];
    };

    useEffect(() => {
        if (userCreated?.id > 0 && userCreated?.name?.length > 0) {
            setFullUsersList([...fullUsersList, userCreated]);
        }
    }, [userCreated]);

    useEffect(() => {
        const reqBody = {
            page: usersPage
        };
        
        dispatch(getUsersList(reqBody));
    }, [usersPage]);

    useEffect(() => {
        const newUsersPortion = usersNew?.results;
        if (newUsersPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...newUsersPortion]);
        }
        const isNext = usersNew?.next?.length > 0;
        if (isNext) {
            setUsersPage(usersPage + 1);
        }
    }, [usersNew]);

    const rowSelection = useMemo(() => {
        const undeletedRows = tableData?.filter(x => x?.deleted !== true);
        if (tableData?.length <= 0 || undeletedRows?.length <= 0) {
            return {
                getCheckboxProps: getCheckProp,
                getSelectedRows,
                preserveSelectedRowKeys: true,
                hideSelectAll: true,
                onChange: handleRowSelectionChange
            };
        }

        return {
            getCheckboxProps: getCheckProp,
            getSelectedRows,
            preserveSelectedRowKeys: true,
            hideSelectAll: true,
            onChange: handleRowSelectionChange
        };
    }, [tableData]);

    const [reservedColumns, setReservedColumns] = useState([]);
    
    
    useEffect(() => {
        let resultingArray = reservedColumns?.filter(resCol => {
            const findingColumn = activeColumnOptions?.filter(actCol => actCol?.key === resCol?.key);
            const findingAlternative = activeColumnOptions?.filter(actCol => actCol?.value === resCol?.key || actCol?.value === resCol?.dataIndex);
            return (findingColumn && findingColumn?.length > 0) || (findingAlternative && findingAlternative?.length > 0);
        });
        setColumnsArray(resultingArray);
    }, [activeColumnOptions]);

    useEffect(() => {
        setUserList && setUserList(fullUsersList);
        fullUsersList?.length > 0 && fullUsersList.map(user => {
            const userDictCopy = usersDictionary;
            userDictCopy[user.id] = user.name;
            setUsersDictionary(userDictCopy);
        });
    }, [fullUsersList]);

    const setValueToNew = (dataIndex, newValue) => {
        if (dataIndex === 'K') {
            setSelectedK(newValue);
        }
        if (dataIndex === 'A') {
            setSelectedA(newValue);
        }
        if (dataIndex === 'method') {
            setSelectedMethod(newValue);
        }
        if (dataIndex === 'ph_water') {
            setSelectedPhWater(newValue);
        }
        if (dataIndex === 'ph_salt') {
            setSelectedPhSalt(newValue);
        }
        if (dataIndex === 'electrical_conductivity') {
            setSelectedElectricalConductivity(newValue);
        }
        if (dataIndex === 'organic_substances') {
            setSelectedOrganicSubstances(newValue);
        }
        if (dataIndex === 'productivity_zone') {
            setSelectedProductivityZone(newValue);
        }
        if (dataIndex === 'zone_area') {
            setSelectedProductivityZoneArea(newValue);
        }
        if (dataIndex === 'date') {
            setSelectedDate(newValue);
        }
        if (dataIndex === 'analysis_date') {
            setSelectedKapelDate(newValue);
        }
        if (dataIndex === 'field_num') {
            setSelectedFNum(newValue);
        }
        if (dataIndex === 'NH4') {
            setSelectedNH4(newValue);
        }
        if (dataIndex === 'K20') {
            setSelectedK2O(newValue);
        }
        if (dataIndex === 'Na') {
            setSelectedNa(newValue);
        }
        if (dataIndex === 'Mg') {
            setSelectedMg(newValue);
        }
        if (dataIndex === 'Ca') {
            setSelectedCa(newValue);
        }
        if (dataIndex === 'Cl') {
            setSelectedCl(newValue);
        }
        if (dataIndex === 'SO4') {
            setSelectedSO4(newValue);
        }
        if (dataIndex === 'N') {
            setSelectedN(newValue);
        }
        if (dataIndex === 'PO4') {
            setSelectedPO4(newValue);
        }
    };

    useEffect(() => {
        if (
            selectedNH4 || selectedK2O || selectedNa || selectedMg || selectedCa || selectedCl || selectedSO4 || selectedN || selectedPO4 || selectedA || selectedK || selectedMethod || selectedPhSalt || selectedPhWater || selectedElectricalConductivity || selectedOrganicSubstances || selectedProductivityZone || selectedProductivityZoneArea || selectedDate || selectedKapelDate || selectedFNum
        ) {
            setNewRecordEntity([{
                type: 'new record',
                num: newPNum,
                owner: newPOwner,
                point: newPField,
                vega_key: newPFieldName,
                zone_area: selectedProductivityZoneArea,
                productivity_zone: selectedProductivityZone,
                organic_substances: selectedOrganicSubstances,
                electrical_conductivity: selectedElectricalConductivity,
                ph_salt: selectedPhSalt,
                ph_water: selectedPhWater,
                method: selectedMethod,
                A: selectedA,
                K: selectedK,
                date: selectedDate,
                analysis_date: selectedKapelDate,
                field_num: selectedFNum,
                //
                NH4: selectedNH4,
                K20: selectedK2O,
                Na: selectedNa,
                Mg: selectedMg,
                Ca: selectedCa,
                Cl: selectedCl,
                SO4: selectedSO4,
                N: selectedN,
                PO4: selectedPO4
            }]);
        }
    }, [
        selectedA, selectedK, selectedMethod, selectedPhSalt, selectedPhWater,
        selectedElectricalConductivity, selectedOrganicSubstances,
        selectedProductivityZone, selectedProductivityZoneArea,
        selectedDate, selectedKapelDate, selectedFNum,
        selectedNH4, selectedK2O, selectedNa, selectedMg, selectedCa,
        selectedCl, selectedSO4, selectedN, selectedPO4
    ]);

    useEffect(() => {
        if (newPOwner > 0 || newPField?.length > 0  || newPNum?.length > 0 || newPFieldName?.length > 0) {
            setNewRecordEntity([{
                type: 'new record',
                //
                NH4_result: selectedNH4,
                K20_result: selectedK2O,
                Na_result: selectedNa,
                Mg_result: selectedMg,
                Ca_result: selectedCa,
                Cl_result: selectedCl,
                SO4_result: selectedSO4,
                N_result: selectedN,
                PO4_result: selectedPO4,
                //
                num: newPNum,
                owner: newPOwner,
                point: newPField,
                vega_key: newPFieldName,
                zone_area: selectedProductivityZoneArea,
                productivity_zone: selectedProductivityZone,
                organic_substances: selectedOrganicSubstances,
                electrical_conductivity: selectedElectricalConductivity,
                ph_salt: selectedPhSalt,
                ph_water: selectedPhWater,
                method: selectedMethod,
                A: selectedA,
                K: selectedK,
                date: moment(selectedDate ? selectedDate : null).format('YYYY-MM-DD').split('T')[0],
                analysis_date: moment(selectedKapelDate ? selectedKapelDate : null).format('YYYY-MM-DD').split('T')[0],
                field_num: selectedFNum
            }]);
        }

        if (
            newRecordEntity && newRecordEntity[0] && newRecordEntity?.length > 0 && Object.keys(newRecordEntity[0])?.length > 0 && newPField?.length > 0 && newPOwner > 0 && newPNum?.length > 0
            // && newPFieldName?.length > 0
            ) {
            dispatch(createSampleRow({
                num: newPNum,
                owner: parseInt(newPOwner, 10),
                point: newPField,
                vega_key: newPFieldName,
                zone_area: selectedProductivityZoneArea,
                productivity_zone: selectedProductivityZone,
                organic_substances: selectedOrganicSubstances,
                electrical_conductivity: selectedElectricalConductivity,
                ph_salt: selectedPhSalt,
                ph_water: selectedPhWater,
                method: selectedMethod,
                A: selectedA,
                K: selectedK,
                date: selectedDate,
                analysis_date: selectedKapelDate,
                field_num: selectedFNum,
                //
                NH4: selectedNH4,
                K20: selectedK2O,
                Na: selectedNa,
                Mg: selectedMg,
                Ca: selectedCa,
                Cl: selectedCl,
                SO4: selectedSO4,
                N: selectedN,
                PO4: selectedPO4,
                //
                from_file: false
            }));
            setNewRecordEntity([]);
            setNewPOwner('');
            setNewPField('');
            setNewPNum('');
            setNewPFieldName('');
            setSelectedK(null);
            setSelectedA(null);
            setSelectedMethod(null);
            setSelectedPhWater(null);
            setSelectedPhSalt(null);
            setSelectedElectricalConductivity(null);
            setSelectedOrganicSubstances(null);
            setSelectedProductivityZone(null);
            setSelectedProductivityZoneArea(null);
            setSelectedDate(null);
            setSelectedKapelDate(null);
            setSelectedFNum(null);
            //
            setSelectedNH4(null);
            setSelectedK2O(null);
            setSelectedNa(null);
            setSelectedMg(null);
            setSelectedCa(null);
            setSelectedCl(null);
            setSelectedSO4(null);
            setSelectedN(null);
            setSelectedPO4(null);
            //
        }
    }, [
        newPNum, newPOwner, newPField,
        // newPFieldName
    ]);

    useEffect(() => {
        if (reservedColumns?.length < columnsArray?.length && columnsArray?.length > 0) {
            setReservedColumns([...columnsArray]);
        }

        if (activeColumnOptions?.length === 0 && columnsArray?.length > 0) {
            setActiveColumnOptions([...columnsArray].map(x => {
                if (typeof x?.title !== 'string') {
                    if (typeof x?.title === 'function') {
                        const resName = x.title();
                        const isAnswer = resName?.props?.children?.map(x => x?.props?.children)?.join(', ');
                        return {
                            label: isAnswer,
                            value: x?.dataIndex
                        };
                    }
                }
                return {
                    label: x?.title,
                    value: x?.dataIndex
                };
            }));
        }
    }, [columnsArray]);

    useEffect(() => {
        // setTableDataState([...tableData, ...newRecordEntity]);
        setTableDataState([...newRecordEntity, ...tableData]);
    }, [tableData]);

    useEffect(() => {
        // setTableDataState([...tableData, ...newRecordEntity]);
        setTableDataState([...newRecordEntity, ...tableData]);
    }, [newRecordEntity]);

    const addNewOwner = () => {
        if (isFullScreen) {
            exitFullScreen();
        }
        setIsNewUserModalOpen(true);
    };

    const onPaginationChange = (page, newPageSize) => {
        setCurrentPage(page);
        if (newPageSize !== pageSize) {
            setPageSize(newPageSize);
        }
    };
    
    return (
        <>
            <Table
                className={isFullScreen ? 'full-screen-table' : ''}
                bordered
                rowKey="id"
                rowSelection={rowSelection}
                columns={activeColumnOptions?.length <= 0 ? [] : columnsArray?.length > 0 ? columnsArray : reservedColumns}
                style={{ whiteSpace: 'pre' }}
                dataSource={tableDataState}
                components={components}
                pagination={{
                    total: dataLength,
                    onChange: onPaginationChange,
                    current: currentPage,
                    pageSize: pageSize,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '25', '50', '100']
                }}
                rowClassName={record => {
                    return (!record?.from_file || !Object.keys(record).includes('from_file')) ? 'editable-row': 'editable-row disabled-row'
                }}
                scroll={{ y: 550, x: 'fit-content' }}
                footer={() => (
                    <Row>
                        <Col style={{ height: '100%' }} span={4}>
                            <Button
                                style={{ height: '100%' }}
                                onClick={addNewOwner}
                                type="secondary"
                                className="buttons-row__bottom__button">
                                <PlusCircleOutlined />
                                {t('add owner user')}
                            </Button>
                        </Col>
                    </Row>
                )} />
        </>
    );
};

export default SampleTable;