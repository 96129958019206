import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import './style.less';
import { toFixed } from '../../../../../utils/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const statusColor = {
    veryhigh: '#27AE60',
    high: '#6FCF97',
    optimal: '#D9D9D9',
    low: '#FF7E77',
    verylow: '#FF4D4F'
};

const Chart = ({ chartData }) => {

    const { t } = useTranslation('leaf diagnostics');

    const pluginList = [
        {
            id: 'barPlugin',
            beforeDraw: function (chart) {
                if (chart.chartArea) {
                    const { ctx } = chart;

                    const barArray = [
                        ...chart.getDatasetMeta(0).data,
                        ...chart.getDatasetMeta(1).data,
                        ...chart.getDatasetMeta(2).data,
                        ...chart.getDatasetMeta(3).data,
                        ...chart.getDatasetMeta(4).data
                    ];

                    for (let i = 0; i < barArray.length; i++) {
                        const {
                            x, width, height, borderSkipped, base
                        } = barArray[i];

                        const borderKey = Object.keys(borderSkipped)[0];

                        let labelPosition;
                        let numPosition;

                        if (borderKey === 'top') {
                            labelPosition = base + height + 10;
                            numPosition = base - 10;
                        } else if (borderKey === 'bottom') {
                            labelPosition = base - height - 10;
                            numPosition = base + 10;
                        }

                        const dataArray = [];
                        chart?.config?.data?.datasets?.forEach(dtst => {
                            dtst?.data?.forEach(itm => {
                                dataArray.push(itm.y);
                            });
                        });

                        const numberValue = toFixed(dataArray[i], 0);

                        ctx.textAlign = 'start';

                        ctx.fillText(
                            numberValue,
                            numberValue > 10 ? (x - (width / 4)) : (x - (width / 8)),
                            numPosition
                        );
                    }
                }
            }
        }
    ];

    const generateBottomBorder = (deviationArr) => {
        const minValue = Math.min(...deviationArr);
        // if (Math.min(...deviationArr) < -50) {
        //     return Math.min(...deviationArr) - 10;
        // }
        //
        // return -50;

        const remainder = minValue % -10;

        if (remainder === 0) {
            return minValue - 10;
        }

        return minValue - (10 + remainder);
    };

    const generateTopBorder = (deviationArr) => {
        const maxValue = Math.max(...deviationArr);
        // if (Math.max(...deviationArr) > 50) {
        //     return Math.max(...deviationArr) + 10;
        // }
        //
        // return 50;

        const remainder = maxValue % 10;

        if (remainder === 0) {
            return maxValue + 10;
        }

        return maxValue + (10 - remainder);
    };

    const options = {
        responsive: true,
        grouped: false,
        plugins: {
            legend: {
                position: 'top',
                align: 'end'
            },
            title: {
                display: true,
                text: t('chart title'),
                align: 'start'
            },
            tooltip: {
                enabled: false
            }
        },
        maintainAspectRatio: true,
        scales: {
            y: {
                max: generateTopBorder(chartData.map(x => x?.deviation)),
                min: generateBottomBorder(chartData.map(x => x?.deviation)),
                ticks: {
                    callback: (val) => `${val}%`
                }
            },
            x: {
                ticks: {
                    // display: false
                }
            }
        }
    };

    const labels = [
        'n', 'p', 'ks', 'kcl',
        'ca', 'mg', 'b', 'cu',
        'zn', 'mn', 'fe',
        'mo', 'co', 'i'
    ].map(x => t(`chart element ${x}`));

    const chartDataMemo = useMemo(() => {
        return {
            veryhigh: chartData.filter(x => x?.status === 'veryhigh').map(y => {
                return {
                    x: t(`chart element ${y.name}`),
                    y: y?.deviation
                };
            }),
            high: chartData.filter(x => x?.status === 'high').map(y => {
                return {
                    x: t(`chart element ${y.name}`),
                    y: y?.deviation
                };
            }),
            optimal: chartData.filter(x => x?.status === 'optimal').map(y => {
                return {
                    x: t(`chart element ${y.name}`),
                    y: y?.deviation
                };
            }),
            low: chartData.filter(x => x?.status === 'low').map(y => {
                return {
                    x: t(`chart element ${y.name}`),
                    y: y?.deviation
                };
            }),
            verylow: chartData.filter(x => x?.status === 'verylow').map(y => {
                return {
                    x: t(`chart element ${y.name}`),
                    y: y?.deviation
                };
            })
        };
    }, [chartData]);

    const data = {
        labels,
        datasets: [
            {
                label: t('chloroplast status veryhigh'),
                data: chartDataMemo.veryhigh,
                backgroundColor: statusColor.veryhigh,
                borderColor: statusColor.veryhigh
            },
            {
                label: t('chloroplast status high'),
                data: chartDataMemo.high,
                backgroundColor: statusColor.high,
                borderColor: statusColor.high
            },
            {
                label: t('chloroplast status optimal'),
                data: chartDataMemo.optimal,
                backgroundColor: statusColor.optimal,
                borderColor: statusColor.optimal
            },
            {
                label: t('chloroplast status low'),
                data: chartDataMemo.low,
                backgroundColor: statusColor.low,
                borderColor: statusColor.low
            },
            {
                label: t('chloroplast status verylow'),
                data: chartDataMemo.verylow,
                backgroundColor: statusColor.verylow,
                borderColor: statusColor.verylow
            }
        ]
    };

    return (
        <Bar
            className="diagnostics__chart"
            height={100}
            options={options}
            plugins={pluginList}
            data={data} />
    );
};

export default Chart;