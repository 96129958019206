import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';

import cn from '../../utils/cn';

import './style.less';

const { RangePicker: AntRangePicker } = DatePicker;

@cn('range-picker-filter')
export default class RangePicker extends Component {
    render() {
        const { cn } = this;
        const {
            t,
            className: propClass,
            label,
            ...rest
        } = this.props;
        const className = `${cn()} ${propClass}`;

        return (
            <div className={className}>
                <span className={cn('label')}>{label}</span>
                <AntRangePicker {...rest} />
            </div>
        );
    }
}

RangePicker.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string
};

RangePicker.defaultProps = {
    className: '',
    label: ''
};
