import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Layout } from 'antd';

import { useClassName } from 'utils/cn';
import { SideBar } from '../../components';


import './style.less';
import CalculatorDetail from './components/calculator-detail';
import { actions } from '../../models';
import { isAuth } from '../../models/account/selectors';

const {
    getCalculatorMordovia
} = actions;

export default () => {
    const cn = useClassName('all-calculators');
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const auth = useSelector(state => isAuth(state));

    useEffect(() => {
        const { id, year } = match.params;
        if (id) {
            dispatch(getCalculatorMordovia({ id, year }));
        }
    }, []);


    return (
        <>
            {!auth && <SideBar isMordovia username={match.params.id} />}
            <Layout className={cn()}>
                <CalculatorDetail vegaKey={match.params.id} />
            </Layout>
        </>
    );
};
