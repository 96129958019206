import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Rate, Row, Col, Icon, Collapse, Form, Input, Select
} from 'antd';
import { Button } from '../../../components';


import { regions } from '../../../regions';
import cn from '../../../utils/cn';
import '../style.less';
import { selectors, actions } from '../../../models';
import { object2Form } from '../../../utils/form';

const { TextArea } = Input;
const {
    getPesticideMessages
} = actions;
const {
    getCurrentPesticide
} = selectors;

const mstp = (state) => ({
    pesticide: getCurrentPesticide(state)
});
const mdtp = ({
    getPesticideMessages
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('pesticide review')
@cn('pesticide-detail')
export default class PesticideReview extends Component {
    componentDidMount() {
        const { match } = this.props;
        const { id } = match.params;
    }

    onSubmit = (e, form) => {
        const { addReview, id } = this.props;
        const { validateFields } = form;

        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                !id && addReview(values);
            } else {
                console.warn(err);
            }
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    onChange = (e, form) => {
        this.setState(e);
    };

    initForm = () => {
        const { id } = this.props;
        const { formInit } = this.state;

        id && formInit(object2Form({}));
    };

    get years() {
        const arr = [];
        for (let i = 2000; i <= 2020; i++) {
            arr.push({ value: i, title: i });
        }
        return arr;
    }

    goBack = () => {
        const { history } = this.props;
        history.push('/pesticides');
    };

    render() {
        const { cn } = this;
        const {
            t, pesticide
        } = this.props;
        if (!pesticide) return <div />;
        return (

            <div className={cn()}>
                <span className={cn('back')} onClick={this.goBack}>
                    {t('back')}
                </span>
                <div className={cn('content')}>
                    <div className={cn('title')}>
                        {pesticide.title}
                    </div>
                    <Form onSubmit={this.onSubmit}
                        onMount={this.onMount}
                        className={cn('review')}
                        name="review-form">
                        <Row
                            gutter={20}
                            style={{ marginBottom: '20px', marginTop: '45px' }}
                            className={cn('ant-row')}>
                            <Col span={6}>
                                {t('common rate')}
                            </Col>
                            <Col span={6}>
                                <Rate allowHalf defaultValue={0} />
                            </Col>
                        </Row>
                        <Row
                            gutter={20}
                            style={{ marginBottom: '20px' }}
                            className={cn('ant-row')}>
                            <Col span={6}>
                                {t('year')}
                            </Col>
                            <Col span={6}>
                                <SelectField
                                    name="year"
                                    isRequired
                                    placeholder={t('select')}
                                    items={this.years} />
                            </Col>
                        </Row>
                        <Row
                            gutter={20}
                            style={{ marginBottom: '20px' }}
                            className={cn('ant-row')}>
                            <Col span={6}>
                                {t('steps')}
                            </Col>
                            <Col span={6}>
                                <SelectField
                                    name="steps"
                                    isRequired
                                    placeholder={t('select')}
                                    mode="multiple"
                                    items={[
                                        { value: 'autumn', title: t('autumn') },
                                        { value: 'spring', title: t('spring') },
                                        { value: 'first', title: t('first') },
                                        { value: 'second', title: t('second') }
                                    ]} />
                            </Col>
                        </Row>
                        <Row
                            gutter={20}
                            style={{ marginBottom: '20px' }}
                            className={cn('ant-row')}>
                            <Col span={6}>
                                {t('region')}
                            </Col>
                            <Col span={6}>
                                <SelectField
                                    name="region"
                                    isRequired
                                    placeholder={t('select')}
                                    items={
                                        regions.map(region =>
                                            ({ value: region.code, title: region.name }))
                                    } />
                            </Col>
                        </Row>
                        <div className={cn('title')}>
                            {t('more')}
                        </div>
                        <Row gutter={20} style={{ marginBottom: '20px' }}>
                            <Col span={12}>
                                <TextAreaField
                                    autoSize={{ minRows: 3 }}
                                    name="virtues"
                                    label={t('like')}
                                    isRequired
                                    placeholder={t('like placeholder')} />
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ marginBottom: '20px' }}>
                            <Col span={12}>
                                <TextAreaField
                                    autoSize={{ minRows: 3 }}
                                    name="disadvantages"
                                    label={t('dislike')}
                                    isRequired
                                    placeholder={t('dislike placeholder')} />
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ marginBottom: '20px' }}>
                            <Col span={12}>
                                <TextAreaField
                                    autoSize={{ minRows: 3 }}
                                    name="message"
                                    label={t('common comment')}
                                    isRequired
                                    placeholder={t('common comment placeholder')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className={cn('button')}>
                                <Button type="primary" htmlType="submit">{t('send')}</Button>
                                <Button type="default">{t('cancel')}</Button>
                            </Col>
                        </Row>
                    </Form>


                </div>
            </div>
        );
    }
}
