import * as constants from '../constants';
import { getCalculate, addCalculate, clearCalculate } from '../../item-calculate/constants';
import {
    getCalculatorMordovia,
    clearCalculatorMordovia,
    addGroupCalculate,
    setActiveCalculate
} from '../../mordovia/constants';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getRB.success:
        case constants.addRB.success:
        case constants.updateRB.success: {
            return action.payload;
        }

        case getCalculate.success:
        case addCalculate.success: {
            const { rb } = action.payload;
            return rb;
        }

        case clearCalculatorMordovia.toString():
        case clearCalculate.toString():
        case constants.clearRB.toString(): {
            return initialState;
        }

        case getCalculatorMordovia.success: {
            const calculator = { ...action.payload?.calculator?.rb };
            return calculator;
        }

        case addGroupCalculate.success: {
            const calculator = { ...action.payload?.calculator?.rb };
            return calculator;
        }

        case setActiveCalculate.toString(): {
            const calculator = { ...action.payload?.rb };
            return calculator;
        }

        default:
            return state;
    }
}
