import * as constants from './constants';

export const getProtocol = payload => ({
    type: constants.getProtocol.toString(),
    payload
});
export const getFedPoints = payload => ({
    type: constants.getFedPoints.toString(),
    payload
});
export const getAgroPoints = payload => ({
    type: constants.getAgroPoints.toString(),
    payload
});