import React  from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import { Row, Col } from 'antd';
import BreadCrumbs from './breadcrumbs';


export default (props) => {
    const cn = useClassName('home-page');
    const { t } = useTranslation('home');
    const { setPageType } = props;

    return (
        <div className={cn()}>
            <Row className={cn('block-auto')}>
                <BreadCrumbs setPageType={setPageType} />
            </Row>

            <Row className={cn('block-auto')} style={{ marginTop: '60px', justifyContent: 'space-around' }}>
                <img src="/images/home/icon_partner.svg" />
            </Row>
            <Row className={cn('block-auto')} style={{ marginTop: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h2>
                        Проект получил поддержку<br />
                        Фонда содействия инновациям
                    </h2>
                </Col>
            </Row>
            <Row className={cn('block-auto')}>
                <Col span={1} />
                <Col span={11} style={{fontSize:'18px', paddingRight: '12.5px'}}>
                    <p>
                        В 2020  году команда ООО "Терриус" получала поддержку от Фонда содействия инновациям  на разработку и развитие программных модулей по расчету стратегии оптимального сбалансированного минерального питания для повышения урожайности сельскохозяйственных культур.
                    </p>
                    <p>
                        Инструменты  позволяют рассчитать нормы и этапы внесения препаратов с учетом особенностей культуры и агроэкологических характеристик поля. Справочники проекта содержат более 18 000 наименований и позволяют подобрать именно те препараты, которые наиболее удобны для пользователя, но при этом соответствуют выработанным рекомендациям.
                    </p>
                    <p>
                        Разработка решения была также поддержана АНО ВО "Университетом Иннополис". В 2021 году открыта цифровая агроэкологическая лаборатория, позволяющая объединить цифровые технологии и лабораторные исследования почвы и семенного материала.
                    </p>
                </Col>

                <Col span={11} style={{fontSize:'18px', paddingLeft: '12.5px'}}>
                    <p>
                        Фонд содействия развитию малых форм предприятий в научно-технической сфере (Фонд содействия инновациям) – государственная некоммерческая организация в форме федерального государственного бюджетного учреждения, образованная в соответствии с постановлением Правительства Российской Федерации от 3 февраля 1994 г. №65.
                    </p>
                    <p>
                        Цель Фонда – поддержка инновационного бизнеса на самых ранних стадиях до организации производства и коммерциализации наукоемкой продукции.
                    </p>
                    <p>
                        Для нашей команды поддержка Фонда - означает возможность
                        воплощения идей и выход на этап масштабирования и дальнейшей развития
                        информационных инструментов диагностирования проблем
                        растениеводства, выработка решений и т.д.
                    </p>
                    <p>
                        Подробную информацию о Фонде вы можете узнать на
                        &nbsp;<a href="https://fasie.ru/" target="_blank" rel="noopener noreferrer">сайте</a>&nbsp;
                        организации.
                    </p>
                </Col>
                <Col span={1} />
            </Row>
        </div>
    );
};