import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Spin } from 'antd';

import cn from '../../utils/cn';

import { PanelLayout, SideBar, TableFooter } from '../../components';

import './style.less';
import RequestDetail from './components/request-detail';
import { actions, selectors } from '../../models';

const { Content } = Layout;

const {
    getRequest,
    clearRequest
} = actions;
const {
    getCurrentRequest
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentRequest(state)
});
const mdtp = ({
    getRequest,
    clearRequest
});

@withRouter
@connect(mstp, mdtp)
@cn('page')
export default class Request extends Component {
    state = {
        id: null,
        name: false
    };

    componentDidMount() {
        const { getRequest, match } = this.props;
        const { id } = match.params;

        if (id) {
            this.setState({ id });
            getRequest(id);
        }
    }

    render() {
        const { cn } = this;
        const { name } = this.state;
        return (
            <Layout className={cn()}>
                <RequestDetail
                    name={name} />
            </Layout>
        );
    }
}
