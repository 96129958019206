import React, { Component } from 'react';
import { translate, withTranslation } from 'react-i18next';
import {
    Modal, Row, Col, Icon
} from 'antd';
import cn from '../../utils/cn';
import QuestionCircle from '../../../public/images/question-circle.svg';
import Button from '../button';
import './style.less';

@withTranslation('save message')
@cn('save-message')
class SaveMessage extends Component {
    render() {
        const { cn } = this;
        const {
            t,
            visible,
            title,
            text,
            okBtn,
            cancelBtn,
            onCancel,
            onOk
        } = this.props;

        return (
            <Modal
                width={490}
                footer={null}
                closable={false}
                destroyOnClose
                visible={visible}
                className={cn('modal')}
                onCancel={onCancel}>
                <Row gutter={28}>
                    <Col span={3}>
                        <Icon component={QuestionCircle} />
                    </Col>
                    <Col span={21}>
                        <div className={cn('title')}>
                            {t(title)}
                        </div>
                    </Col>
                    <Col span={3} />
                    <Col span={21}>
                        <div className={cn('content')}>
                            {t(text)}
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className={cn('footer')}>
                            <Button
                                onClick={onCancel}
                                type="default">
                                {t(cancelBtn)}
                            </Button>
                            <Button
                                onClick={onOk}
                                type="primary">
                                {t(okBtn)}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        );
    }
}
export default SaveMessage;