import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';

import cn from '../../utils/cn';
import './style.less';

@cn('button')
export default class Button extends Component {
    render() {
        const { cn } = this;
        const {
            className: propClass,
            ...rest
        } = this.props;
        const className = `${cn()} ${propClass}`;

        return (
            <AntButton className={className} {...rest} />
        );
    }
}

Button.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string
};

Button.defaultProps = {
    className: '',
    type: 'primary',
    size: 'large'
};
