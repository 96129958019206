import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import Icon from '@ant-design/icons';
import {
    Row, Col, Select, InputNumber, Form, Input
} from 'antd';

import cn from '../../../utils/cn';
import {
    Tabs, CatalogTitle
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import ObjectsTable from './objects-table';

const {
    getHarmfulObjectDivisions,
    getHarmfulObjects,
    getPesticides,
    getPesticideProductApplication,
    getPesticideApplicationArea,
    getPesticideChemicalClasses,
    getPesticideHarmfulObject,
    getPesticideHazardClass,
    getPesticidePlaceOfProduction,
    getPesticideGroup,
    getPesticideMainDrug,
    getPesticidePreparativeForm,
    getPesticideTreatmentType,
    getCultures
} = actions;
const {
    currentHarmfulObjectDivisions,
    currentHarmfulObjects,
    currentHarmfulObjectsCount,
    currentHarmfulObject,
    //
    getPesticideApplicationList,
    getPesticideApplicationCount,
    getPesticideApplicationAreaList,
    getPesticideHarmfulObjectList,
    getPesticideHazardClassList,
    getPesticidePlaceOfProductionList,
    getPesticideGroupList,
    getPesticideMainDrugList,
    getPesticidePreparativeFormList,
    getPesticideTreatmentTypeList,
    getCulturesCatalog
} = selectors;

const mstp = (state) => ({
    harmfulDivisions: currentHarmfulObjectDivisions(state),
    harmfulObjectList: currentHarmfulObjects(state),
    harmfulObjectCount: currentHarmfulObjectsCount(state),
    //
    pesticidesList: getPesticideApplicationList(state),
    pesticidesCount: getPesticideApplicationCount(state),
    applicationArea: getPesticideApplicationAreaList(state),
    harmfulObjects: getPesticideHarmfulObjectList(state),
    hazardClasses: getPesticideHazardClassList(state),
    placeOfProduction: getPesticidePlaceOfProductionList(state),
    groups: getPesticideGroupList(state),
    cultures: getCulturesCatalog(state),
    mainDrug: getPesticideMainDrugList(state),
    preparativeForms: getPesticidePreparativeFormList(state),
    treatmentTypes: getPesticideTreatmentTypeList(state)
});
const mdtp = ({
    getHarmfulObjectDivisions,
    getHarmfulObjects,
    getPesticides,
    getPesticideProductApplication,
    getPesticideApplicationArea,
    getPesticideChemicalClasses,
    getPesticideHarmfulObject,
    getPesticideHazardClass,
    getPesticidePlaceOfProduction,
    getPesticideGroup,
    getPesticideMainDrug,
    getPesticidePreparativeForm,
    getPesticideTreatmentType,
    getCultures
});

const { TabPane } = Tabs;
const { Option } = Select;

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_FERTILIZER = { 1: 'N', 2: 'P', 3: 'K' };

@withRouter
@connect(mstp, mdtp)
@withTranslation('harmful objects')
@cn('pesticides')
export default class ObjectsDetail extends Component {
    formRef = React.createRef();

    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        priceTo: undefined,
        priceFrom: undefined,
        page_size: 10,
        page: 1,
        showFilter: true,
        pesticidesListState: [],
        setFilter: false,
        showInfo: false,
        showPesticide: undefined
    };

    componentDidMount() {
        const {
            getHarmfulObjectDivisions,
            // getPesticideGroup,
            getCultures
        } = this.props;
        getHarmfulObjectDivisions(`?page=1&page_size=1000`);
        // getPesticideGroup('');
        getCultures('?page_size=1000');
        this.getFilteredHO();
    }

    getFilteredHO = () => {
        const { getHarmfulObjects } = this.props;
        const {
            page_size,
            page,
            name,
            culture,
            division
        } = this.state;
        const urlArr = [];

        name ? urlArr.push(`"name.icontains":"${name}"`) : '';
        culture ? urlArr.push(`"related_cultures.culture.id":${culture}`) : '';
        division ? urlArr.push(`"order.id":${division}`) : '';

        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;

        getHarmfulObjects(`?type="pest"&${page_size_url}&${page_url}&filter={${urlArr.join(',')}}`);
        // getHarmfulObjects(``);
    }

    onChange = (value, values) => {
        this.setState(value, () => {
            this.setState({ setFilter: true, page: 1 }, () => {
                this.getFilteredHO();
                // this.getFilteredPesticide();
            });
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    hideInfo = () => {
        this.setState({ showInfo: false });
    };

    onCurrentPageChange = (page) => {
        this.setState({
            page
        }, () => {
            this.getFilteredHO();
            // this.getFilteredPesticide();
        });
    };

    onPageSizeChange = (page_size) => {
        this.setState({
            page_size, page: 1
        }, () => {
            this.getFilteredHO();
            // this.getFilteredPesticide();
        });
    };

    get filterItems() {
        const { pesticidesCount: dataSize } = this.props;
        const items = [];
        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        return items;
    }

    goBack = () => {
        const { history } = this.props;
        history.push('/catalog-info');
    };


    showFilter = () => {
        this.setState({ showFilter: true });
    };

    showInfo = (pesticide) => {
        this.setState({ showInfo: true, showPesticide: pesticide });
    };

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    render() {
        const { cn } = this;
        const {
            t,
            cultures,
            harmfulDivisions,
            harmfulObjectList,
            harmfulObjectCount,
            isCompleted
        } = this.props;
        const {
            showFilter,
            page_size,
            page
        } = this.state;

        return (
            <div>
                <Form
                    ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="pesticide">
                    <Row gutter={20}>
                        <Col span={12}>
                            <CatalogTitle title={t('title harmful')} />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '30px' }}>
                        <Col>
                            <span className={cn('back')} onClick={this.goBack}>
                                {t('back to catalog')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={6} className="field">
                            <Form.Item label={t('culture harmful')}
                                name="culture">
                                <Select
                                    placeholder={t('placeholder culture')}
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {cultures.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('division harmful')}
                                name="division">
                                <Select
                                    placeholder={t('placeholder division')}
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {harmfulDivisions?.map && harmfulDivisions.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item name="name" label={t('name harmful')}>
                                <Input
                                    disabled={isCompleted}
                                    placeholder={t('placeholder name')}
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <ObjectsTable
                            filterItems={this.filterItems}
                            // dataSource={pesticidesList}
                            dataSource={harmfulObjectList}
                            // dataSize={pesticidesCount}
                            dataSize={harmfulObjectCount}
                            pageSize={page_size}
                            pageNum={page}
                            resetDataSource={showFilter}
                            showInfo={this.showInfo}
                            onPageSizeChange={this.onPageSizeChange}
                            onCurrentPageChange={this.onCurrentPageChange} />
                    </Col>
                </Row>
            </div>

        );
    }
}