import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.removeCalculatorPhyto.success: {
            return action.payload;
        }

        case constants.removeCalculatorPhyto.error: {
            return {};
        }

        case constants.removeCalculatorPhyto.try: {
            return initialState;
        }

        default:
            return state;
    }
}