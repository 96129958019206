import React, { Component } from 'react';
import { translate, withTranslation } from 'react-i18next';
import {
    Modal, Row, Col, Icon, Rate
} from 'antd';

import { rus2Latin } from '../../../../utils/utils';
import cn from '../../../../utils/cn';
import Button from '../../../../components/button';
import './style.less';
import * as constants from '../../../../constants';

@withTranslation('fertilizer review')
@cn('fertilizer-reviews-modal')
class ReviewModal extends Component {
    get steps() {
        const { review = {}, t } = this.props;
        if (review.steps) {
            const arr = review.steps.split(',');
            const textArr = arr.map((item) => t(item));
            return textArr.join(', ');
        } else {
            return '';
        }
    }

    render() {
        const { cn } = this;
        const {
            t,
            visible,
            title,
            onCancel,
            review,
            curRate
        } = this.props;
        if (!review) return <div />;
        return (
            <Modal
                width={760}
                footer={null}
                closable={false}
                destroyOnClose
                visible={visible}
                className={cn('modal')}
                onCancel={onCancel}>
                <div className={cn('content')}>
                    <div className={cn('title')}>
                        {title}
                    </div>
                    <Row>
                        <Col span={24} style={{ marginBottom: '30px' }}>
                            <Rate disabled value={review.rate} allowHalf />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('steps')}
                        </Col>
                        <Col span={12}>
                            {this.steps}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('like')}
                        </Col>
                        <Col span={12}>
                            {review.virtues}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('dislike')}
                        </Col>
                        <Col span={12}>
                            {review.disadvantages}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('common comment')}
                        </Col>
                        <Col span={12}>
                            {review.message}
                        </Col>
                    </Row>
                </div>
                <div className={cn('footer')}>
                    <Button
                        onClick={onCancel}
                        type="primary">
                        {t('close')}
                    </Button>
                </div>
                    
            </Modal>
        );
    }
}
export default ReviewModal;