import * as constants from '../constants';

const initialState = {
    answers: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.setAnswer.success:
        case constants.setSimpleAnswer.success:
        case constants.getQuestion.success:
        case constants.resetAnswer.success: {
            return action.payload;
        }

        case constants.clearDeterminant.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
