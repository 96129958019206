import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
    Button, Form, Input, DatePicker,
    Col, Tooltip, Popconfirm, message,
    Layout, Popover, Row, Spin
} from 'antd';
import { CopyOutlined, DownloadOutlined, PlusCircleOutlined, DeleteOutlined, FilterOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import {
    PanelLayout, Title
} from '../../components';

import RegistriesTable from './components/registries-table';

import {
    createInitialProtocol,
    deleteProtocol,
    getProtocolList,
    publishProtocol
} from '../../models/fed-registry/actions';

import './style.less';
import { curDeletionStatus, curNewInitialProtocol, curPublicationStatus } from '../../models/fed-registry/selectors';

const { PanelHeader, PanelContent } = PanelLayout;

const { RangePicker } = DatePicker;

const FedRegistries = () => {
    const [filtersForm] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const { t } = useTranslation('fed registry');

    const newProtocol = useSelector(state => curNewInitialProtocol(state));
    const statusUpdate = useSelector(state => curPublicationStatus(state));
    const deleteStatus = useSelector(state => curDeletionStatus(state));

    const [selectedTableItems, setSelectedTableItems] = useState([]);
    const [protocolPage, setProtocolPage] = useState(1);
    const [protocolPageSize, setProtocolPageSize] = useState(10);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [sortingOrder, setSortingOrder] = useState(['protocol_number', 'ascend']);

    useEffect(() => {
        if (!Object.keys(deleteStatus)?.includes('error')) {
            message.success('Протокол удален успешно!');
        } else if (deleteStatus?.error) {
            message.error('Ошибка при удалении протокола!');
        }
    }, [deleteStatus]);

    useEffect(() => {
        if (!Object.keys(statusUpdate)?.includes('error')) {
            message.success('Статус протокола успешно обновлен!');
        } else if (statusUpdate?.error) {
            message.error('Ошибка при обновлении статуса протокола!');
        }
    }, [statusUpdate]);

    useEffect(() => {
        if (!Object.keys(newProtocol)?.includes('error')) {
            message.success('Протокол успешно создан!');
        } else if (newProtocol?.error) {
            message.error('Ошибка при создании протокола!');
        }
    }, [newProtocol]);
    

    const handleGetRegistries = (pageNum) => {
        let orderingString;
        if (sortingOrder && sortingOrder?.length > 0) {
            orderingString = sortingOrder?.[1] === 'ascend' ? `&sort=["${sortingOrder[0]}"]` : `&sort=["-${sortingOrder[0]}"]`;
        }

        let filterString;
        const formValues = filtersForm?.getFieldsValue();
        const {
            protocol_number, field_num, examination_date, date_created, culture, acceptation_date
        } = formValues;
        if (
            protocol_number?.length > 0 || field_num?.length > 0 || culture?.length > 0
            || (examination_date && examination_date[0] && examination_date[1])
            || (date_created && date_created[0] && date_created[1])
            || (acceptation_date && acceptation_date[0] && acceptation_date[1])
        ) {
            const filtersArray = [];
            if (protocol_number?.length > 0) {
                filtersArray.push([`"protocol_number":"${protocol_number}"`]);
            }
            if (field_num?.length > 0) {
                filtersArray.push([`"field_num.icontains":"${field_num}"`]);
            }
            if (culture?.length > 0) {
                filtersArray.push([`"culture.name.icontains":"${culture}"`]);
            }
            if (examination_date && examination_date[0] && examination_date[1]) {
                filtersArray.push(`"examination_date.gte":"${examination_date[0]?.format('YYYY-MM-DD')}"`);
                filtersArray.push(`"examination_date.lte":"${examination_date[1]?.format('YYYY-MM-DD')}"`);
            }
            if (date_created && date_created[0] && date_created[1]) {
                filtersArray.push(`"date_created.gte":"${date_created[0]?.format('YYYY-MM-DD')}"`);
                filtersArray.push(`"date_created.lte":"${date_created[1]?.format('YYYY-MM-DD')}"`);
            }
            if (acceptation_date && acceptation_date[0] && acceptation_date[1]) {
                filtersArray.push(`"acceptation_date.gte":"${acceptation_date[0]?.format('YYYY-MM-DD')}"`);
                filtersArray.push(`"acceptation_date.lte":"${acceptation_date[1]?.format('YYYY-MM-DD')}"`);
            }
            filterString = `&filter={${filtersArray?.join(',')}}`;
        }

        dispatch(getProtocolList({
            page: protocolPage,
            pageSize: protocolPageSize,
            sorting: orderingString,
            filtering: filterString
        }));
    }

    useEffect(() => {
        if (protocolPage && protocolPageSize) {
            handleGetRegistries(protocolPage);
        }
    }, [protocolPage]);

    useEffect(() => {
        if (protocolPage === 1) {
            handleGetRegistries(protocolPage);
        } else {
            setProtocolPage(1);
        }
    }, [protocolPageSize]);

    useEffect(() => {
        if (newProtocol?.id && newProtocol?.id > 0) {
            handleGetRegistries(protocolPage);
        }
    }, [newProtocol]);

    useEffect(() => {
      if (statusUpdate?.statusUpdated) {
        handleGetRegistries(protocolPage);
      }
    }, [statusUpdate]);

    useEffect(() => {
        handleGetRegistries(protocolPage);
    }, [sortingOrder]);
    
    const managePublicationStatus = (statusType) => {
        switch (statusType) {
            case 'publish':
                selectedTableItems?.forEach(selectedRow => {
                    dispatch(publishProtocol({
                        id: selectedRow?.id,
                        values: {
                            public: true
                        }
                    }));
                });
                break;
            case 'edit':
                selectedTableItems?.forEach(selectedRow => {
                    dispatch(publishProtocol({
                        id: selectedRow?.id,
                        values: {
                            public: false
                        }
                    }));
                });
                break;
            case 'archive':
                selectedTableItems?.forEach(selectedRow => {
                    dispatch(deleteProtocol(selectedRow?.id));
                });
                setTimeout(() => {
                    handleGetRegistries(protocolPage);
                }, 1500);
                break;
            default:
                break;
        }
    };

    const statusManagementButtons = (
        <div className="status-management-popover">
            <Button
                onClick={() => managePublicationStatus('publish')}
                className="status-management-popover__top"
                type="primary">
                {t('status button publish')}
            </Button>
            <Button
                onClick={() => managePublicationStatus('edit')}
                className="status-management-popover__mid">
                {t('status button edit')}
            </Button>
        </div>
    );

    const copyRecord = () => {
        const recordToCopy = selectedTableItems[0];
        const resultRecord = {
            date_created: recordToCopy?.date_created,
            acceptation_date: recordToCopy?.acceptation_date,
            comment: recordToCopy?.comment,
            culture: recordToCopy?.culture?.id,
            examination_date: recordToCopy?.examination_date,
            fed_calculator: recordToCopy?.fed_calculator,
            fed_fertilizers: recordToCopy?.fed_fertilizers,
            field_num: recordToCopy?.field_num,
            field_point: recordToCopy?.field_point,
            owner: recordToCopy?.owner?.id,
            productivity_zone: recordToCopy?.productivity_zone,
            protocol_number: recordToCopy?.protocol_number,
            sample: 'Leaf',
            vega_key: recordToCopy?.vega_key
        };
        dispatch(createInitialProtocol(resultRecord));
    };

    const [isRecordGenerating, setIsRecordGenerating] = useState(false);

    const handleDownload = (recordId) => {
        setIsRecordGenerating(true);

        fetch(`/api/v1/fed_calculator/download_fed_protocol/download_protocol/`, {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            //
            body: JSON.stringify({
                protocol_ids: recordId
            })
        }).then((resp) => {
            if (resp?.status < 400 || resp?.ok) {
                resp.blob().then((blb) => {
                    const url = window.URL.createObjectURL(blb);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `FED_Registry_${recordId}`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    setIsRecordGenerating(false);
                });
            } else {
                message.error('Ошибка при загрузке файла');
            }
        });
    };

    const handleDownloadRecord = () => {
        handleDownload(selectedTableItems?.map(x => x?.id));
    };

    const handleResetFilters = () => {
        filtersForm?.resetFields();
        if (protocolPage === 1) {
            handleGetRegistries(protocolPage);
        } else {
            setProtocolPage(1);
        }
    };

    return (
        <Layout className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <Row className="controls-row" gutter={12}>
                        <Col span={6}>
                            <Link
                                to="/fed-registry/"
                                onClick={() => {
                                    setTimeout(() => window.location.reload(), 500);
                                }}>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary">
                                    <PlusCircleOutlined />
                                    {t('add protocol')}
                                </Button>
                            </Link>
                        </Col>

                        <Col span={5}>
                            {selectedTableItems?.length < 1 ? (
                                <Button style={{ width: '100%' }} disabled type="secondary">
                                    {t('manage status')}
                                </Button>
                            ) : (
                                <Popover content={statusManagementButtons} trigger="click" placement="bottom">
                                    <Button style={{ width: '100%' }} type="secondary">
                                        {t('manage status')}
                                    </Button>
                                </Popover>
                            )}
                        </Col>

                        <Col span={9} />

                        <Col span={4}>
                            <Row gutter={12}>
                                <Col span={8}>
                                    <Tooltip
                                        placement="bottomLeft"
                                        title={t('copy hint')}>
                                        <Button
                                            disabled={selectedTableItems?.length !== 1}
                                            onClick={copyRecord}>
                                            <CopyOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip
                                        placement="bottomLeft"
                                        title={t('download hint')}>
                                        <Button
                                            disabled={selectedTableItems?.length < 1}
                                            onClick={handleDownloadRecord}>
                                            {isRecordGenerating ? (
                                                <Spin spinning={isRecordGenerating} />
                                            ) : (
                                                <DownloadOutlined />
                                            )}
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Popconfirm
                                            placement="rightBottom"
                                            title="Подтвердите удаление"
                                            onConfirm={() => managePublicationStatus('archive')}
                                            onCancel={() => {}}
                                            okText="Подтвердить"
                                            cancelText="Отмена"
                                        >
                                        <Tooltip
                                            placement="bottomLeft"
                                            title={t('delete hint')}>
                                            <Button
                                                disabled={selectedTableItems?.length < 1}
                                                // onClick={() => managePublicationStatus('archive')}
                                                className="status-management-popover__bot"
                                                danger>
                                                <DeleteOutlined />
                                            </Button>
                                        </Tooltip>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '25px' }}>
                        <Col span={4}>
                            <Button size="medium" onClick={() => { setFiltersOpened(!filtersOpened); }}>
                                <FilterOutlined />
                                {filtersOpened ? t('close filters') : t('open filters')}
                            </Button>
                        </Col>
                    </Row>
                    {filtersOpened && (
                        <Form form={filtersForm} labelCol={{ span: 24 }}>
                            <Row gutter={12}>
                                <Col span={3}>
                                    <Form.Item name="protocol_number" label={t('filter protocol_number')}>
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item name="field_num" label={t('filter field_num')}>
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item name="culture" label={t('filter culture')}>
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name="date_created" label={t('filter date_created')}>
                                        <RangePicker allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name="acceptation_date" label={t('filter acceptation_date')}>
                                        <RangePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name="examination_date" label={t('filter examination_date')}>
                                        <RangePicker />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12} style={{ marginBottom: '25px' }}>
                                <Col span={3}>
                                    <Button
                                        style={{ width: '100%' }}
                                        size="medium"
                                        onClick={() => {
                                            if (protocolPage === 1) {
                                                handleGetRegistries(protocolPage);
                                            } else {
                                                setProtocolPage(1);
                                            }
                                        }}
                                        type="primary">
                                        Применить
                                    </Button>
                                </Col>
                                <Col span={2}>
                                    <Button size="medium" onClick={handleResetFilters} type="secondary">
                                        Очистить
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    <RegistriesTable
                        sortingOrder={sortingOrder}
                        setSortingOrder={setSortingOrder}
                        setCurrentPageSize={setProtocolPageSize}
                        currentPageSize={protocolPageSize}
                        currentPage={protocolPage}
                        setCurrentPage={setProtocolPage}
                        selectedItems={selectedTableItems}
                        setSelectedItems={setSelectedTableItems} />
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FedRegistries;