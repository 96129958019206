import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Layout, Spin, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import {
    Title, Tabs, SideBar, PanelLayout
} from '../../components';
import PesticidesDetail from './components/pesticides';

import cn from '../../utils/cn';

import './style.less';

const { PanelHeader, PanelFooter, PanelContent } = PanelLayout;

@withTranslation('pesticides')
@cn('pesticides')
export default class Pesticides extends Component {
    render() {
        const { cn } = this;

        const { t, history } = this.props;

        return (
            <Layout className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <PesticidesDetail />
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}