import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import converter from './converter';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getRegistrants.toString(),
            defaultSaga({
                constants: constants.getRegistrants,
                api: api.getRegistrants
            })
        ),
        yield takeLatest(
            constants.getRegistrant.toString(),
            defaultSaga({
                constants: constants.getRegistrant,
                api: api.getRegistrant
            })
        )
    ]);
}