import {all, call, put, takeLatest} from 'redux-saga/effects';
import {defaultSaga, getActions} from '../utils';

import * as constants from './constants';
import * as api from './api';
import { publishMycologicalRegistry, uploadRegistryFile } from './api';
import { deleteMycologicalRegistry, updateFileDescription } from './actions';

function* addMycologicalRegistrySaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addMycologicalRegistry.try,
        constants.addMycologicalRegistry.success,
        constants.addMycologicalRegistry.error
    );
    try {
        const { history, values } = payload;
        yield put(start());
        const data = yield call(api.addMycologicalRegistry, values);
        history.push(`/mycological-registry/${data.id}`);
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* uploadRegistryFileSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.uploadRegistryFile.try,
        constants.uploadRegistryFile.success,
        constants.uploadRegistryFile.error
    );
    try {
        const { values, type } = payload;
        yield put(start());
        const data = yield call(api.uploadRegistryFile, { values, type });
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getMycologicalRegistries.toString(),
            defaultSaga({
                constants: constants.getMycologicalRegistries,
                api: api.getMycologicalRegistries
            })
        ),
        yield takeLatest(
            constants.getMycologicalRegistry.toString(),
            defaultSaga({
                constants: constants.getMycologicalRegistry,
                api: api.getMycologicalRegistry
            })
        ),
        yield takeLatest(
            constants.addMycologicalRegistry.toString(),
            addMycologicalRegistrySaga
        ),
        yield takeLatest(
            constants.generateMycologicalRegistryDoc.toString(),
            defaultSaga({
                constants: constants.generateMycologicalRegistryDoc,
                api: api.generateMycologicalRegistryDoc
            })
        ),
        yield takeLatest(
            constants.getUsersList.toString(),
            defaultSaga({
                constants: constants.getUsersList,
                api: api.getUsersList
            })
        ),
        yield takeLatest(
            constants.getFilteredFields.toString(),
            defaultSaga({
                constants: constants.getFilteredFields,
                api: api.getFilteredFields
            })
        ),
        yield takeLatest(
            constants.updateFileDescription.toString(),
            defaultSaga({
                constants: constants.updateFileDescription,
                api: api.updateFileDescription
            })
        ),
        yield takeLatest(
            constants.uploadRegistryFile.toString(),
            uploadRegistryFileSaga
        ),
        yield takeLatest(
            constants.updateMycologicalRegistry.toString(),
            defaultSaga({
                constants: constants.updateMycologicalRegistry,
                api: api.updateMycologicalRegistry
            })
        ),
        yield takeLatest(
            constants.deleteMycologicalRegistry.toString(),
            defaultSaga({
                constants: constants.deleteMycologicalRegistry,
                api: api.deleteMycologicalRegistry
            })
        ),
        yield takeLatest(
            constants.publishMycologicalRegistry.toString(),
            defaultSaga({
                constants: constants.publishMycologicalRegistry,
                api: api.publishMycologicalRegistry
            })
        )
    ]);
}