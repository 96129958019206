import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Spin } from 'antd';

import cn from '../../utils/cn';

import { PanelLayout, SideBar, TableFooter } from '../../components';

import './style.less';
import CalculatorDetail from './components/calculator-detail';
import { actions, selectors } from '../../models';

const { Content } = Layout;

const {
    getCalculatorFluid
} = actions;
const {
    getCurrentCalculatorFluid,
    isCalculatorFluidLoading
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFluid(state),
    loading: isCalculatorFluidLoading(state)
});
const mdtp = ({
    getCalculatorFluid
});

@withRouter
@connect(mstp, mdtp)
@cn('all-calculators')
export default class Calculator extends Component {
    state = {
        id: null,
        name: false
    };

    componentDidMount() {
        const { getCalculatorFluid, match } = this.props;
        const { id } = match.params;

        if (id) {
            this.setState({ id });
        }
    }

    render() {
        const { cn } = this;
        const { calculator, loading } = this.props;
        const { name, id } = this.state;
        return (
            <Layout className={cn()}>
                <CalculatorDetail loading={loading} />
            </Layout>
        );
    }
}
