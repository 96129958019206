import React, {useState} from 'react';

import { useTranslation } from 'react-i18next';

import {
    Title, PanelLayout
} from '../../../components';

import Map from './map';
import FieldInfo from './field';

import CalculatorDetail from './calculator';

const { PanelHeader, PanelContent } = PanelLayout;


export default ({ vegaKey }) => {
    const { t } = useTranslation('mordovia');
    const [curPoint, setCurPoint] = useState(undefined);

    const setPoint = (id) => {
        setCurPoint(id);
    };

    return (
        <PanelLayout>
            <PanelHeader>
                <Title title={t('calculator name')} />
            </PanelHeader>
            <PanelContent>
                <div id="map-mordovia">
                    <Map curPoint={curPoint} />
                    <FieldInfo />
                </div>
                <CalculatorDetail vegaKey={vegaKey} setCurPoint={setPoint} />
            </PanelContent>
        </PanelLayout>
    );
};