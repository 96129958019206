import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, Form, Input
} from 'antd';

import cn from '../../../utils/cn';
import {
    Title, Button, Tabs, InfoMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import { regions } from '../../../regions';

const { Password } = Input;
const {
    resetPasswordConfirm
} = actions;
const {
    getUser
} = selectors;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({
    resetPasswordConfirm
});


@withRouter
@connect(mstp, mdtp)
@withTranslation('profile detail')
@cn('reset-form')
export default class ResetPassword extends Component {
    formRef = React.createRef();

    state = {
        isSet: false,
        passwordMatch: false
    }

    onSubmit = (values) => {
        const { resetPasswordConfirm, match } = this.props;
        const { uid, token } = match.params;
        const sendValues = {
            uid, token, new_password: values.new_password
        };
        resetPasswordConfirm(sendValues);
        this.setState({ isSet: true }, () => {
            setTimeout(() => window.location.replace('/'), 1000);
        });
    };
    
    onFieldsChange = (field, fields) => {
        const password = fields.find(item => item.name.includes('new_password'));
        const password_repeat = fields.find(item => item.name.includes('password_repeat'));
        if (password.value === password_repeat.value) {
            this.setState({ passwordMatch: true });
        } else {
            this.setState({ passwordMatch: false });
        }
    };

    validatePassword = (rule, value, callback) => {
        const { t } = this.props;
        if (value && value.length < 8) {
            callback(t('not valid pass'));
        } else {
            callback();
        }
    };

    render() {
        const { cn } = this;
        const { t } = this.props;
        const { isSet, passwordMatch } = this.state;

        return (
            <Form onFinish={this.onSubmit}
                ref={this.formRef}
                className={cn()}
                onFieldsChange={this.onFieldsChange}
                name="profile-form">
                <div>
                    <Row gutter={20}>
                        <Col span={24}>
                            <div className={cn('header')}>
                                {t('reset password title')}
                            </div>
                        </Col>
                    </Row>
                    { !isSet && (
                        <><Row gutter={20}>
                            <Col span={24}>
                                <div className="field">
                                    <Form.Item label={t('new password')}
                                        name="new_password"
                                        rules={[
                                            { required: true, message: t('not empty') },
                                            { validator: this.validatePassword }
                                        ]}>
                                        <Password
                                            className={cn('input')}
                                            placeholder={t('new password placeholder')}
                                            type="password"
                                            maxLength={255} />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <div className="field">
                                    <Form.Item label={t('repeat password')}
                                        name="password_repeat"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('new_password') === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error(t('passwords do not match')));
                                                }
                                            })
                                        ]}>
                                        <Password
                                            className={cn('input')}
                                            placeholder={t('repeat password placeholder')}
                                            type="password"
                                            maxLength={255} />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className={cn('button-block')}>
                                <Button
                                    disabled={!passwordMatch}
                                    type="primary"
                                    htmlType="submit">
                                    {t('save')}
                                </Button>
                            </Col>
                        </Row>
                        </>
                    )}
                    {isSet && (
                        <Row>
                            <Col span={24}>
                                {t('password saved')}
                            </Col>
                        </Row>
                    )}
                </div>
            </Form>

        );
    }
}