import React, { Component } from 'react';
import { Layout } from 'antd';
import ErrorPage from "./components/error-page"
import cn from '../../utils/cn';

import './style.less';

const { Content } = Layout;

@cn('error-page')
export default class Error extends Component {
    render() {
        return (
            <Layout className={cn()}>
                <Content className={cn('content')}>
                    <ErrorPage />
                </Content>
            </Layout>
        );
    }
}
