import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import converter from './converter';
import { updateCalculate } from '../item-calculate/actions';

function* addInterpretationSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addInterpretation.try,
        constants.addInterpretation.success,
        constants.addInterpretation.error
    );
    try {
        const { calculate, values } = payload;
        yield put(start());
        const dataRaw = yield call(api.addInterpretation, values);
        if (!dataRaw) {
            yield put(failure({ type: 'INTERPRETATION_ERROR', error: 'Planned yield is required' }));
        }
        // yield console.log('INTER dataRaw', dataRaw);
        const data = converter.toClient(dataRaw);
        // yield console.log('INTER data', data);
        yield put(updateCalculate({ id: calculate.id, values: { agrochemical: data.id, ...calculate } }));
        yield put(success(data));
    } catch (error) {
        // yield console.log('INTER error', error);
        yield put(failure({ type: 'INTERPRETATION_ERROR', error: 'Planned yield is required' }));
        // yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getInterpretation.toString(),
            defaultSaga({
                constants: constants.getInterpretation,
                api: api.getInterpretation,
                after: converter.toClient
            })
        ),
        yield takeLatest(
            constants.getInterpretations.toString(),
            defaultSaga({
                constants: constants.getInterpretations,
                api: api.getInterpretations
            })
        ),
        yield takeLatest(
            constants.addInterpretation.toString(),
            addInterpretationSaga
        ),
        yield takeLatest(
            constants.updateInterpretation.toString(),
            defaultSaga({
                constants: constants.updateInterpretation,
                api: api.updateInterpretation,
                after: converter.toClient
            })
        )
    ]);
}