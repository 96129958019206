import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsDegreeOfSoil.toString(),
            defaultSaga({
                constants: constants.getCalculatorsDegreeOfSoil,
                api: api.getCalculatorsDegreeOfSoil
            })
        ),
        yield takeLatest(
            constants.getCalculatorsDegreeOfSoilItem.toString(),
            defaultSaga({
                constants: constants.getCalculatorsDegreeOfSoilItem,
                api: api.getCalculatorsDegreeOfSoilItem
            })
        )
    ]);
}