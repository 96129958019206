import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { Menu } from 'antd';

import { Link, withRouter } from 'react-router-dom';

import cn from '../../utils/cn';

import './style.less';

const { SubMenu } = Menu;

@withRouter
@withTranslation('vertical menu')
@cn('menu')
export default class VMenu extends Component {
    render() {
        const { cn } = this;
        const {
            t, username
        } = this.props;

        return (
            <div className={cn()}>
                <Menu mode="inline">
                    <Menu.Item key="determinants">
                        <span>
                            <span>{username}</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        );
    }
}