import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getFertilizersPhyto.success: {
            return {
                next: action.payload.next,
                results: action.payload.results
            };
        }

        case constants.getFertilizersPhyto.error: {
            return initialState;
        }

        case constants.getFertilizersPhyto.try: {
            return {};
        }

        default:
            return state;
    }
}