import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Icon from '@ant-design/icons';

import {
    Rate, Row, Col, Collapse
} from 'antd';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { rus2Latin } from '../../../utils/utils';
import cn from '../../../utils/cn';
import Button from '../../../components/button';
import '../style.less';
import * as constants from '../../../constants';
import { selectors, actions } from '../../../models';
import { Title } from '../../../components';

const { Panel } = Collapse;

const {
    getFertilizer
} = actions;
const {
    getCurrentFertilizer
} = selectors;

const mstp = (state) => ({
    fertilizer: getCurrentFertilizer(state)
});
const mdtp = ({
    getFertilizer
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('fertilizer modal')
@cn('fertilizer-detail')
export default class FertilizerDetail extends Component {
    componentDidMount() {
        const { getFertilizer, match } = this.props;
        const { id } = match.params;
        getFertilizer(id);
    }

    get getEconomyType() {
        const { t, fertilizer } = this.props;
        const arr = [];
        constants.economyTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item)); }
        });
        const str = arr.join(', ');
        return str;
    }

    get getMacro() {
        const { t, fertilizer } = this.props;
        const arr = [];
        constants.macroTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item, { value: fertilizer[item] })); }
        });
        const str = arr.join(', ');
        return str;
    }

    get getApplicationRate() {
        const { t, fertilizer } = this.props;
        const arr = [];
        constants.economyTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item)); }
        });
        const str = arr.join(', ');
        return str;
    }

    jsPdfGenerator = () => {
        const { t, fertilizer } = this.props;
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const text = doc.splitTextToSize(fertilizer.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        const applicationRate = [];
        if (fertilizer.presowing_application_rate) {
            applicationRate.push([
                `${t('application rate')}. ${t('presowingApplicationRate')}`,
                fertilizer.presowing_application_rate
            ]);
        }
        if (fertilizer.sowing_application_rate) {
            applicationRate.push([
                `${t('application rate')}. ${t('sowingApplicationRate')}`,
                fertilizer.sowing_application_rate
            ]);
        }
        if (fertilizer.basic_application_rate) {
            applicationRate.push([
                `${t('application rate')}. ${t('basicApplicationRate')}`,
                fertilizer.basic_application_rate
            ]);
        }
        if (fertilizer.top_dressing_application_rate_open) {
            applicationRate.push([
                `${t('application rate')}. ${t('topDressingApplicationRateOpen')}`,
                fertilizer.top_dressing_application_rate_open
            ]);
        }
        if (fertilizer.top_dressing_application_rate_close) {
            applicationRate.push([
                `${t('application rate')}. ${t('topDressingApplicationRateClose')}`,
                fertilizer.top_dressing_application_rate_close
            ]);
        }
        const body = [
            [t('group fertilizers'), fertilizer.group_name],
            [t('subgroup fertilizers'), fertilizer.subgroup_name],
            [t('culture group'), fertilizer.culture_description],
            [t('main drug'), fertilizer.main_drug],
            [t('economy type'), this.getEconomyType],
            [t('cultivated'), fertilizer.culture_description],
            [t('synonyms'), fertilizer.synonyms.map(item => `${item.name} `)],
            [t('macrocells'), this.getMacro],
            [t('release form'), fertilizer.release_form_name],
            [t('registrant'), fertilizer.registration],
            [t('registry'), fertilizer.is_in_state_registry ? t('inStateRegistryYes') : t('inStateRegistryNo')]
        ];
        applicationRate.forEach(item =>
            (body.push(item)));
        doc.autoTable({
            startY: finalY + 40,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
            },
            body
        });
        const fileName = rus2Latin(fertilizer.name);
        doc.save(`${fileName}.pdf`);
    };

    goBack = () => {
        const { history } = this.props;
        history.push('/fertilizers');
    };

    render() {
        const { cn } = this;
        const {
            t, fertilizer,
            onTabChange,
            rate
        } = this.props;
        if (!fertilizer) return <div />;

        return (

            <div className={cn()}>
                <div className={cn('content')}>
                    <span className={cn('back')} onClick={this.goBack}>
                        {t('back')}
                    </span>
                    <div className={cn('title')}>
                        {fertilizer.name}
                    </div>
                    { /* <div className={cn('rate')}>
                        <Rate disabled allowHalf value={rate} />
                        <div className={cn('rate', 'value')}>{rate.toFixed(1)}</div>
                    </div> */ }
                    <div className={cn('data')}>
                        <Row>
                            <Col span={12}>
                                {t('group fertilizers')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.group_name}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('subgroup fertilizers')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.subgroup_name}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('culture group')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.culture_description}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('main drug')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.main_drug}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('economy type')}
                            </Col>
                            <Col span={12}>
                                {this.getEconomyType}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('cultivated')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.culture_description}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('synonyms')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.synonyms.map(item => `${item.name} `)}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('macrocells')}
                            </Col>
                            <Col span={12}>
                                {this.getMacro}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {t('release form')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.release_form_name}
                            </Col>
                        </Row>
                        {fertilizer.presowing_application_rate && (
                            <Row>
                                <Col span={12}>
                                    {`${t('application rate')}. ${t('presowingApplicationRate')}`}
                                </Col>
                                <Col span={12}>

                                    {fertilizer.presowing_application_rate}
                                </Col>
                            </Row>
                        )}
                        {fertilizer.sowing_application_rate && (
                            <Row>
                                <Col span={12}>
                                    {`${t('application rate')}. ${t('sowingApplicationRate')}`}
                                </Col>
                                <Col span={12}>

                                    {fertilizer.sowing_application_rate}
                                </Col>
                            </Row>
                        )}
                        {fertilizer.basic_application_rate && (
                            <Row>
                                <Col span={12}>
                                    {`${t('application rate')}. ${t('basicApplicationRate')}`}
                                </Col>
                                <Col span={12}>

                                    {fertilizer.basic_application_rate}
                                </Col>
                            </Row>
                        )}
                        {fertilizer.top_dressing_application_rate_open && (
                            <Row>
                                <Col span={12}>
                                    {`${t('application rate')}. ${t('topDressingApplicationRateOpen')}`}
                                </Col>
                                <Col span={12}>

                                    {fertilizer.top_dressing_application_rate_open}
                                </Col>
                            </Row>
                        )}
                        {fertilizer.top_dressing_application_rate_close && (
                            <Row>
                                <Col span={12}>
                                    {`${t('application rate')}. ${t('topDressingApplicationRateClose')}`}
                                </Col>
                                <Col span={12}>

                                    {fertilizer.top_dressing_application_rate_close}
                                </Col>
                            </Row>
                        )}
                        {fertilizer.leaf_dressing && (
                            <Row>
                                <Col span={12}>
                                    {`${t('application rate')}. ${t('leaf_dressing')}`}
                                </Col>
                                <Col span={12}>

                                    {fertilizer.leaf_dressing}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col span={12}>
                                {t('registrant')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.registration}
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col span={12}>
                                {t('price')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.averagePrice}
                            </Col>
                        </Row>*/}
                        <Row>
                            <Col span={12}>
                                {t('registry')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.is_in_state_registry ? t('inStateRegistryYes') : t('inStateRegistryNo')}
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className={cn('footer')}>
                    <Button
                        onClick={this.jsPdfGenerator}
                        type="link">
                        <Icon type="download" />
                        {t('generate')}
                    </Button>
                    { /* <Button
                        onClick={() => onTabChange('3', true)}
                        type="default">
                        {t('send review')}
                    </Button> */ }
                </div>
            </div>
        );
    }
}
