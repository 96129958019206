import React from 'react';
import {
    TileLayer,
    LayersControl,
    LayerGroup
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { CustomWMSLayer } from './custom-wms';

export default () => {
    const { t } = useTranslation('layers');
    return (
        <>
            <LayersControl position="bottomleft">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay checked name={t('Field scheme')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Field scheme')}
                            checked
                            isShow
                            layer="teleagronom:ammoniy_field"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Sampling points, agrochemistry')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Sampling points, agrochemistry')}
                            layer="teleagronom:Ammoniy_soil_point2022"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Productivity Zones')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Productivity Zones')}
                            legend_url="teleagronom:ammonij_zones"
                            layer="teleagronom:Zone_prod_viborka"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Soils')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Soils')}
                            legend_url="teleagronom:ammonij_soil"
                            layer="teleagronom:Ammoniy_soil"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('slope')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('slope')}
                            legend_url="teleagronom:ammonij_uklon"
                            layer="teleagronom:Ammoniy_uklon"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('exposition')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('exposition')}
                            legend_url="teleagronom:Exposition"
                            layer="teleagronom:Ammoniy_Expos"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Contours')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Contours')}
                            layer="teleagronom:Ammoniy_Izolinii1m"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Erosion')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Erosion')}
                            layer="teleagronom:Ammoniy_Vodotoki"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Erosion factor')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Erosion factor')}
                            legend_url="teleagronom:ammonij_ls_factor"
                            layer="teleagronom:Ammoniy_LS factor_"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Relief')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Relief')}
                            layer="teleagronom:Ammoniy_srtm"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </>
    );
};
