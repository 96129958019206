import moment from 'moment';
import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    _server2Client = (item = {}) => ({
        id: item.id,
        rate: item.rate,
        year: item.year,
        steps: item.steps,
        region: item.region,
        virtues: item.virtues,
        disadvantages: item.disadvantages,
        message: item.message,
        createDate: item.createDate,
        fertilizer: item.fertilizer
    });

    list2Client = (items = []) => items.map(this._server2Client);

    reviewsFullInfo2Client = (rawObject = {}) => {
        const {
            content,
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        } = rawObject;

        return {
            content: this.list2Client(content),
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        };
    };

    removedReviewId2Client = id => ({ removedReviewId: id });
}

export default new Converter();