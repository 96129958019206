import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Layout, Spin, Icon, Row, Col
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Title, Tabs, SideBar } from '../../components';
import DiseaseDetail from './components/disease-detail';

import cn from '../../utils/cn';

import './style.less';

@withTranslation('diseases')
@cn('diseases')
export default class Disease extends Component {

    render() {
        const { cn } = this;

        const { t, history } = this.props;

        return (
            <Layout className={cn()}>
                <DiseaseDetail />
            </Layout>
        );
    }
}