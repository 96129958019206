import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
    Space, Layout, Modal
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useClassName } from 'utils/cn';
import './style.less';
import { Button, PanelLayout, Title } from '../../components';
import {
    deleteAgrotechnicalEvent,
    getAgrotechnicalEventsAccounting
} from '../../models/agrotechnical-events/actions';
import {
    curAccountingEvents,
    curVegetationPhases
} from '../../models/agrotechnical-events/selectors';
import AccountingTable from './components/accounting-table';
import AccountingModal from './components/accounting-modal/accounting-modal';
import SowingModal from './components/sowing-modal/component';
import { getUser } from '../../models/account/selectors';
import {
    getCalculatorsMordoviaCatalog
} from '../../models/mordovia/selectors';
import { getCalculatorsMordovia } from '../../models/mordovia/actions';

const { PanelHeader, PanelContent } = PanelLayout;

const { confirm } = Modal;

const AccountingDocuments = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation('accounting documents');
    const cn = useClassName('accounting-documents');

    const currentUser = useSelector(state => getUser(state));
    const vegetationPhaseList = useSelector(state => curVegetationPhases(state));
    const fieldsList = useSelector(state => getCalculatorsMordoviaCatalog(state));
    const agrotechnicalEvents = useSelector(state => curAccountingEvents(state));

    const [culturesMapper, setCulturesMapper] = useState({});
    const [editableEvent, setEditableEvent] = useState(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sowingModalOpen, setSowingModalOpen] = useState(false);
    const [selectedEntries, setSelectedEntries] = useState([]);

    const updateAgroEvents = (uId) => {
        dispatch(getAgrotechnicalEventsAccounting({
            userId: uId
        }));
    };

    useEffect(() => {
        fieldsList.forEach(x => {
            if (x.culture.name && x.culture.id) {
                const tempObj = culturesMapper;
                tempObj[x.culture.id] = x.culture.name;
                setCulturesMapper(tempObj);
            }
        });
    }, [fieldsList]);

    useEffect(() => {
        if (currentUser.id) {
            const urlArr = [`"user":${currentUser.id}`];
            dispatch(getCalculatorsMordovia(`?filter={${urlArr.join(',')}}`));
            updateAgroEvents(currentUser?.id);
        }
    }, [currentUser]);

    const handleAdd = () => {
        setIsModalOpen(true);
    };

    const handleAddSowing = () => {
        setSowingModalOpen(true);
    };

    const handleEdit = () => {
        setEditableEvent(agrotechnicalEvents.filter(x => x.id === selectedEntries[0])[0]);
        setIsModalOpen(true);
    };

    const handleDelete = () => {
        confirm({
            title: t('modal confirm deletion'),
            okText: t('modal ok deletion'),
            icon: <ExclamationCircleOutlined />,
            onOk() {
                selectedEntries.forEach(evtEntry => {
                    dispatch(deleteAgrotechnicalEvent({
                        eventId: evtEntry
                    }));
                });
                setTimeout(() => updateAgroEvents(currentUser?.id), 1000);
            }
        });
    };

    return (
        <Layout className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title')} />
                </PanelHeader>
                <PanelContent>

                    <div className="accounting-documents__infobox">
                        <p>{t('description block')}</p>
                        <p>{t('description action')}</p>
                    </div>

                    <Space
                        className="accounting-documents__buttons"
                        direction="horizontal">
                        <div>
                            <Button
                                type="primary"
                                onClick={handleAdd}
                                disabled={false}
                                size="large">
                                {t('button add')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleAddSowing}
                                size="large"
                                style={{ marginLeft: '10px' }}
                                disabled={false}>
                                <PlusCircleOutlined />
                                {t('button sowing')}
                            </Button>
                        </div>

                        <Space className="accounting-documents__buttons__group">
                            <Button
                                type="secondary"
                                onClick={handleEdit}
                                disabled={!(selectedEntries.length === 1)}
                                size="large">
                                {t('button edit')}
                            </Button>
                            <Button
                                type="secondary"
                                onClick={handleDelete}
                                disabled={!(selectedEntries.length >= 1)}
                                size="large">
                                <DeleteOutlined />
                                {t('button delete')}
                            </Button>
                        </Space>
                    </Space>

                    <AccountingTable
                        agrotechnicalEvents={agrotechnicalEvents}
                        culturesMapper={culturesMapper}
                        selectedEntries={selectedEntries}
                        setSelectedEntries={setSelectedEntries} />
                    <AccountingModal
                        editableEvent={editableEvent}
                        setEditableEvent={setEditableEvent}
                        updateEvents={() => updateAgroEvents(currentUser?.id)}
                        vegPhases={vegetationPhaseList}
                        setIsOpened={setIsModalOpen}
                        isOpened={isModalOpen} />
                    <SowingModal
                        updateEvents={() => updateAgroEvents(currentUser?.id)}
                        setIsOpened={setSowingModalOpen}
                        isOpened={sowingModalOpen} />
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default AccountingDocuments;