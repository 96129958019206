import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Col, Row } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import ImageCard from './image-card';
// import ControlArrow from './control-arrow';

import '../../style.less';

const ImageCarousel = ({ imageCards }) => {
    const { t } = useTranslation('field representation mycological analysis');

    const carouselRef = useRef(null);

    return (
        <Row className="mycointerpretation__carousel__container" align="middle">
            <Col>
                <LeftCircleOutlined
                    style={{
                        verticalAlign: 'middle'
                    }}
                    className="mycointerpretation__carousel__container__button"
                    onClick={() => carouselRef?.current?.prev()} />
            </Col>
            <Col span={23}>
                <Carousel
                    ref={carouselRef}
                    slidesToShow={imageCards?.length > 3 ? 3 : imageCards?.length}
                    dots={false}
                    className="mycointerpretation__carousel">
                    {imageCards?.map((cardEntity) => (
                        <ImageCard
                            key={cardEntity?.id}
                            cardId={cardEntity?.id}
                            cardImage={cardEntity?.picture_file}
                            cardText={cardEntity?.picture_desc} />
                    ))}
                </Carousel>
            </Col>
            <Col>
                <RightCircleOutlined
                    style={{
                        verticalAlign: 'middle'
                    }}
                    className="mycointerpretation__carousel__container__button"
                    onClick={() => carouselRef?.current?.next()} />
            </Col>
        </Row>
    );
};

export default ImageCarousel;