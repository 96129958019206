import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, DatePicker, Form, Input, InputNumber, Icon, Spin
} from 'antd';

import moment from 'moment';
import cn from '../../../utils/cn';
import * as constants from '../../../constants';
import {
    Title, Button, Tabs, InfoMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import RequestsTable from './requests-table';

const {
    getRequests,
    getTaskFile,
    addRequest,
    setStatusRequest
} = actions;
const {
    getRequestsList,
    getRequestsListCount,
    getRequestsBody
} = selectors;

const mstp = (state) => ({
    requestsList: getRequestsList(state),
    requestsCount: getRequestsListCount(state),
    requestsPage: getRequestsBody(state)
});
const mdtp = ({
    getRequests,
    getTaskFile,
    addRequest,
    setStatusRequest
});

const { RangePicker } = DatePicker;

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const statusesArr = ['NEW', 'IN_PROGRESS', 'CLOSED', 'ANSWER'];

@withRouter
@connect(mstp, mdtp)
@withTranslation('requests')
@cn('requests')
export default class RequestsDetail extends Component {
    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        perPage: 10,
        pageNum: 0,
        showFilter: true,
        cultures: [],
        statuses: []
    };

    componentDidMount() {
        const {
            getRequests
        } = this.props;
        const { perPage } = this.state;
        this.getFilteredRequests();
    }

    getFilteredRequests = () => {
        const { getRequests } = this.props;
        const {
            perPage,
            pageNum,
            cultures,
            statuses,
            dateFrom,
            dateTo
        } = this.state;
        getRequests({
            perPage,
            pageNum,
            cultures,
            statuses,
            dateFrom,
            dateTo
        });
    };

    onChange = (e, form) => {
        this.setState(e, () => {
            this.setState({ pageNum: 0 }, () => {
                this.getFilteredRequests();
            });
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    isDateFilterCleared = (dateFrom, dateTo) => {
        const todayMoment = moment(new Date()).format('DD-MM-YYYY h:mm');

        return (dateFrom === todayMoment && dateTo === todayMoment);
    }

    onRangePickerChange = (e) => {
        const [from, to] = e;

        let dateFrom = moment(from).format('DD-MM-YYYY h:mm');
        let dateTo = moment(to).format('DD-MM-YYYY h:mm');
        if (this.isDateFilterCleared(dateFrom, dateTo)){
            dateFrom = '';
            dateTo = '';
        }

        this.setState({
            dateFrom,
            dateTo
        }, () =>
            this.getFilteredRequests());
    };

    onCurrentPageChange = (page) => {
        const newPageNum = page - 1; // page count starts from 0
        this.setState({
            pageNum: newPageNum
        }, () => {
            this.getFilteredRequests();
        });
    };

    onPageSizeChange = (pageSize) => {
        this.setState({
            perPage: pageSize, pageNum: 0
        }, () => this.getFilteredRequests());
    };

    get filterItems() {
        const { requestsCount: dataSize } = this.props;
        const items = [];
        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        return items;
    }

    removeDeterminant = () => {
        const { removeRequest, getDeterminants } = this.props;
        const { deleteId } = this.state;

        removeRequest(deleteId);

        this.hideDeleteModal();
    };

    hideDeleteModal = () => {
        this.setState({ infoMessageVisible: false });
    };

    createRequest = () => {
        const { addRequest } = this.props;
        const { history } = this.props;
        history.push('/request');
        addRequest({ history });
    }

    sendRequest = (id) => {
        const { setStatusRequest } = this.props;
        setStatusRequest({ requestId: id, status: 'IN_PROGRESS' });
    };

    closeRequest = (id) => {
        const { setStatusRequest } = this.props;
        setStatusRequest({ requestId: id, status: 'CLOSED' });
    };

    render() {
        const { cn } = this;
        const {
            t,
            requestsList,
            requestsCount,
            history
        } = this.props;
        const {
            infoMessageVisible,
            dateFrom,
            dateTo,
            statuses
        } = this.state;

        if (!requestsList?.length && !statuses && !dateFrom && !dateTo) {
            return (
                <div className={cn('empty')}>
                    <span>{t('empty')}</span>
                    <Button>{t('create')}</Button>
                </div>
            );
        }

        return (
            <div>
                <Form
                    onValuesChange={this.onChange}
                    onMount={this.onMount}
                    name="requests">
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className={cn('title')}>{t('requests')}</div>
                        </Col>
                    </Row>
                    <Row gutter={20} className={cn('filter-block')}>
                        <Col span={6}>
                            <Select
                                label={t('statuses')}
                                name="statuses"
                                mode="multiple"
                                placeholder=""
                                items={
                                    statusesArr.map(item => ({ value: item, title: t(item) }))
                                } />
                        </Col>
                        <Col span={6}>
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item-required">
                                        {t('date')}
                                    </label>
                                </div>
                                <div className="ant-col ant-form-item-control-wrapper">
                                    <div className="ant-form-item-control">
                                        <RangePicker
                                            className={cn('date-filter')}
                                            onChange={this.onRangePickerChange} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} />
                        <Col span={6}>
                            <Button onClick={this.createRequest}>{t('new request')}</Button>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <RequestsTable
                            filterItems={this.filterItems}
                            dataSource={requestsList}
                            dataSize={requestsCount}
                            onPageSizeChange={this.onPageSizeChange}
                            closeRequest={this.closeRequest}
                            sendRequest={this.sendRequest}
                            onCurrentPageChange={this.onCurrentPageChange} />
                        <InfoMessage
                            visible={infoMessageVisible}
                            title={t('delete title')}
                            okBtn={t('delete')}
                            cancelBtn={t('cancel')}
                            onOk={this.removeCalculator}
                            onCancel={this.hideDeleteModal} />
                    </Col>
                </Row>
            </div>

        );
    }
}