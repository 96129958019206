import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useClassName } from 'utils/cn';
import '../../../style.less';
import {
    getPesticideApplicationList,
    getPesticidesBody, getPesticidesList
} from '../../../../../models/pesticides/selectors';
import { getFertilizersList } from '../../../../../models/fertilizers/selectors';
import { getFertilizers } from '../../../../../models/fertilizers/actions';
import { getPesticides } from '../../../../../models/pesticides/actions';
import ProtectionItem from './protection-item';

const groupToNumber = {
    insecticides: 1,
    herbicides: 10,
    pesticides: 8
};

const PlantProtections = ({
    relatedCulture,
    protectionMode,
    protectionList,
    setProtectionList
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('accounting documents');
    const cn = useClassName('accounting-documents');

    const pesticidesList = useSelector(state => getPesticidesList(state));
    const fertilizersList = useSelector(state => getFertilizersList(state));

    const [protectorOptions, setProtectorOptions] = useState([]);

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [pestGroup, setPestGroup] = useState('');
    const [isAppendable, setIsAppendable] = useState(false);

    useEffect(() => {
        setIsAppendable(false);
        setCurrentPage(1);
        handlePestSearch();
    }, [pestGroup]);

    useEffect(() => {
        const getData = setTimeout(() => {
            setIsAppendable(false);
            setCurrentPage(1);
            handlePestSearch(searchString);
        }, 2000);

        return () => clearTimeout(getData);
    }, [searchString]);

    useEffect(() => {
        if (currentPage > 1) {
            setIsAppendable(true);
            handlePestSearch(searchString?.length > 0 ? searchString : null);
        }
    }, [currentPage]);

    useEffect(() => {
        if (isAppendable) {
            setProtectorOptions([...protectorOptions, ...pesticidesList?.map(x => {
                return {
                    label: x?.name,
                    value: x?.id
                };
            })]);
        } else {
            setProtectorOptions(pesticidesList?.map(x => {
                return {
                    label: x?.name,
                    value: x?.id
                };
            }));
        }
    }, [pesticidesList]);

    useEffect(() => {
        if (isAppendable) {
            setProtectorOptions([...protectorOptions, ...fertilizersList.map(x => {
                return {
                    label: x.name,
                    value: x.id
                };
            })]);
        } else {
            setProtectorOptions(fertilizersList.map(x => {
                return {
                    label: x.name,
                    value: x.id
                };
            }));
        }
    }, [fertilizersList]);

    useEffect(() => {
        setSearchString('');
        setProtectorOptions([]);
        handlePestSearch();
    }, [protectionMode]);

    const onAddProtection = () => {
        if (protectionMode === 'protect') {
            setProtectionList([...protectionList, {
                name: `protector-${protectionList.length + 1}`,
                fertilizer: 0,
                cultivated_area: 0,
                fact_amount: 0
            }]);
        } else if (protectionMode === 'fertilizer') {
            setProtectionList([...protectionList, {
                name: `fertilizer-${protectionList.length + 1}`,
                fertilizer: 0,
                cultivated_area: 0,
                fact_amount: 0,
                stage: ''
            }]);
        }
    };

    const onDeleteProtection = (arrIdx) => {
        if (protectionList.length === 1) {
            setProtectionList([]);
        } else {
            const protectionsCpy = [...protectionList];
            const deletableItem = protectionsCpy[arrIdx];
            if (deletableItem.id) {
                setProtectionList(protectionsCpy.filter(x => x.id !== deletableItem.id));
            } else if (deletableItem.name) {
                setProtectionList(protectionsCpy.filter(x => x.name !== deletableItem.name));
            }
        }
    };

    const onFieldInput = (protectorIdx, fieldName, value) => {
        const arrCpy = [...protectionList];
        switch (fieldName) {
            case 'fact':
                arrCpy[protectorIdx].fact_amount = value;
                break;
            case 'processed':
                arrCpy[protectorIdx].cultivated_area = value;
                break;
            default:
                break;
        }
        setProtectionList(arrCpy);
    };

    const onFieldSelect = (protectorIdx, fieldName, value) => {
        const arrCpy = [...protectionList];
        switch (fieldName) {
            case 'name':
                arrCpy[protectorIdx].fertilizer = value;
                break;
            case 'stage':
                arrCpy[protectorIdx].stage = value;
                break;
            default:
                break;
        }
        setProtectionList(arrCpy);
    };

    const handlePestSearch = () => {
        const urlArr = [];
        const page_size_url = `page_size=${pageSize}`;
        const page_url = `page=${currentPage}`;
        switch (protectionMode) {
            case 'fertilizer':
                urlArr.push('"is_in_state_registry":true');
                searchString?.length > 0 ? urlArr.push(`"name.icontains":"${searchString}"`) : '';
                dispatch(getFertilizers(`?${page_size_url}&${page_url}&filter={${urlArr.join(',')}}`));
                break;
            case 'protect':
                if (pestGroup?.length > 0) {
                    urlArr.push(`"group.id":${groupToNumber[pestGroup]}`);
                }
                searchString?.length > 0 ? urlArr.push(`"plant_protection_product.name.icontains":"${searchString}"`) : '';
                dispatch(getPesticides(`?${page_size_url}&${page_url}&filter={${urlArr.join(',')}}`));
                break;
            default:
                break;
        }
    };

    return (
        <div>
            {protectionList?.length > 0 && protectionList.map((item, ind) => (
                <ProtectionItem
                    key={item.name}
                    onPestSearch={handlePestSearch}
                    pestName={searchString}
                    setPestName={setSearchString}
                    setPestPage={setCurrentPage}
                    pestPage={currentPage}
                    protectorOptions={protectorOptions}
                    isNumerated={protectionList.length > 1}
                    onFieldInput={onFieldInput}
                    onFieldSelect={onFieldSelect}
                    protectionMode={protectionMode}
                    setPesticideGroup={setPestGroup}
                    deleteItem={onDeleteProtection}
                    idx={ind}
                    item={item} />
            ))}
            <Button
                onClick={onAddProtection}>
                <PlusCircleOutlined /> {protectionMode === 'protect' ? t('modal add szr') : t('modal add fertilizer')}
            </Button>
        </div>
    );
};

export default PlantProtections;