import React from 'react';
import { Image, Tooltip } from 'antd';

const ImageCard = ({ cardText, cardImage, cardId }) => {
    return (
        <div className="mycointerpretation__carousel__card" key={cardId}>
            <Image
                width={310}
                src={cardImage} />
            <Tooltip placement="bottom" title={cardText}>
                <p className="mycointerpretation__carousel__card__text">
                    {cardText}
                </p>
            </Tooltip>
        </div>
    );
};

export default ImageCard;