import React, { Component, useState } from 'react';
import {
    MapContainer,
    TileLayer,
    LayersControl,
    LayerGroup,
    WMSTileLayer
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import { CustomWMSLayer } from '../../components/layers/custom-wms';

const center = [56.0068738, 50.4447374];

export default (props) => {
    const { getData } = props;
    const { t } = useTranslation('map');
    const cn = useClassName('page');

    return (
        <MapContainer center={center} zoom={12} scrollWheelZoom={false}>
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                {/* <LayersControl.Overlay name={t('2018_2020')}>
                    <LayerGroup>
                        <WMSTileLayer url="/geoserver/teleagronom/wms?"
                                      params={{
                                          layers: 'teleagronom:2018_2020',
                                          format: 'image/png',
                                          transparent: true
                                      }} />
                    </LayerGroup>
                </LayersControl.Overlay> */}
                <LayersControl.Overlay name={t('MERGED_Uklon(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Uklon(1)"
                            legend_url="teleagronom:Uklon"
                            layer="teleagronom:MERGED_Uklon(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_Ekspoziciya(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Ekspoziciya(1)"
                            legend_url="teleagronom:Exposition"
                            layer="teleagronom:MERGED_Ekspoziciya(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_Visota(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Visota(1)"
                            legend_url="teleagronom:relief"
                            layer="teleagronom:MERGED_Visota(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_Pochvennij_sloj_Total')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_Pochvennij_sloj_Total"
                            layer="teleagronom:RT_Pochvennij_sloj_Total"
                            attributes={['Name']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2018')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2018"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2018"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2019')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2019"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2019"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2020')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2020"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2020"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Zalesenie')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Zalesenie"
                            legend_url="teleagronom:Zalesenie"
                            layer="teleagronom:Zalesenie"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('rt_zones_2018(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="rt_zones_2018(1)"
                            layer="teleagronom:rt_zones_2018(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('vvod_v_oborot')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="vvod_v_oborot"
                            legend_url="teleagronom:vvod_v_oborot"
                            layer="teleagronom:vvod_v_oborot"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Plowed_agro')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Plowed_agro"
                            legend_url="teleagronom:Zalesenie"
                            layer="teleagronom:Plowed_agro"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('istory_district')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="istory_district"
                            layer="teleagronom:istory_district"
                            attributes={['District', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_pereregistr_RT_s_rayonami')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_pereregistr_RT_s_rayonami"
                            legend_url="teleagronom:vvod_v_oborot"
                            layer="teleagronom:MERGED_pereregistr_RT_s_rayonami"
                            attributes={['Cad_Number', 'S ga', 'Cad_EZ', 'District', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('vvod_v_oborot%20bez%20cad')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="vvod_v_oborot%20bez%20cad"
                            layer="teleagronom:vvod_v_oborot%20bez%20cad"
                            attributes={['Cad_Number', 'S ga', 'Cad_EZ', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Necelevie%20na%20sx')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Necelevie%20na%20sx"
                            layer="teleagronom:Necelevie%20na%20sx"
                            legend_url="teleagronom:vvod_v_oborot"
                            attributes={['Cad_Number', 'S ga', 'Cad_EZ', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('tatar')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="tatar"
                            layer="teleagronom:tatar"
                            attributes={[t('plat_1')]}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('30km_mete_agro_fields')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="30km_mete_agro_fields"
                            layer="teleagronom:30km_mete_agro_fields"
                            attributes={['S ga']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="NMDI">
                    <LayerGroup>
                        <CustomWMSLayer
                            name="NMDI"
                            checked
                            legend_url="ndvi_ndmi_base"
                            layer="teleagronom:ndmi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="NDVI">
                    <LayerGroup>
                        <CustomWMSLayer
                            name="NDVI"
                            checked
                            legend_url="ndvi_ndmi_base"
                            layer="teleagronom:ndvi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name={t('schema')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            getData={getData}
                            name="schema"
                            checked
                            layer="teleagronom:agricultural_field_agriculturalfield"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Deleniepole_sab')}>
                    <LayerGroup>
                        <WMSTileLayer url="/geoserver/teleagronom/wms?"
                            params={{
                                layers: 'teleagronom:Deleniepole_sab',
                                format: 'image/png',
                                transparent: true
                            }} />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('ETALON_RT_precip')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="ETALON_RT_precip"
                            layer="teleagronom:ETALON_RT_precip"
                            legend_url="teleagronom:Precipitation"
                            attributes={['District', 'S ga', 'precmean', '_midTempme']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('sab_him_sostav_pochv')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="sab_him_sostav_pochv"
                            legend_url="teleagronom:Soils_gumus"
                            layer="teleagronom:sab_him_sostav_pochv"
                            attributes={['MEAN_GUM', 'PH', 'P', 'K']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('sabin_MERGED_pereregistr_RT_s_rayonami')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="sabin_MERGED_pereregistr_RT_s_rayonami"
                            layer="teleagronom:sabin_MERGED_pereregistr_RT_s_rayonami"
                            attributes={['Category', 'Cad_Number', 'S_ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('verif_sabin_Zabros_pashnya_sx_')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="verif_sabin_Zabros_pashnya_sx_"
                            layer="teleagronom:verif_sabin_Zabros_pashnya_sx_"
                            attributes={['Category', 'Cad_Number', 'S ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('verif_sabin_Necelevie_i_poterya')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="verif_sabin_Necelevie_i_poterya"
                            layer="teleagronom:verif_sabin_Necelevie_i_poterya"
                            attributes={['Category', 'Cad_Number', 'S_ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>

                <LayersControl.Overlay name={t('Verif_sabin_vvod')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Verif_sabin_vvod"
                            layer="teleagronom:Verif_sabin_vvod"
                            attributes={['Category', 'Cad_Number', 'S_ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('ver_zales_sab')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="ver_zales_sab"
                            layer="teleagronom:ver_zales_sab"
                            attributes={['Category', 'Cad_Number', 'S_ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Tochki_otbora_Sabinskiy')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Tochki_otbora_Sabinskiy"
                            layer="teleagronom:Tochki_otbora_Sabinskiy"
                            attributes={['ID', 'xcoord', 'ycoord', 'District']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Cad_sab%2Bpestr')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Cad_sab%2Bpestr"
                            layer="teleagronom:Cad_sab%2Bpestr"
                            attributes={['Cad_Number', 'Cad_EZ', 'Category', 'Type_use', 'Type_doc', 'District']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </MapContainer>
    );
};