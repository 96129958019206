import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const { fileUploadUrl } = options;

export const getCardDefaults = ({ laborantId }) => request.get({
    url: `/api/v1/agrochemical_analysis/defaults/?filter={"laborant": ${laborantId}}`
});
export const updateCardDefaults = ({ configId, values }) => request.patch({
    url: `/api/v1/agrochemical_analysis/defaults/${configId}/`,
    body: {
        ...values
    }
});
export const getSamplesList = ({ page, pageSize = 1000, filterString = '' }) => request.get({
    url: `${url}/?page=${page}&page_size=${pageSize}${filterString?.length ? `&filter={${filterString}}` : ''}`
});

export const getDownloadableDataset = ({ filterString }) => request.get({
    url: `${url}/?page=${1}&page_size=${1000000}${filterString?.length ? `&filter={${filterString}}` : ''}`
});

export const getSampleById = ({ sampleRowId }) => request.get({
    url: `${url}${sampleRowId}/`
});

export const editSampleRow = ({ body, sampleRowId }) => request.patch({
    url: `${url}${sampleRowId}/`,
    body: body
});

export const uploadSampleFile = ({ uploadableFile, type, analysisId }) => request.post({
    url: `${fileUploadUrl}`,
    body: {
        file: uploadableFile,
        analysis: analysisId
    },
    type: type
});

export const createSampleRow = body => request.post({
    url: url,
    body: body
});

export const createRowCopy = body => request.post({
    url: url,
    body: body
});

export const deleteSampleRow = body => request.remove({
    url: `${url}${body.rowId}/`
});

export const manageSampleRowPublication = body => request.post({
    url: `/api/v1/agrochemical_analysis/analysis/${body.rowId}/publish_analysis/`,
    body: {
        public: body.public
    }
});

export const addOwnerSampleLog = body => request.post({
    url: '/api/v1/teleagronom_user/owners/',
    body: body.values
});

export const getUsersList = body => request.get({
    url: `/api/v1/teleagronom_user/owners/?page_size=1000&page=${body?.page}${body?.searchString ? `&filter={"name.icontains": "${body.searchString}" }` : ''}`
});