import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Layout, Spin, Select, Row, Col
} from 'antd';

import cn from '../../utils/cn';

import {
    InfoMessage, SideBar, TableFooter, PanelLayout, Title
} from '../../components';

import CalculatorsTable from './components/calculators-table';

import './style.less';

const { PanelFooter, PanelHeader, PanelContent } = PanelLayout;

@withTranslation('mordovia')
@cn('all-page')
export default class AllCalculators extends Component {
    render() {
        const { cn } = this;
        const { t } = this.props;

        return (
            <Layout className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <CalculatorsTable />
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}