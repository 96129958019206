import { getConstant } from '../utils';

export const getPivotTableByField = getConstant('GET_PIVOT_TABLE_BY_FIELD');
export const getPivotTableMapData = getConstant('GET_PIVOT_TABLE_MAP_DATA');
export const getPivotTableByFieldTotal = getConstant('GET_PIVOT_TABLE_BY_FIELD_TOTAL');
export const getPivotTableByCulture = getConstant('GET_PIVOT_TABLE_BY_CULTURE');
export const getPivotTableByCultureTotal = getConstant('GET_PIVOT_TABLE_BY_CULTURE_TOTAL');
export const getPivotByAgroTechicalPeriod = getConstant('GET_PIVOT_TABLE_BY_AGROTECHNICAL_PERIOD');
export const getPivotByAgroTechicalFields = getConstant('GET_PIVOT_TABLE_BY_AGROTECHNICAL_FIELDS');
export const clearPivotTable = getConstant('CLEAR_PIVOT_TABLE');
