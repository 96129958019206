import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Row, Tabs } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import LeafDiagnostics from './index';

import './style.less';
import moment from 'moment';
import { calculatorsGroup, curPoints, getCurrentCalculatorMordovia } from '../../../../models/mordovia/selectors';
import { getProtocol } from '../../../../models/fed-registry/actions';
import { curProtocol } from '../../../../models/fed-registry/selectors';
import { toFixed } from '../../../../utils/utils';
import { removeCalculate } from '../../../../models/item-calculate/actions';

const elementsArray = [
    'k2', 'n', 'p', 'ks', 'kcl', 'k3', 'ca', 'mg', 'b',
    'cu', 'k4', 'zn', 'mn', 'fe', 'mo', 'k5', 'co', 'i', 'k6'
];

const { TabPane } = Tabs;

const LeafInterpetation = ({

}) => {
    const { t } = useTranslation('leaf diagnostics');
    const { t: tTable } = useTranslation('fed registry');
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const calcGroup = useSelector(state => calculatorsGroup(state));
    const currentCalculator = useSelector(state => getCurrentCalculatorMordovia(state));
    const currentFedProtocol = useSelector(state => curProtocol(state));

    const [calculatorsList, setCalculatorsList] = useState([]);
    const [currentSelectedCalculator, setCurrentSelectedCalculator] = useState({});

    useEffect(() => {
        const calcYear = currentCalculator?.year_group?.years?.filter(yearObj => yearObj?.year?.toString() === match.params.year?.toString());
        if (calcYear?.[0]) {
            const calcList = calcYear?.[0]?.calculator_group?.calculators?.filter(calc => calc?.calculator_type === 12);
            setCalculatorsList(calcList);
        }
    }, [currentCalculator]);

    const deleteCalcNumber = (record) => {
        dispatch(removeCalculate(record?.id));
        
        const calcYear = currentCalculator?.year_group?.years?.filter(yearObj => yearObj?.year?.toString() === match.params.year?.toString());

        if (calcYear[0]) {
            const calcList = calcYear[0]?.calculator_group?.calculators?.filter(calc => calc?.calculator_type === 12 && calc?.id !== record?.id);
            setCalculatorsList(calcList);
        }

        setRequestLock(false);
    };

    const [requestLock, setRequestLock] = useState(false);

    const columns = [
        {
            title: t('table header type'),
            key: 'type',
            dataIndex: 'type',
            render: val => t(`table type ${val}`)
        },
        {
            title: t('table header date'),
            key: 'date',
            dataIndex: 'date',
            render: val => val ? moment(val).format('DD.MM.YYYY') : '-'
        },
        {
            title: t('table header points'),
            key: 'points',
            dataIndex: 'points'
        },
        {
            title: t('table header zones'),
            key: 'zones',
            dataIndex: 'zones',
            render: val => typeof val === 'string' ? t(`zone select ${val}`) : val?.length > 0 ? val?.map(x => t(`zone select ${x}`))?.join(', ') : ''
        },
        ...elementsArray?.map(elmnt => {
            return {
                title: t(`table element ${elmnt}`),
                key: elmnt,
                children: [
                    {
                        title: t('before lighting'),
                        key: ['fed', `c_${elmnt}`],
                        dataIndex: ['fed', `c_${elmnt}`]
                    },
                    {
                        title: t('after lighting'),
                        key: ['fed', `d_${elmnt}`],
                        dataIndex: ['fed', `d_${elmnt}`]
                    }
                ]
            }
        }),
        {
            key: 'actions',
            render: value => (
                <DeleteOutlined
                    onClick={() => {
                        setRequestLock(true);
                        deleteCalcNumber(value);
                    }} />
            )
        }
    ];

    const openCurrentCalc = (currCalc) => {
        console.log('OPENING CURRENT CALC', currCalc);
        if (currCalc?.fed?.fed_protocol) {

        }
        dispatch(getProtocol({ id: currCalc?.fed?.fed_protocol  }));
        setCurrentSelectedCalculator(currCalc?.fed);
    };

    const fertilizerColumns = [
        {
            title: tTable('name'),
            dataIndex: ['fertilizer', 'name'],
            key: 'name',
            // render: (text, record) => (
            //     <Tooltip
            //         placement="bottomLeft"
            //         title={text}>
            //         {text}
            //     </Tooltip>
            // )

        },
        {
            title: tTable('table elements header group'),
            children: elementsArray?.map(x => {
                return {
                    title: tTable(`table element ${x}`),
                    dataIndex: ['fertilizer', x?.toLowerCase()],
                    key: x?.toLowerCase(),
                    render: value => toFixed(value, 2)
                };
            })
        },
        {
            title: tTable('table fertilizer price group'),
            children: [
                {
                    title: tTable('table min'),
                    dataIndex: ['fertilizer', 'consumption_rate_min'],
                    key: 'consumption_rate_min'
                },
                {
                    title: tTable('table max'),
                    dataIndex: ['fertilizer', 'consumption_rate_max'],
                    key: 'consumption_rate_max'
                }
            ]
        },
        {
            title: tTable('avg_price'),
            dataIndex: ['price'],
            key: 'avg_price',
            sortType: 'number',
            render: (value, record) => record?.price ? value : record?.fertilizer?.price
        },
        {
            title: tTable('table consumption header group'),
            children: [
                {
                    title: tTable('table min'),
                    dataIndex: 'calc_cons_rate_min',
                    key: 'calc_cons_rate_min',
                    width: 100
                },
                {
                    title: tTable('table max'),
                    dataIndex: 'calc_cons_rate_max',
                    key: 'calc_cons_rate_max',
                    width: 100
                },
                {
                    title: tTable('table opt'),
                    dataIndex: 'calc_cons_rate_opt',
                    key: 'calc_cons_rate_opt',
                    width: 100
                }
            ]
        },
        {
            title: tTable('table price header group'),
            children: [
                {
                    title: tTable('table min'),
                    dataIndex: 'calc_price_min',
                    key: 'calc_price_min',
                    width: 100
                },
                {
                    title: tTable('table max'),
                    dataIndex: 'calc_price_max',
                    key: 'calc_price_max',
                    width: 100
                },
                {
                    title: tTable('table opt'),
                    dataIndex: 'calc_price_opt',
                    key: 'calc_price_opt',
                    width: 100
                }
            ]
        },
        //
        {
            title: tTable('table price area header group'),
            children: [
                {
                    title: tTable('table min'),
                    dataIndex: 'area_price_min',
                    key: 'area_price_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: tTable('table max'),
                    dataIndex: 'area_price_max',
                    key: 'area_price_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: tTable('table opt'),
                    dataIndex: 'area_price_opt',
                    key: 'area_price_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        {
            title: tTable('table norm area header group'),
            children: [
                {
                    title: tTable('table min'),
                    dataIndex: 'area_cons_rate_min',
                    key: 'area_cons_rate_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: tTable('table max'),
                    dataIndex: 'area_cons_rate_max',
                    key: 'area_cons_rate_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: tTable('table opt'),
                    dataIndex: 'area_cons_rate_opt',
                    key: 'area_cons_rate_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        {
            title: tTable('table rate'),
            dataIndex: 'rate',
            key: 'rate'
        },
    ];

    useEffect(() => {
        console.log('calculatorsList UPDATE', calculatorsList);
    }, [calculatorsList]);

    return (
        <div>
            <Table
                rowClassName={(record, index) => record?.fed?.id === currentSelectedCalculator?.id ? 'selected-row' : ''}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        if (requestLock) {
                            event?.preventDefault();
                        } else {
                            openCurrentCalc(record);
                        }
                      }
                    };
                }}
                rowKey="id"
                bordered
                columns={columns}
                dataSource={calculatorsList}
                scroll={{ x: 1500 }} />

            <Tabs defaultActiveKey="1">
                {calculatorsList?.map(calcItem => calcItem?.fed_fertilizers?.length > 0 ? (
                    <>
                        <TabPane
                            tab={`${calcItem?.type === 'field' ? `${t('field')}` : calcItem?.points?.length > 0 ? `${calcItem?.points?.length === 1 ? `${t('a point')} - ${calcItem?.points[0]}` : `${t('points')} - [${calcItem?.points?.join(', ')}]`}` : `${calcItem?.zones?.length === 1 ? `${t('zone')} - ${calcItem?.zones[0]}` : `${t('zones')} - ${calcItem?.zones?.map(x => t(`zone select ${x}`))?.join(', ')}`}`}`}
                            key={calcItem?.fed?.id}>
                            <LeafDiagnostics setCurrentCalc={setCurrentSelectedCalculator} currentCalc={calcItem?.fed} />
                        </TabPane>
                        <Row style={{ marginTop: '15px' }}>
                            <b>{t('recomm subhead')}</b>
                        </Row>
                        <Table bordered columns={fertilizerColumns} dataSource={currentFedProtocol?.fed_fertilizers} scroll={{ x: 1500 }} />
                    </>
                ) : (
                    <TabPane
                        tab={`${calcItem?.type === 'field' ? `${t('field')}` : calcItem?.points?.length > 0 ? `${calcItem?.points?.length === 1 ? `${t('a point')} - ${calcItem?.points[0]}` : `${t('points')} - [${calcItem?.points?.join(', ')}]`}` : `${calcItem?.zones?.length === 1 ? `${t('zone')} - ${calcItem?.zones[0]}` : `${t('zones')} - ${calcItem?.zones?.map(x => t(`zone select ${x}`))?.join(', ')}`}`}`}
                        key={calcItem?.fed?.id}>
                        <LeafDiagnostics setCurrentCalc={setCurrentSelectedCalculator} currentCalc={calcItem?.fed} />
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default LeafInterpetation;