import * as constants from './constants';

export const getCalculatorsYear = payload => ({
    type: constants.getCalculatorsYear.toString(),
    payload
});

export const getCalculatorsYearItem = payload => ({
    type: constants.getCalculatorsYearItem.toString(),
    payload
});