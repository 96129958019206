import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { Collapse } from 'antd';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import CalculatorData from './calculator-data';
import CalculatorParams from './calculator-params';
import CalculatorCalc from './calculator-calc';
import { selectors, actions } from '../../../models';
import * as constants from '../../../constants';

import CalculatorIcon from '../../../../public/images/calculator/calculator2_stroke.svg';
import CheckIcon from '../../../../public/images/calculator/check-square.svg';
import MoreIcon from '../../../../public/images/calculator/more-info.svg';
import ApplicationIcon from '../../../../public/images/calculator/application_stroke.svg';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';


const {
    getCalculatorFed,
    clearCalculatorFed
} = actions;
const {
    getCurrentCalculatorFed
} = selectors;

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFed(state)
});
const mdtp = ({
    getCalculatorFed,
    clearCalculatorFed
});


@withRouter
@withTranslation('calculator detail')
@connect(mstp, mdtp)
@cn('calculator-detail')
export default class CalculatorDetail extends Component {
    state = {
        name: false,
        id: null,
        activeKeys: []
    };

    componentDidMount() {
        const {
            getCalculatorFed, clearCalculatorFed, match, calculator
        } = this.props;
        const { id } = match.params;
        const { activeKeys } = this.state;
        this.setState({ id });
        const arr = [...activeKeys];
        this.setState({ activeKeys: [...arr] });
        clearCalculatorFed();
        id && getCalculatorFed(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            calculator, history, match, clearCalculatorFed
        } = this.props;
        const { id } = match.params;
        const { activeKeys } = this.state;

        if (!id && (prevProps.match.url !== match.url)) {
            clearCalculatorFed();
            this.setName();
        }

        if (!id && (prevProps.calculator !== calculator) && !!calculator.id) {
            history.push(`/calculator-fed/${calculator.id}`);
        }
    }

    setName = (name) => {
        this.setState({ name });
    };

    changeActiveKeys = (key) => {
        this.setState({ activeKeys: [...key] });
    };

    render() {
        const { cn } = this;
        const {
            t, calculator, loading, match
        } = this.props;
        const { fertilizerType } = calculator;
        const {
            name, activeKeys
        } = this.state;
        const { id } = match.params;
        const isCompleted = calculator.status === constants.STATUS_COMPLETED;
        const isResult = (calculator && calculator.mean) || null;

        return (
            <PanelLayout loading={loading}>
                <PanelHeader>
                    <Title title={calculator.name || name || t('calculator name')} />
                </PanelHeader>
                <PanelContent>
                    <Collapse activeKey={activeKeys}
                        onChange={this.changeActiveKeys}
                        expandIconPosition="right"
                        className={cn('collapse')}>
                        <Panel
                            header={(
                                <b>
                                    <Icon component={ListIcon} />
                                    {t('calculator data')}
                                </b>
                            )}
                            key="1">
                            <CalculatorData
                                id={id}
                                isCompleted={isCompleted}
                                calculator={calculator}
                                onTabChange={this.onTabChange}
                                setName={this.setName} />
                        </Panel>
                        <Panel
                            disabled={!id}
                            header={(
                                <b>
                                    <Icon component={CalculatorIcon} />
                                    {t('calculator parameters')}
                                </b>
                            )}
                            key="2">
                            <CalculatorParams
                                id={id}
                                isCompleted={isCompleted}
                                calculator={calculator}
                                onTabChange={this.onTabChange} />
                        </Panel>
                        <Panel
                            disabled={calculator.stress_level === undefined || calculator.stress_level === null}
                            header={(
                                <b>
                                    <Icon component={ListIcon} />
                                    {t('calculator calculate')}
                                </b>
                            )}
                            key="3">
                            <CalculatorCalc
                                id={id}
                                isCompleted={isCompleted}
                                calculator={calculator}
                                onTabChange={this.onTabChange} />
                        </Panel>
                    </Collapse>
                </PanelContent>
            </PanelLayout>
        );
    }
}