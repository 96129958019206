import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Checkbox, Col, DatePicker, Form, Input, Row, Select
} from 'antd';
import { useTranslation } from 'react-i18next';

// import { useClassName } from 'utils/cn';

import '../style.less';

import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../models/account/selectors';
import { getAgroPoints, getFedPoints, getProtocol } from '../../../models/general-protocol/actions';
import { curMycoFields } from '../../../models/mycological-registry/selectors';
import { getFilteredFields } from '../../../models/mycological-registry/actions';
import { getUserList } from '../../../models/user/selectors';
import { getUsersList } from '../../../models/sample-log/actions';
import { usrListResponse } from '../../../models/sample-log/selectors';
import { getCalculatorsMordoviaCatalog } from '../../../models/mordovia/selectors';
import { curAgroPoints, curFedPoints } from '../../../models/general-protocol/selectors';

const { RangePicker } = DatePicker;

const Parameters = ({
    setGroupingMethod, setSelectedUserNames,
    triedProtocol, setTriedProtocol,
    activeTabs, setActiveTabs,
    setSelectedField, setSelectedYear, setSelectedProd,
    setSelectedOwner, updateParentUsers, setOwnerName,
    setSelectedFieldNames, setSelectedYearNames,
    setSelectedProductivityNames
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    // const cn = useClassName('calculators');
    const { t } = useTranslation('general protocol');

    const [selectedFields, setSelectedFields] = useState([]);

    const [availableFieldYears, setAvailableFieldYears] = useState([]);

    const [fieldsList, setFieldsList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [userPage, setUserPage] = useState(1);

    const [fedPoints, setFedPoints] = useState([]);
    const [agroPoints, setAgroPoints] = useState([]);
    const [fedPage, setFedPage] = useState(1);
    const [agroPage, setAgroPage] = useState(1);

    const users = useSelector(state => usrListResponse(state));
    const currentUser = useSelector(state => getUser(state));
    const fields = useSelector(state => curMycoFields(state));
    const calcFields = useSelector(state => getCalculatorsMordoviaCatalog(state));

    const currentFedPoints = useSelector(state => curFedPoints(state));
    const currentAgroPoints = useSelector(state => curAgroPoints(state));

    const currentDate = new Date().getFullYear();
    const firstDate = currentDate - 10;
    let yearIteraror = currentDate;
    const yearOptionPlaceholder = [];
    while (yearIteraror >= firstDate) {
        yearOptionPlaceholder.push({
            label: yearIteraror,
            value: yearIteraror
        });
        yearIteraror--;
    }

    useEffect(() => {
        const yearsArray = [];
        const filteredFlds = fieldsList?.filter(fld => selectedFields?.includes(fld?.field_num));

        filteredFlds?.forEach(fld => {
            fld?.years?.forEach(yr => {
                if (!yearsArray?.includes(yr)) {
                    yearsArray.push(yr);
                }
            });
        });
        setAvailableFieldYears([...yearsArray]);
    }, [selectedFields]);

    useEffect(() => {
        dispatch(getAgroPoints(agroPage));
    }, [agroPage]);

    useEffect(() => {
        dispatch(getFedPoints(fedPage));
    }, [fedPage]);

    useEffect(() => {
        const fedCpy = [...fedPoints];
        const listPortion = currentFedPoints?.results;
        listPortion?.forEach(listEl => {
            const foundEl = fedCpy?.filter(fedEl => fedEl?.point_number === listEl?.point_number)[0];
            if (!foundEl?.id) {
                fedCpy.push(listEl);
            }
        });
        setFedPoints([...fedCpy]);

        if (currentFedPoints?.next?.length > 0) {
            setFedPage(fedPage + 1);
        }
    }, [currentFedPoints]);

    useEffect(() => {
        const agroCpy = [...agroPoints];
        const listPortion = currentAgroPoints?.results;
        listPortion?.forEach(listEl => {
            const foundEl = agroCpy?.filter(agroEl => agroEl?.point_number === listEl?.point_number)[0];
            if (!foundEl?.id) {
                agroCpy.push(listEl);
            }
        });
        setAgroPoints([...agroCpy]);

        if (currentAgroPoints?.next?.length > 0) {
            setAgroPage(agroPage + 1);
        }
    }, [currentAgroPoints]);

    useEffect(() => {
        const listPortion = users?.results;
        if (listPortion?.length > 0) {
            setUsersList([...usersList, ...listPortion]);
        }
        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUserPage(userPage + 1);
        }
    }, [users]);

    useEffect(() => {
        dispatch(getUsersList({
            page: userPage,
            searchString: ''
        }));
    }, [userPage]);

    useEffect(() => {
        const fieldsCpy = [...fieldsList];

        const fieldKeys = Object?.keys(fields);
        fieldKeys.filter(x => x !== 'next').forEach(fieldKey => {
            fieldsCpy.push(fields[fieldKey]);
        });
        setFieldsList([...fieldsCpy]);
    }, [fields]);

    useEffect(() => {
        updateParentUsers(usersList);
    }, [usersList]);

    const handleCreateProtocol = () => {
        const {
            agro_points,
            analysis_date,
            fed_points,
            field_nums,
            owner_user,
            productivity_zones,
            protocol_types,
            years
        } = form?.getFieldsValue();

        if (protocol_types?.length > 0) {
            setTriedProtocol(true);
            let dateFrom;
            let dateTo;

            if (analysis_date && analysis_date[0] && analysis_date[1]) {

                dateFrom = analysis_date[0]?.format('YYYY-MM-DD');
                dateTo = analysis_date[1]?.format('YYYY-MM-DD');
                
            }

            if (currentUser?.id) {
                dispatch(getProtocol({
                    userId: owner_user,
                    values: {
                        field_nums: field_nums?.length > 0 ? field_nums : undefined,
                        years: years?.length > 0 ? years?.map(yr => parseInt(yr, 10)) : undefined,
                        productivity_zones: productivity_zones?.length > 0 ? productivity_zones : undefined,
                        fed_points: fed_points?.length > 0 ? fed_points?.map(point => parseInt(point, 10)) : undefined,
                        agro_points: agro_points?.length > 0 ? agro_points?.map(point => parseInt(point, 10)) : undefined,
                        protocol_types: protocol_types?.length > 0 ? protocol_types : undefined,
                        analysis_date_min: dateFrom ? dateFrom : undefined,
                        analysis_date_max: dateTo ? dateTo : undefined
                    }
                }));
            }

            if (protocol_types?.length > 0) {
                setTimeout(() => {
                    setActiveTabs([...protocol_types, 'parameters']);
                }, 300);
            }
        }
    };

    const compareOpts = (a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    };

    const handleFormChange = (updatedVal, allVal) => {
        const updatedField = Object?.keys(updatedVal)[0];

        switch (updatedField) {
            case 'protocol_types':
                handleCreateProtocol();
                break;
            case 'field_nums':
                const selFieldNames = updatedVal[updatedField]?.map(fldNum => {
                    const foundField = fieldsList?.filter(fld => fld?.field_num === fldNum)[0];
                    return foundField?.vega_key;
                });
                setSelectedField(selFieldNames);
                break;
            case 'years':
                setSelectedYear(updatedVal[updatedField]);
                break;
            case 'productivity_zones':
                setSelectedProd(updatedVal[updatedField]);
                break;
            case 'owner_user':
                const selectedOwner = updatedVal[updatedField].map(usrId => usersList?.filter(usr => usr?.id === usrId)[0]);
                setOwnerName(selectedOwner?.map(x => x?.name)?.join(', '));
                setSelectedOwner(selectedOwner?.map(x => x?.id));
                setFieldsList([]);
                dispatch(getFilteredFields({
                    userId: selectedOwner?.map(x => x?.id),
                    pageSize: 10000
                }));
                break;
            default:
                break;
        }
    };

    const protocolTypeOptions = [
        {
            label: 'Агрохимия',
            value: 'agro'
        },
        {
            label: 'ФЭД',
            value: 'fed'
        },
        {
            label: 'ИМАП',
            value: 'imap'
        },
        {
            label: 'ФСМ',
            value: 'fito'
        },
    ];

    const productivityZoneOptions = [
        {
            label: t('productivity zone high'),
            value: 'high'
        },
        {
            label: t('productivity zone middle'),
            value: 'middle'
        },
        {
            label: t('productivity zone low'),
            value: 'low'
        }
    ];

    const orderingParameters = [
        {
            label: t('grouping field'),
            value: 'field'
        },
        {
            label: t('grouping year'),
            value: 'year'
        }
    ];

    const resetFilters = () => {
        const { owner_user } = form?.getFieldsValue();
        form?.resetFields();
        setActiveTabs(['parameters']);
        dispatch(getProtocol({
            userId: owner_user,
            values: {
                field_nums: undefined,
                years: undefined,
                productivity_zones: undefined,
                fed_points: undefined,
                agro_points: undefined,
                protocol_types: [],
                analysis_date_min: undefined,
                analysis_date_max: undefined
            }
        }));
    };

    return (
        <div>
            <Form onValuesChange={handleFormChange} form={form}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="owner_user"
                            label={t('parameters owner_user')}>
                            <Select
                                style={{ maxWidth: '100%', overflow: 'hidden' }}
                                mode="multiple"
                                showSearch
                                onChange={(val1, labeledOptions) => setSelectedUserNames(labeledOptions?.map(opt => opt?.label))}
                                filterOption={(input, option) => {
                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                }}
                                filterSort={(optionA, optionB) => {
                                    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                                }}
                                options={usersList?.length > 0 ? usersList?.map(usr => {
                                    return {
                                        label: usr?.name,
                                        value: usr?.id
                                    };
                                }).sort(compareOpts) : []} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="field_nums"
                            label={t('parameters field_nums')}>
                            <Select
                                mode="multiple"
                                onChange={(val1, labeledOptions) => {
                                    setSelectedFields(val1);
                                    setSelectedFieldNames(labeledOptions?.map(opt => opt?.label));
                                }}
                                options={fieldsList?.map(fld => {
                                    return {
                                        key: fld?.id,
                                        label: `${fld?.vega_key ? fld?.vega_key : '"-"'} - ${fld?.field_num ? fld?.field_num : '"-"'}`,
                                        value: fld?.field_num
                                    };
                                })} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="years"
                            label={t('parameters years')}>
                            <Select
                                mode="multiple"
                                onChange={(val1, labeledOptions) => setSelectedYearNames(labeledOptions?.map(opt => opt?.label))}
                                options={availableFieldYears?.length > 0 ? availableFieldYears?.map(yr => {
                                    return {
                                        label: yr,
                                        value: yr
                                    };
                                }) : yearOptionPlaceholder} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="productivity_zones"
                            label={t('parameters productivity_zones')}>
                            <Select
                                onChange={(val1, labeledOptions) => setSelectedProductivityNames(labeledOptions?.map(opt => opt?.label))}
                                mode="multiple"
                                options={[...productivityZoneOptions]} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="fed_points"
                            label={t('parameters fed_points')}>
                            <Select
                                options={fedPoints?.map(point => {
                                    return {
                                        label: point?.point_number,
                                        value: point?.point_number
                                    };
                                })}
                                mode="tags"
                                tokenSeparators={[',']} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="agro_points"
                            label={t('parameters agro_points')}>
                            <Select
                                options={agroPoints?.map(point => {
                                    return {
                                        label: point?.point_number,
                                        value: point?.point_number
                                    };
                                })}
                                mode="tags"
                                tokenSeparators={[',']} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="protocol_types"
                            label={t('parameters protocol_types')}>
                            <Select mode="multiple" options={protocolTypeOptions} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="analysis_date"
                            label={t('parameters analysis_date')}>
                            <RangePicker format="DD.MM.YYYY" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="order_by"
                            label={t('parameters order_by')}>
                            <Select onSelect={setGroupingMethod} options={orderingParameters} />
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 12 }}
                            name="show_culture"
                            label={t('parameters show_culture')}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 16 }}
                            name="show_productivity"
                            label={t('parameters show_productivity')}>
                            <Checkbox />
                         </Form.Item>
                    </Col>
                </Row> */}
            </Form>

            <Row align="center">
                <Button
                    type="primary"
                    onClick={handleCreateProtocol}>
                    {t('parameters create')}
                </Button>
                <Col span={1} />
                <Button type="secondary" onClick={resetFilters}>
                    {t('parameters reset')}
                </Button>
            </Row>
        </div>
    );
};

export default Parameters;