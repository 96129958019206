import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Icon from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import {
    Row, Col, InputNumber, Form, Input, Select
} from 'antd';

import moment from 'moment';
import cn from '../../../utils/cn';
import {
    Button, Table, SaveMessage, CatalogTitle
} from '../../../components';

import { selectors, actions } from '../../../models';

import PesticidesTable from './pesticides/pesticide-table';
import PesticideModal from './pesticides/pesticide-modal';
import * as constants from './constants';
import * as constantsCommon from '../../../constants';
import { rus2Latin } from '../../../utils/utils';

const {
    closeCalculatorFluid,
    getPesticideProductApplication,
    getPesticideApplicationArea,
    getPesticideChemicalClasses,
    getPesticideHarmfulObject,
    getPesticideHazardClass,
    getPesticidePlaceOfProduction,
    getPesticideGroup,
    getPesticideMainDrug,
    getPesticidePreparativeForm,
    getPesticideTreatmentType,
    setPlantProtectionCalculatorFluid,
    getCultures
} = actions;
const {
    getCurrentProtectionProductCalculatorFluid,
    getCurrentCalculatorFluid,
    getPesticideApplicationList,
    getPesticideApplicationCount,
    getPesticideApplicationAreaList,
    getPesticideChemicalClassesList,
    getPesticideHarmfulObjectList,
    getPesticideHazardClassList,
    getPesticidePlaceOfProductionList,
    getPesticideGroupList,
    getPesticideMainDrugList,
    getPesticidePreparativeFormList,
    getPesticideTreatmentTypeList,
    getCulturesCatalog
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFluid(state),
    pesticide: getCurrentProtectionProductCalculatorFluid(state),
    pesticidesList: getPesticideApplicationList(state),
    pesticidesCount: getPesticideApplicationCount(state),
    applicationArea: getPesticideApplicationAreaList(state),
    chemicalClasses: getPesticideChemicalClassesList(state),
    harmfulObjects: getPesticideHarmfulObjectList(state),
    hazardClasses: getPesticideHazardClassList(state),
    placeOfProduction: getPesticidePlaceOfProductionList(state),
    groups: getPesticideGroupList(state),
    cultures: getCulturesCatalog(state),
    mainDrug: getPesticideMainDrugList(state),
    preparativeForms: getPesticidePreparativeFormList(state),
    treatmentTypes: getPesticideTreatmentTypeList(state)
});
const mdtp = ({
    closeCalculatorFluid,
    getPesticideProductApplication,
    getPesticideApplicationArea,
    getPesticideChemicalClasses,
    getPesticideHarmfulObject,
    getPesticideHazardClass,
    getPesticidePlaceOfProduction,
    getPesticideGroup,
    getPesticideMainDrug,
    getPesticidePreparativeForm,
    getPesticideTreatmentType,
    setPlantProtectionCalculatorFluid,
    getCultures
});


const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_FERTILIZER = { 1: 'N', 2: 'P', 3: 'K' };
const REG_EXP = /^[0-9]*[.]?[0-9]+$/;

@withRouter
@connect(mstp, mdtp)
@withTranslation('pesticides')
@cn('calculator-detail-pesticide')
export default class CalculatorData extends Component {
    formRef = React.createRef();

    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        page_size: 10,
        page: 1,
        group: null,
        showFilter: true,
        setFilter: false,
        showInfo: false,
        showPesticide: {},
        selectedPesticide: undefined,
        name: undefined
    };

    componentDidMount() {
        const {
            getPesticideApplicationArea,
            getPesticideChemicalClasses,
            getPesticideHarmfulObject,
            getPesticideHazardClass,
            getPesticidePlaceOfProduction,
            getPesticideGroup,
            getPesticideMainDrug,
            getPesticideProductApplication,
            getPesticidePreparativeForm,
            getPesticideTreatmentType,
            getCultures,
            calculator,
            pesticide
        } = this.props;
        const { plant_protection_product_application } = calculator;
        this.getFilteredPesticide();
        getPesticideApplicationArea('');
        getPesticideChemicalClasses('?page_size=1000');
        getPesticideHarmfulObject('');
        getPesticideHazardClass('?page_size=1000');
        getPesticidePlaceOfProduction('');
        getPesticideGroup('');
        getPesticideMainDrug('');
        getPesticidePreparativeForm('?page_size=1000');
        getPesticideTreatmentType('?page_size=1000');
        getCultures('?page_size=1000');
        if (plant_protection_product_application) {
            this.setState({
                selectedPesticide: pesticide,
                normFluidFlowRate: calculator.fluid_consumption,
                normOfTheDrug: calculator.rate_of_use,
                showFilter: false,
                setFilter: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            calculator, history, match, clearCalculatorFluid
        } = this.props;
        const { id } = match.params;

        if (!id) {
            history.push(`/calculator-fluid/${calculator.id}`);
        }
    }

    getFilteredPesticide = () => {
        const { getPesticideProductApplication } = this.props;
        const {
            page_size,
            page,
            group,
            hazard_class,
            name,
            main_drug,
            culture,
            preparativeForm,
            treatment_type,
            harmful_object,
            chemical_class,
            price_from,
            price_to
        } = this.state;
        const urlArr = [];
        urlArr.push('"plant_protection_product.is_in_state_registry":true');
        name ? urlArr.push(`"plant_protection_product.name.icontains":"${name}"`) : '';
        price_from ? urlArr.push(`"plant_protection_product.related_sellers.price.gte":${price_from}`) : '';
        price_to ? urlArr.push(`"plant_protection_product.related_sellers.price.lte":"${price_to}"`) : '';
        main_drug ? urlArr.push(`"plant_protection_product.related_main_drugs.main_drug.name.icontains":"${main_drug}"`) : '';
        preparativeForm ? urlArr.push(`"plant_protection_product.preparative_form.id":"${preparativeForm}"`) : '';
        treatment_type ? urlArr.push(`"treatment_type.id":${treatment_type}`) : '';
        culture ? urlArr.push(`"related_cultures.culture.id":${culture}`) : '';
        harmful_object ? urlArr.push(`"related_harmful_objects.harmful_object.name.icontains":"${harmful_object}"`) : '';
        group ? urlArr.push(`"plant_protection_product.group.id":${group}`) : '';
        hazard_class ? urlArr.push(`"plant_protection_product.hazard_class.id":${hazard_class}`) : '';
        chemical_class ? urlArr.push(`"plant_protection_product.related_chemical_classes.chemical_class.id":${chemical_class}`) : '';
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        getPesticideProductApplication(`?${page_size_url}&${page_url}&filter={${urlArr.join(',')}}`);
    };

    onChange = (value, values) => {
        this.setState(value, () => {
            this.setState({ setFilter: true, page: 1 }, () => {
                this.getFilteredPesticide();
            });
        });
    };

    onChangeNumberDrug = (value) => {
        if (REG_EXP.test(value) || value === '') {
            this.setState({ normOfTheDrug: value });
        }
    };

    onChangeNumberFlow = (value) => {
        if (REG_EXP.test(value) || value === '') {
            this.setState({ normFluidFlowRate: value });
        }
    };

    setStatusComplete = () => {
        const { closeCalculatorFluid, calculator } = this.props;
        const { id } = calculator;
        closeCalculatorFluid(id);
        this.hideModal();
    };

    hideModal = () => {
        this.setState({ visible: false, showInfo: false });
    };

    hideInfo = () => {
        this.setState({ showInfo: false });
    };

    onCurrentPageChange = (page) => {
        this.setState({
            page
        }, () => {
            this.getFilteredPesticide();
        });
    };

    onPageSizeChange = (page_size) => {
        this.setState({
            page_size, page: 1
        }, () => this.getFilteredPesticide());
    };

    get filterItems() {
        const { pesticidesCount: dataSize } = this.props;
        const items = [];
        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        return items;
    }

    setPesticide = () => {
        const {
            selectedPesticide,
            normFluidFlowRate,
            normOfTheDrug
        } = this.state;
        const {
            updateCalculatorFluid,
            setPlantProtectionCalculatorFluid,
            calculator,
            onTabChange
        } = this.props;
        const { id: pesticideId } = selectedPesticide;
        const { id } = calculator;
        const values = {
            plant_protection: pesticideId,
            rate_of_use: normOfTheDrug,
            fluid_consumption: normFluidFlowRate
        };
        this.setState({ visible: true });
        setPlantProtectionCalculatorFluid({ id, values });
    };

    onSetPesticide = (item) => {
        if (item) {
            const { calculator } = this.props;
            const { fluid_consumption_rate_for_rod: norm } = calculator;
            const pesticideNorm = 0;
            const normOfTheDrug = Number(item.rate_of_use_min.toString().replace(/,/g, '.'));
            const normFluidFlowRate = Number(item.fluid_consumption_min.toString().replace(/,/g, '.'));
            this.setState({
                showFilter: false,
                pesticideNorm,
                normFluidFlowRate,
                normOfTheDrug,
                selectedPesticide: item
            });
        } else {
            this.getFilteredPesticide();
            this.setState({
                showFilter: true,
                normFluidFlowRate: undefined,
                normOfTheDrug: '',
                selectedPesticide: ''
            });
        }
    };

    showInfo = (pesticide) => {
        this.setState({ showPesticide: { ...pesticide } }, () => {
            const { showPesticide } = this.state;
            this.setState({ showInfo: true });
        });
    };

    get getNorm() {
        const { normFluidFlowRate, normOfTheDrug } = this.state;
        const { calculator } = this.props;
        const { normFluid } = calculator;
        const norm = normFluidFlowRate ? (normOfTheDrug * normFluid) / normFluidFlowRate : 0;
        return norm.toFixed(2) || 0;
    }

    jsPdfGenerator = () => {
        const {
            t, calculator, pesticide, isCompleted
        } = this.props;
        const {
            normFluidFlowRate, normOfTheDrug
        } = this.state;

        const pdfTitle = t('pdf title');
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constantsCommon.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        doc.setFontSize(16);
        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const text = doc.splitTextToSize(pesticide?.plant_protection_product?.name, pageWidth - 50, {});
        const textMain = doc.splitTextToSize(calculator.name, pageWidth - 50, {});
        const textY = textMain.length * 16 + 60;
        doc.text(textMain, 40, 40);
        doc.setFontSize(14);
        doc.autoTable({
            startY: textY,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
            },
            body: [
                [
                    t('method pdf', { value: t('fluid flow rate pdf') }),
                    t('date pdf', { value: moment(calculator.creation_date).format('DD.MM.YYYY') })
                ]
            ]
        });
        const tableY = doc.previousAutoTable.finalY + 40;
        const finalY = doc.previousAutoTable.finalY + 60 + text.length * 16;
        doc.text(text, 40, tableY);
        const bodyHead = [
            [t('fluid flow rate'), normFluidFlowRate],
            [t('norm of the drug'), normOfTheDrug],
            [t('rate of working fluid'), calculator.fluid_consumption_rate_for_rod && calculator.fluid_consumption_rate_for_rod.toFixed(2)],
            [t('rate of drug'), calculator.rate_of_use_of_plant_protection && calculator.rate_of_use_of_plant_protection.toFixed(2)]
        ];


        doc.autoTable({
            startY: finalY,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
            },
            body: bodyHead
        });
        const cultivatedCultures = pesticide.related_cultures ? pesticide.related_cultures.map(item => (
            item && item.culture.name
        )) : [];
        const harmfulObjects = pesticide.related_harmful_objects ? pesticide.related_harmful_objects.map(item => (
            item && item.harmful_object.name
        )) : [];

        const normOfUse = (pesticide.rate_of_use_min === pesticide.rate_of_use_max)
            ? pesticide.rate_of_use_min
            : `${pesticide.rate_of_use_min} - ${pesticide.rate_of_use_max}`;
        const fluidFlowRate = (pesticide.fluid_consumption_min === pesticide.fluid_consumption_max)
            ? pesticide.fluid_consumption_min
            : `${pesticide.fluid_consumption_min} - ${pesticide.fluid_consumption_max}`;

        const bodyPesticide = [
            [t('group culture'), cultivatedCultures.join(', ')],
            [t('harmful object'), harmfulObjects.join(', ')],
            [t('type of processing'), pesticide?.treatment_type?.name],
            [t('units'), pesticide?.unit_of_measurement?.name],
            [t('application type'), pesticide.application_features],
            [t('norm'), normOfUse],
            [t('fluid flow rate'), fluidFlowRate]
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
            },
            body: bodyPesticide
        });
        const fileName = rus2Latin(calculator.name.substr(0, 50));
        doc.save(`${fileName}.pdf`);
    };

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    clearFields = () => {
        this.formRef.current.resetFields();
        this.setState({
            name: undefined,
            page: 1,
            group: null,
            hazard_class: null,
            main_drug: null,
            culture: null,
            preparativeForm: null,
            treatment_type: null,
            harmful_object: null,
            chemical_class: null,
            price_from: null,
            price_to: null
        }, () => this.getFilteredPesticide());
    };

    render() {
        const { cn } = this;
        const {
            t,
            pesticidesList,
            pesticidesCount,
            groups,
            hazardClasses,
            mainDrug,
            cultures,
            preparativeForms,
            treatmentTypes,
            harmfulObjects,
            chemicalClasses,
            calculator,
            isCompleted
        } = this.props;

        if (!Object.keys(calculator).length) return <div />;

        const { pesticide = {}, calculationMethod } = calculator;
        const {
            visible,
            pesticideNorm,
            showFilter,
            setFilter,
            showInfo,
            showPesticide,
            selectedPesticide,
            page,
            page_size,
            normFluidFlowRate,
            normOfTheDrug
        } = this.state;


        return (
            <div className={cn()}>
                <div className={cn('title')}>{t('title calc')}</div>

                <Form ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="calculator-fertilizer">
                    {showFilter && (
                        <>
                            <Row gutter={20} style={{ marginBottom: '20px' }}>
                                <Col span={12} className="field">
                                    <Form.Item name="name" label={t('search')}>
                                        <Input
                                            disabled={isCompleted}
                                            placeholder={t('search name')}
                                            suffix={<Icon type="search" />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Row gutter={20}>
                                        <Col span={6} className="field">
                                            <Form.Item name="price_from" label={t('price from')}>
                                                <InputNumber
                                                    min={0}
                                                    max={1000000}
                                                    disabled={isCompleted}
                                                    placeholder={t('price from placeholder')} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} className="field">
                                            <Form.Item name="price_to" label={t('price to')}>
                                                <InputNumber
                                                    min={0}
                                                    max={1000000}
                                                    disabled={isCompleted}
                                                    placeholder={t('price to placeholder')} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={20} style={{ marginBottom: '20px' }}>
                                <Col span={6} className="field">
                                    <Form.Item label={t('group pesticides')}
                                        name="group">
                                        <Select
                                            allowClear
                                            showSearch
                                            filterOption={this.filterOption}>
                                            {groups.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item label={t('treatment type pesticide')}
                                        name="treatment_type">
                                        <Select
                                            allowClear
                                            showSearch
                                            filterOption={this.filterOption}>
                                            {treatmentTypes.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item label={t('hazard class pesticide')}
                                        name="hazard_class">
                                        <Select
                                            allowClear
                                            showSearch
                                            filterOption={this.filterOption}>
                                            {hazardClasses.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item label={t('preparative form pesticide')}
                                        name="preparativeForm">
                                        <Select
                                            allowClear
                                            showSearch
                                            filterOption={this.filterOption}>
                                            {preparativeForms.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20} style={{ marginBottom: '20px' }}>
                                <Col span={6} className="field">
                                    <Form.Item label={t('culture pesticide')}
                                        name="culture">
                                        <Select
                                            allowClear
                                            showSearch
                                            filterOption={this.filterOption}>
                                            {cultures.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item label={t('chemical class pesticide')}
                                        name="chemical_class">
                                        <Select
                                            allowClear
                                            showSearch
                                            filterOption={this.filterOption}>
                                            {chemicalClasses.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6} className="field">
                                    <Form.Item label={t('main drug pesticide')}
                                        name="main_drug">
                                        <Input placeholder={t('search main drug')}
                                            suffix={<Icon type="search" />} />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item label={t('harmful object pesticide')}
                                        name="harmful_object">
                                        <Input placeholder={t('search harmful object')}
                                            suffix={<Icon type="search" />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>

                <Row>
                    <Col>
                        <Button type="secondary" onClick={this.clearFields}>
                            {t('clear')}
                        </Button>
                    </Col>
                </Row>

                {showFilter && setFilter && (
                    <Row>
                        <Col>
                            <span className={cn('find-count')}>
                                {t('find', { value: pesticidesCount })}
                            </span>
                        </Col>
                    </Row>
                )}
                {!showFilter && !isCompleted && (
                    <Row>
                        <Col>
                            <span className={cn('back')} onClick={() => this.onSetPesticide()}>
                                {t('back to results')}
                            </span>
                        </Col>
                    </Row>
                )}
                { setFilter && pesticidesList && (
                    <Row>
                        <Col span={24}>
                            <PesticidesTable
                                filterItems={this.filterItems}
                                pesticide={selectedPesticide ? [selectedPesticide] : undefined}
                                dataSource={pesticidesList}
                                dataSize={pesticidesCount}
                                pageSize={page_size}
                                pageNum={page}
                                resetDataSource={showFilter}
                                isCompleted={isCompleted}
                                onSetPesticide={this.onSetPesticide}
                                showInfo={this.showInfo}
                                onPageSizeChange={this.onPageSizeChange}
                                onCurrentPageChange={this.onCurrentPageChange} />
                        </Col>
                    </Row>
                )}
                {selectedPesticide && (
                    <>
                        <Row gutter={20} style={{ marginBottom: '30px', marginTop: '30px' }}>
                            <Col span={6}>{t('norm of the drug')}</Col>
                            <Col span={6}>
                                {selectedPesticide.rate_of_use_min !== selectedPesticide.rate_of_use_max ? (
                                    <>
                                        <div className="ant-col ant-form-item-label">
                                            <label className="ant-form-item-required">
                                                {t('input range', { value: `${selectedPesticide.rate_of_use_min} - ${selectedPesticide.rate_of_use_max}` })}
                                            </label>
                                        </div>
                                        <InputNumber
                                            min={Number(selectedPesticide.rate_of_use_min)}
                                            max={Number(selectedPesticide.rate_of_use_max)}
                                            onChange={this.onChangeNumberDrug}
                                            decimalSeparator=","
                                            value={normOfTheDrug}
                                            name="normOfTheDrug"
                                            disabled={isCompleted}
                                            placeholder={t('input placeholder', { value: `${selectedPesticide.rate_of_use_min} - ${selectedPesticide.rate_of_use_max}` })} />
                                    </>
                                ) : (
                                    <InputNumber
                                        value={
                                            normOfTheDrug
                                            || Number(selectedPesticide.rate_of_use_min.toString().replace(',', '.'))
                                        }
                                        decimalSeparator=","
                                        name="normOfTheDrug"
                                        disabled />
                                )}
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col span={6}>{t('fluid flow rate')}</Col>
                            <Col span={6}>
                                {selectedPesticide.fluid_consumption_min !== selectedPesticide.fluid_consumption_max ? (
                                    <>
                                        <div className="ant-col ant-form-item-label">
                                            <label className="ant-form-item-required">
                                                {t('input range', { value: `${selectedPesticide.fluid_consumption_min} - ${selectedPesticide.fluid_consumption_max}` })}
                                            </label>
                                        </div>
                                        <InputNumber
                                            min={Number(selectedPesticide.fluid_consumption_min)}
                                            max={Number(selectedPesticide.fluid_consumption_max)}
                                            onChange={this.onChangeNumberFlow}
                                            decimalSeparator=","
                                            name="normFluidFlowRate"
                                            value={normFluidFlowRate}
                                            disabled={isCompleted}
                                            placeholder={t('input placeholder', { value: `${selectedPesticide.fluid_consumption_min} - ${selectedPesticide.fluid_consumption_max}` })} />
                                    </>
                                ) : (
                                    <InputNumber
                                        value={
                                            normFluidFlowRate
                                            || Number(selectedPesticide.fluid_consumption_max.toString().replace(',', '.'))
                                        }
                                        decimalSeparator=","
                                        name="normFluidFlowRate"
                                        disabled />
                                )}
                            </Col>
                        </Row>
                    </>
                )}
                {calculator.rate_of_use_of_plant_protection && (
                    <Row className={cn('green-border')}>
                        <Col span={24}>
                            {t('rate of working fluid', { value: calculator.fluid_consumption_rate_for_rod && calculator.fluid_consumption_rate_for_rod.toFixed(2) })}<br />
                            {t('rate of drug', { value: calculator.rate_of_use_of_plant_protection && calculator.rate_of_use_of_plant_protection.toFixed(2) })}
                        </Col>
                    </Row>
                )}
                <div className={cn('fixed-info')}>
                    {normOfTheDrug >= 0 && normFluidFlowRate >= 0 && !isCompleted && (
                        <Row className={cn('button')}>
                            <Col>
                                <Button
                                    type="primary"
                                    disabled={isCompleted}
                                    onClick={this.setPesticide}
                                    size="large">
                                    {t('finish')}
                                </Button>
                                <Button
                                    type="default"
                                    disabled={isCompleted}
                                    onClick={() => this.onSetPesticide()}
                                    size="large">
                                    {t('back to')}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    { isCompleted && (
                        <Row className={cn('button')}>
                            <Col>
                                <Button
                                    onClick={this.jsPdfGenerator}
                                    type="default">
                                    <Icon type="download" />
                                    {t('generate')}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>
                <SaveMessage
                    visible={visible}
                    title={t('end title')}
                    text={t('end text')}
                    okBtn={t('end save')}
                    cancelBtn={t('cancel save')}
                    onOk={this.setStatusComplete}
                    onCancel={this.hideModal} />
                <PesticideModal
                    visible={showInfo}
                    application={showPesticide}
                    onCancel={this.hideInfo} />
            </div>

        );
    }
}