import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, Upload, Input
} from 'antd';

import { PaperClipOutlined } from '@ant-design/icons';
import moment from 'moment';
import cn from '../../../utils/cn';

import { selectors } from '../../../models';
import { EXTENTIONS } from '../../../constants';

const {
    getCurrentRequest,
    getUserId
} = selectors;

const mstp = (state) => ({
    request: getCurrentRequest(state),
    curUserId: getUserId(state)
});
const mdtp = ({});

@withRouter
@connect(mstp, mdtp)
@withTranslation('requests')
@cn('request-detail')
export default class RequestData extends Component {
    state = {
        isAdmin: false
    };

    componentDidMount() {
        const { task, request } = this.props;
        const { userId: taskUserId } = task;
        const { userId: requestUserId } = request;
        this.setState({ isAdmin: Number(taskUserId) !== Number(requestUserId) });
    }

    showFile = (id, image) => {
        const { history, onClick } = this.props;
        const str = image.split('.');
        const ext = str[str.length - 1];
        if (EXTENTIONS.includes(ext.toLowerCase())) {
            onClick(id);
        } else {
            location.href = `/v1/task-file/downloadFile/${id}`;
        }
    }


    render() {
        const { cn } = this;
        const {
            t, loading, task
        } = this.props;
        const { isAdmin } = this.state;
        if (!task) return <div />;
        const { files = [], createDate = 0, title = '' } = task;

        return (
            
            <div style={{ marginBottom: '30px' }}>
                <div className={cn('block-title')}>
                    {isAdmin ? t('answer') : t('clarification')}
                    <span>{moment(createDate).format('DD.MM.YYYY')}</span>
                </div>
                <Row gutter={20}>
                    <Col span={12}>
                        <div className={cn('field', isAdmin ? 'answer' : 'annotation')}>
                            {title}
                        </div>
                    </Col>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={24}>{t('files')}</Col>
                        </Row>
                        {files.map(file => (
                            <Row gutter={20}>
                                <Col span={6}>
                                    <PaperClipOutlined />
                                    <div onClick={() => this.showFile(file.id, file.fileName)}
                                        className={cn('link')}>
                                        {file.fileName}
                                    </div>
                                </Col>
                                <Col span={8}>{file.description}</Col>
                            </Row>

                        ))}
                    </Col>
                </Row>
            </div>

        );
    }
}