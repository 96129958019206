import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    Col, Form, InputNumber, Row, Select
} from 'antd';
import { useClassName } from 'utils/cn';

import { actions, selectors } from '../../models';

import './style.less';
import { Button, SaveMessage } from '../../components';
import { setCurDistributionCalculate } from '../../reducers/view/actions';

const {
    curCalculate,
    getRegionsCatalog,
    getRegionNormCultureCatalog,
    getSoilCompositionCatalog,
    curNormative,
    getUser,
    isAuth,
    isCalculateCompleted
} = selectors;

const {
    getCalculatorsNormRegionCulture,
    addNormative,
    updateNormative
} = actions;

const FIELDS = ['planned_yield', 'region', 'culture', 'preceding_culture', 'ph_salt', 'mechanical_composition'];

export default () => {
    const user = useSelector(state => getUser(state));
    const isAuthState = useSelector(state => isAuth(state));
    const calculator = useSelector(state => curCalculate(state));
    const normative = useSelector(state => curNormative(state));
    const regions = useSelector(state => getRegionsCatalog(state));
    const cultures = useSelector(state => getRegionNormCultureCatalog(state));
    const compositionSoil = useSelector(state => getSoilCompositionCatalog(state));
    const isCompleted = useSelector(state => isCalculateCompleted(state));
    const dispatch = useDispatch();
    const { t } = useTranslation('calculators');
    const cn = useClassName('calculator');
    const match = useRouteMatch();
    const { params: { id } } = match;
    const history = useHistory();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [isFull, setIsFull] = useState(true);
    const [filteredRegions, setFilteredRegions] = useState([]);

    useEffect(() => {
        normative.region?.id
        && dispatch(getCalculatorsNormRegionCulture(`?filter={"region.id":${normative.region?.id}}`));
        isAuthState
            ? form.setFieldsValue({
                planned_yield: normative.planned_yield,
                region: normative.region?.id,
                culture: normative.culture?.id,
                preceding_culture: normative.preceding_culture?.id,
                ph_salt: normative.ph_salt,
                mechanical_composition: normative.mechanical_composition?.id
            })
            : form.setFieldsValue({
                planned_yield: normative.planned_yield,
                region: normative.region?.name,
                culture: normative.culture?.name,
                preceding_culture: normative.preceding_culture?.name,
                ph_salt: normative.ph_salt,
                mechanical_composition: normative.mechanical_composition?.name
            });
    }, [calculator, isAuthState]);

    useEffect(() => {
        // для пользователя с ролью Мордовия оставляем только Мордовию в списке регионов

        setFilteredRegions(regions.filter(item => item.visible));
    }, [user, regions]);

    const sendData = (type) => {
        const values = form.getFieldsValue(true);
        dispatch(setCurDistributionCalculate(type ?? 'normative'));

        const updateValues = {
            planned_yield: values?.planned_yield || normative.planned_yield,
            region: values?.region || normative.region?.id,
            culture: values?.culture || normative.culture?.id,
            preceding_culture: values?.preceding_culture || normative.preceding_culture?.id,
            ph_salt: values?.ph_salt || normative.ph_salt,
            mechanical_composition: values?.mechanical_composition || normative.mechanical_composition?.id
        };
        normative.id
            ? dispatch(updateNormative({ id: normative.id, values: updateValues }))
            : dispatch(addNormative({
                calculate: {
                    id,
                    name: calculator.name,
                    calculator_type: calculator.calculator_type
                },
                values
            }));
    };

    const onFieldsChange = (field, fields) => {
        let isFull = true;

        fields.forEach(field => {
            if (!field.value) isFull = false;
        });
        setIsFull(isFull);
    };

    const onValuesChange = (value, values) => {
        if (Object.keys(value)[0] === 'region') {
            const filter = `?filter={"region.id":${value.region}}`;
            dispatch(getCalculatorsNormRegionCulture(filter));
            form.setFieldsValue({ culture: '' });
        }
    };

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();
        inputName = inputName.toLowerCase();
        return optionName.indexOf(inputName) !== -1;
    };

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const closeCalculator = () => {
        history.push('/calculators');
    };

    const clearFields = () => {
        form.resetFields();
    };

    return (
        <div>
            <Form onFieldsChange={onFieldsChange}
                onValuesChange={onValuesChange}
                form={form}
                className={cn()}
                name="calculator-params">
                <div className={cn('params')}>
                    <Row gutter={16}>
                        <Col span={7}>{t('planned yield t')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="planned_yield"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={1000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('predecessor choice')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="preceding_culture"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        cultures.map(
                                            (item =>
                                                (
                                                    <Select.Option value={item.culture?.id} key={item.culture?.id}>
                                                        {item.culture?.name}
                                                    </Select.Option>
                                                )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('region type')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="region"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        filteredRegions.map(
                                            (item =>
                                                (
                                                    <Select.Option value={item.id} key={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('acidity type')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="ph_salt"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={50}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('culture choice')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="culture"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        cultures.map(
                                            (item =>
                                                (
                                                    <Select.Option value={item.culture?.id} key={item.culture?.id}>
                                                        {item.culture?.name}
                                                    </Select.Option>
                                                )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('soil texture choice')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="mechanical_composition"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        compositionSoil.map(
                                            (item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '15px' }}>
                        <Col span={2}>
                            <Button
                                size="large"
                                type="secondary"
                                onClick={clearFields}>
                                {t('clear')}
                            </Button>
                        </Col>
                    </Row>


                    {!isFull && (
                        <Row className={cn('info')}>
                            <Col>
                                <div className={cn('info__inner')}>{t('info')}</div>
                            </Col>
                        </Row>
                    )}
                    {normative.id && (
                        <>
                            <div className="table-percent">
                                <div className="table-percent__title">{normative.expert_data ? t('table title normative') : t('table title')}</div>
                                <Row className="table-percent__header">
                                    <Col span={4}>{t('table name')}</Col>
                                    <Col span={5}>{t('table autumn')}</Col>
                                    <Col span={5}>{t('table spring')}</Col>
                                    <Col span={5}>{t('table feeding')}</Col>
                                    <Col span={5}>{t('table total')}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table n')}</Col>
                                    <Col span={5}>{normative.recommended_n_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{normative.recommended_n_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{normative.recommended_n_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+normative.recommended_n_main + normative.recommended_n_sowing + normative.recommended_n_feeding)?.toFixed(1)}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table p')}</Col>
                                    <Col span={5}>{normative.recommended_p_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{normative.recommended_p_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{normative.recommended_p_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+normative.recommended_p_main + normative.recommended_p_sowing + normative.recommended_p_feeding)?.toLocaleString('ru-RU')}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table k')}</Col>
                                    <Col span={5}>{normative.recommended_k_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{normative.recommended_k_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{normative.recommended_k_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+normative.recommended_k_main + normative.recommended_k_sowing + normative.recommended_k_feeding)?.toLocaleString('ru-RU')}</Col>
                                </Row>
                            </div>
                            {normative.expert_data && (
                                <>
                                    <div className="table-percent">
                                        <div className="table-percent__title">{t('table title expert')}</div>
                                        <Row className="table-percent__header">
                                            <Col span={4}>{t('table name')}</Col>
                                            <Col span={5}>{t('table autumn')}</Col>
                                            <Col span={5}>{t('table spring')}</Col>
                                            <Col span={5}>{t('table feeding')}</Col>
                                            <Col span={5}>{t('table total')}</Col>
                                        </Row>
                                        <Row className="table-percent__row">
                                            <Col span={4}>{t('table n')}</Col>
                                            <Col span={5}>{normative.expert_n_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{normative.expert_n_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{normative.expert_n_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{(+normative.expert_n_main + normative.expert_n_sowing + normative.expert_n_feeding)?.toLocaleString('ru-RU')}</Col>
                                        </Row>
                                        <Row className="table-percent__row">
                                            <Col span={4}>{t('table p')}</Col>
                                            <Col span={5}>{normative.expert_p_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{normative.expert_p_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{normative.expert_p_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{(+normative.expert_p_main + normative.expert_p_sowing + normative.expert_p_feeding)?.toLocaleString('ru-RU')}</Col>
                                        </Row>
                                        <Row className="table-percent__row">
                                            <Col span={4}>{t('table k')}</Col>
                                            <Col span={5}>{normative.expert_k_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{normative.expert_k_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{normative.expert_k_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                            <Col span={5}>{(+normative.expert_k_main + normative.expert_k_sowing + normative.expert_k_feeding)?.toLocaleString('ru-RU')}</Col>
                                        </Row>
                                    </div>
                                    {normative.expert_comment && (
                                        <Row className={cn('info-lg')} style={{ marginBottom: '30px' }}>
                                            <Col span={24}>
                                                <div className={cn('info__inner')}>{normative.expert_comment}</div>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {!isCompleted && (
                        <Row className={cn('button')}>
                            <Col>
                                {normative.expert_data && (
                                    <>
                                        <Button
                                            type="primary"
                                            disabled={isCompleted}
                                            onClick={() => sendData('expert')}
                                            size="large">
                                            {t('calculate expert')}
                                        </Button>
                                        <Button
                                            type="primary"
                                            disabled={isCompleted}
                                            onClick={() => sendData('normative')}
                                            size="large">
                                            {t('calculate normative')}
                                        </Button>
                                    </>
                                )}
                                {!normative.expert_data && (
                                    <Button
                                        type="primary"
                                        disabled={isCompleted}
                                        onClick={() => sendData()}
                                        size="large">
                                        {t('submit')}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    )}
                </div>
                <SaveMessage
                    visible={visible}
                    title="end title"
                    text="end text"
                    okBtn="end save"
                    cancelBtn="cancel save"
                    onOk={() => {}}
                    onCancel={hideModal} />
            </Form>
        </div>
    );
};