import React, { useEffect } from 'react';
import { Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { removeGroupCalculate, setActiveCalculate } from '../../../../models/mordovia/actions';
import {
    calculatorsGroup,
    calculatorsGroupInfo,
    getCurrentCalculatorMordovia
} from '../../../../models/mordovia/selectors';
import { curCalculate } from '../../../../models/item-calculate/selectors';
import { useClassName } from '../../../../utils/cn';
import { toFixed } from '../../../../utils/utils';
import moment from 'moment';

const InterpretationTable = () => {
    const dispatch = useDispatch();
    const calculator = useSelector(state => curCalculate(state));
    const calcGroupInfo = useSelector(state => calculatorsGroupInfo(state));
    const calcGroup = useSelector(state => calculatorsGroup(state));
    const fieldCalc = useSelector(state => getCurrentCalculatorMordovia(state));
    const { t } = useTranslation('interpretation');
    const cn = useClassName('field-info');

    useEffect(() => {
        if (calcGroup && calcGroup[0]) {
            const fieldCalculator = calcGroup.filter(x => (x?.calculator_type === 7 && x?.type === 'field'))[0];
            if (fieldCalculator) {
                setCurrentAgroCalculator(fieldCalculator);
            } else {
                setCurrentAgroCalculator(calcGroup.filter(x => x?.calculator_type === 7)[0]);
            }
        }
    }, [calcGroup]);

    const setCurrentAgroCalculator = (calculator) => {
        dispatch(setActiveCalculate(calculator));
    };

    const deleteAgroInterpretation = (id) => {
        dispatch(removeGroupCalculate(id));
    };

    const columns = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (value, record) =>
                (
                    Number(value) !== Number(fieldCalc?.calculator?.id)
                    && Number(value) !== Number(calculator?.id)
                )
                && <DeleteOutlined onClick={() => deleteAgroInterpretation(value)} />

        },
        {
            title: t('method analysis'),
            dataIndex: 'type',
            width: 150,
            render: value => t(value)
        },
        {
            title: t('analysis date'),
            dataIndex: 'date',
            width: 150,
            render: value => moment(value).format('DD.MM.YYYY')
        },
        {
            title: t('point number'),
            dataIndex: 'points',
            width: 100,
            render: value => value?.join(', ')
        },
        {
            title: t('productivity zone'),
            dataIndex: 'zones',
            width: 100,
            render: value => value?.map((el, i) => `${t(el)}${i < value.length - 1 ? ', ' : ''}`)
        },
        {
            title: t('ph water'),
            dataIndex: ['agrochemical', 'ph_water'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('ph salt'),
            dataIndex: ['agrochemical', 'ph_salt'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('salt_conductivity'),
            dataIndex: ['agrochemical', 'salt_conductivity'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('organic_substances'),
            dataIndex: ['agrochemical', 'organic_substances'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('no'),
            dataIndex: ['agrochemical', 'no3'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('nh'),
            dataIndex: ['agrochemical', 'nh4'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('po'),
            dataIndex: ['agrochemical', 'p2o5'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('ko'),
            dataIndex: ['agrochemical', 'k2o'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('s'),
            dataIndex: ['agrochemical', 's'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('na'),
            dataIndex: ['agrochemical', 'na'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('ca'),
            dataIndex: ['agrochemical', 'ca'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('mg'),
            dataIndex: ['agrochemical', 'mg'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('so'),
            dataIndex: ['agrochemical', 'so4'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('cl'),
            dataIndex: ['agrochemical', 'cl'],
            width: 100,
            render: value => toFixed(value ? value : null)
        },
        {
            title: t('method po'),
            dataIndex: ['agrochemical', 'method'],
            width: 100,
            render: value => value && t(`${value} sm`)
        }
    ];

    return (
        <Row style={{ marginBottom: '30px' }}>
            <Table dataSource={calcGroup?.filter(x => x.calculator_type !== 12)}
                onRow={(record, rowIndex) => ({
                    onClick: event => {
                        setCurrentAgroCalculator(record);
                    }
                })}
                rowClassName={(record, index) => (record?.id === calculator?.id && cn('green'))}
                columns={columns}
                pagination={false}
                scroll={{
                    x: 1300
                }} />
        </Row>
    );
};

export default InterpretationTable;