import ConverterClass from '../../utils/converter';
import { toFixed } from '../../utils/utils';

class Converter extends ConverterClass {
    toClient(data = {}) {
        return {
            id: data.id,
            user: data.user,
            created_at: data.created_at,
            updated_at: data.updated_at,
            region: data.region || {},
            culture: data.culture || {},
            mechanical_composition: data.mechanical_composition || {},
            degree_of_soil_moisture: data.degree_of_soil_moisture || {},
            fertilizer_action_year: data.fertilizer_action_year,
            analysis_method: data.analysis_method,
            soil_sampling_depth: data.soil_sampling_depth,
            depth_of_arable_layer: data.depth_of_arable_layer,
            report_n: data.report_n,
            report_p: data.report_p,
            report_k: data.report_k,
            planned_yield: data.planned_yield,
            recommended_n_main: toFixed(data.recommended_n_main, 1),
            recommended_p_main: toFixed(data.recommended_p_main, 1),
            recommended_k_main: toFixed(data.recommended_k_main, 1),
            recommended_n_sowing: toFixed(data.recommended_n_sowing, 1),
            recommended_p_sowing: toFixed(data.recommended_p_sowing, 1),
            recommended_k_sowing: toFixed(data.recommended_k_sowing, 1),
            recommended_n_feeding: toFixed(data.recommended_n_feeding, 1),
            recommended_p_feeding: toFixed(data.recommended_p_feeding, 1),
            recommended_k_feeding: toFixed(data.recommended_k_feeding, 1)
        };
    }
}

export default new Converter();