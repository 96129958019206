import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Layout, Select, Row, Col
} from 'antd';

import { connect } from 'react-redux';
import cn from '../../utils/cn';

import { PanelLayout, Title } from '../../components';

import DeterminantsTable from './components/determinants-table';

import './style.less';
import { selectors, actions } from '../../models';
import { DETERMINANTS } from '../../constants/determinats';

const { PanelFooter, PanelHeader, PanelContent } = PanelLayout;

const {
    getDeterminantTypes,
    clearDeterminantTypes,
    getDeterminants,
    getDiseases
} = actions;
const {
    getCurDeterminants,
    getCurDeterminantTypes,
    CurDeterminantTypesLoading,
    getUserId
} = selectors;

const mstp = state => ({
    determinants: getCurDeterminants(state),
    userId: getUserId(state),
    determinantTypes: getCurDeterminantTypes(state),
    isDeterminantTypesLoading: CurDeterminantTypesLoading(state)
});

const mdtp = {
    getDeterminantTypes,
    clearDeterminantTypes,
    getDeterminants,
    getDiseases
};

@withTranslation('all determinants')
@connect(mstp, mdtp)
@cn('all-page')
export default class AllDeterminants extends Component {
    state = {
        culture: undefined
    };

    componentDidMount() {
        const { getDeterminantTypes, getDeterminants, getDiseases } = this.props;
        getDeterminants('?page_size=10');
        getDiseases('');
    }

    onSelectChange = (value) => {
        const { getDeterminants, culture, userId } = this.props;
        getDeterminants(`?filter={"determinant":[${value}]}`);
        this.setState({ culture: value });
    }

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    render() {
        const { cn } = this;
        const { t, determinantTypes } = this.props;
        const { culture } = this.state;

        return (
            <Layout className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <Row gutter={20} style={{ marginBottom: '30px' }}>
                            <Col span={24} style={{ marginBottom: '6px' }}>
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item-required">
                                        {t('culture type')}
                                    </label>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Select
                                    showSearch
                                    className={cn('select')}
                                    onChange={this.onSelectChange}
                                    name="cultureId"
                                    filterOption={this.filterOption}>
                                    {determinantTypes.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {t(DETERMINANTS[item.name])}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <DeterminantsTable culture={culture} />
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}