import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from '../../utils/cn';
import './style.less';

@cn('catalog-title')
class CatalogTitle extends Component {
    render() {
        const { cn } = this;
        const {
            className,
            title,
            ...rest
        } = this.props;

        return (
            <div className={`${cn()} ${className}`} {...rest}>
                {title}
            </div>
        );
    }
}

CatalogTitle.propTypes = {
    /** Дополнительное имя класса */
    className: PropTypes.string,
    title: PropTypes.any
};

CatalogTitle.defaultProps = {
    className: '',
    title: false
};

export default CatalogTitle;