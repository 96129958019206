import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import '../style.less';
import { Table } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { curGeneralProtocol } from '../../../models/general-protocol/selectors';

const Fed = ({
    calculatorData, selectedFeds, setSelectedFeds
}) => {
    const { t } = useTranslation('general protocol');

    const generalProto = useSelector(state => curGeneralProtocol(state));

    const columns = [
        {
            title: t('header num'),
            dataIndex: 'protocol_number',
            key: 'protocol_number'
        },
        {
            title: t('header vega_key'),
            dataIndex: 'vega_key',
            key: 'vega_key'
        },
        {
            title: t('header owner'),
            dataIndex: ['owner', 'name'],
            key: 'owner'
        },
        {
            title: t('header acceptation_date'),
            dataIndex: 'acceptation_date',
            key: 'acceptation_date',
            render: value => moment(value).format('DD.MM.YYYY')
        },
        {
            title: t('header examination_date'),
            dataIndex: 'examination_date',
            key: 'examination_date',
            render: value => moment(value).format('DD.MM.YYYY')
        }
    ];

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedFeds(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys: selectedFeds?.map(item => item.id),
        onChange: onSelectChange
    };

    return (
        <div>
            {(calculatorData?.length > 0) ? (
                <Table
                    rowSelection={rowSelection}
                    rowKey="id"
                    columns={columns}
                    dataSource={calculatorData} />
            ) : (
                <div>{t('no protocols')}</div>
            )}
        </div>
    );
};

export default Fed;