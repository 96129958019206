import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getCurDeterminants = state => state.models[name].determinants;
export const getCurDeterminantsBody = state => state.models[name].determinants.results;
export const getCurDeterminantsCount = state => state.models[name].determinants.count;
export const getCurrentDeterminant = state => state.models[name].determinant;
export const isDeterminantsLoading = state => state.models[name].loading;
export const isDeterminantLoading = state => state.models[name].loading;
export const getCurrentQuestion = state => state.models[name].question;
export const getCurrentAnswers = state => state.models[name].question?.answers;
export const getCurrentQuestions = state => state.models[name].questions;