import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import cn from '../../../utils/cn';
import '../style.less';
import { selectors, actions } from '../../../models';
import ReviewAdd from './reviews/review-add';
import Reviews from './reviews/reviews';

const {
    getReviews,
    getAverageRate
} = actions;
const {
    getCurrentFertilizer,
    getCurrentAverageRate
} = selectors;

const mstp = (state) => ({
    fertilizer: getCurrentFertilizer(state),
    rate: getCurrentAverageRate(state)
});
const mdtp = ({
    getReviews,
    getAverageRate
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('disease review')
@cn('disease-potato-detail')
export default class DiseaseReview extends Component {
    state = {
        isCreate: false
    };

    componentDidMount() {
        const { match, isCreateReview } = this.props;
        const { id } = match.params;
        this.setState({ isCreate: isCreateReview });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isCreateReview } = this.props;
        if (prevProps.isCreateReview !== isCreateReview) {
            this.setState({ isCreate: isCreateReview });
        }
    }

    goBack = () => {
        const { history } = this.props;
        history.push('/fertilizers');
    };

    createReview = (value) => {
        this.setState({ isCreate: value });
    };

    render() {
        const { cn } = this;
        const {
            t, fertilizer, rate
        } = this.props;
        const { isCreate } = this.state;
        if (!fertilizer) return <div />;
        return (

            <div className={cn()}>
                <div className={cn('content')}>
                    <span className={cn('back')} onClick={this.goBack}>
                        {t('back')}
                    </span>
                    <div className={cn('title')}>
                        {fertilizer.name}
                    </div>
                    { (isCreate) && (<ReviewAdd createReview={this.createReview} curRate={rate} />)}
                    { (!isCreate) && (<Reviews createReview={this.createReview} curRate={rate} />)}
                </div>
            </div>
        );
    }
}
