import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import cn from '../../utils/cn';
import { getProfile, refresh } from '../../models/account/actions';
import { isAuth, isLoading, getUser } from '../../models/account/selectors';
import { isValid } from '../../utils/utils';
import './style.less';
import { SideBar } from '../../components';
// import ChangeLang from '../change-lang';

const mstp = state => ({
    isAuth: isAuth(state),
    isLoading: isLoading(state),
    user: getUser(state)
});

const mdtp = ({ getProfile, refresh });

@withRouter
@connect(mstp, mdtp)
@cn('auth-layout')
export default class AuthLayout extends Component {
    componentDidMount() {
        const { getProfile, refresh } = this.props;
        const { history } = this.props;
        const { listen } = history;
        let token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh');
        const prevPage = localStorage.getItem('prevPage');
        const tokenStr = location.search;
        let isInfinity = false;
        localStorage.setItem('isInfinity', 'no');
        if (tokenStr.includes('?token=')) {
            token = tokenStr.replace('?token=', '');
            localStorage.setItem('isInfinity', 'yes');
            localStorage.setItem('token', token);
            isInfinity = true;
        }

        // Запоминаем pathname каждой открытой страницы и сохраняем в LS, чтобы на странице авторизации, при успешном логине, переадресовать туда, где юзер был.
        // Переадресация происходит в login-form, на странице auth
        // Запоминаем pathname, если страница открылась, то listen еще не сработал
        // localStorage.setItem('prevPage', location.pathname);
        if (location.pathname !== '/'
            && location.pathname !== '/404/'
            && !(location.pathname.includes('/user-activate'))
        ) {
            localStorage.setItem('prevPage', location.pathname);
        } else {
            localStorage.setItem('prevPage', '/determinants');
        }

        // Запоминаем pathname при переходах по "внутреннему" роутингу
        listen((location) => { location.pathname !== '/' && location.pathname !== '/reset-password/' && localStorage.setItem('prevPage', location.pathname); });
        token && getProfile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isAuth, history } = this.props;
        if (isAuth !== prevProps.isAuth) {
            const pathName = isAuth ? localStorage.getItem('prevPage') : '/';
            history.push(pathName);
        }
    }

    render() {
        const { cn } = this;
        const { children, isLoading, isAuth } = this.props;

        return (
            <>
                {/* <ChangeLang /> */}
                { isAuth && !(location.pathname === '/') && <SideBar /> }
                {
                    // (!isLoading && !(location.pathname.includes('/mordovia/')) && !(location.pathname === '/'))
                    (!isLoading && !(location.pathname.includes('/representation/')) && !(location.pathname === '/'))
                        ? <div className={cn('spin')}> <Spin /> </div>
                        : children
                }
            </>
        );
    }
}
