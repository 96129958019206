import { getConstant } from '../utils';

export const getCalculatorMordovia = getConstant('GET_CALCULATOR_MORDOVIA');
export const getCalculatorsMordovia = getConstant('GET_CALCULATORS_MORDOVIA');
export const getCalculatorsMordoviaShape = getConstant('GET_CALCULATORS_MORDOVIA_SHAPE');
export const getCalculatorsMordoviaForecast = getConstant('GET_CALCULATORS_MORDOVIA_FORECAST');
export const updateCalculatorsMordoviaForecast = getConstant('UPDATE_CALCULATORS_MORDOVIA_FORECAST');
export const clearCalculatorMordovia = getConstant('CLEAR_CALCULATOR_MORDOVIA');

export const addGroupCalculate = getConstant('ADD_GROUP_CALCULATE');
export const removeGroupCalculate = getConstant('REMOVE_GROUP_CALCULATE');
export const setActiveCalculate = getConstant('SET_ACTIVE_CALCULATE');

export const getFieldPoints = getConstant('GET_FIELD_POINTS');
export const updateDistributionGroupCalculate = getConstant('UPDATE_DISTRIBUTION_GROUP_CALCULATE');

export const getPestProbabilityData = getConstant('GET_PEST_PROBABILITY_DATA');
export const calculatePestProbability = getConstant('CALCULATE_PEST_PROBABILITY');

export const updateFieldDistribution = getConstant('UPDATE_FIELD_DISTRIBUTION');
export const getFieldYears = getConstant('GET_FIELD_YEARS');
export const updateFieldYield = getConstant('UPDATE_YIELD');