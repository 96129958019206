import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Col, Row, Layout, Checkbox, DatePicker, Input, Slider, Dropdown, Popover
} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import moment from 'moment';
import {
    Button,
    PanelLayout,
    Title
} from '../../components';
import './style.less';

import {
    addMycologicalRegistry, publishMycologicalRegistry,
    updateMycologicalRegistry, getMycologicalRegistries
} from '../../models/mycological-registry/actions';
import { getUsersList } from '../../models/sample-log/actions';
import { deleteMycologicalRegistry } from '../../models/mycological-registry/actions';
import { usrListResponse } from '../../models/sample-log/selectors';
import SelectableTable from '../../components/selectable-table';
import { curMycoRegistries, curMycoRegistriesCount } from '../../models/mycological-registry/selectors';
import { getCulturesCatalog } from '../../models/cultures/selectors';
import { getCultures } from '../../models/cultures/actions';

const { PanelFooter, PanelHeader, PanelContent } = PanelLayout;

const { RangePicker } = DatePicker;

const Registries = () => {
    const cn = useClassName('calculators');
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();

    const { params: { type, id } } = match;
    const { t } = useTranslation('mycological registry');

    const users = useSelector(state => usrListResponse(state));
    const protocolItems = useSelector(state => curMycoRegistries(state));
    const cultures = useSelector(state => getCulturesCatalog(state));

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedItems, setSelectedItems] = useState([]);

    const [fullUsersList, setFullUsersList] = useState([]);
    const [usersPage, setUsersPage] = useState(1);
    const [searchRange, setSearchRange] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [buttonOpen, setButtonOpen] = useState(false);

    useEffect(() => {
        if (usersPage !== 1) {
            dispatch(getUsersList({
                page: 1,
                searchString: ''
            }));
        } else {
            dispatch(getUsersList({
                page: usersPage,
                searchString: ''
            }));
        }
    }, [usersPage]);

    useEffect(() => {
        const listPortion = users?.results;
        if (listPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...listPortion]);
        }
        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUsersPage(usersPage + 1);
        }
    }, [users]);

    useEffect(() => {
        if (page && pageSize) {
            dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize }));
        }
    }, [page]);

    useEffect(() => {
        if (page === 1) {
            dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize }));
        } else {
            setPage(1);
        }
    }, [pageSize]);
    
    useEffect(() => {
        dispatch(getCultures());
    }, []);

    const handleFiltersChange = (pagination, filters, sorter) => {
        console.log('SORTER', sorter);
        const { order, columnKey } = sorter;

        console.log('SORRTER', sorter);

        if (Object.keys(sorter)?.length > 0 && !order) {
            // dispatch();  // default sorting
        } else if (order?.length > 0) {
            if (order === 'ascend') {
                // dispatch();
            }

            if (order === 'descend') {
                // dispatch();
            }
        }

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        console.log('HANDLING SEARCH');
        console.log('HANDLING SEARCH selectedKeys', selectedKeys);
        console.log('HANDLING SEARCH dataIndex', dataIndex);
        handleApplyFilter(dataIndex, selectedKeys);
        // confirm();
        // if (dataIndex === 'creation_date' || dataIndex === 'test_date' || dataIndex === 'id') {
        //     setSearchRange(selectedKeys);
        // }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchRange([]);
        clearAll();
    };

    const [isFiltering, setIsFiltering] = useState(false);

    const [fOwner, setFOwner] = useState(null);
    const [fNum, setFNum] = useState(null);
    const [fStatus, setFStatus] = useState(null);
    const [fTestDate, setFTestDate] = useState(null);
    const [fCreationDate, setFCreationDate] = useState(null);
    const [fCulture, setFCulture] = useState(null);
    const [fFieldNum, setFFieldNum] = useState(null);
    const [sortingOrder, setSortingOrder] = useState([]);

    useEffect(() => {
        if (sortingOrder?.length > 0) {
            console.log('Sortying', sortingOrder);
        }
    }, [sortingOrder]);

    useEffect(() => {
        let filterStr = '';
        console.log('FILTERS UPDATE');
        console.log('FILTERS', fOwner, fNum, fStatus, fTestDate, fCreationDate, fCulture, fFieldNum);

        if (
            fOwner ?? fNum ?? fStatus ?? fTestDate
            ?? fCreationDate ?? fCulture ?? fFieldNum
        ) {
            const filtersArray = [];
            if (fOwner) {
                filtersArray.push(`"owner.icontains": "${fOwner}"`);
            }
            if (fNum) {
                filtersArray.push(`"num.icontains": "${fNum}"`);
            }
            // if (fStatus) {
            //     filtersArray.push(``);
            // }
            if (fTestDate) {
                filtersArray.push(`"test_date.gte": "${fTestDate[0]?.format('YYYY-MM-DD')}"`);
                filtersArray.push(`"test_date.lte": "${fTestDate[1]?.format('YYYY-MM-DD')}"`);
            }
            if (fCreationDate) {
                filtersArray.push(`"creation_date.gte": "${fCreationDate[0]?.format('YYYY-MM-DD')}"`);
                filtersArray.push(`"creation_date.lte": "${fCreationDate[1]?.format('YYYY-MM-DD')}"`);
            }
            if (fCulture) {
                filtersArray.push(`"culture.name.icontains": "${fCulture}"`);
            }
            if (fFieldNum) {
                filtersArray.push(`"field_num.icontains": "${fFieldNum}"`);
            }
            filterStr = `?page=1&page_size=${pageSize}&filter={${filtersArray?.join(',')}}`;
            console.log('Requesting with filters: ', filterStr);
            dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize, filterString: filterStr }));
        } else {
            console.log('REQUESTING WITHOUT FILTERS');
            filterStr = `?page=1&page_size=${pageSize}`;
            dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize }));
        }
    }, [
        fOwner, fNum, fTestDate, fStatus,
        fCreationDate, fCulture, fFieldNum
    ]);
    

    const handleApplyFilter = (filterType, ...filterParams) => {
        if (!isFiltering) {
            setIsFiltering(true);
            if (filterType === 'num') {
                setFNum(filterParams?.[0]?.[0]); // String
            }
            if (filterType === 'owner') {
                setFOwner(filterParams?.[0]?.[0]); // String
            }
            if (filterType === 'creation_date') {
                setFCreationDate(filterParams[0]); // [Moment, Moment]
            }
            if (filterType === 'test_date') {
                setFTestDate(filterParams[0]); // [Moment, Moment]
            }
            if (filterType === 'culture') {
                setFCulture(filterParams?.[0]?.[0]); // String
            }
            if (filterType === 'field_num') {
                setFFieldNum(filterParams?.[0]?.[0]); // String
            }
        }
        setIsFiltering(false);
    };

    const [searchedStatus, setSearchedStatus] = useState(null);

    const getColumnSearchProps = (dataIndex, sliderBorders = [0, 100]) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <div>
                    {(
                        dataIndex === 'owner_user' || dataIndex === 'num' || dataIndex === 'field_num' || dataIndex === 'culture' || dataIndex === 'owner'
                    ) ? (
                            <Input onChange={(evt) => {
                                setSelectedKeys([evt?.target?.value]);
                            }} />
                        ) : dataIndex === 'id' ? (
                            <Slider
                                max={sliderBorders[1]}
                                min={sliderBorders[0]}
                                range
                                defaultValue={sliderBorders}
                                onChange={(vals) => {
                                    setSearchRange(vals);
                                    setSelectedKeys(vals);
                                }} />
                        ) : dataIndex === 'public' ? (
                            <Checkbox.Group onChange={setSearchedStatus}>
                                {[
                                    {
                                        label: t('archived'),
                                        value: 'archived'
                                    },
                                    {
                                        label: t('published'),
                                        value: 'published'
                                    },
                                    {
                                        label: t('edition'),
                                        value: 'edition'
                                    },
                                ].map(optn => (
                                    <Row>
                                        <Checkbox
                                            key={optn?.value}
                                            label={optn?.label}
                                            // handleChange={props.handleChange}
                                            value={optn?.value}>
                                            {optn?.label}
                                        </Checkbox>
                                    </Row>
                                ))}
                            </Checkbox.Group>
                        ) : (
                            <RangePicker
                                onChange={(e) => {
                                    setSearchRange(e);
                                    setSelectedKeys(e);
                                }} />
                        )}
                </div>
                <Row gutter={12}>
                    <Col span={12}>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{ width: '100%' }}>
                            Сбросить
                        </Button>
                    </Col>
                    <Col span={12}>
                        {dataIndex === 'public' ? (
                            <Button
                                type="primary"
                                onClick={() => setFStatus(searchedStatus)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: '100%' }}>
                                Поиск
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: '100%' }}>
                                Поиск
                            </Button>
                        )}
                    </Col>
                </Row>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#03B575' : undefined }} />
        ),
        onFilter: (value, record) => handleApplyFilter('owner', value, record)
    });

    // sortDirections: ['descend', 'ascend'],

    const requestsColumns = [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            filteredValue: filteredInfo.id || null,
            sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('test date'),
            dataIndex: 'creation_date',
            key: 'creation_date',
            filteredValue: filteredInfo.creation_date || null,
            sortOrder: sortedInfo.columnKey === 'creation_date' ? sortedInfo.order : null,
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('DD.MM.YYYY'),
            ...getColumnSearchProps('creation_date')
        },
        {
            title: t('owner'),
            dataIndex: 'owner',
            key: 'owner',
            filteredValue: filteredInfo.owner || null,
            sortOrder: sortedInfo.columnKey === 'owner' ? sortedInfo.order : null,
            render: (val) => {
                return fullUsersList?.length > 0 ? fullUsersList.filter(usr => usr.id === parseInt(val, 10))[0]?.name : val
            },
            ...getColumnSearchProps('owner')
        },
        {
            title: t('number'),
            dataIndex: 'num',
            key: 'num',
            filteredValue: filteredInfo.num || null,
            sortOrder: sortedInfo.columnKey === 'num' ? sortedInfo.order : null,
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('num')
        },
        {
            title: t('date of test'),
            dataIndex: 'test_date',
            key: 'test_date',
            filteredValue: filteredInfo.test_date || null,
            sortOrder: sortedInfo.columnKey === 'test_date' ? sortedInfo.order : null,
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('DD.MM.YYYY'),
            ...getColumnSearchProps('test_date')
        },
        {
            title: t('field num'),
            dataIndex: 'field_num',
            key: 'field_num',
            filteredValue: filteredInfo.field_num || null,
            sortOrder: sortedInfo.columnKey === 'field_num' ? sortedInfo.order : null,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('field_num')
        },
        {
            title: t('culture'),
            dataIndex: 'culture',
            key: 'culture',
            filteredValue: filteredInfo.culture || null,
            sortOrder: sortedInfo.columnKey === 'culture' ? sortedInfo.order : null,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('culture'),
            render: (val) => cultures.find(item => item.id === val)?.name
        },
        {
            title: t('is published'),
            dataIndex: 'public',
            key: 'public',
            filteredValue: filteredInfo.public || null,
            sortOrder: sortedInfo.columnKey === 'public' ? sortedInfo.order : null,
            ...getColumnSearchProps('public'),
            render: (val, record) => record?.deleted ? (
                <div className="publication-status__archived">
                    {t('archived')}
                </div>
            ) : val ? (
                <div className="publication-status__published">
                    {t('published')}
                </div>
            ) : (
                <div className="publication-status__edition">
                    {t('edition')}
                </div>
            )
        }
    ];

    const handleDownloadWord = () => {
        selectedItems?.map(x => x.id)?.forEach(selEn => {
            fetch(`/api/v1/mycological_calculator/mycological_calculator_reestr/${selEn}/download_protocol/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`
                }
            }).then((resp) => {
                resp.blob().then((blb) => {
                    const url = window.URL.createObjectURL(blb);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `mycological-registry[id-${selEn}]`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                });
            });
        });
    };

    const handleStatusUpdate = () => {
        selectedItems.forEach(selectedRow => {
            const rowId = selectedRow?.id;
            dispatch(updateMycologicalRegistry({ values: { id: selectedRow?.id, public: selectedRow?.public ? !selectedRow.public : true }, id: rowId }));
        });
    };

    const makeRowCopy = (prototypeObject) => {
        const clonedObject = {
            ...prototypeObject
        };
        clonedObject.pictures1 = clonedObject?.pictures1?.map(pic => pic?.id);
        clonedObject.pictures2 = clonedObject?.pictures2?.map(pic => pic?.id);
        clonedObject.pictures3 = clonedObject?.pictures3?.map(pic => pic?.id);
        clonedObject.alternaria = { value: clonedObject.alternaria?.value };
        clonedObject.bipolaris = { value: clonedObject.bipolaris?.value };
        clonedObject.blumeria_graminis = { value: clonedObject.blumeria_graminis?.value };
        clonedObject.cladosporium = { value: clonedObject.cladosporium?.value };
        clonedObject.fusarium = { value: clonedObject.fusarium?.value };
        clonedObject.murogenella = { value: clonedObject.murogenella?.value };
        clonedObject.phoma = { value: clonedObject.phoma?.value };
        clonedObject.pythium = { value: clonedObject.pythium?.value };
        clonedObject.microorganisms = clonedObject.microorganisms?.map(x => {

            const cpyNew = { ...x };
            if (cpyNew?.genius?.id) {
                cpyNew.genius = cpyNew?.genius?.id;
            }
            cpyNew.mycobiota_type = cpyNew?.mycobiota_type?.id;
            if (!cpyNew?.mycobiota_type) {
                delete cpyNew.mycobiota_type;
            }
            return cpyNew;
        });
        return clonedObject;
    };

    const handleCopy = () => {
        const toCopy = selectedItems[0];
        delete toCopy.id;
        delete toCopy.creation_date;
        const clonedObject = makeRowCopy(toCopy);
        dispatch(addMycologicalRegistry({
            history,
            values: clonedObject
        }));
    };

    const handleDelete = () => {
        selectedItems.forEach(selectedRow => {
            setTimeout(() => dispatch(deleteMycologicalRegistry(selectedRow?.id)), 100);
        });
        selectedItems?.length > 0 && setTimeout(() => dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize })), 2000);
    };

    const discardFilters = () => {
        setSelectedItems([]);
    };

    const handleRowsAction = (actionType) => {
        switch (actionType) {
            case 'publish':
                selectedItems.forEach(objEntity => {
                    dispatch(publishMycologicalRegistry({
                        protocolId: objEntity?.id,
                        values: {
                            public: true
                        }
                    }));
                });
                setTimeout(() => dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize })), 1500);
                break;
            case 'edit':
                selectedItems.forEach(objEntity => {
                    dispatch(publishMycologicalRegistry({
                        protocolId: objEntity?.id,
                        values: {
                            public: false
                        }
                    }));
                });
                setTimeout(() => dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize })), 1500);
                break;
            case 'archive':
                selectedItems.forEach(objEntity => {
                    dispatch(updateMycologicalRegistry({
                        id: objEntity?.id,
                        values: {
                            deleted: true
                        }
                    }));
                });
                setTimeout(() => dispatch(getMycologicalRegistries({ page: page, pageSize: pageSize })), 1500);
                break;
            default:
                break;
        }
    };

    const handleOpenRegistry = (record) => {
        return {
            onClick: () => {
                if (
                    !record?.deleted
                ) {
                    if (
                        record.public
                    ) {
                        history.push(`/mycological-registry/${record.id}/published`)
                    } else {
                        history.push(`/mycological-registry/${record.id}`)
                    }
                }
                // setTimeout(() => window.location.reload(), 200);
            }
        };
    };

    return (
        <Layout className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <Row>
                        <div className="myco-top-text">
                            {t('top text')}
                        </div>
                    </Row>
                    <Row style={{ marginBottom: '30px' }}>
                        <Col span={4}>
                            {selectedItems?.length > 0 ? (
                                <Popover
                                    placement="bottom"
                                    content={(
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '200px'
                                            }}>
                                            <Button
                                                size="default"
                                                onClick={() => handleRowsAction('publish')}
                                                style={{ margin: '10px 5px' }}>
                                                {t('publish')}
                                            </Button>
                                            <Button
                                                size="default"
                                                type="secondary"
                                                onClick={() => handleRowsAction('edit')}
                                                style={{ margin: '0px 5px' }}>
                                                {t('edit')}
                                            </Button>
                                            <Button
                                                size="default"
                                                type="secondary"
                                                onClick={() => handleRowsAction('archive')}
                                                style={{ margin: '10px 5px' }}>
                                                {t('archive')}
                                            </Button>
                                        </div>
                                    )}
                                    trigger="click"
                                    visible={buttonOpen}
                                    onVisibleChange={setButtonOpen}>
                                    <Button size="default">
                                        {t('manage status')}
                                    </Button>
                                </Popover>
                            ) : (
                                <Button
                                    size="default"
                                    onClick={() => history.push('/mycological-registry')}>
                                    <PlusCircleOutlined />
                                    {t('add new registry')}
                                </Button>
                            )}
                        </Col>
                        <Col span={4}>
                            <Button
                                size="default"
                                type="secondary"
                                disabled={selectedItems?.length < 1}
                                onClick={handleDownloadWord}>
                                <DownloadOutlined />
                                {`${t('download')} Word`}
                            </Button>
                        </Col>

                        <Col span={9} />

                        <Col span={7}>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={12}>
                                            <Button
                                                type="secondary"
                                                size="default"
                                                onClick={handleCopy}
                                                disabled={selectedItems?.length !== 1}>
                                                <CopyOutlined />
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                type="secondary"
                                                danger
                                                size="default"
                                                onClick={handleDelete}
                                                disabled={selectedItems?.length < 1}>
                                                <DeleteOutlined />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Button size="default" onClick={discardFilters}>
                                        {t('reset filter')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className={cn()}>
                        <SelectableTable
                            isBordered={false}
                            isLoading={protocolItems?.isLoading}
                            onRowClick={handleOpenRegistry}
                            onChange={handleFiltersChange}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            tableColumns={requestsColumns}
                            tablePage={page}
                            tableTotal={protocolItems?.total}
                            setTablePage={setPage}
                            tablePageSize={pageSize}
                            setTablePageSize={setPageSize}
                            tableData={protocolItems?.results} />

                    </div>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default Registries;
