import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Icon from '@ant-design/icons';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, InputNumber, Input, Form, Select
} from 'antd';

import cn from '../../../../utils/cn';
import {
    Title, Button, Tabs, SaveMessage
} from '../../../../components';

import { object2Form } from '../../../../utils/form';
import * as constants from '../constants';
import { selectors, actions } from '../../../../models';

const {
    setParamsCalculatorFluid,
    closeCalculatorFluid,
    getRateNozzleCalculatorFluid
} = actions;
const {
    getCurrentCalculatorFluid,
    getCurrentCalculatorFluidNozzleColor,
    getCurrentCalculatorFluidRateNozzle
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFluid(state),
    colors: getCurrentCalculatorFluidNozzleColor(state),
    pressure: getCurrentCalculatorFluidRateNozzle(state)
});
const mdtp = ({
    setParamsCalculatorFluid,
    closeCalculatorFluid,
    getRateNozzleCalculatorFluid
});

const { TabPane } = Tabs;
const { Option } = Select;

const REG_EXP = /^[0-9]*[.]?[0-9]+$/;
const colorsArr = [
    'noContent',
    'orange',
    'green',
    'yellow',
    'lilac',
    'darkBlue',
    'red',
    'brown',
    'gray',
    'white',
    'blue',
    'lightGreen',
    'black'
];

@withRouter
@connect(mstp, mdtp)
@withTranslation('calculator params fluid')
@cn('calculator-detail-params')
export default class CalculatorParamsFluid extends Component {
    formRef = React.createRef();

    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        nozzle_color: -1,
        isFullFields: false
    };

    componentDidMount() {
        const { calculator } = this.props;
        this.initForm(calculator);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calculator, history, match } = this.props;
        const { id } = match.params;
        if (prevProps.calculator.id !== calculator.id) {
            this.initForm(calculator);
        }
    }

    onSubmit = (values) => {
        const { setParamsCalculatorFluid, match } = this.props;
        const { id } = match.params;
        const calculation_parameters = { ...values };
        setParamsCalculatorFluid({ id, values: { calculation_parameters } });
    };

    initForm = (calculator) => {
        const {
            consumption_rate_from_catalog,
            fluid_consumption_rate_for_nozzle,
            number_of_nozzles_on_the_rod,
            rod_width,
            actual_spraying_speed
        } = calculator;

        this.formRef.current.setFieldsValue({
            consumption_rate_from_catalog: consumption_rate_from_catalog && consumption_rate_from_catalog.id,
            fluid_consumption_rate_for_nozzle,
            number_of_nozzles_on_the_rod,
            rod_width,
            actual_spraying_speed,
            nozzle_color: consumption_rate_from_catalog && consumption_rate_from_catalog.nozzle_color && consumption_rate_from_catalog.nozzle_color.id
        });
    };

    onValuesChange = (value, values) => {
        const { getRateNozzleCalculatorFluid } = this.props;
        if (Object.keys(value)[0] === 'nozzle_color') {
            this.formRef.current.setFieldsValue({
                consumption_rate_from_catalog: '',
                fluid_consumption_rate_for_nozzle: ''
            });
            this.setState(value);
            getRateNozzleCalculatorFluid(`?filter={"nozzle_color.id":${value.nozzle_color}}`);
        }
    };

    onFieldsChange = (field, fields) => {
        let checked = true;
        fields.forEach(item => {
            if (!item.value) {
                if (!(item.name[0] === 'nozzle_color' && item.value === 0)) {
                    checked = false;
                }
            }
            if (item.errors && item.errors.length) checked = false;
        });
        this.setState({ isFullFields: checked });
    };


    closeCalculator = () => {
        const { history } = this.props;
        this.hideModal();
        history.push('/calculators/');
    };

    hideModal = () => {
        this.setState({ visible: false });
    };

    showModal = () => {
        this.setState({ visible: true });
    };

    setStatusComplete = () => {
        const { closeCalculatorFluid, calculator } = this.props;
        const { id } = calculator;
        closeCalculatorFluid(id);
        this.closeCalculator();
    };

    clearFields = () => {
        this.formRef.current.resetFields();
    };

    get colorSelect() {
        const { cn } = this;
        const { t, colors } = this.props;
        const arrItems = [];
        colorsArr.forEach((item, index) => {
            arrItems.push(
                <Select.Option value={index} key={t(`${item}.title`)}>
                    <div className={cn('color-option')}>
                        {t(`${item}.title`)}
                        <div className={cn('color')}>
                            {t(`${item}.value`)}
                            <i className={cn(`color-${item}`)} />
                        </div>
                    </div>
                </Select.Option>
            );
        });
        return arrItems;
    }

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    isInteger = (_, value) => {
        const { t } = this.props;
        if (Number.isInteger(value)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(t('not int')));
    };

    render() {
        const { cn } = this;
        const {
            t, isCompleted, pressure, calculator
        } = this.props;
        const {
            visible, nozzle_color, isFullFields
        } = this.state;

        return (
            <Form ref={this.formRef}
                onFinish={this.onSubmit}
                onValuesChange={this.onValuesChange}
                onFieldsChange={this.onFieldsChange}
                className={cn()}
                name="calculator-params-fluid">
                <div>
                    <div className={cn('title')}>{t('title')}</div>
                    <Row gutter={20} className={cn('gray')}>
                        <Col span={12} style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                            <Row style={{ alignItems: 'center', width: '100%' }}>
                                <Col span={12} className={cn('label')}>
                                    <span>{t('nozzle color choice')}</span>
                                </Col>
                                <Col span={12} className={cn('input')}>
                                    <Form.Item name="nozzle_color">
                                        <Select
                                            showSearch={false}
                                            disabled={isCompleted}
                                            isRequired>
                                            {this.colorSelect.map(item => item)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {nozzle_color !== 0 && pressure.length > 0 && (
                                <Row style={{ alignItems: 'center', width: '100%', marginTop: '20px' }}>
                                    <Col span={12} className={cn('label')}>
                                        <span>{t('pressure choice')}</span>
                                    </Col>
                                    <Col span={12} className={cn('input')}>
                                        <Form.Item name="consumption_rate_from_catalog">
                                            <Select
                                                name="pressure"
                                                disabled={isCompleted}
                                                className={cn('color-select')}
                                                filterOption={this.filterOption}>
                                                {pressure.map(item => (
                                                    <Select.Option value={item.id} key={item.id}>
                                                        {item.nozzle_pressure && item.nozzle_pressure.pressure}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            {nozzle_color === 0 && (
                                <Row style={{ alignItems: 'center', width: '100%', marginTop: '20px' }}>
                                    <Col span={12} className={cn('label')}>
                                        <span>{t('fluid flow rate')}</span>
                                    </Col>
                                    <Col span={12} className={cn('input')}>
                                        <Form.Item name="fluid_consumption_rate_for_nozzle">
                                            <InputNumber
                                                min={constants.FLUID_COLOR_COEFF.noContent[0]}
                                                max={constants.FLUID_COLOR_COEFF.noContent[1]}
                                                decimalSeparator=","
                                                disabled={isCompleted}
                                                validator="float"
                                                placeholder="" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col span={12} className={cn('description')}>
                            <span>
                                {t('nozzle color description')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={20} className={cn('light-green')}>
                        <Col span={6} className={cn('label')}>
                            <span>{t('number of sprayers')}</span>
                        </Col>
                        <Col span={6} className={cn('input')}>
                            <Form.Item name="number_of_nozzles_on_the_rod" rules={[{ validator: this.isInteger }]}>
                                <InputNumber
                                    min={0}
                                    max={1000000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={12} className={cn('description')}>
                            <span>
                                {t('number of sprayers description')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={20} className={cn('gray')}>
                        <Col span={6} className={cn('label')}>
                            <span>{t('width of the bar')}</span>
                        </Col>
                        <Col span={6} className={cn('input')}>
                            <Form.Item name="rod_width">
                                <InputNumber
                                    min={0}
                                    max={1000000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={12} className={cn('description')}>
                            <span>
                                {t('width of the bar description')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={20} className={cn('light-green')}>
                        <Col span={6} className={cn('label')}>
                            <span>{t('spraying speed')}</span>
                        </Col>
                        <Col span={6} className={cn('input')}>
                            <Form.Item name="actual_spraying_speed">
                                <InputNumber
                                    min={0}
                                    max={1000000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={12} className={cn('description')}>
                            <span>
                                {t('spraying speed description')}
                            </span>
                        </Col>
                    </Row>
                    { calculator.fluid_consumption_rate_for_rod && (
                        <Row className={cn('green')}>
                            <Icon type="check-circle" theme="outlined" />

                            <Col span={24}>
                                <div className={cn('sum-title')}>
                                    {t('final text', { value: calculator.fluid_consumption_rate_for_rod })}
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>

                <Row>
                    <Col>
                        <Button type="secondary" onClick={this.clearFields}>
                            {t('clear')}
                        </Button>
                    </Col>
                </Row>

                <div>
                    { !isFullFields && (
                        <Row className={cn('info')}>
                            <Col>
                                <div className={cn('info', 'inner')}>{t('info')}</div>
                            </Col>
                        </Row>
                    )}
                    { isFullFields && (
                        <Row className={cn('button')}>
                            <Col>
                                <Button
                                    type="primary"
                                    disabled={isCompleted}
                                    htmlType="submit"
                                    size="large">
                                    {t('submit')}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>
                <SaveMessage
                    visible={visible}
                    title="end title"
                    text="end text"
                    okBtn="end save"
                    cancelBtn="cancel save"
                    onOk={this.setStatusComplete}
                    onCancel={this.hideModal} />
            </Form>


        );
    }
}