import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getProtocol = ({ userId, values }) => request.post({
    url: `/api/v1/teleagronom_user/owners/${userId}/create_general_protocol/`,
    body: values
});
export const getFedPoints = pageNum => request.get({
    url: `api/v1/mordovia/fed_points/?page_size=100&page=${pageNum}`
});
export const getAgroPoints = pageNum => request.get({
    url: `api/v1/mordovia/field_points/?page_size=100&page=${pageNum}`
});