import React, { Component } from 'react';
import { translate, withTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import cn from '../../utils/cn';
import Button from '../button';
import './style.less';

@withTranslation('info message')
@cn('info-message')
class InfoMessage extends Component {
    render() {
        const { cn } = this;
        const {
            t,
            visible,
            title,
            text,
            okBtn,
            cancelBtn,
            onCancel,
            onOk
        } = this.props;

        return (
            <Modal
                title={t(title)}
                width={490}
                footer={null}
                closable={false}
                destroyOnClose
                visible={visible}
                className={cn('modal')}
                onCancel={onCancel}>
                <Row gutter={28}>
                    <Col span={24}>
                        <div className={cn('content')}>
                            {t(text)}
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className={cn('footer')}>
                            <Button
                                onClick={onOk}
                                type="primary">
                                {t(okBtn)}
                            </Button>
                            <Button
                                onClick={onCancel}
                                type="default">
                                {t(cancelBtn)}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        );
    }
}
export default InfoMessage;