import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import moment from 'moment';

import { withTranslation } from 'react-i18next';
import { Modal, Input, Form } from 'antd';

import { Button } from '../../../components';

import { object2Form } from '../../../utils/form';

import { selectors, actions } from '../../../models';

import LogoIcon from '../../../../public/images/logo.svg';

import cn from '../../../utils/cn';

const { login, getProfile, resetPassword } = actions;
const { isLoading, isAuth, isError } = selectors;

const LOGIN_FORM = 'login_form';

const mstp = (state) => ({
    isError: isError(state)
});

const mdtp = ({ login, resetPassword });

@withRouter
@connect(mstp, mdtp)
@withTranslation('login form')
@cn('login-form')
export default class LoginForm extends Component {
    formRef = React.createRef();
    
    state = {
        username: '',
        password: '',
        isRecovery: false,
        isSendRecoveryLink: false
    };

    onSubmit = (values) => {
        const { history, login, resetPassword } = this.props;
        const { isRecovery } = this.state;

        
        if (isRecovery) {
            resetPassword({ email: values.email });
            this.setState({ isSendRecoveryLink: true });
        } else { login(values); }
        this.setState({ isLoading: true });
    };

    onChange = (e, form) => {
        this.setState(e);
    };

    setReg = () => {
        const { setReg } = this.props;
        setReg();
    };

    showRecovery = () => {
        this.setState({ isRecovery: true });
    };
    
    handleCancel = () => {
        this.setState({ isRecovery: false });
    };

    validatePassword = (rule, value, callback) => {
        const { t } = this.props;
        callback();
    };

    render() {
        const { cn } = this;
        const { t, isError, onClose } = this.props;
        const {
            username, password, isRecovery, isSendRecoveryLink
        } = this.state;

        return (
            <Form
                ref={this.formRef}
                name={LOGIN_FORM}
                className={cn('modal')}
                onValuesChange={this.onChange}
                onFinish={this.onSubmit}>
                <div className={cn('header')}>
                    {isRecovery ? t('title recovery') : t('title')}
                </div>
                
                {!isRecovery && (
                    <>
                        <div className={`${cn('content')} field`}>
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item-required">
                                    {username ? t('email') : ''}
                                </label>
                            </div>
                            <Form.Item name="username"
                                rules={[
                                    { required: true, message: t('not empty') }
                                ]}>
                                <Input
                                    className={cn('input')}
                                    name="username"
                                    placeholder={t('email placeholder')}
                                    maxLength={255} />
                            </Form.Item>
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item-required">
                                    {password ? t('password') : ''}
                                </label>
                            </div>
                            <Form.Item name="password"
                                rules={[
                                    { required: true, message: t('not empty') },
                                    { validator: this.validatePassword }
                                ]}>
                                <Input
                                    className={cn('input')}
                                    placeholder={t('password placeholder')}
                                    type="password"
                                    maxLength={255} />
                            </Form.Item>
                        </div>
                        {isError && (
                            <div className={cn('error')}>
                                {t('error')}
                            </div>
                        )}
                        <div className={cn('footer')}>
                            <Button
                                htmlType="submit"
                                type="primary">
                                {t('submit')}
                            </Button>
                        </div>
                    </>
                )}
                {isRecovery && (
                    <>
                        <div className={`${cn('content')} field`}>
                            <p>
                                {isSendRecoveryLink ? t('recovery text send') : t('recovery text')}
                            </p>
                            {!isSendRecoveryLink && (
                                <>
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-item-required">
                                            {t('recovery email label')}
                                        </label>
                                    </div>
                                    <Form.Item name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: t('not valid email')
                                            }
                                        ]}>
                                        <Input
                                            placeholder={t('recovery email placeholder')}
                                            className={cn('input')}
                                            maxLength={255} />
                                    </Form.Item>
                                </>
                            )}
                        </div>
                        <div className={cn('footer')}>
                            { !isSendRecoveryLink
                                ? (
                                    <Button htmlType="submit">
                                        {t('send')}
                                    </Button>
                                )
                                : (
                                    <Button onClick={onClose}>
                                        {t('continue')}
                                    </Button>
                                )}
                        </div>
                        <div className={cn('text')}>
                            {t('info text')}
                            <span onClick={this.setReg}>{t('reg')}</span>
                        </div>
                    </>
                )}
            </Form>

        );
    }
}