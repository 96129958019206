import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Layout, Spin, Icon, Row, Col
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Title, Tabs, SideBar, PanelLayout } from '../../components';
import ObjectDetail from './components/object-detail';

import cn from '../../utils/cn';

import './style.less';
// import SideBar from '../../components/sidebar/component';


const { PanelHeader, PanelContent } = PanelLayout;

@withTranslation('pesticides')
@cn('pesticides')
export default class HarmfulObject extends Component {

    render() {
        const { cn } = this;

        const { t, history } = this.props;

        return (
            <Layout className={cn()}>
                {/* <SideBar /> */}
                <ObjectDetail />
            </Layout>
        );
    }
}