/* eslint-env browser */
import '@babel/polyfill';
import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import notifications from './middleware/notifications';
import redirect from './middleware/redirect';
import reducers from './reducers';
import { saga } from './models';

const sagaMiddleware = createSagaMiddleware();

const sagaConnect = (...sagas) => {
    sagas.forEach(saga => sagaMiddleware.run(saga));
};

const composeEnhancers = typeof window === 'object'
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const middleware = composeEnhancers(
    applyMiddleware(
        thunk,
        logger,
        sagaMiddleware,
        notifications,
        redirect
    )
);

const createStoreWithMiddleware = middleware(createStore);
const store = createStoreWithMiddleware(reducers);

sagaConnect(...saga);

window.store = store;

export default store;
