import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlTask = options.apiTaskUrl;
const urlFile = options.apiFileUrl;
const urlPath = (url, path) => `${url}${path}`;

export const getRequests = filter => request.get({
    url,
    body: filter
});

export const getRequest = id => request.get({
    url: urlPath(url, id),
    id
});

export const addRequest = () => request.post({
    url
});

export const updateRequest = body => request.put({
    url,
    body
});

export const setStatusRequest = body => request.put({
    url: urlPath(url, `status/?requestId=${body.requestId}&status=${body.status}`)
});

export const removeRequest = id => request.remove({
    url: urlPath(url, id)
});


export const getTask = body => request.get({
    url: urlPath(urlTask, `/?requestId=${body.requestId}`)
});

export const getTasks = id => request.get({
    url: urlPath(urlTask, id),
    id
});

export const addTask = body => request.post({
    url: urlTask,
    body
});

export const removeTask = id => request.remove({
    url: urlPath(urlTask, id)
});

export const uploadTaskFile = body => request.post({
    url: urlPath(
        urlFile, body.url
    ),
    type: 'formdata',
    body: body.file
});