import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getRequest.try:
        case constants.updateRequest.try:
        case constants.addRequest.try:
        case constants.getRequests.try:
        case constants.removeRequest.try: {
            return true;
        }

        case constants.getRequest.success:
        case constants.updateRequest.success:
        case constants.addRequest.success:
        case constants.getRequests.success:
        case constants.removeRequest.success:
        case constants.getRequest.error:
        case constants.updateRequest.error:
        case constants.addRequest.error:
        case constants.getRequests.error:
        case constants.removeRequest.error: {
            return false;
        }

        default:
            return state;
    }
}