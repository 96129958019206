import * as constants from '../constants';

const initialState = {
    data: {
        results: [],
        count: 0
    },
    item: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getPesticideProductApplication.success: {
            return {
                ...state, data: action.payload
            };
        }

        case constants.getPesticideProductApplicationItem.success: {
            return {
                ...state, item: { ...action.payload }
            };
        }

        default:
            return state;
    }
}
