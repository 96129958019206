import jsPDF from 'jspdf';
import 'jspdf-autotable';

import moment from 'moment';

import * as constantsCommon from '../../constants';
import { rus2Latin } from '../../utils/utils';
import { selectors } from '../../models';

const STEPS = ['main', 'sowing', 'feeding'];
const methodsArr = { kirsanov: 'по Кирсанову', chirikov: 'по Чирикову', machigin: 'по Мачигину' };
const intervals = {
    VERY_LOW: 'Очень низкое',
    LOW: 'Низкое',
    AVERAGE: 'Среднее',
    INCREASED: 'Повышенное',
    HIGH: 'Высокое',
    VERY_HIGH: 'Очень высокое',
    TOXIC: 'Токсичное',
    VERY_STRONGLY_ACID: 'Очень сильнокислый',
    STRONG_ACID: 'Сильнокислый',
    MEDIUM_ACID: 'Среднекислый',
    SLIGHTLY_ACID: 'Слабокислый',
    CLOSE_TO_NEUTRAL: 'Близкие к нейтральным',
    NEUTRAL: 'Нейтральные',
    WEAKLY_ALKALINE: 'Слабощелочные',
    STRONG_ALKALINE: 'Сильнощелочные',
    SHARPLY_ALKALINE: 'Резкощелочные'
};
export function generatePdf(t, calculator) {
    const {
        normative, agrochemical, rb, distribution
    } = calculator;
    const pdfTitle = 'pdf title';
    const doc = new jsPDF('p', 'pt', 'a4', true);

    const arr = [];

    doc.addFileToVFS('Arial-normal.ttf', constantsCommon.FONT);
    doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

    doc.setFont('Arial');
    doc.setFontSize(16);
    const { pageSize } = doc.internal;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const textMain = doc.splitTextToSize(calculator?.name || '', pageWidth - 50, {});
    const textY = textMain.length * 16 + 60;
    doc.text(textMain, 40, 40);
    doc.setFontSize(14);
    doc.autoTable({
        startY: textY,
        columnStyles: {
            0: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
        },
        body: [
            [
                t('date pdf', { value: moment(calculator.created_at).format('DD.MM.YYYY') })
            ]
        ]
    });
    const finalY = doc.previousAutoTable.finalY + 40;


    const bodyHead = [
        ['Культура', normative?.culture?.name || rb?.culture?.name]
    ];

    const getEconomyType = (fertilizer) => {
        const arr = [];
        constantsCommon.economyTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item)); }
        });
        const str = arr.join(', ');
        return str;
    };

    const getMacro = (fertilizer) => {
        const arr = [];
        constantsCommon.macroTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item, { value: fertilizer[item] })); }
        });
        const str = arr.join(', ');
        return str;
    };

    const totalFertilizer = (distribution) => {
        const fertilizers = [];
        const totalRecommended = { actual_n: 0, actual_p: 0, actual_k: 0 };
        const total = { actual_n: 0, actual_p: 0, actual_k: 0 };
        STEPS.forEach(step => {
            totalRecommended.actual_n += distribution[`recommended_n_${step}`];
            totalRecommended.actual_p += distribution[`recommended_p_${step}`];
            totalRecommended.actual_k += distribution[`recommended_k_${step}`];
        });
        fertilizers.push({
            id: 0,
            type: 'row-bold-green',
            name: t('recommended'),
            ...totalRecommended
        });
        distribution.related_fertilizers?.forEach(fertilizer => {
            const curFertilizer = fertilizer.fertilizer;
            if (curFertilizer.id) {
                const actual = {};
                ['n', 'p', 'k'].forEach(item => {
                    actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer.total) / 100) ?? 0;
                    total[`actual_${item}`] += actual[`actual_${item}`];
                });
                fertilizers.push({
                    id: curFertilizer.id,
                    name: curFertilizer.name,
                    total: fertilizer.total,
                    ...actual
                });
            }
        });
        fertilizers.push({
            id: 0, type: 'row-bold-green', name: t('total'), ...total
        });
        return { title: t('total'), fertilizers };
    };

    doc.autoTable({
        startY: finalY,
        columnStyles: {
            0: { font: 'Arial', cellPadding: 10 },
            1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
        },
        body: bodyHead
    });

    if (agrochemical?.id) {
        const bodyParamsAgro = [
            ['Азот аммиачный (аммонийный) NH4, мг / 100г', agrochemical.nh4, intervals[agrochemical.nh4_interval]],
            ['Гумус', agrochemical.organic_substances, intervals[agrochemical.organic_substances_interval]],
            ['Азот нитратный NO3, мг / 100г', agrochemical.no3, intervals[agrochemical.no3_interval]],
            ['Метод анализа', methodsArr[agrochemical.method], ''],
            ['Фосфор подвижный P2O5, мг / 100г', agrochemical.p2o5, intervals[agrochemical.p2o5_interval]],
            ['Калий обменный K2O, мг / 100г', agrochemical.k2o, intervals[agrochemical.k2o_interval]],
            ['Сера подвижная S, мг / 100 г', agrochemical.s, intervals[agrochemical.s_interval]],
            ['Кальций Са, мг-экв / 100г', agrochemical.ca, intervals[agrochemical.ca_interval]],
            ['Магний Mg, мг-экв / 100г', agrochemical.mg, intervals[agrochemical.mg_interval]],
            ['Сульфаты SO4, мг / 100г', agrochemical.so4, intervals[agrochemical.so4_interval]],
            ['Хлориды Cl, мг / 100 г', agrochemical.cl, intervals[agrochemical.cl_interval]],
            ['Натрий Na, ммоль / 100г (мг / 100 г)', agrochemical.na, intervals[agrochemical.na_interval]],
            ['Электропроводность водной вытяжки почвы, мСм/см (дСм/м)', agrochemical.salt_conductivity, intervals[agrochemical.salt_conductivity_interval]],
            ['pH водный, ед. pH', agrochemical.ph_water, intervals[agrochemical.ph_water_interval]],
            ['pH солевой, ед. pH', agrochemical.ph_salt, intervals[agrochemical.ph_salt_interval]]
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 4, cellPadding: 10 },
                2: { font: 'Arial', cellWidth: pageWidth / 4, cellPadding: 10 }
            },
            body: bodyParamsAgro
        });
    }

    if (normative?.id) {
        const bodyParamsNormative = [
            ['Планируемая урожайность, т/га', normative?.planned_yield],
            ['Регион', normative?.region?.name],
            ['Будущая культура', normative?.culture?.name],
            ['Предшественник', normative?.preceding_culture?.name],
            ['Группа кислотности почвы', normative?.ph_salt],
            ['Гранулометрический состав почвы', normative?.mechanical_composition?.name]
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 4, cellPadding: 10 }
            },
            body: bodyParamsNormative
        });

        const paramsRecommended = [
            [
                t('table name'),
                t('table autumn'),
                t('table spring'),
                t('table feeding'),
                t('total')
            ],
            [
                'N',
                normative?.recommended_n_main,
                normative?.recommended_n_sowing,
                normative?.recommended_n_feeding,
                normative.recommended_n_main + normative.recommended_n_sowing + normative.recommended_n_feeding
            ],
            [
                'P',
                normative?.recommended_p_main,
                normative?.recommended_p_sowing,
                normative?.recommended_p_feeding,
                normative.recommended_p_main + normative.recommended_p_sowing + normative.recommended_p_feeding
            ],
            [
                'K',
                normative?.recommended_k_main,
                normative?.recommended_k_sowing,
                normative?.recommended_k_feeding,
                normative.recommended_k_main + normative.recommended_k_sowing + normative.recommended_k_feeding
            ]
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                2: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                3: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                4: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 }
            },
            body: paramsRecommended
        });
    }

    if (rb?.id) {
        const bodyParamsNormative = [
            ['Планируемая урожайность, т/га', rb?.planned_yield],
            ['Регион', rb?.region?.name],
            ['Будущая культура', rb?.culture?.name],
            ['Степень увлажнения', rb?.degree_of_soil_moisture?.name],
            ['Метод анализа почвы', rb?.analysis_method],
            ['Глубина отбора почвы, см', rb?.soil_sampling_depth],
            ['Год действия удобрения', rb?.fertilizer_action_year],
            ['Глубина пахотного слоя, см', rb?.depth_of_arable_layer],
            ['Механический состав почвы', rb?.mechanical_composition?.name],
            ['Азот, мг/100 г', rb?.report_n],
            ['Фосфор, мг/100 г', rb?.report_p],
            ['Калий, мг/100 г', rb?.report_k]
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 4, cellPadding: 10 }
            },
            body: bodyParamsNormative
        });

        const paramsRecommended = [
            [
                t('table name'),
                t('table autumn'),
                t('table spring'),
                t('table feeding'),
                t('total')
            ],
            ['N', rb?.recommended_n_main, rb?.recommended_n_sowing, rb?.recommended_n_feeding, rb.recommended_n_main + rb.recommended_n_sowing + rb.recommended_n_feeding],
            ['P', rb?.recommended_p_main, rb?.recommended_p_sowing, rb?.recommended_p_feeding, rb.recommended_p_main + rb.recommended_p_sowing + rb.recommended_p_feeding],
            ['K', rb?.recommended_k_main, rb?.recommended_k_sowing, rb?.recommended_k_feeding, rb.recommended_k_main + rb.recommended_k_sowing + rb.recommended_k_feeding]
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                2: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                3: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                4: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 }
            },
            body: paramsRecommended
        });
    }

    if (distribution?.id) {
        const bodyFertilizers = distribution.related_fertilizers?.map(item => [
            item.fertilizer.name,
            item.fertilizer.content_n,
            item.fertilizer.content_p,
            item.fertilizer.content_k
        ]);
        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                2: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                3: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 }
            },
            theme: 'grid',
            bodyStyles: { lineColor: [0, 0, 0] },
            body: bodyFertilizers
        });
        const distributionFertilizers = [];
        STEPS.forEach(step => {
            const total = { actual_n: 0, actual_p: 0, actual_k: 0 };
            const fertilizers = [];
            fertilizers.push({
                id: 0,
                name: t('recommended'),
                actual_n: distribution[`recommended_n_${step}`],
                actual_p: distribution[`recommended_p_${step}`],
                actual_k: distribution[`recommended_k_${step}`],
                total: ''
            });
            distribution.related_fertilizers?.forEach(fertilizer => {
                const curFertilizer = fertilizer.fertilizer;
                if (curFertilizer.id) {
                    const actual = {};
                    ['n', 'p', 'k'].forEach(item => {
                        actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer[step]) / 100) ?? 0;
                        total[`actual_${item}`] += actual[`actual_${item}`];
                    });
                    fertilizers.push({
                        id: curFertilizer.id,
                        name: curFertilizer.name,
                        total: fertilizer[step],
                        ...actual
                    });
                }
            });
            fertilizers.push({
                id: 0, name: t('total'), total: '', ...total
            });
            distributionFertilizers.push({ title: t(step), fertilizers });
        });
        distributionFertilizers?.forEach(fertilizer => {
            const body = [
                [fertilizer.title, 'N', 'P', 'K', 'Физический вес']
            ];
            fertilizer.fertilizers?.forEach(item => {
                body.push([
                    item.name,
                    item?.actual_n?.toFixed(2),
                    item?.actual_p?.toFixed(2),
                    item?.actual_k?.toFixed(2),
                    item.total
                ]);
            });
            doc.autoTable({
                startY: doc.previousAutoTable.finalY + 20,
                columnStyles: {
                    0: { font: 'Arial', cellPadding: 10 },
                    1: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                    2: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                    3: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                    4: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 }
                },
                theme: 'grid',
                bodyStyles: { lineColor: [0, 0, 0] },
                body
            });
        });

        const total = totalFertilizer(distribution);
        const body = [
            [t('total'), 'N', 'P', 'K', 'Физический вес']
        ];

        total.fertilizers?.forEach(item => {
            body.push([
                item.name,
                item?.actual_n?.toFixed(2),
                item?.actual_p?.toFixed(2),
                item?.actual_k?.toFixed(2),
                item.total
            ]);
        });
        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                2: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                3: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 },
                4: { font: 'Arial', cellWidth: pageWidth / 6, cellPadding: 10 }
            },
            theme: 'grid',
            bodyStyles: { lineColor: [0, 0, 0] },
            body
        });

        distribution.related_fertilizers?.forEach(item => {
            const fertilizer = { ...item.fertilizer };
            const tableY = doc.previousAutoTable.finalY + 60;
            const finalY = doc.previousAutoTable.finalY + 60;
            doc.text(fertilizer.name, 40, tableY);
            const applicationRate = [];
            if (fertilizer.presowing_application_rate) {
                applicationRate.push([
                    `${t('application rate')}. ${t('presowingApplicationRate')}`,
                    fertilizer.presowing_application_rate
                ]);
            }
            if (fertilizer.sowing_application_rate) {
                applicationRate.push([
                    `${t('application rate')}. ${t('sowingApplicationRate')}`,
                    fertilizer.sowing_application_rate
                ]);
            }
            if (fertilizer.basic_application_rate) {
                applicationRate.push([
                    `${t('application rate')}. ${t('basicApplicationRate')}`,
                    fertilizer.basic_application_rate
                ]);
            }
            if (fertilizer.top_dressing_application_rate_open) {
                applicationRate.push([
                    `${t('application rate')}. ${t('topDressingApplicationRateOpen')}`,
                    fertilizer.top_dressing_application_rate_open
                ]);
            }
            if (fertilizer.top_dressing_application_rate_close) {
                applicationRate.push([
                    `${t('application rate')}. ${t('topDressingApplicationRateClose')}`,
                    fertilizer.top_dressing_application_rate_close
                ]);
            }
            const bodyFertilizer = [
                [t('group fertilizers'), fertilizer.subgroup && fertilizer.subgroup?.group?.name],
                [t('subgroup fertilizers'), fertilizer.subgroup?.name],
                [t('culture group'), fertilizer.culture_description],
                [t('economy type'), getEconomyType(fertilizer)],
                [t('macrocells'), getMacro(fertilizer)],
                [t('release form'), fertilizer.release_form && fertilizer.release_form?.name]
            ];
            applicationRate.forEach(item =>
                (bodyFertilizer.push(item)));
            doc.autoTable({
                startY: finalY + 20,
                columnStyles: {
                    0: { font: 'Arial', cellPadding: 10 },
                    1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
                },
                body: bodyFertilizer
            });
        });
    }

    const fileName = rus2Latin(calculator.name?.substr(0, 50) ?? 'Calculator');
    doc.save(`${fileName}.pdf`);
}