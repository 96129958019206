import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Upload, Input
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import cn from '../../../utils/cn';
import { Button } from '../../../components';
import { selectors, actions } from '../../../models';

import FilesTable from './files-table';
import { EXTENTIONS } from '../../../constants';

const {
    addFile,
    addTask
} = actions;
const {
    getCurrentRequest,
    getUserId
} = selectors;

const mstp = (state) => ({
    request: getCurrentRequest(state),
    curUserId: getUserId(state)
});

const mdtp = ({
    addFile,
    addTask
});

const { TextArea } = Input;
@withRouter
@connect(mstp, mdtp)
@withTranslation('requests')
@cn('request-detail')
export default class RequestData extends Component {
    state = {
        fileList: [],
        files: [],
        isAdmin: false,
        message: '',
        isMess: false
    };

    componentDidMount() {
        const { curUserId, request } = this.props;
        const { userId } = request;
        this.setState({ isAdmin: Number(userId) !== Number(curUserId) });
    }

    beforeUpload = file => {
        const { t } = this.props;
        const fileItem = {};
        fileItem.file = file;
        if (file.size <= 52428800) {
            this.setState(state => ({
                fileList: [file],
                message: '',
                isMess: false
            }));
        } else {
            this.setState(state => ({
                message: t('file size'),
                isMess: true
            }));
        }

        return false;
    }
    
    onRemove = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList
            };
        });
    }

    onChange = (e) => {
        const { sendValues } = this.props;
        const { value } = e.target;
        this.setState({ [e.target.name]: value }, () => {
            const { files, title } = this.state;
            sendValues(files, title);
        });
    };

    addFileToTask = () => {
        const { sendValues } = this.props;
        const { fileList, description } = this.state;
        const uploadDate = new Date();
        this.setState(state => {
            const files = state.files.concat({
                file: fileList[0],
                description: description || '',
                fileName: fileList[0].name,
                uploadDate
            });
            return { files };
        }, () => {
            const { files, title } = this.state;
            this.setState({ fileList: [], description: '' });
            sendValues(files, title);
        });
    };

    render() {
        const { cn } = this;
        const { t, onClick } = this.props;
        const {
            fileList,
            files,
            isAdmin,
            isMess,
            message
        } = this.state;

        return (
            
            <div>
                <div className={cn('block-title')}>
                    {isAdmin ? t('answer') : t('clarification')}
                </div>
                <Row gutter={20}>
                    <Col span={12}>
                        <div className="field">
                            <TextArea
                                onChange={this.onChange}
                                label={isAdmin ? t('answer') : t('annotation')}
                                placeholder={isAdmin ? t('answer placeholder') : t('annotation placeholder')}
                                name="title" />
                        </div>
                    </Col>
                </Row>
                <div className={cn('block-title-no-border')}>
                    {t('files')}
                </div>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className={cn('label')}>{t('upload img')}</div>
                        <Upload fileList={fileList}
                            onRemove={this.onRemove}
                            beforeUpload={this.beforeUpload}>
                            <Button type="default">
                                <UploadOutlined /> {t('upload')}
                            </Button>
                        </Upload>
                        {isMess && <div className={cn('error')}>{message}</div>}
                    </Col>
                    {fileList.length > 0 && (
                        <Col span={16} style={{ marginTop: '30px' }}>
                            <div className={cn('label')}>{t('description')}</div>
                            <Input placeholder={t('description placeholder')}
                                onChange={this.onChange}
                                name="description" />
                            <Button type="primary"
                                className={cn('file-button')}
                                onClick={this.addFileToTask}>{t('add file')}
                            </Button>
                        </Col>
                    )}
                    {files.length > 0 && (
                        <Col span={24} style={{ marginTop: '30px' }}>
                            <FilesTable fileList={files} onClick={onClick} />
                        </Col>

                    )}
                </Row>

            </div>

        );
    }
}