import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Col, Collapse, Form, Layout, Row, Spin
} from 'antd';

import Icon from '@ant-design/icons';

import {
    Button, PanelLayout, Title
} from '../../components';
import './style.less';
import { useTranslation } from 'react-i18next';
import { useClassName } from '../../utils/cn';

import ListIcon from '../../../public/images/calculator/list2_stroke.svg';
import { calculatorsGroup, curPoints, getFieldUser } from '../../models/mordovia/selectors';

import Interpretation from './components/interpretation';
import InterpretationTable from './components/interpretation/table';
import AgroResults from './components/agro-results';
import NormativeNutrition from './components/normative-nutrition';
import Distribution from './components/distribution';
import { getCalculatorMordovia, getFieldPoints } from '../../models/mordovia/actions';
import { getUserId, isAuth } from '../../models/account/selectors';
import { isCalculateCompleted } from '../../models/item-calculate/selectors';

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;

const RecommendationRegistry = () => {
    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const match = useRouteMatch();
    const { params: { id, year } } = match;

    const { t } = useTranslation('recommendation registry');

    const points = useSelector(state => curPoints(state));
    const calcGroup = useSelector(state => calculatorsGroup(state));
    const isAuthState = useSelector(state => isAuth(state));
    const isCompletedState = useSelector(state => isCalculateCompleted(state));
    const fieldUserID = useSelector(state => getFieldUser(state));
    const userID = useSelector(state => getUserId(state));

    const [activeKeysState, setActiveKeysState] = useState(['agrochemical']);
    const [curPoint, setCurPoint] = useState(undefined);
    const [isOwner, setIsOwner] = useState(false);
    const [isCompleted, setCompleted] = useState(false);

    useEffect(() => {
        setIsOwner(fieldUserID === userID);
    }, [fieldUserID, userID]);

    useEffect(() => {
        setCompleted(isCompletedState);
    }, [isCompletedState]);

    useEffect(() => {
        if (id && year) {
            dispatch(getFieldPoints({ id, filter: year }));
            dispatch(getCalculatorMordovia({ id, year }));
        }
    }, [id, year]);

    useEffect(() => {
        console.log('points', points, 'calcGroup', calcGroup);
    }, [points, calcGroup]);

    const onCollapseChange = (keys) => {
        setActiveKeysState(keys);
    };

    return (
        <>
            <Layout>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <Collapse
                            activeKey={activeKeysState}
                            onChange={onCollapseChange}
                            expandIconPosition="right"
                            className={cn('collapse')}>
                            {points?.length > 0 && (
                                <Panel
                                    header={<><Icon component={ListIcon} />{t('recommendation registry tab agrochemical')}</>}
                                    key="agrochemical">
                                    <AgroResults setCurPoint={setCurPoint} />
                                </Panel>
                            )}

                            <Panel
                                header={<><Icon component={ListIcon} />{t('recommendation registry tab interpretation')}</>}
                                key="interpretation">
                                    <InterpretationTable isCompleted={!isAuthState || isCompleted || !isOwner} />
                                    <Interpretation year={year} isCompleted={!isAuthState || isCompleted || !isOwner} />
                            </Panel>

                            <Panel
                                header={<><Icon component={ListIcon} />{t('recommendation registry tab recommendation')}</>}
                                key="recommendation">
                                    Recommendation
                            </Panel>

                            <Panel
                                header={<><Icon component={ListIcon} />{t('recommendation registry tab expert')}</>}
                                key="expert">
                                    Expert
                            </Panel>
                        </Collapse>
                    </PanelContent>
                </PanelLayout>
            </Layout>
        </>
    );
};

export default RecommendationRegistry;
