import request from '../../utils/request';
import options from './options';

const { fertilizerUrl, protocolUrl, calculatorUrl } = options;

const urlPath = (url, path) => `${url}${path}`;

export const createNewFertilizer = body => request.post({
    url: '/api/v1/fed_calculator/fed_fertilizer/',
    body: body
});
export const updateFertilizerInfo = body => request.patch({
    url: `/api/v1/fed_calculator/fed_protocol_fertilizer/${body.id}/`,
    body: body.values
});
export const updateFertilizerPrice = ({ fertilizerId, newPrice }) => request.post({
    url: `/api/v1/fed_calculator/fed_protocol_fertilizer/${fertilizerId}/set_price/`,
    body: {
        price: newPrice
    }
});
export const createInitialProtocol = body => request.post({
    url: protocolUrl,
    body
});
export const getFertilizer = body => request.get({
    url: `${fertilizerUrl}${body.id}/`
});
export const getFertilizers = ({ page, pageSize, searchString }) => request.get({
    url: `${fertilizerUrl}?page=${page}&page_size=${pageSize}&sort=["name","DESC"]${searchString ? `&filter={"name.icontains": "${searchString}"}` : ''}`
});
export const getProtocol = body => request.get({
    url: urlPath(protocolUrl, body.id),
    body
});
export const getProtocolList = body => request.get({
    url: `${protocolUrl}?page=${body?.page}&page_size=${body?.pageSize}${body?.sorting?.length > 0 ? `${body?.sorting}` : ''}${body?.filtering?.length > 0 ? `${body?.filtering}` : ''}`
});
export const deleteProtocol = id => request.remove({
    url: `${protocolUrl}${id}/`
});
export const updateProtocol = body => request.patch({
    url: `${protocolUrl}${body.id}/`,
    body: body.values
});
export const updateProtocolFertilizers = body => request.post({
    url: `${protocolUrl}${body.id}/set_fertilizers/`,
    body: body.values
});
export const createInternalCalculator = body => request.post({
    url: `${protocolUrl}${body.id}/create_calculator/`,
    body: body?.values
});
export const updateInternalCalculator = body => request.post({
    url: `${calculatorUrl}${body.id}/set_parameters/`,
    body: body.values
});
export const publishProtocol = body => request.post({
    url: `${protocolUrl}${body.id}/publish_protocol/`,
    body: body.values
});