import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';
import { getCultures } from '../cultures/actions';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

function* removeCalculatorMycoSaga({ payload: id }) {
    const [start, success, failure] = getActions(
        constants.removeCalculatorMyco.try,
        constants.removeCalculatorMyco.success,
        constants.removeCalculatorMyco.error
    );
    try {
        yield put(start(id));
        yield call(api.removeCalculatorMyco, id);
        const obj = converter.removedCalculatorId2Client(id);
        yield put(success(obj));
    } catch (error) {
        yield put(failure(error));
    }
}

function* addCalculatorMycoSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addCalculatorMyco.try,
        constants.addCalculatorMyco.success,
        constants.addCalculatorMyco.error
    );
    try {
        yield put(start());
        const { history, values } = payload;
        const dataRaw = yield call(api.addCalculatorMyco, values);
        const data = converter._server2Client(dataRaw);
        yield put(getCultures('?page_size=1000'));
        yield put(actions.getGenusOfMicroCalculatorsMyco('?page_size=1000'));
        yield put(actions.getTypeOfResearchCalculatorsMyco('?page_size=1000'));
        yield put(success(data));
        const { id } = data;
        history.push(`/calculator-myco/${id}`);
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* getCalculatorMycoSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getCalculatorMyco.try,
        constants.getCalculatorMyco.success,
        constants.getCalculatorMyco.error
    );
    try {
        yield put(start());
        const dataRaw = yield call(api.getCalculatorMyco, payload);
        const data = converter._server2Client(dataRaw);
        yield put(getCultures('?page_size=1000'));
        yield put(actions.getGenusOfMicroCalculatorsMyco('?page_size=1000'));
        yield put(actions.getTypeOfResearchCalculatorsMyco('?page_size=1000'));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}
export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsMyco.toString(),
            defaultSaga({
                constants: constants.getCalculatorsMyco,
                api: api.getCalculatorsMyco
            })
        ),
        yield takeLatest(
            constants.getCalculatorMyco.toString(),
            getCalculatorMycoSaga
        ),
        yield takeLatest(
            constants.addCalculatorMyco.toString(),
            addCalculatorMycoSaga
        ),
        yield takeLatest(
            constants.closeCalculatorMyco.toString(),
            defaultSaga({
                constants: constants.closeCalculatorMyco,
                api: api.closeCalculatorMyco
            })
        ),
        yield takeLatest(
            constants.removeCalculatorMyco.toString(),
            defaultSaga({
                constants: constants.removeCalculatorMyco,
                api: api.removeCalculatorMyco
            })
        ),
        yield takeLatest(
            constants.setParamsCalculatorMyco.toString(),
            defaultSaga({
                constants: constants.setParamsCalculatorMyco,
                api: api.setParamsCalculatorMyco,
                after: converter._server2Client
            })
        ),
        yield takeLatest(
            constants.getCultureCalculatorsMyco.toString(),
            defaultSaga({
                constants: constants.getCultureCalculatorsMyco,
                api: api.getCultureCalculatorsMyco
            })
        ),
        yield takeLatest(
            constants.getPrecedingCalculatorsMyco.toString(),
            defaultSaga({
                constants: constants.getPrecedingCalculatorsMyco,
                api: api.getPrecedingCalculatorsMyco
            })
        ),
        yield takeLatest(
            constants.getGenusOfMicroCalculatorsMyco.toString(),
            defaultSaga({
                constants: constants.getGenusOfMicroCalculatorsMyco,
                api: api.getGenusOfMicroCalculatorsMyco
            })
        ),
        yield takeLatest(
            constants.getTypeOfResearchCalculatorsMyco.toString(),
            defaultSaga({
                constants: constants.getTypeOfResearchCalculatorsMyco,
                api: api.getTypeOfResearchCalculatorsMyco
            })
        )
    ]);
}