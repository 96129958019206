import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form, Select, Row, Col, Button, DatePicker, InputNumber, Input, Table, Tabs, Modal
} from 'antd';
import { useTranslation } from 'react-i18next';
import { calculatorsGroup, curPoints, getCurCalculatorField, getCurrentCalculatorMordovia } from '../../../../models/mordovia/selectors';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { calculatedRecommendationRegistry, createdRecommendationRegistry, currentRecommendationCompositions, currentRecommendationCultures, currentRecommendationRegions, currentRecommendationSubtypes } from '../../../../models/recommendation-registries/selectors';
import { calculateRecommendation, createRecommendationRegistry, getCompositions, getCultures, getRegions, getSubtypes } from '../../../../models/recommendation-registries/actions';
import { DeleteOutlined, PlusCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import FertilizerModal from './fertilizer-modal';
import NormativeNutrition from '../normative-nutrition';
import Distribution from '../distribution';
import { updateCalculate } from '../../../../models/item-calculate/actions';
import { useClassName } from '../../../../utils/cn';
import XlsxGenerator from '../../../../containers/xlsx-generator';
import { getCalculatorMordovia } from '../../../../models/mordovia/actions';
import { getUser } from '../../../../models/account/selectors';
import moment from 'moment';

const { TabPane } = Tabs;

const ExpertRecommendations = ({
    isAuthState, isCompleted, isOwner, isFasie, setActive,
    setIsSend, generatedPDF, setExpert
}) => {
    const cn = useClassName('calculators');
    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [distributionForm] = Form.useForm();
    const { t } = useTranslation('mordovia');
    const { t: interT } = useTranslation('interpretation');

    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));
    const calculatorTwo = useSelector(state => getCurCalculatorField(state));
    const calcGroup = useSelector(state => calculatorsGroup(state));
    const createdRecommendation = useSelector(state => createdRecommendationRegistry(state));
    const calculatedRecommendation = useSelector(state => calculatedRecommendationRegistry(state));
    const points = useSelector(state => curPoints(state));
    const user = useSelector(state => getUser(state));
    const cultureList = useSelector(state => currentRecommendationCultures(state));

    const [visible, setVisible] = useState(false);
    const [cultureCatalog, setCultureCatalog] = useState([]);
    const [cultureNewPortion, setCultureNewPortion] = useState([]);
    const [culturePage, setCulturePage] = useState(1);
    const [cultureNext, setCultureNext] = useState('');
    const [recommended, setRecommended] = useState({});
    const [modalText, setModalText] = useState('');

    const isLaborant = user?.groups?.includes('Laborant');
    //
    const [recommendationsList, setRecommendationsList] = useState([]);
    const [recommendationStage, setRecommendationStage] = useState(1);
    const [recommendationCreationBody, setRecommendationCreationBody] = useState({});
    const [recommendationCalculationBody, setRecommendationCalculationBody] = useState({});
    const [selectedFertilizers, setSelectedFertilizers] = useState([]);
    const [showFertilizerModal, setShowFertilizerModal] = useState(false);
    const [dateOptions, setDateOptions] = useState([]);

    useEffect(() => {
        if (recommendationsList?.length > 0) {
            setExpert(true);
        } else {
            setExpert(false);
        }
    }, [recommendationsList]);
    

    useEffect(() => {
        const calcYears = calculator?.year_group?.years;
        if (calcYears?.length > 0) {
            const year = calcYears.filter(x => x?.year?.toString() === match?.params?.year)[0];
            form?.setFieldsValue({ sowing_date: year?.sowing_date ? moment(year?.sowing_date) : null });
            setSelectedDate(year?.sowing_date);
        }
    }, [calculator]);
    
    useEffect(() => {
        const makeSet = new Set(points?.map(x => x?.date));
        const resArr = Array.from(makeSet);
        const answerIs = resArr?.map(x => {
            return {
                label: x ? moment(x).format('DD.MM.YYYY') : '-',
                value: x
            }
        });
        setDateOptions(answerIs);
    }, [points]);
    

    useEffect(() => {
        const { id, year } = match.params;
        if (id && year) {
            setTimeout(() => dispatch(getCalculatorMordovia({ id, year })), 1000);
        }
    }, [calculatedRecommendation]);
    

    useEffect(() => {
        if (cultureNext?.length > 0) {
            setCulturePage(culturePage + 1);
        }
    }, [cultureNext]);

    useEffect(() => {
        dispatch(getCultures({ filter: `&page=${culturePage}` }));
    }, [culturePage]);
    
    useEffect(() => {
        const { next, results } = cultureList;

        setCultureNewPortion(results);
        setCultureNext(next);
    }, [cultureList]);

    useEffect(() => {
        if (cultureNewPortion?.length > 0) {
            setCultureCatalog(cultureCatalog.concat(cultureNewPortion?.map(x => {
                return {
                    label: x?.name,
                    value: x?.id
                }
            })));
        }
    }, [cultureNewPortion]);
    //

    useEffect(() => {
        setRecommendationsList(calcGroup?.filter(x => x?.calculator_type === 14));
    }, [calcGroup]);
    

    //
    const compositionList = useSelector(state => currentRecommendationCompositions(state));
    const [compositionCatalog, setCompositionCatalog] = useState([]);
    const [compositionNewPortion, setCompositionNewPortion] = useState([]);
    const [compositionPage, setCompositionPage] = useState(1);
    const [compositionNext, setCompositionNext] = useState('');

    useEffect(() => {
        if (compositionNext?.length > 0) {
            setCompositionPage(compositionPage + 1);
        }
    }, [compositionNext]);

    useEffect(() => {
        dispatch(getCompositions({ filter: `?page=${compositionPage}` }));
    }, [compositionPage]);

    useEffect(() => {
        const { next, results } = compositionList;

        setCompositionNewPortion(results);
        setCompositionNext(next);
    }, [compositionList]);

    useEffect(() => {
        if (compositionNewPortion?.length > 0) {
            const makeSet = new Set([...compositionCatalog, ...compositionNewPortion?.map(x => {
                return {
                    label: x?.name,
                    value: x?.id
                }
            })]);
            const resArray = Array.from(makeSet);
            setCompositionCatalog(resArray);
        }
    }, [compositionNewPortion]);
    //

    //
    const regionList = useSelector(state => currentRecommendationRegions(state));
    const [regionCatalog, setRegionCatalog] = useState([]);
    const [regionNewPortion, setRegionNewPortion] = useState([]);
    const [regionPage, setRegionPage] = useState(1);
    const [regionNext, setRegionNext] = useState('');

    useEffect(() => {
        if (regionNext?.length > 0) {
            setRegionPage(regionPage + 1);
        }
    }, [regionNext]);

    useEffect(() => {
        dispatch(getRegions({ filter: `&page=${regionPage}` }));
    }, [regionPage]);

    useEffect(() => {
        const { next, results } = regionList;

        setRegionNewPortion(results);
        setRegionNext(next);
    }, [regionList]);

    useEffect(() => {
        if (regionNewPortion?.length > 0) {
            setRegionCatalog(regionCatalog.concat(regionNewPortion?.map(x => {
                return {
                    label: x?.name,
                    value: x?.id
                }
            })));
        }
    }, [regionNewPortion]);
    //

    //
    const subtypeList = useSelector(state => currentRecommendationSubtypes(state));
    const [subtypeCatalog, setSubtypeCatalog] = useState([]);
    const [subtypeNewPortion, setSubtypeNewPortion] = useState([]);
    const [subtypePage, setSubtypePage] = useState(1);
    const [subtypeNext, setSubtypeNext] = useState('');

    useEffect(() => {
        if (subtypeNext?.length > 0) {
            setSubtypePage(subtypePage + 1);
        }
    }, [subtypeNext]);
    
    useEffect(() => {
        dispatch(getSubtypes({ filter: `?page=${subtypePage}` }));
    }, [subtypePage]);

    useEffect(() => {
        const { next, results } = subtypeList;
        setSubtypeNewPortion(results);
        setSubtypeNext(next);
    }, [subtypeList]);

    useEffect(() => {
        if (subtypeNewPortion?.length > 0) {
            const makeSet = new Set([...subtypeCatalog, ...subtypeNewPortion?.map(x => {
                return {
                    label: x?.name,
                    value: x?.id
                }
            })]);
            const resArray = Array.from(makeSet);
            setSubtypeCatalog(resArray);
        }
    }, [subtypeNewPortion]);
    //

    const [selectedSubtype, setSelectedSubtype] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [feedingsNumber, setFeedingsNumber] = useState(1);
    const [feedingNumbers, setFeedingNumbers] = useState([0]);

    useEffect(() => {
        let iter = 0;
        const newFeeds = [];

        while (iter < feedingsNumber) {
            newFeeds.push(0);
            iter++;
        }

        setFeedingNumbers(newFeeds);
    }, [feedingsNumber]);

    useEffect(() => {
        if (match?.params?.year) {
            form.setFieldsValue({ year: match?.params?.year });
        }

        if (match?.params?.id) {
            form.setFieldsValue({ vega_key: match?.params?.id });
        }

        // if () {
        //     dispatch(getCurrentCalculatorMordovia({ id: match?.params?.id })
        // }
    }, [match]);

    useEffect(() => {
        const formSetterValues = {};

        if (calculator?.region?.id) { formSetterValues.region = calculator?.region?.id; }
        if (calculator?.culture?.id) { formSetterValues.culture = calculator?.culture?.id; }
        if (calculator?.preceding_culture?.id) { formSetterValues.preceding_culture = calculator?.preceding_culture?.id; }
        if (calculator?.mechanical_composition?.id) { formSetterValues.mechanical_composition = calculator?.mechanical_composition?.id; }
        if (calculator?.planned_yield) { formSetterValues.planned_yield = calculator?.planned_yield; }
        if (calculator?.vega_key) { formSetterValues.vega_key = calculator?.vega_key; }
        if (calculator?.field_num) { formSetterValues.field_num = calculator?.field_num; }

        form.setFieldsValue(formSetterValues);
    }, [calculator]);

    const handleCreateRegistry = () => {
        const formVals = form.getFieldsValue();
        const formKeys = Object.keys(formVals);
        const feedingKeys = formKeys.filter(x => x.includes('feeding'));
        const feedingsArray = feedingKeys.map(x => formVals[x]);

        const reqBody = {
            region: formVals?.region,
            culture: formVals?.culture,
            preceding_culture: formVals?.preceding_culture,
            mechanical_composition: formVals?.mechanical_composition,
            field_num: formVals?.field_num,
            vega_key: formVals?.vega_key,
            planned_yield: formVals?.planned_yield,
            soil_subtype: formVals?.soil_subtype,
            feeding_stages: feedingsArray,
            sowing_date: formVals?.sowing_date?.format('YYYY-MM-DD'),
            year: formVals?.year
        }

        setRecommendationCreationBody(reqBody);
        
        if (reqBody?.region && reqBody?.culture && reqBody?.preceding_culture && reqBody?.mechanical_composition && (reqBody?.field_num || reqBody?.vega_key) && reqBody?.planned_yield && reqBody?.soil_subtype && reqBody?.feeding_stages?.length > 0 && reqBody?.sowing_date && reqBody?.year) {
            setRecommendationStage(2);
        }
    };

    const repOptions = [1, 2, 3, 4, 5].map(x => ({ label: x, value: x }));

    const handleCalculation = () => {
        const formVals = form.getFieldsValue();
        const reqBody = {
            field_year: calculator?.year_group?.years?.filter(x => x?.year?.toString() === match?.params?.year)[0]?.id,
            type: formVals?.type,
            // method: formVals?.method,
            analysis_date: formVals?.analysis_date,
            fertilizers: selectedFertilizers?.map(x => x.id) || []
        };

        if (formVals?.type === 'zones') {
            reqBody.zones = [formVals?.zones];
        }

        if (formVals?.type === 'points') {
            reqBody.points = formVals?.points;
        }

        setRecommendationCalculationBody(reqBody);
        dispatch(createRecommendationRegistry(recommendationCreationBody));
    };

    useEffect(() => {
        if (createdRecommendation?.id > 0) {
            dispatch(calculateRecommendation({
                recommendationId: createdRecommendation?.id,
                values: recommendationCalculationBody
            }));
        }
    }, [createdRecommendation]);

    const [typeValue, setTypeValue] = useState('field');

    const typeOptions = [
        {
            label: t('type field'),
            value: 'field'
        },
        {
            label: t('type points'),
            value: 'points'
        },
        {
            label: t('type zones'),
            value: 'zones'
        }
    ];

    const zoneOptions = [
        {
            label: t('zone high'),
            value: 'high'
        },
        {
            label: t('zone middle'),
            value: 'middle'
        },
        {
            label: t('zone low'),
            value: 'low'
        }
    ];

    const methodOptions = [
        {
            label: t('method kirsanov'),
            value: 'kirsanov'
        },
        {
            label: t('method chirikov'),
            value: 'chirikov'
        },
        {
            label: t('method machigin'),
            value: 'machigin'
        },
    ];

    const removeFertilizer = (removableItem) => {
        setSelectedFertilizers(selectedFertilizers.filter(x => x?.id !== removableItem?.id));
    };

    const fertilizerColumns = [
        {
            title: t('fertilizer table name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'N, %',
            dataIndex: 'content_n',
            key: 'content_n'
        },
        {
            title: 'P, %',
            dataIndex: 'content_p',
            key: 'content_p'
        },
        {
            title: 'K, %',
            dataIndex: 'content_k',
            key: 'content_k'
        },
        {
            title: t('fertilizer table avg_price'),
            dataIndex: 'avg_price',
            key: 'avg_price'
        },
        {
            key: 'actions',
            render: (record) => (<DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => removeFertilizer(record)} />)
        }
    ];

    const handleClick = () => {
        if (isAuthState) {
            history.push(`/representation/${match?.params?.id}`);
            // history.push(`/mordovia/${match?.params?.id}`);
        } else {
            setActive(true);
            setIsSend(true);
        }
    };

    const fixedCalculate = () => {
        setVisible(true);
        const type = t('calculate type for save'); // или по данным эксперта
        const name = calculatorTwo?.normative?.culture?.name;
        const planned_yield = calculatorTwo?.normative?.planned_yield;

        const text = `${t('text type')} ${t('text name', { value: name })} ${t('text yield', { value: planned_yield })}`;
        setModalText(text);
    };

    const unfixedCalculate = () => {
        dispatch(updateCalculate({
            id: calculatorTwo.id,
            values: {
                name: calculatorTwo.name,
                calculator_type: calculatorTwo.calculator_type,
                is_done: false
            }
        }));
    };

    const onCloseCalculate = () => {
        dispatch(updateCalculate({
            id: calculatorTwo.id,
            values: {
                name: calculatorTwo.name,
                calculator_type: calculatorTwo.calculator_type,
                is_done: true
            }
        }));
    };

    const onCancel = () => {
        setVisible(false);
    };

    return (
        <>
            {isLaborant && (
                <Form form={form} labelCol={{ span: 24 }}>
                    {recommendationStage === 2 ? (
                        <>
                            <Row>
                                <Col>
                                    <a onClick={() => setRecommendationStage(1)}>{`< ${t('go back')}`}</a>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item name='type' label={t('form type')}>
                                        <Select options={typeOptions} style={{ width: '100%' }} onChange={setTypeValue} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    {typeValue === 'points' && (
                                        <Form.Item name='points' label={t('form points')}>
                                            <Select
                                                mode="multiple"
                                                options={points?.map(x => {
                                                    return {
                                                        label: x?.point_number,
                                                        value: x?.point_number
                                                    }
                                                })}
                                                style={{ width: '100%' }} />
                                        </Form.Item>
                                    )}
                                    {typeValue === 'zones' && (
                                        <Form.Item name='zones' label={t('form zones')}>
                                            <Select options={zoneOptions} style={{ width: '100%' }} />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                {/* <Col span={12}>
                                    <Form.Item name='method' label={t('form method')}>
                                        <Select options={methodOptions} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col> */}
                                <Col span={12}>
                                    <Form.Item name='analysis_date' label={t('form analysis_date')}>
                                        <Select options={dateOptions} />
                                        {/* <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} /> */}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '15px' }}>
                                <Button type="primary" onClick={handleCalculation}>
                                    {t('calculate recommendation')}
                                </Button>
                            </Row>
                            <Row style={{ marginTop: '15px' }}>
                                <Button type="primary" onClick={() => setShowFertilizerModal(true)}>
                                    <PlusCircleOutlined />
                                    {t('add fertilizer')}
                                </Button>
                            </Row>
                            <FertilizerModal
                                isOpen={showFertilizerModal}
                                setIsOpen={setShowFertilizerModal}
                                fertilizerList={selectedFertilizers}
                                setFertilizerList={setSelectedFertilizers} />
                            <Table rowKey="id" columns={fertilizerColumns} dataSource={selectedFertilizers} style={{ marginTop: '15px' }} />
                        </>
                    ) : (
                        <>
                            <Row gutter={12}>
                                <Col span={6}>
                                    <Form.Item name='year' label={t('form year')}>
                                        <Select disabled style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name='vega_key' label={t('form vega_key')}>
                                        <Input disabled style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name='field_num' label={t('form field_num')}>
                                        <Input disabled style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name='sowing_date' label={t('form sowing_date')}>
                                        <DatePicker disabled style={{ width: '100%' }} format="DD.MM.YYYY" onChange={setSelectedDate} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={12}>
                                <Col span={6}>
                                    <Form.Item name='soil_subtype' label={t('form soil_subtype')}>
                                        <Select
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                            style={{ width: '100%' }}
                                            options={subtypeCatalog} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name='mechanical_composition' label={t('form mechanical_composition')}>
                                        <Select
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                            style={{ width: '100%' }}
                                            options={compositionCatalog} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name='planned_yield' label={`${t('form planned_yield')}, т/га`}>
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name='region' label={t('form region')}>
                                        <Select
                                            disabled
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                            style={{ width: '100%' }}
                                            options={regionCatalog} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={12}>
                                <Col span={6}>
                                    <Form.Item name='preceding_culture' label={t('form preceding_culture')}>
                                        <Select
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                            style={{ width: '100%' }}
                                            options={cultureCatalog} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name='culture' label={t('form culture')}>
                                        <Select
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                            style={{ width: '100%' }}
                                            options={cultureCatalog} />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row style={{ marginTop: '15px' }}>
                                {t('number of feedings')}
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <Select options={repOptions} value={feedingsNumber} onChange={setFeedingsNumber} style={{ width: '100%' }} />
                                </Col>
                            </Row>

                            <Row>
                                {feedingNumbers.map((itm, idx) => (
                                    <Col span={4}>
                                        <Form.Item key={idx} name={`feeding/${idx + 1}`} label={`${t('feeding num')} ${idx + 1}, %`}>
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Button type="primary" onClick={handleCreateRegistry}>
                                    {t('create recommendation registry')}
                                </Button>
                            </Row>
                        </>
                    )}
                </Form>
            )}
            {recommendationsList?.length > 0 && (
                <Form form={distributionForm} style={{ marginTop: '15px' }}>
                    <Tabs defaultActiveKey="1">
                        {recommendationsList?.filter(x => {
                            if (x?.type === 'points') {
                                if (x?.points?.length <= 1) {
                                    return true;
                                }

                                return false;
                            }
                            return true;
                        })?.map(recommendationReg => {
                            return (
                                <TabPane
                                    tab={recommendationReg?.type === 'field'
                                        ? (recommendationReg.type === 'field' && `${interT(recommendationReg.type)}${recommendationReg?.date ? `- ${recommendationReg?.date}` : ''}`)
                                    : (
                                        recommendationReg.type === 'zones'
                                            ? `${interT(recommendationReg.type)} ${recommendationReg.zones?.map((el, i) => `${interT(el)}${i < recommendationReg.length - 1 ? ', ' : ''}`)}${recommendationReg?.date ? ` - ${moment(recommendationReg?.date).format('DD.MM.YYYY')}` : ''}`
                                            : `${interT(recommendationReg.type)} ${recommendationReg.points?.join(', ')}${recommendationReg?.date ? ` - ${moment(recommendationReg?.date).format('DD.MM.YYYY')}` : ''}`
                                    )}
                                    key={recommendationReg.id}>
                                        <NormativeNutrition
                                            calculator={recommendationReg}
                                            normative={recommendationReg.normative}
                                            distribution={recommendationReg.distribution}
                                            nutrition={recommendationReg.nutrition}
                                            isCompleted={!isAuthState || isCompleted || !isOwner} />
                                        {!!recommendationReg.normative?.id && (isAuthState || !!recommendationReg.distribution?.id) && (
                                            <div style={{ marginTop: '40px' }}>
                                                <Distribution
                                                    allowable={isLaborant}
                                                    isExpert={isLaborant}
                                                    type="AGROCHEMICAL_NORMATIVE_DISTRIBUTION"
                                                    vegaKey={match?.params?.id}
                                                    calculator={recommendationReg}
                                                    normative={recommendationReg.normative}
                                                    nutrition={recommendationReg.nutrition}
                                                    distribution={recommendationReg.distribution}
                                                    isCompleted={!isAuthState || isCompleted || !isOwner}
                                                    {...recommended} />
                                            </div>
                                        )}
                                        {!isFasie && (
                                            <Row className={cn('button')} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <Col>
                                                    {!isAuthState && (
                                                        <Button
                                                            type="primary"
                                                            onClick={handleClick}
                                                            size="large">
                                                            <DownloadOutlined />
                                                            {t('change data')}
                                                        </Button>
                                                    )}
                                                    <Button
                                                        type="primary"
                                                        onClick={generatedPDF}
                                                        size="large">
                                                        <DownloadOutlined />
                                                        {t('generate pdf')}
                                                    </Button>
                                                    <XlsxGenerator />
                                                    {!isCompleted && isOwner && isLaborant && (
                                                        <Button
                                                            type="primary"
                                                            disabled={!isAuthState || isCompleted || !isOwner}
                                                            onClick={fixedCalculate}
                                                            size="large">
                                                            {t('fixed calculate')}
                                                        </Button>
                                                    )}
                                                    {isCompleted && isOwner && (
                                                        <Button
                                                            type="primary"
                                                            disabled={!isAuthState || !isOwner}
                                                            onClick={unfixedCalculate}
                                                            size="large">
                                                            {t('unfixed calculate')}
                                                        </Button>
                                                    )}
                                                    <Modal
                                                        title={t('fixed title')}
                                                        visible={visible}
                                                        okText={t('ok text')}
                                                        cancelText={t('cancel text')}
                                                        onOk={onCloseCalculate}
                                                        onCancel={onCancel}>
                                                        {modalText}
                                                    </Modal>
                                                </Col>
                                            </Row>
                                        )}
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </Form>
            )}
        </>
    );
};

export default ExpertRecommendations;