import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, InputNumber, Icon, Rate, Form, Input
} from 'antd';

import cn from '../../../../utils/cn';
import { Button, Tabs, Table } from '../../../../components';
import { selectors, actions } from '../../../../models';

import ReviewsTable from './review-table';
import ReviewsModal from './review-modal';
import { regions } from '../../../../regions';

const {
    getReviews,
    getAverageRate
} = actions;
const {
    isReviewLoading,
    getReviewsList,
    getReviewsListCount,
    getReviewsBody,
    getCurrentFertilizer
} = selectors;

const mstp = (state) => ({
    reviewsList: getReviewsList(state),
    reviewsCount: getReviewsListCount(state),
    reviewsPage: getReviewsBody(state),
    fertilizer: getCurrentFertilizer(state)
});
const mdtp = ({
    getReviews,
    getAverageRate
});


const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;

@withRouter
@connect(mstp, mdtp)
@withTranslation('fertilizer review')
@cn('fertilizer-reviews')
export default class CalculatorData extends Component {
    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        perPage: 10,
        pageNum: 0,
        rate: undefined,
        region: undefined,
        steps: undefined,
        fertilizerId: undefined,
        showInfo: false,
        showReview: {}
    };

    componentDidMount() {
        const { match } = this.props;
        const { id } = match.params;
        this.setState({ fertilizerId: id }, () => {
            this.getFilteredReviews();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { reviewsCount, getAverageRate } = this.props;
        const { fertilizerId } = this.state;
        if (prevProps.reviewsCount !== reviewsCount) {
            getAverageRate({ fertilizerId });
        }
    }

    getFilteredReviews = () => {
        const { getReviews } = this.props;
        const {
            perPage,
            pageNum,
            rate,
            region,
            steps,
            fertilizerId
        } = this.state;
        getReviews({
            perPage,
            pageNum,
            rate,
            region,
            steps,
            fertilizerId
        });
    };

    onChange = (e, form) => {
        this.setState(e, () => {
            this.setState({ pageNum: 0 }, () => {
                this.getFilteredReviews();
            });
        });
    };

    hideInfo = () => {
        this.setState({ showInfo: false });
    };

    onCurrentPageChange = (page) => {
        const newPageNum = page - 1; // page count starts from 0
        this.setState({
            pageNum: newPageNum
        }, () => {
            this.getFilteredReviews();
        });
    };

    onPageSizeChange = (pageSize) => {
        this.setState({
            perPage: pageSize, pageNum: 0
        }, () => this.getFilteredReviews());
    };

    get filterItems() {
        const { reviewsCount: dataSize } = this.props;
        const items = [];
        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        return items;
    }


    showFilter = () => {
        this.setState({ showFilter: true });
    };

    createReview = () => {
        const { createReview } = this.props;
        createReview(true);
    };

    showInfo = (review) => {
        this.setState({ showInfo: true, showReview: review });
    };

    render() {
        const { cn } = this;
        const {
            t,
            reviewsList,
            reviewsPagesCount,
            reviewsCount,
            fertilizer,
            curRate
        } = this.props;

        const {
            perPage,
            pageNum,
            rate,
            region,
            steps,
            fertilizerId,
            showReview,
            showInfo
        } = this.state;

        const { name } = fertilizer;

        const years = [];
        for (let i = 2000; i <= 2020; i++) {
            years.push({ value: i, title: i });
        }

        return (
            <div className={cn()}>
                <Row>
                    <Col span={24} style={{ marginBottom: '30px' }}>
                        <div className={cn('rate')}>
                            <Rate disabled allowHalf value={curRate} />
                            <div className={cn('rate', 'value')}>{curRate.toFixed(1)}</div>
                        </div>
                    </Col>
                    <Col span={24} style={{ marginBottom: '40px' }}>
                        <Button type="default" onClick={this.createReview}>
                            {t('add')}
                        </Button>
                    </Col>
                </Row>

                <Form
                    onValuesChange={this.onChange}
                    onMount={this.onMount}
                    name="reviews-filter">
                    <Row gutter={20} style={{ marginBottom: '30px' }}>
                        <Col span={6}>
                            <Select
                                label={t('rate')}
                                name="rate"
                                mode="multiple"
                                placeholder=""
                                items={
                                    [
                                        { value: 1, title: 1 },
                                        { value: 2, title: 2 },
                                        { value: 3, title: 3 },
                                        { value: 4, title: 4 },
                                        { value: 5, title: 5 }
                                    ]
                                } />
                        </Col>
                        <Col span={6}>
                            <Select
                                label={t('steps')}
                                name="steps"
                                mode="multiple"
                                placeholder=""
                                items={[
                                    { value: 'autumn', title: t('autumn') },
                                    { value: 'spring', title: t('spring') },
                                    { value: 'first', title: t('first') },
                                    { value: 'second', title: t('second') }
                                ]} />
                        </Col>
                        <Col span={6}>
                            <Select
                                label={t('region')}
                                name="region"
                                mode="multiple"
                                placeholder=""
                                items={
                                    regions.map(region =>
                                        ({ value: region.code, title: region.name }))
                                } />
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col span={24}>
                        <ReviewsTable
                            filterItems={this.filterItems}
                            dataSource={reviewsList}
                            dataSize={reviewsCount}
                            pageSize={perPage}
                            pageNum={pageNum}
                            onSetFertilizer={this.onSetFertilizer}
                            showInfo={this.showInfo}
                            onPageSizeChange={this.onPageSizeChange}
                            onCurrentPageChange={this.onCurrentPageChange} />
                    </Col>
                </Row>
                <ReviewsModal
                    curRate={curRate}
                    title={name}
                    visible={showInfo}
                    review={showReview}
                    onCancel={this.hideInfo} />
            </div>

        );
    }
}