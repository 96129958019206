import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import {
    Modal, Button, Row, Col
} from 'antd';
import BreadCrumbs from './breadcrumbs';
import SliderWrapper from './slider-wrapper';
import Determinants from './determinant';


export default (props) => {
    const cn = useClassName('home-page');
    const { t } = useTranslation('home');
    const { setPageType } = props;

    const onCancel = () => {
        setActive(false);
    };

    const [active, setActive] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const onStart = () => {
        setActive(true);
    };

    const setReg = () => {
        setIsLogin(false);
    };

    const setLogin = () => {
        setIsLogin(true);
    };

    const goToModule = (url) => {
        localStorage.setItem('prevPage', url);
        onStart();
    };

    return (
        <div className={cn()}>
            <Row className={cn('block')}>
                <Col span={24}>
                    <BreadCrumbs type="main" setPageType={setPageType} isShow={false} />
                </Col>
                <Col span={12}>
                    <h1>
                        {t('title')}
                    </h1>
                    <p className={cn('text')}>
                        {t('subtitle')}
                    </p>
                </Col>
                <Col span={12}>
                    <img src="/images/icon_main.svg" />
                </Col>
            </Row>
            <Row className={cn('block-auto')} style={{ marginBottom: '60px' }}>
                <Col span={24}>
                    <div className={cn('partners')}>
                        <div onClick={() => setPageType('partners')}>
                            <img src="/images/logos/logo_fsi.svg" alt="" />
                        </div>
                        <div>
                            <a href="https://innopolis.university/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/logos/logo_iu.svg" alt="" />
                            </a>
                        </div>
                        <div>
                            <a href="http://innogeotech.ru/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/logos/logo_inno_rus.svg" alt="" />
                            </a>
                        </div>
                        <div>
                            <img src="/images/logos/logo_sort.svg" alt="" />
                        </div>
                        <div>
                            <a href="https://acxt.ru/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/logos/logo_gbpou.svg" alt="" />
                            </a>
                        </div>
                        <div>
                            <a href="https://kazgau.ru/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/logos/logo-kgau.png" alt="" />
                            </a>
                        </div>
                        <div>
                            <img src="/images/logos/logo_nur.svg" alt="" />
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className={cn('block-auto')}>
                <Col span={24}>
                    <Row gutter={40} style={{ marginBottom: '40px' }}>
                        <Col span={12}>
                            <div className={`${cn('module-block')} ${cn('module-block-frame')} mb-font-24`}>
                                <h4>{t('for legal entities')}</h4>
                                <p className="bm-font-22">{t('you can')}:</p>
                                <ul className={cn('module-block-ul')}>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7].map(item => (
                                            <li className="bm-font-22" key={t(`legal entities${item}`)}>{t(`legal entities${item}`)}</li>
                                        ))
                                    }
                                </ul>
                                <div className={cn('module-block__btn-block')}>
                                    <Button type="primary"
                                        size="large"
                                        onClick={() => { setPageType('legal-entities'); }}>
                                        {t('go')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={`${cn('module-block')} ${cn('module-block-frame')}`}>
                                <h4>{t('for individuals')}</h4>
                                <p className="bm-font-22">{t('you can')}:</p>
                                <ul className={cn('module-block-ul')}>
                                    {
                                        [1, 2, 3, 4, 5, 6].map(item => (
                                            <li className="bm-font-22" key={t(`individuals${item}`)}>{t(`individuals${item}`)}</li>
                                        ))
                                    }
                                </ul>
                                <div className={cn('module-block__btn-block')}>
                                    <Button type="primary"
                                        size="large"
                                        onClick={() => { setPageType('individuals'); }}>
                                        {t('go')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <SliderWrapper />
        </div>
    );
};
