import options from './options';

const { name } = options;

export const curMeteodata = state => state.models[name].list;
export const curAnalysisMeteodata = state => state.models[name].analysis;
export const curEffectiveMeteodata = state => state.models[name].effective;
export const curPrecipitationMeteodata = state => state.models[name].precipitation;

export const meteodataIsLoading = state => state.models[name].loading;
export const analysisIsLoading = state => state.models[name].analysisLoading;
export const effectiveIsLoading = state => state.models[name].effectiveLoading;
export const precipitationIsLoading = state => state.models[name].precipitationLoading;
