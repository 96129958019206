import * as constants from '../constants';

const initialState = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {},
    size: 10,
    sort: {},
    totalElements: 0,
    totalPages: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getRequests.success: {
            return action.payload;
        }

        case constants.removeRequest.success: {
            const { removedRequestId } = action.payload;

            return {
                ...state, content: state.content.filter(item => item.id !== removedRequestId)
            };
        }

        case constants.setStatusRequest.success: {
            const { status, id } = action.payload;
            const newContent = state.content;
            const index = newContent.findIndex(item => item.id === id);
            if (newContent[index]) {
                newContent[index].status = status;
            }
            return { ...state, content: newContent };
        }

        default:
            return state;
    }
}
