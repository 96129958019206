import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';
import { deleteAgrotechnicalEvent, getAgrotechnicalEventsList } from './api';
import { getAgrotechnicalEventsAccounting, getAgrotechnicalEventsField } from './actions';

function* updateAgroeventSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.updateAgrotechnicalEvents.try,
        constants.updateAgrotechnicalEvents.success,
        constants.updateAgrotechnicalEvents.error
    );
    try {
        yield put(start());
        const dataRaw = yield call(api.updateAgrotechnicalEvents, payload.body);

        if (payload.reqType === 'field') {
            yield put(actions.getAgrotechnicalEventsField(payload.fieldParams));
        }
        if (payload.reqType === 'accounting') {
            yield put(actions.getAgrotechnicalEventsAccounting(payload.accountingParams));
        }

        yield put(success(dataRaw[0]));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getAgrotechnicalEventsAccounting.toString(),
            defaultSaga({
                constants: constants.getAgrotechnicalEventsAccounting,
                api: api.getAgrotechnicalEventsAccounting
            })
        ),
        yield takeLatest(
            constants.getAgrotechnicalEventsField.toString(),
            defaultSaga({
                constants: constants.getAgrotechnicalEventsField,
                api: api.getAgrotechnicalEventsField
            })
        ),
        yield takeLatest(
            constants.getVegetationPhases.toString(),
            defaultSaga({
                constants: constants.getVegetationPhases,
                api: api.getVegetationPhases
            })
        ),
        yield takeLatest(
            constants.updateAgrotechnicalEvents.toString(),
            updateAgroeventSaga
        ),
        yield takeLatest(
            constants.getAgrotechnicalEventsList.toString(),
            defaultSaga({
                constants: constants.getAgrotechnicalEventsList,
                api: api.getAgrotechnicalEventsList
            })
        ),
        yield takeLatest(
            constants.deleteAgrotechnicalEvent.toString(),
            defaultSaga({
                constants: constants.deleteAgrotechnicalEvent,
                api: api.deleteAgrotechnicalEvent
            })
        ),
        yield takeLatest(
            constants.patchAgrotechnicalEvent.toString(),
            defaultSaga({
                constants: constants.patchAgrotechnicalEvent,
                api: api.patchAgrotechnicalEvent
            })
        )
    ]);
}