import { combineReducers } from 'redux';

import calculator from './calculator';
import calculators from './calculators';
import nozzleColor from './nozzle-color';
import nozzlePressure from './nozzle-pressure';
import rateNozzle from './rate-nozzle';
import protectionProduct from './plant-protection-product';

export default combineReducers({
    calculators,
    calculator,
    nozzleColor,
    nozzlePressure,
    rateNozzle,
    protectionProduct
});
