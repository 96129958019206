import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Icon } from 'antd';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import RequestData from './request-data';
import { selectors, actions } from '../../../models';
import * as constants from '../../../constants';

const {
    getRequest,
    clearRequest,
    getProfile
} = actions;
const {
    getCurrentRequest
} = selectors;

const { TabPane } = Tabs;
const { PanelHeader, PanelContent } = PanelLayout;

const mstp = (state) => ({
    request: getCurrentRequest(state)
});
const mdtp = ({
    getRequest,
    clearRequest
});


@withRouter
@withTranslation('requests')
@connect(mstp, mdtp)
@cn('request-detail')
export default class RequestDetail extends Component {
    state = {
        name: false,
        id: null,
        tab: '1'
    };

    componentDidMount() {
        const {
            getRequest, clearRequest, match
        } = this.props;
        const { id } = match.params;
        this.setState({ id });
        clearRequest();
        id && getRequest(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            request, history, match, clearRequest
        } = this.props;
        const { id } = match.params;

        if (!id && (prevProps.match.url !== match.url)) {
            clearRequest();
            this.setName();
        }

        if (!id && (prevProps.request !== request) && !!request.id) {
            history.push(`/request/${request.id}`);
        }
    }

    setName = (name) => {
        this.setState({ name });
    };

    render() {
        const { cn } = this;
        const { t, request, loading } = this.props;
        const { name, id, tab } = this.state;

        return (
            <PanelLayout loading={loading}>
                <PanelHeader>
                    <Title title={name || request.title || t('request name')} />
                </PanelHeader>
                <PanelContent>
                    <RequestData
                        id={id}
                        request={request}
                        setName={this.setName} />
                </PanelContent>
            </PanelLayout>
        );
    }
}