import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getCalculatorsMycoBody = state => state.models[name].calculators;
export const getCalculatorsMycoList = state => state.models[name].calculators.results;
export const getCalculatorsMycoListCount = state => state.models[name].calculators.count;
export const getCurrentCalculatorMyco = state => state.models[name].calculator;
export const isCalculatorMycoLoading = state => state.models[name].loading;

export const getCultureCatalogCalculatorsMyco = state => state.models[name].culture;
export const getPrecedingCatalogCalculatorsMyco = state => state.models[name].preceding;
export const getGenusOfMicroCatalogCalculatorsMyco = state => state.models[name].genusOfMicro;
export const getTypeOfResearchCalculatorsMyco = state => state.models[name].typeOfResearch;