import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';


import './style.less';
import CalculatorDetail from './components/calculator-detail';
import { actions } from '../../models';

const {
    getCalculatorMyco,
    clearCalculatorMyco
} = actions;

export default () => {
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('home');
    const match = useRouteMatch();
    const dispatch = useDispatch();
    useEffect(() => {
        const { id } = match.params;
        if (id) {
            dispatch(getCalculatorMyco(id));
        } else {
            dispatch(clearCalculatorMyco());
        }
    }, [match]);

    
    return (
        <Layout className={cn()}>
            <CalculatorDetail />
        </Layout>
    );
};
