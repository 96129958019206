import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private
        exact
        key="RecommendationRegistry"
        path="/recommendation-registry/:year/:id?"
        component={Page} />
];

export default Routes;