import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import Icon from '@ant-design/icons';
import {
    Row, Col, Slider, InputNumber, Input, Form, Select, Button
} from 'antd';

import cn from '../../../utils/cn';
import * as constants from '../../../constants';
import {
    Tabs, CatalogTitle
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import FertilizersTable from './fertilizers-table';

const {
    getFertilizers,
    getFertilizersGroup,
    getFertilizersSubGroup,
    getFertilizersType,
    getFertilizersReleaseForm
} = actions;
const {
    getFertilizersList,
    getFertilizersListCount,
    getFertilizerTypesList,
    getFertilizerGroupList,
    getFertilizerSubGroupList,
    getFertilizerReleaseFormList,
    getFertilizerMainDrugList
} = selectors;

const mstp = (state) => ({
    fertilizersList: getFertilizersList(state),
    fertilizersCount: getFertilizersListCount(state),
    types: getFertilizerTypesList(state),
    groups: getFertilizerGroupList(state),
    subgroups: getFertilizerSubGroupList(state),
    releaseForm: getFertilizerReleaseFormList(state),
    mainDrug: getFertilizerMainDrugList(state)
});
const mdtp = ({
    getFertilizers,
    getFertilizersGroup,
    getFertilizersSubGroup,
    getFertilizersType,
    getFertilizersReleaseForm
});

const { TabPane } = Tabs;
const { Option } = Select;

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;

@withRouter
@connect(mstp, mdtp)
@withTranslation('fertilizers')
@cn('fertilizers')
export default class FertilizersDetail extends Component {
    formRef = React.createRef();

    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        priceTo: undefined,
        priceFrom: undefined,
        page_size: 10,
        page: 1,
        showFilter: true,
        setFilter: false,
        showInfo: false,
        showFertilizer: undefined,
        name: undefined
    };

    componentDidMount() {
        const {
            getFertilizers,
            getFertilizersGroup,
            getFertilizersSubGroup,
            getFertilizersType,
            getFertilizersReleaseForm
        } = this.props;
        const { page_size } = this.state;
        this.getFilteredFertilizer();
        getFertilizersReleaseForm('?sort=["name","DESC"]');
        getFertilizersGroup('?sort=["name","DESC"]');
        getFertilizersType('');
    }

    getFilteredFertilizer = () => {
        const { getFertilizers } = this.props;
        const {
            page_size,
            page,
            fertilizer_type,
            group,
            subgroup,
            name,
            main_drug,
            releaseForm,
            containsElements,
            price_from,
            price_to
        } = this.state;
        const urlArr = [];
        urlArr.push('"is_in_state_registry":true');
        containsElements ? urlArr.push(`"content_${containsElements}.gt":0`) : '';
        price_from ? urlArr.push(`"related_sellers.price.gte":${price_from}`) : '';
        price_to ? urlArr.push(`"related_sellers.price.lte":${price_to}`) : '';
        name ? urlArr.push(`"name.icontains":"${name}"`) : '';
        main_drug ? urlArr.push(`"related_main_drugs.main_drug.name.icontains":"${main_drug}"`) : '';
        releaseForm ? urlArr.push(`"release_form":${releaseForm}`) : '';
        group ? urlArr.push(`"subgroup.group.id":${group}`) : '';
        subgroup ? urlArr.push(`"subgroup.id":${subgroup}`) : '';
        fertilizer_type ? urlArr.push(`"fertilizer_type.id":${fertilizer_type}`) : '';
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        getFertilizers(`?sort=["content_n","ASC"]&sort=["content_p","ASC"]&sort=["content_k","ASC"]&${page_size_url}&${page_url}&filter={${urlArr.join(',')}}`);
    };

    onChange = (value, values) => {
        const { getFertilizersSubGroup } = this.props;
        if (Object.keys(value)[0] === 'group') {
            this.setState({ subgroup: undefined });
            this.formRef.current.setFieldsValue({ subgroup: '' });
            getFertilizersSubGroup(`?sort=["name","DESC"]&filter={"group.id":${value.group}}`);
        }
        this.setState(value, () => {
            this.setState({ setFilter: true, page: 1 }, () => {
                this.getFilteredFertilizer();
            });
        });
    };

    onSort = (sortingFields, sortDirection) => {
        this.setState({ sortingFields, sortDirection }, () => {
            this.setState({ setFilter: true, page: 1 }, () => {
                this.getFilteredFertilizer();
            });
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    hideInfo = () => {
        this.setState({ showInfo: false });
    };

    onCurrentPageChange = (page) => {
        this.setState({
            page
        }, () => {
            this.getFilteredFertilizer();
        });
    };

    onPageSizeChange = (page_size) => {
        this.setState({
            page_size, page: 1
        }, () => this.getFilteredFertilizer());
    };

    get filterItems() {
        const { fertilizersCount: dataSize } = this.props;
        const items = [];
        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        return items;
    }

    goBack = () => {
        const { history } = this.props;
        history.push('/catalog-info');
    };


    showFilter = () => {
        this.setState({ showFilter: true });
    };

    showInfo = (fertilizer) => {
        this.setState({ showInfo: true, showFertilizer: fertilizer });
    };

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    handleClearFields = () => {
        this.formRef?.current?.resetFields();
        this.setState({
            fertilizer_type: null,
            group: null,
            subgroup: null,
            name: '',
            main_drug: null,
            releaseForm: null,
            containsElements: null,
            price_from: null,
            price_to: null
        }, () => {
            this.setState({ setFilter: false, page: 1 }, () => {
                this.getFilteredFertilizer();
            });
        });
    };

    render() {
        const { cn } = this;
        const {
            t,
            fertilizersList,
            fertilizersCount,
            types,
            groups,
            subgroups,
            releaseForm,
            mainDrug
        } = this.props;
        const {
            priceTo,
            priceFrom,
            group,
            showFilter,
            page_size,
            page
        } = this.state;

        const elementsAr = [{ title: t('all'), value: '' }];
        constants.containElementsTypes.forEach(item => (elementsAr.push({
            value: item, title: t(item)
        })));

        return (
            <div>
                <Form ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="fertilizer">
                    <Row gutter={20}>
                        <Col span={12}>
                            <CatalogTitle title={t('fertilizers')} />
                        </Col>
                        <Col span={12} className={cn('label')}>
                            <Form.Item name="name">
                                <Input
                                    placeholder={t('search')}
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '30px' }}>
                        <Col>
                            <span className={cn('back')} onClick={this.goBack}>
                                {t('back to catalog')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={6} className="field">
                            <Form.Item label={t('contains elements')}
                                name="containsElements">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {elementsAr.map(item => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {item.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={20}>
                                <Col span={6} className="field">
                                    <Form.Item name="price_from" label={t('price from')}>
                                        <InputNumber
                                            min={0}
                                            max={1000000}
                                            placeholder={t('price from placeholder')} />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item name="price_to" label={t('price to')}>
                                        <InputNumber
                                            min={0}
                                            max={1000000}
                                            placeholder={t('price to placeholder')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={6} className="field">
                            <Form.Item label={t('group fertilizer')}
                                name="group">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {groups.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('subgroup fertilizer')}
                                name="subgroup">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {subgroups.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('release form fertilizer')}
                                name="releaseForm">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {releaseForm.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('main drug fertilizer')}
                                name="main_drug">
                                <Input placeholder={t('search main drug')}
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginBottom: '15px' }}>
                    <Button onClick={this.handleClearFields} type="secondary">{t('reset fields')}</Button>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <FertilizersTable
                            onSort={this.onSort}
                            filterItems={this.filterItems}
                            dataSource={fertilizersList}
                            dataSize={fertilizersCount}
                            pageSize={page_size}
                            pageNum={page}
                            resetDataSource={showFilter}
                            showInfo={this.showInfo}
                            onPageSizeChange={this.onPageSizeChange}
                            onCurrentPageChange={this.onCurrentPageChange} />
                    </Col>
                </Row>
            </div>

        );
    }
}