import options from './options';

const { name } = options;

export const curRecommendationRegistries = state => state.models[name].registriesList;
export const createdRecommendationRegistry = state => state.models[name].createdRecommendation;

export const currentRecommendationCompositions = state => state.models[name].compositionList;
export const currentRecommendationCultures = state => state.models[name].cultureList;
export const currentRecommendationRegions = state => state.models[name].regionList;
export const currentRecommendationSubtypes = state => state.models[name].subtypeList;
export const calculatedRecommendationRegistry = state => state.models[name].calculatedRecommendation;
export const currentReportGeneral = state => state.models[name].reportGeneral;
export const currentReportNorm = state => state.models[name].reportNorm;
export const currentReportVolume = state => state.models[name].reportVolume;