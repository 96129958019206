import * as constants from '../constants';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getMycologicalRegistry.success:
        case constants.addMycologicalRegistry.success:
        case constants.updateMycologicalRegistry.success: {
            return action.payload;
        }

        case constants.clearMycologicalRegistry.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
