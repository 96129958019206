import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Modal,
    Table
} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import './style.less';

const { confirm } = Modal;

const stageConverter = {
    main: 'Основной',
    sowing: 'Припосевной',
    feeding: 'Подкормка'
};

const EventsTable = ({
    rawData, deleteEvent, editEvent, commentEvent, setCurrentComment
}) => {
    const { t } = useTranslation('accounting documents');

    const handleEdit = (record) => {
        editEvent(record);
    };

    const handleAddComment = (commentType, recordItem) => {
        commentEvent(commentType, recordItem);
    };

    const handleDelete = (record) => {
        confirm({
            title: t('modal confirm deletion field'),
            icon: <ExclamationCircleOutlined />,
            okText: t('button delete'),
            onOk() {
                deleteEvent(record);
            }
        });
    };

    const expandedRowRender = (events) => {
        const columns = [
            {
                title: t('table agroevent'),
                dataIndex: ['event_plan', 'event', 'name']
            },
            {
                title: t('table proved plan'),
                dataIndex: 'plan_date',
                width: 120
            },
            {
                title: t('table proved fact'),
                dataIndex: 'fact_date',
                width: 120
            },
            {
                title: t('field table stage'),
                dataIndex: 'fertilizers',
                render: (val) => {
                    if (val?.length > 0) {
                        return val.map(x => stageConverter[x.stage]).join(' / ');
                    }
                    return '';
                }
            },
            {
                title: t('field table fertilizer name'),
                render: (val) => {
                    const fertList = val.fertilizers?.length > 0 ? val.fertilizers : val.plant_protections;
                    if (fertList.length > 0) {
                        return fertList.map(x => x.fertilizer?.name).join(' / ');
                    }
                    return '';
                }
            },
            {
                title: t('field table application plan'),
                render: (val) => {
                    const fertList = val.fertilizers?.length > 0 ? val.fertilizers : val.plant_protections;
                    if (fertList.length > 0) {
                        return fertList.map(x => x.recommended_amount).join(' / ');
                    }
                    return '';
                }
            },
            {
                title: t('field table application fact'),
                render: (val) => {
                    const fertList = val.fertilizers?.length > 0 ? val.fertilizers : val.plant_protections;
                    if (fertList.length > 0) {
                        return fertList.map(x => x.fact_amount).join(' / ');
                    }
                    return '';
                }
            },
            {
                title: t('field table expert comment'),
                dataIndex: 'events',
                render: (value, record) => {
                    return record?.expert_comment ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center'
                            }}>
                            { record.expert_comment }
                            <EditOutlined
                                onClick={() => {
                                    if (record?.expert_comment?.length > 0) {
                                        setCurrentComment(record.expert_comment);
                                    }
                                    handleAddComment('expert', record);
                                }}
                                className="table__actions__icon" />
                        </div>
                    ) : (
                        <a onClick={() => handleAddComment('expert', record)}>
                            <PlusCircleOutlined /> {t('modal protections header add')}
                        </a>
                    );
                }
            },
            {
                title: t('field table user comment'),
                dataIndex: 'events',
                render: (value, record) => {
                    return record?.user_comment ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center'
                            }}>
                            { record.user_comment }
                            <EditOutlined
                                onClick={() => {
                                    if (record?.user_comment?.length > 0) {
                                        setCurrentComment(record.user_comment);
                                    }
                                    handleAddComment('user', record);
                                }}
                                className="table__actions__icon" />
                        </div>
                    ) : (
                        <a onClick={() => handleAddComment('user', record)}>
                            <PlusCircleOutlined /> {t('modal protections header add')}
                        </a>
                    );
                }
            },
            {
                title: t('field table actions'),
                dataIndex: 'events',
                render: (value, record) => {
                    return (
                        <div
                            className="table__actions">
                            <EditOutlined
                                onClick={() => handleEdit(record)}
                                className="table__actions__icon" />
                            <div className="table__actions__separator" />
                            <DeleteOutlined
                                onClick={() => handleDelete(record)}
                                className="table__actions__icon" />
                        </div>
                    );
                }
            }
        ];

        return (
            <Table
                rowKey="id"
                columns={columns}
                dataSource={events}
                pagination={false} />
        );
    };

    const toDdMmYyyy = (inpDate) => {
        const dateArray = inpDate?.split('-');
        if (
            dateArray && dateArray[0]?.length > 0 && dateArray[1]?.length > 0 && dateArray[2]?.length > 0
        ) {
            return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
        }
        return inpDate;
    };

    const columns = [
        {
            title: t('table vegetation phase'),
            dataIndex: 'vegetation_phase',
            fixed: 'left',
            width: '50%',
            render: (value) => (<b>{value}</b>)
        },
        {
            title: t('table phase period'),
            dataIndex: 'phase_period',
            fixed: 'right',
            width: '50%',
            render: (_, record) => `${toDdMmYyyy(record.start_date)} - ${toDdMmYyyy(record.end_date)}`
        }
    ];

    return (
        <div className="row-table">
            <Table
                rowKey="vegetation_phase"
                expandable={{
                    expandedRowRender: record => expandedRowRender(record.events),
                    defaultExpandedRowKeys: ['vegetation_phase']
                }}
                pagination={false}
                dataSource={rawData}
                columns={columns} />
        </div>
    );
};

export default EventsTable;