import * as constants from './constants';

export const getCardDefaults = payload => ({
    type: constants.getCardDefaults.toString(),
    payload
});
export const updateCardDefaults = payload => ({
    type: constants.updateCardDefaults.toString(),
    payload
});
export const getSamplesList = payload => ({
    type: constants.getSamplesList.toString(),
    payload
});

export const getDownloadableDataset = payload => ({
    type: constants.getDownloadableDataset.toString(),
    payload
});

export const createSampleRow = payload => ({
    type: constants.createSampleRow.toString(),
    payload
});

export const createRowCopy = payload => ({
    type: constants.createRowCopy.toString(),
    payload
});

export const deleteSampleRow = payload => ({
    type: constants.deleteSampleRow.toString(),
    payload
});

export const getSampleById = payload => ({
    type: constants.getSampleById.toString(),
    payload
});

export const editSampleRow = payload => ({
    type: constants.editSampleRow.toString(),
    payload
});

export const uploadSampleFile = payload => ({
    type: constants.uploadSampleFile.toString(),
    payload
});

export const manageSampleRowPublication = payload => ({
    type: constants.manageSampleRowPublication.toString(),
    payload
});

export const addOwnerSampleLog = payload => ({
    type: constants.addOwnerSampleLog.toString(),
    payload
});

export const getUsersList = payload => ({
    type: constants.getUsersList.toString(),
    payload
});