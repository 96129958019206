import * as constants from './constants';
import request from '../../utils/request';
//
// export const generateProtocolFertilizers = payload => ({
//     type: constants.generateProtocolFertilizers.toString(),
//     payload
// });

export const updateFertilizerInfo = payload => ({
    type: constants.updateFertilizerInfo.toString(),
    payload
});
export const createNewFertilizer = payload => ({
    type: constants.createNewFertilizer.toString(),
    payload
});
export const deleteProtocol = payload => ({
    type: constants.deleteProtocol.toString(),
    payload
});
export const createInitialProtocol = payload => ({
    type: constants.createInitialProtocol.toString(),
    payload
});
export const getFertilizer = payload => ({
    type: constants.getFertilizer.toString(),
    payload
});
export const getProtocol = payload => ({
    type: constants.getProtocol.toString(),
    payload
});
export const getProtocolList = payload => ({
    type: constants.getProtocolList.toString(),
    payload
});
export const updateProtocol = payload => ({
    type: constants.updateProtocol.toString(),
    payload
});
export const updateProtocolFertilizers = payload => ({
    type: constants.updateProtocolFertilizers.toString(),
    payload
});
export const createInternalCalculator = payload => ({
    type: constants.createInternalCalculator.toString(),
    payload
});
export const updateInternalCalculator = payload => ({
    type: constants.updateInternalCalculator.toString(),
    payload
});
export const getFertilizers = payload => ({
    type: constants.getFertilizers.toString(),
    payload
});
export const publishProtocol = payload => ({
    type: constants.publishProtocol.toString(),
    payload
});
export const updateFertilizerPrice = payload => ({
    type: constants.updateFertilizerPrice.toString(),
    payload
});