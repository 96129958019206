import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getCarouselData = filter => request.get({
    url: urlPath(options.apiDiseasesUrl, `&culture=${filter.culture}&symptom_category=${filter.symptom_category}`)
});

export const getCarouselCultures = () => request.get({
    url: options.apiCulturesUrl
});

export const getCarouselSymptoms = filter => request.get({
    url: urlPath(options.apiSymptomsUrl, `&culture=${filter.culture}`)
});


export const getCarouselDataMock = () => Promise.resolve(
    {
        results: [
            {
                id: 1,
                image: 'images/Rectangle_104.png',
                title: 'Простая гниль (Подземная – клубни)'
            },
            {
                id: 2,
                image: 'images/Rectangle_104.png',
                title: 'Кольцевая гниль (Подземная – клубни)'
            },
            {
                id: 3,
                image: 'images/Rectangle_104.png',
                title: 'Так себе гниль (Подземная – клубни)'
            },
            {
                id: 4,
                image: 'images/Rectangle_104.png',
                title: 'какая то там гниль (Подземная – клубни)'
            },
            {
                id: 5,
                image: 'images/Rectangle_104.png',
                title: 'вот гниль (Подземная – клубни)'
            },
            {
                id: 6,
                image: 'images/Rectangle_104.png',
                title: 'пока гниль (Подземная – клубни)'
            }
        ]
    }
);

export const getCarouselCulturesMock = () => Promise.resolve(
    {
        results: [
            {
                id: 1,
                title: 'Рожь'
            },
            {
                id: 2,
                title: 'Пшеница'
            },
            {
                id: 3,
                title: 'Ячмень'
            },
            {
                id: 4,
                title: 'Картофель'
            },
            {
                id: 5,
                title: 'Морковь'
            }
        ]
    }
);

export const getCarouselSymptomsMock = () => Promise.resolve(
    {
        results: [
            {
                id: 1,
                title: 'Пятна'
            },
            {
                id: 2,
                title: 'Желтеют листья'
            }
        ]
    }
);