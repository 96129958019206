import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Spin } from 'antd';

import cn from '../../utils/cn';

import { PanelLayout, SideBar, TableFooter } from '../../components';

import './style.less';
import ProfileDetail from './components/profile-detail';
import { actions, selectors } from '../../models';

const { Content } = Layout;

const {
    getProfileInfo
} = actions;
const {
    getUser
} = selectors;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({
    getProfileInfo
});

@withRouter
@connect(mstp, mdtp)
@cn('page')
export default class Profile extends Component {

    componentDidMount() {
        const { getProfileInfo } = this.props;
    }

    render() {
        const { cn } = this;
        return (
            <Layout className={cn()}>
                <ProfileDetail />
            </Layout>
        );
    }
}
