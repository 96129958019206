import React, { Fragment } from 'react';
import { message } from 'antd';

import i18n from '../../i18n';
import { constants } from '../../models';

const {
    updateProfile,
    removeDeterminant,
    addCalculatorFed,
    setParametersCalculatorFed,
    removeCalculatorFed,
    addCalculatorFluid,
    removeCalculatorFluid,
    updateRequest,
    uploadTaskFile,
    removeTask,
    userActivation,
    resetPassword,
    resetPasswordConfirm,
} = constants;

const baseName = 'fastNotifications:';

const successEvents = {
    [updateProfile.success]: `${baseName}updateProfileSuccess`,
    [removeDeterminant.success]: `${baseName}removeDeterminantSuccess`,
    [addCalculatorFed.success]: `${baseName}addCalculatorFedSuccess`,
    [setParametersCalculatorFed.success]: `${baseName}setParametersCalculatorFedSuccess`,
    [removeCalculatorFed.success]: `${baseName}removeCalculatorFedSuccess`,
    [addCalculatorFluid.success]: `${baseName}addCalculatorFluidSuccess`,
    [removeCalculatorFluid.success]: `${baseName}removeCalculatorFluidSuccess`,
    [updateRequest.success]: `${baseName}updateRequestSuccess`,
    [uploadTaskFile.success]: `${baseName}uploadTaskFileSuccess`,
    [removeTask.success]: `${baseName}removeTaskSuccess`,
    [userActivation.success]: `${baseName}userActivationSuccess`,
    [resetPassword.success]: `${baseName}resetPasswordSuccess`,
    [resetPasswordConfirm.success]: `${baseName}resetPasswordConfirmSuccess`,
};

const errorEvents = {
    [updateProfile.error]: `${baseName}updateProfileError`,
    [removeDeterminant.error]: `${baseName}removeDeterminantError`,
    [setParametersCalculatorFed.error]: `${baseName}setParametersCalculatorFedError`,
    [removeCalculatorFed.error]: `${baseName}removeCalculatorFedError`,
    [removeCalculatorFluid.error]: `${baseName}removeCalculatorFluidError`,
    [updateRequest.error]: `${baseName}updateRequestError`,
    [uploadTaskFile.error]: `${baseName}uploadTaskFileError`,
    [removeTask.error]: `${baseName}removeTaskError`,
    [userActivation.error]: `${baseName}userActivationError`,
    [resetPassword.error]: `${baseName}resetPasswordError`,
    [resetPasswordConfirm.error]: `${baseName}resetPasswordConfirmError`,
};

export default store => next => (action) => {
    if (successEvents[action.type] || errorEvents[action.type]) {
        const isSuccess = !!successEvents[action.type];
        let path = isSuccess ? successEvents[action.type] : errorEvents[action.type];
        if (action.subtype) path += `_${action.subtype}`;
        let label = i18n.t(path);
        if (Array.isArray(label)) {
            label = label.map(m => (<Fragment key={m}>{m}<br /></Fragment>));
        }
        if (isSuccess) {
            message.success(label);
        } else {
            message.error(label, 4);
        }
    }
    next(action);
};
