import * as constants from '../constants';
import converter from '../converter';

const initialState = converter._server2Client({});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorMordovia.success: {
            return action.payload;
        }

        case constants.getCalculatorsMordoviaForecast.success: {
            return { ...state, forecast: { ...action.payload } };
        }

        case constants.updateCalculatorsMordoviaForecast.success: {
            return { ...state, forecast: { ...action.payload }, forecast_error: false };
        }

        case constants.updateCalculatorsMordoviaForecast.try: {
            const forecast = { ...state.forecast };
            const culture = { ...forecast.culture };
            culture.id = action.payload?.body?.culture;
            forecast.culture = { ...culture };
            forecast.sowing_date = action.payload?.body?.sowing_date;
            return { ...state, forecast };
        }

        case constants.updateCalculatorsMordoviaForecast.error: {
            return { ...state, forecast_error: true };
        }

        case constants.clearCalculatorMordovia.toString(): {
            return initialState;
        }

        case constants.removeGroupCalculate.try: {
            return { ...state, removedId: action.payload };
        }

        case constants.removeGroupCalculate.success: {
            const { removedId } = state;
            const group = state?.calculator_group;
            return {
                ...state,
                removedId: undefined,
                calculator_group: {
                    ...group,
                    calculators: group?.calculators.filter(item => item.id !== removedId)
                }
            };
        }

        case constants.removeGroupCalculate.error: {
            return {
                ...state,
                removedId: undefined
            };
        }

        case constants.addGroupCalculate.success: {
            const group = state?.calculator_group;
            if (group?.calculators?.find(item => action.payload?.calculator.id === item.id)) {
                return state;
            }
            return {
                ...state,
                calculator_group: {
                    ...group,
                    calculators: [...group?.calculators, action.payload?.calculator]
                }
            };
        }

        case constants.updateDistributionGroupCalculate.success: {
            const group = state?.calculator_group;
            const calculators = group?.calculators;
            const filteredGroup = calculators?.filter(item =>
                item.distribution.id !== action.payload?.id);
            const distributionCalc = calculators?.find(item =>
                item.distribution.id === action.payload?.id);
            distributionCalc.distribution = { ...action.payload };
            return {
                ...state,
                calculator_group: {
                    ...group,
                    calculators: [...filteredGroup, distributionCalc]
                }
            };
        }

        default:
            return state;
    }
}
