import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    InputNumber, Modal, Space, Table
} from 'antd';
import { useRouteMatch } from 'react-router-dom';

import { useClassName } from 'utils/cn';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { actions, selectors } from '../../../../models';
import { Button } from '../../../../components';
import { feedingDistributionResults } from '../../../../models/item-distribution/selectors';

const {
    calculatorsGroupInfo,
    isDistributionLoading
} = selectors;

const {
    updateFeedingDistribution,
    getCalculatorMordovia,
    getFieldPoints
} = actions;

const { Column } = Table;

export default (props) => {
    const {
        isModalVisible,
        closeModal
    } = props;

    const match = useRouteMatch();
    const cn = useClassName('calculator');
    const dispatch = useDispatch();
    const { t } = useTranslation('calculators');

    const isFeedingLoading = useSelector(state => isDistributionLoading(state));
    const calcGroupInfo = useSelector(state => calculatorsGroupInfo(state));
    const feedingsDistribution = useSelector(state => feedingDistributionResults(state));

    const [previousLoading, setPreviousLoading] = useState(false);
    const [potentialFeedings, setPotentialFeedings] = useState([]);
    const [feedingModalPercentageError, setFeedingModalPercentageError] = useState(false);
    const [feedingModalAmountError, setFeedingModalAmountError] = useState(false);

    useEffect(() => {
        if (feedingsDistribution?.length > 0) {
            setPotentialFeedings(prepareFeedingStages(feedingsDistribution));
        }
    }, [feedingsDistribution]);

    useEffect(() => {
        if (calcGroupInfo.feeding_stages.length > 0 && potentialFeedings?.length === 0) {
            setPotentialFeedings(prepareFeedingStages(calcGroupInfo.feeding_stages));
        }
    }, [calcGroupInfo]);

    const prepareFeedingStages = (stagesArray) => {
        const structuredFeedings = [];

        stagesArray?.forEach((percent, indx) => {
            structuredFeedings.push({
                id: indx + 1,
                name: `${t('feeding item')} ${indx + 1}`,
                percent: percent
            });
        });

        return structuredFeedings;
    };

    const checkFeedingPercentage = () => {
        let percSum = 0;

        potentialFeedings.forEach((feeding) => {
            percSum += feeding.percent;
        });

        if (percSum === 100) {
            setFeedingModalPercentageError(false);
            return true;
        } else {
            setFeedingModalPercentageError(true);
            return false;
        }
    };

    useEffect(() => {
        if (previousLoading === true && isFeedingLoading === false) {
            closeModal();
        }

        setPreviousLoading(isFeedingLoading);
    }, [isFeedingLoading]);

    useEffect(() => {
        if (potentialFeedings.length > 0) {
            checkFeedingPercentage();
        }
    }, [potentialFeedings]);

    const onFeedingPercentChange = (feedEntry, newPercentage) => {
        const existingFeedingCopy = [...potentialFeedings];
        existingFeedingCopy.forEach((existingEntry) => {
            if (existingEntry.name === feedEntry.name && existingEntry.id === feedEntry.id) {
                existingEntry.percent = newPercentage;
            }
        });
        setPotentialFeedings([...existingFeedingCopy]);
    };

    const addFeedingStage = () => {
        if (potentialFeedings.length <= 4) {
            const newFeedingsArray = [...potentialFeedings, {
                id: potentialFeedings.length + 1,
                name: `${t('feeding item')} ${potentialFeedings.length + 1}`,
                percent: 0
            }];

            if (feedingModalAmountError && newFeedingsArray.length >= 2) {
                setFeedingModalAmountError(false);
            }

            setPotentialFeedings(newFeedingsArray);
        }
    };

    const closeFeedingStagesModal = () => {
        setFeedingModalPercentageError(false);
        setFeedingModalAmountError(false);
        closeModal();
    };

    const sendFeedingPercentage = () => {
        if (checkFeedingPercentage()) {
            setFeedingModalPercentageError(false);
        } else {
            setFeedingModalPercentageError(true);
        }
        if (potentialFeedings.length < 2) {
            setFeedingModalAmountError(true);
        } else {
            setFeedingModalAmountError(false);
        }
        if (potentialFeedings.length >= 2 && checkFeedingPercentage()) {
            const { id, year } = match.params;

            dispatch(updateFeedingDistribution({
                id: calcGroupInfo.id,
                year: year,
                values: {
                    feeding_stages: [...potentialFeedings].map(x => x.percent)
                }
            }));

            if (id && year) {
                dispatch(getCalculatorMordovia({ id, year }));
            }
        }
    };

    const removeFeedingStage = (feedingEntry) => {
        const existingStages = [...potentialFeedings];
        const filteredStages = existingStages.filter(existingEntry =>
            existingEntry.name !== feedingEntry.name);
        filteredStages.forEach((stageEntry, indx) => {
            stageEntry.name = `${t('feeding item')} ${indx + 1}`;
        });
        setPotentialFeedings(filteredStages);
    };

    return (
        <Modal
            title={t('calculate fertilizers')}
            width={1000}
            closable
            footer={[
                <Button
                    onClick={sendFeedingPercentage}
                    type="primary">
                    {t('perform calculation')}
                </Button>
            ]}
            destroyOnClose
            visible={isModalVisible}
            className={cn('modal')}
            onCancel={closeFeedingStagesModal}>
            <Table
                className="feedings-table"
                dataSource={potentialFeedings}
                size="small"
                pagination={false}
                bordered
                rowKey="id">
                <Column
                    align="center"
                    width="30%"
                    title={t('feeding stages')}
                    dataIndex="name"
                    key="name" />
                <Column
                    align="center"
                    width="70%"
                    title={t('feeding percent proportion')}
                    dataIndex="percent"
                    key="percent"
                    render={(percentNumber, feedingEntry) => (
                        <Space size="medium" style={{ width: '100%', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <Space>
                                <InputNumber
                                    min={0}
                                    max={100}
                                    step={1}
                                    defaultValue={feedingEntry.percent}
                                    decimalSeparator=","
                                    onChange={(inpNum) =>
                                        onFeedingPercentChange(feedingEntry, inpNum)} />
                                <span>%</span>
                            </Space>
                            <DeleteOutlined
                                onClick={() => removeFeedingStage(feedingEntry)} />
                        </Space>
                    )} />
            </Table>
            <div className="add-button-container">
                <Button
                    className="add-feeding-button"
                    onClick={addFeedingStage}
                    disabled={potentialFeedings.length === 5}
                    type="secondary">
                    <PlusCircleOutlined />{t('add feeding stage')}
                </Button>
            </div>
            {feedingModalPercentageError && (
                <div className={cn('warning-lite')}>
                    <div className={cn('warning-lite__inner')}>{t('feeding modal percentage error')}</div>
                </div>
            )}
            {feedingModalAmountError && (
                <div className={cn('warning')}>
                    <div className={cn('warning__inner')}>{t('feeding modal amount error')}</div>
                </div>
            )}
        </Modal>
    );
};