export default {
    name: 'fertilizers',
    serverName: '/api/v1/fertilizer/fertilizer_trade_name/',
    apiUrl: '/api/v1/fertilizer/fertilizer_trade_name/',
    apiGroupUrl: '/api/v1/fertilizer/fertilizer_group/',
    apiMainDrugUrl: '/api/v1/fertilizer/fertilizer_main_drug/',
    apiMainDrugSynonymsUrl: '/api/v1/fertilizer/fertilizer_main_drug_synonyms/',
    apiReleaseFormUrl: '/api/v1/fertilizer/fertilizer_release_form/',
    apiSubGroupUrl: '/api/v1/fertilizer/fertilizer_subgroup/',
    apiToMainDrugUrl: '/api/v1/fertilizer/fertilizer_to_main_drug/',
    apiToRegistrationUrl: '/api/v1/fertilizer/fertilizer_to_registration/',
    apiToSellerUrl: '/api/v1/fertilizer/fertilizer_to_seller/',
    apiTypeUrl: '/api/v1/fertilizer/fertilizer_type/'
};
