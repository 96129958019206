import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Spin } from 'antd';

import cn from '../../utils/cn';

import { PanelLayout, SideBar, TableFooter } from '../../components';

import './style.less';
import CalculatorDetail from './components/calculator-detail';
import { actions, selectors } from '../../models';

const { Content } = Layout;

const {
    getCalculatorFed,
    clearCalculatorFed
} = actions;
const {
    getCurrentCalculatorFed,
    isCalculatorFedLoading
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFed(state),
    loading: isCalculatorFedLoading(state)
});
const mdtp = ({
    getCalculatorFed,
    clearCalculatorFed
});

@withRouter
@connect(mstp, mdtp)
@cn('all-calculators')
export default class Calculator extends Component {
    state = {
        id: null,
        name: false
    };

    componentDidMount() {
        const { getCalculatorFed, match } = this.props;
        const { id } = match.params;

        if (id) {
            this.setState({ id });
            getCalculatorFed(id);
        }
    }

    render() {
        const { cn } = this;
        const { loading } = this.props;
        const { name } = this.state;
        return (
            <Layout className={cn()}>
                <CalculatorDetail
                    loading={loading}
                    name={name} />
            </Layout>
        );
    }
}
