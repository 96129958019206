import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
    Col, Row, Form, Input, Select, DatePicker, Button
} from 'antd';
import { actions, selectors } from '../../../models';
import { curMycoFields } from '../../../models/mycological-registry/selectors';
import { getFilteredFields } from '../../../models/mycological-registry/actions';
import { DATEPICKER_FORMAT } from '../../../constants';

const { getCultures } = actions;
const { getCulturesCatalog } = selectors;

export const Data = ({
    registry, t, form, usersList, setUserSearchString,
    fieldList, setFieldList, isBlocked
}) => {
    const cultures = useSelector(state => getCulturesCatalog(state));
    const fields = useSelector(state => curMycoFields(state));
    const dispatch = useDispatch();

    // const [fieldList, setFieldList] = useState([]);
    const [fieldsPage, setFieldsPage] = useState(1);

    useEffect(() => {
        const { owner } = form?.getFieldsValue();
        owner && dispatch(getFilteredFields({
            userId: owner,
            pageNum: fieldsPage
        }));
    }, [fieldsPage]);

    useEffect(() => {
        const fieldsListArr = [];
        const fldKeysArr = Object.keys(fields);

        fldKeysArr.forEach(key => {
            if (key !== 'next') {
                fieldsListArr.push(fields[key]);
            }
        });

        const fieldsCpy = [...fieldList];
        fieldsListArr.forEach(item => {
            const foundField = fieldsCpy?.filter(x => x?.value === item?.field_num)[0];
            if (!foundField?.label?.length > 0) {
                fieldsCpy.push({
                    label: `${item?.vega_key} - ${item?.field_num}`,
                    value: item?.field_num || item?.vega_key
                });
            }
        });
        setFieldList([...fieldsCpy]);
        fields?.next?.length > 0 && setFieldsPage(fieldsPage + 1);
    }, [fields]);

    useEffect(() => {
        dispatch(getCultures('?page_size=10000'));
    }, []);

    const onUserSelect = (userId) => {
        dispatch(getFilteredFields({
            userId: userId
        }));
        setFieldsPage(1);
    };

    const onOwnerSearch = (value) => {
        setUserSearchString(value);
    };

    const clearFormFields = () => {
        form.setFieldsValue({
            num: undefined,
            owner: undefined,
            sample_date: undefined,
            test_date: undefined,
            field_num: undefined,
            year: undefined,
            culture: undefined
        });
    }

    return (
        <Fragment>
            <Row gutter={20} style={{ marginBottom: '20px' }}>
                <Col span={7} className="field">
                    <Form.Item name="num" label={t('number')} rules={[{ required: true, message: t('not empty') }]}>
                        <Input disabled={isBlocked} placeholder={t('number placeholder')} />
                    </Form.Item>
                </Col>
                <Col span={8} className="field">
                    <Form.Item
                        name="owner"
                        label={t('owner')}
                        rules={[{ required: true, message: t('not empty') }]}>
                        <Select
                            disabled={isBlocked}
                            showSearch
                            filterOption={(inputValue, option) =>
                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                            onChange={onUserSelect}
                            // onSearch={onOwnerSearch}
                            options={usersList?.length > 0 ? usersList?.map(x => {
                                return {
                                    label: x.name,
                                    value: x.id
                                };
                            }).sort((a, b) => {
                                return a.label?.localeCompare(b.label);
                            }) : []} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20} style={{ marginBottom: '20px' }}>
                <Col span={5} className="field">
                    <Form.Item name="sample_date" label={t('date of receipt')} rules={[{ required: true, message: t('not empty') }]}>
                        <DatePicker
                            disabledDate={ d => d > new Date()}
                            disabled={isBlocked}
                            format={DATEPICKER_FORMAT}
                            onChange={(val1, val2) => {
                                form.setFieldsValue({
                                    sample_date: val2 ? moment(val2, 'DD.MM.YYYY') : val2
                                });
                            }}
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={5} className="field">
                    <Form.Item name="test_date" label={t('date of test')} rules={[{ required: true, message: t('not empty') }]}>
                        <DatePicker
                            disabledDate={ d => d > new Date()}
                            disabled={isBlocked}
                            format={DATEPICKER_FORMAT}
                            onChange={(val1, val2) => {
                                form.setFieldsValue({
                                    test_date: val2 ? moment(val2, 'DD.MM.YYYY') : val2
                                });
                            }}
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={5} className="field">
                    <Form.Item name="field_num" label={t('field num')} rules={[{ required: true, message: t('not empty') }]}>
                        <Select
                            disabled={isBlocked}
                            showSearch
                            // onSearch={onFieldSearch}
                            filterOption={(inputValue, option) =>
                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                            options={fieldList.filter(x => x?.label?.length > 0)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20} style={{ marginBottom: '20px' }}>
                <Col span={5} className="field">
                    <Form.Item name="year" label={t('year')}>
                        <DatePicker disabledDate={ d => d > new Date()} disabled={isBlocked} picker="year" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={5} className="field">
                    <Form.Item name="culture" label={t('culture')} rules={[{ required: true, message: t('not empty') }]}>
                        <Select
                            disabled={isBlocked}
                            options={cultures?.length > 0 ? cultures?.map((item) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                };
                            }).sort((a, b) => {
                                return a.label?.localeCompare(b.label);
                            }) : []}
                            filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                            showSearch
                            allowClear />
                    </Form.Item>
                </Col>
                <Col span={5} className="field">
                    <Form.Item name="sample" label={t('example')}>
                        <Select
                            options={[
                                {
                                    label: t('soil'),
                                    value: 'Soil'
                                }
                            ]}
                            defaultValue="Soil"
                            value={{
                                label: t('soil'),
                                value: 'Soil'
                            }}
                            disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Button type="secondary" disabled={isBlocked} onClick={clearFormFields}>
                        {t('clear')}
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};