import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getCalculatorsFed = filter => request.get({
    url: urlPath(filter)
});

export const getCalculatorFed = id => request.get({
    url: urlPath(`${id}/`),
    id
});

export const addCalculatorFed = body => request.post({
    url: urlPath('create_calculator/'),
    body
});

export const setParametersCalculatorFed = body => request.post({
    url: urlPath(`${body.id}/set_parameters/`),
    body: body.values
});

export const removeCalculatorFed = id => request.remove({
    url: urlPath(`${id}/`)
});

export const closeCalculatorFed = body => request.post({
    url: urlPath(`${body.id}/close/`)
});