import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import {
    Table, TableFooter, Tooltip
} from '../../../components';

import cn from '../../../utils/cn';
import { selectors, actions } from '../../../models';

import { EXTENTIONS } from '../../../constants';

const ROW_DIVIDER = 10;

const {
    removeRequestFile
} = actions;
const {
    getCurrentRequest,
    isRequestLoading
} = selectors;

const mstp = (state) => ({
    request: getCurrentRequest(state),
    loading: isRequestLoading(state)
});
const mdtp = ({
    removeRequestFile
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('requests')
@cn('requests-table')
export default class FilesTable extends Component {
    get requestsColumns() {
        const { cn } = this;
        const { t, history, onDelete } = this.props;

        return [
            {
                title: t('file name'),
                dataIndex: 'name',
                key: 'name',
                className: cn('name'),
                render: (text, record) => (
                    <div className="link"
                        onClick={() => this.showFile(record.id, text, record.file)}>
                        {text}
                    </div>
                )
            },
            {
                title: t('upload date'),
                dataIndex: 'uploadDate',
                key: 'uploadDate',
                className: cn('uploadDate'),
                render: (text) => moment(text).format('DD.MM.YYYY')
            },
            {
                title: t('description'),
                dataIndex: 'description',
                key: 'description',
                className: cn('description')
            }
        ];
    }

    onDelete = (id) => {
        const { removeRequestFile } = this.props;
        removeRequestFile(id);
    };

    get getDataSource() {
        const { fileList } = this.props;
        const files = fileList.map(file => ({
            id: file.id,
            name: file.fileName,
            description: file.description,
            file: file || undefined
        }));

        const data = [];

        return files;
    }

    showFile = (id, image, file) => {
        const { history, onClick } = this.props;
        const str = image.split('.');
        const ext = str[str.length - 1];
        if (EXTENTIONS.includes(ext.toLowerCase())) {
            onClick(id, file);
        } else {
            if (id) location.href = `/v1/task-file/downloadFile/${id}`;
        }
    }

    render() {
        const { cn } = this;

        return (
            <div className={cn()}>
                <Table
                    dataSource={this.getDataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
            </div>
        );
    }
}