import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Icon from '@ant-design/icons';

import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import { Collapse } from 'antd';

import { Title, Tabs, PanelLayout } from '../../../components';

import CalculatorData from './calculator-data';
import CalculatorParamsMyco from './calculator-params';
import { selectors, actions } from '../../../models';
import * as constants from '../../../constants';

import CalculatorIcon from '../../../../public/images/calculator/calculator2_stroke.svg';
import CalendarIcon from '../../../../public/images/calculator/calendar2_stroke.svg';
import ApplicationIcon from '../../../../public/images/calculator/application_stroke.svg';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';


const {
    getCalculatorMyco,
    clearCalculatorMyco
} = actions;
const {
    getCurrentCalculatorMyco
} = selectors;

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;


export default () => {
    const cn = useClassName('calculator-detail');
    const { t } = useTranslation('calculator detail');
    const match = useRouteMatch();
    const { id } = match.params;
    const dispatch = useDispatch();
    const calculator = useSelector(state => getCurrentCalculatorMyco(state));
    const [curId, setId] = useState(id);
    const [name, setName] = useState(calculator.name);
    const [activeKeys, setActiveKeys] = useState([]);

    useEffect(() => {
        setName(calculator.name);
    }, [calculator]);

    const setTitleName = (name) => {
        setName(name);
    };

    const changeActiveKeys = (key) => {
        setActiveKeys(key);
    };

    const addActiveKeys = (key) => {
        const arr = activeKeys;
        if (!activeKeys.includes(key)) {
            arr.push(key);
            setActiveKeys([...arr]);
        }
    };

    return (
        <PanelLayout>
            <PanelHeader>
                <Title title={name || t('calculator myco name')} />
            </PanelHeader>
            <PanelContent>
                <Collapse activeKey={activeKeys}
                    onChange={changeActiveKeys}
                    expandIconPosition="right"
                    className={cn('collapse')}>
                    <Panel header={<b><Icon component={ListIcon} />{t('calculator data')}</b>}
                        key="1">
                        <CalculatorData setName={setTitleName}
                            addActiveKeys={addActiveKeys} />
                    </Panel>
                    <Panel header={<b><Icon component={CalculatorIcon} />{t('calculator parameters')}</b>}
                        disabled={!id}
                        key="2">
                        <CalculatorParamsMyco
                            id={id}
                            addActiveKeys={addActiveKeys}
                            isCompleted={false}
                            calculator={calculator} />

                    </Panel>
                </Collapse>
            </PanelContent>
        </PanelLayout>
    );
};