import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsPrecedingCategory.toString(),
            defaultSaga({
                constants: constants.getCalculatorsPrecedingCategory,
                api: api.getCalculatorsPrecedingCategory
            })
        ),
        yield takeLatest(
            constants.getCalculatorsPrecedingCategoryItem.toString(),
            defaultSaga({
                constants: constants.getCalculatorsPrecedingCategoryItem,
                api: api.getCalculatorsPrecedingCategoryItem
            })
        )
    ]);
}