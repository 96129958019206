import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import {defaultSaga, getActions} from '../utils';

import * as constants from './constants';
import * as api from './api';

function* getNDVIandNDMISaga({ payload: id }) {
    const [start, success, failure] = getActions(
        constants.getNDVIandNDMI.try,
        constants.getNDVIandNDMI.success,
        constants.getNDVIandNDMI.error
    );
    try {
        yield put(start(id));
        const dataNDVI = yield call(api.getNDVI, id);
        const dataNDMI = yield call(api.getNDMI, id);
        yield put(success({ dataNDMI, dataNDVI }));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getNDVI.toString(),
            defaultSaga({
                constants: constants.getNDVI,
                api: api.getNDVI
            })
        ),
        yield takeLatest(
            constants.getNDMI.toString(),
            defaultSaga({
                constants: constants.getNDMI,
                api: api.getNDMI
            })
        ),
        yield takeLatest(
            constants.getSWI.toString(),
            defaultSaga({
                constants: constants.getSWI,
                api: api.getSWI
            })
        ),
        yield takeLatest(
            constants.getNDVIandNDMI.toString(),
            getNDVIandNDMISaga
        )
    ]);
}