import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getCalculatorsFluidBody = state => state.models[name].calculators;
export const getCalculatorsFluidList = state => state.models[name].calculators.results;
export const getCalculatorsFluidListCount = state => state.models[name].calculators.count;
export const getCurrentCalculatorFluid = state => state.models[name].calculator;
export const getCurrentProtectionProductCalculatorFluid = state => state.models[name].protectionProduct;
export const isCalculatorFluidLoading = state => state.models[name].loading;

export const getCurrentCalculatorFluidRateNozzle = state => state.models[name].rateNozzle;
export const getCurrentCalculatorFluidNozzleColor = state => state.models[name].nozzleColor;
export const getCurrentCalculatorFluidNozzlePressure = state => state.models[name].nozzlePressure;