import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Button, DatePicker, Form, Input,
    Modal, Select,
    Table
} from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { getCalculatorMordovia } from '../../../../models/mordovia/actions';
import {
    getCalculatorsMordoviaCatalog,
    getCurrentCalculatorMordovia
} from '../../../../models/mordovia/selectors';

import './style.less';
import { updateAgrotechnicalEvents } from '../../../../models/agrotechnical-events/actions';
import { getUser } from '../../../../models/account/selectors';
import { DATEPICKER_FORMAT } from '../../../../constants';

const EventsTable = ({
    isOpened, setIsOpened
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('accounting documents');
    const [form] = Form.useForm();

    const currentUser = useSelector(state => getUser(state));
    const fields = useSelector(state => getCalculatorsMordoviaCatalog(state));
    const information = useSelector(state => getCurrentCalculatorMordovia(state));

    const [fieldNames, setFieldNames] = useState([]);
    const [fieldCultures, setFieldCultures] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [selectedCulture, setSelectedCulture] = useState(undefined);
    const [year, setYear] = useState(0);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        if (fields && fields.length > 0) {
            const fieldNames = createFieldsList(fields);
            setFieldNames(fieldNames);
            const fieldCultures = createCultureList(fields);
            setFieldCultures(fieldCultures);
        }
    }, [fields]);

    const createFieldsList = (fieldsArray) => {
        const fieldsList = [];
        fieldsArray.forEach(fieldEntity => {
            fieldsList.push({
                label: fieldEntity.vega_key,
                value: fieldEntity.vega_key
            });
        });
        return fieldsList;
    };

    const createCultureList = (fieldsArray) => {
        const culturesList = [];
        fieldsArray.forEach(fieldEntity => {
            const finderArray = culturesList.filter(x => x.value === fieldEntity?.culture?.id);
            if (finderArray.length === 0) {
                culturesList.push({
                    label: fieldEntity?.culture?.name,
                    value: fieldEntity?.culture?.id
                });
            }
        });
        return culturesList;
    };

    useEffect(() => {
        form.setFieldsValue({
            vega_keys: selectedFields
        });

        if (selectedFields?.length > 0) {
            dispatch(getCalculatorMordovia({
                id: selectedFields[0],
                year: year
            }));

            if (!selectedCulture) {
                const fieldEntry = [...fields].filter(fieldEntry => selectedFields.includes(fieldEntry.vega_key));
                setSelectedCulture(fieldEntry[0]?.culture?.id);
                setFieldNames(createFieldsList([...fields].filter(x => x?.culture?.id === fieldEntry[0]?.culture?.id)));
                const newCultures = createCultureList(fieldEntry);
                setFieldCultures(newCultures);
            }
        } else if (selectedFields.length < 1 && !selectedCulture) {
            setFieldCultures(createCultureList([...fields]));
            setFieldNames(createFieldsList([...fields]));
        }
    }, [selectedFields]);

    const handleCancel = () => {
        setYear(0);
        setFieldNames([]);
        setFieldCultures([]);
        setSelectedFields([]);
        setSelectedCulture(undefined);
        setErrorText('');
        form.resetFields();
        setIsOpened(false);
    };

    const checkFields = () => {
        const formValues = form.getFieldsValue();
        form.submit();

        let checkValue = true;

        Object.keys(formValues).forEach(formKey => {
            if (formValues[formKey]?.length < 1) {
                setErrorText(t('sowing modal error text'));
                checkValue = false;
            }
        });

        return checkValue;
    };

    const onSubmit = () => {
        if (checkFields()) {
            const fieldValues = form.getFieldsValue();

            dispatch(updateAgrotechnicalEvents({
                reqType: 'accounting',
                userId: currentUser?.id || null,
                body: {
                    vega_keys: fieldValues.vega_keys,
                    fact_date: fieldValues.date,
                    performer: fieldValues.performer,
                    year: information.year,
                    event: 'Посев'
                }
            }));

            handleCancel();
        }
    };

    const onFieldSelect = (selectedFields) => {
        setSelectedFields(selectedFields);
    };

    const onCultureSelect = (newVal) => {
        form.setFieldsValue({ culture: newVal });
    };

    const onDateSelect = (val1, dateString) => {
        form.setFieldsValue({ date: dateString });
    };

    return (
        <Modal
            className="modal"
            visible={isOpened}
            width={482}
            onCancel={handleCancel}
            footer={[
                <div
                    key="footer">
                    <Button
                        key="cancel"
                        onClick={handleCancel}>
                        {t('modal button cancel')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSubmit}
                        htmlType="submit">
                        {t('modal button ok')}
                    </Button>
                </div>
            ]}
            title={t('modal add sowing title')}>
            <Form
                form={form}
                name="add_event_form"
                labelCol={{ flex: '50%' }}
                labelAlign="left">
                <Form.Item
                    label={t('modal add form name')}
                    rules={[{ required: true }]}
                    name="vega_keys">
                    <div>
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder={t('modal add form name placeholder')}
                            onChange={onFieldSelect}
                            options={fieldNames} />
                    </div>
                </Form.Item>
                <Form.Item
                    label={t('modal add form culture')}
                    rules={[{ required: true }]}
                    name="culture">
                    <div>
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder={t('modal add form culture placeholder')}
                            onChange={onCultureSelect}
                            options={fieldCultures} />
                    </div>
                </Form.Item>
                <Form.Item
                    label={t('modal add sowing form date')}
                    rules={[{ required: true }]}
                    name="date">
                    <div>
                        <DatePicker
                            format={DATEPICKER_FORMAT}
                            onChange={onDateSelect}
                            style={{ width: '100%' }}
                            placeholder={t('modal add sowing form date placeholder')} />
                    </div>
                </Form.Item>
                <Form.Item
                    label={t('modal add form performer')}
                    rules={[{ required: true }]}
                    name="performer">
                    <div>
                        <Input
                            placeholder={t('modal add form performer placeholder')}
                            allowClear />
                    </div>
                </Form.Item>
                {errorText?.length > 0 && (
                    <div className="error-message">
                        <div>
                            <CloseCircleFilled />
                        </div>
                        <div className="error-message__text">
                            {errorText}
                        </div>
                    </div>
                )}
            </Form>

        </Modal>
    );
};

export default EventsTable;