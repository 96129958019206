import { combineReducers } from 'redux';

import harmfulObject from './harmful-object';
import harmfulObjects from './harmful-objects';
import harmfulObjectDivisions from './divisions';

export default combineReducers({
    harmfulObject,
    harmfulObjects,
    harmfulObjectDivisions
});
