import * as constants from './constants';

export const getCalculatorsNormRegionCulture = payload => ({
    type: constants.getCalculatorsNormRegionCulture.toString(),
    payload
});

export const getCalculatorNormRegionCultureItem = payload => ({
    type: constants.getCalculatorNormRegionCultureItem.toString(),
    payload
});

export const getCalculatorsRBRegionCulture = payload => ({
    type: constants.getCalculatorsRBRegionCulture.toString(),
    payload
});

export const getCalculatorRBRegionCultureItem = payload => ({
    type: constants.getCalculatorRBRegionCultureItem.toString(),
    payload
});