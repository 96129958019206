import * as constants from '../constants';
import { addCalculate, clearCalculate, getCalculate } from '../../item-calculate/constants';
import {
    addGroupCalculate,
    clearCalculatorMordovia,
    getCalculatorMordovia,
    setActiveCalculate
} from '../../mordovia/constants';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getNutrition.success:
        case constants.addNutrition.success:
        case constants.updateNutrition.success: {
            return action.payload;
        }

        case getCalculate.success:
        case addCalculate.success: {
            const { nutrition } = action.payload;
            return nutrition;
        }

        case clearCalculatorMordovia.toString():
        case clearCalculate.toString():
        case constants.clearNutrition.toString(): {
            return initialState;
        }

        case getCalculatorMordovia.success: {
            const calculator = { ...action.payload?.calculator?.nutrition };
            return calculator;
        }

        case addGroupCalculate.success: {
            const calculator = { ...action.payload?.calculator?.nutrition };
            return calculator;
        }

        case setActiveCalculate.toString(): {
            const calculator = { ...action.payload?.nutrition };
            return calculator;
        }

        default:
            return state;
    }
}
