import { combineReducers } from 'redux';

import token from './token';
import isAuth from './is-auth';
import isLoading from './is-loading';
import isError from './is-error';
import isRegister from './is-register';
import isActivate from './is-activate';
import user from './user';

export default combineReducers({
    token,
    isAuth,
    isLoading,
    isError,
    isRegister,
    isActivate,
    user
});
