import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getPesticidesBody = state => state.models[name].pesticides;
export const getPesticidesList = state => state.models[name].pesticides.results;
export const getPesticidesListCount = state => state.models[name].pesticides.count;
export const getCurrentPesticide = state => state.models[name].pesticide;

export const getPesticideApplicationList = state => state.models[name].productApplication.data.results;
export const getPesticideApplicationCount = state => state.models[name].productApplication.data.count;
export const curPesticideProductApplication = state => state.models[name].productApplication.item;

export const getPesticideApplicationAreaList = state => state.models[name].applicationArea.data;
export const curPesticideApplicationArea = state => state.models[name].applicationArea.item;
export const getPesticideChemicalClassesList = state => state.models[name].chemicalClasses.data;
export const curPesticideChemicalClasses = state => state.models[name].chemicalClasses.item;
export const getPesticideHarmfulObjectList = state => state.models[name].harmfulObject.data;
export const curPesticideHarmfulObject = state => state.models[name].harmfulObject.item;
export const getPesticideHazardClassList = state => state.models[name].hazardClass.data;
export const curPesticideHazard = state => state.models[name].hazardClass.item;
export const getPesticidePlaceOfProductionList = state => state.models[name].placeOfProduction.data;
export const curPesticidePlaceOfProduction = state => state.models[name].placeOfProduction.item;
export const getPesticideGroupList = state => state.models[name].group.data;
export const curPesticideGroup = state => state.models[name].group.item;
export const getPesticideMainDrugList = state => state.models[name].mainDrug.data;
export const curPesticideMainDrug = state => state.models[name].mainDrug.item;
export const getPesticidePreparativeFormList = state => state.models[name].preparativeForm.data;
export const curPesticidePreparativeForm = state => state.models[name].preparativeForm.item;
export const getPesticideTreatmentTypeList = state => state.models[name].treatmentType.data;
export const curPesticideTreatmentType = state => state.models[name].treatmentType.item;
