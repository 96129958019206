import * as constants from './constants';

// export const getMycologicalRegistry = payload => ({
//     type: constants.getMycologicalRegistry.toString(),
//     payload
// });

export const calculateRecommendation = payload => ({
    type: constants.calculateRecommendation.toString(),
    payload
});

export const getRecommendationRegistries = payload => ({
    type: constants.getRecommendationRegistries.toString(),
    payload
});

export const createRecommendationRegistry = payload => ({
    type: constants.createRecommendationRegistry.toString(),
    payload
});

export const getCompositions = payload => ({
    type: constants.getCompositions.toString(),
    payload
});

export const getCultures = payload => ({
    type: constants.getCultures.toString(),
    payload
});

export const getRegions = payload => ({
    type: constants.getRegions.toString(),
    payload
});

export const getSubtypes = payload => ({
    type: constants.getSubtypes.toString(),
    payload
});

export const makeParameterSpread = payload => ({
    type: constants.makeParameterSpread.toString(),
    payload
});

export const makeReportGeneral = payload => ({
    type: constants.makeReportGeneral.toString(),
    payload
});

export const makeReportVolume = payload => ({
    type: constants.makeReportVolume.toString(),
    payload
});

export const makeReportNorm = payload => ({
    type: constants.makeReportNorm.toString(),
    payload
});