import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { Radio } from 'antd';
import { Table, TableFooter, Tooltip } from '../../../../components';
import cn from '../../../../utils/cn';
import ListLine from '../../../../../public/images/calculator/list_line.svg';

const ROW_DIVIDER = 10;

@withRouter
@withTranslation('pesticides')
@cn('pesticides-table')
export default class PesticidesTable extends Component {
    state = {
        value: undefined
    };

    onChange = e => {
        const { dataSource, dataSize } = this.props;
        this.setState({
            value: e.target.value
        }, () => {
            const { onSetPesticide } = this.props;
            const curPesticide = [dataSource.find(element => Number(element.id) === Number(e.target.value))];
            onSetPesticide(curPesticide[0]);
        });
    };

    get requestsColumns() {
        const { cn } = this;
        const {
            t, history,
            isCompleted,
            showInfo
        } = this.props;

        return [
            {
                title: '',
                dataIndex: 'radio',
                key: 'radio',
                className: cn('radio'),
                render: (text, record) => (
                    <div className={cn('radio')}>
                        <Radio value={record.id} disabled={isCompleted} />
                    </div>
                )
            },
            {
                title: t('pesticide title'),
                dataIndex: 'plant_protection_product',
                key: 'plant_protection_product',
                className: cn('name'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text && text.name}>
                        {text && text.name}
                    </Tooltip>
                )

            },
            {
                title: t('norm'),
                dataIndex: 'rate_of_use_min',
                key: 'rate_of_use_min',
                className: cn('norm'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}>
                        { (record.rate_of_use_min === record.rate_of_use_max) && (
                            <>{record.rate_of_use_min}</>)}
                        { (record.rate_of_use_min !== record.rate_of_use_max)
                        && (<>{record.rate_of_use_min}-{record.rate_of_use_max}</>)}
                    </Tooltip>
                )
            },
            {
                title: t('unit'),
                dataIndex: 'unit_of_measurement',
                key: 'unit_of_measurement',
                className: cn('unit'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text && text.name}>
                        {text && text.name}
                    </Tooltip>
                )
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: cn('action'),
                width: 100,
                render: (text, record) => (
                    <div className={cn('button')}>
                        <Icon component={ListLine} onClick={() => showInfo(record)} />
                    </div>
                )
            }
        ];
    }

    render() {
        const { cn } = this;
        const {
            history,
            onCurrentPageChange,
            onPageSizeChange,
            filterItems,
            pageSize,
            pageNum,
            pesticide,
            dataSize,
            dataSource
        } = this.props;
        const {
            curPesticide,
            value
        } = this.state;

        if (!dataSource) return <div />;
        return (
            <div>
                <Radio.Group
                    className={cn('radio-wrap')}
                    onChange={this.onChange}
                    value={pesticide && pesticide[0].id}>
                    <Table
                        scroll={{ x: 1080 }}
                        dataSource={pesticide || dataSource}
                        columns={this.requestsColumns}
                        pagination={false} />
                    {dataSize > 1 && !pesticide && (
                        <TableFooter
                            filterItems={filterItems}
                            showSizeChanger={false}
                            onSelect={onPageSizeChange}
                            onChange={onCurrentPageChange}
                            pageSize={pageSize}
                            current={pageNum}
                            rowDivider={ROW_DIVIDER.toString()}
                            dataSize={dataSize} />
                    )}
                </Radio.Group>
            </div>
        );
    }
}