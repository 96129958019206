import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getAgrotechnicalEventsAccounting.success: {
            return true;
        }

        case constants.getAgrotechnicalEventsAccounting.error: {
            return false;
        }

        default:
            return state;
    }
}
