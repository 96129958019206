import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    DatePicker, Space, Table, Typography, Spin
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    curMeteodata,
    meteodataIsLoading
} from '../../../../models/item-meteodata/selectors';
import { dayNames, monthNames } from './constants';
import { getMeteodata } from '../../../../models/item-meteodata/actions';
import { toFixed } from '../../../../utils/utils';

const { RangePicker } = DatePicker;

const TableRepresentation = (props) => {
    const {
        isGeneralDateSet,
        generalDates,
        setGeneralDates
    } = props;
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const { Text } = Typography;
    const { t } = useTranslation('meteodata');
    const data = useSelector(state => curMeteodata(state));
    const [tableDate, setTableDate] = useState({
        start_date: '',
        end_date: ''
    });

    const { year } = match.params;

    const isLoadingAll = useSelector(state => meteodataIsLoading(state));

    useEffect(() => {
        setTableDate(generalDates);
    }, [generalDates]);

    const getDates = (inputDates) => {
        const startDate = new Date(inputDates.start_date);
        const endDate = new Date(inputDates.end_date);
        return {
            start_date: startDate.toISOString().split('T')[0],
            end_date: endDate.toISOString().split('T')[0]
        };
    };

    useEffect(() => {
        if (tableDate.start_date.length > 0 && tableDate.end_date.length > 0) {
            const { id } = match.params;
            if (isGeneralDateSet) {
                const requestBody = getDates(tableDate);
                dispatch(getMeteodata({
                    vega_key: id,
                    body: { ...requestBody, year }
                }));
            } else {
                setGeneralDates({
                    start_date: tableDate.start_date,
                    end_date: tableDate.end_date
                });
            }
        }
    }, [tableDate]);

    const getDayName = (dateString) => {
        const dateObject = new Date(dateString);
        const currentDateSplit = dateString.split('-');
        if (dateObject.getDay() === 0) {
            return dateString.length && dateString.length > 0
                ? `${t(dayNames[6])}, ${currentDateSplit[2]} ${t(monthNames[currentDateSplit[1]])}` : '';
        }
        return dateString.length && dateString.length > 0
            ? `${t(dayNames[dateObject.getDay() - 1])}, ${currentDateSplit[2]} ${t(monthNames[currentDateSplit[1] - 1])}` : '';
    };

    const columns = [
        {
            title: t('date'),
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 115,
            render: value => value.split('-').reverse().join('-')
        },
        // {
        //     title: t('avg_humidity'),
        //     dataIndex: 'avg_humidity',
        //     key: 'avg_humidity',
        //     align: 'center',
        //     render: value => (value ? toFixed(value, 2) : 0)
        // },
        // {
        //     title: t('wind_speed'),
        //     dataIndex: 'wind_speed',
        //     key: 'wind_speed',
        //     align: 'center',
        //     render: value => (value ? toFixed(value, 2) : 0)
        // },
        {
            title: t('avg_temperature_forecast'),
            dataIndex: 'avg_temperature_forecast',
            key: 'avg_temperature_forecast',
            align: 'center',
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('avg_temperature_fact'),
            dataIndex: 'avg_temperature_fact',
            key: 'avg_temperature_fact',
            width: 100,
            align: 'center',
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('precipitation'),
            dataIndex: 'precipitation',
            key: 'precipitation',
            align: 'center',
            width: 50,
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('avg_humidity'),
            dataIndex: 'avg_humidity',
            key: 'avg_humidity',
            align: 'center',
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('wind_speed'),
            dataIndex: 'wind_speed',
            key: 'wind_speed',
            align: 'center',
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('set_fact'),
            dataIndex: 'set_fact',
            key: 'set_fact',
            width: 125,
            align: 'center',
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('set_forecast'),
            dataIndex: 'set_forecast',
            key: 'set_forecast',
            width: 125,
            align: 'center',
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('sum_of_precipitation'),
            dataIndex: 'sum_of_precipitation',
            key: 'sum_of_precipitation',
            align: 'center',
            render: value => (value ? toFixed(value, 2) : 0)
        },
        {
            title: t('vegetation_phase'),
            dataIndex: ['vegetation_phase', 'name'],
            align: 'center',
            key: 'vegetation_phase'
        }
    ];

    const handleDateChange = (moments, dateStrings) => {
        if (generalDates.start_date.length > 0 && generalDates.end_date.length > 0) {
            setTableDate({
                start_date: dateStrings[0],
                end_date: dateStrings[1]
            });
        } else {
            setGeneralDates({
                start_date: dateStrings[0],
                end_date: dateStrings[1]
            });
        }
    };

    return (
        <Space direction="vertical">
            <Space align="center">
                {!props?.isLaborant && (
                    <RangePicker
                        onChange={handleDateChange} />
                )}
                <Text style={{ fontWeight: '700' }}>
                    {tableDate.start_date.length > 0 && tableDate.end_date.length > 0 && `${getDayName(tableDate.start_date)} - ${getDayName(tableDate.end_date)}`}
                </Text>
            </Space>
            <Spin spinning={isLoadingAll}>
                <Table
                    rowKey="id"
                    dataSource={data}
                    columns={columns}
                    pagination={false} />
            </Spin>
        </Space>
    );
};

export default TableRepresentation;