import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import {
    Button, Row, Col, Collapse
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BreadCrumbs from './breadcrumbs';


const { Panel } = Collapse;

export default (props) => {
    const cn = useClassName('home-page');
    const { t } = useTranslation('home');
    const { setPageType } = props;
    const [infoShow, setInfoShow] = useState([]);

    const handleInfoBlock = (type) => {
        const types = [...infoShow];
        if (types.includes(type)) {
            setInfoShow(types.filter(item => item !== type));
        } else {
            types.push(type);
            setInfoShow(types);
        }
    };

    const downloadPDF = () => {

    };

    return (
        <div className={cn()}>
            <Row className={cn('block-auto')}>
                <BreadCrumbs type="lab-research" setPageType={setPageType} />
            </Row>
            <Row className={cn('block-auto')} style={{ marginTop: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}><h2>{t('teleagronom for lab research')}</h2></Col>
            </Row>
            <Row className={cn('block-auto')}>
                <Col span={1} />
                <Col span={22}>
                    <Collapse ghost
                        className={`${cn('collapse')} ${cn('collapse-individuals')}`}
                        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} />}
                        expandIconPosition="right">
                        <Panel header={<PanelHeader cn={cn} t={t} img="01" step="1" />} key="1">
                            <Row gutter={20} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <Col span={12}>
                                    <p className={cn('text-sm')}>{t('rs 1 indicators')}</p>
                                    <ol className={cn('list-nums')}>
                                        {
                                            [1, 2, 3, 4].map(item => (
                                                <li key={t(`rs 1 indicators ${item}`)}>{t(`rs 1 indicators ${item}`)}</li>
                                            ))
                                        }
                                    </ol>
                                </Col>
                                <Col span={12} className={cn('module-step-img')}>
                                    <p className={cn('text-sm')}>{t('rs 1 contains')}</p>
                                    <Row>
                                        <Col span={12}>
                                            <ul className={cn('line-list')}>
                                                {
                                                    [1, 2, 3, 4, 5, 6].map(item => (
                                                        <li key={t(`rs 1 contains ${item}`)}>{t(`rs 1 contains ${item}`)}</li>
                                                    ))
                                                }
                                            </ul>
                                        </Col>
                                        <Col span={12}>
                                            <ul className={cn('line-list')}>
                                                {
                                                    [7, 8, 9, 10, 11].map(item => (
                                                        <li key={t(`rs 1 contains ${item}`)}>{t(`rs 1 contains ${item}`)}</li>
                                                    ))
                                                }
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="02" step="2" />} key="2">
                            <Row style={{ paddingTop: '40px' }}>
                                <Col span={12} className={cn('v-align')}>
                                    <img src="/images/home/research/research_1.png" alt="" />
                                </Col>
                                <Col span={12} className={cn('v-align')}>
                                    <ul className={cn('line-list')}>
                                        <li>{t('rs 2 1')}</li>
                                        <li>{t('rs 2 2')}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="03" step="3" />} key="3">
                            <p className={cn('text-sm')}>{t('rs 3 determinate')}</p>
                            <Row>
                                <Col span={12}>
                                    <ul className={cn('line-list')}>
                                        {
                                            [1, 2, 3, 4, 5].map(item => (
                                                <li key={t(`rs 3 determinate ${item}`)}>{t(`rs 3 determinate ${item}`)}</li>
                                            ))
                                        }
                                    </ul>
                                </Col>
                                <Col span={12}>
                                    <ul className={cn('line-list')}>
                                        {
                                            [6, 7, 8, 9, 10].map(item => (
                                                <li key={t(`rs 3 determinate ${item}`)}>{t(`rs 3 determinate ${item}`)}</li>
                                            ))
                                        }
                                    </ul>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="04" step="4" />} key="4">
                            <Row style={{ paddingTop: '40px' }}>
                                <Col span={12}>
                                    <p className={`${cn('text-sm')} ${cn('height110')}`}>{t('rs 4 indicators')}</p>
                                    <ol className={cn('list-nums')}>
                                        {
                                            [1, 2, 3].map(item => (
                                                <li key={t(`rs 4 indicators ${item}`)}>{t(`rs 4 indicators ${item}`)}</li>
                                            ))
                                        }
                                    </ol>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={12}>
                                            <p className={`${cn('text-sm')} ${cn('height110')}`}>{t('rs 4 determinate cat')}</p>
                                            <ul className={cn('line-list')}>
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                                                        <li key={t(`rs 4 determinate cat ${item}`)}>{t(`rs 4 determinate cat ${item}`)}</li>
                                                    ))
                                                }
                                            </ul>
                                        </Col>
                                        <Col span={12}>
                                            <p className={`${cn('text-sm')} ${cn('height110')}`}>{t('rs 4 determinate an')}</p>
                                            <ul className={cn('line-list')}>
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7].map(item => (
                                                        <li key={t(`rs 4 determinate an ${item}`)}>{t(`rs 4 determinate an ${item}`)}</li>
                                                    ))
                                                }
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="05" step="5" />} key="5">
                            <Row style={{ paddingTop: '40px' }}>
                                <Col span={12} className={cn('v-align')}>
                                    <img src="/images/home/research/research_2.png" alt="" />
                                </Col>
                                <Col span={12} className={cn('v-align')}>
                                    <ul className={cn('line-list')}>
                                        {
                                            [1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                                                <li key={t(`rs 5 ${item}`)}>{t(`rs 5 ${item}`)}</li>
                                            ))
                                        }
                                    </ul>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="06" step="6" />} key="6">
                            <Row style={{ paddingTop: '40px' }}>
                                <Col span={12} className={cn('v-align')}>
                                    <ul className={cn('line-list')}>
                                        {
                                            [1, 2, 3, 4, 5].map(item => (
                                                <li key={t(`rs 6 ${item}`)}>{t(`rs 6 ${item}`)}</li>
                                            ))
                                        }
                                    </ul>
                                </Col>
                                <Col span={12} className={cn('v-align')} style={{ justifyContent: 'flex-end' }}>
                                    <img src="/images/home/research/research_3.png" alt="" />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col span={1} />
            </Row>
            <Row className={cn('block')} style={{ padding: '60px 0' }}>
                <Col span={24}>
                    <p className={cn('text-center')}>{t('research text')}</p>
                </Col>
                <Col span={24} className={cn('btn-block')}>
                    <Button type="primary"
                        onClick={() => window.open('https://t.me/TELEAGRONOM', '_blank')}
                        size="large">
                        {t('research button')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const PanelHeader = ({
    step, img, cn, t
}) => (
    <div className={cn('tabs__step')}>
        <img src={`/images/nums/${img}.svg`} className={cn('module-block__img-nums')} />
        <div>{t(`research step ${step}`)}</div>
    </div>
);
