import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Col, Form, InputNumber, Row, Select, Collapse, Modal, Image, Upload, Table, Popconfirm, Input, Popover, Tabs
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { jsPDF } from 'jspdf';

import { useClassName } from '../../../utils/cn';

import '../style.less';

import InfoModal from './info-modal';
import {
    addCausativeAgent,
    addExternalContamination,
    getCausativeAgents,
    updateRegistryPhyto,
    uploadRegistryFile,
    updateExternalContamination,
    updatePhotoDescription,
    newKindPhyto,
    newAgentPhyto,
    newDiseasePhyto,
    newConnectionPhyto,
    getVegPhasesPhyto,
    getSubgroupsPhyto,
    getSymptomsPhyto,
    getFertilizersPhyto,
    getProtocolPhyto,
    getDrugsPhyto,
    getCalculatorPhyto,
    removeExternalContamination
} from '../../../models/calculators-phyto/actions';
import {
    currentCausativeAgentsPhyto,
    currentContaminationCreatedPhyto,
    currentProtocolPhyto,
    sympPhyto, subPhyto, vegPhyto,
    uploadedFilePhyto,
    currentFertilizersPhyto,
    drugsPhyto,
    curCalculatorPhyto
} from '../../../models/calculators-phyto/selectors';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as constants from '../../../constants';
import { useForm } from 'antd/lib/form/Form';
import { getCulturesCatalog } from '../../../models/cultures/selectors';
import { getCultures } from '../../../models/cultures/actions';

const { Panel } = Collapse;
const { confirm } = Modal;

const ExternalContamination = ({
    activeTabs, setActiveTabs,
    form, images, calculatorId,
    pathogens, fertilizersFullList,
    culturesList
}) => {
    const history = useHistory();
    const [agentsForm] = useForm();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const { t: pdfT } = useTranslation('phyto calculator');

    const uploadedRegistryFile = useSelector(state => uploadedFilePhyto(state));
    const agents = useSelector(state => currentCausativeAgentsPhyto(state));

    const symptoms = useSelector(state => sympPhyto(state));
    const subgroups = useSelector(state => subPhyto(state));
    const vegPhases = useSelector(state => vegPhyto(state));
    const cultures = useSelector(state => getCulturesCatalog(state));
    const currentCalculator = useSelector(state => curCalculatorPhyto(state));

    useEffect(() => {
        pathogens?.length > 0 && setActiveDrugTab(pathogens[0].id);
    }, [pathogens]);
    
    useEffect(() => {
        dispatch(getVegPhasesPhyto({}));
        dispatch(getSubgroupsPhyto());
        dispatch(getSymptomsPhyto());
        dispatch(getCultures('?page_size=1000'));
    }, []);
    
    const [agentModalOpen, setAgentModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [agentPage, setAgentPage] = useState(1);
    const [agentsList, setAgentsList] = useState([]);
    const [currentDisease, setCurrentDisease] = useState({});
    const [contaminationStage, setContaminationStage] = useState(1);
    const [showDrugModal, setShowDrugModal] = useState(false);
    const [drugDisease, setDrugDisease] = useState({});
    const [activeDrugTab, setActiveDrugTab] = useState(null);
    const [creationType, setCreationType] = useState('');
    const [selectedDrugList, setSelectedDrugList] = useState([]);
    
    useEffect(() => {
        const { image_type, uploaded_file } = uploadedRegistryFile;
        if (uploaded_file?.id && image_type === 'external_contamination') {
            let newImg = [];
            if (images?.length > 0) {
                newImg = images?.map(x => x?.id);
            }
            
            setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));

            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    external_pathogen_images: [...newImg, uploaded_file?.id]
                }
            }));

            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }, [uploadedRegistryFile]);

    const handleUpdatePhotoDescription = (currentPhoto, newDescription) => {
        dispatch(updatePhotoDescription({
            imageId: currentPhoto?.id,
            values: {
                picture_desc: newDescription
            }
        }));
    };

    const editableDescriptionContent = (photoInstance, oldVals) => {
        const [photoDescription, setPhotoDescription] = useState('');

        useEffect(() => {
            setPhotoDescription(oldVals)
        }, [oldVals])

        return (
            <div style={{ width: '300px', height: '200px' }}>
                <Row style={{ height: '150px' }}>
                    <Input.TextArea
                        style={{ maxHeight: '150px' }}
                        value={photoDescription}
                        defaultValue={oldVals}
                        onChange={evt => setPhotoDescription(evt?.target?.value)} />
                </Row>
                <Row align="middle" gutter={12} style={{ height: '50px' }}>
                    <Col span={24}>
                        <Button onClick={() => handleUpdatePhotoDescription(photoInstance, photoDescription)} type="primary" style={{ width: '100%' }}>
                            {t('save photo changes')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    const imagesColumns = [
        {
            key: "picture_file",
            dataIndex: "picture_file",
            render: value => value ? (
                <Image width={100} src={value} />
            ) : "-"
        },
        {
            key: "picture_name",
            dataIndex: "picture_name"
        },
        {
            key: "picture_desc",
            dataIndex: "picture_desc",
            render: (value, record) => (
                <Popover content={() => editableDescriptionContent(record, value)} trigger="click">
                    {value}
                </Popover>
            )
        },
        {
            key: "uploaded",
            dataIndex: "uploaded",
            render: value => value ? moment(value).format('DD.MM.YYYY') : "-"
        },
        {
            key: "actions",
            dataIndex: '',
            render: (value, record) => (
                <DeleteOutlined
                    onClick={() => {
                        const newImgs = [...images].map(x => x.id).filter(y => y !== record?.id);
                        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
                        dispatch(updateRegistryPhyto({
                            calcId: match?.params?.id,
                            values: {
                                external_pathogen_images: newImgs
                            }
                        }));
                    }} />
            )
        }
    ];

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (match?.params?.id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    protocolId: match?.params?.id,
                    imageType: 'external_contamination',
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `External pathogen ${moment().format('YYYY-MM-DDThh:mm')}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    useEffect(() => {
        dispatch(getCausativeAgents({ page: agentPage }));
    }, [agentPage]);

    useEffect(() => {
        const { next, results } = agents;
        if (results?.length > 0) {
            const toInsert = [];
            results?.forEach(resVal => {
                const findingSame = agentsList?.filter(agnt => agnt?.id === resVal?.id);
                if (!findingSame[0]) {
                    toInsert.push(resVal);
                }
            })
            setAgentsList([
                ...agentsList,
                ...toInsert?.map(x => {
                    return {
                        label: x?.name,
                        value: x?.id
                    };
                })?.filter(insertableItem => {
                    const searchResult = agentsList?.filter(agentItem => agentItem?.value === insertableItem?.value);
                    return searchResult?.length < 1;
                })]);
        }
        if (next?.length > 0) {
            setAgentPage(agentPage + 1);
        }
    }, [agents]);

    const addSelectedAgents = () => {
        const formVals = form.getFieldsValue();
        dispatch(addExternalContamination({
            calculatorId: calculatorId,
            values: {
                causative_agents: selectedItems?.length > 0 ? selectedItems : formVals?.external_pathogen_ids
            }
        }));

        const recalculationTimeout = setTimeout(() => {
            dispatch(getCalculatorPhyto(match?.params?.id));
            clearTimeout(recalculationTimeout);
        }, 1000);
    };

    const downloadDiseasePDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        doc.setFontSize(14);

        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageWidthTable = pageSize.width ? pageSize.width - 80 : pageSize.getWidth() - 80;
        const text = doc.splitTextToSize(currentDisease?.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        doc.setFontSize(12);
        doc.setFontSize(12);

        const rowOne = pdfT('modal row 1');
        const rowTwo = pdfT('modal row 2');
        const rowThree = pdfT('modal row 3');
        const rowFour = pdfT('modal row 4');
        const rowFive = pdfT('modal row 5');
        const rowSix = pdfT('modal row 6');
        
        const body = [
            [rowOne, currentDisease?.causative_agent?.name],
            [rowTwo, currentDisease?.causative_agent?.causes_of_occurrence],
            [rowThree, currentDisease?.pathogen_morphology],
            [rowFour, currentDisease?.biology_spread_pathogen],
            [rowFive, currentDisease?.ecology_of_disease],
            [rowSix, currentDisease?.disease_harmfulness],
        ];

        doc.autoTable({
            startY: finalY,
            styles: {
                fontSize: 11,
                cellWidth: 'wrap'
            },
            columnStyles: {
                0: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 }
            },
            body
        });
        const fileName = `${currentDisease?.name} ${pdfT('summary')}`;
        doc.save(`${fileName}.pdf`);
    };

    const showDiseaseModal = (disease) => {
        setCurrentDisease(disease);
        setIsModalOpen(true);
    };

    const handleCalcFinish = () => {
        const formVals = form?.getFieldsValue();
        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
        dispatch(updateRegistryPhyto({
            calcId: match?.params?.id,
            values: {
                external_pathogen_comment: formVals?.external_pathogen_comment
            }
        }));
        const formKeys = Object.keys(formVals)?.filter(x => x?.startsWith('external/percentage/'));
        formKeys?.forEach(pathIdString => {
            setTimeout(() => {
                dispatch(updateExternalContamination({
                    pathogenId: pathIdString?.split('/')[2],
                    values: { pathogens_percentage: formVals[pathIdString] }
                }));
            }, 200);
        })
    };

    const confirmFinishCalculation = () => {
        confirm({
            title: t('finish calculation confirm title'),
            icon: <ExclamationCircleOutlined />,
            content: t('finish calculation confirm text'),
            okText: t('yes'),
            cancelText: t('no'),
            onOk() {
                handleCalcFinish();
            }
        });
    };

    const openDrugSelector = () => {
        setShowDrugModal(true);
    };

    const clearFormFields = () => {

        const { external_pathogens } = currentCalculator;
        if (external_pathogens?.length > 0) {
            external_pathogens?.map(x => x?.id)?.forEach(pathId => {
                dispatch(removeExternalContamination({
                    pathogenId: pathId
                }));
            });

            setTimeout(() => {
                dispatch(getCalculatorPhyto(match?.params?.id));
            }, 500);
        }
    };

    const goToProtection = () => {
        const { culture } = form?.getFieldsValue();
        const diseasesList = [];
        const cultureName = culturesList?.filter(x => x?.id === culture)[0]?.name;
        pathogens?.map(x => x?.disease?.name)?.forEach(diseaseName => {
            if (!diseasesList.includes(diseaseName)) {
                diseasesList.push(diseaseName);
            }
        });
        if (cultureName?.length > 0 && diseasesList?.length > 0) {
            history.push(`/pesticides/${cultureName}/${diseasesList?.join('-')}`);
        }
    };

    return (
        <>
            <Row style={{
                marginTop: '25px'
            }}>
                <Col span={12}>
                    <Form.Item
                        label={t('external select label')}
                        name={['external_pathogen_ids']}>
                        <Select
                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                            showSearch
                            options={agentsList}
                            allowClear
                            placeholder={t('external select placeholder')}
                            className="w-100"
                            mode="multiple"
                            onChange={setSelectedItems} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <Button onClick={addSelectedAgents} type="primary" className="mt-25 mb-25">
                        {t('button text select')}
                    </Button>
                </Col>
                <Col span={1} />
                <Col span={2}>
                    <Button type="secondary" onClick={clearFormFields} className="mt-25 mb-25">
                        {t('clear')}
                    </Button>
                </Col>
            </Row>
            {pathogens?.length > 0 && (
                <Collapse
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white', marginBottom: '0' }}
                    bordered={false}
                    defaultActiveKey={['external_probability']}
                    className={`${cn('collapse')} remove-padding`}>
                    <Panel
                        header={<b>{t('calculator external collapse header')}</b>}
                        key="external_probability">
                        <div className="pathogens-table">

                            <Row className="pathogens-table__row__header">
                                <Col className="pathogens-table__row__text__header" span={contaminationStage > 1 ? 4 : 6}>
                                    {t('external probability header reason')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={contaminationStage > 1 ? 4 : 6}>
                                    {t('external probability header harmful')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={contaminationStage > 1 ? 8 : 12}>
                                    {t('external probability header probability')}
                                </Col>
                                {contaminationStage > 1 && (
                                    <Col className="pathogens-table__row__text__header" span={4}>
                                        {t('external probability header pcs')}
                                    </Col>
                                )}
                            </Row>

                            {pathogens?.map((selectedPathogen, pIdx) => (
                                <Row className="pathogens-table__row">
                                    <Col className="pathogens-table__row__text" span={contaminationStage > 1 ? 4 : 6}>
                                        {selectedPathogen?.causative_agent?.name}
                                    </Col>
                                    <Col className="pathogens-table__row__text__highlighted" span={contaminationStage > 1 ? 4 : 6}>
                                        <div onClick={() => showDiseaseModal(selectedPathogen?.disease)}>{selectedPathogen?.disease?.name}</div>
                                    </Col>
                                    <Col className="pathogens-table__row__text__columned" span={contaminationStage > 1 ? 8 : 12}>
                                        {selectedPathogen?.disease?.cultures?.map(x => x?.vegetation_phases?.map(y => y?.name))?.filter(x => x?.length > 0).join(',')}
                                    </Col>
                                    {contaminationStage > 1 && (
                                        <>
                                            <Col className="pathogens-table__row__text__header" span={4}>
                                                <Form.Item name={`external/percentage/${selectedPathogen?.id}`} key="">
                                                    <InputNumber />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            )}

            {pathogens?.length > 0 && (
                <Collapse
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white', padding: '0', marginTop: '15px' }}
                    bordered={false}
                    defaultActiveKey={['drug_selection']}
                    className={`${cn('collapse')} remove-padding`}>
                    <Panel
                        header={<b>{t('calculator external collapse header drug_selection')}</b>}
                        key="drug_selection">
                        <div className="drug-cards">
                            {pathogens?.map(drugOption => (
                                <div className="drug-card">
                                    <div className="drug-card__header">
                                        {drugOption?.disease?.name}
                                    </div>
                                    <div className="drug-card__list">
                                        <ul>
                                            {drugOption?.drugs?.map(dr => (
                                                <li>
                                                    {dr?.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            )}

            {/* <Row style={{ marginTop: '15px' }}>
                <Form.Item name="external_pathogen_comment">
                    <Input.TextArea disabled />
                </Form.Item>
            </Row> */}

            {images?.length > 0 && (
                <Table dataSource={images} columns={imagesColumns} />
            )}

            <Row className="mt-25" gutter={12}>
                <Col span={5} />
                <Col span={6}>
                    <Button
                        disabled={pathogens?.length < 1}
                        className="w-100"
                        onClick={() => goToProtection()}>{t('external bottom button goto')}</Button>
                </Col>
                <Col span={6}>
                    <Button className="w-100" onClick={confirmFinishCalculation}>{t('external bottom button finish')}</Button>
                </Col>
                <Col span={5} />
            </Row>

            <InfoModal
                inspectableItem={currentDisease}
                downloadPDF={downloadDiseasePDF}
                closeModal={() => setIsModalOpen(false)}
                isOpened={isModalOpen} />
        </>
    );
};

export default ExternalContamination;

