import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getDisease.try:
        case constants.getDiseases.try: {
            return true;
        }

        case constants.getDisease.success:
        case constants.getDiseases.success:
        case constants.getDisease.error:
        case constants.getDiseases.error: {
            return false;
        }

        default:
            return state;
    }
}