import React, { useEffect, useState, useRef } from 'react';
import {
    Button, Form,
    Col, Input, InputNumber, Modal, Row, Table
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import { CheckSquareOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import FertilizerModal from './fertilizer-modal';
import { Tooltip } from '../../../components';
import { createNewFertilizer, getFertilizer, updateFertilizerInfo, updateProtocolFertilizers, updateFertilizerPrice, getProtocol } from '../../../models/fed-registry/actions';
import { useRouteMatch } from 'react-router-dom';
import { curRetrievedFertilizer } from '../../../models/fed-registry/selectors';
import { toFixed } from '../../../utils/utils';
import { getUserId } from '../../../models/account/selectors';

import '../style.less';

const elementsArray = [
    'N', 'P', 'KS', 'KCl', 'Ca', 'Mg', 'B', 'Cu', 'Zn', 'Mn', 'Fe', 'Mo', 'Co', 'I'
];

const CalculatorFertilizers = ({
    fertilizers, calculator,
    protocolEntity, allFieldsDisabled
}) => {
    const cn = useClassName('calculator-detail-fed-params');
    const { t } = useTranslation('fed registry');
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const [newFertilizerForm] = Form.useForm();

    const retrievedFert = useSelector(state => curRetrievedFertilizer(state));
    const currentUserId = useSelector(state => getUserId(state));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentFertilizerList, setCurrentFertilizerList] = useState([]);

    const [selectedFertilizersStage, setSelectedFertilizersStage] = useState([]);
    const [selectedKeysStage, setSelectedKeysStage] = useState([]);

    const onStageSelect = (selKeys, selRows) => {
        setSelectedKeysStage(selKeys);
        setSelectedFertilizersStage(selRows);
    };

    const rowSelectionStage = {
        selectedKeysStage,
        onChange: onStageSelect
    };

    const rowSelectionDisabled = {};

    // useEffect(() => {
    //     if (retrievedFert?.id && retrievedFert?.id > 0) {
    //         setCurrentFertilizerList([...currentFertilizerList, retrievedFert]);
    //     }
    // }, [retrievedFert]);

    useEffect(() => {
        setCurrentFertilizerList(fertilizers);
    }, [fertilizers]);

    const addFertilizer = (selectedItem) => {
        dispatch(getFertilizer({ id: selectedItem?.id }));
    };

    const deleteFertilizer = (record) => {
        const resArr = [...currentFertilizerList].filter(x => x.id !== record?.id);
        setCurrentFertilizerList([...resArr]);
        dispatch(updateProtocolFertilizers({
            id: match?.params?.id,
            values: {
                fertilizers: resArr?.map(x => x?.fertilizer?.id),
                result_fertilizers: protocolEntity?.result_fertilizers
            }
        }));
    };

    const delayedUpdate = () => {
        if (match?.params?.id) {
            setTimeout(() => dispatch(getProtocol({ id: match?.params?.id })), 1000);
        }
    };

    const handleFertilizerInfoUpdate = (fieldName, fertilizerItem, newValues) => {
        dispatch(updateFertilizerInfo({
            id: fertilizerItem?.id,
            values: {
                [fieldName]: newValues
            }
        }));
        delayedUpdate();
    };

    const handleUpdateFertilizerPrice = (fertilizerItem, newPrice) => {
        dispatch(updateFertilizerPrice({
            fertilizerId: fertilizerItem?.id,
            newPrice: typeof newPrice === 'number' ? newPrice : parseFloat(newPrice?.replace(',', '.'))
        }));
        delayedUpdate();
    };

    const disabledColumns = [
        {
            title: t('name'),
            dataIndex: ['fertilizer', 'name'],
            key: 'name',
            render: (text, record) => (
                <Tooltip
                    placement="bottomLeft"
                    title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: t('table elements header group'),
            children: elementsArray?.map(x => {
                return {
                    title: x,
                    dataIndex: ['fertilizer', x?.toLowerCase()],
                    key: x?.toLowerCase(),
                    render: value => toFixed(value, 2)
                };
            })
        },
        {
            title: t('table fertilizer price group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: ['fertilizer', 'consumption_rate_min'],
                    key: 'consumption_rate_min'
                },
                {
                    title: t('table max'),
                    dataIndex: ['fertilizer', 'consumption_rate_max'],
                    key: 'consumption_rate_max'
                }
            ]
        },
        {
            title: t('avg_price'),
            // dataIndex: ['fertilizer', 'price'],
            dataIndex: ['price'],
            key: 'avg_price',
            width: 140,
            sortType: 'number',
            // render: (value, record) => record?.price ? (
            //     <InputNumber
            //         decimalSeparator=","
            //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
            //         defaultValue={value}
            //         onBlur={(evnt) => handleUpdateFertilizerPrice(record, evnt?.target?.value)} />
            // ) : (
            //     <InputNumber
            //         decimalSeparator=","
            //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
            //         defaultValue={record?.fertilizer?.price}
            //         onBlur={(evnt) => handleUpdateFertilizerPrice(record, evnt?.target?.value)} />
            // )
        },
        {
            title: t('table consumption header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'calc_cons_rate_min',
                    key: 'calc_cons_rate_min',
                    width: 100,
                    // render: (value, record) => (
                    //     <InputNumber
                    //         decimalSeparator=","
                    //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    //         defaultValue={value}
                    //         onBlur={(evnt) => handleFertilizerInfoUpdate('calc_cons_rate_min', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    // )
                },
                {
                    title: t('table max'),
                    dataIndex: 'calc_cons_rate_max',
                    key: 'calc_cons_rate_max',
                    width: 100,
                    // render: (value, record) => (
                    //     <InputNumber
                    //         decimalSeparator=","
                    //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    //         defaultValue={value}
                    //         onBlur={(evnt) => handleFertilizerInfoUpdate('calc_cons_rate_max', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    // )
                },
                {
                    title: t('table opt'),
                    dataIndex: 'calc_cons_rate_opt',
                    key: 'calc_cons_rate_opt',
                    width: 100,
                    // render: (value, record) => (
                    //     <InputNumber
                    //         decimalSeparator=","
                    //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    //         defaultValue={value}
                    //         onBlur={(evnt) => handleFertilizerInfoUpdate('calc_cons_rate_opt', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    // )
                }
            ]
        },
        {
            title: t('table price header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'calc_price_min',
                    key: 'calc_price_min',
                    width: 100,
                    // render: (value, record) => (
                    //     <InputNumber
                    //         decimalSeparator=","
                    //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    //         defaultValue={value}
                    //         onBlur={(evnt) => handleFertilizerInfoUpdate('calc_price_min', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    // )
                },
                {
                    title: t('table max'),
                    dataIndex: 'calc_price_max',
                    key: 'calc_price_max',
                    width: 100,
                    // render: (value, record) => (
                    //     <InputNumber
                    //         decimalSeparator=","
                    //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    //         defaultValue={value}
                    //         onBlur={(evnt) => handleFertilizerInfoUpdate('calc_price_max', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    // )
                },
                {
                    title: t('table opt'),
                    dataIndex: 'calc_price_opt',
                    key: 'calc_price_opt',
                    width: 100,
                    // render: (value, record) => (
                    //     <InputNumber
                    //         decimalSeparator=","
                    //         disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    //         defaultValue={value}
                    //         onBlur={(evnt) => handleFertilizerInfoUpdate('calc_price_opt', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    // )
                }
            ]
        },
        //
        {
            title: t('table price area header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'area_price_min',
                    key: 'area_price_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table max'),
                    dataIndex: 'area_price_max',
                    key: 'area_price_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table opt'),
                    dataIndex: 'area_price_opt',
                    key: 'area_price_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        {
            title: t('table norm area header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'area_cons_rate_min',
                    key: 'area_cons_rate_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table max'),
                    dataIndex: 'area_cons_rate_max',
                    key: 'area_cons_rate_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table opt'),
                    dataIndex: 'area_cons_rate_opt',
                    key: 'area_cons_rate_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        //
        {
            title: t('table rate'),
            dataIndex: 'rate',
            key: 'rate'
        }
    ];

    const columns = [
        {
            title: t('name'),
            dataIndex: ['fertilizer', 'name'],
            key: 'name',
            render: (text, record) => (
                <Tooltip
                    placement="bottomLeft"
                    title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: t('table elements header group'),
            children: elementsArray?.map(x => {
                return {
                    title: x,
                    dataIndex: ['fertilizer', x?.toLowerCase()],
                    key: x?.toLowerCase(),
                    render: value => toFixed(value, 2)
                };
            })
        },
        {
            title: t('table fertilizer price group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: ['fertilizer', 'consumption_rate_min'],
                    key: 'consumption_rate_min'
                },
                {
                    title: t('table max'),
                    dataIndex: ['fertilizer', 'consumption_rate_max'],
                    key: 'consumption_rate_max'
                }
            ]
        },
        {
            title: t('avg_price'),
            // dataIndex: ['fertilizer', 'price'],
            dataIndex: ['price'],
            key: 'avg_price',
            width: 140,
            sortType: 'number',
            render: (value, record) => record?.price ? (
                <InputNumber
                    decimalSeparator=","
                    disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    defaultValue={value}
                    onBlur={(evnt) => handleUpdateFertilizerPrice(record, evnt?.target?.value)} />
            ) : (
                <InputNumber
                    decimalSeparator=","
                    disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                    defaultValue={record?.fertilizer?.price}
                    onBlur={(evnt) => handleUpdateFertilizerPrice(record, evnt?.target?.value)} />
            )
        },
        {
            title: t('table consumption header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'calc_cons_rate_min',
                    key: 'calc_cons_rate_min',
                    width: 100,
                    render: (value, record) => (
                        <InputNumber
                            decimalSeparator=","
                            disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                            defaultValue={value}
                            onBlur={(evnt) => handleFertilizerInfoUpdate('calc_cons_rate_min', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    )
                },
                {
                    title: t('table max'),
                    dataIndex: 'calc_cons_rate_max',
                    key: 'calc_cons_rate_max',
                    width: 100,
                    render: (value, record) => (
                        <InputNumber
                            decimalSeparator=","
                            disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                            defaultValue={value}
                            onBlur={(evnt) => handleFertilizerInfoUpdate('calc_cons_rate_max', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    )
                },
                {
                    title: t('table opt'),
                    dataIndex: 'calc_cons_rate_opt',
                    key: 'calc_cons_rate_opt',
                    width: 100,
                    render: (value, record) => (
                        <InputNumber
                            decimalSeparator=","
                            disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                            defaultValue={value}
                            onBlur={(evnt) => handleFertilizerInfoUpdate('calc_cons_rate_opt', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    )
                }
            ]
        },
        {
            title: t('table price header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'calc_price_min',
                    key: 'calc_price_min',
                    width: 100,
                    render: (value, record) => (
                        <InputNumber
                            decimalSeparator=","
                            disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                            defaultValue={value}
                            onBlur={(evnt) => handleFertilizerInfoUpdate('calc_price_min', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    )
                },
                {
                    title: t('table max'),
                    dataIndex: 'calc_price_max',
                    key: 'calc_price_max',
                    width: 100,
                    render: (value, record) => (
                        <InputNumber
                            decimalSeparator=","
                            disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                            defaultValue={value}
                            onBlur={(evnt) => handleFertilizerInfoUpdate('calc_price_max', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    )
                },
                {
                    title: t('table opt'),
                    dataIndex: 'calc_price_opt',
                    key: 'calc_price_opt',
                    width: 100,
                    render: (value, record) => (
                        <InputNumber
                            decimalSeparator=","
                            disabled={!Object.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers}
                            defaultValue={value}
                            onBlur={(evnt) => handleFertilizerInfoUpdate('calc_price_opt', record, evnt?.target?.value?.replace(/,/g, '.'))} />
                    )
                }
            ]
        },
        //
        {
            title: t('table price area header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'area_price_min',
                    key: 'area_price_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table max'),
                    dataIndex: 'area_price_max',
                    key: 'area_price_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table opt'),
                    dataIndex: 'area_price_opt',
                    key: 'area_price_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        {
            title: t('table norm area header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'area_cons_rate_min',
                    key: 'area_cons_rate_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table max'),
                    dataIndex: 'area_cons_rate_max',
                    key: 'area_cons_rate_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table opt'),
                    dataIndex: 'area_cons_rate_opt',
                    key: 'area_cons_rate_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        //
        {
            title: t('table rate'),
            dataIndex: 'rate',
            key: 'rate'
        },
        // {
        //     title: t('table choice'),
        //     dataIndex: 'choice',
        //     key: 'choice'
        // },
        {
            title: t('actions'),
            dataIndex: '',
            key: 'actions',
            width: 80,
            render: (value, record) => (
                <DeleteOutlined onClick={() => deleteFertilizer(record)} />
            )
        }
    ];
    
    const [newFertilizerModalOpen, setNewFertilizerModalOpen] = useState(false);

    const handleOpenNewFertilizer = () => {
        setIsModalOpen(false);
        setNewFertilizerModalOpen(true);
    };

    const handleCreateNewFertilizer = () => {
        const formVals = newFertilizerForm?.getFieldsValue();
        formVals.user = currentUserId;
        dispatch(createNewFertilizer(formVals));
        setTimeout(() => {
            setNewFertilizerModalOpen(false);
            setIsModalOpen(true);
        }, 300);
    };

    const handleSelectionFinal = () => {
        dispatch(updateProtocolFertilizers({
            id: match?.params?.id,
            values: {
                fertilizers: selectedFertilizersStage?.map(x => x?.fertilizer?.id),
                result_fertilizers: true
            }
        }));
    };

    const handleSelectionRevert = () => {
        dispatch(updateProtocolFertilizers({
            id: match?.params?.id,
            values: {
                fertilizers: protocolEntity?.fed_fertilizers?.map(x => x?.fertilizer?.id),
                result_fertilizers: true
            }
        }));
    };

    const checkDeficitValues = () => {
        if (
            typeof calculator?.deficit_b === 'number'
            && typeof calculator?.deficit_ca === 'number'
            && typeof calculator?.deficit_co === 'number'
            && typeof calculator?.deficit_cu === 'number'
            && typeof calculator?.deficit_fe === 'number'
            && typeof calculator?.deficit_i === 'number'
            && typeof calculator?.deficit_kcl === 'number'
            && typeof calculator?.deficit_ks === 'number'
            && typeof calculator?.deficit_mg === 'number'
            && typeof calculator?.deficit_mn === 'number'
            && typeof calculator?.deficit_mo === 'number'
            && typeof calculator?.deficit_n === 'number'
            && typeof calculator?.deficit_p === 'number'
            && typeof calculator?.deficit_s === 'number'
            && typeof calculator?.deficit_zn === 'number'
        ) {
            return true;
        }

        return false;
    };

    const [finalizeDisabled, setFinalizeDisabled] = useState(true);

    useEffect(() => {
        console.log('PROTO', protocolEntity);
        if (protocolEntity?.result_fertilizers && selectedKeysStage?.length > 0) {
            setFinalizeDisabled(false);
        }
    }, [protocolEntity, selectedKeysStage]);

    return (
        <div>
            <Row>
                <Col span={8} className={cn('title-col')}>
                    {t('header deficite')}
                </Col>
            </Row>

            <Table
                bordered
                pagination={false}
                dataSource={[calculator]}
                columns={elementsArray?.map(x => {
                    return {
                        title: x === 'KS' ? 'K' : x === 'KCl' ? 'S' : x,
                        dataIndex: `deficit_${x?.toLowerCase()}`,
                        key: `deficit_${x?.toLowerCase()}`,
                        render: value => toFixed(value, 2)
                    };
                })} />

            {checkDeficitValues() ? (
                <>
                    <Row style={{ marginTop: '25px' }}>
                        <Col span={24} className={cn('title-col')}>
                            {t('header recommendations')}
                        </Col>
                    </Row>

                    {fertilizers?.length < 5 && (
                        <>
                            <Row style={{ marginTop: '15px' }}>
                                <Col span={5}>
                                    <Button disabled={allFieldsDisabled} onClick={() => setIsModalOpen(true)} type="primary">
                                        <PlusCircleOutlined />
                                        {t('add fertilizer to selection')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '15px' }}>
                                <Col span={4}>
                                    {!finalizeDisabled ? (
                                        <Button disabled={allFieldsDisabled} onClick={handleSelectionFinal} type="primary">
                                            <CheckSquareOutlined />
                                            {t('finalize selection')}
                                        </Button>
                                    ) : (
                                        <Tooltip
                                            placement="bottomLeft"
                                            title={t('finalize selection hint')}>
                                            <Button disabled onClick={handleSelectionFinal} type="primary">
                                                <CheckSquareOutlined />
                                                {t('finalize selection')}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}

                    {(!Object?.keys(protocolEntity)?.includes('result_fertilizers') || !protocolEntity?.result_fertilizers) && (
                        <Row>
                            <div className="diagnostics__card">
                                <div className="diagnostics__card__text">
                                    {t('fertilizer hint text')}
                                </div>
                            </div>
                        </Row>
                    )}

                    <Table
                        rowKey="id"
                        rowSelection={allFieldsDisabled ? null : rowSelectionStage}
                        bordered
                        scroll={{ x: 1500 }}
                        style={{ marginTop: '25px' }}
                        columns={allFieldsDisabled ? disabledColumns : columns}
                        dataSource={[...currentFertilizerList]} />

                    <Modal
                        width={800}
                        footer={(
                            <Row justify="center">
                                <Col span={20} />
                                <Col span={4}>
                                    <Button onClick={handleCreateNewFertilizer} type="primary">
                                        {t('create')}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        onCancel={() => {
                            setNewFertilizerModalOpen(false);
                            newFertilizerForm?.resetFields();
                            setIsModalOpen(true);
                        }}
                        visible={newFertilizerModalOpen}>
                        <div>
                            <Form form={newFertilizerForm} labelCol={{ span: 24 }}>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item name="name" key="name" label={t('new fertilizer create modal header name')}>
                                            <Input style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="price" key="price" label={t('new fertilizer create modal header price')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item name="consumption_rate_min" key="consumption_rate_min" label={t('new fertilizer create modal header consumption_rate_min')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="consumption_rate_max" key="consumption_rate_max" label={t('new fertilizer create modal header consumption_rate_max')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name="n" key="n" label={t('new fertilizer create modal header n')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="p" key="p" label={t('new fertilizer create modal header p')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="ks" key="ks" label={t('new fertilizer create modal header ks')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="kcl" key="kcl" label={t('new fertilizer create modal header kcl')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name="ca" key="ca" label={t('new fertilizer create modal header ca')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="mg" key="mg" label={t('new fertilizer create modal header mg')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="b" key="b" label={t('new fertilizer create modal header b')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="cu" key="cu" label={t('new fertilizer create modal header cu')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name="zn" key="zn" label={t('new fertilizer create modal header zn')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="mn" key="mn" label={t('new fertilizer create modal header mn')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="fe" key="fe" label={t('new fertilizer create modal header fe')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="mo" key="mo" label={t('new fertilizer create modal header mo')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name="co" key="co" label={t('new fertilizer create modal header co')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="i" key="i" label={t('new fertilizer create modal header i')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Modal>
                    <FertilizerModal
                        existingFerts={currentFertilizerList?.map(x => x?.fertilizer?.id)}
                        // existingFerts={currentFertilizerList?.map(x => x?.id)}
                        setCurrentFertilizerList={setCurrentFertilizerList}
                        currentFertilizerList={currentFertilizerList}
                        handleOpenNewFertilizer={handleOpenNewFertilizer}
                        addNewFertilizer={addFertilizer}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen} />
                </>
            ) : (
                <div style={{ marginTop: '15px' }} className="info-box">
                    {t('unable to calculate fertilizer')}
                </div>
            )}
        </div>
    );
};

export default CalculatorFertilizers;