import React from 'react';
import {
    TileLayer,
    LayersControl,
    LayerGroup
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { CustomWMSLayer } from './custom-wms';

export default () => {
    const { t } = useTranslation('layers');
    return (
        <>
            <LayersControl position="bottomleft">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay name={t('Izoline_5m')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Izoline_5m')}
                            // legend_url="teleagronom:Embulatovo_soils"
                            layer="teleagronom:Izoline_5m"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Izoline_1m')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Izoline_1m')}
                            // legend_url="teleagronom:Embulatovo_soils"
                            layer="teleagronom:Izoline_1m"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Eroziya_line')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Eroziya_line')}
                            legend_url="teleagronom:Kub_agro/Eroziya_line"
                            layer="teleagronom:Eroziya_line"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Eroziya_Ploskostnaya')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Eroziya_Ploskostnaya')}
                            // legend_url="teleagronom:Embulatovo_soils"
                            layer="teleagronom:Eroziya_Ploskostnaya"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Ekspoziciya')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Ekspoziciya')}
                            legend_url="teleagronom:Kub_agro/Ekspoziciya"
                            layer="teleagronom:Ekspoziciya"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Zone_prod')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Zone_prod')}
                            legend_url="teleagronom:Kub_agro/Zone_prod"
                            layer="teleagronom:Zone_prod"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('2023_field')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('2023_field')}
                            legend_url="teleagronom:kub_2023"
                            layer="teleagronom:2023_field"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('2024_field')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('2024_field')}
                            legend_url="teleagronom:kub_2024"
                            layer="teleagronom:2024_field"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('reg_71_2023_06_month_ndvi')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('reg_71_2023_06_month_ndvi')}
                            legend_url="teleagronom:NDVI_0_1"
                            layer="teleagronom:reg_71_2023_06_month_ndvi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('reg_71_2023_07_month_ndvi')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('reg_71_2023_07_month_ndvi')}
                            legend_url="teleagronom:NDVI_0_1"
                            layer="teleagronom:reg_71_2023_07_month_ndvi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('reg_71_2023_08_month_ndvi')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('reg_71_2023_08_month_ndvi')}
                            legend_url="teleagronom:NDVI_0_1"
                            layer="teleagronom:reg_71_2023_08_month_ndvi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </>
    );
};
