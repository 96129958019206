import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useClassName } from 'utils/cn';
import HomePage from './components/home-page';
import Individuals from './components/individuals';
import LegalEntities from './components/legal-entities';
import LabResearch from './components/lab-research';
import Partners from './components/partners';

import './style.less';

const { Content } = Layout;

export default () => {
    const cn = useClassName('home');
    const [pageType, setPageType] = useState('main');

    const setPageTypeToTop = (type) => {
        setPageType(type);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    return (
        <Layout className={cn()}>
            <Content className={cn('content')}>
                {pageType === 'main' && <HomePage setPageType={setPageTypeToTop} />}
                {pageType === 'individuals' && <Individuals setPageType={setPageTypeToTop} />}
                {pageType === 'legal-entities' && <LegalEntities setPageType={setPageTypeToTop} />}
                {pageType === 'lab-research' && <LabResearch setPageType={setPageTypeToTop} />}
                {pageType === 'partners' && <Partners setPageType={setPageTypeToTop} />}
            </Content>
        </Layout>
    );
};
