import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorFed.try:
        case constants.setParametersCalculatorFed.try:
        case constants.addCalculatorFed.try:
        case constants.getCalculatorsFed.try:
        case constants.removeCalculatorFed.try: {
            return true;
        }

        case constants.getCalculatorFed.success:
        case constants.setParametersCalculatorFed.success:
        case constants.addCalculatorFed.success:
        case constants.getCalculatorsFed.success:
        case constants.removeCalculatorFed.success:
        case constants.getCalculatorFed.error:
        case constants.setParametersCalculatorFed.error:
        case constants.addCalculatorFed.error:
        case constants.getCalculatorsFed.error:
        case constants.removeCalculatorFed.error: {
            return false;
        }

        default:
            return state;
    }
}