import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Rate, Row, Col, Icon, Collapse, Form, Input, Select
} from 'antd';
import { Button } from '../../../../components';


import { regions } from '../../../../regions';
import cn from '../../../../utils/cn';
import './style.less';
import { selectors, actions } from '../../../../models';
import { object2Form } from '../../../../utils/form';

const { TextArea } = Input;
const {
    addReview
} = actions;
const {
    getCurrentFertilizer
} = selectors;

const mstp = (state) => ({
    fertilizer: getCurrentFertilizer(state)
});
const mdtp = ({
    addReview
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('disease review')
@cn('fertilizer-reviews-add')
export default class FertilizerReview extends Component {
    state = {
        rate: undefined,
        year: undefined,
        region: undefined,
        steps: undefined,
        virtues: undefined,
        disadvantages: undefined,
        message: undefined
    }

    componentDidMount() {
        const { match } = this.props;
        const { id } = match.params;
    }

    onSubmit = (e, form) => {
        const {
            addReview, id, fertilizer, createReview
        } = this.props;
        const { rate } = this.state;
        const { validateFields } = form;

        e.preventDefault();

        validateFields((err, values) => {
            if (!err && rate) {
                values.fertilizerId = fertilizer.id;
                values.rate = rate;
                values.steps = values.steps.join(',');
                !id && addReview(values);
                createReview(false);
            } else {
                console.warn(err);
            }
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    onChange = (e, form) => {
        this.setState(e);
    };

    handleChange = value => {
        this.setState({ rate: value });
    };

    initForm = () => {
        const { id } = this.props;
        const { formInit } = this.state;

        id && formInit(object2Form({}));
    };

    get years() {
        const arr = [];
        for (let i = 2000; i <= 2020; i++) {
            arr.push({ value: i, title: i });
        }
        return arr;
    }

    goBack = () => {
        const { history } = this.props;
        history.push('/fertilizers');
    };

    get checkFields() {
        const {
            rate,
            year,
            region,
            steps
        } = this.state;
        return rate && year && region && steps;
    }

    render() {
        const { cn } = this;
        const {
            t, fertilizer, curRate
        } = this.props;
        if (!fertilizer) return <div />;
        return (

            <Form onSubmit={this.onSubmit}
                onValuesChange={this.onChange}
                onMount={this.onMount}
                className={cn('review')}
                name="review-form">
                <Row
                    gutter={20}
                    style={{ marginBottom: '20px', marginTop: '45px' }}
                    className={cn('ant-row')}>
                    <Col span={6}>
                        {t('common rate')}
                    </Col>
                    <Col span={6}>
                        <Rate defaultValue={0} onChange={this.handleChange} />
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    style={{ marginBottom: '20px' }}
                    className={cn('ant-row')}>
                    <Col span={6}>
                        {t('year')}
                    </Col>
                    <Col span={6}>
                        <Select
                            name="year"
                            isRequired
                            placeholder={t('select')}
                            items={this.years} />
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    style={{ marginBottom: '20px' }}
                    className={cn('ant-row')}>
                    <Col span={6}>
                        {t('steps')}
                    </Col>
                    <Col span={6}>
                        <Select
                            name="steps"
                            isRequired
                            placeholder={t('select')}
                            mode="multiple"
                            items={[
                                { value: 'autumn', title: t('autumn') },
                                { value: 'spring', title: t('spring') },
                                { value: 'first', title: t('first') },
                                { value: 'second', title: t('second') }
                            ]} />
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    style={{ marginBottom: '20px' }}
                    className={cn('ant-row')}>
                    <Col span={6}>
                        {t('region')}
                    </Col>
                    <Col span={6}>
                        <Select
                            name="region"
                            isRequired
                            placeholder={t('select')}
                            items={
                                regions.map(region =>
                                    ({ value: region.code, title: region.name }))
                            } />
                    </Col>
                </Row>
                <div className={cn('title')}>
                    {t('more')}
                </div>
                <Row gutter={20} style={{ marginBottom: '20px' }}>
                    <Col span={12}>
                        <TextArea
                            autoSize={{ minRows: 3 }}
                            name="virtues"
                            label={t('like')}
                            placeholder={t('like placeholder')} />
                    </Col>
                </Row>
                <Row gutter={20} style={{ marginBottom: '20px' }}>
                    <Col span={12}>
                        <TextArea
                            autoSize={{ minRows: 3 }}
                            name="disadvantages"
                            label={t('dislike')}
                            placeholder={t('dislike placeholder')} />
                    </Col>
                </Row>
                <Row gutter={20} style={{ marginBottom: '20px' }}>
                    <Col span={12}>
                        <TextArea
                            autoSize={{ minRows: 3 }}
                            name="message"
                            label={t('common comment')}
                            placeholder={t('common comment placeholder')} />
                    </Col>
                </Row>
                <Row className={cn('fixed-info')}>
                    <Col className={cn('button')}>
                        <Button type="primary"
                            htmlType="submit"
                            disabled={!this.checkFields}>
                            {t('send')}
                        </Button>
                        <Button type="default">{t('cancel')}</Button>
                    </Col>
                </Row>
            </Form>

        );
    }
}
