import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getDeterminants = filter => request.get({
    url: urlPath(filter)
});

export const getDeterminant = id => request.get({
    url: urlPath(`${id}/`)
});

export const addDeterminant = body => request.post({
    url,
    body
});

export const removeDeterminant = id => request.remove({
    url: urlPath(`${id}/`)
});

export const setAnswer = (body) => request.post({
    url: urlPath(`${body.id}/answer_question/`),
    body: body.values
});

export const resetAnswer = (body) => request.post({
    url: urlPath(`${body.id}/reanswer_question/`),
    body: body.values
});

export const getQuestion = id => request.get({
    url: urlPath(`${id}/current_item/`)
});

export const getQuestions = id => request.get({
    url: urlPath(`${id}/question_history/`)
});