import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';
import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

function* removeRequestSaga({ payload: id }) {
    const [start, success, failure] = getActions(
        constants.removeRequest.try,
        constants.removeRequest.success,
        constants.removeRequest.error
    );
    try {
        yield put(start(id));
        yield call(api.removeRequest, id);
        const obj = converter.removedRequestId2Client(id);
        yield put(success(obj));
    } catch (error) {
        yield put(failure(error));
    }
}

function* addRequestSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addRequest.try,
        constants.addRequest.success,
        constants.addRequest.error
    );
    try {
        const { history } = payload;
        yield put(start());
        const data = yield call(api.addRequest);
        yield call(() => history.push(`/request/${data.id}`));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* uploadTaskFileSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.uploadTaskFile.try,
        constants.uploadTaskFile.success,
        constants.uploadTaskFile.error
    );
    try {
        const { requestId, file } = payload;
        yield put(start());
        const data = yield call(api.uploadTaskFile, payload);
        yield put(actions.getRequest(requestId));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* addTaskSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addTask.try,
        constants.addTask.success,
        constants.addTask.error
    );
    try {
        const {
            requestId, files, title, isAdmin
        } = payload;
        const body = {};
        body.title = title;
        body.requestId = requestId;
        yield put(start());
        const data = yield call(api.addTask, body);
        const { id } = data;
        const status = isAdmin ? 'ANSWER' : 'IN_PROGRESS';
        yield all(
            files.map(file => put(actions.uploadTaskFile({
                url: `?taskId=${id}`,
                requestId,
                file
            })))
        );
        yield put(success());
        yield put(actions.setStatusRequest({ requestId, status }));
        yield put(actions.getRequest(requestId));
    } catch (error) {
        yield put(failure(error));
    }
}

function* saveAndSetStatusRequestSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.updateRequest.try,
        constants.updateRequest.success,
        constants.updateRequest.error
    );
    try {
        const { requestId, file } = payload;
        yield put(start());
        yield call(api.updateRequest, payload);
        yield put(success());
        yield put(actions.setStatusRequest(payload));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getRequests.toString(),
            defaultSaga({
                constants: constants.getRequests,
                api: api.getRequests
            })
        ),
        yield takeLatest(
            constants.getRequest.toString(),
            defaultSaga({
                constants: constants.getRequest,
                api: api.getRequest
            })
        ),
        yield takeLatest(
            constants.addRequest.toString(),
            addRequestSaga
        ),
        yield takeLatest(
            constants.updateRequest.toString(),
            defaultSaga({
                constants: constants.updateRequest,
                api: api.updateRequest
            })
        ),
        yield takeLatest(
            constants.saveAndSetStatusRequest.toString(),
            saveAndSetStatusRequestSaga
        ),
        yield takeLatest(
            constants.setStatusRequest.toString(),
            defaultSaga({
                constants: constants.setStatusRequest,
                api: api.setStatusRequest
            })
        ),
        yield takeLatest(
            constants.removeRequest.toString(),
            removeRequestSaga
        ),
        yield takeLatest(
            constants.getTasks.toString(),
            defaultSaga({
                constants: constants.getTasks,
                api: api.getTasks
            })
        ),
        yield takeLatest(
            constants.getTask.toString(),
            defaultSaga({
                constants: constants.getTask,
                api: api.getTask
            })
        ),
        yield takeLatest(
            constants.addTask.toString(),
            addTaskSaga
        ),
        yield takeLatest(
            constants.removeTask.toString(),
            defaultSaga({
                constants: constants.removeTask,
                api: api.removeTask
            })
        ),
        yield takeLatest(
            constants.uploadTaskFile.toString(),
            uploadTaskFileSaga
        )
    ]);
}