import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

const AzotobacterTable = ({ tableData }) => {
    const { t } = useTranslation('field representation mycological analysis');

    const columns = [
        {
            title: t('repetitions table col 1'),
            dataIndex: 'genus',
            key: 'genus'
        },
        {
            title: t('repetitions table col 2'),
            dataIndex: 'repetitions',
            children: tableData[0]?.repetitions?.map((x, idx) => {
                return {
                    title: idx + 1,
                    dataIndex: ['repetitions', idx],
                    key: `repetition-${x}`
                };
            }),
            key: 'repetitions'
        },
        {
            title: t('repetitions table col 3'),
            dataIndex: 'mean',
            key: 'mean',
            width: 30
        }
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={tableData} />
    );
};

export default AzotobacterTable;