import { getConstant } from '../utils';

export const removeCalculator = getConstant('REMOVE_CALCULATOR');

export const getAgrotechnicalEventsField = getConstant('GET_AGROTECHNICAL_EVENTS_FIELD');
export const getAgrotechnicalEventsAccounting = getConstant('GET_AGROTECHNICAL_EVENTS_ACCOUNTING');
export const updateAgrotechnicalEvents = getConstant('UPDATE_AGROTECHNICAL_EVENTS');
export const getVegetationPhases = getConstant('AGROTECHNICAL_EVENTS_GET_VEGETATION_PHASES');
export const getAgrotechnicalEventsList = getConstant('AGROTECHNICAL_EVENTS_GET_LIST');
export const deleteAgrotechnicalEvent = getConstant('AGROTECHNICAL_EVENTS_DELETE_EVENT');
export const patchAgrotechnicalEvent = getConstant('PATCH_AGROTECHNICAL_EVENT');