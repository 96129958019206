import { isLoading } from '../../account/selectors';
import * as constants from '../constants';

const initialState = {
    isLoading: false,
    results: [{}, {}],
    count: 2,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getSamplesList.success: {
            return { isLoading: false, results: action.payload?.results, count: action.payload?.count };
        }

        case constants.getSamplesList.error: {
            return { isLoading: false, results: [], count: 0 };
        }

        case constants.getSamplesList.try: {
            return { isLoading: true, results: [], count: 0 };;
        }

        default:
            return state;
    }
}
