import { combineReducers } from 'redux';

import registriesList from './registries-list';
import createdRecommendation from './created-recommendation';
import compositionList from './composition-list';
import cultureList from './culture-list';
import regionList from './region-list';
import subtypeList from './subtype-list';
import calculatedRecommendation from './calculated-recommendation';
import reportGeneral from './report-general';
import reportNorm from './report-norm';
import reportVolume from './report-volume';

export default combineReducers({
    reportGeneral,
    reportNorm,
    reportVolume,
    calculatedRecommendation,
    compositionList,
    cultureList,
    regionList,
    subtypeList,
    registriesList,
    createdRecommendation
});
