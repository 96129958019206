import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import cn from '../../utils/cn';

import './style.less';

@cn('custom-filter')
export default class CustomFilter extends Component {
    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    render() {
        const { cn } = this;
        const {
            className: propClass,
            label,
            onSelect,
            items = [],
            ...rest
        } = this.props;
        const className = `${cn()} ${propClass}`;

        return (
            <div className={className}>
                <span>{label}</span>
                <Select onSelect={onSelect}
                    filterOption={this.filterOption}
                    {...rest}>
                    {items?.map(item => (
                        <Select.Option value={item.value} key={item.value}>{item.title}</Select.Option>
                    ))}
                </Select>
            </div>
        );
    }
}

CustomFilter.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

CustomFilter.defaultProps = {
    className: '',
    label: '',
    placeholder: ''
};
