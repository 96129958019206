import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private exact
        key="calculator"
        path="/mycological-registry/:id?/:published?"
        component={Page} />
];

export default Routes;