import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getCalculatorsMyco = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getCalculatorMyco = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});

export const addCalculatorMyco = body => request.post({
    url: urlPath(options.apiUrl, 'create_calculator/'),
    body
});

export const setParamsCalculatorMyco = body => request.post({
    url: urlPath(options.apiUrl, `${body.id}/set_parameters/`),
    body: body.values
});

export const closeCalculatorMyco = body => request.post({
    url: urlPath(options.apiUrl, `${body.id}/close/`),
    body: body.values
});

export const removeCalculatorMyco = id => request.remove({
    url: urlPath(options.apiUrl, `${id}/`)
});

export const getCultureCalculatorsMyco = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});


export const getPrecedingCalculatorsMyco = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});


export const getGenusOfMicroCalculatorsMyco = filter => request.get({
    url: urlPath(options.apiGenusUrl, filter)
});

export const getGenusOfMicroCalculatorsMycoItem = id => request.get({
    url: urlPath(options.apiGenusUrl, `${id}/`)
});


export const getTypeOfResearchCalculatorsMyco = filter => request.get({
    url: urlPath(options.apiMycoUrl, filter)
});

export const getTypeOfResearchCalculatorsMycoItem = id => request.get({
    url: urlPath(options.apiMycoUrl, `${id}/`)
});
