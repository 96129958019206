import React from 'react';
import '../style.less';

export default ({ cardHeader, cardText, cardColor }) => {
    return (
        <div className={`quality-card__${cardColor}`}>
            <div className="quality-card__header">
                {cardHeader}
            </div>
            <div className="quality-card__text">
                {cardText}
            </div>
        </div>
    );
};