import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.manageSampleRowPublication.success: {
            return { ...action.payload, responseStatus: 'success' };
        }

        case constants.manageSampleRowPublication.error: {
            return { ...action.payload, responseStatus: 'error' };
        }

        case constants.manageSampleRowPublication.try: {
            return initialState;
        }

        default:
            return state;
    }
}