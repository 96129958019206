import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getAgroEvents = ({ vega_key, year }) => request.get({
    url: urlPath(options.apiEventsUrl, `${vega_key}/field/?year=${year}`)
});

export const updateAgroEvent = ({ id, year, body }) => request.patch({
    url: urlPath(options.apiEventUrl, `${id}/event/?year=${year}`),
    body
});