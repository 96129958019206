import { getConstant } from '../utils';

export const getFertilizers = getConstant('GET_FERTILIZERS');
export const getFertilizer = getConstant('GET_FERTILIZER');
export const clearFertilizer = getConstant('CLEAR_FERTILIZER');

export const getFertilizersGroup = getConstant('GET_FERTILIZERS_GROUP');
export const getFertilizersGroupItem = getConstant('GET_FERTILIZERS_GROUP_ITEM');
export const getFertilizersMainDrug = getConstant('GET_FERTILIZERS_MAIN_DRUG');
export const getFertilizersMainDrugItem = getConstant('GET_FERTILIZERS_MAIN_DRUG_ITEM');
export const getFertilizersMainDrugSynonyms = getConstant('GET_FERTILIZERS_MAIN_DRUG_SYNONYMS_ITEM');
export const getFertilizersMainDrugSynonymsItem = getConstant('GET_FERTILIZERS_MAIN_DRUG_SYNONYMS_ITEM');
export const getFertilizersReleaseForm = getConstant('GET_FERTILIZERS_RELEASE_FORM');
export const getFertilizersReleaseFormItem = getConstant('GET_FERTILIZERS_RELEASE_FORM_ITEM');
export const getFertilizersSubGroup = getConstant('GET_FERTILIZERS_SUBGROUP');
export const getFertilizersSubGroupItem = getConstant('GET_FERTILIZERS_SUBGROUP_ITEM');
export const getFertilizersToMainDrug = getConstant('GET_FERTILIZERS_TO_MAIN_DRUG');
export const getFertilizersToMainDrugItem = getConstant('GET_FERTILIZERS_TO_MAIN_DRUG_ITEM');
export const getFertilizersToRegistration = getConstant('GET_FERTILIZERS_TO_REGISTRATION');
export const getFertilizersToRegistrationItem = getConstant('GET_FERTILIZERS_TO_REGISTRATION_ITEM');
export const getFertilizersToSeller = getConstant('GET_FERTILIZERS_TO_SELLER');
export const getFertilizersToSellerItem = getConstant('GET_FERTILIZERS_TO_SELLER_ITEM');
export const getFertilizersType = getConstant('GET_FERTILIZERS_TYPE');
export const getFertilizersTypeItem = getConstant('GET_FERTILIZERS_TYPE_ITEM');
