import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteMatch, useHistory } from 'react-router-dom';
import {
    Row, Col, Form, Input, Select
} from 'antd';

import { useClassName } from 'utils/cn';
import {
    Button, InfoMessage
} from '../../../components';

import { selectors, actions } from '../../../models';

const { addCalculatorMyco } = actions;
const { getCurrentCalculatorMyco } = selectors;

export default ({ setName, addActiveKeys }) => {
    const cn = useClassName('calculator-detail');
    const { t } = useTranslation('calculator detail');
    const match = useRouteMatch();
    const history = useHistory();
    const { id } = match.params;
    const dispatch = useDispatch();
    const calculator = useSelector(state => getCurrentCalculatorMyco(state));
    const [visible, setVisible] = useState(false);
    const isCompleted = calculator.is_done;

    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({ name: calculator.name || '' });
    }, [calculator]);

    const closeCalculator = () => {
        history.push('/calculators/');
    };

    const hideModal = () => {
        setVisible(false);
    };

    const showModal = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
        values.user = 1;
        !id && dispatch(addCalculatorMyco({ history, values }));
        addActiveKeys('2');
    };
    const setCalculatorName = (e) => {
        setName(e.target.value);
    };

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    return (
        <Form onFinish={onFinish}
            form={form}
            className={cn('data')}
            name="calculator-form">
            <div>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className="field" style={{ marginBottom: '24px' }}>
                            <Form.Item
                                label={t('calculator title')}
                                name="name"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Input
                                    disabled={isCompleted}
                                    onChange={setCalculatorName} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col span={24} className={cn('button-block')}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isCompleted || id}>
                        {t('start')}
                    </Button>
                    <Button type="default"
                        onClick={showModal}
                        disabled={isCompleted}>{t('cancel')}
                    </Button>
                </Col>
            </Row>
            <InfoMessage
                visible={visible}
                title="create calculator title"
                text="create calculator description"
                okBtn="create calculator ok"
                cancelBtn="create calculator cancel"
                onOk={closeCalculator}
                onCancel={hideModal} />
        </Form>

    );
};