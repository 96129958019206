import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import cn from '../../../utils/cn';
import './style.less';

@cn('panel-footer')
class PanelFooter extends Component {
    render() {
        const { cn } = this;
        const {
            className,
            children,
            ...rest
        } = this.props;

        return (
            <div className={`${cn()} ${className}`} {...rest}>
                {children}
            </div>
        );
    }
}

PanelFooter.propTypes = {
    /** Дополнительное имя класса */
    className: PropTypes.string,
    children: PropTypes.any.isRequired
};

PanelFooter.defaultProps = {
    className: ''
};

export default PanelFooter;