import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import {
    defaultSaga, getActions
} from '../utils';

import * as constants from './constants';
import * as api from './api';

function* updateInternalCalcSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.updateInternalCalculator.try,
        constants.updateInternalCalculator.success,
        constants.updateInternalCalculator.error
    );
    try {
        yield put(start(payload));
        const data = yield call(api.updateInternalCalculator, payload);
        yield put(success(data));
    } catch (error) {
        yield put(failure({ error: error }));
    }
}
export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getProtocolList.toString(),
            defaultSaga({
                constants: constants.getProtocolList,
                api: api.getProtocolList
            })
        ),
        yield takeLatest(
            constants.createInitialProtocol.toString(),
            defaultSaga({
                constants: constants.createInitialProtocol,
                api: api.createInitialProtocol
            })
        ),
        yield takeLatest(
            constants.getProtocol.toString(),
            defaultSaga({
                constants: constants.getProtocol,
                api: api.getProtocol
            })
        ),
        yield takeLatest(
            constants.createInternalCalculator.toString(),
            defaultSaga({
                constants: constants.createInternalCalculator,
                api: api.createInternalCalculator
            })
        ),
        yield takeLatest(
            constants.publishProtocol.toString(),
            defaultSaga({
                constants: constants.publishProtocol,
                api: api.publishProtocol
            })
        ),
        yield takeLatest(
            constants.updateInternalCalculator.toString(),
            updateInternalCalcSaga
            // defaultSaga({
            //     constants: constants.updateInternalCalculator,
            //     api: api.updateInternalCalculator
            // })
        ),
        yield takeLatest(
            constants.updateProtocol.toString(),
            defaultSaga({
                constants: constants.updateProtocol,
                api: api.updateProtocol
            })
        ),
        yield takeLatest(
            constants.getFertilizer.toString(),
            defaultSaga({
                constants: constants.getFertilizer,
                api: api.getFertilizer
            })
        ),
        yield takeLatest(
            constants.getFertilizers.toString(),
            defaultSaga({
                constants: constants.getFertilizers,
                api: api.getFertilizers
            })
        ),
        yield takeLatest(
            constants.updateProtocolFertilizers.toString(),
            defaultSaga({
                constants: constants.updateProtocolFertilizers,
                api: api.updateProtocolFertilizers
            })
        ),
        yield takeLatest(
            constants.updateFertilizerInfo.toString(),
            defaultSaga({
                constants: constants.updateFertilizerInfo,
                api: api.updateFertilizerInfo
            })
        ),
        yield takeLatest(
            constants.createNewFertilizer.toString(),
            defaultSaga({
                constants: constants.createNewFertilizer,
                api: api.createNewFertilizer
            })
        ),
        yield takeLatest(
            constants.deleteProtocol.toString(),
            defaultSaga({
                constants: constants.deleteProtocol,
                api: api.deleteProtocol
            })
        ),
        yield takeLatest(
            constants.updateFertilizerPrice.toString(),
            defaultSaga({
                constants: constants.updateFertilizerPrice,
                api: api.updateFertilizerPrice
            })
        )
    ]);
}