import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getInterpretation.try:
        case constants.addInterpretation.try:
        case constants.updateInterpretation.try: {
            return true;
        }

        case constants.getInterpretation.success:
        case constants.addInterpretation.success:
        case constants.updateInterpretation.success:
        case constants.getInterpretation.error:
        case constants.addInterpretation.error:
        case constants.updateInterpretation.error: {
            return false;
        }

        default:
            return state;
    }
}