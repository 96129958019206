import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import PesticideData from './pesticide-data';
import PesticideReview from './pesticide-review';
import PesticidePrice from './pesticide-price';
import CircleInfoIcon from '../../../../public/images/catalog/circle-info.svg';
import PriceIcon from '../../../../public/images/catalog/price.svg';
import ReviewsIcon from '../../../../public/images/catalog/reviews.svg';

const { TabPane } = Tabs;
const { PanelHeader, PanelContent } = PanelLayout;

@withRouter
@withTranslation('pesticide detail')
@cn('pesticide')
export default class PesticideDetail extends Component {
    state = {
        tab: '1'
    };

    onTabChange = (key) => {
        this.setState({ tab: key });
    };

    render() {
        const { cn } = this;
        const { t } = this.props;
        const { tab } = this.state;

        return (
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('pesticide')} />
                </PanelHeader>
                <PanelContent>
                    <Tabs
                        className={cn()}
                        onChange={this.onTabChange}
                        activeKey={tab}>
                        <TabPane
                            tab={(
                                <span>
                                    <Icon component={CircleInfoIcon} />
                                    {t('about')}
                                </span>
                            )}
                            key="1">
                            <PesticideData onTabChange={this.onTabChange} />
                        </TabPane>
                        <TabPane
                            tab={(
                                <span>
                                    <Icon component={PriceIcon} />
                                    {t('price')}
                                </span>
                            )}
                            key="2">
                            <PesticidePrice />
                        </TabPane>
                        { /* <TabPane
                            disabled
                            tab={(
                                <span>
                                    <Icon component={ReviewsIcon} />
                                    {t('review')}
                                </span>
                            )}
                            key="3">
                            <PesticideReview />
                        </TabPane> */ }
                    </Tabs>
                </PanelContent>
            </PanelLayout>
        );
    }
}