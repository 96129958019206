import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';
import {
    InfoMessage, Table, TableFooter, Tooltip, Button
} from '../../../components';
import Popover from './action-popover';
import cn from '../../../utils/cn';

import { selectors, actions } from '../../../models';

const {
    removeDeterminant,
    getDeterminants
} = actions;

const {
    getCurDeterminantsBody,
    getCurDeterminantsCount,
    getUserId,
    getDiseasesList
} = selectors;

const mstp = state => ({
    dataSource: getCurDeterminantsBody(state),
    dataSize: getCurDeterminantsCount(state),
    userId: getUserId(state),
    diseases: getDiseasesList(state)
});

const mdtp = { removeDeterminant, getDeterminants };

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_TABLE_PAGE = 1;

@withRouter
@connect(mstp, mdtp)
@withTranslation('determinants table')
@cn('determinants-table')
export default class DeterminantsTable extends Component {
    state = {
        page: DEFAULT_TABLE_PAGE,
        page_size: ROW_DIVIDER,
        infoMessageVisible: false,
        deleteId: null
    };

    onPageSizeChange = (page_size) => {
        const { getDeterminants, culture, userId } = this.props;
        const { page } = this.state;
        const filter = culture ? `&filter={"determinant":[${culture}]}` : '';
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        this.setState({
            page_size
        }, () => getDeterminants(`?${page_size_url}&${page_url}${filter}`));
    };

    onCurrentPageChange = (page) => {
        const { getDeterminants, culture, userId } = this.props;
        const { page_size } = this.state;
        const filter = culture ? `&filter={"determinant":[${culture}]}` : '';
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        this.setState({
            page
        }, () => getDeterminants(`?${page_size_url}&${page_url}${filter}`));
    };

    get initRequest() {
        const { page, page_size } = this.state;

        return { page, page_size };
    }

    get filterItems() {
        const { dataSize } = this.props;
        const items = [];

        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }

        return items;
    }

    get pagesCount() {
        const { dataSize } = this.props;
        const { perPage } = this.state;

        if (!dataSize) {
            return 0;
        }

        return (dataSize / perPage) * ROW_DIVIDER;
    }

    removeDeterminant = () => {
        const { removeDeterminant } = this.props;
        const { deleteId } = this.state;

        removeDeterminant(deleteId);

        this.hideDeleteModal();
    };

    hideDeleteModal = () => {
        this.setState({ infoMessageVisible: false });
    };

    showDeleteModal = id => {
        this.setState({
            deleteId: id,
            infoMessageVisible: true
        });
    };

    get requestsColumns() {
        const { cn } = this;
        const {
            t, history, diseases, culture
        } = this.props;

        return [
            {
                title: t('title'),
                dataIndex: 'name',
                key: 'name',
                className: cn('title'),
                sorter: (a, b) => a.name.length - b.name.length,
                sortDirections: ['descend'],
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}
                        onClick={() => history.push(`/determinant/${record.determinant.id}/${record.id}`)}>
                        {text}
                    </Tooltip>
                )

            },
            {
                title: t('create date'),
                dataIndex: 'created_date',
                key: 'created_date',
                className: cn('createDate'),
                sorter: (a, b) => moment(a.created_date).unix() - moment(b.created_date).unix(),
                sortDirections: ['descend', 'ascend'],
                render: (text) => moment(text).format('DD.MM.YYYY')
            },
            {
                title: t('disease'),
                dataIndex: 'disease',
                key: 'disease',
                className: cn('disease'),
                render: (text) => (text && text.name)
            },
            {
                title: t('status'),
                dataIndex: 'disease',
                key: 'status',
                className: cn('status'),
                render: (text) => (
                    <>
                        {text && t('COMPLETED')}
                        {!text && t('IN_PROGRESS')}
                    </>
                )
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: cn('action'),
                render: (text, record) => (
                    <div className={cn('button')}>
                        <Popover
                            status={record.status}
                            type={record.determinant.id}
                            id={record.id}
                            placement="bottomRight"
                            url="determinant"
                            trigger="click"
                            deleteClick={() => this.showDeleteModal(record.id)} />
                    </div>
                )
            }
        ];
    }

    render() {
        const { cn } = this;
        const {
            dataSource,
            dataSize,
            t,
            loading,
            culture,
            history
        } = this.props;
        const { infoMessageVisible, page, page_size } = this.state;
        if (!dataSource.length && loading) {
            return (
                <div className={cn('empty')}>
                    <Spin />
                </div>
            );
        }
        if (!dataSource.length) {
            return (
                <div className={cn('empty')}>
                    {culture && (
                        <>
                            <div>{t('no')}</div>
                            <Button type="primary"
                                onClick={() => history.push(`/determinant/${culture}/`)}>
                                {t('create')}
                            </Button>
                        </>
                    )}
                    {!culture && t('no common')}
                </div>
            );
        }
        return (
            <div className={cn()}>
                <div className={cn()}>
                    <Table
                        dataSource={dataSource}
                        columns={this.requestsColumns}
                        pagination={false} />
                </div>
                <TableFooter
                    filterItems={this.filterItems}
                    showSizeChanger={false}
                    onSelect={this.onPageSizeChange}
                    onChange={this.onCurrentPageChange}
                    pageSize={page_size}
                    current={page}
                    rowDivider={ROW_DIVIDER.toString()}
                    dataSize={dataSize} />
                <InfoMessage
                    visible={infoMessageVisible}
                    title={t('delete title')}
                    okBtn={t('delete')}
                    cancelBtn={t('cancel')}
                    onOk={this.removeDeterminant}
                    onCancel={this.hideDeleteModal} />
            </div>
        );
    }
}