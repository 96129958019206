import { getConstant } from '../utils';

export const getSamplesList = getConstant('GET_SAMPLES_LIST');
export const getDownloadableDataset = getConstant('GET_DOWNLOADABLE_DATASET');
export const createSampleRow = getConstant('CREATE_SAMPLE_ROW');
export const deleteSampleRow = getConstant('DELETE_SAMPLE_ROW');
export const getSampleById = getConstant('GET_SAMPLE_BY_ID');
export const editSampleRow = getConstant('EDIT_SAMPLE_ROW');
export const uploadSampleFile = getConstant('UPLOAD_SAMPLE_FILE');
export const manageSampleRowPublication = getConstant('MANAGE_SAMPLE_PUBLICATION');
export const addOwnerSampleLog = getConstant('ADD_OWNER_SAMPLE_LOG');
export const getUsersList = getConstant('GET_USERS_SAMPLE_LOG');
export const getCardDefaults = getConstant('GET_CARD_DEFAULTS');
export const updateCardDefaults = getConstant('UPDATE_CARD_DEFAULTS');
export const createRowCopy = getConstant('COPY_ROW');