import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import * as actions from './actions';

function* getProtocolSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getProtocol.try,
        constants.getProtocol.success,
        constants.getProtocol.error
    );
    try {
        yield put(start());
        const { userId, values } = payload;
        const resultsValues = yield all(userId?.map(usrId => call(api.getProtocol, { userId: usrId, values })));
        yield put(success(resultsValues));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getProtocol.toString(),
            getProtocolSaga
        ),
        yield takeLatest(
            constants.getFedPoints.toString(),
            defaultSaga({
                constants: constants.getFedPoints,
                api: api.getFedPoints
            })
        ),
        yield takeLatest(
            constants.getAgroPoints.toString(),
            defaultSaga({
                constants: constants.getAgroPoints,
                api: api.getAgroPoints
            })
        )
    ]);
}