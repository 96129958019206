import * as constants from '../constants';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getRequest.success:
        case constants.addRequest.success: {
            return action.payload;
        }

        case constants.setStatusRequest.success: {
            const { status } = action.payload;
            return { ...state, status };
        }

        case constants.clearRequest.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
