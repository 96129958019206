import moment from 'moment';
import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    _server2Client = (item = {}) => ({
        id: item.id,
        createDate: item.createDate,
        updateDate: item.updateDate,
        fertilizerType: item.fertilizerType && item.fertilizerType.name,
        fertilizerTypeId: item.fertilizerType && item.fertilizerType.id,
        culture: item.culture && item.culture.name,
        cultureId: item.culture && item.culture.id,
        calculationMethod: item.calculationMethod && item.calculationMethod.name,
        calculationMethodId: item.calculationMethod && item.calculationMethod.id,
        status: item.status,
        name: item.name
    });

    list2Client = (items = []) => items.map(this._server2Client);

    calculatorsFullInfo2Client = (rawObject = {}) => {
        const {
            content,
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        } = rawObject;

        return {
            content: this.list2Client(content),
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        };
    };

    removedCalculatorId2Client = id => ({ removedCalculatorId: id });
}

export default new Converter();