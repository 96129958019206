import { register, userActivation } from '../constants';

export default function reducer(state = false, action) {
    switch (action.type) {
        case register.error:
        case userActivation.error: {
            return true;
        }
        case register.success:
        case userActivation.success: {
            return false;
        }
        default: return state;
    }
}
