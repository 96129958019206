import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import converter from './converter';
import * as api from './api';
import { setCurDistributionCalculate } from '../../reducers/view/actions';
import { getNutrition } from '../item-nutrition/actions';

const DISTRIBUTION_TYPE = {
    1: 'rb',
    2: 'normative',
    3: '',
    4: '',
    5: 'rb',
    6: 'normative',
    7: 'normative',
    8: 'rb',
    9: 'normative',
    10: 'rb',
    11: 'nutrition'
};

function* getCalculateSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getCalculate.try,
        constants.getCalculate.success,
        constants.getCalculate.error
    );
    try {
        yield put(start());
        const dataRaw = yield call(api.getCalculate, payload);
        const data = converter.toClient(dataRaw);
        if (typeof dataRaw.nutrition === 'number') {
            yield put(getNutrition(dataRaw.nutrition));
        }
        yield put(setCurDistributionCalculate(DISTRIBUTION_TYPE[data.calculator_type]));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* addCalculateSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addCalculate.try,
        constants.addCalculate.success,
        constants.addCalculate.error
    );
    try {
        const { history, values } = payload;
        yield put(start());
        const dataRaw = yield call(api.addCalculate, values);
        const data = converter.toClient(dataRaw);
        history.push(`/calculator/${data.calculator_type}/${data.id}`);
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculate.toString(),
            getCalculateSaga
        ),
        yield takeLatest(
            constants.removeCalculate.toString(),
            defaultSaga({
                constants: constants.removeCalculate,
                api: api.removeCalculate
            })
        ),
        yield takeLatest(
            constants.getCalculates.toString(),
            defaultSaga({
                constants: constants.getCalculates,
                api: api.getCalculates
            })
        ),
        yield takeLatest(
            constants.addCalculate.toString(),
            addCalculateSaga
        ),
        yield takeLatest(
            constants.updateCalculate.toString(),
            defaultSaga({
                constants: constants.updateCalculate,
                api: api.updateCalculate,
                after: converter.toClient
            })
        )
    ]);
}