import { useTranslation } from 'react-i18next';

import ListIcon from '../../../public/images/calculator/list2_stroke.svg';

export default () => {
    const { t } = useTranslation('scheme');
    return (
        {
            scheme: {
                RB: {
                    title: t('settlement balance'),
                    id: 1,
                    type: 'RB',
                    items: ['calculate', 'rb'],
                    icon: ListIcon
                },
                NORMATIVE: {
                    title: t('normative'),
                    id: 2,
                    type: 'NORMATIVE',
                    items: ['calculate', 'normative'],
                    icon: ListIcon
                },
                AGROCHEMICAL: {
                    title: t('interpretation'),
                    id: 3,
                    type: 'AGROCHEMICAL',
                    items: ['calculate', 'interpretation']
                },
                DISTRIBUTION: {
                    title: t('distribution'),
                    id: 4,
                    type: 'DISTRIBUTION',
                    items: ['calculate', 'distribution'],
                    icon: ListIcon
                },
                RB_DISTRIBUTION: {
                    title: t('settlement balance'),
                    id: 5,
                    type: 'RB_DISTRIBUTION',
                    items: ['calculate', 'rb', 'distribution'],
                    icon: ListIcon
                },
                NORMATIVE_DISTRIBUTION: {
                    title: t('normative'),
                    id: 6,
                    type: 'NORMATIVE_DISTRIBUTION',
                    items: ['calculate', 'normative', 'distribution'],
                    icon: ListIcon
                },
                AGROCHEMICAL_NORMATIVE_DISTRIBUTION: {
                    title: t('interpretation'),
                    id: 7,
                    type: 'AGROCHEMICAL_NORMATIVE_DISTRIBUTION',
                    items: ['calculate', 'interpretation', 'normative', 'distribution'],
                    icon: ListIcon
                },
                AGROCHEMICAL_RB_DISTRIBUTION: {
                    title: t('interpretation'),
                    id: 8,
                    type: 'AGROCHEMICAL_RB_DISTRIBUTION',
                    items: ['calculate', 'interpretation', 'rb', 'distribution'],
                    icon: ListIcon
                },
                AGROCHEMICAL_NORMATIVE: {
                    title: t('interpretation'),
                    id: 9,
                    type: 'AGROCHEMICAL_NORMATIVE',
                    items: ['calculate', 'interpretation', 'normative'],
                    icon: ListIcon
                },
                AGROCHEMICAL_RB: {
                    title: t('interpretation'),
                    id: 10,
                    type: 'AGROCHEMICAL_RB',
                    items: ['calculate', 'interpretation', 'rb'],
                    icon: ListIcon
                },
                NUTRITION_DISTRIBUTION: {
                    title: t('nutrition'),
                    id: 11,
                    type: 'NUTRITION_DISTRIBUTION',
                    items: ['calculate', 'nutrition', 'distribution'],
                    icon: ListIcon
                }
            }
        }
    );
};