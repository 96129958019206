export default {
    name: 'calculatorPhyto',
    serverName: '/api/v1/fito_calculator/fito_calculator/',
    apiUrl: '/api/v1/fito_calculator/fito_calculator/',
    deleteProtocolUrl: '/api/v1/fito_calculator/fito_protocol/',
    reproductionUrl: '/api/v1/fito_calculator/reproduction/',
    varietiesUrl: '/api/v1/culture/culture_sort/',
    culturesUrl: '/api/v1/culture/culture/',
    fertilizersApi: '/api/v1/fertilizer/fertilizer_trade_name/',
    regionsUrl: '/api/v1/region/region/',
    purposesUrl: '/api/v1/fito_calculator/seeding_purpose/'
};
