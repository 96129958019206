import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getReview.try:
        case constants.updateReview.try:
        case constants.addReview.try:
        case constants.getReviews.try:
        case constants.removeReview.try: {
            return true;
        }

        case constants.getReview.success:
        case constants.updateReview.success:
        case constants.addReview.success:
        case constants.getReviews.success:
        case constants.removeReview.success:
        case constants.getReview.error:
        case constants.updateReview.error:
        case constants.addReview.error:
        case constants.getReviews.error:
        case constants.removeReview.error: {
            return false;
        }

        default:
            return false;
    }
}