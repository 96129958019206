import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import '../style.less';

const UploadStatus = ({
    statusCode, statusText
}) => {
    return (
        <div className={statusCode === 200 ? 'upload-status__success' : 'upload-status__error'}>
            {statusCode === 200 && (
                <CheckCircleOutlined className="upload-status__success__logo" />
            )}
            {statusCode === 400 && (
                <CloseCircleOutlined className="upload-status__error__logo" />
            )}
            <div>{statusText}</div>
        </div>
    );
};

export default UploadStatus;