import * as constants from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getUsersList.success: {
            return action.payload.results;
        }

        default:
            return state;
    }
}
