import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Table } from 'antd';

import './style.less';

const Results = ({ chloroplastActivity, deficiteElements }) => {

    const [tableData, setTableData] = useState([]);
    const { t } = useTranslation('leaf diagnostics');

    useEffect(() => {
        if (chloroplastActivity < 0) {

            setTableData([{
                stress: `${chloroplastActivity} ${t('chloroplast stress negative')}`,
                productivity: t('chloroplast productivity negative'),
                recommended: t('chloroplast recommended negative'),
                deficit: deficiteElements.map(x => t(`table element ${x}`)).join(', ')
            }]);
        } else if (chloroplastActivity >= 0 && chloroplastActivity <= 10) {

            setTableData([{
                stress: `${chloroplastActivity} ${t('chloroplast stress 0-10')}`,
                productivity: t('chloroplast productivity 0-10'),
                recommended: t('chloroplast recommended 0-10'),
                deficit: deficiteElements.map(x => t(`table element ${x}`)).join(', ')
            }]);
        } else if (chloroplastActivity >= 11 && chloroplastActivity <= 70) {

            setTableData([{
                stress: `${chloroplastActivity} ${t('chloroplast stress 11-70')}`,
                productivity: t('chloroplast productivity 11-70'),
                recommended: t('chloroplast recommended 11-70'),
                deficit: deficiteElements.map(x => t(`table element ${x}`)).join(', ')
            }]);
        } else if (chloroplastActivity > 70) {

            setTableData([{
                stress: `${chloroplastActivity} ${t('chloroplast stress more 70')}`,
                productivity: t('chloroplast productivity more 70'),
                recommended: t('chloroplast recommended more 70'),
                deficit: deficiteElements.map(x => t(`table element ${x}`)).join(', ')
            }]);
        }
    }, [chloroplastActivity]);

    const columns = [
        {
            title: t('table stress header'),
            dataIndex: 'stress',
            key: 'stress'
        },
        {
            title: t('table productivity header'),
            dataIndex: 'productivity',
            key: 'productivity'
        },
        {
            title: t('table recommended header'),
            dataIndex: 'recommended',
            key: 'recommended'
        },
        {
            title: t('table deficit header'),
            dataIndex: 'deficit',
            key: 'deficit'
        }
    ];

    return (
        <Table
            rowKey="stress"
            className="diagnostics__results"
            pagination={false}
            columns={columns}
            dataSource={tableData} />
    );
};

export default Results;