import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import converter from './converter';
import * as api from './api';
import * as actions from './actions';


function* getFertilizerSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getFertilizer.try,
        constants.getFertilizer.success,
        constants.getFertilizer.error
    );
    try {
        yield put(start());
        const data = yield call(api.getFertilizer, payload);
        const { id } = data;
        const main_drug = yield call(api.getFertilizersToMainDrug, `?filter={"fertilizer":${id}}`);
        data.main_drug = main_drug.results
            && main_drug.results[0]
            && main_drug.results[0].main_drug
            && (main_drug.results[0].main_drug.name || '');
        const synonyms = yield call(api.getFertilizersMainDrugSynonyms, `?filter={"main_drug":${main_drug.results[0].main_drug.id}}`);
        data.synonyms = synonyms.results || [];
        const registration = yield call(api.getFertilizersToRegistration, `?filter={"fertilizer":${id}}`);
        data.registration = registration.results
            && registration.results[0]
            && registration.results[0].registrant
            && (registration.results[0].registrant.name || '');
        const seller = yield call(api.getFertilizersToSeller, `?filter={"fertilizer":${id}}`);
        data.seller = seller.results || [];
        const obj = converter.fertilizer2Client(data);
        yield put(success(obj));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getFertilizers.toString(),
            defaultSaga({
                constants: constants.getFertilizers,
                api: api.getFertilizers
            })
        ),
        yield takeLatest(
            constants.getFertilizer.toString(),
            getFertilizerSaga
        ),
        yield takeLatest(
            constants.getFertilizersGroup.toString(),
            defaultSaga({
                constants: constants.getFertilizersGroup,
                api: api.getFertilizersGroup
            })
        ),
        yield takeLatest(
            constants.getFertilizersGroupItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersGroupItem,
                api: api.getFertilizersGroupItem
            })
        ),
        yield takeLatest(
            constants.getFertilizersMainDrug.toString(),
            defaultSaga({
                constants: constants.getFertilizersMainDrug,
                api: api.getFertilizersMainDrug
            })
        ),
        yield takeLatest(
            constants.getFertilizersMainDrugItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersMainDrugItem,
                api: api.getFertilizersMainDrugItem
            })
        ),
        yield takeLatest(
            constants.getFertilizersReleaseForm.toString(),
            defaultSaga({
                constants: constants.getFertilizersReleaseForm,
                api: api.getFertilizersReleaseForm
            })
        ),
        yield takeLatest(
            constants.getFertilizersReleaseFormItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersReleaseFormItem,
                api: api.getFertilizersReleaseFormItem
            })
        ),
        yield takeLatest(
            constants.getFertilizersSubGroup.toString(),
            defaultSaga({
                constants: constants.getFertilizersSubGroup,
                api: api.getFertilizersSubGroup
            })
        ),
        yield takeLatest(
            constants.getFertilizersSubGroupItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersSubGroupItem,
                api: api.getFertilizersSubGroupItem
            })
        ),
        yield takeLatest(
            constants.getFertilizersToMainDrug.toString(),
            defaultSaga({
                constants: constants.getFertilizersToMainDrug,
                api: api.getFertilizersToMainDrug
            })
        ),
        yield takeLatest(
            constants.getFertilizersToMainDrugItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersToMainDrugItem,
                api: api.getFertilizersToMainDrugItem
            })
        ),
        yield takeLatest(
            constants.getFertilizersToRegistration.toString(),
            defaultSaga({
                constants: constants.getFertilizersToRegistration,
                api: api.getFertilizersToRegistration
            })
        ),
        yield takeLatest(
            constants.getFertilizersToRegistrationItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersToRegistrationItem,
                api: api.getFertilizersToRegistrationItem
            })
        ),
        yield takeLatest(
            constants.getFertilizersToSeller.toString(),
            defaultSaga({
                constants: constants.getFertilizersToSeller,
                api: api.getFertilizersToSeller
            })
        ),
        yield takeLatest(
            constants.getFertilizersToSellerItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersToSellerItem,
                api: api.getFertilizersToSellerItem
            })
        ),
        yield takeLatest(
            constants.getFertilizersType.toString(),
            defaultSaga({
                constants: constants.getFertilizersType,
                api: api.getFertilizersType
            })
        ),
        yield takeLatest(
            constants.getFertilizersTypeItem.toString(),
            defaultSaga({
                constants: constants.getFertilizersTypeItem,
                api: api.getFertilizersTypeItem
            })
        )
    ]);
}