import * as constants from './constants';

export const getCalculatorsSoilComposition = payload => ({
    type: constants.getCalculatorsSoilComposition.toString(),
    payload
});

export const getCalculatorsSoilCompositionItem = payload => ({
    type: constants.getCalculatorsSoilCompositionItem.toString(),
    payload
});