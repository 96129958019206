import * as constants from './constants';

export const getAgroEvents = payload => ({
    type: constants.getAgroEvents.toString(),
    payload
});

export const updateAgroEvent = payload => ({
    type: constants.updateAgroEvent.toString(),
    payload
});