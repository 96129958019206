import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

// import { useClassName } from 'utils/cn';

import '../style.less';
import { Table } from 'antd';
import moment from 'moment';
import { curGeneralProtocol } from '../../../models/general-protocol/selectors';
import { useSelector } from 'react-redux';

const Agrochemical = ({
    calculatorData, selectedAgros, setSelectedAgros, ownerName, usersList
}) => {
    // const cn = useClassName('calculators');
    const { t } = useTranslation('general protocol');

    const generalProto = useSelector(state => curGeneralProtocol(state));

    const columns = [
        {
            title: t('header num'),
            dataIndex: 'num',
            key: 'num'
        },
        {
            title: t('header vega_key'),
            dataIndex: 'vega_key',
            key: 'vega_key'
        },
        {
            title: t('header owner'),
            dataIndex: 'owner',
            key: 'owner',
            render: (value) => usersList?.filter(x => x.id === value)[0]?.name
        },
        {
            title: t('header acceptation_date'),
            dataIndex: 'date',
            key: 'date',
            render: value => value ? moment(value).format('DD.MM.YYYY') : '-'
        },
        {
            title: t('header examination_date'),
            dataIndex: 'analysis_date',
            key: 'analysis_date',
            render: value => value ? moment(value).format('DD.MM.YYYY') : '-'
        }
    ];

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedAgros(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys: selectedAgros?.map(item => item.id),
        onChange: onSelectChange
    };

    return (
        <div>
            {(calculatorData?.length > 0) ? (
                <Table
                    rowSelection={rowSelection}
                    rowKey="id"
                    columns={columns}
                    dataSource={calculatorData} />
            ) : (
                <div>{t('no protocols')}</div>
            )}
        </div>
    );
};

export default Agrochemical;