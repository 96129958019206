import moment from 'moment';

export const makeRequest = (values) => {
    return {
        dist_water_volume: values?.dist_water_volume,
        air_weigth: values?.air_weigth,
        dilution_factor: values?.dilution_factor,
        field_num: values?.field_num,
        vega_key: values?.vega_key,
        date: values?.date?.format('YYYY-MM-DD'),
        analysis_date: values?.analysis_date?.format('YYYY-MM-DD'),
        num: values?.num,
        point: values?.point,
        K: values?.K,
        A: values?.A,
        method: values?.method,
        ph_water: values?.ph_water,
        ph_salt: values?.ph_salt,
        electrical_conductivity: values?.electrical_conductivity,
        organic_substances: values?.organic_substances,
        comment: values?.comment,
        deleted: values?.deleted,
        owner: values?.owner,
        productivity_zone: values?.productivity_zone,
        zone_area: values?.zone_area
    };
};

export const makeResponse = (values) => {
    return {
        id: values?.id,
        field_num: values?.field_num,
        vega_key: values?.vega_key,
        date: values?.date ? moment(values?.date) : undefined,
        analysis_date: values?.analysis_date ? moment(values?.analysis_date) : undefined,
        num: values?.num,
        point: values?.point,
        K: values?.K,
        A: values?.A,
        method: values?.method,
        NH4: values?.NH4,
        K20: values?.K20,
        Na: values?.Na,
        Mg: values?.Mg,
        Ca: values?.Ca,
        Cl: values?.Cl,
        SO4: values?.SO4,
        NH4_result: values?.NH4_result,
        K20_result: values?.K20_result,
        Na_result: values?.Na_result,
        Mg_result: values?.Mg_result,
        Ca_result: values?.Ca_result,
        Cl_result: values?.Cl_result,
        SO4_result: values?.SO4_result,
        S: values?.S,
        N: values?.N,
        N_result: values?.N_result,
        NO3: values?.NO3,
        PO4: values?.PO4,
        PO4_result: values?.PO4_result,
        P2O5: values?.P2O5,
        ph_water: values?.ph_water,
        ph_salt: values?.ph_salt,
        electrical_conductivity: values?.electrical_conductivity,
        organic_substances: values?.organic_substances,
        dist_water_volume: values?.dist_water_volume,
        air_weigth: values?.air_weigth,
        dilution_factor: values?.dilution_factor,
        comment: values?.comment,
        deleted: values?.deleted,
        owner: values?.owner,
        productivity_zone: values?.productivity_zone,
        zone_area: values?.zone_area
    };
};