import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';

import {
    Row, Col, Input, InputNumber, Form, Select
} from 'antd';

import { useClassName } from 'utils/cn';
import {
    Button, Table, TableFooter, Tooltip, CatalogTitle
} from '../../../../components';

import { selectors, actions } from '../../../../models';

const {
    getFertilizers,
    getFertilizersGroup,
    getFertilizersSubGroup,
    getFertilizersType,
    getFertilizer,
    getFertilizersReleaseForm
} = actions;
const {
    getFertilizersList,
    getFertilizersListCount,
    getFertilizerGroupList,
    getFertilizerSubGroupList,
    getFertilizerReleaseFormList
} = selectors;

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_FERTILIZER = { 1: 'n', 2: 'p', 3: 'k' };

export default ({
    selectedFertilizers, onCancel, setFertilizers, showInfo
}) => {
    const fertilizersList = useSelector(state => getFertilizersList(state));
    const fertilizersCount = useSelector(state => getFertilizersListCount(state));
    const groups = useSelector(state => getFertilizerGroupList(state));
    const subgroups = useSelector(state => getFertilizerSubGroupList(state));
    const release_form = useSelector(state => getFertilizerReleaseFormList(state));
    const dispatch = useDispatch();

    const cn = useClassName('calculator-detail-agro-fertilizer');
    const { t } = useTranslation('calculator fertilizer');
    const match = useRouteMatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [page_size, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [isSetFilter, setFilter] = useState(false);
    const [activeFertilizer, setActiveFertilizer] = useState(null);
    const [filterItems, setFilterItems] = useState([]);
    const [dataList, setFertilizersList] = useState([]);

    useEffect(() => {
        dispatch(getFertilizersGroup(''));
        dispatch(getFertilizersType(''));
        dispatch(getFertilizersReleaseForm('?sort=["name","DESC"]'));
        dispatch(getFertilizers(`?sort=["content_n","ASC"]&sort=["content_p","ASC"]&sort=["content_k","ASC"]&${page_size}&${page}`));
    }, []);

    useEffect(() => {
        const items = [];
        for (let i = 1; i < fertilizersCount; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        setFilterItems([...items]);
    }, [fertilizersCount]);

    useEffect(() => {
        setFertilizersList([...fertilizersList]);
    }, [fertilizersList]);


    const getFilteredFertilizer = () => {
        const values = form.getFieldsValue();
        const urlArr = [];
        const content_type = { 2: 'content_k', 7: 'content_p' };
        urlArr.push('"is_in_state_registry":true');
        values.price_from ? urlArr.push(`"related_sellers.price.gte":${values.price_from}`) : '';
        values.price_to ? urlArr.push(`"related_sellers.price.lte":"${values.price_to}"`) : '';
        values.name ? urlArr.push(`"name.icontains":"${values.name}"`) : '';
        values.main_drug ? urlArr.push(`"related_main_drugs.main_drug.name.icontains":"${values.main_drug}"`) : '';
        values.release_form ? urlArr.push(`"release_form":${values.release_form}`) : '';
        values.group ? urlArr.push(`"subgroup.group.id":${values.group}`) : '';
        values.subgroup ? urlArr.push(`"subgroup.id":${values.subgroup}`) : '';
        values.fertilizer_type ? urlArr.push(`"fertilizer_type.id":${values.fertilizer_type}`) : '';
        const contentName = (values.group === 2 || values.group === 7) ? content_type[values.group] : 'content_n';
        const sort = `sort=["${contentName}","ASC"]`;
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        dispatch(getFertilizers(`?${page_size_url}&${page_url}&${sort}&filter={${urlArr.join(',')}}`));
    };

    useEffect(() => {
        getFilteredFertilizer();
    }, [page, page_size]);


    const onValuesChange = (value, values) => {
        const key = Object.keys(value)[0];
        !isSetFilter && setFilter(true);
        if (key === 'group') {
            dispatch(getFertilizersSubGroup(`?filter={"group.id":${value[key]}}`));
            form.setFieldsValue({ subgroup: '' });
            setActiveFertilizer(null);
        }
        getFilteredFertilizer();
    };

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();
        inputName = inputName.toLowerCase();
        return optionName.indexOf(inputName) !== -1;
    };

    const onChange = id => {
        const indexOfEl = selectedFertilizers.findIndex(item => item.id === id);
        const curFertilizer = fertilizersList.find(item => item.id === id);
        if (indexOfEl > -1) {
            setActiveFertilizer(false);
        } else {
            selectedFertilizers.push(curFertilizer);
            setActiveFertilizer(curFertilizer);
            setFertilizers(selectedFertilizers);
            onCancel();
        }
    };

    const requestsColumns = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            className: cn('name'),
            render: (text, record) => (
                <Tooltip
                    placement="bottomLeft"
                    title={text}>
                    <div onClick={() => onChange(record.id)} className={cn('link')}>{text}</div>
                </Tooltip>
            )

        },
        {
            title: t('avg_price'),
            dataIndex: 'avg_price',
            key: 'avg_price',
            width: 140,
            render: (value, record) => (
                value ? t('price unit', { value }) : '-'
            )
        },
        {
            title: t('N'),
            dataIndex: 'content_n',
            key: 'content_n',
            className: cn('content-main'),
            sortType: 'number',
            width: 100,
            render: (value, record) => (
                value ? t('content unit', { value }) : t('no')
            )
        },
        {
            title: t('P'),
            dataIndex: 'content_p',
            key: 'content_p',
            className: cn('content-main'),
            sortType: 'number',
            width: 100,
            render: (value, record) => (
                value ? t('content unit', { value }) : t('no')
            )
        },
        {
            title: t('K'),
            dataIndex: 'content_k',
            key: 'content_k',
            className: cn('content-main'),
            sortType: 'number',
            width: 100,
            render: (value, record) => (
                value ? t('content unit', { value }) : t('no')
            )
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            className: cn('action'),
            render: (text, record) => (
                <div className={cn('button')}>
                    <QuestionCircleOutlined onClick={() => showFertilizerInfo(record.id)} />
                </div>
            )
        }
    ];

    const onCurrentPageChange = (page) => {
        setPage(page);
    };

    const onPageSizeChange = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const showFertilizerInfo = (fertilizerId) => {
        showInfo(fertilizerId);
    };

    return (
        <div>
            <Form
                form={form}
                onValuesChange={onValuesChange}
                name="calculator-fertilizer">

                <Row gutter={20} style={{ marginBottom: 30 }}>
                    <Col span={6}>
                        <Row gutter={20}>
                            <Col span={12} className="field">
                                <Form.Item name="price_from" label={t('price from')}>
                                    <InputNumber min={0} placeholder={t('price from placeholder')} />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="field">
                                <Form.Item name="price_to" label={t('price to')}>
                                    <InputNumber min={0} placeholder={t('price to placeholder')} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className="field">
                        <Form.Item name="name" label={t('search')}>
                            <Input placeholder={t('search')} suffix={<SearchOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20} style={{ marginBottom: '20px' }}>
                    <Col span={6} className="field">
                        <Form.Item label={t('group fertilizer')} name="group">
                            <Select
                                allowClear
                                showSearch
                                filterOption={filterOption}>
                                {groups.map(item => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="field">
                        <Form.Item label={t('subgroup fertilizer')} name="subgroup">
                            <Select
                                allowClear
                                showSearch
                                filterOption={filterOption}>
                                {subgroups.map(item => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="field">
                        <Form.Item label={t('release form fertilizer')} name="release_form">
                            <Select

                                allowClear
                                showSearch
                                filterOption={filterOption}>
                                {release_form.map(item => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="field">
                        <Form.Item label={t('main drug fertilizer')} name="main_drug">
                            <Input placeholder={t('search main drug')} suffix={<SearchOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>


            <Row>
                <Col>
                    <span className={cn('find-count')}>
                        {t('find', { value: fertilizersCount })}
                    </span>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <div className={cn('radio-wrap')}>
                        <Table
                            dataSource={dataList}
                            columns={requestsColumns}
                            pagination={false} />
                        {fertilizersCount > 1 && (
                            <TableFooter
                                showSizeChanger={false}
                                onSelect={onPageSizeChange}
                                onChange={onCurrentPageChange}
                                pageSize={page_size}
                                current={page}
                                filterItems={filterItems}
                                rowDivider={ROW_DIVIDER.toString()}
                                dataSize={fertilizersCount} />
                        )}
                    </div>
                </Col>
            </Row>

        </div>
    );
};