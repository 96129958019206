import * as constants from './constants';

export const getCarouselData = payload => ({
    type: constants.getCarouselData.toString(),
    payload
});

export const getCarouselCultures = payload => ({
    type: constants.getCarouselCultures.toString(),
    payload
});

export const getCarouselSymptoms = payload => ({
    type: constants.getCarouselSymptoms.toString(),
    payload
});