import { setCurDistributionCalculate } from './constants';

const initialState = {
    distributionType: '',
    calculatorId: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case setCurDistributionCalculate:
        {
            return {
                ...state,
                distributionType: action.payload.type,
                calculatorId: action.payload.calculator
            };
        }

        default: return state;
    }
}
