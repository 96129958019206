export const FLUID_APPLICATION_TYPE = "Опрыскивание в период вегетации";

export const REGION_COEFF_NORM = {
    // Республика Татарстан
    TATARSTAN: {
        // Ячмень яровой
        4: {
            // чернозем
            BLACK_EARTH: {
                N: 28,
                P: 26,
                K: 25
            },
            // не чернозем
            NO_BLACK_EARTH: {
                N: 31,
                P: 32,
                K: 27
            }
        },
        // Озимая пшеница
        3: {
            // чернозем
            BLACK_EARTH: {
                N: 22,
                P: 22,
                K: 24
            },
            // не чернозем
            NO_BLACK_EARTH: {
                N: 31,
                P: 29,
                K: 25
            }
        }
    },
    // Самарская область
    SAMARA: {
        // Ячмень яровой
        4: {
            N: 45,
            P: 20,
            K: 10
        },
        // Озимая пшеница
        3: {
            N: 23,
            P: 23,
            K: 10
        }
    }
};

export const PREDESSOR_COEFF_NORM = {
    WINTER: {
        N: 1,
        P: 1,
        K: 1
    },
    ANNUAL: {
        N: 1.1,
        P: 1.0,
        K: 1.1
    },
    PERENNIAL: {
        N: 1.2,
        P: 1.1,
        K: 1.2
    },
    OTHER: {
        N: 1.5,
        P: 1.5,
        K: 1.5
    }
};

export const ACIDITY_COEFF_NORM = {
    FIRST: {
        N: 1,
        P: 1.3,
        K: 1.2
    },
    SECOND: {
        N: 1,
        P: 1.2,
        K: 1.1
    },
    THIRD: {
        N: 1,
        P: 1,
        K: 1
    }
};

export const COMPOSITION_COEFF_NORM = {
    CLAYEY: {
        N: 0.9,
        P: 1.1,
        K: 0.8
    },
    MEDIUM_LOAMY: {
        N: 1,
        P: 1,
        K: 1
    },
    SANDY: {
        N: 1,
        P: 1,
        K: 1.2
    }
};

export const CULTURE_COEFF_N = {
    4: 2.5, // Ячмень яровой
    3: 3.3 // Озимая пшеница
};

export const HYDRATION_COEFF_N = {
    NO_HYDRATION: {
        CLEAN_FALLOW: 0.25,
        FULL_FALLOW: 0.025,
        SPRING: 0.05,
        POTATO: 0.025
    },
    EASY_HYDRATION: {
        CLEAN_FALLOW: 0.325,
        FULL_FALLOW: 0.125,
        SPRING: 0.15,
        POTATO: 0.125
    },
    MODERATE_HYDRATION: {
        CLEAN_FALLOW: 0.375,
        FULL_FALLOW: 0.275,
        SPRING: 0.25,
        POTATO: 0.275
    },
    HEAVY_RAINFALL: {
        CLEAN_FALLOW: 0.45,
        FULL_FALLOW: 0.375,
        SPRING: 0.3,
        POTATO: 0.45
    }
};
export const YEAR_COEFF_N = {
    FIRST_YEAR: 0.6,
    SECOND_YEAR: 0.05,
    THIRD_YEAR: 0.05
};

export const DEPTH_COEFF_N = {
    22: {
        LOAMY: 26,
        SABULOUS: 28,
        SANDY: 30
    },
    25: {
        LOAMY: 30,
        SABULOUS: 32,
        SANDY: 35
    },
    28: {
        LOAMY: 34,
        SABULOUS: 36,
        SANDY: 39
    },
    30: {
        LOAMY: 36,
        SABULOUS: 39,
        SANDY: 42
    }
};

export const CULTURE_COEFF_P = {
    4: 1.1, // Ячмень яровой
    3: 1.2 // Озимая пшеница
};

export const SOIL_DEPTH_COEFF_P = {
    5: 0.1,
    10: 0.08,
    15: 0.07,
    20: 0.06,
    21: 0.05
};

export const METHOD_COEFF_P = {
    // ячмень яровой
    4: {
        5: {
            KIRSANOV: 0.1,
            CHIRIKOV: 0.1,
            MACHIGIN: 0.15
        },
        10: {
            KIRSANOV: 0.08,
            CHIRIKOV: 0.08,
            MACHIGIN: 0.12
        },
        15: {
            KIRSANOV: 0.07,
            CHIRIKOV: 0.07,
            MACHIGIN: 0.1
        },
        20: {
            KIRSANOV: 0.06,
            CHIRIKOV: 0.06,
            MACHIGIN: 0.08
        },
        21: {
            KIRSANOV: 0.05,
            CHIRIKOV: 0.05,
            MACHIGIN: 0.05
        }
    },
    // озимая пщеница
    3: {
        5: {
            KIRSANOV: 0.12,
            CHIRIKOV: 0.12,
            MACHIGIN: 0.15
        },
        10: {
            KIRSANOV: 0.11,
            CHIRIKOV: 0.11,
            MACHIGIN: 0.12
        },
        15: {
            KIRSANOV: 0.09,
            CHIRIKOV: 0.09,
            MACHIGIN: 0.1
        },
        20: {
            KIRSANOV: 0.08,
            CHIRIKOV: 0.08,
            MACHIGIN: 0.08
        },
        21: {
            KIRSANOV: 0.05,
            CHIRIKOV: 0.05,
            MACHIGIN: 0.05
        }
    }
};

export const YEAR_COEFF_P = {
    FIRST_YEAR: 0.225,
    SECOND_YEAR: -0.125,
    THIRD_YEAR: 0.05
};

export const DEPTH_COEFF_P = {
    22: {
        LOAMY: 26,
        SABULOUS: 28,
        SANDY: 30
    },
    25: {
        LOAMY: 30,
        SABULOUS: 32,
        SANDY: 35
    },
    28: {
        LOAMY: 34,
        SABULOUS: 36,
        SANDY: 39
    },
    30: {
        LOAMY: 36,
        SABULOUS: 39,
        SANDY: 42
    }
};

export const CULTURE_COEFF_K = {
    4: 1.8, // Ячмень яровой
    3: 2 // Озимая пшеница
};

export const METHOD_COEFF_K = {
    KIRSANOV: {
        3: {
            '< 8': 0.15,
            '8-12': 0.13,
            '13-17': 0.12,
            '18-24': 0.10,
            '> 24': 0.07
        },
        4: {
            '< 8': 0.12,
            '8-12': 0.11,
            '13-17': 0.09,
            '18-24': 0.08,
            '> 24': 0.06
        }
    },
    CHIRIKOV: {
        3: {
            '< 3': 0.30,
            '3-5': 0.25,
            '6-8': 0.20,
            '9-12': 0.15,
            '13-18': 0.13,
            '> 18': 0.10
        },
        4: {
            '< 3': 0.25,
            '3-5': 0.20,
            '6-8': 0.15,
            '9-12': 0.10,
            '13-18': 0.08,
            '> 18': 0.05
        }
    },
    MACHIGIN: {
        3: {
            '< 10': 0.13,
            '10-20': 0.12,
            '20-30': 0.10,
            '30-40': 0.07,
            '> 40': 0.05
        },
        4: {
            '< 10': 0.12,
            '10-20': 0.11,
            '20-30': 0.10,
            '30-40': 0.06,
            '> 40': 0.05
        }
    }
};
export const YEAR_COEFF_K = {
    FIRST_YEAR: 0.6,
    SECOND_YEAR: -0.2,
    THIRD_YEAR: 0
};

export const DEPTH_COEFF_K = {
    22: {
        LOAMY: 26,
        SABULOUS: 28,
        SANDY: 30
    },
    25: {
        LOAMY: 30,
        SABULOUS: 32,
        SANDY: 35
    },
    28: {
        LOAMY: 34,
        SABULOUS: 36,
        SANDY: 39
    },
    30: {
        LOAMY: 36,
        SABULOUS: 39,
        SANDY: 42
    }
};


export const seasonDataWinter = {
    1: {
        abcd: {
            autumnValue: 50,
            springValue: 30,
            firstValue: [5, 20],
            secondValue: [5, 20]
        },
        abc: {
            autumnValue: [40, 50],
            springValue: [30, 40],
            firstValue: [10, 20]
        },
        abd: {
            autumnValue: [40, 50],
            springValue: [30, 40],
            secondValue: [10, 20]
        },
        ab: {
            autumnValue: [50, 70],
            springValue: [30, 50]
        },
        a: {
            autumnValue: [80, 100]
        }
    },
    2: {
        ab: {
            autumnValue: [80, 100],
            springValue: [10, 20]
        },
        a: {
            autumnValue: [90, 100]
        }
    },
    3: {
        a: {
            autumnValue: [40, 80]
        }
    }
};
export const seasonDataSpring = {
    1: {
        abcd: {
            autumnValue: 40,
            springValue: 40,
            firstValue: [5, 15],
            secondValue: [5, 15]
        },
        bcd: {
            springValue: [60, 70],
            firstValue: [10, 30],
            secondValue: [10, 20]
        },
        bc: {
            springValue: [60, 80],
            firstValue: [10, 40]
        },
        bd: {
            springValue: [60, 80],
            secondValue: [10, 40]
        },
        b: {
            springValue: [80, 100]
        }
    },
    2: {
        ab: {
            autumnValue: [80, 90],
            springValue: [10, 20]
        },
        a: {
            autumnValue: [90, 100]
        }
    },
    3: {
        a: {
            autumnValue: [40, 80]
        }
    }
};

export const maskValues = {
    1: {
        a: 'autumn', b: 'spring', c: 'first', d: 'second'
    },
    2: {
        a: 'autumn', b: 'spring'
    },
    3: {
        a: 'autumn'
    }
};
export const maskArray = {
    1: {
        springArray: ['abcd', 'bcd', 'bc', 'bd', 'b'],
        winterArray: ['abcd', 'abc', 'abd', 'ab', 'a']
    },
    2: {
        springArray: ['ab', 'a'],
        winterArray: ['ab', 'a']
    },
    3: {
        springArray: ['a'],
        winterArray: ['a']
    }
};

export const maskArrayNorm = {
    1: {
        springArray: ['abcd', 'bcd', 'bc', 'b'],
        winterArray: ['abcd', 'abc', 'ab', 'a']
    },
    2: {
        springArray: ['ab', 'a'],
        winterArray: ['ab', 'a']
    },
    3: {
        springArray: ['a'],
        winterArray: ['a']
    }
};

export const FLUID_COLOR_COEFF = {
    noContent: [0.23, 9.11],
    orange: {
        1: 0.23,
        1.5: 0.28,
        2: 0.32,
        2.5: 0.36,
        3: 0.39,
        3.5: 0.42,
        4: 0.45,
        5: 0.51,
        6: 0.55,
        7: 0.60,
        8: 0.64
    },
    green: {
        1: 0.34,
        1.5: 0.42,
        2: 0.48,
        2.5: 0.54,
        3: 0.59,
        3.5: 0.63,
        4: 0.68,
        5: 0.76,
        6: 0.83,
        7: 0.90,
        8: 0.96
    },
    yellow: {
        1: 0.46,
        1.5: 0.56,
        2: 0.65,
        2.5: 0.73,
        3: 0.80,
        3.5: 0.86,
        4: 0.92,
        5: 1.03,
        6: 1.13,
        7: 1.22,
        8: 1.30
    },
    lilac: {
        1: 0.57,
        1.5: 0.70,
        2: 0.81,
        2.5: 0.91,
        3: 0.99,
        4: 1.15,
        5: 1.28,
        6: 1.40,
        7: 1.52,
        8: 1.62
    },
    darkBlue: {
        1: 0.69,
        1.5: 0.84,
        2: 0.97,
        2.5: 1.08,
        3: 1.19,
        4: 1.37,
        5: 1.53,
        6: 1.68,
        7: 1.81,
        8: 1.94
    },
    red: {
        1: 0.91,
        1.5: 1.12,
        2: 1.29,
        2.5: 1.44,
        3: 1.58,
        4: 1.82,
        5: 2.04,
        6: 2.23,
        7: 2.41,
        8: 2.58
    },
    brown: {
        1: 1.14,
        1.5: 1.39,
        2: 1.61,
        2.5: 1.80,
        3: 1.97,
        4: 2.28,
        5: 2.55,
        6: 2.79,
        7: 3.01,
        8: 3.22
    },
    gray: {
        1: 1.36,
        1.5: 1.67,
        2: 1.93,
        2.5: 2.16,
        3: 2.36,
        4: 2.73,
        5: 3.05,
        6: 3.34,
        7: 3.61,
        8: 3.86
    },
    white: {
        1: 1.82,
        1.5: 2.24,
        2: 2.58,
        2.5: 2.88,
        3: 3.16,
        4: 3.64,
        5: 4.08,
        6: 4.46,
        7: 4.82,
        8: 5.16
    },
    blue: {
        1: 2.28,
        1.5: 2.78,
        2: 3.22,
        2.5: 3.60,
        3: 3.94,
        4: 4.56,
        5: 5.10,
        6: 5.58,
        7: 6.02,
        8: 6.44
    },
    lightGreen: {
        1: 3.42,
        1.5: 4.19,
        2: 4.83,
        2.5: 5.40,
        3: 5.92,
        4: 6.84,
        5: 7.64,
        6: 8.37
    },
    black: {
        1: 4.56,
        1.5: 5.58,
        2: 6.44,
        2.5: 7.30,
        3: 7.89,
        4: 9.11
    }
};

export const economyTypes = ['economyTypeS', 'economyTypeL', 'economyTypeLS'];
export const macroTypes = [
    'contentN',
    'contentP',
    'contentK',
    'contentCa',
    'contentMg',
    'contentS',
    'contentFe',
    'contentAg',
    'contentB',
    'contentMo',
    'contentMn',
    'contentCu',
    'contentZn',
    'contentCo',
    'contentI',
    'contentI'
];
