import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Radio
} from 'antd';

import cn from '../../../utils/cn';
import {
    Button, InfoMessage
} from '../../../components';

import { selectors, actions } from '../../../models';

import * as constants from '../../../constants';

const {
    setAnswer,
    getQuestion,
    resetAnswer,
    getQuestions
} = actions;
const {
    getCurrentDeterminant,
    getCurrentQuestion,
    getCurrentQuestions
} = selectors;

const mstp = (state) => ({
    determinant: getCurrentDeterminant(state),
    question: getCurrentQuestion(state),
    questions: getCurrentQuestions(state)
});
const mdtp = ({
    setAnswer,
    getQuestion,
    resetAnswer,
    getQuestions
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('determinant detail')
@cn('determinant-detail-question')
export default class DeterminantQuestions extends Component {
    state = {
        visibleAborted: false,
        visibleChange: false,
        value: -1,
        currentQuestionId: 1,
        currentQuestion: {}
    };

    componentDidMount() {
        const {
            determinant, questions, question
        } = this.props;
        if (determinant.disease) {
            this.setState({
                currentQuestionId: -1, value: -1
            });
        } else {
            if (question.number) {
                this.setState({
                    currentQuestionId: questions.length + 1,
                    value: -1,
                    currentQuestion: question
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            question, onTabChange, determinant, match, questions
        } = this.props;
        const { id, type: paramsType } = match.params;
        const { type } = question;
        if (type === 'disease' && prevProps.question.type !== type) {
            this.setState({ currentQuestionId: -1 });
            onTabChange('1');
        }
        if ((prevProps.determinant.id !== determinant.id)
            || (prevProps.match.params.id !== id && !id)
            || (prevProps.match.params.type !== paramsType)) {
            this.setState({
                currentQuestionId: questions.length + 1,
                currentQuestion: question,
                value: -1
            });
        }
    }

    hideModal = () => {
        this.setState({ visibleChange: false, visibleAborted: false });
    };

    showAbortedModal = () => {
        this.setState({ visibleAborted: true });
    };

    showChangeModal = () => {
        this.setState({ visibleChange: true });
    };

    showNext = () => {
        const {
            determinant, questions, setAnswer, getQuestion, match
        } = this.props;
        const { id } = match.params;
        const { disease } = determinant;
        const { currentQuestionId, value } = this.state;
        if (disease) {
            const question = questions.find(
                item =>
                    Number(item.index) === Number(currentQuestionId + 1)
            );
            this.setState({
                currentQuestionId: currentQuestionId + 1,
                value: question.answer,
                currentQuestion: question
            });
        } else {
            if (currentQuestionId === questions.length) {
                if (Number(value) !== Number(questions[currentQuestionId - 1].answer)) {
                    this.setState({ visibleChange: true });
                } else {
                    this.setState({
                        currentQuestion: undefined,
                        currentQuestionId: currentQuestionId + 1,
                        value: -1
                    });
                    getQuestion(id);
                }
            }
            if (currentQuestionId > questions.length) {
                setAnswer({ id, values: { answer: value } });
                this.setState({
                    currentQuestion: {},
                    currentQuestionId: currentQuestionId + 1,
                    value: -1
                });
            }
            if (currentQuestionId < questions.length) {
                const question = questions.find(
                    item =>
                        Number(item.index) === Number(currentQuestionId + 1)
                );
                if (Number(value) !== Number(questions[currentQuestionId - 1].answer)) {
                    this.setState({ visibleChange: true });
                } else {
                    this.setState({
                        currentQuestionId: currentQuestionId + 1,
                        value: question.answer,
                        currentQuestion: question
                    });
                }
            }
        }
    };

    showPrev = () => {
        const {
            determinant, questions, match
        } = this.props;
        const { id } = match.params;
        const { disease } = determinant;
        const { currentQuestionId } = this.state;
        const question = questions.find(
            item =>
                Number(item.index) === Number(currentQuestionId - 1)
        );
        this.setState({
            currentQuestionId: currentQuestionId - 1,
            value: question && question.answer,
            currentQuestion: question
        });
    };

    abortedDeterminant = () => {
        const { onTabChange } = this.props;
        this.hideModal();
        onTabChange('1');
    };

    changeAnswer = () => {
        const { resetAnswer, match } = this.props;
        const { id } = match.params;
        const { currentQuestionId, value, currentQuestion } = this.state;
        resetAnswer({ id, values: { question: currentQuestion.number, answer: value } });
        this.setState({
            currentQuestion: {},
            currentQuestionId: currentQuestionId + 1,
            value: -1,
            visibleChange: false
        });
    };

    onChange = e => {
        this.setState({
            value: e.target.value
        });
    };

    back2Journal = () => {
        this.setState({ currentQuestionId: -1, value: -1 });
    };

    showQuestion = (number) => {
        const { determinant, questions } = this.props;
        const { disease } = determinant;
        const question = questions[number - 1];
        this.setState({ currentQuestionId: number, value: question.answer, currentQuestion: question });
    };
    
    compare = (a, b) => {
        if (a.number < b.number) {
            return -1;
        }
        if (a.number > b.number) {
            return 1;
        }
        return 0;
    }

    render() {
        const { cn } = this;
        const {
            t, determinant, questions, question
        } = this.props;
        const { disease } = determinant;
        const { type } = question;
        const determinantStatus = disease;
        const {
            visibleAborted,
            visibleChange,
            value,
            currentQuestionId,
            currentQuestion
        } = this.state;


        const stateQuestion = (currentQuestionId > questions.length) ? { ...question } : { ...currentQuestion };
        const { answers = [] } = stateQuestion;

        if ((disease && (currentQuestionId === -1)) || (!disease && type === 'disease')) {
            return (
                <div className={cn('journal')}>
                    <div className={cn('journal-title')}>
                        {t('journal title')}
                    </div>
                    <ol>
                        {
                            questions.map((item, i) => (
                                (i < questions.length) && (
                                    <li onClick={() => this.showQuestion(item.index)}>
                                        <span>{item.title}</span>
                                    </li>
                                )))
                        }

                    </ol>
                </div>
            );
        }

        return (
            <div className={cn()}>
                <div className={cn('title')}>
                    {
                        (answers.length > 0)
                    && <div className={cn('title-circle')}>{currentQuestionId}</div>
                    }
                    {stateQuestion.title}
                </div>
                <div className={cn('content')}>

                    <Radio.Group onChange={this.onChange} value={value} className={cn('item-wrap')}>
                        {
                            disease
                                ? answers.filter((item) => item.number === stateQuestion.answer).map(
                                    (item) => (
                                        <Radio value={item.number}
                                            key={item.number}
                                            disabled
                                            className={cn('item')}>
                                            {item.text}
                                        </Radio>
                                    )
                                )
                                : answers?.sort(this.compare).map(
                                    (item) => (
                                        <Radio value={item.number}
                                            key={item.number}
                                            className={cn('item')}>
                                            {item.text}
                                        </Radio>
                                    )
                                )
                        }
                    </Radio.Group>
                </div>
                <Col className={cn('buttons')}>
                    <Row className={cn('fixed-info')}>
                        { !disease
                        && (
                            <Button type="default"
                                onClick={this.showAbortedModal}>
                                {t('aborted')}
                            </Button>
                        ) }
                        <Button type="default"
                            disabled={currentQuestionId === 1}
                            onClick={this.showPrev}>{t('prev')}
                        </Button>
                        <Button type="default"
                            disabled={
                                // кнопка отключена, если не выбрано значение при заполнении
                                // или при просмотре завершающего вопроса  в статусе "завершено"
                                value < 0
                                || (disease && currentQuestionId === questions.length)
                            }
                            onClick={this.showNext}>{t('next')}
                        </Button>
                    </Row>
                    { disease && (
                        <Row>
                            <Button type="default"
                                onClick={this.back2Journal}>
                                {t('to journal')}
                            </Button>
                        </Row>
                    )}
                </Col>

                <InfoMessage
                    visible={visibleAborted}
                    title="aborted determinant title"
                    text="aborted determinant description"
                    okBtn="aborted determinant ok"
                    cancelBtn="aborted determinant cancel"
                    onOk={this.abortedDeterminant}
                    onCancel={this.hideModal} />
                <InfoMessage
                    visible={visibleChange}
                    title="change determinant title"
                    text="change determinant description"
                    okBtn="change determinant ok"
                    cancelBtn="change determinant cancel"
                    onOk={this.changeAnswer}
                    onCancel={this.hideModal} />
            </div>

        );
    }
}