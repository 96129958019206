import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { Form, Input } from 'antd';

import { withTranslation } from 'react-i18next';

import { Button } from '../../../components';

import { selectors, actions } from '../../../models';

import cn from '../../../utils/cn';

const { register } = actions;
const { isRegister } = selectors;
const { Password } = Input;
const REG_FORM = 'reg_form';

const mstp = (state) => ({
    isError: isRegister(state)
});

const mdtp = ({ register });

@withRouter
@connect(mstp, mdtp)
@withTranslation('reg form')
@cn('reg-form')
export default class LoginForm extends Component {
    formRef = React.createRef();
    
    state = {
        username: '',
        email: '',
        password: '',
        password_repeat: '',
        isInputError: true,
        passwordMatch: true
    };

    passwordField = React.createRef();

    onSubmit = (values) => {
        const { register } = this.props;
        const sendValues = {
            email: values.email,
            username: values.username,
            password: values.password
        };
        register(sendValues);
        this.setState({ isLoading: true });
    };

    onFieldsChange = (field, fields) => {
        let checked = false;
        field[0] && this.setState({ [field[0].name[0]]: field[0].value });
        fields.forEach(item => {
            if (!item.value) checked = true;
            if (item.errors && item.errors.length) checked = true;
        });
        const password = fields.find(item => item.name.includes('password'));
        const password_repeat = fields.find(item => item.name.includes('password_repeat'));
        if (password.value === password_repeat.value) {
            this.setState({ passwordMatch: true });
        } else {
            this.setState({ passwordMatch: false });
            checked = true;
        }
        
        this.setState({ isInputError: checked });
    };


    setLogin = () => {
        const { setLogin } = this.props;
        setLogin();
    };

    validatePassword = (rule, value, callback) => {
        const { t } = this.props;
        if (value && value.length < 8) {
            callback(t('not valid pass'));
        } else {
            callback();
        }
    };

    render() {
        const { cn } = this;
        const { t, isError } = this.props;
        const {
            username,
            email,
            password,
            password_repeat,
            isInputError
        } = this.state;

        return (
            <Form
                ref={this.formRef}
                name={REG_FORM}
                className={cn('modal')}
                onValuesChange={this.onChange}
                onFieldsChange={this.onFieldsChange}
                onFinish={this.onSubmit}>
                <div className={cn('header')}>
                    {t('title')}
                </div>

                <div className={`${cn('content')} field`}>
                    <div className="ant-col ant-form-item-label">
                        <label className="ant-form-item-required">
                            {email ? t('email') : ''}
                        </label>
                    </div>
                    <Form.Item name="email"
                        rules={[
                            {
                                type: 'email',
                                message: t('not valid email')
                            }
                        ]}>
                        <Input
                            className={cn('input')}
                            placeholder={t('email placeholder')}
                            maxLength={255} />
                    </Form.Item>
                    <div className="ant-col ant-form-item-label">
                        <label className="ant-form-item-required">
                            {username ? t('username') : ''}
                        </label>
                    </div>
                    <Form.Item name="username">
                        <Input
                            className={cn('input')}
                            placeholder={t('username placeholder')}
                            maxLength={255} />
                    </Form.Item>
                    <div className="ant-col ant-form-item-label">
                        <label className="ant-form-item-required">
                            {password ? t('password') : ''}
                        </label>
                    </div>
                    <Form.Item name="password"
                        rules={[
                            { required: true, message: t('not empty') },
                            { validator: this.validatePassword }
                        ]}>
                        <Password
                            className={cn('input')}
                            placeholder={t('password placeholder')}
                            type="password"
                            maxLength={255} />
                    </Form.Item>
                    <div className="ant-col ant-form-item-label">
                        <label className="ant-form-item-required">
                            {password_repeat ? t('password repeat') : ''}
                        </label>
                    </div>
                    <Form.Item name="password_repeat"
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(t('passwords do not match')));
                                }
                            })
                        ]}>
                        <Password
                            className={cn('input')}
                            placeholder={t('password repeat placeholder')}
                            type="password"
                            maxLength={255} />
                    </Form.Item>
                    <div className={cn('text-left')}>
                        {t('agreement text')}
                        <a href={t('agreement link')} target="_blank" rel="noopener noreferrer">{t('agreement')}</a>
                        {t('personal text')}
                        <a href={t('personal link')} target="_blank" rel="noopener noreferrer">{t('personal')}</a>.
                    </div>
                </div>
                {isError && (
                    <div className={cn('error')}>
                        {t('error')}
                    </div>
                )}
                <div className={cn('footer')}>
                    <Button
                        disabled={isInputError}
                        htmlType="submit"
                        type="primary">
                        {t('submit')}
                    </Button>
                </div>
                <div className={cn('text')}>
                    {t('info text')}
                    <span onClick={this.setLogin}>{t('enter')}</span>
                </div>
            </Form>

        );
    }
}