import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from '../../utils/cn';
import './style.less';

@cn('title')
class Title extends Component {
    render() {
        const { cn } = this;
        const {
            className,
            title,
            extra,
            ...rest
        } = this.props;

        return (
            <div className={`${cn()} ${className}`} {...rest}>
                <div>
                    {title}
                </div>
                <div>
                    {extra && extra.map(item => item)}
                </div>
            </div>
        );
    }
}

Title.propTypes = {
    /** Дополнительное имя класса */
    className: PropTypes.string,
    title: PropTypes.any,
    extra: PropTypes.array
};

Title.defaultProps = {
    className: '',
    title: false,
    extra: []
};

export default Title;