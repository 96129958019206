import jsPDF from 'jspdf';
import 'jspdf-autotable';

import moment from 'moment';

import * as constantsCommon from '../../constants';
import { rus2Latin } from '../../utils/utils';

const methodsArr = { kirsanov: 'по Кирсанову', chirikov: 'по Чирикову', machigin: 'по Мачигину' };
const intervals = {
    VERY_LOW: 'Очень низкое',
    LOW: 'Низкое',
    AVERAGE: 'Среднее',
    INCREASED: 'Повышенное',
    HIGH: 'Высокое',
    VERY_HIGH: 'Очень высокое',
    TOXIC: 'Токсичное',
    VERY_STRONGLY_ACID: 'Очень сильнокислый',
    STRONG_ACID: 'Сильнокислый',
    MEDIUM_ACID: 'Среднекислый',
    SLIGHTLY_ACID: 'Слабокислый',
    CLOSE_TO_NEUTRAL: 'Близкие к нейтральным',
    NEUTRAL: 'Нейтральные',
    WEAKLY_ALKALINE: 'Слабощелочные',
    STRONG_ALKALINE: 'Сильнощелочные',
    SHARPLY_ALKALINE: 'Резкощелочные'
};
export function generatePdf(t, calculator) {
    const {
        normative, agrochemical, rb, distribution
    } = calculator;
    const pdfTitle = 'pdf title';
    const doc = new jsPDF('p', 'pt', 'a4', true);

    const arr = [];

    doc.addFileToVFS('Arial-normal.ttf', constantsCommon.FONT);
    doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

    doc.setFont('Arial');
    doc.setFontSize(16);
    const { pageSize } = doc.internal;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const textMain = doc.splitTextToSize(calculator?.name || '', pageWidth - 50, {});
    const textY = textMain.length * 16 + 60;
    doc.text(textMain, 40, 40);
    doc.setFontSize(14);
    doc.autoTable({
        startY: textY,
        columnStyles: {
            0: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
        },
        body: [
            [
                t('date pdf', { value: moment(calculator.created_at).format('DD.MM.YYYY') })
            ]
        ]
    });
    const finalY = doc.previousAutoTable.finalY + 40;


    const bodyHead = [
        ['Культура', normative?.culture?.name || rb?.culture?.name]
    ];

    doc.autoTable({
        startY: finalY,
        columnStyles: {
            0: { font: 'Arial', cellPadding: 10 },
            1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
        },
        body: bodyHead
    });

    if (agrochemical?.id) {
        const { type } = agrochemical;
        const bodyParamsAgro = [];
        if (type) {
            bodyParamsAgro.push(['Произведен', t(agrochemical.type), '']);
            bodyParamsAgro.push(['Год', agrochemical.year, '']);
            switch (type) {
                case 'point': {
                    bodyParamsAgro.push(['Точка', agrochemical.point, '']);
                    break;
                }
                case 'zone': {
                    bodyParamsAgro.push(['Зона продуктивности', t(agrochemical.zone), '']);
                    break;
                }
                case 'field':
                default: {
                    break;
                }
            }
            bodyParamsAgro.push(['', '', '']);
        }
        bodyParamsAgro.push(
            ['Азот аммиачный (аммонийный) NH4, мг / 100г', agrochemical.nh4, intervals[agrochemical.nh4_interval]],
            ['Гумус', agrochemical.organic_substances, intervals[agrochemical.organic_substances_interval]],
            ['Азот нитратный NO3, мг / 100г', agrochemical.no3, intervals[agrochemical.no3_interval]],
            ['Метод анализа', methodsArr[agrochemical.method], ''],
            ['Фосфор подвижный P2O5, мг / 100г', agrochemical.p2o5, intervals[agrochemical.p2o5_interval]],
            ['Калий обменный K2O, мг / 100г', agrochemical.k2o, intervals[agrochemical.k2o_interval]],
            ['Сера подвижная S, мг / 100 г', agrochemical.s, intervals[agrochemical.s_interval]],
            ['Кальций Са, мг-экв / 100г', agrochemical.ca, intervals[agrochemical.ca_interval]],
            ['Магний Mg, мг-экв / 100г', agrochemical.mg, intervals[agrochemical.mg_interval]],
            ['Сульфаты SO4, мг / 100г', agrochemical.so4, intervals[agrochemical.so4_interval]],
            ['Хлориды Cl, мг / 100 г', agrochemical.cl, intervals[agrochemical.cl_interval]],
            ['Натрий Na, ммоль / 100г (мг / 100 г)', agrochemical.na, intervals[agrochemical.na_interval]],
            ['Электропроводность водной вытяжки почвы, мСм/см (дСм/м)', agrochemical.salt_conductivity, intervals[agrochemical.salt_conductivity_interval]],
            ['pH водный, ед. pH', agrochemical.ph_water, intervals[agrochemical.ph_water_interval]],
            ['pH солевой, ед. pH', agrochemical.ph_salt, intervals[agrochemical.ph_salt_interval]]
        );

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 4, cellPadding: 10 },
                2: { font: 'Arial', cellWidth: pageWidth / 4, cellPadding: 10 }
            },
            body: bodyParamsAgro
        });
    }

    const fileName = rus2Latin(calculator.name?.substr(0, 50) ?? 'Calculator');
    doc.save(`${fileName}.pdf`);
}