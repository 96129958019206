import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { useClassName } from 'utils/cn';

import FertilizersTable from './fertilizers-table';
import Fertilizer from './fertilizer';

export default (props) => {
    const {
        visible, children, onCancel, title
    } = props;
    const { t } = useTranslation('calculator fertilizer');
    const cn = useClassName('calculator-detail-agro-fertilizer');

    return (
        <Modal
            title={title}
            width={1000}
            closable
            footer={null}
            destroyOnClose
            visible={visible}
            className={cn('modal')}
            onCancel={onCancel}>
            {children}
        </Modal>

    );
};