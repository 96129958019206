import * as constants from './constants';

export const getDeterminants = payload => ({
    type: constants.getDeterminants.toString(),
    payload
});

export const getDeterminant = payload => ({
    type: constants.getDeterminant.toString(),
    payload
});

export const addDeterminant = payload => ({
    type: constants.addDeterminant.toString(),
    payload
});

export const addSimpleDeterminant = payload => ({
    type: constants.addSimpleDeterminant.toString(),
    payload
});

export const removeDeterminant = payload => ({
    type: constants.removeDeterminant.toString(),
    payload
});

export const clearDeterminants = () => ({
    type: constants.clearDeterminants.toString()
});

export const clearDeterminant = () => ({
    type: constants.clearDeterminant.toString()
});
export const clearQuestion = () => ({
    type: constants.clearQuestion.toString()
});
export const clearQuestions = () => ({
    type: constants.clearQuestions.toString()
});

export const setAnswer = payload => ({
    type: constants.setAnswer.toString(),
    payload
});

export const setSimpleAnswer = payload => ({
    type: constants.setSimpleAnswer.toString(),
    payload
});

export const resetAnswer = payload => ({
    type: constants.resetAnswer.toString(),
    payload
});

export const getQuestion = payload => ({
    type: constants.getQuestion.toString(),
    payload
});

export const getQuestions = payload => ({
    type: constants.getQuestions.toString(),
    payload
});
