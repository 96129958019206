import * as constants from '../constants';
import { addOwnerSampleLog, createSampleRow, deleteSampleRow } from '../actions';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.addOwnerSampleLog.success: {
            return action.payload;
        }

        case constants.addOwnerSampleLog.error: {
            return { isLoading: false };
        }

        case constants.addOwnerSampleLog.try: {
            return { isLoading: true };
        }

        default:
            return state;
    }
}