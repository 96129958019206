import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Select,
    InputNumber
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useClassName } from 'utils/cn';
import '../../../style.less';

const ProtectionItem = ({
    item, idx, deleteItem, protectionMode, onFieldInput,
    onFieldSelect, isNumerated, protectorOptions, onPestSearch,
    setPestPage, pestPage, pestName, setPestName, setPesticideGroup
}) => {
    const { t } = useTranslation('accounting documents');
    const cn = useClassName('accounting-documents');
    const [form] = Form.useForm();

    const handlePestSearch = (newPestName) => {
        setPestName(newPestName);
        onPestSearch();
    };

    const stageOptions = [
        {
            label: t('modal protection item stage main'),
            value: 'main'
        },
        {
            label: t('modal protection item stage sowing'),
            value: 'sowing'
        },
        {
            label: t('modal protection item stage feeding'),
            value: 'feeding'
        }
    ];

    const pestGroupOptions = [
        {
            label: t('pest group option insecticides'),
            value: 'insecticides'
        },
        {
            label: t('pest group option herbicides'),
            value: 'herbicides'
        },
        {
            label: t('pest group option pesticides'),
            value: 'pesticides'
        }
    ];

    useEffect(() => {
        form.setFieldsValue({
            protection_name: item?.fertilizer.id,
            protection_fact: item?.fact_amount,
            protection_processed: item?.cultivated_area,
            protection_stage: item?.stage
        });
    }, [item]);

    const handleScroll = (evt) => {
        evt.stopPropagation();
        const bottom = evt.target.scrollHeight - evt.target.clientHeight <= evt.target.scrollTop;
        if (bottom) {
            setPestPage(pestPage + 1);
            onPestSearch();
        }
    };

    useEffect(() => {
        const resArr = protectorOptions.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.label === value.label
            )));
        if (resArr?.length < 10) {
            setPestPage(pestPage + 1);
            onPestSearch();
        }
    }, [protectorOptions]);

    return (
        <Form form={form} key={item.name}>
            <div className="add-fertilizer-header">
                <h3>
                    {`${t('modal protections header add')} ${t(`modal protections header ${protectionMode === 'protect' ? 'protection' : 'fertilizer'}`)} ${isNumerated ? `№${idx + 1}` : ''}`}
                </h3>
                <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => deleteItem(idx)} />
            </div>
            {protectionMode === 'fertilizer' && (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                value={item.fertilizer}
                                label={t('modal protections form fertilizer name')}
                                name="protection_name">
                                <Select
                                    value={item.fertilizer}
                                    onChange={(val) => onFieldSelect(idx, 'name', val)}
                                    onPopupScroll={handleScroll}
                                    onSearch={handlePestSearch}
                                    options={protectorOptions.filter((value, index, self) =>
                                        index === self.findIndex((t) => (
                                            t.label === value.label
                                        )))}
                                    showSearch
                                    filterOption={(input, option) => (
                                        option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                    )}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal protections form fact')}
                                name="protection_fact">
                                <InputNumber
                                    value={item.fact_amount}
                                    onChange={(val) => onFieldInput(idx, 'fact', val)}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal protections form processed')}
                                name="protection_processed">
                                <InputNumber
                                    value={item.cultivated_area}
                                    onChange={(val) => onFieldInput(idx, 'processed', val)}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal protections form stage')}
                                name="protection_stage">
                                <Select
                                    value={item.stage}
                                    onChange={(val) => onFieldSelect(idx, 'stage', val)}
                                    options={stageOptions}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
            {protectionMode === 'protect' && (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal protections form pestgroup')}
                                name="pest_group">
                                <Select
                                    onChange={(val) => setPesticideGroup(val)}
                                    options={pestGroupOptions}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                value={item.fertilizer}
                                label={t('modal protections form fertilizer name')}
                                name="protection_name">
                                <Select
                                    value={item.fertilizer}
                                    onChange={(val) => onFieldSelect(idx, 'name', val)}
                                    onPopupScroll={handleScroll}
                                    onSearch={handlePestSearch}
                                    options={protectorOptions.filter((value, index, self) =>
                                        index === self.findIndex((t) => (
                                            t.label === value.label
                                        )))}
                                    showSearch
                                    filterOption={(input, option) => (
                                        option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                    )}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal protections form fact')}
                                name="protection_fact">
                                <InputNumber
                                    value={item.fact_amount}
                                    onChange={(val) => onFieldInput(idx, 'fact', val)}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal protections form processed')}
                                name="protection_processed">
                                <InputNumber
                                    value={item.cultivated_area}
                                    onChange={(val) => onFieldInput(idx, 'processed', val)}
                                    style={{ width: '100%' }}
                                    allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
};

export default ProtectionItem;