/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Popover, Tooltip, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import '../style.less';
import { useSelector } from 'react-redux';
import { curMycoUsers } from '../../../models/mycological-registry/selectors';
import { DownloadOutlined } from '@ant-design/icons';
import { isLoading } from '../../../models/account/selectors';

const methodNames = [
    {
        label: 'Ч',
        value: 'chirikov'
    },
    {
        label: 'K',
        value: 'kirsanov'
    },
    {
        label: 'М',
        value: 'machigin'
    }
];

const registrationColumns = [
    'num', 'owner', 'vega_key', 'field_num', 'point',
    'public', 'date', 'analysis_date', 'K', 'A',
    'comment'
];

const resultColumns = [
    'num', 'owner', 'field_num', 'point', 'method', 'ph_water', 'ph_salt',
    'E', 'organic_substances', 'productivity_zone', 'zone_area',
    'NH4_result', 'K20_result', 'Na_result', 'Mg_result', 'Ca_result', 'Cl_result', 'SO4_result',
    'S_result', 'N_result', 'NO3_result', 'PO4_result', 'P2O5_result', 'comment'
];

const generalColumns = [
    {
        label: 'table header sample num',
        value: 'num',
        handlerFunction: (item, users, t) => {
            return item?.num;
        }
    },
    {
        label: 'table header owner',
        value: 'owner',
        handlerFunction: (item, users, t) => {
            return users?.filter(x => parseInt(x?.id, 10) === parseInt(item?.owner, 10))[0]?.name || item?.owner;
        }
    },
    {
        label: 'table header field name',
        value: 'vega_key',
        handlerFunction: (item, users, t) => {
            return item?.vega_key;
        }
    },
    {
        label: 'table header field num',
        value: 'field_num',
        handlerFunction: (item, users, t) => {
            return item?.field_num;
        }
    },
    {
        label: 'table header point num',
        value: 'point',
        handlerFunction: (item, users, t) => {
            return item?.point;
        }
    },
    {
        label: 'table header status',
        value: 'public',
        handlerFunction: (item, users, t) => {
            return item?.public ? (
                t('status text public')
            ) : (
                t('status text not public')
            );
        }
    },
    {
        label: 'table header sample date',
        value: 'date',
        handlerFunction: (item, users, t) => {
            const dateArray = item?.date?.length > 0 ? item?.date?.split('-') : [];
            return dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : null;
        }
    },
    {
        label: 'table header analysis date',
        value: 'analysis_date',
        handlerFunction: (item, users, t) => {
            const dateArray = item?.analysis_date?.length > 0 ? item?.analysis_date?.split('-') : [];
            return dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : null;
        }
    },
    {
        label: 'table header K',
        value: 'K',
        handlerFunction: (item, users, t) => {
            return item?.K;
        }
    },
    {
        label: 'table header A',
        value: 'A',
        handlerFunction: (item, users, t) => {
            return item?.A;
        }
    },
    {
        label: 'table header method',
        value: 'method',
        handlerFunction: (item, users, t) => {
            return methodNames?.filter(x => x.value === item?.num)[0] ? methodNames?.filter(x => x.value === item?.num)[0].label : null;
        }
    },
    {
        label: 'table header ph_water',
        value: 'ph_water',
        handlerFunction: (item, users, t) => {
            return item?.ph_water;
        }
    },
    {
        label: 'table header ph_salt',
        value: 'ph_salt',
        handlerFunction: (item, users, t) => {
            return item?.ph_salt;
        }
    },
    {
        label: 'table header E',
        value: 'E',
        handlerFunction: (item, users, t) => {
            return item?.E;
        }
    },
    {
        label: 'table header organic_substances',
        value: 'organic_substances',
        handlerFunction: (item, users, t) => {
            return item?.organic_substances;
        }
    },
    {
        label: 'table header productivity zone',
        value: 'productivity_zone',
        handlerFunction: (item, users, t) => {
            return item?.productivity_zone ? t(`productivity zone ${item?.productivity_zone}`) : '';
        }
    },
    {
        label: 'table header productivity zone area',
        value: 'zone_area',
        handlerFunction: (item, users, t) => {
            return item?.zone_area;
        }
    },
    {
        label: 'table header NH4',
        value: 'NH4_result',
        handlerFunction: (item, users, t) => {
            return item?.NH4_result;
        }
    },
    {
        label: 'table header K20',
        value: 'K20_result',
        handlerFunction: (item, users, t) => {
            return item?.K20_result;
        }
    },
    {
        label: 'table header Na',
        value: 'Na_result',
        handlerFunction: (item, users, t) => {
            return item?.Na_result;
        }
    },
    {
        label: 'table header Mg',
        value: 'Mg_result',
        handlerFunction: (item, users, t) => {
            return item?.Mg_result;
        }
    },
    {
        label: 'table header Ca',
        value: 'Ca_result',
        handlerFunction: (item, users, t) => {
            return item?.Ca_result;
        }
    },
    {
        label: 'table header Cl',
        value: 'Cl_result',
        handlerFunction: (item, users, t) => {
            return item?.Cl_result;
        }
    },
    {
        label: 'table header SO4',
        value: 'SO4_result',
        handlerFunction: (item, users, t) => {
            return item?.SO4_result;
        }
    },
    {
        label: 'table header S',
        value: 'S_result',
        handlerFunction: (item, users, t) => {
            return item?.S_result;
        }
    },
    {
        label: 'table header N',
        value: 'N_result',
        handlerFunction: (item, users, t) => {
            return item?.N_result;
        }
    },
    {
        label: 'table header NO3',
        value: 'NO3_result',
        handlerFunction: (item, users, t) => {
             return item?.NO3_result;
        }
    },
    {
        label: 'table header PO4',
        value: 'PO4_result',
        handlerFunction: (item, users, t) => {
            return item?.PO4_result;
        }
    },
    {
        label: 'table header P2O5',
        value: 'P2O5_result',
        handlerFunction: (item, users, t) => {
            return item?.P2O5_result;
        }
    },
    {
        label: 'table header comment',
        value: 'comment',
        handlerFunction: (item, users, t) => {
            return item?.comment;
        }
    }
];

const ExcelExport = ({
    apiData, fileName, filteredDataSet = [], ...props
}) => {
    const { t } = useTranslation('sample log');

    const users = useSelector(state => curMycoUsers(state));

    const [predefinedFormat, setPredefinedFormat] = useState(true);
    const [reformattedData, setReformattedData] = useState([]);
    const [originalDataCopy, setOriginalDataCopy] = useState([]);
    const [activeColumns, setActiveColumns] = useState([
        'comment', 'P2O5_result', 'PO4_result', 'NO3_result', 'N_result', 'S_result',
        'SO4_result', 'Cl_result', 'Ca_result', 'Mg_result', 'Na_result', 'K20_result',
        'NH4_result', 'zone_area', 'productivity_zone', 'organic_substances', 'E', 'ph_salt',
        'ph_water', 'method', 'A', 'K', 'analysis_date', 'date', 'public', 'point', 'field_num',
        'vega_key', 'owner', 'num'
    ]);

    useEffect(() => {
        if (filteredDataSet?.results?.length > 0) {
            setOriginalDataCopy([...filteredDataSet?.results]);

            const resultArray = [];
            filteredDataSet?.results?.forEach(rawDataItem => {
                const processedItem = {};
                generalColumns?.map(columnObj => {
                    processedItem[t(columnObj?.label)] = columnObj?.handlerFunction(rawDataItem, users, t);
                });
                resultArray.push(processedItem);
            });

            setReformattedData([...resultArray]);

            if (originalDataCopy?.length < 1) {
                setOriginalDataCopy([...resultArray]);
            }
        }
    }, [filteredDataSet]);

    useEffect(() => {
        if (apiData?.results?.length > 0) {
            setOriginalDataCopy([...apiData?.results]);

            const resultArray = [];
            [...apiData?.results].forEach(rawDataItem => {
                const processedItem = {};
                generalColumns?.map(columnObj => {
                    processedItem[t(columnObj?.label)] = columnObj?.handlerFunction(rawDataItem, users, t);
                });
                resultArray.push(processedItem);
            });

            setReformattedData([...resultArray]);

            if (originalDataCopy?.length < 1) {
                setOriginalDataCopy([...resultArray]);
            }
        }
    }, [apiData]);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCsv = (apiData, fileName) => {
        if (apiData) {
            const ws = XLSX.utils.json_to_sheet([...apiData]);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };

    const ownerDecoder = (item) => {
        return users?.filter(x => parseInt(x?.id, 10) === parseInt(item, 10))[0]?.name || item;
    };

    const handleDataRestructure = (selectedColumns) => {
        const dataCopy = [...reformattedData].map(dataItem => {
            const remadeDataItem = {};
            if (selectedColumns?.length > 0) {
                selectedColumns.forEach(columnKey => {
                    const pickColumn = generalColumns?.filter(searchCol => searchCol?.value === columnKey)[0];
                    const columnLocale = t(pickColumn.label);
                    if (pickColumn?.value === 'owner') {
                        remadeDataItem[columnLocale] = ownerDecoder(dataItem[columnLocale]);
                    } else {
                        remadeDataItem[columnLocale] = dataItem[columnLocale];
                    }
                });
            }

            return remadeDataItem;
        });

        return dataCopy?.length > 0 ? dataCopy : [];
    };

    const exportXmlTable = () => {
        const resultData = handleDataRestructure(activeColumns);
        exportToCsv(resultData, fileName);
        setReformattedData([...originalDataCopy]);
    };

    const generateReport = (reportType) => {
        let resultData;
        let newFileName;
        const currentDate = new Date();

        if (reportType === 'registration') {
            resultData = handleDataRestructure(registrationColumns);
            newFileName = `[${currentDate.getDate()}.${currentDate.getMonth() + 1}.${ currentDate.getFullYear()}]_Registration_SampleLog`;
        }
        if (reportType === 'result') {
            resultData = handleDataRestructure(resultColumns);
            newFileName = `[${currentDate.getDate()}.${currentDate.getMonth() + 1}.${ currentDate.getFullYear()}]_Result_SampleLog`;
        }

        exportToCsv(resultData, newFileName);
        setReformattedData([...originalDataCopy]);
    };

    const columnOptions = (
        <div>
            {predefinedFormat ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <Button
                        style={{
                            marginBottom: '5px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px'
                        }}
                        onClick={() => generateReport('registration')}>
                        {t('report type registration')}
                    </Button>
                    <Button
                        style={{
                            marginBottom: '5px',
                            marginLeft: '5px',
                            marginRight: '5px'
                        }}
                        onClick={() => generateReport('result')}>
                        {t('report type result')}
                    </Button>
                </div>
            ) : (
                <>
                    <div
                        style={{
                            minWidth: '600px',
                            width: '600px',
                            maxWidth: '600px',
                            padding: '10px 20px'
                        }}>
                        <Checkbox.Group
                            options={generalColumns?.length > 0 ? generalColumns?.map(x => {
                                return {
                                    label: t(x?.label),
                                    value: x?.value
                                };
                            }) : []}
                            defaultValue={[...activeColumns]}
                            onChange={setActiveColumns} />
                    </div>
                    <div
                        style={{
                            paddingBottom: '5px',
                            paddingTop: '5px',
                            paddingLeft: '5px',
                            paddingRight: '5px'
                        }}>
                        <Button
                            style={{
                                width: '100%',
                                textAlign: 'center'
                            }}
                            onClick={() => exportXmlTable()}
                            type="secondary">
                            {t('export ok button')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );

    return (
        // <div {...props}>
        <Popover
            title={() => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                    <div
                        onClick={() => setPredefinedFormat(true)}
                        style={{
                            marginRight: '25px',
                            color: predefinedFormat ? 'black' : 'grey',
                            cursor: predefinedFormat ? 'auto' : 'pointer'
                        }}>
                        {t('choose needed format')}
                    </div>

                    <div
                        onClick={() => setPredefinedFormat(false)}
                        style={{
                            color: predefinedFormat ? 'grey' : 'black',
                            cursor: predefinedFormat ? 'pointer' : 'auto'
                        }}>
                        {t('choose needed columns')}
                    </div>
                </div>
            )}
            placement="bottom"
            content={columnOptions}
            trigger="click">
            <Tooltip title='Сформировать Excel'>
                <Button size="medium" style={{ width: '100%' }} className="excel-generator__button">
                    {apiData?.isLoading ? (
                        <Spin spinning={true} />
                    ) : (
                        <>
                            <DownloadOutlined {...props} />
                            {t('export to excel')}
                        </>
                    )}
                </Button>
            </Tooltip>
        </Popover>
        // </div>
    );
};

export default ExcelExport;