import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import {
    Modal, Row, Col
} from 'antd';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { useClassName } from 'utils/cn';
import Button from '../../components/button';
import './style.less';
import { selectors } from '../../models';
import * as constants from '../../constants';

const {
    getCurrentFertilizer
} = selectors;

export default ({ hideInfo }) => {
    const { t } = useTranslation('fertilizer modal');
    const cn = useClassName('fertilizer-modal');
    const fertilizer = useSelector(state => getCurrentFertilizer(state));
    
    const onCancel = () => {
        hideInfo();
    };

    const getEconomyType = () => {
        const arr = [];
        constants.economyTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item)); }
        });
        const str = arr.join(', ');
        return str;
    };

    const getMacro = () => {
        const arr = [];
        constants.macroTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item, { value: fertilizer[item] })); }
        });
        const str = arr.join(', ');
        return str;
    };

    const getApplicationRate = () => {
        const arr = [];
        constants.economyTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item)); }
        });
        const str = arr.join(', ');
        return str;
    };
    
    if (!fertilizer) return <div />;
    return (
        <>
            <div className={cn('content')}>
                <div className={cn('title')}>
                    {fertilizer.name}
                </div>
                <Row>
                    <Col span={12}>
                        {t('group fertilizers')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.group_name}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('subgroup fertilizers')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.subgroup_name}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('culture group')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.culture_description}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('main drug')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.main_drug}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('economy type')}
                    </Col>
                    <Col span={12}>
                        {getEconomyType()}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('cultivated')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.culture_description}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('synonyms')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.synonyms.map(item => `${item.name} `)}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('macrocells')}
                    </Col>
                    <Col span={12}>
                        {getMacro()}
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        {t('release form')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.release_form_name}
                    </Col>
                </Row>
                {fertilizer.presowing_application_rate && (
                    <Row>
                        <Col span={12}>
                            {`${t('application rate')}. ${t('presowingApplicationRate')}`}
                        </Col>
                        <Col span={12}>

                            {fertilizer.presowing_application_rate}
                        </Col>
                    </Row>
                )}
                {fertilizer.sowing_application_rate && (
                    <Row>
                        <Col span={12}>
                            {`${t('application rate')}. ${t('sowingApplicationRate')}`}
                        </Col>
                        <Col span={12}>

                            {fertilizer.sowing_application_rate}
                        </Col>
                    </Row>
                )}
                {fertilizer.basic_application_rate && (
                    <Row>
                        <Col span={12}>
                            {`${t('application rate')}. ${t('basicApplicationRate')}`}
                        </Col>
                        <Col span={12}>

                            {fertilizer.basic_application_rate}
                        </Col>
                    </Row>
                )}
                {fertilizer.top_dressing_application_rate_open && (
                    <Row>
                        <Col span={12}>
                            {`${t('application rate')}. ${t('topDressingApplicationRateOpen')}`}
                        </Col>
                        <Col span={12}>

                            {fertilizer.top_dressing_application_rate_open}
                        </Col>
                    </Row>
                )}
                {fertilizer.top_dressing_application_rate_close && (
                    <Row>
                        <Col span={12}>
                            {`${t('application rate')}. ${t('topDressingApplicationRateClose')}`}
                        </Col>
                        <Col span={12}>

                            {fertilizer.top_dressing_application_rate_close}
                        </Col>
                    </Row>
                )}
                {fertilizer.leaf_dressing && (
                    <Row>
                        <Col span={12}>
                            {`${t('application rate')}. ${t('leaf_dressing')}`}
                        </Col>
                        <Col span={12}>

                            {fertilizer.leaf_dressing}
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col span={12}>
                        {t('registrant')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.registration}
                    </Col>
                </Row>
                {/* <Row>
                            <Col span={12}>
                                {t('price')}
                            </Col>
                            <Col span={12}>
                                {fertilizer.averagePrice}
                            </Col>
                        </Row> */}
                <Row>
                    <Col span={12}>
                        {t('registry')}
                    </Col>
                    <Col span={12}>
                        {fertilizer.is_in_state_registry ? t('inStateRegistryYes') : t('inStateRegistryNo')}
                    </Col>
                </Row>
            </div>
        </>
    );
};
