import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    toClient(data = {}) { // eslint-disable-line
        return {
            id: data.id,
            user: data.user,
            created_at: data.created_at,
            updated_at: data.updated_at,
            errors: data.errors || {},
            ph_water_interval: data.ph_water_interval,
            ph_salt_interval: data.ph_salt_interval,
            nh4_interval: data.nh4_interval,
            no3_interval: data.no3_interval,
            p2o5_interval: data.p2o5_interval,
            k2o_interval: data.k2o_interval,
            s_interval: data.s_interval,
            ca_interval: data.ca_interval,
            na_interval: data.na_interval,
            mg_interval: data.mg_interval,
            so4_interval: data.so4_interval,
            cl_interval: data.cl_interval,
            organic_substances_interval: data.organic_substances_interval,
            salt_conductivity_interval: data.salt_conductivity_interval,
            compare_ph_water_with_ph_salt_interval: data.compare_ph_water_with_ph_salt_interval,
            compare_no3_with_nh4_interval: data.compare_no3_with_nh4_interval,
            ph_water: data.ph_water,
            ph_salt: data.ph_salt,
            nh4: data.nh4,
            no3: data?.no3?.toFixed(2),
            p2o5: data.p2o5,
            k2o: data.k2o,
            s: data.s,
            ca: data.ca,
            na: data.na,
            mg: data.mg,
            so4: data.so4,
            cl: data.cl,
            organic_substances: data?.organic_substances?.toFixed(2),
            salt_conductivity: data.salt_conductivity,
            method: data.method,
            type: data.type,
            points: data.points,
            zones: data.zones,
            year: data.year
        };
    }
}

export default new Converter();