import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Layout, Select, Row, Col
} from 'antd';

import { connect } from 'react-redux';
import cn from '../../utils/cn';

import {
    PanelLayout, Title
} from '../../components';

import CalculatorsTable from './components/calculators-table';
import CalculatorsTableFluid from './components/calculators-table-fluid';
import CalculatorsTableFed from './components/calculators-table-fed';
import CalculatorsTableMyco from './components/calculators-table-myco';

import './style.less';
import { selectors } from '../../models';
import CalculatorsTablePhyto from './components/calculators-table-phyto';

const { PanelFooter, PanelHeader, PanelContent } = PanelLayout;
const methods = [
    { value: 2, title: 'norm' },
    { value: 5, title: 'agro' },
    { value: 7, title: 'nutrition' },
    { value: 6, title: 'myco' },
    { value: 3, title: 'fluid' },
    { value: 4, title: 'fed' },
    { value: 16, title: 'phyto' }
];
const methodsFasie = [
    { value: 2, title: 'norm' },
    { value: 1, title: 'rb' }
];

const {
    getUser
} = selectors;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({});

@withTranslation('all calculators')
@connect(mstp, mdtp)
@cn('all-page')
export default class AllCalculators extends Component {
    state = {
        methodTypeId: undefined,
        isFasie: false
    };

    componentDidMount() {
        const { user } = this.props;
        const { groups = [] } = user;
        this.setState({ isFasie: groups.includes('fasie') });
    }

    onSelectChange = (methodTypeId) => {
        this.setState({ methodTypeId });
    }

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };


    render() {
        const { cn } = this;
        const { t } = this.props;
        const { methodTypeId, isFasie } = this.state;

        return (
            <Layout className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <Row gutter={20} style={{ marginBottom: '30px' }}>
                            <Col span={24} style={{ marginBottom: '6px' }}>
                                <div className="ant-col ant-form-item-label">
                                    <label className="ant-form-item-required">
                                        {t('method type')}
                                    </label>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Select
                                    showSearch
                                    className={cn('select')}
                                    onChange={this.onSelectChange}
                                    name="methodTypeId"
                                    filterOption={this.filterOption}>
                                    {!isFasie && methods.map(item => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {t(item.title)}
                                        </Select.Option>
                                    ))}
                                    {isFasie && methodsFasie.map(item => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {t(item.title)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        {!methodTypeId && (
                            <div className={cn('empty')}>
                                {t('choose')}
                            </div>
                        )}
                        {methodTypeId === 1 && (<CalculatorsTable type={5} />)}
                        {methodTypeId === 2 && (<CalculatorsTable type={6} />)}
                        {methodTypeId === 3 && (<CalculatorsTableFluid />)}
                        {methodTypeId === 4 && (<CalculatorsTableFed />)}
                        {methodTypeId === 5 && (<CalculatorsTable type={7} />)}
                        {methodTypeId === 6 && (<CalculatorsTableMyco />)}
                        {methodTypeId === 7 && (<CalculatorsTable type={11} />)}
                        {methodTypeId === 16 && (<CalculatorsTablePhyto />)}
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}