import * as constants from '../constants';

const initialState = {
    results: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getFieldPoints.success: {
            return { results: action.payload?.results || [], isCalculate: false };
        }

        default:
            return state;
    }
}
