import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    _server2Client = ({
        Id,
        Email,
        Fio,
        Status
    }) => (this.removeEmptyField({
        id: Id,
        email: Email,
        fio: Fio,
        isOnline: Status.IsOnline,
        isValid: Status.IsValid
    }));

    list2Client = ({
        value
    }) => value.map(this._server2Client);

    _client2Server = ({
        id,
        email,
        fio,
        isOnline,
        isValid
    }) => (
        this.removeEmptyField({
            Id: id,
            Email: email,
            Fio: fio,
            Status: {
                IsOnline: isOnline,
                IsValid: isValid
            }
        })
    );

    toCreate = (body) => {
        const content = this._client2Server(body);
        const form = new FormData();
        Object.keys(content).forEach((field) => {
            if (field === 'FormFile') {
                form.append(field, content[field], content[field].name);
            } else {
                form.append(field, content[field]);
            }
        });
        return form;
    };
}

export default new Converter();
