import * as constants from './constants';

export const getCalculatorsDegreeOfSoil = payload => ({
    type: constants.getCalculatorsDegreeOfSoil.toString(),
    payload
});

export const getCalculatorsDegreeOfSoilItem = payload => ({
    type: constants.getCalculatorsDegreeOfSoilItem.toString(),
    payload
});