import { getProfile, verify, login } from '../constants';

export default function reducer(state = true, action) {
    switch (action.type) {
        case verify.error:
        case verify.success:
        case login.error:
        case login.success:
        case getProfile.error:
        case getProfile.success: {
            return true;
        }
        case verify.try:
        case getProfile.try: {
            return false;
        }
        default: return state;
    }
}
