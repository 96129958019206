import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import { useSelector } from 'react-redux';
import {
    GeoJSON, MapContainer, TileLayer, useMap
} from 'react-leaflet';
import { selectors } from '../../../models';

import { Button } from '../../../components';
import LayersForEmbulatovo from '../../../components/layers/layers-embulatovo';
import LayersForSaetovo from '../../../components/layers/layers-saetovo';
import LayersForSouz from '../../../components/layers/layers-souz';
import LayersForPikaev from '../../../components/layers/layers-pikaev';
import LayersForAgroBio from '../../../components/layers/layers-agrobio';
import LayersForTest from '../../../components/layers/layers-test';
import LayersForAmmoni from '../../../components/layers/layers-ammoni';

const {
    tableMapData,
    isShapeLoading,
    getUserId
} = selectors;

export default ({ curField }) => {
    const { t } = useTranslation('pivot table');
    const cn = useClassName('map-wrapper');
    const userId = useSelector(state => getUserId(state));
    const fields = useSelector(state => tableMapData(state));
    const [center, setCenter] = useState([54, 44]);
    const [geoJSON, setGeoJSON] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (fields.length > 0) {
            const coordinates = getShapeCoordinates(fields);
            const centroid = getCentroid(coordinates);
            const center = [centroid[1], centroid[0]];
            setCenter([...center]);
            setGeoJSON(createGeoJSON(fields));
        }
    }, [fields]);

    useEffect(() => {
        if (curField) {
            const curFieldInfo = fields.filter(item => item.id === curField);
            const coordinates = getShapeCoordinates(curFieldInfo);
            const centroid = getCentroid(coordinates);
            const center = [centroid[1], centroid[0]];
            setGeoJSON(createGeoJSON(fields));
            setCenter([...center]);
        }
    }, [curField]);

    const createGeoJSON = (data) => {
        const arr = [];
        data.forEach(item => {
            const shape = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: { ...item.shape }
                    }
                ]
            };
            const style = curField === item.id ? () => ({ color: 'red' }) : () => ({ color: item.culture?.color || 'green' });
            arr.push({ shape, style, id: item.id });
        });
        return arr;
    };
    const getCentroid = (coordinates) => {
        const coord = { lat: 0, lan: 0 };
        coordinates.forEach((item) => {
            coord.lat += item[0];
            coord.lan += item[1];
        });
        return [coord.lat / coordinates.length, coord.lan / coordinates.length];
    };

    const getShapeCoordinates = (data) => {
        let coordinates = [];
        data.forEach(item => {
            coordinates = coordinates.concat(item.shape?.coordinates[0].flat(1));
        });
        return coordinates;
    };

    const go2Field = () => {
        history.push(`/representation/${curField}`);
        // history.push(`/mordovia/${curField}`);
    };

    const ChangeView = ({ center }) => {
        const map = useMap();
        map.setView(center, 11);
        return null;
    };

    const Map = ({ geoJSON, center, curField }) => (
        <MapContainer
            center={center}
            zoom={11}
            doubleClickZoom
            scrollWheelZoom>
            <ChangeView center={center} zoom={11} curField={curField} />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {
                geoJSON.map((item) => <GeoJSON data={item.shape} style={item.style} />)
            }
            {userId === 73 && (
                <LayersForEmbulatovo />
            )}
            {userId === 845 && (
                <LayersForSaetovo />
            )}
            {userId === 844 && (
                <LayersForSouz />
            )}
            {userId === 261 && (
                <LayersForPikaev />
            )}
            {userId === 860 && (
                <LayersForAgroBio />
            )}
            {userId === 861 && (
                <LayersForTest />
            )}
            {userId === 862 && (
                <LayersForAmmoni />
            )}
        </MapContainer>
    );

    return (
        <>
            <Map geoJSON={geoJSON} center={center} curField={curField} />
            { curField && <Button onClick={go2Field} className={cn('button')}>{t('go to field')}</Button> }
        </>
    );
};