import React, { Component } from 'react';
import { Tooltip as AntTooltip } from 'antd';

import cn from '../../utils/cn';
import './style.less';

@cn('tooltip')
export default class Tooltip extends Component {
    render() {
        const { cn } = this;
        const { ...rest } = this.props;

        return (
            <AntTooltip className={cn()} {...rest} />
        );
    }
}