import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const curFieldEvents = state => state.models[name].eventsField.items;
export const curAccountingEvents = state => state.models[name].eventsAccounting;
export const curVegetationPhases = state => state.models[name].vegetationPhases;
export const curAgroEventsList = state => state.models[name].agroeventsList;
export const curAgroEventsErrors = state => state.models[name].agroeventsError;
