import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import {defaultSaga, getActions} from '../utils';

import * as constants from './constants';
import converter from './converter';
import * as api from './api';
import {getPivotByAgroTechicalPeriodMock} from "./api";


export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getPivotTableMapData.toString(),
            defaultSaga({
                constants: constants.getPivotTableMapData,
                api: api.getPivotTableMapData
            })
        ),
        yield takeLatest(
            constants.getPivotTableByFieldTotal.toString(),
            defaultSaga({
                constants: constants.getPivotTableByFieldTotal,
                api: api.getPivotTableByFieldTotal
            })
        ),
        yield takeLatest(
            constants.getPivotTableByField.toString(),
            defaultSaga({
                constants: constants.getPivotTableByField,
                api: api.getPivotTableByField
            })
        ),
        yield takeLatest(
            constants.getPivotTableByCultureTotal.toString(),
            defaultSaga({
                constants: constants.getPivotTableByCultureTotal,
                api: api.getPivotTableByCultureTotal
            })
        ),
        yield takeLatest(
            constants.getPivotTableByCulture.toString(),
            defaultSaga({
                constants: constants.getPivotTableByCulture,
                api: api.getPivotTableByCulture
            })
        ),
        yield takeLatest(
            constants.getPivotByAgroTechicalPeriod.toString(),
            defaultSaga({
                constants: constants.getPivotByAgroTechicalPeriod,
                api: api.getPivotByAgroTechicalPeriod
            })
        ),
        yield takeLatest(
            constants.getPivotByAgroTechicalFields.toString(),
            defaultSaga({
                constants: constants.getPivotByAgroTechicalFields,
                api: api.getPivotByAgroTechicalFields
            })
        )
    ]);
}