import React, { Component, useState, useEffect } from 'react';
import {
    MapContainer,
    TileLayer,
    GeoJSON,
    Circle, useMap
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import { useSelector } from 'react-redux';
import { selectors } from '../../../models';

import LayersForEmbulatovo from '../../../components/layers/layers-embulatovo';
import LayersForSaetovo from '../../../components/layers/layers-saetovo';
import LayersForSouz from '../../../components/layers/layers-souz';
import LayersForPikaev from '../../../components/layers/layers-pikaev';
import LayersForAgroBio from '../../../components/layers/layers-agrobio';
import LayersForTest from '../../../components/layers/layers-test';
import LayersForAmmoni from '../../../components/layers/layers-ammoni';
import LayersForKubagro from '../../../components/layers/layers-kubagro';
import { Button } from '../../../components';

const {
    getCurrentShape,
    isShapeLoading,
    getUserId,
    getUser,
    curPoints
} = selectors;

export default ({ curPoint }) => {
    const { t } = useTranslation('map');
    const shape = useSelector(state => getCurrentShape(state));
    const points = useSelector(state => curPoints(state));
    const isShape = useSelector(state => isShapeLoading(state));
    const userId = useSelector(state => getUserId(state));
    const user = useSelector(state => getUser(state));
    const [geoJson, setGeoJson] = useState({});
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [drawMap, setDraw] = useState({});
    const [center, setCenter] = useState([54.26, 44.27]);

    useEffect(() => {
        if (curPoint) {
            const curPointInfo = points.find(item =>
                Number(item.id) === Number(curPoint));
            if (curPointInfo?.coordinates) {
                setCenter([curPointInfo.coordinates.coordinates[1], curPointInfo.coordinates.coordinates[0]]);
            }
        }
    }, [curPoint]);

    useEffect(() => {
        const filteredPoints = points.filter(point => point.coordinates);
        filteredPoints.forEach((point) => {
            point.coordinates.coordinates = [
                point.coordinates.coordinates[1], point.coordinates.coordinates[0]
            ];
        });
        setFilteredPoints(filteredPoints ?? []);
    }, [points]);

    useEffect(() => {
        if (shape.length && shape[0].field_shape) {
            const coord = shape[0].field_shape.coordinates[0];
            const coordinates = getShapeCoordinates(coord);
            const centroid = getCentroid(coordinates);
            const center = [centroid[1], centroid[0]];
            setCenter([...center]);
            const geoJson = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: { ...shape[0].field_shape }
                    }
                ]
            };
            setGeoJson({ ...geoJson });
        }
        setDraw(isShape);
    }, [shape, isShape]);

    const getShapeCoordinates = (data) => (data.length > 0 ? data.flat(1) : []);

    const getCentroid = (coordinates) => {
        const coord = { lat: 0, lan: 0 };
        coordinates.forEach((item) => {
            coord.lat += item[0];
            coord.lan += item[1];
        });
        return [coord.lat / coordinates.length, coord.lan / coordinates.length];
    };

    const ChangeView = ({ center, zoom }) => {
        const map = useMap();
        map.setView(center, zoom);
        return null;
    };

    const isFasie = user?.groups?.includes('fasie');

    if (!drawMap) return <div />;

    const Map = ({ center, curPoint }) => (
        <MapContainer center={center} zoom={15} scrollWheelZoom={false}>
            <ChangeView center={center} zoom={15} curPoint={curPoint} />
            
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {Object.keys(geoJson).length !== 0 && (<GeoJSON data={geoJson} />)}

            {filteredPoints?.map(p => (
                <Circle center={[p.coordinates.coordinates[1], p.coordinates.coordinates[0]]}
                    radius={30}
                    pathOptions={{ color: 'green' }} />
            )
            )}
            
            {userId === 73 && (
                <LayersForEmbulatovo />
            )}
            {(userId === 845 || isFasie) && (
                <LayersForSaetovo />
            )}
            {userId === 844 && (
                <LayersForSouz />
            )}
            {userId === 261 && (
                <LayersForPikaev />
            )}
            {userId === 860 && (
                <LayersForAgroBio />
            )}
            {userId === 861 && (
                <LayersForTest />
            )}
            {userId === 862 && (
                <LayersForAmmoni />
            )}
            {userId === 882 && (
                <LayersForKubagro />
            )}
        </MapContainer>
    );

    return (
        <Map center={center} curPoint={curPoint} />
    );
};