import React, { useEffect, useState, useRef } from 'react';
import {
    Table, Button, Row, Col, Tooltip, Popover, Select, InputNumber
} from 'antd';

import { useTranslation } from 'react-i18next';

import '../style.less';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { usrListResponse } from '../../../models/sample-log/selectors';
import { getUsersList } from '../../../models/sample-log/actions';
import SelectableTable from '../../../components/selectable-table';
import { currentFieldYearsList, getCalculatorsMordoviaObject } from '../../../models/mordovia/selectors';
import { DownloadOutlined } from '@ant-design/icons';
import { updateFieldYield } from '../../../models/mordovia/actions';

const RegistriesTable = ({
    pageSize, page, setPage, setPageSize,
    generateReport, userNameMapping,
    getFields
}) => {
    const { t } = useTranslation('recommendation registries');
    const history = useHistory();
    const dispatch = useDispatch();

    const users = useSelector(state => usrListResponse(state));
    const yearItems = useSelector(state => currentFieldYearsList(state));

    useEffect(() => {
        console.log('yearItems', yearItems);
    }, [yearItems]);
    

    const [fullUsersList, setFullUsersList] = useState([]);
    const [usersPage, setUsersPage] = useState(1);

    useEffect(() => {
        dispatch(getUsersList({
            page: usersPage,
            searchString: ''
        }));
    }, [usersPage]);

    useEffect(() => {
        const listPortion = users?.results;

        if (listPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...listPortion]);
        }

        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUsersPage(usersPage + 1);
        }
    }, [users]);

    const getUserName = (id) => {
        return userNameMapping[id];
    };

    const handleYieldUpdate = (evnt, oldValue, recordEntity) => {
        const yearId = recordEntity?.id;
        const newYield = parseFloat(evnt?.target?.value);
        if (newYield !== oldValue) {
            dispatch(updateFieldYield({
                yearId: yearId,
                body: {
                    planned_yield: newYield
                }
            }));

            const renewTimeout = setTimeout(() => {
                getFields();
                clearTimeout(renewTimeout);
            }, 800);
        }
    };

    const columns = [
        //
        {
            title: t('date of creation'),
            // dataIndex: 'created',
            dataIndex: ['field_info', 'created'],
            key: 'created',
            render: val => val ? moment(val).format('DD.MM.YYYY') : '-'
        },
        {
            title: t('years'),
            // dataIndex: 'years',
            dataIndex: 'year',
            // key: 'years',
            key: 'year',
            render: (value, record) => (
                // <a onClick={() => history.push(`/mordovia/${value}/${record?.field_info?.vega_key}`)}>
                <a onClick={() => history.push(`/representation/${value}/${record?.field_info?.vega_key}`)}>
                    {value}
                </a>
            )
            // render: (value, record) => value?.length > 0 ? value?.map((x, idx) => {
            //     return (
            //         <a onClick={() => history.push(`/mordovia/${x}/${record?.vega_key}`)}>
            //             {`${idx > 0 ? ', ' : ''}${x}`}
            //         </a>
            //     )
            // }) : '-'
        },
        {
            title: t('owner'),
            dataIndex: ['field_info', 'owner_user', 'name'],
            // dataIndex: 'owner_user',
            key: 'owner_user',
            render: val => getUserName(val) || val
        },
        {
            title: t('field_num'),
            // dataIndex: 'field_num',
            dataIndex: ['field_info', 'field_num'],
            key: 'field_num',
            render: (val, record) => `${val ? val : '-'} / ${record?.field_info?.vega_key ? record?.field_info?.vega_key : '-'}`
        },
        {
            title: t('culture'),
            dataIndex: ['culture', 'name'],
            key: 'culture',
        },
        {
            title: t('planned_yield'),
            dataIndex: 'planned_yield',
            key: 'planned_yield',
            render: (value, record) => (
                <InputNumber value={value} onBlur={(evt) => handleYieldUpdate(evt, value, record)} />
            )
        },
        {
            title: t('reports'),
            dataIndex: '',
            key: 'actions',
            width: 150,
            render: (value, record) => {
                const [selectedYear, setSelectedYear] = useState(null);
                const [selectedYears, setSelectedYears] = useState(null);
                const [popoverOpen, setPopoverOpen] = useState(false);

                return (
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={t("generate general report")}>
                                {/* <Popover
                                    content={() => (
                                        <div style={{ padding: '5px', display: 'flex', justifyContent: 'middle', alignItems: 'center' }}>
                                            <Button style={{ margin: 'auto' }} type="primary" onClick={() => generateReport('general', { vega_key: record?.vega_key, year: selectedYear })}>{t('download')}</Button>
                                        </div>
                                    )}
                                    title={() => (<Select value={selectedYear} onChange={setSelectedYear} options={record?.year_group?.years?.map(x => {
                                        return {
                                            label: x?.year,
                                            value: x?.year
                                        };
                                    })} />)}
                                    trigger="click"
                                    open={popoverOpen}
                                    onOpenChange={(val) => {
                                        console.log('OPEN CHANGE', val);
                                        setSelectedYear(null);
                                        setSelectedYears(null);
                                        setPopoverOpen(val);
                                    }}> */}
                                    <Button onClick={() => generateReport('general', { vega_key: record?.vega_key, year: record?.year })} primary icon={<DownloadOutlined />} />
                                {/* </Popover> */}
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={t("generate value report")}>
                                {/* <Popover
                                    content={() => (
                                        <div style={{ padding: '5px', display: 'flex', justifyContent: 'middle', alignItems: 'center' }}>
                                            <Button style={{ margin: 'auto' }} type="primary" onClick={() => generateReport('volume', { years: selectedYears })}>{t('download')}</Button>
                                        </div>
                                    )}
                                    title={() => (<Select value={selectedYears} mode="multiple" onChange={setSelectedYears} options={record?.year_group?.years?.map(x => {
                                        return {
                                            label: x?.year,
                                            value: x?.id
                                        };
                                    })} />)}
                                    trigger="click"
                                    open={popoverOpen}
                                    onOpenChange={(val) => {
                                        setSelectedYear(null);
                                        setSelectedYears(null);
                                        setPopoverOpen(val);
                                    }}> */}
                                    <Button onClick={() => generateReport('volume', { years: [record?.year] })} type="text" icon={<DownloadOutlined />} />
                                {/* </Popover> */}
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={t("generate norm report")}>
                                {/* <Popover
                                    content={() => (
                                        <div style={{ padding: '5px', display: 'flex', justifyContent: 'middle', alignItems: 'center' }}>
                                            <Button style={{ margin: 'auto' }} type="primary" onClick={() => generateReport('norm', { years: selectedYears })}>{t('download')}</Button>
                                        </div>
                                    )}
                                    title={() => (<Select value={selectedYears} onChange={setSelectedYears} mode="multiple" options={record?.year_group?.years?.map(x => {
                                        return {
                                            label: x?.year,
                                            value: x?.id
                                        };
                                    })} />)}
                                    trigger="click"
                                    open={popoverOpen}
                                    onOpenChange={(val) => {
                                        setSelectedYear(null);
                                        setSelectedYears(null);
                                        setPopoverOpen(val);
                                    }}> */}
                                    <Button onClick={() => generateReport('norm', { years: [record?.year] })} type="text" icon={<DownloadOutlined />} />
                                {/* </Popover> */}
                            </Tooltip>
                        </Col>
                    </Row>
                )
            }
        },
    ];

    const onPaginationChange = (newPage, newPageSize) => {
        if (page !== newPage) {
            setPage(newPage);
        }
        if (pageSize !== newPageSize) {
            setPageSize(newPageSize);
        }
    };

    return (
        <Table
            className='myTable'
            scroll={{ y: 500, x: 1500 }}
            rowKey='id'
            columns={columns}
            pagination={{
                onChange: onPaginationChange,
                current: page,
                pageSize: pageSize,
                total: yearItems?.count,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100']
            }}
            dataSource={yearItems?.results} />
    );
};

export default RegistriesTable;