import {
    all, takeLatest
} from 'redux-saga/effects';
import { defaultSaga } from '../utils';

import * as constants from './constants';
import * as api from './api';
import converter from './converter';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getMeteodata.toString(),
            defaultSaga({
                constants: constants.getMeteodata,
                api: api.getMeteodata,
                after: converter.toClient
            })
        ),

        yield takeLatest(
            constants.getAnalysisMeteodata.toString(),
            defaultSaga({
                constants: constants.getAnalysisMeteodata,
                api: api.getMeteodata,
                after: converter.toClient
            })
        ),

        yield takeLatest(
            constants.getEffectiveMeteodata.toString(),
            defaultSaga({
                constants: constants.getEffectiveMeteodata,
                api: api.getMeteodata,
                after: converter.toClient
            })
        ),

        yield takeLatest(
            constants.getPrecipitationMeteodata.toString(),
            defaultSaga({
                constants: constants.getPrecipitationMeteodata,
                api: api.getMeteodata,
                after: converter.toClient
            })
        )
    ]);
}