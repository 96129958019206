import i18n from '../i18n';

export const object2Form = (object, ...objects) => {
    let result = {};
    Object.keys(object)
        .forEach(key => result[key] = Object.assign({ value: object[key] }));
    // .forEach(key => result[key] = Object.assign({ value: object[key] }, ...objects));
    result = Object.assign(result);
    return result;
};

export const object2FormWithErrors = (object) => {
    let result = {};
    Object.keys(object)
        .forEach((key) => {
            const { value, error } = object[key];
            result[key] = { value };
            if (error) result[key].errors = [new Error(error)];
        });
    result = { ...result };
    return result;
};

export const numberValidator = (rule, value, callback) => {
    !value || /^(\d*)?\d+$/igm.test(value) ? callback() : callback(i18n.t('handler-settings:notDecimal'));
};
