import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Icon from '@ant-design/icons';
import {
    Modal, Row, Col, Collapse
} from 'antd';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import moment from 'moment';
import { rus2Latin } from '../../../../utils/utils';
import cn from '../../../../utils/cn';
import Button from '../../../../components/button';
import '../../style.less';
import * as constants from '../../../../constants';
import { selectors, actions } from '../../../../models';
import { Title } from '../../../../components';

const { Panel } = Collapse;

const {
    getPesticide
} = actions;
const {
    getCurrentPesticide,
    curPesticideProductApplication
} = selectors;

const mstp = (state) => ({
    pesticide: getCurrentPesticide(state),
});
const mdtp = ({
    getPesticide
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('pesticide modal')
@cn('pesticide-modal')
class PesticideModal extends Component {
    componentDidMount() {
        const { getPesticide, application } = this.props;
        application.plant_protection_product && getPesticide(application.plant_protection_product.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getPesticide, application } = this.props;
        if (prevProps.application.plant_protection_product !== application.plant_protection_product) {
            getPesticide(application.plant_protection_product.id);
        }
    }

    jsPdfGenerator = () => {
        const { t, pesticide, application } = this.props;
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const text = doc.splitTextToSize(pesticide.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        const cultivatedCultures = application.related_cultures ? application.related_cultures.map(item => (
            item && item.culture.name
        )) : [];
        const harmfulObjects = application.related_harmful_objects ? application.related_harmful_objects.map(item => (
            item && item.harmful_object.name
        )) : [];
        const mainDrug = pesticide.related_main_drugs ? pesticide.related_main_drugs.map(item => (
            item && item.main_drug.name
        )) : [];
        const price = pesticide.related_sellers ? pesticide.related_sellers.map(item => (
            item && item.price
        )) : [];
        const chemicalClass = pesticide.related_chemical_classes ? pesticide.related_chemical_classes.map(item => (
            item && item.chemical_class.name
        )) : [];
        const registrant = pesticide.related_registrations ? pesticide.related_registrations.map(item => (
            item && item.registrant.name
        )) : [];
        const expDate = pesticide.related_registrations ? pesticide.related_registrations.map(item => (
            item && item.expiration_date
        )) : [];
        const registrationNumber = pesticide.related_registration ? pesticide.related_registrations.map(item => (
            item && item.registration_number
        )) : [];
        const applicationArea = pesticide.related_application_areas ? pesticide.related_application_areas.map(item => (
            item && item.application_area.name
        )) : [];
        const normOfUse = (application.rate_of_use_min === application.rate_of_use_max)
            ? application.rate_of_use_min
            : `${application.rate_of_use_min} - ${application.rate_of_use_max}`;
        const fluidFlowRate = (application.fluid_consumption_min === application.fluid_consumption_max)
            ? application.fluid_consumption_min
            : `${application.fluid_consumption_min} - ${application.fluid_consumption_max}`;

        const body = [
            [t('group pesticides'), pesticide.group.name],
            [t('group culture'), cultivatedCultures.join(', ')],
            [t('harmful object'), harmfulObjects.join(', ')],
            [t('main drug'), mainDrug.join(', ')],
            [t('main drug content'), pesticide.main_drug_content],
            [t('type of processing'), application.treatment_type && application.treatment_type.name],
            [t('price'), price.join(', ')],
            [t('units'), application?.unit_of_measurement?.name],
            [t('preparative form'), pesticide?.preparative_form?.name],
            [t('chemical class'), chemicalClass.join(', ')],
            [t('application type'), application.application_features],
            [t('danger class'), pesticide.hazard_class.name],
            [t('registrant'), registrant.join(', ')],
            [t('registration number'), registrationNumber.join(', ')],
            [t('norm'), normOfUse],
            [t('application area'), applicationArea.join(', ')],
            [t('fluid flow rate'), fluidFlowRate],
            [t('waiting time'), `${pesticide?.waiting_period}(${pesticide?.multiplicity_of_treatments})`],
            [t('release dates'), pesticide.exit_dates_for_manual_works],
            [t('end date'), expDate.join(', ')]
        ];
        doc.autoTable({
            startY: finalY + 40,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
            },
            body
        });
        const fileName = rus2Latin(pesticide.name);
        doc.save(`${fileName}.pdf`);
    };

    render() {
        const { cn } = this;
        const {
            t,
            visible,
            title,
            onCancel,
            pesticide,
            application,
            onTabChange
        } = this.props;
        if (!pesticide || !application) return <div />;
        const cultivatedCultures = application.related_cultures ? application.related_cultures.map(item => (
            item && item.culture.name
        )) : [];
        const harmfulObjects = application.related_harmful_objects ? application.related_harmful_objects.map(item => (
            item && item.harmful_object.name
        )) : [];
        const mainDrug = pesticide.related_main_drugs ? pesticide.related_main_drugs.map(item => (
            item && item.main_drug.name
        )) : [];
        const price = pesticide.related_sellers ? pesticide.related_sellers.map(item => (
            item && item.price
        )) : [];
        const chemicalClass = pesticide.related_chemical_classes ? pesticide.related_chemical_classes.map(item => (
            item && item.chemical_class.name
        )) : [];
        const registrant = pesticide.related_registrations ? pesticide.related_registrations.map(item => (
            item && item.registrant.name
        )) : [];
        const expDate = pesticide.related_registrations ? pesticide.related_registrations.map(item => (
            item && item.expiration_date
        )) : [];
        const registrationNumber = pesticide.related_registration ? pesticide.related_registrations.map(item => (
            item && item.registration_number
        )) : [];
        const applicationArea = pesticide.related_application_areas ? pesticide.related_application_areas.map(item => (
            item && item.application_area.name
        )) : [];
        const normOfUse = (application.rate_of_use_min === application.rate_of_use_max)
            ? application.rate_of_use_min
            : `${application.rate_of_use_min} - ${application.rate_of_use_max}`;
        const fluidFlowRate = (application.fluid_consumption_min === application.fluid_consumption_max)
            ? application.fluid_consumption_min
            : `${application.fluid_consumption_min} - ${application.fluid_consumption_max}`;

        if (!pesticide) return <div />;
        return (
            <Modal
                title={t(title)}
                width={760}
                footer={null}
                closable={false}
                destroyOnClose
                visible={visible}
                className={cn('modal')}
                onCancel={onCancel}>
                <div className={cn('content')}>
                    <div className={cn('title')}>
                        {pesticide.title}
                    </div>
                    
                    <Row>
                        <Col span={12}>
                            {t('group pesticides')}
                        </Col>
                        <Col span={12}>
                            {pesticide.group && pesticide.group.name}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('group culture')}
                        </Col>
                        <Col span={12}>
                            {cultivatedCultures.join(', ')}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('harmful object')}
                        </Col>
                        <Col span={12}>
                            {harmfulObjects.join(', ')}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('main drug')}
                        </Col>
                        <Col span={12}>
                            {mainDrug.join(', ')}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('main drug content')}
                        </Col>
                        <Col span={12}>
                            {pesticide.main_drug_content}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('type of processing')}
                        </Col>
                        <Col span={12}>
                            {application.treatment_type && application.treatment_type.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('price')}
                        </Col>
                        <Col span={12}>
                            {price.join(', ')}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('units')}
                        </Col>
                        <Col span={12}>
                            {application.unit_of_measurement && application.unit_of_measurement.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('preparative form')}
                        </Col>
                        <Col span={12}>
                            {pesticide.preparative_form && pesticide.preparative_form.name}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('chemical class')}
                        </Col>
                        <Col span={12}>
                            {chemicalClass.join(', ')}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('application type')}
                        </Col>
                        <Col span={12}>
                            {application.application_features}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('danger class')}
                        </Col>
                        <Col span={12}>
                            {pesticide.hazard_class && pesticide.hazard_class.name}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('registrant')}
                        </Col>
                        <Col span={12}>
                            {registrant.join(', ')}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('registration number')}
                        </Col>
                        <Col span={12}>
                            {registrationNumber.join(', ')}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('norm')}
                        </Col>
                        <Col span={12}>
                            {normOfUse}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('application area')}
                        </Col>
                        <Col span={12}>
                            {applicationArea.join(', ')}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('fluid flow rate')}
                        </Col>
                        <Col span={12}>
                            {fluidFlowRate}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('waiting time')}
                        </Col>
                        <Col span={12}>
                            {`${pesticide.waiting_period}(${pesticide.multiplicity_of_treatments})`}
                        </Col>
                    </Row>
                    <Row className={cn('gray')}>
                        <Col span={12}>
                            {t('release dates')}
                        </Col>
                        <Col span={12}>
                            {pesticide.exit_dates_for_manual_works}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {t('end date')}
                        </Col>
                        <Col span={12}>
                            { expDate.join(', ') }
                        </Col>
                    </Row>

                        
                </div>
                <div className={cn('footer')}>
                    <Button
                        onClick={this.jsPdfGenerator}
                        type="link">
                        <Icon type="download" />
                        {t('generate')}
                    </Button>
                    <Button
                        onClick={onCancel}
                        type="primary">
                        {t('close')}
                    </Button>
                </div>
            </Modal>
        );
    }
}
export default PesticideModal;