import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, Form, Input
} from 'antd';

import cn from '../../../utils/cn';
import {
    Title, Button, Tabs, InfoMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

const {
    addCalculatorFluid
} = actions;
const {
    getCurrentCalculatorFluid
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFluid(state)
});
const mdtp = ({
    addCalculatorFluid
});

const { TabPane } = Tabs;
const { Option } = Select;


@withRouter
@connect(mstp, mdtp)
@withTranslation('calculator detail')
@cn('calculator-detail')
export default class CalculatorData extends Component {
    formRef = React.createRef();

    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        calculationMethodId: 3
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calculator, history, match } = this.props;
        const { id } = match.params;
        if (prevProps.calculator !== calculator) {
            this.initForm(calculator);
        }
    }

    closeCalculator = () => {
        const { history } = this.props;
        history.push('/calculators/');
    };

    hideModal = () => {
        this.setState({ visible: false });
    };

    showModal = () => {
        this.setState({ visible: true });
    };

    onSubmit = (values) => {
        const { addCalculatorFluid, id, onTabChange } = this.props;
        !id && addCalculatorFluid(values);
    };

    onChange = (e, form) => {
        this.setState(e);
    };

    initForm = (calculator) => {
        const { match } = this.props;
        const { id, type } = match.params;
        const {
            name
        } = calculator;

        id && this.formRef.current.setFieldsValue({
            name
        });
    };

    setCalculatorName = (e) => {
        const { setName } = this.props;
        setName(e.target.value);
    };

    render() {
        const { cn } = this;
        const { t, isCompleted, match } = this.props;
        const { id } = match.params;
        const { visible } = this.state;

        return (
            <Form ref={this.formRef}
                className={cn('data')}
                onFinish={this.onSubmit}
                onValuesChange={this.onChange}
                name="calculator-form">
                <div>
                    <Row gutter={20}>
                        <Col span={24}>
                            <div className="field" style={{ marginBottom: '24px' }}>
                                <Form.Item label={t('calculator title')}
                                    name="name"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Input
                                        disabled={isCompleted}
                                        onChange={this.setCalculatorName} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <div className="field">
                                <span>{t('method type')}</span>
                                <div className={cn('type-title')}>{t('method3')}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <div className={cn('table-result')}>
                                <div className={cn('table-info')}>
                                    <div>
                                        <p>{t('title top dressing')}</p>
                                        <p className={cn('description-table')}>{t('subtitle top dressing')}</p>
                                        {t('top dressing table').map(elem => (
                                            <p key={elem}>{elem}</p>))}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row gutter={20}>
                    <Col span={24} className={cn('button-block')}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isCompleted || id}>
                            {t('start')}
                        </Button>
                        <Button type="default"
                            onClick={this.showModal}
                            disabled={isCompleted}>{t('cancel')}
                        </Button>
                    </Col>
                </Row>
                <InfoMessage
                    visible={visible}
                    title="create calculator title"
                    text="create calculator description"
                    okBtn="create calculator ok"
                    cancelBtn="create calculator cancel"
                    onOk={this.closeCalculator}
                    onCancel={this.hideModal} />
            </Form>

        );
    }
}