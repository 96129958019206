import { combineReducers } from 'redux';

import eventsAccounting from './events-accounting';
import eventCreated from './event-created';
import vegetationPhases from './vegetation-phases';
import eventsField from './events-field';
import agroeventsList from './agroevents-list';
import agroeventsError from './agroevents-error';

export default combineReducers({
    eventsAccounting,
    eventCreated,
    vegetationPhases,
    agroeventsList,
    agroeventsError,
    eventsField
});
