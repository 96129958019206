import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteMatch, useHistory } from 'react-router-dom';
import {
    Row, Col, Form, Input, InputNumber, Collapse, Radio, Select
} from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useClassName } from 'utils/cn';
import getConstants from './constants';

import {
    Button, SaveMessage
} from '../../../components';

import { selectors, actions } from '../../../models';

const { Panel } = Collapse;

const {
    setParamsCalculatorMyco,
    closeCalculatorMyco
} = actions;
const {
    getCurrentCalculatorMyco,
    getCulturesCatalog,
    getPrecedingCatalogCalculatorsMyco,
    getGenusOfMicroCatalogCalculatorsMyco,
    getTypeOfResearchCalculatorsMyco
} = selectors;


const REG_EXP = /^[0-9]*[.]?[0-9]+$/;
export default ({ addActiveKeys }) => {
    const calculator = useSelector(state => getCurrentCalculatorMyco(state));
    const micro = useSelector(state => getGenusOfMicroCatalogCalculatorsMyco(state));
    const cultures = useSelector(state => getCulturesCatalog(state));
    const research = useSelector(state => getTypeOfResearchCalculatorsMyco(state));
    const preceding = useSelector(state => getPrecedingCatalogCalculatorsMyco(state));

    const constants = getConstants();

    const dispatch = useDispatch();

    const cn = useClassName('calculator-detail-params');
    const { t } = useTranslation('calculator params myco');
    const match = useRouteMatch();
    const history = useHistory();
    const { id } = match.params;
    const [visible, setVisible] = useState(false);
    const [sum, setSum] = useState(0);
    const [isFullFields, setFullFields] = useState(false);
    const [inputType, setInputType] = useState('koe');
    const [phytopathogenics, setPytopathogenic] = useState([]);
    const [curPhytopathogenic, setCurPytopathogenic] = useState([]);
    const [suppressives, setSuppressive] = useState([]);
    const [curSuppressive, setCurSuppressive] = useState([]);
    const [neutrals, setNeutral] = useState([]);
    const [curNeutral, setCurNeutral] = useState([]);
    const isCompleted = calculator.is_done;

    const [form] = Form.useForm();
    useEffect(() => {
        setPytopathogenic([...micro.filter(item => (item.mycobiota_type && item.mycobiota_type.code === 'phytopathogenic'))]);
        setSuppressive([...micro.filter(item => (item.mycobiota_type && item.mycobiota_type.code === 'suppressive'))]);
        setNeutral([...micro.filter(item => (item.mycobiota_type && item.mycobiota_type.code === 'neutral'))]);
    }, [micro]);

    useEffect(() => {
        const {
            sampling_coordinates,
            interpretation,
            probe_number,
            culture,
            preceding_culture
        } = calculator;
        const {
            phytopathogenic,
            suppressive,
            neutral
        } = interpretation;
        const phytopathogenicArr = [];
        const phyt = [];
        const suppressiveArr = [];
        const sup = [];
        const neutralArr = [];
        const neu = [];
        const dataObj = {};
        phytopathogenic.micromycete.forEach(item => {
            const data = phytopathogenics.find(i => i.id === item.ref);
            phytopathogenicArr.push({
                id: item.ref,
                name: data && data.name,
                koe: item.koe,
                percentage: item.percentage,
                losses: item.losses
            });
            phyt.push(item.ref);
            dataObj[`${item.ref}-koe`] = item.koe && Number(item.koe.toFixed(2));
            dataObj[`${item.ref}-percentage`] = item.percentage && Number(item.percentage.toFixed(0));
        });
        suppressive.micromycete.forEach(item => {
            const data = suppressives.find(i => i.id === item.ref);
            suppressiveArr.push({
                id: item.ref,
                name: data && data.name,
                koe: item.koe,
                percentage: item.percentage,
                losses: item.losses
            });
            sup.push(item.ref);
            dataObj[`${item.ref}-koe`] = item.koe && Number(item.koe.toFixed(2));
            dataObj[`${item.ref}-percentage`] = item.percentage && Number(item.percentage.toFixed(0));
        });
        neutral.micromycete.forEach(item => {
            const data = neutrals.find(i => i.id === item.ref);
            neutralArr.push({
                id: item.ref,
                name: data && data.name,
                koe: item.koe,
                percentage: item.percentage,
                losses: item.losses
            });
            neu.push(item.ref);
            dataObj[`${item.ref}-koe`] = item.koe && Number(item.koe.toFixed(2));
            dataObj[`${item.ref}-percentage`] = item.percentage && Number(item.percentage.toFixed(0));
        });
        setCurPytopathogenic([...phytopathogenicArr]);
        setCurSuppressive([...suppressiveArr]);
        setCurNeutral([...neutralArr]);
        form.setFieldsValue({
            lat: sampling_coordinates.coordinates[0],
            lon: sampling_coordinates.coordinates[1],
            probe_number,
            culture: culture.id,
            preceding_culture: preceding_culture.id,
            phytopathogenicArr: phyt,
            suppressiveArr: sup,
            neutralArr: neu
        });
        form.setFieldsValue(dataObj);
    }, [calculator, phytopathogenics, suppressives, neutrals]);

    const closeCalculator = () => {
        hideModal();
    };

    const hideModal = () => {
        setVisible(false);
    };

    const showModal = () => {
        setVisible(true);
    };

    const setStatusComplete = () => {
        dispatch(closeCalculatorMyco({ id }));
        closeCalculator();
    };

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    const onFinish = (values) => {
        const sendValues = {};

        sendValues.culture = values.culture;
        sendValues.preceding_culture = values.preceding_culture;
        const report = [];
        values.phytopathogenicArr.forEach(item => {
            report.push({
                genus: item,
                koe: inputType === 'koe' ? values[`${item}-koe`] : null,
                percentage: inputType === 'percentage' ? values[`${item}-percentage`] : null
            });
        });
        values.suppressiveArr.forEach(item => {
            report.push({
                genus: item,
                koe: inputType === 'koe' ? values[`${item}-koe`] : null,
                percentage: inputType === 'percentage' ? values[`${item}-percentage`] : null
            });
        });
        values.neutralArr.forEach(item => {
            report.push({
                genus: item,
                koe: inputType === 'koe' ? values[`${item}-koe`] : null,
                percentage: inputType === 'percentage' ? values[`${item}-percentage`] : null
            });
        });
        sendValues.report = [...report];
        dispatch(setParamsCalculatorMyco({ id, values: sendValues }));
    };

    const onFieldsChange = (field, fields) => {};
    const onValuesChange = (value, values) => {
        const phytopathogenicArr = [];
        const suppressiveArr = [];
        const neutralArr = [];
        let sum = 0;
        Object.keys(values).forEach(item => {
            if (
                item.includes('percentage') && (
                    phytopathogenicArr.includes(Number(item.split('-')[0]))
                    || suppressiveArr.includes(Number(item.split('-')[0]))
                    || neutralArr.includes(Number(item.split('-')[0])))
            ) {
                sum += Number(values[item]);
            }
        });
        setSum(sum);
        if (Object.keys(value)[0] === 'phytopathogenicArr') {
            values.phytopathogenicArr.forEach(item => {
                const data = phytopathogenics.find(i => i.id === item);
                const curPhyto = curPhytopathogenic.find(i => i.id === item);
                if (curPhyto) {
                    phytopathogenicArr.push(curPhyto);
                } else {
                    phytopathogenicArr.push({
                        id: item, name: data && data.name, koe: null, percentage: null
                    });
                }
            });
            setCurPytopathogenic([...phytopathogenicArr]);
        }
        if (Object.keys(value)[0] === 'suppressiveArr') {
            values.suppressiveArr.forEach(item => {
                const data = suppressives.find(i => i.id === item);
                const curSup = curSuppressive.find(i => i.id === item);
                if (curSup) {
                    suppressiveArr.push(curSup);
                } else {
                    suppressiveArr.push({
                        id: item, name: data && data.name, koe: null, percentage: null
                    });
                }
            });
            setCurSuppressive([...suppressiveArr]);
        }
        if (Object.keys(value)[0] === 'neutralArr') {
            values.neutralArr.forEach(item => {
                const data = neutrals.find(i => i.id === item);
                const curNeut = curSuppressive.find(i => i.id === item);
                if (curNeut) {
                    neutralArr.push(curNeut);
                } else {
                    neutralArr.push({
                        id: item, name: data && data.name, koe: null, percentage: null
                    });
                }
            });
            setCurNeutral([...neutralArr]);
        }
    };

    const onCloseCalculator = () => {
        dispatch(closeCalculatorMyco(id));
    };

    const onChange = (e) => {
        const curType = inputType;
        setInputType(e.target.value);
        const values = form.getFieldsValue(true);
        Object.keys(values).forEach(val => {
            if (val.includes(curType)) form.setFieldsValue({ [val]: '' });
        });
    };

    const notNull = (_, value) => {
        if (value > 0) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(t('not null')));
    };

    const clearFields = (type) => {
        if (type === 'culture') {
            form?.setFieldsValue({
                preceding_culture: null,
                culture: null
            });
        }

        if (type === 'phytopathogenic') {
            form?.setFieldsValue({
                phytopathogenicArr: []
            });
            setCurPytopathogenic([]);
        }
        if (type === 'suppressive') {
            form?.setFieldsValue({
                suppressiveArr: []
            });
            setCurSuppressive([]);
        }
        if (type === 'neutral') {
            form?.setFieldsValue({
                neutralArr: []
            });
            setCurNeutral([]);
        }
    }

    return (
        <Form onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            onValuesChange={onValuesChange}
            form={form}
            className={cn()}
            name="calculator-params">
            <div>
                <Row className={cn('gray')}>
                    <Col span={12} style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                        <Row style={{ alignItems: 'center', width: '100%' }}>
                            <Col span={12} className={cn('label')}>
                                <span>{t('culture choice')}</span>
                            </Col>
                            <Col span={12} className={cn('input')}>
                                <Form.Item
                                    name="culture"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Select
                                        showSearch
                                        disabled={isCompleted}
                                        filterOption={filterOption}>
                                        {
                                            cultures.map(
                                                (item =>
                                                    (
                                                        <Select.Option value={item.id} key={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center', width: '100%', marginTop: '20px' }}>
                            <Col span={12} className={cn('label')}>
                                <span>{t('predecessor choice')}</span>
                            </Col>
                            <Col span={12} className={cn('input')}>
                                <Form.Item
                                    name="preceding_culture"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Select
                                        showSearch
                                        disabled={isCompleted}
                                        filterOption={filterOption}>
                                        {
                                            cultures.map(
                                                (item =>
                                                    (
                                                        <Select.Option value={item.id} key={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className={cn('description')}>
                        <span>
                            {constants.cultureCompare[calculator.cultures_comment]}
                        </span>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button type="secondary" onClick={() => clearFields('culture')}>{t('clear')}</Button>
                    </Col>
                </Row>

                <div className={cn('row-table')}>
                    <Row className={cn('row-title')}>
                        <Col span={24}>{t('results')}</Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }} className={cn('row-table__noborder')}>
                        <Col span={6}>{t('contains type')}</Col>
                        <Col span={18}>
                            <Radio.Group onChange={onChange} defaultValue="koe">
                                <Radio.Button value="koe">{t('koe')}</Radio.Button>
                                <Radio.Button value="percentage">{t('percent')}</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className={cn('row-head')}>
                        <Col span={5} className={cn('row-table__label')}>
                            {t('genus of micro')}
                        </Col>
                        <Col span={5} className={cn('row-table__label')}>
                            {t('contains koe')}
                        </Col>
                        <Col span={5} className={cn('row-table__label')}>
                            {t('contains percent')}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {t('yield')}
                        </Col>
                        <Col span={5} className={cn('row-table__label')}>
                            {t('contains group')}
                        </Col>
                    </Row>
                    <Collapse defaultActiveKey={['1', '2', '3']} ghost className={cn('collapse-panel')}>
                        <Panel header={t('phytopathogenic')} key="1">
                            <Row className={cn('row-table__withborder')}>
                                <Col span={6} className={cn('row-table__label')}>
                                    <Form.Item name="phytopathogenicArr">
                                        <Select mode="multiple"
                                            disabled={isCompleted}
                                            showSearch
                                            allowClear>
                                            {phytopathogenics.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={2}>
                                    <Button type="secondary" onClick={() => clearFields('phytopathogenic')}>{t('clear')}</Button>
                                </Col>
                                <Col span={10} />
                                <Col span={5} className={cn('input')}>
                                    {calculator?.interpretation?.phytopathogenic?.percentage?.toFixed(0)}
                                </Col>
                            </Row>
                            {curPhytopathogenic.map((item, i) => (
                                <Row className={cn('row-table__withborder')} key={item.name + i}>
                                    <Col span={5} className={cn('row-table__label')}>
                                        {item.name}
                                    </Col>
                                    <Col span={5} className={cn('input')}>
                                        <Form.Item name={`${item.id}-koe`} rules={inputType === 'koe' && [{ validator: notNull }]}>
                                            <InputNumber
                                                min={0}
                                                decimalSeparator=","
                                                disabled={isCompleted || inputType === 'percentage'}
                                                placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5} className={cn('input')}>
                                        <Form.Item name={`${item.id}-percentage`} rules={inputType === 'percentage' && [{ validator: notNull }]}>
                                            <InputNumber
                                                min={0}
                                                decimalSeparator=","
                                                disabled={isCompleted || inputType === 'koe'}
                                                placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className={cn('input')}>
                                        {item.losses && item.losses.toFixed(2)}
                                    </Col>
                                </Row>
                            ))}
                            {calculator.interpretation.phytopathogenic.interpretation && (
                                <Row style={{ marginTop: '20px' }} className={cn('row-table__withborder')}>
                                    <b>
                                        {t('interpretation', { value: constants.interpretation[calculator.interpretation.phytopathogenic.interpretation] })}
                                    </b>
                                </Row>
                            )}
                        </Panel>
                        <Panel header={t('suppressive')} key="2">
                            <Row className={cn('row-table__withborder')}>
                                <Col span={6} className={cn('row-table__label')}>
                                    <Form.Item name="suppressiveArr">
                                        <Select mode="multiple" disabled={isCompleted}>
                                            {suppressives.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={2}>
                                    <Button type="secondary" onClick={() => clearFields('suppressive')}>{t('clear')}</Button>
                                </Col>
                                <Col span={10} />
                                <Col span={5} className={cn('input')}>
                                    {calculator.interpretation.suppressive.percentage.toFixed(0)}
                                </Col>
                            </Row>
                            {
                                curSuppressive.map((item, i) => (
                                    <Row className={cn('row-table__withborder')}  key={item.name + i}>
                                        <Col span={5} className={cn('row-table__label')}>
                                            {item.name}
                                        </Col>
                                        <Col span={5} className={cn('input')}>
                                            <Form.Item name={`${item.id}-koe`} rules={inputType === 'koe' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={isCompleted || inputType === 'percentage'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5} className={cn('input')}>
                                            <Form.Item name={`${item.id}-percentage`} rules={inputType === 'percentage' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={isCompleted || inputType === 'koe'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} className={cn('input')}>
                                            {item.losses && item.losses.toFixed(2)}
                                        </Col>
                                    </Row>
                                ))
                            }
                            {calculator.interpretation.suppressive.interpretation && (
                                <Row style={{ marginTop: '20px' }} className={cn('row-table__withborder')}>
                                    <b>
                                        {t('interpretation', { value: constants.interpretation[calculator.interpretation.suppressive.interpretation] })}
                                    </b>
                                </Row>
                            )}
                        </Panel>
                        <Panel header={t('neutral')} key="3">
                            <Row className={cn('row-table__withborder')}>
                                <Col span={6} className={cn('row-table__label')}>
                                    <Form.Item name="neutralArr">
                                        <Select mode="multiple" disabled={isCompleted}>
                                            {neutrals.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={2}>
                                    <Button type="secondary" onClick={() => clearFields('neutral')}>{t('clear')}</Button>
                                </Col>
                                <Col span={10} />
                                <Col span={5} className={cn('input')}>
                                    {calculator.interpretation.neutral.percentage.toFixed(0)}
                                </Col>
                            </Row>
                            {
                                curNeutral.map((item, i) => (
                                    <Row className={cn('row-table__withborder')} key={item.name + i}>
                                        <Col span={5} className={cn('row-table__label')}>
                                            {item.name}
                                        </Col>
                                        <Col span={5} className={cn('input')}>
                                            <Form.Item name={`${item.id}-koe`} rules={inputType === 'koe' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={isCompleted || inputType === 'percentage'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5} className={cn('input')}>
                                            <Form.Item name={`${item.id}-percentage`} rules={inputType === 'percentage' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={isCompleted || inputType === 'koe'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} className={cn('input')}>
                                            {item.losses && item.losses.toFixed(2)}
                                        </Col>
                                    </Row>
                                ))
                            }
                            {calculator.interpretation.neutral.interpretation && (
                                <Row style={{ marginTop: '20px' }} className={cn('row-table__withborder')}>
                                    <b>
                                        {t('interpretation', { value: constants.interpretation[calculator.interpretation.neutral.interpretation] })}
                                    </b>
                                </Row>
                            )}
                        </Panel>
                    </Collapse>
                </div>

                { calculator.interpretation.conclusion && (
                    <Row className={cn('info')}>
                        <Col>
                            <div className={cn('info__inner')}>{constants.mycobiotaCompare[calculator.interpretation.conclusion]}</div>
                        </Col>
                    </Row>
                )}
                { sum > 100 && (
                    <Row className={cn('info')}>
                        <Col>
                            <div className={cn('info__inner')}>{t('sum more')}</div>
                        </Col>
                    </Row>
                )}

            </div>
            <Row className={cn('button')}>
                <Col>
                    <Button
                        type="primary"
                        disabled={isCompleted || sum > 100}
                        htmlType="submit"
                        size="large">
                        {t('calculate')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={showModal}
                        disabled={isCompleted || sum > 100}
                        size="large">
                        {t('end')}
                    </Button>
                    {/* <Button
                        type="default"
                        disabled={isCompleted || sum > 100}
                        size="large">
                        {t('get drug')}
                    </Button> */}
                </Col>
            </Row>
            <SaveMessage
                visible={visible}
                title="end title"
                text="end text"
                okBtn="end save"
                cancelBtn="cancel save"
                onOk={setStatusComplete}
                onCancel={hideModal} />
        </Form>


    );
};