import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getFertilizersBody = state => state.models[name].fertilizers;
export const getFertilizersList = state => state.models[name].fertilizers.results;
export const getFertilizersListCount = state => state.models[name].fertilizers.count;
export const getCurrentFertilizer = state => state.models[name].fertilizer;

export const getFertilizerTypesList = state => state.models[name].type.data;
export const getFertilizerGroupList = state => state.models[name].group.data;
export const getFertilizerSubGroupList = state => state.models[name].subgroup.data;
export const getFertilizerReleaseFormList = state => state.models[name].releaseForm.data;
export const getFertilizerMainDrugList = state => state.models[name].mainDrug.data;

export const isLoadingFertilizer = state => state.models[name].loading;
