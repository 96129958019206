import { combineReducers } from 'redux';

import calculator from './calculator';
import calculators from './calculators';
import shape from './shape';
import probabilityData from './probability-data';
import points from './points';
import loading from './loading';
import fieldLoading from './field-loading';
import forecastLoading from './forecast-loading';
import calculatorGroupResult from './calc-group-result';
import fieldYears from './field-years';

export default combineReducers({
    fieldYears,
    calculatorGroupResult,
    calculator,
    calculators,
    shape,
    forecastLoading,
    probabilityData,
    fieldLoading,
    points,
    loading
});
