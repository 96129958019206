import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Button, Input
} from 'antd';
import { useTranslation } from 'react-i18next';

import '../style.less';
import { curNewUsr } from '../../../models/sample-log/selectors';
import { addOwnerSampleLog } from '../../../models/sample-log/actions';
import { getUser } from '../../../models/account/selectors';

const NewUserModal = ({
    isOpened, setOpened
}) => {
    const { t } = useTranslation('sample log');

    const dispatch = useDispatch();

    const currentUser = useSelector(state => getUser(state));
    const newUsr = useSelector(state => curNewUsr(state));

    const [ownerName, setOwnerName] = useState('');

    useEffect(() => {
        if (newUsr?.id && isOpened === true) {
            setOpened(false);
        }
    }, [newUsr]);

    const handleOk = () => {
        if (currentUser?.id) {
            dispatch(addOwnerSampleLog({
                values: {
                    name: ownerName,
                    user: currentUser?.id
                }
            }));
        }
    };

    const handleCancel = () => {
        setOpened(false);
    };

    return (
        <Modal
            visible={isOpened}
            title={t('add owner user')}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    OK
                </Button>
            ]}>
            <p>
                {t('new owner name')}
            </p>
            <Input
                onChange={(evt) => setOwnerName(evt?.target?.value ? evt?.target?.value : '')} />
        </Modal>
    );
};

export default NewUserModal;