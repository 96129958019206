import { login, getProfile, verify } from '../constants';

export default function reducer(state = false, action) {
    switch (action.type) {
        case getProfile.success:
        case verify.success:
        case login.success: {
            return true;
        }

        case 'LOGOUT':
        case login.error:
        case login.try:
        case getProfile.error:
        case getProfile.try: {
            return false;
        }

        default: return state;
    }
}
