import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import converter from './converter';

function* removeReviewSaga({ payload: id }) {
    const [start, success, failure] = getActions(
        constants.removeReview.try,
        constants.removeReview.success,
        constants.removeReview.error
    );
    try {
        yield put(start(id));
        yield call(api.removeReview, id);
        const obj = converter.removedReviewId2Client(id);
        yield put(success(obj));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getReviews.toString(),
            defaultSaga({
                constants: constants.getReviews,
                after: converter.reviewsFullInfo2Client,
                api: api.getReviews
            })
        ),
        yield takeLatest(
            constants.getReview.toString(),
            defaultSaga({
                constants: constants.getReview,
                api: api.getReview
            })
        ),
        yield takeLatest(
            constants.addReview.toString(),
            defaultSaga({
                constants: constants.addReview,
                api: api.addReview
            })
        ),
        yield takeLatest(
            constants.updateReview.toString(),
            defaultSaga({
                constants: constants.updateReview,
                api: api.updateReview
            })
        ),
        yield takeLatest(
            constants.getAverageRate.toString(),
            defaultSaga({
                constants: constants.getAverageRate,
                api: api.getAverageRate
            })
        ),
        yield takeLatest(
            constants.removeReview.toString(),
            removeReviewSaga
        )
    ]);
}