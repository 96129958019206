import { getConstant } from '../utils';


export const getDeterminants = getConstant('GET_DETERMINANTS');
export const clearDeterminants = getConstant('CLEAR_DETERMINANTS');

export const getDeterminant = getConstant('GET_DETERMINANT');
export const addDeterminant = getConstant('ADD_DETERMINANT');
export const addSimpleDeterminant = getConstant('ADD_SIMPLE_DETERMINANT');
export const clearDeterminant = getConstant('CLEAR_DETERMINANT');

export const getDeterminantHistory = getConstant('GET_DETERMINANT_HISTORY');
export const clearDeterminantHistory = getConstant('CLEAR_DETERMINANT_HISTORY');

export const getDeterminantItem = getConstant('GET_DETERMINANT_ITEM');
export const clearDeterminantItem = getConstant('CLEAR_DETERMINANT_ITEM');

export const removeDeterminant = getConstant('REMOVE_DETERMINANT');

export const setAnswer = getConstant('SET_ANSWER');
export const setSimpleAnswer = getConstant('SET_SIMPLE_ANSWER');
export const resetAnswer = getConstant('RESET_ANSWER');

export const getQuestion = getConstant('GET_QUESTION');
export const clearQuestion = getConstant('CLEAR_QUESTION');
export const getQuestions = getConstant('GET_QUESTIONS');
export const clearQuestions = getConstant('CLEAR_QUESTIONS');
