import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';

import '../style.less';

import { toFixed } from '../../../utils/utils';
import { curMycoFields } from '../../../models/mycological-registry/selectors';
import { getFilteredFields, getUsersList } from '../../../models/mycological-registry/actions';
import { editSampleRow } from '../../../models/sample-log/actions';
import { useClassName } from '../../../utils/cn';
import { DATEPICKER_FORMAT } from '../../../constants';

const { TextArea } = Input;

const elementsList = [
    'NH4', 'K20', 'Na', 'Mg',
    'Ca', 'SO4', 'S', 'N',
    'NO3', 'PO4', 'P2O5',
    'ph_salt', 'ph_water',
    'E', 'organic_substances'
];

const SampleModal = ({
    editableEvent = {},
    handleOk, handleCancel,
    isOpened, form, currentSample,
    fullUserList
}) => {
    const dispatch = useDispatch();
    const cn = useClassName('calculator-detail-params');
    const { t } = useTranslation('sample log');

    const fields = useSelector(state => curMycoFields(state));

    const [fieldsArray, setFieldsArray] = useState([]);

    useEffect(() => {
        const fieldsListArr = [];
        const fldKeysArr = Object.keys(fields);

        fldKeysArr.forEach(key => {
            if (key !== 'next') {
                fieldsListArr.push(fields[key]);
            }
        });

        setFieldsArray(fieldsListArr);
    }, [fields]);

    useEffect(() => {
        dispatch(getUsersList());
    }, []);

    const onUserSelect = (userId) => {
        dispatch(getFilteredFields({
            userId: userId
        }));
    };

    const onOwnerSearch = (value) => {
        dispatch(getUsersList({
            searchString: value
        }));
    };

    const onFieldSearch = (value) => {
        dispatch(getFilteredFields({
            userId: form.getFieldValue('owner'),
            searchString: value
        }));
    };

    const onEditionFinish = () => {
        dispatch(editSampleRow({
            sampleRowId: 0,
            body: {}
        }));
    };

    const handleDateInput = (dataIndex, newVals) => {
        const dateSplit = newVals?.length > 0 ? newVals?.split('.') : [];
        form?.setFieldsValue({
            [dataIndex]: `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`
        });
    };

    return (
        <Modal
            width={864}
            onOk={handleOk}
            onCancel={handleCancel}
            visible={isOpened}
            footer={[
                <Button onClick={handleCancel} type="secondary">
                    {t('modal cancel button')}
                </Button>,
                <Button onClick={handleOk}>
                    {t('modal ok button')}
                </Button>
            ]}
            title={t('modal title')}>
            <Row>
                <Col span={11}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 12 }}
                        name="owner"
                        label={t('row card owner')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <Select
                            disabled
                            showSearch
                            filterOption={(inputValue, option) =>
                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                            onChange={onUserSelect}
                            onSearch={onOwnerSearch}
                            options={fullUserList?.map(x => {
                                return {
                                    label: x.name,
                                    value: x.id
                                };
                            })}
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        name="num"
                        label={t('row card num')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <InputNumber
                            decimalSeparator=","
                            disabled={true}
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 12 }}
                        name="field_num"
                        label={t('row card field num')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <Select
                            showSearch
                            onSearch={onFieldSearch}
                            filterOption={(inputValue, option) =>
                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                            options={fieldsArray?.filter(fld => fld.field_num !== null).map(x => {
                                return {
                                    label: x.field_num,
                                    value: x.field_num
                                };
                            })}
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        name="point"
                        label={t('row card point')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 12 }}
                        name="vega_key"
                        label={t('row card field name')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <Select
                            showSearch
                            filterOption={(inputValue, option) =>
                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                            options={fieldsArray?.filter(fld => fld.field_num !== null).map(x => {
                                return {
                                    label: x.vega_key,
                                    value: x.vega_key
                                };
                            })}
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        name="dist_water_volume"
                        label={t('row card dilution_factor')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 12 }}
                        name="date"
                        label={t('row card date')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <DatePicker
                            format={DATEPICKER_FORMAT}
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        name="air_weigth"
                        label={t('row card dist_water_volume')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 12 }}
                        name="analysis_date"
                        label={t('row card analysis date')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <DatePicker
                            format={DATEPICKER_FORMAT}
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        // noStyle
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        name="dilution_factor"
                        label={t('row card air_weigth')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Row>
                        <Col span={10} />
                        <Col span={6}>
                            <Form.Item name="K" label={t('row card K')} rules={[{ required: true, message: '' }]} help="">
                                <Select
                                    options={[
                                        {
                                            label: '0',
                                            value: 0
                                        },
                                        {
                                            label: '1',
                                            value: 1
                                        }
                                    ]}
                                    className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={6}>
                            <Form.Item name="A" label={t('row card A')} rules={[{ required: true, message: '' }]} help="">
                                <Select
                                    options={[
                                        {
                                            label: '0',
                                            value: 0
                                        },
                                        {
                                            label: '1',
                                            value: 1
                                        }
                                    ]}
                                    className="w-100" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        name="method"
                        label={t('row card method')}
                        rules={[{ required: true, message: '' }]} help="">
                        <Select
                            options={[
                                {
                                    label: 'Ч',
                                    value: 'chirikov'
                                },
                                {
                                    label: 'K',
                                    value: 'kirsanov'
                                },
                                {
                                    label: 'М',
                                    value: 'machigin'
                                }
                            ]}
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 12 }}
                        name="productivity_zone"
                        label={t('table header productivity zone')}
                        rules={[{ required: true, message: '' }]} help="">
                        <Select
                            options={[
                                {
                                    label: 'Высокая',
                                    value: 'high'
                                },
                                {
                                    label: 'Средняя',
                                    value: 'middle'
                                },
                                {
                                    label: 'Низкая',
                                    value: 'low'
                                }
                            ]}
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        name="zone_area"
                        label={t('table header productivity zone area')}
                        rules={[{ required: true, message: '' }]}
                        help="">
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="comment"
                        labelCol={{ span: 24 }}
                        label={t('row card comment')}>
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <div className={cn('row-table')} style={{ overflowX: 'auto !important' }}>
                {currentSample?.id && (
                    <Row className={cn('row-head')}>
                        <Col span={4} className={cn('row-table__label')}>
                            {t('sample row card table header 1')}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {t('sample row card table header 2')}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {t('sample row card table header 3')}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {t('sample row card table header 4')}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {t('sample row card table header 5')}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {t('sample row card table header 6')}
                        </Col>
                    </Row>
                )}
                {currentSample?.id && elementsList.map(elName => (
                    <Row className={cn('row-table__withborder')}>
                        <Col span={4} className={cn('row-table__label')}>
                            {t(`row card ${elName} full name`)}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {t(`row card ${elName} short name`)}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {(elName === 'S' || elName === 'NO3' || elName === 'P2O5') ? '-' : currentSample[elName] ? toFixed(currentSample[elName], 1) : '-'}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {(elName === 'S' || elName === 'NO3' || elName === 'P2O5') ? currentSample[elName] ? toFixed(currentSample[elName], 1) : '-' : currentSample[`${elName}_result`] ? toFixed(currentSample[`${elName}_result`], 1) : '-'}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {currentSample[`${elName}_interval_name`] ? t(`${currentSample[`${elName}_interval_name`]}`) : '-'}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            {currentSample[`${elName}_interval`] ? currentSample[`${elName}_interval`] : '-'}
                        </Col>
                    </Row>
                ))}
            </div>
        </Modal>
    );
};

export default SampleModal;