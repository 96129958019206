import { getConstant } from '../utils';

export const getRecommendationRegistries = getConstant('GET_RECOMMENDATION_REGISTRIES');
export const createRecommendationRegistry = getConstant('CREATE_RECOMMENDATION_REGISTRY');
export const getCompositions = getConstant('GET_COMPOSITIONS_RECOMMENDATION_REGISTRY');
export const getCultures = getConstant('GET_CULTURES_RECOMMENDATION_REGISTRY');
export const getRegions = getConstant('GET_REGIONS_RECOMMENDATION_REGISTRY');
export const getSubtypes = getConstant('GET_SUBTYPES_RECOMMENDATION_REGISTRY');
export const calculateRecommendation = getConstant('CALCULATE_RECOMMENDATION_RECOMMENDATION_REGISTRY');
export const makeParameterSpread = getConstant('MAKE_PARAMETER_SPREAD_RECOMMENDATION_REGISTRY');
export const makeReportGeneral = getConstant('MAKE_REPORT_GENERAL_RECOMMENDATION_REGISTRY');
export const makeReportVolume = getConstant('MAKE_REPORT_VOLUME_RECOMMENDATION_REGISTRY');
export const makeReportNorm = getConstant('MAKE_REPORT_NORM_RECOMMENDATION_REGISTRY');