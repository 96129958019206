import * as constants from './constants';

export const getNormative = payload => ({
    type: constants.getNormative.toString(),
    payload
});

export const getNormatives = payload => ({
    type: constants.getNormatives.toString(),
    payload
});

export const addNormative = payload => ({
    type: constants.addNormative.toString(),
    payload
});

export const updateNormative = payload => ({
    type: constants.updateNormative.toString(),
    payload
});

export const clearNormative = payload => ({
    type: constants.clearNormative.toString()
});
