import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';

function* calculateLeafSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.calculateDiagnostics.try,
        constants.calculateDiagnostics.success,
        constants.calculateDiagnostics.error
    );
    try {
        yield put(start());
        const dataRaw = yield call(api.calculateDiagnostics, payload);
        const fedCalculator = dataRaw?.calculator?.fed;
        yield put(success(fedCalculator));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getLeafFieldPoints.toString(),
            defaultSaga({
                constants: constants.getLeafFieldPoints,
                api: api.getLeafFieldPoints
            })
        ),
        yield takeLatest(
            constants.calculateDiagnostics.toString(),
            calculateLeafSaga
        )
    ]);
}