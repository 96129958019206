import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'antd';
import { useClassName } from '../../../../../utils/cn';

const MUSHROOM_TYPES = [
    { value: 'bipolaris', title: 'bipolaris' },
    { value: 'pythium', title: 'pythium' },
    { value: 'alternaria', title: 'alternaria' },
    { value: 'cladosporium', title: 'cladosporium' },
    { value: 'blumeria_graminis', title: 'blumeria_graminis' },
    { value: 'fusarium', title: 'fusarium' },
    { value: 'phoma', title: 'phoma' },
    { value: 'murogenella', title: 'murogenella' }
];

const MushroomTable = ({ tableData }) => {
    const cn = useClassName('calculator-detail-params');
    const { t } = useTranslation('field representation mycological analysis');

    return (
        <div className="mushroom_table">
            <Row className={cn('row-head')}>
                <Col className={cn('row-table__label')} span={4}>
                    {t('mushroom table col 1')}
                </Col>
                <Col className={cn('row-table__label')} span={4}>
                    {t('mushroom table col 2')}
                </Col>
                <Col className={cn('row-table__label')} span={4}>
                    {t('mushroom table col 3')}
                </Col>
                <Col className={cn('row-table__label')} span={12}>
                    {t('mushroom table col 4')}
                </Col>
            </Row>
            {MUSHROOM_TYPES?.map(mushroomType => (
                <Row className={cn('row-table__withborder')}>
                    <Col className={cn('row-table__label')} span={4}>
                        {t(`${mushroomType.value}`)}
                    </Col>
                    <Col className={cn('row-table__label')} span={4}>
                        {tableData && tableData[`${mushroomType.value}`]?.value}
                    </Col>
                    <Col className={cn('row-table__label')} span={4}>
                        {tableData && tableData[`${mushroomType.value}`]?.soil_type}
                    </Col>
                    <Col className={cn('row-table__label')} span={12}>
                        {tableData && tableData[`${mushroomType.value}`]?.characteristic}
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default MushroomTable;