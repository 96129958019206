import { combineReducers } from 'redux';

import review from './review';
import reviews from './reviews';
import averageRate from './average-rate';
import loading from './loading';

export default combineReducers({
    reviews,
    review,
    averageRate,
    loading
});
