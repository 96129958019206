import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getRB.try:
        case constants.addRB.try:
        case constants.updateRB.try: {
            return true;
        }

        case constants.getRB.success:
        case constants.addRB.success:
        case constants.updateRB.success:
        case constants.getRB.error:
        case constants.addRB.error:
        case constants.updateRB.error: {
            return false;
        }

        default:
            return state;
    }
}