import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

// import { useClassName } from 'utils/cn';

import '../style.less';
import { Button, Col, Collapse, Modal, Row, Checkbox } from 'antd';

const { Panel } = Collapse;

const elementsList = [
    'N', 'P', 'KS', 'KCl', 'Ca',
    'Mg', 'B', 'Cu', 'Zn', 'Mn',
    'Fe', 'Mo', 'Co', 'I'
];

const fedAttributes = [
    'owner', 'field_num', 'productivity_zone', 'field_points', 'culture',
    'acceptation_date', 'examination_date', 'zone_area', 'protocol_number', 'date_created',
    'b_a_illumination', 'ch_activity', 'deviation'
];

const imapAttributes = [
    'owner', 'field_num', 'year', 'culture', 'sample_date',
    'test_date', 'num', 'mycological_analysis', 'flotation', 'microorganisms'
];

const phytoAttributes = [
    'sample_date', 'analysis_date', 'owner', 'culture', 'sort', 'reproduction',
    'year', 'external_pathogen', 'internal_pathogen', 'germination', 'seeding_rate', 'drugs'
];

const agroAttributes = [
    'owner', 'field_num', 'productivity_zone', 'point',
    'date', 'analysis_date', 'zone_area'
];

const agroElementsList = [
    'NH4', 'K20', 'Na', 'Mg', 'Ca', 'Cl', 'SO4', 'S', 'N',
    'NO3', 'PO4', 'P2O5', 'ph_water', 'ph_salt', 'num', 'method', 'electrical_conductivity', 'organic_substances'
];

const DetailsModal = ({
    setIsOpen, isOpen,
    currentSettings, setCurrentSettings,
    //
    currentFedParams, setCurrentFedParams,
    currentImapParams, setCurrentImapParams,
    currentAgroParams, setCurrentAgroParams,
    currentAgroElements, setCurrentAgroElements,
    currentPhytoParams, setCurrentPhytoParams
    //
}) => {
    const { t } = useTranslation('general protocol');

    const [defaultActiveElements, setDefaultActiveElements] = useState(elementsList);
    const [defaultActiveFedParams, setDefaultActiveFedParams] = useState(fedAttributes);
    const [defaultActiveImapParams, setDefaultActiveImapParams] = useState(imapAttributes);
    const [defaultActiveAgroParams, setDefaultActiveAgroParams] = useState(agroAttributes);
    const [defaultActiveAgroElements, setDefaultActiveAgroElements] = useState(agroElementsList);
    const [defaultActivePhytoParams, setDefaultActivePhytoParams] = useState(phytoAttributes);

    useEffect(() => {
        setDefaultActiveElements(elementsList.filter(item => !currentSettings?.includes(item)));
        setDefaultActiveFedParams(fedAttributes.filter(item => !currentFedParams?.includes(item)));
        setDefaultActiveImapParams(imapAttributes.filter(item => !currentImapParams?.includes(item)));
        setDefaultActiveAgroParams(agroAttributes.filter(item => !currentAgroParams?.includes(item)));
        setDefaultActiveAgroElements(agroElementsList.filter(item => !currentAgroElements?.includes(item)));
        setDefaultActivePhytoParams(phytoAttributes.filter(item => !currentPhytoParams?.includes(item)));
    }, []);
    
    const updateSelectedElements = (notSelect) => {
        setCurrentSettings(elementsList.filter(item => !notSelect.includes(item)));
    };

    const updateSelectedFedParams = (notSelect) => {
        setCurrentFedParams(fedAttributes.filter(item => !notSelect.includes(item)));
    };

    const updateSelectedImapParams = (notSelect) => {
        setCurrentImapParams(imapAttributes.filter(item => !notSelect.includes(item)));
    };

    const updateSelectedPhytoParams = (notSelect) => {
        setCurrentPhytoParams(phytoAttributes.filter(item => !notSelect.includes(item)));
    };

    const updateSelectedAgroParams = (notSelect) => {
        setCurrentAgroParams(agroAttributes.filter(item => !notSelect.includes(item)));
    };
    
    const updateSelectedAgroElements = (notSelect) => {
        setCurrentAgroElements(agroElementsList.filter(item => !notSelect.includes(item)));
    };

    const handleOk = () => {
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const handleSelectElements = (selectedItems) => {
        updateSelectedElements(selectedItems);
    };

    const handleSelectFedParams = (selectedItems) => {
        updateSelectedFedParams(selectedItems);
    };

    const handleSelectImapParams = (selectedItems) => {
        updateSelectedImapParams(selectedItems);
    };

    const handleSelectPhytoParams = (selectedItems) => {
        updateSelectedPhytoParams(selectedItems);
    };

    const handleSelectAgroParams = (selectedItems) => {
        updateSelectedAgroParams(selectedItems);
    };

    const handleSelectAgroElements = (selectedItems) => {
        updateSelectedAgroElements(selectedItems);
    };

    return (
        <Modal
            footer={[
                <Button key="back" onClick={handleCancel}>
                    {t('modal detailed close')}
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t('modal detailed ok')}
                </Button>
            ]}
            style={{ overflowY: 'auto' }}
            width={800}
            title={t('detailed download modal title')}
            onCancel={handleCancel}
            onOk={handleOk}
            visible={isOpen}>
                <h3>{t('fed protocol data header')}</h3>
                <Checkbox.Group
                    style={{ width: '100%', marginBottom: '20px' }}
                    // options={elementsOptions}
                    defaultValue={defaultActiveFedParams}
                    onChange={handleSelectFedParams}>
                        {[['owner', 'field_num', 'productivity_zone'], ['field_points', 'culture', 'acceptation_date'], ['date_created', 'zone_area', 'protocol_number'], ['examination_date', 'b_a_illumination'], ['ch_activity', 'deviation']].map((elementRow) => (
                            <Row>
                                {elementRow?.map(element => (
                                    <Col span={elementRow?.length === 1 ? 24 : elementRow?.length === 2 ? 12 : 8}>
                                        <Checkbox value={element}>{t(`fed attribute ${element}`)}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                </Checkbox.Group>
                <Checkbox.Group
                    style={{ width: '100%', marginBottom: '20px' }}
                    // options={elementsOptions}
                    defaultValue={defaultActiveElements}
                    onChange={handleSelectElements}>
                        {[['N', 'P', 'KS'], ['KCl', 'Ca', 'Mg'], ['B', 'Cu', 'Zn'], ['Mn','Fe', 'Mo'], ['Co', 'I']].map((elementRow) => (
                            <Row>
                                {elementRow?.map(element => (
                                    <Col span={8}>
                                        <Checkbox value={element}>{t(`chemical element ${element}`)}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                </Checkbox.Group>
                
                <h3>{t('imap protocol data header')}</h3>
                <Checkbox.Group
                    style={{ width: '100%', marginBottom: '20px' }}
                    // options={elementsOptions}
                    defaultValue={defaultActiveImapParams}
                    onChange={handleSelectImapParams}>
                        {[['owner', 'field_num', 'year'], ['culture', 'sample_date', 'num'], ['test_date'], ['mycological_analysis'], ['flotation'], ['microorganisms']].map((elementRow) => (
                            <Row>
                                {elementRow?.map(element => (
                                    <Col span={elementRow?.length === 1 ? 24 : 8}>
                                        <Checkbox value={element}>{t(`imap attribute ${element}`)}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                </Checkbox.Group>

                <h3>{t('agro protocol data header')}</h3>
                <Checkbox.Group
                    style={{ width: '100%', marginBottom: '20px' }}
                    // options={elementsOptions}
                    defaultValue={defaultActiveAgroParams}
                    onChange={handleSelectAgroParams}>
                        {[['owner', 'field_num', 'productivity_zone'], ['point', 'date', 'zone_area'], ['analysis_date']].map((elementRow) => (
                            <Row>
                                {elementRow?.map(element => (
                                    <Col span={elementRow?.length === 1 ? 24 : 8}>
                                        <Checkbox value={element}>{t(`agro attribute ${element}`)}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                </Checkbox.Group>
                <Checkbox.Group
                    style={{ width: '100%', marginBottom: '20px' }}
                    // options={elementsOptions}
                    defaultValue={defaultActiveAgroElements}
                    onChange={handleSelectAgroElements}>
                        {[['NH4', 'K20', 'Na'], ['Mg', 'Ca', 'Cl'], ['SO4', 'S', 'N'], ['NO3', 'PO4', 'P2O5'], ['ph_water', 'ph_salt', 'num'], ['method', 'electrical_conductivity', 'organic_substances']].map((elementRow) => (
                            <Row>
                                {elementRow?.map(element => (
                                    <Col span={elementRow?.length === 1 ? 24 : 8}>
                                        <Checkbox value={element}>{t(`agro element ${element}`)}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                </Checkbox.Group>

                <h3>{t('phyto protocol data header')}</h3>
                <Checkbox.Group
                    style={{ width: '100%', marginBottom: '20px' }}
                    // options={elementsOptions}
                    defaultValue={defaultActivePhytoParams}
                    onChange={handleSelectPhytoParams}>
                        {[['sample_date', 'analysis_date', 'owner'], ['culture', 'sort', 'reproduction'], ['year', 'external_pathogen', 'internal_pathogen'], ['germination', 'seeding_rate', 'drugs']].map((elementRow) => (
                            <Row>
                                {elementRow?.map(element => (
                                    <Col span={elementRow?.length === 1 ? 24 : 8}>
                                        <Checkbox value={element}>{t(`phyto attribute ${element}`)}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                </Checkbox.Group>
        </Modal>
    );
};

export default DetailsModal;