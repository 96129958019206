import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;


export const getPivotTableMapData = () => request.get({
    url: urlPath('maps/?page_size=1000')
});

export const getPivotTableByField = () => request.get({
    url: urlPath('fields/?page_size=1000')
});

export const getPivotTableByFieldTotal = () => request.get({
    url: urlPath('results/fertilizers/')
});

export const getPivotTableByCulture = () => request.get({
    url: urlPath('cultures/result/')
});

export const getPivotTableByCultureTotal = () => request.get({
    url: urlPath('cultures/result/')
});

export const getPivotByAgroTechicalPeriod = () => request.get({
    url: urlPath('agrotechnical/periods/')
});
export const getPivotByAgroTechicalFields = () => request.get({
    url: urlPath('agrotechnical/fields/')
});


export const getPivotByAgroTechicalPeriodMock = () => Promise.resolve(
    {
        events: [
            {
                id: 1,
                name: 'Сев',
                periods: [
                    {
                        date: '13.06.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            }
                        ]
                    },
                    {
                        date: '04.07.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '04.07.2022',
                                date_fact: '10.07.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '04.07.2022',
                                date_fact: '10.07.2022',
                            }
                        ]
                    },
                    {
                        date_from: '25.07.2022',
                        date_to: '31.07.2022',
                        fields: [
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '04.07.2022',
                                date_fact: '10.07.2022',
                            }
                        ]
                    },
                    {
                        date: '01.08.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            }
                        ]
                    }
                ]
            },
            {
                id: 2,
                name: 'Обработка 1',
                periods: [
                    {
                        date: '20.06.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '20.06.2022',
                                date_fact: '26.06.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '20.06.2022',
                                date_fact: '26.06.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '20.06.2022',
                                date_fact: '26.06.2022',
                            }
                        ]
                    },
                    {
                        date: '04.07.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '04.07.2022',
                                date_fact: '10.07.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                }
                            }
                        ]
                    },
                    {
                        date: '18.07.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '18.07.2022',
                                date_fact: '24.07.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '18.07.2022',
                                date_fact: '24.07.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '18.07.2022',
                                date_fact: '24.07.2022',
                            }
                        ]
                    },
                    {
                        date: '25.07.2022',
                        fields: [
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '25.07.2022',
                                date_fact: '31.07.2022',
                            }
                        ]
                    },
                    {
                        date: '01.08.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            }
                        ]
                    }
                ]
            },
            {
                id: 3,
                name: 'Обработка 2',
                periods: [
                    {
                        date: '13.06.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            }
                        ]
                    },
                    {
                        date: '11.07.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '11.07.2022',
                                date_fact: '17.07.2022',
                            },
                            {
                                field_name: 'поле3',
                                field_number: 'поле3',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '11.07.2022',
                                date_fact: '17.07.2022',
                            }
                        ]
                    },
                    {
                        date: '25.07.2022',
                        fields: [
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '25.07.2022',
                                date_fact: '31.07.2022',
                            }
                        ]
                    },
                    {
                        date: '01.08.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            }
                        ]
                    }
                ]
            },
            {
                id: 3,
                name: 'Обработка 3',
                periods: [
                    {
                        date: '13.06.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '13.06.2022',
                                date_fact: '19.06.2022',
                            }
                        ]
                    },
                    {
                        date: '04.07.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '04.07.2022',
                                date_fact: '10.07.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '04.07.2022',
                                date_fact: '10.07.2022',
                            }
                        ]
                    },
                    {
                        date: '25.07.2022',
                        fields: [
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '25.07.2022',
                                date_fact: '31.07.2022',
                            }
                        ]
                    },
                    {
                        date: '01.08.2022',
                        fields: [
                            {
                                field_name: 'поле1',
                                field_number: 'поле1',
                                culture: {
                                    id: 1,
                                    name: 'Пшеница',
                                    color: '#03B575'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле2',
                                field_number: 'поле2',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#1890FF'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            },
                            {
                                field_name: 'поле3 (факт)',
                                field_number: 'поле3 (факт)',
                                culture: {
                                    id: 2,
                                    name: 'Пшеница',
                                    color: '#FF7E77'
                                },
                                day: '01.08.2022',
                                date_fact: '07.08.2022',
                            }
                        ]
                    }
                ]
            }
        ],
        periods: [
            {
                date: '13.06.2022',
            },
            {
                date: '20.06.2022',
            },
            {
                date: '27.06.2022',
            },
            {
                date: '04.07.2022',
            },
            {
                date: '11.07.2022',
            },
            {
                date: '18.07.2022',
            },
            {
                date: '25.07.2022',
            },
            {
                date: '01.08.2022',
            }
        ]
    }
);
export const getPivotByAgroTechicalFieldsMock = () => request.get({
    url: urlPath('agrotechical/fields/')
});