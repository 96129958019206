import * as constants from '../constants';

const initialState = {
    rb: [],
    norm: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorsRBRegionCulture.success: {
            return { ...state, rb: action.payload.results };
        }

        case constants.getCalculatorsNormRegionCulture.success: {
            return { ...state, norm: action.payload.results };
        }
        
        default:
            return state;
    }
}
