import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Col, Form, InputNumber, Row, Select, Collapse, Modal, Image, Upload, Table, Popconfirm, Input, Popover, Tabs
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { jsPDF } from 'jspdf';

import { useClassName } from '../../../utils/cn';

import '../style.less';

import InfoModal from './info-modal';
import {
    addCausativeAgent,
    addExternalContamination,
    getCausativeAgents,
    updateRegistryPhyto,
    uploadRegistryFile,
    updateExternalContamination,
    updatePhotoDescription,
    newKindPhyto,
    newAgentPhyto,
    newDiseasePhyto,
    newConnectionPhyto,
    getVegPhasesPhyto,
    getSubgroupsPhyto,
    getSymptomsPhyto,
    getFertilizersPhyto,
    getProtocolPhyto,
    getDrugsPhyto,
    getCausativeAgentItems,
    getDiseasesPhyto,
    removeExternalContamination
} from '../../../models/calculators-phyto/actions';
import {
    currentCausativeAgentsPhyto,
    currentContaminationCreatedPhyto,
    currentProtocolPhyto,
    sympPhyto, subPhyto, vegPhyto,
    uploadedFilePhyto,
    currentFertilizersPhyto,
    drugsPhyto,
    currentCausativeAgentItemsPhyto,
    curDiseasesPhyto
} from '../../../models/calculators-phyto/selectors';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as constants from '../../../constants';
import { useForm } from 'antd/lib/form/Form';
import { getCulturesCatalog } from '../../../models/cultures/selectors';
import { getCultures } from '../../../models/cultures/actions';
import { get } from 'leaflet/src/dom/DomUtil';

const { Panel } = Collapse;
const { confirm } = Modal;

const ExternalContamination = ({
    activeTabs, setActiveTabs,
    form, images, calculatorId,
    pathogens, fertilizersFullList
}) => {
    const history = useHistory();
    const [agentsForm] = useForm();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const { t: pdfT } = useTranslation('phyto calculator');

    const uploadedRegistryFile = useSelector(state => uploadedFilePhyto(state));
    const agents = useSelector(state => currentCausativeAgentsPhyto(state));
    const agentItems = useSelector(state => currentCausativeAgentItemsPhyto(state));

    const symptoms = useSelector(state => sympPhyto(state));
    const subgroups = useSelector(state => subPhyto(state));
    const vegPhases = useSelector(state => vegPhyto(state));
    const cultures = useSelector(state => getCulturesCatalog(state));
    const diseases = useSelector(state => curDiseasesPhyto(state));

    useEffect(() => {
        pathogens?.length > 0 && setActiveDrugTab(pathogens[0].id);
    }, [pathogens]);
    
    useEffect(() => {
        dispatch(getVegPhasesPhyto({}));
        dispatch(getSubgroupsPhyto());
        dispatch(getSymptomsPhyto());
        dispatch(getCultures('?page_size=1000'));
    }, []);
    
    const [agentModalOpen, setAgentModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [agentPage, setAgentPage] = useState(1);
    const [diseasesPage, setDiseasesPage] = useState(1);
    const [diseasesFullList, setDiseasesFullList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [agentItemPage, setAgentItemPage] = useState(1);
    const [agentItemFullList, setAgentItemFullList] = useState([]);
    const [currentDisease, setCurrentDisease] = useState({});
    const [contaminationStage, setContaminationStage] = useState(1);
    const [showDrugModal, setShowDrugModal] = useState(false);
    const [drugDisease, setDrugDisease] = useState({});
    const [activeDrugTab, setActiveDrugTab] = useState(null);
    const [creationType, setCreationType] = useState('');
    const [selectedDrugList, setSelectedDrugList] = useState([]);
    
    useEffect(() => {
        const { image_type, uploaded_file } = uploadedRegistryFile;
        if (uploaded_file?.id && image_type === 'external_contamination') {
            let newImg = [];
            if (images?.length > 0) {
                newImg = images?.map(x => x?.id);
            }
            
            setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));

            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    external_pathogen_images: [...newImg, uploaded_file?.id]
                }
            }));

            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }, [uploadedRegistryFile]);

    const handleUpdatePhotoDescription = (currentPhoto, newDescription) => {
        dispatch(updatePhotoDescription({
            imageId: currentPhoto?.id,
            values: {
                picture_desc: newDescription
            }
        }));
    };

    const editableDescriptionContent = (photoInstance, oldVals) => {
        const [photoDescription, setPhotoDescription] = useState('');

        useEffect(() => {
            setPhotoDescription(oldVals)
        }, [oldVals])

        return (
            <div style={{ width: '300px', height: '200px' }}>
                <Row style={{ height: '150px' }}>
                    <Input.TextArea
                        style={{ maxHeight: '150px' }}
                        value={photoDescription}
                        defaultValue={oldVals}
                        onChange={evt => setPhotoDescription(evt?.target?.value)} />
                </Row>
                <Row align="middle" gutter={12} style={{ height: '50px' }}>
                    <Col span={24}>
                        <Button onClick={() => handleUpdatePhotoDescription(photoInstance, photoDescription)} type="primary" style={{ width: '100%' }}>
                            {t('save photo changes')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    const imagesColumns = [
        {
            key: "picture_file",
            dataIndex: "picture_file",
            render: value => value ? (
                <Image width={100} src={value} />
            ) : "-"
        },
        {
            key: "picture_name",
            dataIndex: "picture_name"
        },
        {
            key: "picture_desc",
            dataIndex: "picture_desc",
            render: (value, record) => (
                <Popover content={() => editableDescriptionContent(record, value)} trigger="click">
                    {value}
                </Popover>
            )
        },
        {
            key: "uploaded",
            dataIndex: "uploaded",
            render: value => value ? moment(value).format('DD.MM.YYYY') : "-"
        },
        {
            key: "actions",
            dataIndex: '',
            render: (value, record) => (
                <DeleteOutlined
                    onClick={() => {
                        const newImgs = [...images].map(x => x.id).filter(y => y !== record?.id);
                        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
                        dispatch(updateRegistryPhyto({
                            calcId: match?.params?.id,
                            values: {
                                external_pathogen_images: newImgs
                            }
                        }));
                    }} />
            )
        }
    ];

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (match?.params?.id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    protocolId: match?.params?.id,
                    imageType: 'external_contamination',
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `External pathogen ${moment().format('YYYY-MM-DDThh:mm')}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    useEffect(() => {
        dispatch(getDiseasesPhyto({ page: diseasesPage }));
    }, [diseasesPage]);

    useEffect(() => {
        dispatch(getCausativeAgents({ page: agentPage }));
    }, [agentPage]);

    useEffect(() => {
        dispatch(getCausativeAgentItems({ page: agentItemPage }));
    }, [agentItemPage]);

    useEffect(() => {
        const { next, results } = diseases;

        if (results?.length > 0) {
            const toInsert = [];
            results?.forEach(resVal => {
                const findingSame = diseasesFullList?.filter(agnt => agnt?.value === resVal?.id);
                if (!findingSame[0]) {
                    toInsert.push(resVal);
                }
            })
            setDiseasesFullList([
                ...diseasesFullList,
                ...toInsert?.map(x => {
                    return {
                        label: x?.name,
                        value: x?.id
                    };
                })]);
        }

        if (next?.length > 0) {
            setDiseasesPage(diseasesPage + 1);
        }
    }, [diseases]);

    useEffect(() => {
        const { next, results } = agentItems;

        if (results?.length > 0) {
            const toInsert = [];
            results?.forEach(resVal => {
                const findingSame = agentItemFullList?.filter(agnt => agnt?.value === resVal?.id);
                if (!findingSame[0]) {
                    toInsert.push(resVal);
                }
            })
            setAgentItemFullList([
                ...agentItemFullList,
                ...toInsert?.map(x => {
                    return {
                        label: x?.name,
                        value: x?.id
                    };
                })]);
        }

        if (next?.length > 0) {
            setAgentItemPage(agentItemPage + 1);
        }
    }, [agentItems]);

    useEffect(() => {
        const { next, results } = agents;
        if (results?.length > 0) {
            const toInsert = [];
            results?.forEach(resVal => {
                const findingSame = agentsList?.filter(agnt => agnt?.value === resVal?.id);
                if (!findingSame[0]) {
                    toInsert.push(resVal);
                }
            })
            setAgentsList([
                ...agentsList,
                ...toInsert?.map(x => {
                    return {
                        label: x?.name,
                        value: x?.id
                    };
                })?.filter(insertableItem => {
                    const searchResult = agentsList?.filter(agentItem => agentItem?.value === insertableItem?.value);
                    return searchResult?.length < 1;
                })]);
        }
        if (next?.length > 0) {
            setAgentPage(agentPage + 1);
        }
    }, [agents]);

    const addSelectedAgents = () => {
        dispatch(addExternalContamination({
            calculatorId: calculatorId,
            values: {
                causative_agents: selectedItems
            }
        }));
    };

    const downloadDiseasePDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        doc.setFontSize(14);

        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageWidthTable = pageSize.width ? pageSize.width - 80 : pageSize.getWidth() - 80;
        const text = doc.splitTextToSize(currentDisease?.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        doc.setFontSize(12);
        doc.setFontSize(12);

        const rowOne = pdfT('modal row 1');
        const rowTwo = pdfT('modal row 2');
        const rowThree = pdfT('modal row 3');
        const rowFour = pdfT('modal row 4');
        const rowFive = pdfT('modal row 5');
        const rowSix = pdfT('modal row 6');
        
        const body = [
            [rowOne, currentDisease?.causative_agent?.name],
            [rowTwo, currentDisease?.causative_agent?.causes_of_occurrence],
            [rowThree, currentDisease?.pathogen_morphology],
            [rowFour, currentDisease?.biology_spread_pathogen],
            [rowFive, currentDisease?.ecology_of_disease],
            [rowSix, currentDisease?.disease_harmfulness],
        ];

        doc.autoTable({
            startY: finalY,
            styles: {
                fontSize: 11,
                cellWidth: 'wrap'
            },
            columnStyles: {
                0: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 }
            },
            body
        });
        const fileName = `${currentDisease?.name} ${pdfT('summary')}`;
        doc.save(`${fileName}.pdf`);
    };

    const handleAddCausativeAgent = () => {
        const formVals = agentsForm?.getFieldsValue();
        if (creationType === 'disease') {
            const {
                causative_agent, subgroup, vegetation_phases, name,
                causes_of_occurrence, manifestations_shoots,
                manifestations_leaves, manifestations_stems,
                manifestations_root_system, manifestations_roots,
                manifestations_reproductive_organs, pathogen_morphology,
                biology_spread_pathogen, ecology_of_disease,
                disease_harmfulness, fight_ways,
                economic_threshold_of_harmfulness, symptom_category
            } = formVals;

            dispatch(newDiseasePhyto({
                values: {
                    causative_agent, subgroup, vegetation_phases, name,
                    causes_of_occurrence, manifestations_shoots,
                    manifestations_leaves, manifestations_stems,
                    manifestations_root_system, manifestations_roots,
                    manifestations_reproductive_organs, pathogen_morphology,
                    biology_spread_pathogen, ecology_of_disease,
                    disease_harmfulness, fight_ways,
                    economic_threshold_of_harmfulness, symptom_category
                }
            }));

            setTimeout(() => {
                if (diseasesPage !== 1) {
                    setDiseasesPage(1);
                } else {
                    dispatch(getDiseasesPhyto({ page: 1 }));
                }
            }, 500);
        }
        if (creationType === 'kind') {
            const {
                name
            } = formVals;

            dispatch(newKindPhyto({
                values: {
                    name: name
                }
            }));

            setTimeout(() => {
                if (agentPage !== 1) {
                    setAgentPage(1);
                } else {
                    dispatch(getCausativeAgents({ page: 1 }));
                }
            }, 500);
        }
        if (creationType === 'agent') {
            const {
                name, kind
            } = formVals;

            dispatch(newAgentPhyto({
                values: {
                    name: name,
                    kind: kind
                }
            }));

            setTimeout(() => {
                if (agentItemPage !== 1) {
                    setAgentItemPage(1);
                } else {
                    dispatch(getCausativeAgentItems({ page: 1 }));
                }
                setAgentPage(1);
            }, 500);
        }
        if (creationType === 'connection') {
            const {
                disease, culture, vegetation_phases
            } = formVals;

            dispatch(newConnectionPhyto({
                values: {
                    disease: disease,
                    culture: culture,
                    vegetation_phases: vegetation_phases
                }
            }));
        }

        agentsForm?.resetFields();
        setCreationType('');
        setAgentModalOpen(false);
    };

    const showDiseaseModal = (disease) => {
        setCurrentDisease(disease);
        setIsModalOpen(true);
    };

    const handleCalcFinish = () => {
        const formVals = form?.getFieldsValue();
        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
        dispatch(updateRegistryPhyto({
            calcId: match?.params?.id,
            values: {
                external_pathogen_comment: formVals?.external_pathogen_comment
            }
        }));
        const formKeys = Object.keys(formVals)?.filter(x => x?.startsWith('external/percentage/'));
        formKeys?.forEach(pathIdString => {
            setTimeout(() => {
                dispatch(updateExternalContamination({
                    pathogenId: pathIdString?.split('/')[2],
                    values: { pathogens_percentage: formVals[pathIdString] }
                }));
            }, 200);
        })
    };

    const confirmFinishCalculation = () => {
        confirm({
            title: t('finish calculation confirm title'),
            icon: <ExclamationCircleOutlined />,
            content: t('finish calculation confirm text'),
            okText: t('yes'),
            cancelText: t('no'),
            onOk() {
                handleCalcFinish();
            }
        });
    };

    const handleChooseCulture = (cultureId) => {
        dispatch(getVegPhasesPhyto({
            filter: {
                culture: cultureId
            }
        }))
    };

    const handleAddDrugs = () => {
        dispatch(updateExternalContamination({
            pathogenId: parseInt(activeDrugTab, 10),
            values: {
                drugs: selectedDrugList
            }
        }));

        dispatch(getProtocolPhyto(match?.params?.id));

        setTimeout(() => setShowDrugModal(false), 300);
    };

    const openDrugSelector = () => {
        setShowDrugModal(true);
    };

    const clearFormFields = () => {
        const formValues = form?.getFieldsValue();
        const { fito_calculator } = formValues;
        if (fito_calculator) {
            const { external_pathogen_ids } = fito_calculator;
            if (external_pathogen_ids?.length > 0) {
                external_pathogen_ids?.forEach(pathId => {
                    dispatch(removeExternalContamination({
                        pathogenId: pathId
                    }));
                });

                setTimeout(() => {
                    dispatch(getProtocolPhyto(match?.params?.id));
                }, 500);
            }
        }
    };

    return (
        <>
            <Row style={{
                marginTop: '25px'
            }}>
                <Col span={12}>
                    <Form.Item
                        label={t('external select label')}
                        name={['fito_calculator', 'external_pathogen_ids']}>
                        <Select
                            showSearch
                            filterOption={(inputValue, option) =>
                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                            options={agentsList}
                            allowClear
                            placeholder={t('external select placeholder')}
                            className="w-100"
                            mode="multiple"
                            onChange={setSelectedItems} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <Button onClick={addSelectedAgents} type="primary" className="mt-25 mb-25">
                        {t('button text select')}
                    </Button>
                </Col>
                <Col span={1} />
                <Col>
                    <Button onClick={clearFormFields} type="secondary" className="mt-25 mb-25">
                        {t('clear')}
                    </Button>
                </Col>
            </Row>
            {pathogens?.length > 0 && (
                <Collapse
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white', marginBottom: '0' }}
                    bordered={false}
                    defaultActiveKey={['external_probability']}
                    className={`${cn('collapse')} remove-padding`}>
                    <Panel
                        header={<b>{t('calculator external collapse header')}</b>}
                        key="external_probability">
                        <div className="pathogens-table">

                            <Row className="pathogens-table__row__header">
                                <Col className="pathogens-table__row__text__header" span={contaminationStage > 1 ? 4 : 6}>
                                    {t('external probability header reason')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={contaminationStage > 1 ? 4 : 6}>
                                    {t('external probability header harmful')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={contaminationStage > 1 ? 8 : 12}>
                                    {t('external probability header probability')}
                                </Col>
                                {contaminationStage > 1 && (
                                    <Col className="pathogens-table__row__text__header" span={4}>
                                        {t('external probability header pcs')}
                                    </Col>
                                )}
                            </Row>

                            {pathogens?.map((selectedPathogen, pIdx) => (
                                <Row className="pathogens-table__row">
                                    <Col className="pathogens-table__row__text" span={contaminationStage > 1 ? 4 : 6}>
                                        {selectedPathogen?.causative_agent?.name}
                                    </Col>
                                    <Col className="pathogens-table__row__text__highlighted" span={contaminationStage > 1 ? 4 : 6}>
                                        <div onClick={() => showDiseaseModal(selectedPathogen?.disease)}>{selectedPathogen?.disease?.name}</div>
                                    </Col>
                                    <Col className="pathogens-table__row__text__columned" span={contaminationStage > 1 ? 8 : 12}>
                                        {selectedPathogen?.disease?.cultures?.map(x => x?.vegetation_phases?.map(y => y?.name)).filter(x => x?.length > 0).join(',')}
                                    </Col>
                                    {contaminationStage > 1 && (
                                        <>
                                            <Col className="pathogens-table__row__text__header" span={4}>
                                                <Form.Item name={`external/percentage/${selectedPathogen?.id}`} key="">
                                                    <InputNumber decimalSeparator="," />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            )}

            {pathogens?.length > 0 && (
                <Collapse
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white', padding: '0', marginTop: '15px' }}
                    bordered={false}
                    defaultActiveKey={['drug_selection']}
                    className={`${cn('collapse')} remove-padding`}>
                    <Panel
                        header={<b>{t('calculator external collapse header drug_selection')}</b>}
                        key="drug_selection">
                        <div className="drug-cards">
                            {pathogens?.map(drugOption => (
                                <div className="drug-card">
                                    <div className="drug-card__header">
                                        {drugOption?.disease?.name}
                                    </div>
                                    <div className="drug-card__list">
                                        <ul>
                                            {drugOption?.drugs?.map(dr => (
                                                <li>
                                                    {dr?.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            )}

            <Row style={{ marginTop: '15px' }}>
                <Form.Item name="external_pathogen_comment">
                    <Input.TextArea />
                </Form.Item>
            </Row>

            <Row>
                <Col align="middle">
                    {/* <Button onClick={() => setAgentModalOpen(true)} type="primary" className="mt-25 mb-25"> */}
                    <Button type="primary" className="mt-25 mb-25">
                        {/* {t('add causative agent')} */}
                        <a href="https://t.me/agro_teleagronom" target="_blank">
                            {t('call telegram causative agent')}
                        </a>
                    </Button>
                </Col>
            </Row>

            {match?.params?.id && (
                <Row className="upload-file">
                    <Col>
                        <div className="upload-file__header">
                            {t('upload file header')}
                        </div>
                        <Upload {...uploadProps} className="upload-file__upload">
                            <Button icon={<UploadOutlined />} type="secondary">
                                {t('upload file button')}
                            </Button>
                        </Upload>
                        <div className="upload-file__subtext">
                            {t('upload file subtext 1')}
                        </div>
                        <div className="upload-file__subtext">
                            {t('upload file subtext 2')}
                        </div>
                    </Col>
                </Row>
            )}

            {images?.length > 0 && (
                <Table dataSource={images} columns={imagesColumns} />
            )}

            <Row className="mt-25" gutter={12}>
                <Col span={5} />
                <Col span={6}>
                    <Button className="w-100" onClick={openDrugSelector}>{t('external bottom button goto')}</Button>
                </Col>
                <Col span={6}>
                    <Button className="w-100" onClick={confirmFinishCalculation}>{t('external bottom button finish')}</Button>
                </Col>
                <Col span={5} />
            </Row>

            <Modal
                width={1000}
                visible={showDrugModal}
                title={t('drug modal title')}
                onOk={handleAddDrugs}
                onCancel={() => {
                    setShowDrugModal(false);
                }}
                footer={[
                    <Button
                        type="secondary"
                        key="back"
                        onClick={() => {
                            setShowDrugModal(false);
                        }}>
                            {t('agent modal cancel')}
                    </Button>,
                    <Button
                        key="link"
                        type="primary"
                        onClick={handleAddDrugs}>
                            {t('agent modal ok')}
                    </Button>
                ]}>

                    <Tabs defaultActiveKey={pathogens?.length > 0 ? pathogens[0].id : 1} onChange={setActiveDrugTab}>
                        {pathogens?.map(pathogenInstance => (
                            <Tabs.TabPane tab={pathogenInstance?.disease?.name} key={pathogenInstance?.id}>
                                <Select
                                    showSearch
                                    filterOption={(inputValue, option) =>
                                        option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                    defaultValue={pathogenInstance?.drugs?.map(x => x.id)}
                                    mode="multiple"
                                    onChange={setSelectedDrugList}
                                    options={fertilizersFullList.map(x => {
                                        return {
                                            label: x?.name || '',
                                            value: x?.id
                                        };
                                    })?.reduce((accumulator, current) => {
                                        let exists = accumulator.find(item => {
                                          return item.label === current.label;
                                        });
                                        if(!exists) { 
                                          accumulator = accumulator.concat(current);
                                        }
                                        return accumulator;
                                      }, [])}
                                    style={{ width: '100%' }} />
                            </Tabs.TabPane>
                        ))}
                    </Tabs>

            </Modal>

            <Modal
                width={1000}
                visible={agentModalOpen}
                title={t('agent modal title')}
                onOk={handleAddCausativeAgent}
                onCancel={() => {
                    agentsForm?.resetFields();
                    setCreationType('');
                    setAgentModalOpen(false);
                }}
                footer={[
                    <Button
                        type="secondary"
                        key="back"
                        onClick={() => {
                            agentsForm?.resetFields();
                            setCreationType('');
                            setAgentModalOpen(false);
                        }}>
                            {t('agent modal cancel')}
                    </Button>,
                    <Button
                        key="link"
                        type="primary"
                        onClick={handleAddCausativeAgent}>
                            {t('agent modal ok')}
                    </Button>
                ]}>

                {creationType?.length > 1 && (
                    <Form form={agentsForm} labelCol={{ span: 24 }}>
                        <Row>
                            <a onClick={() => {
                                agentsForm?.resetFields();
                                setCreationType('');
                            }}>
                                Назад
                            </a>
                        </Row>

                        {creationType === 'kind' && (
                            <>
                                <Row>
                                    <Form.Item name='name' label={t('add new label kind')}>
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                </Row>
                            </>
                        )}

                        {creationType === 'agent' && (
                            <>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item name='name' label={t('add new label name')}>
                                            <Input style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name='kind' label={t('add new label kind')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                options={agentsList}
                                                style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {/* "causative_agent": id - Возбудитель болезни
                        "subgroup": id - Подгруппа(GET api/v1/disease/disease_subgroup)
                        "vegetation_phases": [id, id, id], - Фазы вегетации (GET api/v1/field_forecast/vegetation_phase/?filter={"culture":id})
                        "name": "Фузариозное трахеомикозное увядание", - название */}
                        {creationType === 'disease' && (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <div>Культура</div>
                                        <Select showSearch
                                            filterOption={(inputValue, option) =>
                                                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())} onSelect={handleChooseCulture}
                                            options={cultures?.map(x => {
                                                return {
                                                    label: x?.name,
                                                    value: x?.id
                                                }
                                            })} />
                                    </Col>
                                </Row>
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name='causative_agent' label={t('add new label causative_agent')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                options={agentItemFullList}
                                                style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='subgroup' label={t('add new label subgroup')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                style={{ width: '100%' }}
                                                options={subgroups?.map(x => {
                                                    return {
                                                        label: x?.name,
                                                        value: x?.id
                                                    };
                                                })} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='vegetation_phases' label={t('add new label vegetation_phases')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                style={{ width: '100%' }}
                                                mode="multiple"
                                                options={vegPhases?.map(x => {
                                                    return {
                                                        label: x?.name,
                                                        value: x?.id
                                                    };
                                                })} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='name' label={t('add new label name disease')}>
                                            <Input style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                {/* "causes_of_occurrence": "", - Причины возникновения
                                "manifestations_shoots": "", - Проявления: Всходы
                                "manifestations_leaves": "", - Проявления: Листья
                                "manifestations_stems": "", - Проявления: Стебли */}

                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name='causes_of_occurrence' label={t('add new label causes_of_occurrence')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='manifestations_shoots' label={t('add new label manifestations_shoots')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='manifestations_leaves' label={t('add new label manifestations_leaves')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='manifestations_stems' label={t('add new label manifestations_stems')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* "manifestations_root_system": "", - Проявления: Корневая система
                                "manifestations_roots": "", - Проявления: Корнеплоды
                                "manifestations_reproductive_organs": "", - Проявления: Репродуктивные органы
                                "pathogen_morphology": "", - Морфология возбудителя */}
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name='manifestations_root_system' label={t('add new label manifestations_root_system')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='manifestations_roots' label={t('add new label manifestations_roots')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='manifestations_reproductive_organs' label={t('add new label manifestations_reproductive_organs')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='pathogen_morphology' label={t('add new label pathogen_morphology')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* "biology_spread_pathogen": "", - Биология распространения возбудителя
                                "ecology_of_disease": "", - Экология болезни
                                "disease_harmfulness": "", - Вредоносность болезни
                                "fight_ways": "", - Меры борьбы */}
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <Form.Item name='biology_spread_pathogen' label={t('add new label biology_spread_pathogen')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='ecology_of_disease' label={t('add new label ecology_of_disease')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='disease_harmfulness' label={t('add new label disease_harmfulness')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name='fight_ways' label={t('add new label fight_ways')}>
                                            <Input.TextArea style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* "economic_threshold_of_harmfulness": "", - Экономический порог вредоносности (ЭПВ)
	                            "symptom_category": id - Категория симптомов (GET api/v1/disease/symptoms)*/}
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item name='economic_threshold_of_harmfulness' label={t('add new label economic_threshold_of_harmfulness')}>
                                            <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name='symptom_category' label={t('add new label symptom_category')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                options={symptoms?.map(x => {
                                                    return {
                                                        label: x?.name,
                                                        value: x?.id || x?.name
                                                    }
                                                })}
                                                style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {creationType === 'connection' && (
                            <>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item name='disease' label={t('add new label disease new')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                options={diseasesFullList}
                                                style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name='culture' label={t('add new label culture new')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                onSelect={handleChooseCulture}
                                                options={cultures?.map(x => {
                                                    return {
                                                        label: x?.name,
                                                        value: x?.id
                                                    }
                                                })}
                                                style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item name='vegetation_phases' label={t('add new label vegetation_phases')}>
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                                style={{ width: '100%' }}
                                                mode="multiple"
                                                options={vegPhases?.map(x => {
                                                    return {
                                                        label: x?.name,
                                                        value: x?.id
                                                    };
                                                })} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                )}

                {creationType?.length < 1 && (
                    <>
                        <Row align="center" gutter={12}>
                            <Col span={12}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => setCreationType('kind')}
                                    type="secondary">
                                        <PlusCircleOutlined />
                                        {t('agent modal add new kind button')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => setCreationType('agent')}
                                    type="secondary">
                                        <PlusCircleOutlined />
                                        {t('agent modal add new button')}
                                </Button>
                            </Col>
                        </Row>
                        <Row align="center" gutter={12} style={{ marginTop: '15px' }}>
                            <Col span={12}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => setCreationType('disease')}
                                    type="secondary">
                                        <PlusCircleOutlined />
                                        {t('agent modal add new disease button')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => setCreationType('connection')}
                                    type="secondary">
                                        <PlusCircleOutlined />
                                        {t('agent modal add new connection button')}
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}

            </Modal>

            <InfoModal
                inspectableItem={currentDisease}
                downloadPDF={downloadDiseasePDF}
                closeModal={() => setIsModalOpen(false)}
                isOpened={isModalOpen} />
        </>
    );
};

export default ExternalContamination;
