import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, Upload, Input, Modal, Form
} from 'antd';

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import cn from '../../../utils/cn';
import {
    Title, Button, Tabs, InfoMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import FilesTable from './files-table';
import QuestionBlock from './question';
import AnswerBlock from './answer';

const { TextArea } = Input;
const {
    addRequest,
    updateRequest,
    getTaskFile,
    setStatusRequest,
    saveAndSetStatusRequest,
    uploadTaskFile,
    addTask
} = actions;
const {
    getCurrentRequest,
    isRequestLoading,
    getUserId
} = selectors;

const mstp = (state) => ({
    request: getCurrentRequest(state),
    loading: isRequestLoading(state),
    curUserId: getUserId(state)
});
const mdtp = ({
    addRequest,
    updateRequest,
    getTaskFile,
    setStatusRequest,
    saveAndSetStatusRequest,
    uploadTaskFile,
    addTask
});

const { TabPane } = Tabs;
const { Option } = Select;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

@withRouter
@connect(mstp, mdtp)
@withTranslation('requests')
@cn('request-detail')
export default class RequestData extends Component {
    state = {
        visible: false,
        fileList: [],
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        isTask: false,
        showModal: false,
        image: '',
        message: '',
        isMess: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { request, history, match } = this.props;
        const { id } = match.params;
        if ((prevProps.request.id !== request.id)) {
            this.initForm(request);
        }
    }

    closeRequest = () => {
        const { history } = this.props;
        history.push('/requests/');
    };

    hideModal = () => {
        this.setState({ visible: false });
    };

    showModal = () => {
        this.setState({ visible: true });
    };

    onSubmit = (e, form) => {
        const { addRequest, updateRequest, match } = this.props;
        const { validateFields } = form;
        const { id } = match.params;

        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                !id && addRequest(values);
                if (id) {
                    values.id = id;
                    updateRequest(values);
                }
            } else {
                console.warn(err);
            }
        });
    };

    sendRequest = () => {
        const { saveAndSetStatusRequest, match } = this.props;
        const { id } = match.params;
        const { title = '', annotation = '' } = this.state;
        const values = {};
        values.requestId = id;
        values.id = id;
        values.status = 'IN_PROGRESS';
        values.title = title;
        values.annotation = annotation;
        saveAndSetStatusRequest(values);
    };

    closeRequest = () => {
        const { setStatusRequest, match } = this.props;
        const { id } = match.params;
        setStatusRequest({ requestId: id, status: 'CLOSED' });
    };

    answerRequest = () => {
        const { setStatusRequest, match } = this.props;
        const { id } = match.params;
        setStatusRequest({ requestId: id, status: 'ANSWER' });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        }, () => this.initForm());
    };

    onChange = (e) => {
        const { value } = e.target;
        this.setState({ description: value });
    };
    
    onValuesChange = (e) => {
        this.setState(e);
    }

    initForm = () => {
        const { id, request } = this.props;
        const { formInit } = this.state;
        const {
            title,
            annotation
        } = request;
        formInit(object2Form({
            title,
            annotation
        }));
    };

    setRequestName = (e) => {
        const { setName } = this.props;
        setName(e.target.value);
    };

    beforeUpload = file => {
        const { t } = this.props;
        const fileItem = {};
        fileItem.file = file;
        if (file.size <= 52428800) {
            this.setState(state => ({
                fileList: [file],
                message: '',
                isMess: false
            }));
        } else {
            this.setState(state => ({
                message: t('file size'),
                isMess: true
            }));
        }

        return false;
    }

    onRemove = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList
            };
        });
    }

    getTaskFile = () => {
        const { getTaskFile } = this.props;
        getTaskFile(1);
    }

    addTask = (files, title) => {
        this.setState({ isTask: true });
    }

    setTaskData = (files, title) => {
        this.setState({ files, title });
    }

    sendTask = () => {
        const {
            addTask, match, curUserId, request
        } = this.props;
        const { userId } = request;
        const isAdmin = Number(curUserId) !== Number(userId);
        const { id } = match.params;
        const { title, files } = this.state;
        this.setState({ isTask: false });
        addTask({
            requestId: id, title, files, isAdmin
        });
    }

    addFileToRequest = () => {
        const { uploadTaskFile, match } = this.props;
        const { fileList, description } = this.state;
        const { id } = match.params;
        const values = {};
        const url = description ? `?requestId=${id}&description=${description}` : `?requestId=${id}`;
        values.url = url;
        values.requestId = id;

        if (fileList.length) {
            values.file = { file: fileList[0] };
            uploadTaskFile(values);
            this.setState({ fileList: [] });
        } else {
            console.warn();
        }
    }

    onClick = (image, file) => {
        if (image) {
            this.setState({ showModal: true, image: `/v1/task-file/downloadFile/${image}` });
        } else {
            this.handlePreview(file.file);
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    handlePreview = async file => {
        const image = await getBase64(file);

        this.setState({ showModal: true, image });
    };

    render() {
        const { cn } = this;
        const {
            t, loading, request, curUserId
        } = this.props;
        const {
            fileList,
            isTask,
            showModal,
            image,
            isMess,
            message
        } = this.state;
        const {
            tasks = [], files = [], status, userId
        } = request;

        const isAdmin = Number(curUserId) !== Number(userId);

        return (
            <Form onSubmit={this.onSubmit}
                onMount={this.onMount}
                onValuesChange={this.onValuesChange}
                className={cn('data')}
                name="request-form">
                <div>
                    <div className={cn('block-title')} onClick={this.getTaskFile}>
                        {t('details title')}
                    </div>
                    <Row gutter={20}>
                        <Col span={24}>
                            <div className="field">
                                <Input
                                    disabled={status !== 'NEW'}
                                    label={t('request name')}
                                    name="title"
                                    onChange={this.setRequestName} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="field">
                                <TextArea
                                    label={t('annotation')}
                                    disabled={status !== 'NEW'}
                                    placeholder={t('annotation placeholder')}
                                    name="annotation" />
                            </div>
                        </Col>
                    </Row>
                    <div className={cn('block-title-no-border')}>
                        {t('files')}
                    </div>
                    <Row gutter={20} style={{ marginBottom: '30px' }}>
                        { status === 'NEW' && (
                            <Col span={24}>
                                <div className={cn('label')}>{t('upload img')}</div>
                                <Upload fileList={fileList}
                                    onRemove={this.onRemove}
                                    beforeUpload={this.beforeUpload}>
                                    <Button type="default">
                                        <UploadOutlined /> {t('upload')}
                                    </Button>
                                </Upload>
                                {isMess && <div className={cn('error')}>{message}</div>}
                            </Col>
                        )}
                        {fileList.length > 0 && (
                            <Col span={16} style={{ marginTop: '30px' }}>
                                <div className={cn('label')}>{t('description')}</div>
                                <Input placeholder={t('description placeholder')}
                                    onChange={this.onChange}
                                    name="description" />
                                <Button type="primary"
                                    className={cn('file-button')}
                                    onClick={this.addFileToRequest}>{t('add file')}
                                </Button>
                            </Col>
                        )}
                        {files.length > 0 && (
                            <Col span={24} style={{ marginTop: '30px' }}>
                                <FilesTable fileList={files} onClick={this.onClick} />
                            </Col>
                        )}
                    </Row>
                    {tasks.length > 0 && (
                        tasks.map(item => <AnswerBlock task={item} onClick={this.onClick} />)
                    )}
                    {isTask && (
                        <QuestionBlock sendValues={this.setTaskData} onClick={this.onClick} />
                    )}
                </div>

                <Row gutter={20}>
                    {!isAdmin && status === 'NEW' && (
                        <Col span={24} className={cn('button-block')}>
                            <Button type="primary"
                                htmlType="submit"
                                disabled={loading}>
                                {t('save')}
                            </Button>
                            <Button type="default"
                                onClick={this.sendRequest}
                                disabled={loading}>
                                {t('send')}
                            </Button>
                        </Col>
                    )}
                    {!isAdmin && (status === 'IN_PROGRESS' || status === 'ANSWER') && !isTask && (
                        <Col span={24} className={cn('button-block')}>
                            <Button type="primary"
                                onClick={this.addTask}
                                disabled={loading}>
                                {t('clarify')}
                            </Button>
                            <Button type="default"
                                onClick={this.closeRequest}
                                disabled={loading}>
                                {t('close')}
                            </Button>
                        </Col>
                    )}
                    {isTask && (
                        <Col span={24} className={cn('button-block')}>
                            <Button type="default"
                                onClick={this.sendTask}
                                disabled={loading}>
                                {(curUserId === userId) ? t('send') : t('send answer')}
                            </Button>
                        </Col>
                    )}
                    {isAdmin && !isTask && (
                        <Col span={24} className={cn('button-block')}>
                            <Button type="primary"
                                onClick={this.addTask}
                                disabled={loading}>
                                {t('send answer')}
                            </Button>
                        </Col>
                    )}
                    <Modal centered
                        closable
                        destroyOnClose
                        visible={showModal}
                        footer={null}
                        width={900}
                        onCancel={this.closeModal}
                        onHide={this.closeModal}>
                        <div className={cn('images', 'modal')}>
                            <img src={image} alt={image} />
                        </div>
                    </Modal>
                </Row>
            </Form>


        );
    }
}