import * as constants from '../constants';

const initialState = { error: false };

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.deleteProtocol.success: {
            return action.payload;
        }

        case constants.deleteProtocol.try: {
            return initialState;
        }
        case constants.deleteProtocol.error: {
            return { error: true };
        }

        default:
            return state;
    }
}