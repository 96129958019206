import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Icon } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import ProfileData from './profile-data';
import ProfilePassword from './profile-password';
import { selectors, actions } from '../../../models';

const {
    updateProfile
} = actions;
const {
    getUser
} = selectors;

const { TabPane } = Tabs;
const { PanelHeader, PanelContent } = PanelLayout;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({
    updateProfile
});


@withRouter
@withTranslation('profile detail')
@connect(mstp, mdtp)
@cn('profile-detail')
export default class ProfileDetail extends Component {
    state = {
        tab: '1'
    };

    onTabChange = (key) => {
        this.setState({ tab: key });
    };

    render() {
        const { cn } = this;
        const {
            t, user
        } = this.props;
        const { tab } = this.state;

        return (
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title', { value: user.username })} />
                </PanelHeader>
                <PanelContent>
                    <Tabs
                        onChange={this.onTabChange}
                        activeKey={tab}>
                        <TabPane
                            tab={(
                                <span>
                                    <UserOutlined />
                                    {t('profile data')}
                                </span>
                            )}
                            key="1">
                            <ProfileData onTabChange={this.onTabChange} />
                        </TabPane>
                        {/* <TabPane
                            tab={(
                                <span>
                                    <LockOutlined />
                                    {t('profile password')}
                                </span>
                            )}
                            key="2">
                            <ProfilePassword onTabChange={this.onTabChange} />
                        </TabPane> */}
                    </Tabs>
                </PanelContent>
            </PanelLayout>
        );
    }
}