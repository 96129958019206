import moment from 'moment';
import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    fertilizer2Client = (rawObject = {}) => {
        const {
            id,
            subgroup,
            release_form,
            soil_type,
            name,
            economy_type_s,
            economy_type_l,
            presowing_application_rate,
            sowing_application_rate,
            basic_application_rate,
            top_dressing_application_rate_open,
            top_dressing_application_rate_close,
            leaf_dressing,
            content_n,
            content_p,
            content_k,
            content_ca,
            content_mg,
            content_s,
            content_fe,
            content_ag,
            content_b,
            content_mo,
            content_mn,
            content_cu,
            content_zn,
            content_co,
            content_i,
            content_v,
            is_in_state_registry,
            culture_description,
            main_drug,
            registration,
            seller,
            synonyms
        } = rawObject;

        return {
            id,
            subgroup_id: subgroup.id || 0,
            subgroup_name: subgroup.name || '',
            group_id: subgroup && subgroup.group && (subgroup.group.id || 0),
            group_name: subgroup && subgroup.group && (subgroup.group.name || ''),
            release_form_id: release_form.id || 0,
            release_form_name: release_form.name || '',
            soil_type_id: soil_type.id || 0,
            soil_type_id_name: soil_type.number || '',
            name,
            economy_type_s,
            economy_type_l,
            presowing_application_rate,
            sowing_application_rate,
            basic_application_rate,
            top_dressing_application_rate_open,
            top_dressing_application_rate_close,
            leaf_dressing,
            content_n,
            content_p,
            content_k,
            content_ca,
            content_mg,
            content_s,
            content_fe,
            content_ag,
            content_b,
            content_mo,
            content_mn,
            content_cu,
            content_zn,
            content_co,
            content_i,
            content_v,
            is_in_state_registry,
            culture_description,
            main_drug,
            registration,
            seller,
            synonyms
        };
    };

    removedCalculatorId2Client = id => ({ removedCalculatorId: id });

    fertilizersFilter2Client = (data) => {
        const temp = data.map(item => item.trim());
        return temp.sort();
    }
}

export default new Converter();