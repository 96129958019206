import * as constants from '../constants';

const initialState = { error: false };

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.publishProtocol.success: {
            return { ...action.payload, statusUpdated: true };
        }


        case constants.publishProtocol.try: {
            return initialState;
        }

        case constants.publishProtocol.error: {
            return { error: true };
        }

        default:
            return state;
    }
}