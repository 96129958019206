import * as constants from '../constants';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorFluid.success:
        case constants.closeCalculatorFluid.success:
        case constants.setPlantProtectionCalculatorFluid.success:
        case constants.setParamsCalculatorFluid.success:
        case constants.addCalculatorFluid.success: {
            return action.payload;
        }

        case constants.clearCalculatorFluid.toString(): {
            return initialState;
        }


        default:
            return state;
    }
}
