import * as constants from '../constants';

const initialState = {
    byFields: [],
    totalByFields: [],
    byCulture: [],
    byAgroPeriod: [],
    byAgroFields: [],
    totalByCulture: [],
    mapData: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getPivotTableMapData.success: {
            return { ...state, mapData: action.payload?.results };
        }

        case constants.getPivotTableByCulture.success: {
            return { ...state, byCulture: action.payload?.result };
        }

        case constants.getPivotTableByCultureTotal.success: {
            return { ...state, totalByCulture: action.payload?.result };
        }

        case constants.getPivotTableByField.success: {
            return { ...state, byFields: action.payload?.results };
        }

        case constants.getPivotTableByFieldTotal.success: {
            return { ...state, totalByFields: action.payload?.result };
        }

        case constants.getPivotByAgroTechicalPeriod.success: {
            return { ...state, byAgroPeriod: action.payload };
        }

        case constants.getPivotByAgroTechicalFields.success: {
            return { ...state, byAgroFields: action.payload?.result };
        }

        default:
            return state;
    }
}
