import { isLoading } from '../../account/selectors';
import * as constants from '../constants';

const initialState = {
    isLoading: false,
    total: 0,
    results: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getProtocolList.success: {
            return { results: action.payload.results, isLoading: false, total: action.payload.count };
        }

        case constants.getProtocolList.try: {
            return { results: [], isLoading: true, total: 0 };
        }

        case constants.getProtocolList.error: {
            return initialState;
        }

        default:
            return state;
    }
}