import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    Table, TableFooter, Tooltip
} from '../../../components';
import Popover from './action-popover';
import cn from '../../../utils/cn';

const ROW_DIVIDER = 10;

@withRouter
@withTranslation('requests')
@cn('requests-table')
export default class RequestsTable extends Component {
    get requestsColumns() {
        const { cn } = this;
        const {
            t, history, closeRequest, sendRequest
        } = this.props;

        return [
            {
                title: t('id'),
                dataIndex: 'id',
                key: 'id',
                className: cn('id')
            },
            {
                title: t('title'),
                dataIndex: 'title',
                key: 'title',
                className: cn('title'),
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['ascend', 'descend'],
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}
                        onClick={() => history.push(`/request/${record.id}`)}>
                        {text}
                    </Tooltip>
                )
            },
            {
                title: t('create date'),
                dataIndex: 'createDate',
                key: 'createDate',
                className: cn('createDate'),
                sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
                sortDirections: ['descend', 'ascend'],
                render: (text) => moment(text).format('DD.MM.YYYY')
            },
            {
                title: t('statuses'),
                dataIndex: 'status',
                key: 'status',
                className: cn('status'),
                render: (text) => t(text)
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: cn('action'),
                fixed: 'right',
                render: (text, record) => record.status !== 'CLOSED' && (
                    <div className={cn('button')}>
                        <Popover
                            status={record.status}
                            id={record.id}
                            placement="bottomRight"
                            url="request"
                            trigger="click"
                            sendRequest={() => sendRequest(record.id)}
                            closeRequest={() => closeRequest(record.id)} />
                    </div>
                )
            }
        ];
    }

    render() {
        const { cn } = this;
        const {
            dataSource,
            onPageSizeChange,
            onCurrentPageChange,
            dataSize
        } = this.props;

        return (
            <div className={cn()}>
                <Table
                    dataSource={dataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
                {dataSize > 1 && (
                    <TableFooter
                        filterItems={this.filterItems}
                        showSizeChanger={false}
                        onSelect={onPageSizeChange}
                        onChange={onCurrentPageChange}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                )}
            </div>
        );
    }
}