import React, { useEffect, useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Form, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import CalculationData from './components/caluclation-data';
import ImageCarousel from './components/image-carousel/image-carousel';

import './style.less';
import MushroomTable from './components/mushroom-table';
import RepetitionsTable from './components/repetitions-table';
import {
    getCalculatorsMordoviaCatalog,
    getCurrentCalculatorMordovia
} from '../../../../models/mordovia/selectors';
import AzotobacterTable from './components/azotobacter-table';
import { getGenusOfMicroCalculatorsMyco } from '../../../../models/calculators-myco/actions';
import { getCultures } from '../../../../models/cultures/actions';
import { getCalculatorsMordovia } from '../../../../models/mordovia/actions';

const { TabPane } = Tabs;

const MycologicalInterpretation = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('field representation mycological analysis');
    const match = useRouteMatch();
    const { year } = match.params;

    const [form] = Form.useForm();
    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));

    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedCalculator, setSelectedCalculator] = useState({});
        
    useEffect(() => {
        dispatch(getGenusOfMicroCalculatorsMyco('?page_size=1000'));
        dispatch(getCultures());
        dispatch(getCalculatorsMordovia('?page_size=1000'));
    }, []);

    const updateCalculationData = (calcValues) => {
        const newFormValues = {
            protocol_num: calcValues?.num,
            owner: calcValues?.owner,
            sample_date: moment(calcValues?.sample_date),
            experiment_date: moment(calcValues?.testDate),
            field_num: calcValues?.field_num,
            year: calcValues?.year,
            culture: calcValues?.culture,
            sample: t(`sample type ${calcValues?.sample?.toLowerCase()}`)
        };
        form.setFieldsValue(newFormValues);
    };

    useEffect(() => {
        if (selectedCalculator?.id) {
            updateCalculationData(selectedCalculator);
        }
    }, [selectedCalculator]);

    const yearDictionaryMemo = useMemo(() => {
        const yearDictionary = {};
        const yearsData = calculator?.year_group?.years;
        yearsData?.forEach(x => {
            yearDictionary[x.year] = x?.mycological_calculator;
        });
        return yearDictionary;
    }, [calculator]);

    useEffect(() => {
        if (year) {
            setSelectedCalculator(yearDictionaryMemo[year]);
        }
        if (selectedYear) {
            setSelectedCalculator(yearDictionaryMemo[selectedYear]);
        }
    }, [yearDictionaryMemo]);

    const onFormValuesChange = (updatedValue, updatedValues) => {
        const updatedField = Object.keys(updatedValue)[0];
        switch (updatedField) {
            case 'year':
                const newYear = updatedValue[updatedField];
                setSelectedYear(newYear);
                break;
            default:
                break;
        }
    };

    return (
        <div className="mycointerpretation">
            <Form
                onValuesChange={onFormValuesChange}
                form={form}
                layout="vertical">
                <CalculationData
                    yearOptions={Object.keys(yearDictionaryMemo)} />
            </Form>
            <Tabs>
                <TabPane tab={t('mycointerpretation tab1')} key="tab-1">
                    <RepetitionsTable
                        microorganisms={selectedCalculator?.microorganisms}
                        resultImap={selectedCalculator?.result_imap} />
                    {selectedCalculator?.pictures1?.length > 0 && (
                        <ImageCarousel
                            imageCards={selectedCalculator?.pictures1} />
                    )}
                    {selectedCalculator?.comment?.length > 0 && (
                        <div className="mycointerpretation__result-message">
                            {selectedCalculator?.comment}
                        </div>
                    )}
                </TabPane>
                <TabPane tab={t('mycointerpretation tab2')} key="tab-2">
                    <MushroomTable tableData={selectedCalculator} />
                    {selectedCalculator?.pictures2?.length > 0 && (
                        <ImageCarousel
                            imageCards={selectedCalculator?.pictures2} />
                    )}
                    {selectedCalculator?.comment2?.length > 0 && (
                        <div className="mycointerpretation__result-message">
                            {selectedCalculator?.comment2}
                        </div>
                    )}
                </TabPane>
                <TabPane tab={t('mycointerpretation tab3')} key="tab-3">
                    <AzotobacterTable
                        tableData={[{
                            genus: t('azotobacter table head'),
                            repetitions: selectedCalculator?.azotobacter_repetitions,
                            mean: selectedCalculator?.azotobacter_mean
                        }]} />
                    {selectedCalculator?.pictures3?.length > 0 && (
                        <ImageCarousel
                            imageCards={selectedCalculator?.pictures3} />
                    )}
                    {selectedCalculator?.comment3?.length > 0 && (
                        <div className="mycointerpretation__result-message">
                            {selectedCalculator?.comment3}
                        </div>
                    )}
                </TabPane>
            </Tabs>
        </div>
    );
};

export default MycologicalInterpretation;