import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Radio } from 'antd';

import { useTranslation } from 'react-i18next';
import { useClassName } from '../../../../utils/cn';

import { actions, selectors } from '../../../../models';

import './style.less';
import { Button } from '../../../../components';

const { getCurrentDeterminant, getCurrentQuestion, getCurrentAnswers } = selectors;
const { setSimpleAnswer, addSimpleDeterminant, getQuestion } = actions;
function compare(a, b) {
    if (a.number < b.number) {
        return -1;
    }
    if (a.number > b.number) {
        return 1;
    }
    return 0;
}

export default ({ type, setDisease }) => {
    const determinant = useSelector(state => getCurrentDeterminant(state));
    const question = useSelector(state => getCurrentQuestion(state));
    const answers = useSelector(state => getCurrentAnswers(state));
    const cn = useClassName('determinant-detail-question');
    const { t } = useTranslation('determinant detail');
    const dispatch = useDispatch();

    const [answer, setAnswerState] = useState();
    const [count, incCount] = useState(1);
    const [isHide, hideButton] = useState(false);

    useEffect(() => {
        const values = {
            user: null,
            name: 'Temp',
            determinant: type
        };
        dispatch(addSimpleDeterminant(values));
    }, [type]);

    useEffect(() => {
        if (determinant?.id) {
            dispatch(getQuestion(determinant?.id));
        }
    }, [determinant.id]);

    useEffect(() => {
        if (question.type === 'disease') {
            hideButton(true);
            setDisease(question.disease_ref);
        }
    }, [question.type]);

    const showNext = () => {
        const { id } = determinant;
        dispatch(setSimpleAnswer({ id, values: { answer } }));
        setAnswerState(undefined);
        incCount(count + 1);
    };

    const onAnswerChange = (e) => {
        setAnswerState(e.target.value);
    };


    return (
        determinant?.id ? (
            <div className={cn()}>
                {!isHide ? (
                    <div className={cn('wrap-border')}>
                        <div className={cn('title')}>
                            {question.title}
                        </div>
                        <div className={cn('content')}>
                            <Radio.Group onChange={onAnswerChange} className={cn('item-wrap')} value={answer}>
                                {answers?.sort(compare).map((item) => (
                                    <Radio value={item.number}
                                        key={item.number}
                                        className={cn('item')}>
                                        {item.text}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </div>
                        <div className={cn('buttons')}>
                            <Button type="default"
                                onClick={showNext}>{t('next')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={cn('wrap-disease')}>
                        <div className={cn('disease-desc')}>
                            <p>Выберите стратегию защиты и получите консультацию у фитопатолога</p>
                            <Button type="primary" onClick={() => window.open('https://t.me/TELEAGRONOM', '_blank')}>
                            Перейти в телеграм-канал
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        ) : (<div />)
    );
};