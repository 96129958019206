/* eslint max-classes-per-file: ["off"] */
import React, { Component } from 'react';
import { Input, InputNumber } from 'antd';

import cn from '../../utils/cn';
import './style.less';

const { TextArea, Password, Search } = Input;

@cn('input-wrapper')
export default class TeleInput extends Component {
    state = {
        focused: false
    };

    onFocus = () => {
        this.setState({ focused: true });
    };

    onBlur = () => {
        this.setState({ focused: false });
    };

    render() {
        const { focused } = this.state;
        const { cn } = this;
        const {
            className: propClass, maxLength = 255, ...rest
        } = this.props;
        const className = cn({ [propClass]: !!propClass, focused: !!focused });

        return (
            <Input className={className}
                allowClear
                maxLength={maxLength}
                autoComplete="off"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                {...rest} />
        );
    }
}

@cn('text-area')
class TeleTextArea extends Component {
    render() {
        const { cn } = this;
        const {
            className: propClass, maxLength = 1000, ...rest
        } = this.props;
        const className = cn({ [propClass]: !!propClass });

        return (
            <TextArea className={className} maxLength={maxLength} {...rest} />
        );
    }
}

@cn('input-search')
class TeleInputSearch extends Component {
    render() {
        const { cn } = this;
        const {
            className: propClass, ...rest
        } = this.props;
        const className = `${cn()} ${propClass}`;

        return (
            <Search className={className} {...rest} />
        );
    }
}

@cn('password')
class TelePassword extends Component {
    render() {
        const { cn } = this;
        const {
            className: propClass, ...rest
        } = this.props;
        const className = cn({ [propClass]: !!propClass });

        return (
            <Password className={className} {...rest} />
        );
    }
}

@cn('input-number')
class TeleInputNumber extends Component {
    render() {
        const { cn } = this;
        const {
            className: propClass, max = 10000, min = -255, ...rest
        } = this.props;

        const className = cn({ [propClass]: Boolean(propClass) });

        return (
            <InputNumber className={className}
                max={max}
                min={min}
                {...rest} />
        );
    }
}


export {
    TeleTextArea as TextArea,
    TelePassword as Password,
    TeleInputNumber as InputNumber,
    TeleInputSearch as InputSearch
};
