import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getDiseasesList = state => state.models[name].diseases.results;
export const getDiseasesCount = state => state.models[name].diseases.count;

export const getCurrentDisease = state => state.models[name].disease;
export const isDiseaseLoading = state => state.models[name].loading;

export const getDiseasesSubGroupList = state => state.models[name].subgroup.results;
export const getDiseasesGroupList = state => state.models[name].group.results;
export const getDiseasesDamageAreaList = state => state.models[name].damageArea.results;
export const getDiseasesCausativeAgentList = state => state.models[name].causativeAgent.results;
export const getDiseasesCultureList = state => state.models[name].cultures.results;
