import React, { useEffect, useState, useRef } from 'react';
import {
    Col, Collapse, Form, InputNumber, Row
} from 'antd';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import Icon from '@ant-design/icons';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';
import CalculatorData from './data';
import CalculatorIcon from '../../../../public/images/calculator/calculator2_stroke.svg';
import CalculatorResults from './results';
import CalculatorFertilizers from './fertilizer';
import { Button } from '../../../components';

const { Panel } = Collapse;

const CalculatorParameters = ({
    isCompleted, updateInternalCalc,
    form, allFieldsDisabled
}) => {
    const cn = useClassName('calculator-detail-fed-params');
    const { t } = useTranslation('calculator params fed');

    const clearFormFields = () => {
        form.setFieldsValue({
            c_k2: null,
            c_n: null,
            c_p: null,
            c_ks: null,
            c_kcl: null,
            c_k3: null,
            c_ca: null,
            c_mg: null,
            c_b: null,
            c_cu: null,
            c_k4: null,
            c_zn: null,
            c_mn: null,
            c_fe: null,
            c_k5: null,
            c_mo: null,
            c_co: null,
            c_i: null,
            c_k6: null,
            d_k2: null,
            d_n: null,
            d_p: null,
            d_ks: null,
            d_kcl: null,
            d_k3: null,
            d_ca: null,
            d_mg: null,
            d_b: null,
            d_cu: null,
            d_k4: null,
            d_zn: null,
            d_mn: null,
            d_fe: null,
            d_k5: null,
            d_mo: null,
            d_co: null,
            d_i: null,
            d_k6: null,
        });
    };

    return (
        <div>
            <div>
                <div className={cn('subtitle')}>{t('subtitle')}</div>
                <Row className={`${cn('title-line')} ${cn('border')}`}>
                    <Col span={4} />
                    <Col span={2} className={cn('gray-col')}>
                        {t('K2')}
                    </Col>
                    <Col span={2}>
                        {t('N')}
                    </Col>
                    <Col span={2}>
                        {t('P')}
                    </Col>
                    <Col span={2}>
                        {t('K')}
                    </Col>
                    <Col span={2}>
                        {t('S')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        {t('K3')}
                    </Col>
                    <Col span={2}>
                        {t('Ca')}
                    </Col>
                    <Col span={2}>
                        {t('Mg')}
                    </Col>
                    <Col span={2}>
                        {t('B')}
                    </Col>
                    <Col span={2}>
                        {t('Cu')}
                    </Col>
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results before')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k2">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_n">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_p">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_ks">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                validator="any"
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_kcl">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k3">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_ca">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_mg">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_b">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_cu">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results after')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k2">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_n">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_p">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                validator="any"
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_ks">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_kcl">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k3">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_ca">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_mg">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_b">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_cu">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={`${cn('title-line')} ${cn('border')}`}>
                    <Col span={4} />
                    <Col span={2} className={cn('gray-col')}>
                        {t('K4')}
                    </Col>
                    <Col span={2}>
                        {t('Zn')}
                    </Col>
                    <Col span={2}>
                        {t('Mn')}
                    </Col>
                    <Col span={2}>
                        {t('Fe')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        {t('K5')}
                    </Col>
                    <Col span={2}>
                        {t('Mo')}
                    </Col>
                    <Col span={2}>
                        {t('Co')}
                    </Col>
                    <Col span={2}>
                        {t('I')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        {t('K6')}
                    </Col>
                    <Col span={2} />
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results before')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k4">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_zn">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_mn">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_fe">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k5">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_mo">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_co">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_i">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k6">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} />
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results after')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k4">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_zn">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_mn">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_fe">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k5">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_mo">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_co">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_i">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k6">
                            <InputNumber
                                min={0}
                                disabled={isCompleted || allFieldsDisabled}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} />
                </Row>

            </div>
            <Row className={cn('button')}>
                <Col>
                    <Button
                        type="primary"
                        disabled={isCompleted || allFieldsDisabled}
                        onClick={updateInternalCalc}
                        size="large">
                        {t('calculate')}
                    </Button>
                    <Col span={1} />
                    <Button disabled={allFieldsDisabled} type="secondary" onClick={clearFormFields} size="large">
                        {t('clear')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default CalculatorParameters;