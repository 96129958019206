import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Table
} from 'antd';
import { useClassName } from 'utils/cn';
import '../style.less';
import { Button } from '../../../components';
import { toFixed } from '../../../utils/utils';
import moment from 'moment';

const AccountingTable = ({
    selectedEntries, setSelectedEntries, culturesMapper, agrotechnicalEvents
}) => {
    const { t } = useTranslation('accounting documents');
    const cn = useClassName('accounting-documents');

    const [filteredInfo, setFilteredInfo] = useState({});
    const [filterApplied, setFilterApplied] = useState(false);

    const cultureRender = (valInp) => culturesMapper?.[valInp] || '';

    const [tableDataset, setTableDataset] = useState([]);
    
    const sortEvents = (eventsList) => {
        // const resultArray = eventsList?.sort(function (a, b) {
        //     var key1 = moment(a.date).unix;
        //     var key2 = moment(b.date).unix;
        
        //     if (key1 < key2) {
        //         return -1;
        //     } else if (key1 === key2) {
        //         return 0;
        //     } else {
        //         return 1;
        //     }
        // });

        // return resultArray;

        const sowingArray = [];
        const remainingArray = [];

        eventsList.forEach((evnt) => {
            if (evnt.agrotechnical_event === 'Посев') {
                sowingArray.push(evnt);
            } else {
                remainingArray.push(evnt);
            }
        });

        return [...sowingArray, ...remainingArray];
    };

    const filterOptions = useMemo(() => {
        const dataSetOptions = [];
        const fieldnameOptions = [];
        const cultureOptions = [];
        const agrotechnicalOptions = [];
        const dateOptions = [];

        agrotechnicalEvents.forEach(evnt => {
            if (!fieldnameOptions.includes(evnt.vega_key)) {
                fieldnameOptions.push(evnt.vega_key);
            }

            if (!agrotechnicalOptions.includes(evnt.event_plan?.event?.name)) {
                agrotechnicalOptions.push(evnt.event_plan?.event?.name);
            }

            if (!cultureOptions.includes(evnt.event_plan.culture)) {
                cultureOptions.push(evnt.event_plan.culture);
            }

            if (!dateOptions.includes(evnt.fact_date)) {
                dateOptions.push(evnt.fact_date);
            }

            const fertArr = evnt.fertilizers?.length > 0 ? evnt.fertilizers : evnt.plant_protections?.length > 0 ? evnt.plant_protections : [];
            const stageConverter = (stageName) => stageName ? t(`modal protection item stage ${stageName}`) : '-';

            dataSetOptions.push({
                id: evnt.id,
                vega_key: evnt.vega_key,
                culture: evnt.event_plan?.culture,
                field_area: evnt.field_area,
                agrotechnical_event: evnt.event_plan?.event?.name,
                vegetation_phase: evnt.event_plan?.vegetation_phase?.name,
                fact_date: evnt.fact_date,
                plan_date: evnt.plan_date,
                performer: evnt.performer,
                fertilizers: fertArr.map(elem => elem.fertilizer.name).join(' / '),
                fertilizers_recommended: fertArr.map(elem => elem.recommended_amount).join(' / '),
                fertilizers_processed: fertArr.map(elem => elem.cultivated_area).join(' / '),
                fertilizers_stage: fertArr.map(elem => stageConverter(elem.stage)).join(' / ')
            });
        });

        return {
            dataSet: dataSetOptions?.sort((a, b) => {
                var key1 = moment(a.plan_date);
                var key2 = moment(b.plan_date);
            
                if (key1 < key2) {
                    return -1;
                } else if (key1 === key2) {
                    return 0;
                } else {
                    return 1;
                }
            }),
            fieldName: fieldnameOptions.map(x => {
                return {
                    text: x,
                    value: x
                };
            }),
            cultureName: cultureOptions.map(x => {
                return {
                    text: cultureRender(x),
                    value: x
                };
            }),
            agrotechnicalEvent: agrotechnicalOptions.map(x => {
                return {
                    text: x,
                    value: x
                };
            }),
            factDate: dateOptions.map(x => {
                return {
                    text: x,
                    value: x
                };
            })
        };
    }, [agrotechnicalEvents]);

    const clearFilter = () => {
        setFilteredInfo({});
        setFilterApplied(false);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedEntries(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedEntries,
        onChange: onSelectChange
    };

    const logChange = (paginationObj, filtersObj, sorterObj, extraObj) => {
        setFilteredInfo(filtersObj);
    };

    const toDdMmYyyy = (inpDate) => {
        const dateArray = inpDate?.split('-');
        if (
            dateArray && dateArray[0]?.length > 0 && dateArray[1]?.length > 0 && dateArray[2]?.length > 0
        ) {
            return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
        }
        return inpDate;
    };

    const columns = [
        {
            title: t('table fieldname'),
            dataIndex: 'vega_key',
            filters: filterOptions.fieldName,
            filteredValue: filteredInfo.vega_key || null,
            onFilter: (value, record) => {
                setFilterApplied(true);
                return record.vega_key.startsWith(value);
            },
            filterSearch: true
        },
        {
            title: t('table culture'),
            dataIndex: 'culture',
            render: value => cultureRender(value),
            filters: filterOptions.cultureName,
            filteredValue: filteredInfo.culture || null,
            onFilter: (value, record) => {
                setFilterApplied(true);
                return record.culture === value;
            },
            filterSearch: true
        },
        {
            title: t('table culture area'),
            dataIndex: 'field_area',
            render: value => toFixed(value)
        },
        {
            title: t('table agroevent'),
            dataIndex: 'agrotechnical_event',
            filters: filterOptions.agrotechnicalEvent,
            filteredValue: filteredInfo.agrotechnical_event || null,
            onFilter: (value, record) => {
                setFilterApplied(true);
                return record.agrotechnical_event.includes(value);
            },
            filterSearch: true
        },
        {
            title: t('table vegetation phase'),
            dataIndex: 'vegetation_phase'
        },
        {
            title: t('table plan date'),
            dataIndex: 'plan_date',
            defaultSortOrder: 'ascend',
            defaultFilteredValue : ['Open'],
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            // render: val => toDdMmYyyy(val)
            render: val => val ? moment(val).format('DD.MM.YYYY') : '-'
        },
        {
            title: t('table fact date'),
            dataIndex: 'fact_date',
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            filters: filterOptions.factDate,
            filteredValue: filteredInfo.fact_date || null,
            onFilter: (value, record) => {
                setFilterApplied(true);
                return record.fact_date === (value);
            },
            render: val => toDdMmYyyy(val),
            filterSearch: true
        },
        {
            title: t('table performer'),
            dataIndex: 'performer'
        },
        {
            title: t('table fertilizer name'),
            dataIndex: 'fertilizers'
        },
        {
            title: t('table addition norm'),
            dataIndex: 'fertilizers_recommended'
        },
        {
            title: t('table processed area'),
            dataIndex: 'fertilizers_processed'
        },
        {
            title: t('table addition stage'),
            dataIndex: 'fertilizers_stage'
        }
    ];

    return (
        <div className="accounting-documents__table">
            <div className="accounting-documents__table__button">
                <Button
                    type="secondary"
                    onClick={clearFilter}
                    disabled={!filterApplied}
                    size="small">
                    {t('button clear filter')}
                </Button>
            </div>

            <Table
                rowKey="id"
                onChange={logChange}
                style={{ overflowY: 'auto' }}
                rowSelection={rowSelection}
                dataSource={filterOptions?.dataSet}
                columns={columns} />
        </div>
    );
};

export default AccountingTable;