import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Table } from '../../../components';

import { selectors } from '../../../models';

const { tableMapData } = selectors;

export default ({ setCurField }) => {
    const cn = useClassName('field-info');
    const dataSource = useSelector(state => tableMapData(state));
    const { t } = useTranslation('pivot table');

    const setFieldNumber = (id) => {
        setCurField(id);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    let searchInput;

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={t(`search ${dataIndex}`)}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }} />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 110 }}>
                        {t('search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 110 }}>
                        {t('reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            if (dataIndex === 'culture') {
                return (record[dataIndex]?.name
                    ? record[dataIndex]?.name.toString().toLowerCase().includes(value.toLowerCase())
                    : '');
            }
            return (record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '');
        }
    });

    const requestsColumns = [
        {
            title: t('field number'),
            dataIndex: 'id',
            sorter: (a, b) => b?.id?.localeCompare(a?.id),
            render: (value, record) => (
                <div className={cn('link-item')}
                    onClick={() => setFieldNumber(record.id)}>
                    {value}
                </div>
            ),
            ...getColumnSearchProps('id')
        },
        {
            title: t('culture'),
            ellipsis: true,
            dataIndex: 'culture',
            sorter: (a, b) => b?.culture?.name?.localeCompare(a?.culture?.name),
            render: value => (
                <>
                    <div className={cn('culture-indicator')}
                        style={{ backgroundColor: value.color }} />
                    {value?.name}
                </>
            ),
            ...getColumnSearchProps('culture')
        },
        {
            title: t('sowing_date'),
            ellipsis: true,
            dataIndex: 'sowing_date',
            sorter: (a, b) => new Date(b.sowing_date) - new Date(a.sowing_date)
        },
        {
            title: t('phase_date'),
            ellipsis: true,
            dataIndex: 'phase_date',
            sorter: (a, b) => new Date(b.phase_date) - new Date(a.phase_date)
        }, {
            title: t('phase'),
            ellipsis: true,
            dataIndex: 'phase',
            sorter: (a, b) => b.phase.localeCompare(a.phase),
            render: (value, record) => (
                <div className={cn('phase')}>
                    <div>{value}</div>
                    <div className={cn('color-block')} style={{ backgroundColor: record.phase_hex_color }} />
                </div>
            )
        }
    ];


    return (
        <div className={cn()}>
            <Table id="fields-info-table"
                scroll={{ y: '440px', x: 'hidden' }}
                dataSource={dataSource}
                columns={requestsColumns}
                pagination={false} />
        </div>
    );
};