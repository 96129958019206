import * as constants from '../constants';

const initialState = {
    data: [],
    item: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getFertilizersToMainDrug.success: {
            return {
                ...state, data: action.payload.results
            };
        }

        case constants.getFertilizersToMainDrugItem.success: {
            return {
                ...state, item: { ...action.payload }
            };
        }

        default:
            return state;
    }
}
