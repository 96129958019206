import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import converter from './converter';
import { updateCalculate } from '../item-calculate/actions';

function* addNutritionSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addNutrition.try,
        constants.addNutrition.success,
        constants.addNutrition.error
    );
    try {
        const { calculate, values } = payload;
        yield put(start());
        const dataRaw = yield call(api.addNutrition, values);
        const data = converter.toClient(dataRaw);
        yield put(updateCalculate({ id: calculate.id, values: { nutrition: data.id, ...calculate } }));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getNutrition.toString(),
            defaultSaga({
                constants: constants.getNutrition,
                api: api.getNutrition,
                after: converter.toClient
            })
        ),
        yield takeLatest(
            constants.getNutritions.toString(),
            defaultSaga({
                constants: constants.getNutritions,
                api: api.getNutritions
            })
        ),
        yield takeLatest(
            constants.addNutrition.toString(),
            addNutritionSaga
        ),
        yield takeLatest(
            constants.updateNutrition.toString(),
            defaultSaga({
                constants: constants.updateNutrition,
                api: api.updateNutrition,
                after: converter.toClient
            })
        )
    ]);
}