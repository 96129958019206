import { getConstant } from '../utils';

export const createInitialProtocol = getConstant('CREATE_INITIAL_FED_REGISTRY');
export const getFertilizer = getConstant('GET_FERTILIZER_FED_REGISTRY');
export const getFertilizers = getConstant('GET_FERTILIZER_LIST_FED_REGISTRY');
export const getProtocol = getConstant('GET_PROTOCOL_FED_REGISTRY');
export const getProtocolList = getConstant('GET_PROTOCOL_LIST_FED_REGISTRY');
export const updateProtocol = getConstant('UPDATE_PROTOCOL_FED_REGISTRY');
export const createInternalCalculator = getConstant('CREATE_INTERNAL_CALC_FED_REGISTRY');
export const updateInternalCalculator = getConstant('UPDATE_INTERNAL_CALC_FED_REGISTRY');
export const updatePublicationStatus = getConstant('UPDATE_PUBLICATION_STATUS_FED_REGISTRY');
export const publishProtocol = getConstant('PUBLISH_PROTOCOL_FED_REGISTRY');
export const updateProtocolFertilizers = getConstant('UPDATE_PROTOCOL_FERTILIZERS');
// export const generateProtocolFertilizers = getConstant('GENERATE_PROTOCOL_FERTILIZERS');
export const updateFertilizerInfo = getConstant('UPDATE_FERTILIZER_INFO');
export const createNewFertilizer = getConstant('CREATE_NEW_FERTILIZER');
export const deleteProtocol = getConstant('DELETE_PROTOCOL_FED_REGISTRY');
export const updateFertilizerPrice = getConstant('UPDATE_FERTILIZER_PRICE');