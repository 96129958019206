import ConverterClass from '../../utils/converter';
import { toFixed } from '../../utils/utils';

class Converter extends ConverterClass {
    toClient(data = {}) { // eslint-disable-line
        return {
            id: data.id,
            related_fertilizers: data.related_fertilizers || [],
            //
            recommended_feeding: data.recommended_feeding || [],
            actual_feeding: data.actual_feeding || [],
            method: data.method || '',
            type: data.type,
            zones: data.zones,
            points: data.points,
            //
            created_at: data.created_at,
            updated_at: data.updated_at,
            recommended_n_main: toFixed(data.recommended_n_main, 1),
            recommended_p_main: toFixed(data.recommended_p_main, 1),
            recommended_k_main: toFixed(data.recommended_k_main, 1),
            recommended_n_sowing: toFixed(data.recommended_n_sowing, 1),
            recommended_p_sowing: toFixed(data.recommended_p_sowing, 1),
            recommended_k_sowing: toFixed(data.recommended_k_sowing, 1),
            recommended_n_feeding: toFixed(data.recommended_n_feeding, 1),
            recommended_p_feeding: toFixed(data.recommended_p_feeding, 1),
            recommended_k_feeding: toFixed(data.recommended_k_feeding, 1),
            actual_n_main: toFixed(data.actual_n_main, 1),
            actual_p_main: toFixed(data.actual_p_main, 1),
            actual_k_main: toFixed(data.actual_k_main, 1),
            actual_n_sowing: toFixed(data.actual_n_sowing, 1),
            actual_p_sowing: toFixed(data.actual_p_sowing, 1),
            actual_k_sowing: toFixed(data.actual_k_sowing, 1),
            actual_n_feeding: toFixed(data.actual_n_feeding, 1),
            actual_p_feeding: toFixed(data.actual_p_feeding, 1),
            actual_k_feeding: toFixed(data.actual_k_feeding, 1),
            actual_n_total: toFixed(data.actual_n_total, 1),
            actual_p_total: toFixed(data.actual_p_total, 1),
            actual_k_total: toFixed(data.actual_k_total, 1),
            user: data.user
        };
    }
}

export default new Converter();