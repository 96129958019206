import React from 'react';
import {
    TileLayer,
    LayersControl,
    LayerGroup
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { CustomWMSLayer } from './custom-wms';

export default () => {
    const { t } = useTranslation('layers');
    return (
        <>
            <LayersControl position="bottomleft">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay checked name={t('Field scheme')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Field scheme')}
                            checked
                            isShow
                            legend_url="teleagronom:Embulatovo_Field"
                            layer="teleagronom:Embulatovo_field"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Sampling points, agrochemistry')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Sampling points, agrochemistry')}
                            legend_url="teleagronom:Embulatovo_point_soils"
                            layer="teleagronom:Embulatovo_point_agrohim"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Sampling points, FED')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Sampling points, FED')}
                            legend_url="teleagronom:Embulatovo_poind_fed"
                            layer="teleagronom:Embulatovo_point_fed"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Productivity Zones')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Productivity Zones')}
                            legend_url="teleagronom:Embulatovo_zones"
                            layer="teleagronom:Embulatovo_zones"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Soils')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Soils')}
                            legend_url="teleagronom:Embulatovo_soils"
                            layer="teleagronom:Embulatovo_soil"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('slope')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('slope')}
                            legend_url="teleagronom:Pikaev_uklon"
                            layer="teleagronom:Embulatovo_uklon"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('exposition')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('exposition')}
                            legend_url="teleagronom:Exposition"
                            layer="teleagronom:Embulatovo_exposition"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Contours')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Contours')}
                            legend_url="teleagronom:Изолинии"
                            layer="teleagronom:Embulatovo_izolines"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Erosion')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Erosion')}
                            legend_url="line"
                            layer="teleagronom:Embulatovo_erosion"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Relief')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Relief')}
                            legend_url="teleagronom:Embulatovo_srtm"
                            layer="teleagronom:Embulatovo_SRTM"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name="NDVI">
                    <LayerGroup>
                        <CustomWMSLayer
                            name="NDVI"
                            legend_url="teleagronom:NDVI"
                            layer="teleagronom:NDVI"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('chlorides')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('chlorides')}
                            layer="teleagronom:Embulatovo_soil_analyze_Cl"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Chloride values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Chloride values')}
                            layer="teleagronom:Embulatovo_soil_analyze_Cl_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Exchangeable potassium')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Exchangeable potassium')}
                            layer="teleagronom:Embulatovo_soil_analyze_K"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Metabolic potassium values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Metabolic potassium values')}
                            layer="teleagronom:Embulatovo_soil_analyze_K_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Exchangeable magnesium')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Exchangeable magnesium')}
                            layer="teleagronom:Embulatovo_soil_analyze_MG"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Exchangeable magnesium values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Exchangeable magnesium values')}
                            layer="teleagronom:Embulatovo_soil_analyze_MG_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Exchangeable calcium')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Exchangeable calcium')}
                            layer="teleagronom:Embulatovo_soil_analyze_Ca"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Exchangeable calcium values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Exchangeable calcium values')}
                            layer="teleagronom:Embulatovo_soil_analyze_Ca_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Ammonia nitrogen')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Ammonia nitrogen')}
                            layer="teleagronom:Embulatovo_soil_analyze_N_amiac"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Ammonia nitrogen values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Ammonia nitrogen values')}
                            layer="teleagronom:Embulatovo_soil_analyze_N_amiac_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Nitrate nitrogen')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Nitrate nitrogen')}
                            layer="teleagronom:Embulatovo_soil_analyze_N_nitr"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Nitrogen nitrate values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Nitrogen nitrate values')}
                            layer="teleagronom:Embulatovo_soil_analyze_N_nitr_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Exchangeable sodium')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Exchangeable sodium')}
                            layer="teleagronom:Embulatovo_soil_analyze_Na"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Exchangeable sodium values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Exchangeable sodium values')}
                            layer="teleagronom:Embulatovo_soil_analyze_Na_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('mobile phosphorus')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('mobile phosphorus')}
                            layer="teleagronom:Embulatovo_soil_analyze_P"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('mobile phosphorus values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('mobile phosphorus values')}
                            layer="teleagronom:Embulatovo_soil_analyze_P_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('mobile sulfur')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('mobile sulfur')}
                            layer="teleagronom:Embulatovo_soil_analyze_S"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Mobile sulfur values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Mobile sulfur values')}
                            layer="teleagronom:Embulatovo_soil_analyze_S_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('sulfates')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('sulfates')}
                            layer="teleagronom:Embulatovo_soil_analyze_Sulfat"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Sulphate values')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Sulphate values')}
                            layer="teleagronom:Embulatovo_soil_analyze_Sulfat_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('conductivity')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('conductivity')}
                            layer="teleagronom:Embulatovo_soil_analyze_el_provod"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('conductivity value')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('conductivity value')}
                            layer="teleagronom:Embulatovo_soil_analyze_el_provod_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Humus')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Humus')}
                            layer="teleagronom:Embulatovo_soil_analyze_gumus"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Humus value')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Humus value')}
                            layer="teleagronom:Embulatovo_soil_analyze_gumus_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('pH saline')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('pH saline')}
                            layer="teleagronom:Embulatovo_soil_analyze_рН_salt"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('pH saline value')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('pH saline value')}
                            layer="teleagronom:Embulatovo_soil_analyze_рН_salt_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('pH water')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('pH water')}
                            layer="teleagronom:Embulatovo_soil_analyze_рН_water"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('pH water value')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('pH water value')}
                            layer="teleagronom:Embulatovo_soil_analyze_рН_water_count"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </>
    );
};
