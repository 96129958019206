import {all, call, put, takeLatest} from 'redux-saga/effects';
import {defaultSaga, getActions} from '../utils';

import * as constants from './constants';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getRecommendationRegistries.toString(),
            defaultSaga({
                constants: constants.getRecommendationRegistries,
                api: api.getRecommendationRegistries
            })
        ),
        yield takeLatest(
            constants.createRecommendationRegistry.toString(),
            defaultSaga({
                constants: constants.createRecommendationRegistry,
                api: api.createRecommendationRegistry
            })
        ),
        // yield takeLatest(
        //     constants.getMycologicalRegistries.toString(),
        //     defaultSaga({
        //         constants: constants.getMycologicalRegistries,
        //         api: api.getMycologicalRegistries
        //     })
        // )
        yield takeLatest(
            constants.getCompositions.toString(),
            defaultSaga({
                constants: constants.getCompositions,
                api: api.getCompositions
            })
        ),
        yield takeLatest(
            constants.getCultures.toString(),
            defaultSaga({
                constants: constants.getCultures,
                api: api.getCultures
            })
        ),
        yield takeLatest(
            constants.getRegions.toString(),
            defaultSaga({
                constants: constants.getRegions,
                api: api.getRegions
            })
        ),
        yield takeLatest(
            constants.getSubtypes.toString(),
            defaultSaga({
                constants: constants.getSubtypes,
                api: api.getSubtypes
            })
        ),
        yield takeLatest(
            constants.calculateRecommendation.toString(),
            defaultSaga({
                constants: constants.calculateRecommendation,
                api: api.calculateRecommendation
            })
        ),
        yield takeLatest(
            constants.makeParameterSpread.toString(),
            defaultSaga({
                constants: constants.makeParameterSpread,
                api: api.makeParameterSpread
            })
        ),
        yield takeLatest(
            constants.makeReportGeneral.toString(),
            defaultSaga({
                constants: constants.makeReportGeneral,
                api: api.makeReportGeneral
            })
        ),
        yield takeLatest(
            constants.makeReportVolume.toString(),
            defaultSaga({
                constants: constants.makeReportVolume,
                api: api.makeReportVolume
            })
        ),
        yield takeLatest(
            constants.makeReportNorm.toString(),
            defaultSaga({
                constants: constants.makeReportNorm,
                api: api.makeReportNorm
            })
        )
    ]);
}