import { combineReducers } from 'redux';

import data from './data';
import list from './list';
import analysisLoading from './analysis-loading';
import effectiveLoading from './effective-loading';
import precipitationLoading from './precipitation-loading';
import loading from './loading';
import analysis from './analysis';
import effective from './effective';
import precipitation from './precipitation';

export default combineReducers({
    analysis,
    effective,
    precipitation,
    data,
    list,
    analysisLoading,
    effectiveLoading,
    precipitationLoading,
    loading
});
