import React, {
    useMemo, useRef, useState, useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import {
    Modal, Button, Row, Col, Spin, Select, Form
} from 'antd';
import { selectors, actions } from '../../../models';

import Slider from './slider';
import Determinant from './determinant';

const {
    getCurCarouselData, getCurCarouselCultures, getCurCarouselSymptoms, getCurDeterminantTypes
} = selectors;
const {
    getCarouselData, getCarouselSymptoms, getCarouselCultures, getDeterminantTypes
} = actions;

export default () => {
    const cn = useClassName('home-page');
    const { t } = useTranslation('home');
    const slides = useSelector(state => getCurCarouselData(state)) || [];
    const cultures = useSelector(state => getCurCarouselCultures(state)) || [];
    const symptoms = useSelector(state => getCurCarouselSymptoms(state)) || [];
    const types = useSelector(state => getCurDeterminantTypes(state)) || [];
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [type, showDeterminant] = useState();
    const [diseaseId, setDiseaseId] = useState();

    useEffect(() => {
        dispatch(getCarouselCultures());
        dispatch(getDeterminantTypes());
    }, []);

    useEffect(() => {
        if (types.length > 0) {
            dispatch(getCarouselSymptoms({ culture: types[0].name }));
            dispatch(getCarouselData({
                culture: types[0].name,
                symptom_category: ''
            }));
            form.setFieldsValue({ culture: types[0].name });
        }
    }, [types]);

    const onValuesChange = (value, values) => {
        const filter = '';
        if (Object.keys(value).toString() === 'culture') {
            form.setFieldsValue({ symptom_category: '' });
            dispatch(getCarouselSymptoms({ culture: Object.values(value).toString() }));
            dispatch(getCarouselData({
                culture: Object.values(value).toString(),
                symptom_category: ''
            }));
        }
        if (Object.keys(value).toString() === 'symptom_category') {
            dispatch(getCarouselData({
                culture: values.culture.toString(),
                symptom_category: values.symptom_category.toString()
            }));
        }
    };

    const onFinish = (values) => {
        const type = types.find(item => item.name === values.culture);
        showDeterminant(type?.id);
    };

    const setDisease = (id) => {
        setDiseaseId(id);
    };

    return (
        <Row className={cn('block-auto')}>
            <Col span={24} style={{ textAlign: 'center' }}>
                <h2>{t('determinate disease')}</h2>
            </Col>
            <Col span={24}>
                <div className={cn('slides-actions')}>
                    <Form form={form}
                        onValuesChange={onValuesChange}
                        onFinish={onFinish}
                        className={cn()}
                        name="slides-action">
                        <Form.Item name="culture">
                            <Select placeholder={t('choose culture')}>
                                {
                                    cultures.map(item => (
                                        <Select.Option
                                            value={item.culture__name}
                                            key={item.culture__name}>
                                            {item.culture__name}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="symptom_category">
                            <Select placeholder={t('choose symptoms')}>
                                {
                                    symptoms.map(item => (
                                        <Select.Option
                                            value={item.symptom_category}
                                            key={item.symptom_category}>
                                            {item.symptom_category}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Button htmlType="submit" type="default">Определить</Button>
                    </Form>
                </div>
            </Col>
            <Col span={24} style={{ minHeight: '200px' }}>
                {
                    (slides.length <= 0 && symptoms.length > 0) && (
                        <div className={cn('slides-spin')}><Spin /></div>
                    )
                }
                {
                    (slides.length > 0) && (
                        <Slider slides={diseaseId ? slides.filter(item => item?.disease.id === diseaseId) : slides} />
                    )
                }
            </Col>
            {type && (
                <Determinant type={type} key={type} setDisease={setDisease} />
            )}
        </Row>
    );
};
