import * as constants from './constants';

export const getCalculatorsSoilType = payload => ({
    type: constants.getCalculatorsSoilType.toString(),
    payload
});

export const getCalculatorsSoilTypeItem = payload => ({
    type: constants.getCalculatorsSoilTypeItem.toString(),
    payload
});