import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getRB = id => request.get({
    url: urlPath(id),
    id
});

export const getRBs = () => request.get({
    url
});

export const addRB = body => request.post({
    url: urlPath('create_calculation/'),
    body
});

export const updateRB = body => request.post({
    url: urlPath(`${body.id}/update_calculation/`),
    body: body.values
});
