import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Result, Button } from 'antd';


export default () => {
    const { t } = useTranslation('404');
    return (
        <div className="error-page">
            <Result
                title={t('title')}
                subTitle={t('subtitle')}
                extra={(
                    <Link to="/">
                        <Button size="large" type="primary">{t('button')}</Button>
                    </Link>
                )} />

        </div>
    );
};