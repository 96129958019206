import React, { Component, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { connect, useDispatch } from 'react-redux';
import * as WMS from 'leaflet.wms';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import { actions, selectors } from '../../models';

const {
    setUUID
} = actions;
const legendHtmlFor = (legend_url) =>
    `<img src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&STRICT=false&style=${legend_url}">`;
const legendArr = [];
export const CustomWMSLayer = (props) => {
    const {
        url, options, layer, getData, checked, name, legend_url, attributes = [], isShow = false
    } = props;
    const map = useMap();

    const dispatch = useDispatch();
    const { t } = useTranslation('layers');

    const legend = L.control({ position: 'topleft' });

    legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'legend');
        div.innerHTML = legendHtmlFor(legend_url);
        return div;
    };
    legendArr.push(legend);

    // Add WMS source/layers
    const MySource = WMS.Source.extend({
        showFeatureInfo: (latlng, info) => {
            info && map.openPopup(info, latlng);
        },
        parseFeatureInfo: (result, url) => {
            const jsonResult = JSON.parse(result);
            const properties = jsonResult.features
                && jsonResult.features[0]
                && jsonResult.features[0].properties;
            console.log(properties);
            if (name === 'schema') {
                const { uuid } = properties;
                uuid && dispatch(setUUID(uuid));
                return null;
            }

            const popupArr = [];
            if (attributes.length > 0) {
                attributes.forEach(attr => {
                    popupArr.push(`<div class="popup-map">
                            <div>${t(`${attr} value`)}</div>
                            <div>${properties[attr]}</div></div>`);
                });
            }

            return popupArr.join('\n');
        }
    });
    const wmsLayer = new MySource(
        url,
        options
    );

    if (checked) {
        wmsLayer.getLayer(layer).addTo(map);
        console.log(layer);
        if (legend_url && isShow) {
            legend.remove();
            legend.addTo(map);
        }
    }
    map.on('overlayremove', (eo) => {
        if (eo.name === name) {
            setTimeout(() => {
                map.removeLayer(wmsLayer.getLayer(layer).addTo(map));
                legend_url && legend.remove();
            }, 10);
        }
    });
    map.on('overlayadd', (eo) => {
        if (eo.name === name) {
            setTimeout(() => {
                wmsLayer.getLayer(layer).addTo(map);
                legendArr.forEach(legend => legend.remove());
                legend_url && legend.addTo(map);
            }, 10);
        }
    });

    return null;
};