import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import {
    Button, Row, Col, Collapse
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BreadCrumbs from './breadcrumbs';


const { Panel } = Collapse;

export default (props) => {
    const cn = useClassName('home-page');
    const { t } = useTranslation('home');
    const { setPageType } = props;
    const [infoShow, setInfoShow] = useState([]);

    const handleInfoBlock = (type) => {
        const types = [...infoShow];
        if (types.includes(type)) {
            setInfoShow(types.filter(item => item !== type));
        } else {
            types.push(type);
            setInfoShow(types);
        }
    };

    const downloadPDF = () => {

    };

    return (
        <div className={cn()}>
            <Row className={cn('block-auto')}>
                <BreadCrumbs type="individuals" setPageType={setPageType} />
            </Row>
            <Row className={cn('block-auto')} style={{ marginTop: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}><h2>{t('teleagronom for individuals')}</h2></Col>
            </Row>
            <Row className={cn('block-auto')} style={{ marginBottom: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h3>{t('teleagronom for what')}</h3>
                </Col>
                <Col span={12}>
                    <div className={cn('opportunity')}>
                        <div><img src="/images/home/individuals/opportunity1.svg" alt="" /></div>
                        <div>{t('individuals opportunity 1')}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={cn('opportunity')}>
                        <div><img src="/images/home/individuals/opportunity2.svg" alt="" /></div>
                        <div>{t('individuals opportunity 2')}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={cn('opportunity')}>
                        <div><img src="/images/home/individuals/opportunity3.svg" alt="" /></div>
                        <div>{t('individuals opportunity 3')}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={cn('opportunity')}>
                        <div><img src="/images/home/individuals/opportunity4.svg" alt="" /></div>
                        <div>{t('individuals opportunity 4')}</div>
                    </div>
                </Col>
            </Row>
            <Row className={cn('block-auto')} style={{ marginBottom: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h3>{t('work steps')}</h3>
                </Col>
            </Row>
            <Row className={cn('block-auto')}>
                <Col span={3} />
                <Col span={18}>
                    <Collapse ghost
                        className={`${cn('collapse')} ${cn('collapse-individuals')}`}
                        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} />}
                        expandIconPosition="right">
                        <Panel header={<PanelHeader cn={cn} t={t} img="01" step="1" />} key="1">
                            <Row gutter={20} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <Col span={11}>
                                    <div className={cn('module-step-text-left')}>
                                        {t('individuals step1 text')}
                                    </div>
                                </Col>
                                <Col span={13} className={cn('module-step-img')}>
                                    <img src="/images/home/individuals/step1.png" />
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="02" step="2" />} key="2">
                            <Row gutter={20} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <Col span={3} />
                                <Col span={8} className={cn('module-step-img')}>
                                    <img src="/images/home/individuals/step2.png" />
                                </Col>
                                <Col span={10}>
                                    <div className={cn('module-step-text-right')}>
                                        {t('individuals step2 text')}
                                    </div>
                                </Col>
                                <Col span={3} />
                            </Row>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="03" step="3" />} key="3">
                            <div className={cn('module-block')}>
                                <p>{t('individuals step3 title')}</p>
                                <ul className={cn('module-block-ul')}>
                                    {
                                        [1, 2, 3, 4].map(item => (
                                            <li key={t(`individuals step3 li${item}`)}>{t(`individuals step3 li${item}`)}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="04" step="4" />} key="4">
                            <div className={cn('module-step-table')}>
                                <ol>
                                    <Row>
                                        <Col span={12}>{t('individuals step4 title1')}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator1')}</li></Col>
                                        <Col span={12}>{t('individuals step4 indicator1 desc')}</Col>
                                    </Row>
                                    <Row className={cn('module-step-table-line')}>
                                        <Col span={12}><li>{t('individuals step4 indicator2')}</li></Col>
                                        <Col span={12}>{t('individuals step4 indicator2 desc')}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator3')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator4')}</li></Col>
                                    </Row>
                                    <Row className={cn('module-step-table-line')}>
                                        <Col span={12}>{t('individuals step4 title2')}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator5')}</li></Col>
                                        <Col span={12}>{t('individuals step4 indicator5 desc')}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator6')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator7')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator8')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator9')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator10')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator11')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator12')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator13')}</li></Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><li>{t('individuals step4 indicator14')}</li></Col>
                                    </Row>
                                </ol>
                            </div>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="05" step="5" />} key="5">
                            <div className={cn('module-block')}>
                                <p>
                                    {t('individuals step5 text')}
                                </p>
                                <div className={cn('module-step-button')}>
                                    <Button type="default" onClick={downloadPDF}>
                                        {t('individuals step5 button')}
                                    </Button>
                                </div>
                            </div>
                        </Panel>
                        <Panel header={<PanelHeader cn={cn} t={t} img="06" step="6" />} key="6">
                            <div className={cn('module-block')}>
                                <p>{t('individuals step6 text')}</p>
                                <ul className={cn('module-block-ul')}>
                                    {
                                        [1, 2, 3].map(item => (
                                            <li key={t(`individuals step6 li${item}`)}>{t(`individuals step6 li${item}`)}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Panel>
                    </Collapse>
                </Col>
                <Col span={3} />
            </Row>

            <Row className={cn('block-auto')} style={{ marginTop: '60px', paddingBottom: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h2>
                        {t('important info')}
                    </h2>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                    <Row onClick={() => handleInfoBlock('info_1')}
                        className={`${cn('module-block')} ${cn('module-block-column')} ${cn('module-block-frame')}`}>
                        <Col>
                            <img src="/images/home/individuals/info_1.svg" className={cn('module-block__img')} />
                        </Col>
                        <Col className={!infoShow.includes('info_1') && cn('module-block-title')}>
                            <h4>{t('module individuals1')}</h4>
                            {infoShow.includes('info_1') && (
                                <div className={cn('module-block-text')}>
                                    <ol className={cn('module-block-text-ol')}>
                                        <li>
                                            {t('individuals info_1 ol 1')}
                                        </li>
                                        <li>
                                            {t('individuals info_1 ol 2')}
                                        </li>
                                        <li>
                                            {t('individuals info_1 ol 3')}
                                        </li>
                                        <li>
                                            {t('individuals info_1 ol 4')}
                                        </li>
                                        <li>
                                            {t('individuals info_1 ol 5')}
                                        </li>
                                        <li>
                                            {t('individuals info_1 ol 6')}
                                        </li>
                                        <li>
                                            {t('individuals info_1 ol 7')}
                                        </li>
                                    </ol>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                    <Row onClick={() => handleInfoBlock('info_2')}
                        className={`${cn('module-block')} ${cn('module-block-column')} ${cn('module-block-frame')}`}>
                        <Col>
                            <img src="/images/home/individuals/info_2.svg" className={cn('module-block__img')} />
                        </Col>
                        <Col className={!infoShow.includes('info_2') && cn('module-block-title')}>
                            <h4>{t('module individuals2')}</h4>
                            {infoShow.includes('info_2') && (
                                <div className={cn('module-block-text')}>
                                    <ol className={cn('module-block-text-ol')}>
                                        <li>
                                            {t('individuals info_2 ol 1')}
                                        </li>
                                        <li>
                                            {t('individuals info_2 ol 2')}
                                        </li>
                                        <li>
                                            {t('individuals info_2 ol 3')}
                                        </li>
                                        <li>
                                            {t('individuals info_2 ol 4')}
                                        </li>
                                        <li>
                                            {t('individuals info_2 ol 5')}
                                        </li>
                                    </ol>
                                    <h4>{t('individuals info_2 title')}</h4>
                                    <p>{t('individuals info_2 text')}</p>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                    <Row onClick={() => handleInfoBlock('info_3')}
                        className={`${cn('module-block')} ${cn('module-block-column')} ${cn('module-block-frame')}`}>
                        <Col>
                            <img src="/images/home/individuals/info_3.svg" className={cn('module-block__img')} />
                        </Col>
                        <Col className={!infoShow.includes('info_3') && cn('module-block-title')}>
                            <h4>{t('module individuals3')}</h4>
                            {infoShow.includes('info_3') && (
                                <div className={cn('module-block-text')}>
                                    <Row>
                                        <Col span={12}>
                                            <img src="/images/home/certificate/01.jpg" alt="" />
                                        </Col>
                                        <Col span={12}>
                                            <img src="/images/home/certificate/02.jpg" alt="" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <img src="/images/home/certificate/03.jpg" alt="" />
                                        </Col>
                                        <Col span={12}>
                                            <img src="/images/home/certificate/04.jpg" alt="" />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

const PanelHeader = ({
    step, img, cn, t
}) => (
    <div className={cn('tabs__step')}>
        <img src={`/images/nums/${img}.svg`} className={cn('module-block__img-nums')} />
        <div>{t(`individuals step${step}`)}</div>
    </div>
);
