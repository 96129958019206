import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, DatePicker, Form, Input, Row, Select, Collapse
} from 'antd';

import '../style.less';
import { useSelector } from 'react-redux';
import { getCulturesCatalog } from '../../../../../models/cultures/selectors';
import { DATEPICKER_FORMAT } from '../../../../../constants';

const { Panel } = Collapse;

const CalculationData = ({ yearOptions }) => {
    const { t } = useTranslation('field representation mycological analysis');
    const culturesList = useSelector(state => getCulturesCatalog(state));

    return (
        <>
            <Collapse style={{ marginBottom: '15px' }}>
                <Panel header={t('calculation data header')} key="data">
                <Row>
                    <Col span={5}>
                        <Form.Item name="protocol_num" label={t('calculation data protocol_num')}>
                            <Input className="w-100" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={5}>
                        <Form.Item name="sample_date" label={t('calculation data sample_date')}>
                            <DatePicker format={DATEPICKER_FORMAT} className="w-100" />
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={5}>
                        <Form.Item name="experiment_date" label={t('calculation data experiment_date')}>
                            <DatePicker format={DATEPICKER_FORMAT} className="w-100" />
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={5}>
                        <Form.Item name="field_num" label={t('calculation data field_num')}>
                            <Input className="w-100" disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={5}>
                        <Form.Item name="year" label={t('calculation data year')}>
                            <Select
                                className="w-100"
                                options={yearOptions.map(x => {
                                    return {
                                        label: x,
                                        value: x
                                    };
                                })} />
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={5}>
                        <Form.Item name="culture" label={t('calculation data culture')}>
                            <Select
                                disabled
                                options={culturesList?.map(x => {
                                    return {
                                        label: x.name,
                                        value: x.id
                                    };
                                })}
                                className="w-100" />
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={5}>
                        <Form.Item name="sample" label={t('calculation data sample')}>
                            <Input className="w-100" disabled />
                        </Form.Item>
                    </Col>
                </Row>
                </Panel>
            </Collapse>
        </>
    );
};

export default CalculationData;