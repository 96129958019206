import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Table, TableFooter, Tooltip, SortIcon
} from '../../../components';
import cn from '../../../utils/cn';

const ROW_DIVIDER = 10;

@withRouter
@withTranslation('fertilizers table')
@cn('fertilizers-table')
export default class FertilizersTable extends Component {

    get requestsColumns() {
        const { cn } = this;
        const {
            t, history, isFed = false
        } = this.props;

        if (isFed) {
            return [
                {
                    title: t('name'),
                    dataIndex: 'name',
                    key: 'name',
                    className: cn('name'),
                    render: (text, record) => (
                        <Tooltip
                            placement="bottomLeft"
                            title={text}>
                            {text}
                        </Tooltip>
                    )

                },
                {
                    title: t('B'),
                    dataIndex: 'B',
                    key: 'B'
                },
                {
                    title: t('Ca'),
                    dataIndex: 'Ca',
                    key: 'Ca'
                },
                {
                    title: t('Co'),
                    dataIndex: 'Co',
                    key: 'Co'
                },
                {
                    title: t('Cu'),
                    dataIndex: 'Cu',
                    key: 'Cu'
                },
                {
                    title: t('Fe'),
                    dataIndex: 'Fe',
                    key: 'Fe'
                },
                {
                    title: t('I'),
                    dataIndex: 'I',
                    key: 'I'
                },
                {
                    title: t('K2O'),
                    dataIndex: 'K2O',
                    key: 'K2O'
                },
                {
                    title: t('Mg'),
                    dataIndex: 'Mg',
                    key: 'Mg'
                },
                {
                    title: t('Mn'),
                    dataIndex: 'Mn',
                    key: 'Mn'
                },
                {
                    title: t('Mo'),
                    dataIndex: 'Mo',
                    key: 'Mo'
                },
                {
                    title: t('N'),
                    dataIndex: 'N',
                    key: 'N'
                },
                {
                    title: t('P2O5'),
                    dataIndex: 'P2O5',
                    key: 'P2O5'
                },
                {
                    title: t('S'),
                    dataIndex: 'S',
                    key: 'S'
                },
                {
                    title: t('Zn'),
                    dataIndex: 'Zn',
                    key: 'Zn'
                },
                {
                    title: t('avg_price'),
                    dataIndex: 'price',
                    key: 'avg_price',
                    className: cn('content-main'),
                    sortType: 'number',
                    width: 140
                }
            ];
        }

        return [
            {
                title: t('name'),
                dataIndex: 'name',
                key: 'name',
                className: cn('name'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}>
                        {text}
                    </Tooltip>
                )

            },
            {
                title: t('avg_price'),
                dataIndex: 'avg_price',
                key: 'avg_price',
                className: cn('content-main'),
                sortType: 'number',
                width: 140,
                render: (value, record) => (
                    value ? t('price unit', { value }) : '-'
                )
            },
            {
                title: t('N'),
                dataIndex: 'content_n',
                key: 'content_n',
                className: cn('content-main'),
                sortType: 'number',
                width: 80,
                render: (value, record) => (
                    value ? t('content unit', { value }) : t('no')
                )
            },
            {
                title: t('P'),
                dataIndex: 'content_p',
                key: 'content_p',
                className: cn('content-main'),
                sortType: 'number',
                width: 80,
                render: (value, record) => (
                    value ? t('content unit', { value }) : t('no')
                )
            },
            {
                title: t('K'),
                dataIndex: 'content_k',
                key: 'content_k',
                className: cn('content-main'),
                sortType: 'number',
                width: 80,
                render: (value, record) => (
                    value ? t('content unit', { value }) : t('no')
                )
            }
        ];
    }

    render() {
        const { cn } = this;
        const {
            selectFertilizerEntity = () => {
                console.log('selectFertilizerEntity');
            },
            isFed = false,
            history,
            onCurrentPageChange,
            onPageSizeChange,
            filterItems,
            pageSize,
            pageNum,
            dataSource,
            dataSize
        } = this.props;

        return (
            <div>

                <Table
                    onRow={(record, rowIndex) => ({
                        onClick: () => {
                            if (isFed) {
                                selectFertilizerEntity(record);
                            } else {
                                history.push(`/fertilizer/${record.id}`);
                            }
                        }
                    })}
                    dataSource={dataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
                {dataSize > 1 && (
                    <TableFooter
                        filterItems={filterItems}
                        showSizeChanger={false}
                        onSelect={onPageSizeChange}
                        onChange={onCurrentPageChange}
                        pageSize={pageSize}
                        current={pageNum}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                )}
            </div>
        );
    }
}