import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getRequestsBody = state => state.models[name].requests;
export const getRequestsList = state => state.models[name].requests.content;
export const getRequestsListCount = state => state.models[name].requests.totalElements;
export const getCurrentRequest = state => state.models[name].request;
export const isRequestLoading = state => state.models[name].loading;
