import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import cn from '../../../utils/cn';
import './style.less';

@cn('panel-content')
class PanelContent extends Component {
    render() {
        const { cn } = this;
        const {
            className,
            children,
            loading,
            ...rest
        } = this.props;
        return (
            <div className={`${cn()} ${className}`}>
                {loading && <Spin />}
                {children}
            </div>
        );
    }
}

PanelContent.propTypes = {
    /** Дополнительное имя класса */
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    loading: PropTypes.bool
};

PanelContent.defaultProps = {
    className: '',
    loading: false
};

export default PanelContent;