import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

// export const addMycologicalRegistry = body => request.post({
//     url,
//     body
// });

export const getRecommendationRegistries = () => request.get({
    url
});

export const createRecommendationRegistry = body => request.post({
    url,
    body
});

export const calculateRecommendation = body => request.post({
    url: `/api/v1/recomendation_register/register/${body.recommendationId}/calculate_recommendations/`,
    body: body.values
});

//

export const getCompositions = body => request.get({
    url: `/api/v1/rb_calculator/mechanical_composition_of_soil/${body?.filter ? body.filter : ''}`
});

export const getCultures = body => request.get({
    url: `/api/v1/culture/culture/?page_size=1000${body?.filter ? body.filter : ''}`
});

export const getRegions = body => request.get({
    url: `/api/v1/region/region/?page_size=1000${body?.filter ? body.filter : ''}`
});

export const getSubtypes = body => request.get({
    url: `/api/v1/soil_type/soil_subtype/${body?.filter ? body.filter : ''}`
});

export const makeParameterSpread = body => request.post({
    url: `/api/v1/recomendation_register/register/${body.registerId}/replicate/`,
    body: body.values
});

export const makeReportGeneral = body => request.get({
    url: `/api/v1/recomendation_register/register/?filter={"vega_key": "${body.vegaKey}", "year": "${body.fieldYear}"}`
});

export const makeReportVolume = body => request.post({
    url: `/api/v1/recomendation_register/report/fertilizer_price_report/`,
    body: body.values
});

export const makeReportNorm = body => request.post({
    url: `/api/v1/recomendation_register/report/fertilizer_weight_report/`,
    body: body.values
});