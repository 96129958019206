import * as constants from './constants';

export const getRegistrants = payload => ({
    type: constants.getRegistrants.toString(),
    payload
});

export const getRegistrant = payload => ({
    type: constants.getRegistrant.toString(),
    payload
});
