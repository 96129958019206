import * as constants from '../constants';

const initialState = {
    results: [],
    count: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCarouselData.success: {
            return action.payload;
        }

        case constants.getCarouselData.try: {
            return initialState;
        }

        default:
            return state;
    }
}
