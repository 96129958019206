import * as constants from './constants';

export const getPivotTableMapData = payload => ({
    type: constants.getPivotTableMapData.toString(),
    payload
});

export const getPivotTableByField = payload => ({
    type: constants.getPivotTableByField.toString(),
    payload
});

export const getPivotTableByFieldTotal = payload => ({
    type: constants.getPivotTableByFieldTotal.toString(),
    payload
});

export const getPivotTableByCulture = payload => ({
    type: constants.getPivotTableByCulture.toString(),
    payload
});

export const getPivotTableByCultureTotal = payload => ({
    type: constants.getPivotTableByCultureTotal.toString(),
    payload
});


export const getPivotByAgroTechicalFields = payload => ({
    type: constants.getPivotByAgroTechicalFields.toString(),
    payload
});


export const getPivotByAgroTechicalPeriod = payload => ({
    type: constants.getPivotByAgroTechicalPeriod.toString(),
    payload
});

export const clearPivotTable = payload => ({
    type: constants.clearPivotTable.toString()
});
