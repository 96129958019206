import { combineReducers } from 'redux';

import calculator from './calculator';
import protocolCreated from './protocol-created';
import calculatorCreated from './calculator-created';
import calculatorsList from './calculators';
import loading from './loading';
import reproductions from './reproductions';
import varieties from './varieties';
import deletedItem from './item-deleted';
import cultures from './cultures';
import fertilizers from './fertilizers';
import purposes from './purposes';
import regions from './regions';
import causativeAgents from './causative-agents';
import contaminationCreated from './contamination-created';
import protocolList from './protocol-list';
import protocol from './protocol';
import protocolUpdated from './protocol-updated';
import userList from './user-list';
import uploadedFile from './uploaded-file';
import updatedPhoto from './updated-photo';
import causativeAgentItems from './causative-agent-items';
import symptoms from './symptoms';
import subgroups from './subgroups';
import vegPhases from './veg-phases';
import drugs from './drugs';
import diseases from './diseases';

export default combineReducers({
    diseases,
    causativeAgentItems,
    calculatorCreated,
    drugs,
    symptoms,
    subgroups,
    vegPhases,
    updatedPhoto,
    uploadedFile,
    protocolUpdated,
    userList,
    protocol,
    protocolList,
    contaminationCreated,
    calculatorsList,
    calculator,
    protocolCreated,
    loading,
    reproductions,
    varieties,
    cultures,
    fertilizers,
    deletedItem,
    purposes,
    regions,
    causativeAgents
});
