import * as constants from '../constants';
import { updateFileDescription } from '../api';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.updateFileDescription.try:
        case constants.getMycologicalRegistry.try:
        case constants.getMycologicalRegistries.try:
        case constants.addMycologicalRegistry.try:
        case constants.updateMycologicalRegistry.try: {
            return true;
        }

        case constants.updateFileDescription.success:
        case constants.updateFileDescription.error:
        case constants.getMycologicalRegistries.success:
        case constants.getMycologicalRegistry.success:
        case constants.addMycologicalRegistry.success:
        case constants.updateMycologicalRegistry.success:
        case constants.getMycologicalRegistries.error:
        case constants.getMycologicalRegistry.error:
        case constants.addMycologicalRegistry.error:
        case constants.updateMycologicalRegistry.error: {
            return false;
        }

        default:
            return state;
    }
}