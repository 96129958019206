import * as constants from './constants';

export const getDiseases = payload => ({
    type: constants.getDiseases.toString(),
    payload
});

export const getDisease = payload => ({
    type: constants.getDisease.toString(),
    payload
});

export const getDiseasesCausativeAgent = payload => ({
    type: constants.getDiseasesCausativeAgent.toString(),
    payload
});

export const getDiseasesCulture = payload => ({
    type: constants.getDiseasesCulture.toString(),
    payload
});

export const getDiseasesDamageArea = payload => ({
    type: constants.getDiseasesDamageArea.toString(),
    payload
});

export const getDiseasesSubGroup = payload => ({
    type: constants.getDiseasesSubGroup.toString(),
    payload
});

export const getDiseasesGroup = payload => ({
    type: constants.getDiseasesGroup.toString(),
    payload
});
