import * as constants from '../constants';
import converter from '../converter';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getHarmfulObjectDivisions.success: {
            return action.payload.results;
        }

        case constants.getHarmfulObjectDivisions.error: {
            return { error: 'backend error' };
        }

        default:
            return state;
    }
}
