import * as constants from './constants';

export const getNDMI = payload => ({
    type: constants.getNDMI.toString(),
    payload
});

export const getNDVI = payload => ({
    type: constants.getNDVI.toString(),
    payload
});

export const getNDVIandNDMI = payload => ({
    type: constants.getNDVIandNDMI.toString(),
    payload
});

export const getSWI = payload => ({
    type: constants.getSWI.toString(),
    payload
});

export const setUUID = payload => ({
    type: constants.setUUID.toString(),
    payload
});