import { getConstant } from '../utils';

export const getPesticides = getConstant('GET_PESTICIDES');
export const getPesticide = getConstant('GET_PESTICIDE');

export const getPesticideApplicationArea = getConstant('GET_PESTICIDES_APPLICATION_AREA');
export const getPesticideApplicationAreaItem = getConstant('GET_PESTICIDES_APPLICATION_AREA_ITEM');
export const getPesticideChemicalClasses = getConstant('GET_PESTICIDES_CHEMICAL_CLASSES');
export const getPesticideChemicalClassesItem = getConstant('GET_PESTICIDES_CHEMICAL_CLASSES_ITEM');
export const getPesticideHarmfulObject = getConstant('GET_PESTICIDES_HARMFUL_OBJECT');
export const getPesticideHarmfulObjectItem = getConstant('GET_PESTICIDES_HARMFUL_OBJECT_ITEM');
export const getPesticideHazardClass = getConstant('GET_PESTICIDES_HAZARD_CLASS');
export const getPesticideHazardClassItem = getConstant('GET_PESTICIDES_HAZARD_CLASS_ITEM');
export const getPesticidePlaceOfProduction = getConstant('GET_PESTICIDES_PLACE_OF_PRODUCTION');
export const getPesticidePlaceOfProductionItem = getConstant('GET_PESTICIDES_PLACE_OF_PRODUCTION_ITEM');
export const getPesticideGroup = getConstant('GET_PESTICIDES_GROUP');
export const getPesticideGroupItem = getConstant('GET_PESTICIDES_GROUP_ITEM');
export const getPesticideMainDrug = getConstant('GET_PESTICIDES_MAIN_DRUG');
export const getPesticideMainDrugItem = getConstant('GET_PESTICIDES_MAIN_DRUG_ITEM');
export const getPesticideProductApplication = getConstant('GET_PESTICIDES_PRODUCT_APPLICATION');
export const getPesticideProductApplicationItem = getConstant('GET_PESTICIDES_PRODUCT_APPLICATION_ITEM');
export const getPesticidePreparativeForm = getConstant('GET_PESTICIDES_PREPARATIVE_FORM');
export const getPesticidePreparativeFormItem = getConstant('GET_PESTICIDES_PREPARATIVE_FORM_ITEM');
export const getPesticideTreatmentType = getConstant('GET_PESTICIDES_TREATMENT_TYPE');
export const getPesticideTreatmentTypeItem = getConstant('GET_PESTICIDES_TREATMENT_TYPE_ITEM');
