import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getCalculatorsMordoviaObject = state => state.models[name].calculators;
export const getCalculatorsMordoviaCatalog = state => state.models[name].calculators.results;
export const getCalculatorsMordoviaCatalogCount = state => state.models[name].calculators.count;
export const getCurrentCalculatorMordovia = state => state.models[name].calculator;
export const getCurCalculatorField = state => state.models[name].calculator.calculator;
export const getCurrentForecastCalculatorMordovia = state => state.models[name].calculator.forecast;
export const getCurrentVegaKey = state => state.models[name].calculator?.vega_key;
export const getFieldUser = state => state.models[name].calculator?.user;

export const getCurrentForecastErrorCalculatorMordovia = state => state.models[name].calculator.forecast_error;
export const isCalculatorMordoviaLoading = state => state.models[name].loading;
export const isFieldMordoviaLoading = state => state.models[name].fieldLoading;

export const getCurrentShape = state => state.models[name].shape;
export const getProbabilityData = state => state.models[name].probabilityData;
export const isShapeLoading = state => state.models[name].loading;

export const curPoints = state => state.models[name].points.results;

export const calculatorsGroupInfo = state => state.models[name].calculator.calculator_group;
export const calculatorsGroup = state => state.models[name].calculator.calculator_group?.calculators;

export const isForecastLoading = state => state.models[name].forecastLoading;
export const isCalcGroupErroneous = state => state.models[name].calculatorGroupResult;
export const currentFieldYearsList = state => state.models[name].fieldYears;