import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Row, Col, Select, Input, Form, InputNumber } from 'antd';

import cn from '../../../utils/cn';
import {
    Title, Button, Tabs, InfoMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import { regions } from '../../../regions';

const { TextArea, Password } = Input
const {
    updatePassword
} = actions;
const {
    getUser
} = selectors;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({
    updatePassword
});

const { TabPane } = Tabs;
const { Option } = Select;


@withRouter
@connect(mstp, mdtp)
@withTranslation('profile detail')
@cn('profile-detail')
export default class ProfileData extends Component {

    passwordField = React.createRef();

    onSubmit = (e, form) => {
        const { updateProfile, user, onTabChange } = this.props;
        const { validateFields } = form;
        const { id } = user;
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                values.id = id;
                updatePassword(values);
                onTabChange('2');
            } else {
                console.warn(err);
            }
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    onChange = (e, form) => {
        this.setState(e);
    };

    validateCompare = (rule, value, callback) => {
        const { t } = this.props;

        const passwordField = this.passwordField.current;
        const { form } = passwordField.context;
        const newPassword = form.getFieldValue('new_password');

        if (value && newPassword !== value) {
            callback(t('passwords do not match'));
        }

        callback();
    };

    render() {
        const { cn } = this;
        const { t, user } = this.props;

        return (
            <Form onSubmit={this.onSubmit}
                className={cn('data')}
                onMount={this.onMount}
                onValuesChange={this.onChange}
                name="profile-form">
                <div>
                    <Row gutter={20}>
                        <Col span={8}>
                            <div className="field">
                                <Password
                                    className={cn('input')}
                                    label={t('old password')}
                                    name="old_password"
                                    validator={this.validateCompare}
                                    placeholder={t('old password placeholder')}
                                    type="password"
                                    maxLength={255} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <div className="field">
                                <Password
                                    className={cn('input')}
                                    label={t('new password')}
                                    ref={this.passwordField}
                                    name="new_password"
                                    placeholder={t('new password placeholder')}
                                    type="password"
                                    maxLength={255} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <div className="field">
                                <Password
                                    className={cn('input')}
                                    label={t('repeat password')}
                                    name="password_repeat"
                                    validator={this.validateCompare}
                                    placeholder={t('repeat password placeholder')}
                                    type="password"
                                    maxLength={255} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className={cn('button-block')}>
                            <Button
                                type="primary"
                                htmlType="submit">
                                {t('save')}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row gutter={20}>
                    <Col span={24} className={cn('info')}>
                        <div className={cn('info', 'inner')}>{t('info')}</div>
                    </Col>
                </Row>
            </Form>

        );
    }
}