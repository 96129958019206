import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import { Button, Col, Collapse, Form, Image, Input, InputNumber, Modal, Popover, Row, Select, Table, Tabs, Upload } from 'antd';
import InfoModal from './info-modal';
import { useDispatch, useSelector } from 'react-redux';
import { currentCausativeAgentsPhyto, currentFertilizersPhyto, drugsPhyto, uploadedFilePhyto } from '../../../models/calculators-phyto/selectors';
import {
    addInternalContamination,
    getCausativeAgents,
    updateCalculatorPhyto,
    updateRegistryPhyto,
    uploadRegistryFile,
    updateInternalContamination,
    updatePhotoDescription,
    getDrugsPhyto,
    getProtocolPhyto,
    removeInternalContamination
} from '../../../models/calculators-phyto/actions';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import * as constants from '../../../constants';

const { Panel } = Collapse;

const InternalContamination = ({
    internalPathogenStage, setInternalPathogenStage,
    activeTabs, setActiveTabs,
    form, images, calculatorId,
    pathogens, fertilizersFullList,
    curProto, showRecalculateButtons
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const { t: pdfT } = useTranslation('phyto calculator');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentDisease, setCurrentDisease] = useState({});
    const [agentPage, setAgentPage] = useState(1);
    const [agentsList, setAgentsList] = useState([]);
    const [showDrugModal, setShowDrugModal] = useState(false);
    const [drugDisease, setDrugDisease] = useState({});
    const [activeDrugTab, setActiveDrugTab] = useState(null);
    const [selectedDrugList, setSelectedDrugList] = useState([]);

    const agents = useSelector(state => currentCausativeAgentsPhyto(state));
    const uploadedRegistryFile = useSelector(state => uploadedFilePhyto(state));

    useEffect(() => {
        pathogens?.length > 0 && setActiveDrugTab(pathogens[0].id);
    }, [pathogens]);

    useEffect(() => {
        pathogens?.forEach(pathogenVal => {
            if (pathogenVal?.pathogens_number || pathogenVal?.pathogens_percentage) {
                setInternalPathogenStage(2);
            }
        })
    }, [pathogens])
    

    const showDiseaseModal = (disease) => {
        setCurrentDisease(disease);
        setIsModalOpen(true);
    };

    const nextContaminationStep = () => {
        if (internalPathogenStage === 2) {
            const formVals = form?.getFieldsValue();
        }
        setInternalPathogenStage(internalPathogenStage + 1);
    }; 

    useEffect(() => {
        dispatch(getCausativeAgents({ page: agentPage }));
    }, [agentPage]);

    useEffect(() => {
        const formVals = form?.getFieldsValue();
        const { fito_calculator } = form?.getFieldsValue();
        if (fito_calculator) {
            const { total_pathogens_percentage } = fito_calculator;
            if (total_pathogens_percentage) {
                setInternalPathogenStage(3);
            }
        }
    }, [form]);

    useEffect(() => {
        const { fito_calculator } = form?.getFieldsValue();
        if (fito_calculator) {
            const { total_pathogens_percentage } = fito_calculator;
            if (total_pathogens_percentage) {
                setInternalPathogenStage(3);
            }
        }
    }, [curProto]);

    useEffect(() => {
        const { next, results } = agents;
        if (results?.length > 0) {
            const toInsert = [];
            results?.forEach(resVal => {
                const findingSame = agentsList?.filter(agnt => agnt?.id === resVal?.id);
                if (!findingSame[0]) {
                    toInsert.push(resVal);
                }
            })
            setAgentsList([
                ...agentsList,
                ...toInsert?.map(x => {
                    return {
                        label: x?.name,
                        value: x?.id
                    };
                })?.filter(insertableItem => {
                    const searchResult = agentsList?.filter(agentItem => agentItem?.value === insertableItem?.value);
                    return searchResult?.length < 1;
                })]);
        }
        if (next?.length > 0) {
            setAgentPage(agentPage + 1);
        }
    }, [agents]);
    
    useEffect(() => {
        const { image_type, uploaded_file } = uploadedRegistryFile;
        if (uploaded_file?.id && image_type === 'internal_contamination') {
            let newImg = [];
            if (images?.length > 0) {
                newImg = images?.map(x => x?.id);
            }
            setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    internal_pathogen_images: [...newImg, uploaded_file?.id]
                }
            }));

            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }, [uploadedRegistryFile]);

    const handleUpdatePhotoDescription = (currentPhoto, newDescription) => {
        dispatch(updatePhotoDescription({
            imageId: currentPhoto?.id,
            values: {
                picture_desc: newDescription
            }
        }));
    };

    const editableDescriptionContent = (photoInstance, oldVals) => {
        const [photoDescription, setPhotoDescription] = useState('');

        useEffect(() => {
            setPhotoDescription(oldVals)
        }, [oldVals])

        return (
            <div style={{ width: '300px', height: '200px' }}>
                <Row style={{ height: '150px' }}>
                    <Input.TextArea
                        style={{ maxHeight: '150px' }}
                        value={photoDescription}
                        defaultValue={oldVals}
                        onChange={evt => setPhotoDescription(evt?.target?.value)} />
                </Row>
                <Row align="middle" gutter={12} style={{ height: '50px' }}>
                    <Col span={24}>
                        <Button onClick={() => handleUpdatePhotoDescription(photoInstance, photoDescription)} type="primary" style={{ width: '100%' }}>
                            {t('save photo changes')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    const imagesColumns = [
        {
            key: "picture_file",
            dataIndex: "picture_file",
            render: value => value ? (
                <Image width={100} src={value} />
            ) : "-"
        },
        {
            key: "picture_name",
            dataIndex: "picture_name"
        },
        {
            key: "picture_desc",
            dataIndex: "picture_desc",
            render: (value, record) => (
                <Popover content={() => editableDescriptionContent(record, value)} trigger="click">
                    {value}
                </Popover>
            )
        },
        {
            key: "uploaded",
            dataIndex: "uploaded",
            render: value => value ? moment(value).format('DD.MM.YYYY') : "-"
        },
        {
            key: "actions",
            dataIndex: '',
            render: (value, record) => (
                <DeleteOutlined
                    onClick={() => {
                        const newImgs = [...images].map(x => x.id).filter(y => y !== record?.id);
                        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
                        dispatch(updateRegistryPhyto({
                            calcId: match?.params?.id,
                            values: {
                                internal_pathogen_images: newImgs
                            }
                        }));
                    }} />
            )
        }
    ];

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (match?.params?.id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    protocolId: match?.params?.id,
                    imageType: 'internal_contamination',
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `Internal pathogen ${moment().format('YYYY-MM-DDThh:mm')}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    const downloadDiseasePDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        doc.setFontSize(14);

        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageWidthTable = pageSize.width ? pageSize.width - 80 : pageSize.getWidth() - 80;
        const text = doc.splitTextToSize(currentDisease?.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        doc.setFontSize(12);
        doc.setFontSize(12);

        const rowOne = pdfT('modal row 1');
        const rowTwo = pdfT('modal row 2');
        const rowThree = pdfT('modal row 3');
        const rowFour = pdfT('modal row 4');
        const rowFive = pdfT('modal row 5');
        const rowSix = pdfT('modal row 6');
        
        const body = [
            [rowOne, currentDisease?.causative_agent?.name],
            [rowTwo, currentDisease?.causative_agent?.causes_of_occurrence],
            [rowThree, currentDisease?.pathogen_morphology],
            [rowFour, currentDisease?.biology_spread_pathogen],
            [rowFive, currentDisease?.ecology_of_disease],
            [rowSix, currentDisease?.disease_harmfulness],
        ];

        doc.autoTable({
            startY: finalY,
            styles: {
                fontSize: 11,
                cellWidth: 'wrap'
            },
            columnStyles: {
                0: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 }
            },
            body
        });
        const fileName = `${currentDisease?.name} ${pdfT('summary')}`;
        doc.save(`${fileName}.pdf`);
    };

    const handleCreateInternal = () => {
        dispatch(addInternalContamination({
            calculatorId: calculatorId,
            values: {
                causative_agents: selectedItems?.length > 0 ? selectedItems : form?.getFieldsValue()?.fito_calculator?.internal_pathogen_ids
            }
        }));

        // setTimeout(() => {
        //     dispatch(updateCalculatorPhyto({
        //         calcId: calculatorId,
        //         values: {
        //             seeds_number: form?.getFieldsValue()?.fito_calculator?.seeds_number
        //         }
        //     }));
        // }, 300);
    };

    const handleCalcFinish = () => {
        const formVals = form?.getFieldsValue();
        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
        dispatch(updateRegistryPhyto({
            calcId: match?.params?.id,
            values: {
                internal_pathogen_comment: formVals?.internal_pathogen_comment
            }
        }));

        const diseasesObj = {};
        const formPathogenKeys = Object.keys(formVals)?.filter(x => x?.startsWith('internal-infection/'));

        formPathogenKeys?.forEach(pathogenKey => {
            const pathId = pathogenKey?.split('/')[1];
            if (!diseasesObj[pathId]) {
                diseasesObj[pathId] = {
                    seed_infection: formVals[pathogenKey]
                };
            } else {
                diseasesObj[pathId].seed_infection = formVals[pathogenKey];
            }
        });

        curProto?.fito_calculator?.internal_pathogens?.forEach(pathogen => {
            diseasesObj[pathogen?.id].drugs = pathogen?.drugs?.map(x => x?.id);
        });

        formPathogenKeys?.forEach(pathogenIdKey => {
            dispatch(updateInternalContamination({
                pathogenId: pathogenIdKey?.split('/')[1],
                values: diseasesObj[pathogenIdKey?.split('/')[1]]
            }));
            setTimeout(() => {
                dispatch(getProtocolPhyto(match?.params?.id));
            }, 300);
        });
    };

    const handleAddDrugs = () => {
        dispatch(updateInternalContamination({
            pathogenId: parseInt(activeDrugTab, 10),
            values: {
                drugs: selectedDrugList
            }
        }));

        dispatch(getProtocolPhyto(match?.params?.id));

        setTimeout(() => setShowDrugModal(false), 300);
    };

    const openDrugSelector = () => {
        setShowDrugModal(true);
    };

    const updateTableValues = () => {
        const diseasesObj = {};
        const formVals = form?.getFieldsValue();

        const formPathogenKeys = Object.keys(formVals)?.filter(x => x?.startsWith('internal-infection/'));

        formPathogenKeys?.forEach(pathogenKey => {
            const pathId = pathogenKey?.split('/')[1];
            // const pathPcs = formVals[pcsKey];
            if (!diseasesObj[pathId]) {
                diseasesObj[pathId] = {
                    seed_infection: formVals[pathogenKey]
                };
            } else {
                diseasesObj[pathId].seed_infection = formVals[pathogenKey];
            }
        });

        curProto?.fito_calculator?.internal_pathogens?.forEach(pathogen => {
            diseasesObj[pathogen?.id].drugs = pathogen?.drugs?.map(x => x?.id);
        });

        formPathogenKeys?.forEach(pathogenIdKey => {
            dispatch(updateInternalContamination({
                pathogenId: pathogenIdKey?.split('/')[1],
                values: diseasesObj[pathogenIdKey?.split('/')[1]]
            }));
            setTimeout(() => {
                dispatch(getProtocolPhyto(match?.params?.id));
            }, 300);
        });
    };

    const clearFormFields = () => {
        const formValues = form?.getFieldsValue();
        const { fito_calculator } = formValues;
        if (fito_calculator) {
            const { internal_pathogen_ids } = fito_calculator;
            if (internal_pathogen_ids?.length > 0) {
                internal_pathogen_ids?.forEach(pathId => {
                    dispatch(removeInternalContamination({
                        pathogenId: pathId
                    }));
                });

                setTimeout(() => {
                    dispatch(getProtocolPhyto(match?.params?.id));
                }, 500);
            }
        }
        // form.setFieldsValue({
        //     fito_calculator: {
        //         // seeds_number: undefined,
        //         internal_pathogen_ids: []
        //     }
        // });
    };

    return (
        <>
            {/* <Row className="mt-25">
                <Col span={12}>
                    <Form.Item
                        rules={[{ required: true }]}
                        label={t('internal input label')}
                        name={['fito_calculator', 'seeds_number']}>
                        <InputNumber
                            decimalSeparator=","
                            className="w-100"
                            placeholder={t('internal input placeholder')} />
                    </Form.Item>
                </Col>
            </Row> */}
            <Row className="mt-25">
                <Col span={12}>
                    <Form.Item
                        label={t('internal select label')}
                        name={['fito_calculator', 'internal_pathogen_ids']}>
                        <Select
                            options={agentsList}
                            allowClear
                            placeholder={t('internal select placeholder')}
                            className="w-100"
                            onChange={setSelectedItems}
                            mode="multiple" />
                    </Form.Item>
                </Col>
            </Row>
            
            <Row>
                <Col span={2}>
                    <Button
                        type="primary"
                        className="mt-25 mb-25"
                        onClick={() => handleCreateInternal()}>
                        {t('button text select')}
                    </Button>
                </Col>
                <Col span={1} />
                <Col span={2}>
                    <Button
                        type="secondary"
                        className="mt-25 mb-25"
                        onClick={clearFormFields}>
                        {t('clear')}
                    </Button>
                </Col>
            </Row>

            {pathogens?.length > 0 && (
                <Collapse
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white', marginBottom: '0' }}
                    bordered={false}
                    defaultActiveKey={['external_probability']}
                    className={`${cn('collapse')} remove-padding`}>
                    <Panel
                        header={<b>{t('calculator external collapse header')}</b>}
                        key="external_probability">
                        <div className="pathogens-table">

                            <Row className="pathogens-table__row__header">
                                <Col className="pathogens-table__row__text__header" span={8}>
                                    {t('internal probability header reason')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={8}>
                                    {t('internal probability header harmful')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={8}>
                                    {t('internal probability header percent')}
                                </Col>
                            </Row>

                            {pathogens.map(selectedPathogen => (
                                <>
                                    <Row className="pathogens-table__row">
                                        <Col className="pathogens-table__row__text" span={8}>
                                            {selectedPathogen?.causative_agent?.name}
                                        </Col>
                                        <Col className="pathogens-table__row__text__highlighted" span={8}>
                                            <div onClick={() => showDiseaseModal(selectedPathogen?.disease)}>
                                                {selectedPathogen?.disease?.name}
                                            </div>
                                        </Col>
                                        <Col className="pathogens-table__row__text__columned" span={8}>
                                            <Form.Item
                                                name={`internal-infection/${selectedPathogen?.id}`}>
                                                <InputNumber
                                                    min={0} max={100}
                                                    step={0.1} precision={2}
                                                    decimalSeparator=","
                                                    defaultValue={selectedPathogen?.seed_infection ? selectedPathogen?.seed_infection : 0} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ))}

                            {showRecalculateButtons && (
                                <>
                                    <hr />
                                    <Row justify="center">
                                        <Col span={19} />
                                        <Col align="start" span={5}>
                                            <a style={{ color: 'black' }} onClick={updateTableValues}>
                                                {t('recalculate values')}
                                            </a>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </Panel>
                </Collapse>
            )}

            {pathogens?.length > 0 && (
                <Collapse
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white', padding: '0', marginTop: '15px' }}
                    bordered={false}
                    defaultActiveKey={['drug_selection']}
                    className={`${cn('collapse')} remove-padding`}>
                    <Panel
                        header={<b>{t('calculator external collapse header drug_selection')}</b>}
                        key="drug_selection">
                        <div className="drug-cards">
                            {pathogens?.map(drugOption => (
                                <div className="drug-card">
                                    <div className="drug-card__header">
                                        {drugOption?.disease?.name}
                                    </div>
                                    <div className="drug-card__list">
                                        <ul>
                                            {drugOption?.drugs?.map(dr => (
                                                <li>
                                                    {dr?.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            )}

            <Row style={{ marginTop: '15px' }}>
                <Form.Item name="internal_pathogen_comment">
                    <Input.TextArea />
                </Form.Item>
            </Row>

            {match?.params?.id && (
                <Row className="upload-file" style={{ marginTop: '15px' }}>
                    <Col>
                        <div className="upload-file__header">
                            {t('upload file header')}
                        </div>
                        <Upload {...uploadProps} className="upload-file__upload">
                            <Button icon={<UploadOutlined />} type="secondary">
                                {t('upload file button')}
                            </Button>
                        </Upload>
                        <div className="upload-file__subtext">
                            {t('upload file subtext 1')}
                        </div>
                        <div className="upload-file__subtext">
                            {t('upload file subtext 2')}
                        </div>
                    </Col>
                </Row>
            )}

            {images?.length > 0 && (
                <Table dataSource={images} columns={imagesColumns} />
            )}

            <Row className="mt-25" gutter={12}>
                <Col span={6} />
                <Col span={6}>
                    <Button className="w-100" onClick={openDrugSelector}>{t('external bottom button goto')}</Button>
                </Col>
                <Col span={6}>
                    <Button className="w-100" onClick={handleCalcFinish}>{t('external bottom button finish')}</Button>
                </Col>
                <Col span={6} />
            </Row>

            <Modal
                width={1000}
                visible={showDrugModal}
                title={t('drug modal title')}
                onOk={handleAddDrugs}
                onCancel={() => {
                    setShowDrugModal(false);
                }}
                footer={[
                    <Button
                        type="secondary"
                        key="back"
                        onClick={() => {
                            setShowDrugModal(false);
                        }}>
                            {t('agent modal cancel')}
                    </Button>,
                    <Button
                        key="link"
                        type="primary"
                        onClick={handleAddDrugs}>
                            {t('agent modal ok')}
                    </Button>
                ]}>

                    <Tabs defaultActiveKey={pathogens?.length > 0 ? pathogens[0].id : 1} onChange={setActiveDrugTab}>
                        {pathogens?.map(pathogenInstance => (
                            <Tabs.TabPane tab={pathogenInstance?.disease?.name} key={pathogenInstance?.id}>
                                <Select
                                    defaultValue={pathogenInstance?.drugs?.map(x => x.id)}
                                    mode="multiple"
                                    onChange={setSelectedDrugList}
                                    options={fertilizersFullList.map(x => {
                                        return {
                                            label: x?.name || '',
                                            value: x?.id
                                        };
                                    })?.reduce((accumulator, current) => {
                                        let exists = accumulator.find(item => {
                                          return item.label === current.label;
                                        });
                                        if(!exists) { 
                                          accumulator = accumulator.concat(current);
                                        }
                                        return accumulator;
                                      }, [])}
                                    style={{ width: '100%' }} />
                            </Tabs.TabPane>
                        ))}
                    </Tabs>

            </Modal>

            <InfoModal
                inspectableItem={currentDisease}
                downloadPDF={downloadDiseasePDF}
                closeModal={() => setIsModalOpen(false)}
                isOpened={isModalOpen} />
        </>
    );
};

export default InternalContamination;