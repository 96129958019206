import { getConstant } from '../utils';

export const getRequests = getConstant('GET_REQUESTS');
export const getRequest = getConstant('GET_REQUEST');
export const clearRequest = getConstant('CLEAR_REQUEST');
export const addRequest = getConstant('ADD_REQUEST');
export const updateRequest = getConstant('UPDATE_REQUEST');
export const removeRequest = getConstant('REMOVE_REQUEST');
export const setStatusRequest = getConstant('SET_STATUS_REQUEST');
export const saveAndSetStatusRequest = getConstant('SAVE_AND_SET_STATUS_REQUEST');

export const uploadTaskFile = getConstant('UPLOAD_TASK_FILE');
export const getTasks = getConstant('GET_TASKS');
export const getTask = getConstant('GET_TASK');
export const addTask = getConstant('ADD_TASK');
export const removeTask = getConstant('REMOVE_TASK');
