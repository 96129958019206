import { combineReducers } from 'redux';

import diseases from './diseases';
import disease from './disease';
import loading from './loading';
import causativeAgent from './causative-agent';
import cultures from './cultures';
import damageArea from './damage-area';
import subgroup from './subgroup';
import group from './group';

export default combineReducers({
    diseases,
    disease,
    causativeAgent,
    cultures,
    damageArea,
    subgroup,
    group,
    loading
});
