import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getFertilizers = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getFertilizer = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});


export const getFertilizersGroup = filter => request.get({
    url: urlPath(options.apiGroupUrl, filter)
});

export const getFertilizersGroupItem = id => request.get({
    url: urlPath(options.apiGroupUrl, `${id}/`)
});


export const getFertilizersMainDrug = filter => request.get({
    url: urlPath(options.apiMainDrugUrl, filter)
});

export const getFertilizersMainDrugItem = id => request.get({
    url: urlPath(options.apiMainDrugUrl, `${id}/`)
});


export const getFertilizersMainDrugSynonyms = filter => request.get({
    url: urlPath(options.apiMainDrugSynonymsUrl, filter)
});

export const getFertilizersMainDrugSynonymsItem = id => request.get({
    url: urlPath(options.apiMainDrugSynonymsUrl, `${id}/`)
});


export const getFertilizersReleaseForm = filter => request.get({
    url: urlPath(options.apiReleaseFormUrl, filter)
});

export const getFertilizersReleaseFormItem = id => request.get({
    url: urlPath(options.apiReleaseFormUrl, `${id}/`)
});


export const getFertilizersSubGroup = filter => request.get({
    url: urlPath(options.apiSubGroupUrl, filter)
});

export const getFertilizersSubGroupItem = id => request.get({
    url: urlPath(options.apiSubGroupUrl, `${id}/`)
});


export const getFertilizersToMainDrug = filter => request.get({
    url: urlPath(options.apiToMainDrugUrl, filter)
});

export const getFertilizersToMainDrugItem = id => request.get({
    url: urlPath(options.apiToMainDrugUrl, `${id}/`)
});


export const getFertilizersToRegistration = filter => request.get({
    url: urlPath(options.apiToRegistrationUrl, filter)
});

export const getFertilizersToRegistrationItem = id => request.get({
    url: urlPath(options.apiToRegistrationUrl, `${id}/`)
});


export const getFertilizersToSeller = filter => request.get({
    url: urlPath(options.apiToSellerUrl, filter)
});

export const getFertilizersToSellerItem = id => request.get({
    url: urlPath(options.apiToSellerUrl, `${id}/`)
});


export const getFertilizersType = filter => request.get({
    url: urlPath(options.apiTypeUrl, filter)
});

export const getFertilizersTypeItem = id => request.get({
    url: urlPath(options.apiTypeUrl, `${id}/`)
});
