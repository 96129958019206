import * as constants from './constants';

export const getCalculate = payload => ({
    type: constants.getCalculate.toString(),
    payload
});

export const getCalculates = payload => ({
    type: constants.getCalculates.toString(),
    payload
});

export const updateCalculate = payload => ({
    type: constants.updateCalculate.toString(),
    payload
});

export const removeCalculate = payload => ({
    type: constants.removeCalculate.toString(),
    payload
});

export const addCalculate = payload => ({
    type: constants.addCalculate.toString(),
    payload
});

export const clearCalculate = payload => ({
    type: constants.clearCalculate.toString()
});
