import * as constants from '../constants';

const initialState = {
    id: 0,
    subgroup_id: '',
    subgroup_name: '',
    group_id: 0,
    group_name: '',
    release_form_id: 0,
    release_form_name: '',
    soil_type_id: 0,
    soil_type_id_name: '',
    name: '',
    economy_type_s: false,
    economy_type_l: false,
    presowing_application_rate: '',
    sowing_application_rate: '',
    basic_application_rate: '',
    top_dressing_application_rate_open: '',
    top_dressing_application_rate_close: '',
    leaf_dressing: '',
    content_n: 0.0,
    content_p: 0.0,
    content_k: 0.0,
    content_ca: 0.0,
    content_mg: 0.0,
    content_s: 0.0,
    content_fe: 0.0,
    content_ag: 0.0,
    content_b: 0.0,
    content_mo: 0.0,
    content_mn: 0.0,
    content_cu: 0.0,
    content_zn: 0.0,
    content_co: 0.0,
    content_i: 0.0,
    content_v: 0.0,
    is_in_state_registry: false,
    culture_description: '',
    main_drug: '',
    registration: '',
    seller: [],
    synonyms: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getFertilizer.success: {
            return action.payload;
        }

        case constants.clearFertilizer.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
