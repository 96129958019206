import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorPhyto.success:
        case constants.getCalculatorsPhyto.success: {
            return false;
        }

        case constants.getCalculatorPhyto.try:
        case constants.getCalculatorPhyto.error:
        case constants.getCalculatorsPhyto.try:
        case constants.getCalculatorsPhyto.error: {
            return true;
        }

        default:
            return state;
    }
}