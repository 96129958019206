import { combineReducers } from 'redux';

import request from './request';
import requests from './requests';
import loading from './loading';

export default combineReducers({
    requests,
    request,
    loading
});
