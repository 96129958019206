import { getConstant } from '../utils';

export const getMycologicalRegistry = getConstant('GET_MYCOLOGICAL_REGISTRY');
export const getMycologicalRegistries = getConstant('GET_MYCOLOGICAL_REGISTRIES');
export const clearMycologicalRegistry = getConstant('CLEAR_MYCOLOGICAL_REGISTRY');
export const updateMycologicalRegistry = getConstant('UPDATE_MYCOLOGICAL_REGISTRY');
export const addMycologicalRegistry = getConstant('ADD_IMYCOLOGICAL_REGISTRY');
export const generateMycologicalRegistryDoc = getConstant('GENERATE_MYCOLOGICAL_REGISTRY_DOC');
export const getUsersList = getConstant('GET_USERS_LIST');
export const getFilteredFields = getConstant('GET_FILTERED_FIELDS');
export const uploadRegistryFile = getConstant('UPLOAD_REGISTRY_FILE');
export const updateFileDescription = getConstant('UPDATE_FILE_DESCRIPTION');
export const deleteMycologicalRegistry = getConstant('DELETE_MYCOLOGICAL_REGISTRY');
export const publishMycologicalRegistry = getConstant('PUBLISH_MYCOLOGICAL_REGISTRY');