import * as constants from './constants';

export const getUsers = body => ({
    type: constants.getUsers.toString(),
    payload: body
});

export const resetPassword = email => ({
    type: constants.resetPassword.toString(),
    payload: email
});

export const savePassword = body => ({
    type: constants.savePassword.toString(),
    payload: body
});
