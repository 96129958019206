import ConverterClass from '../../utils/converter';

const example = {
    id: 2,
    preceding_culture: { id: 1, name: 'Пшеница' },
    culture: { id: 1, name: 'Пшеница' },
    name: 'Тестовый калькулятор',
    creation_date: '2021-04-21T06:01:41.367959Z',
    is_done: false,
    sampling_coordinates: { type: 'Point', coordinates: [12.0, 23.0] },
    probe_number: 12,
    cultures_comment: 'cultures_match',
    interpretation: {
        neutral: {
            koe: 2.0,
            percentage: 0.2857142857142857,
            micromycete: [
                { koe: 2.0, ref: 24, percentage: 0.2857142857142857 }] },
        total_koe: 7.0,
        conclusion: 'phytopathogenic__very_low-suppressive__very_low',
        suppressive: {
            koe: 3.0, percentage: 0.42857142857142855, micromycete: [{ koe: 3.0, ref: 14, percentage: 0.42857142857142855 }], interpretation: 'very_low'
        },
        phytopathogenic: {
            koe: 2.0,
            percentage: 0.2857142857142857,
            micromycete: [{
                koe: 2.0, ref: 2, losses:
                    0.002857142857142857, percentage: 0.2857142857142857
            }],
            interpretation: 'very_low',
            total_percentage: null
        },
        total_percentage: 0.9999999999999999
    },
    user: 6
};

class Converter extends ConverterClass {
    _server2Client = (item = {}) => ({
        id: item.id,
        preceding_culture: item.preceding_culture || {},
        culture: item.culture || {},
        name: item.name,
        creation_date: item.creation_date,
        is_done: item.is_done,
        sampling_coordinates: item.sampling_coordinates || { type: 'Point', coordinates: [] },
        probe_number: item.probe_number,
        cultures_comment: item.cultures_comment,
        interpretation: this.interpretation2Client(item.interpretation || {}),
        user: 1
    });

    interpretation2Client = (item = {}) => ({
        neutral: this.micromycete2Client(item.neutral || {}),
        total_koe: item.total_koe,
        conclusion: item.conclusion,
        suppressive: this.micromycete2Client(item.suppressive || {}),
        phytopathogenic: this.micromycete2Client(item.phytopathogenic || {}),
        total_percentage: item.total_percentage || 0

    });

    micromycete2Client = (item = {}) => (
        {
            koe: item.koe,
            percentage: item.percentage || 0,
            micromycete: item.micromycete || [],
            interpretation: item.interpretation
        }
    )
}

export default new Converter();
