import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Layout,
    Row,
    Select,
    Modal,
    Input,
    message
} from 'antd';
import { DeleteOutlined, PlusCircleOutlined, FilterOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from '../../utils/cn';

import * as XLSX from 'xlsx';

import {
    PanelLayout, Title
} from '../../components';

import RegistriesTable from './components/registries-table';
import { curNewInitialProtocol } from '../../models/fed-registry/selectors';

import './style.less';

import { getUser } from '../../models/account/selectors';
import { getCalculatorsMordovia, getFieldYears } from '../../models/mordovia/actions';
import { getCalculatorsMordoviaObject } from '../../models/mordovia/selectors';
import moment from 'moment';
import { getUsersList } from '../../models/sample-log/actions';
import { usrListResponse } from '../../models/sample-log/selectors';
import { makeReportGeneral, makeReportNorm, makeReportVolume } from '../../models/recommendation-registries/actions';
import { currentReportGeneral, currentReportNorm, currentReportVolume } from '../../models/recommendation-registries/selectors';

const { PanelHeader, PanelContent } = PanelLayout;

const yearOptions = [];
const currentYear = moment().year();
let yearCounter = currentYear;
const startYear = currentYear - 10;

while (yearCounter >= startYear) {
    yearOptions.push(yearCounter);
    yearCounter--;
};

const RecommendationRegistries = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const { t } = useTranslation('recommendation registries');

    const usersNew = useSelector(state => usrListResponse(state));
    const generalReport = useSelector(state => currentReportGeneral(state));
    const normReport = useSelector(state => currentReportNorm(state));
    const volumeReport = useSelector(state => currentReportVolume(state));

    const [triedGeneration, setTriedGeneration] = useState(false);
    const [fieldsPage, setFieldsPage] = useState(1);
    const [fieldsPageSize, setFieldsPageSize] = useState(10);
    const [usersPage, setUsersPage] = useState(1);
    const [fullUsersList, setFullUsersList] = useState([]);
    const [userIdNameMapping, setUserIdNameMapping] = useState({});
    const [selectedFieldYear, setSelectedFieldYear] = useState(null);
    const [selectedFieldOwner, setSelectedFieldOwner] = useState([]);
    const [selectedFieldNum, setSelectedFieldNum] = useState(null);
    const [selectedFieldCulture, setSelectedFieldCulture] = useState(null);
    const [showYearModal, setShowYearModal] = useState(false);
    const [fieldYears, setFieldYears] = useState([]);
    const [specifiedYear, setSpecifiedYear] = useState(null);
    const [selectedVega, setSelectedVega] = useState(null);
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
        const appendableObj = {};
        fullUsersList?.forEach(user => {
            const { id, name } = user;
            appendableObj[id] = name;
        });
        setUserIdNameMapping({
            ...userIdNameMapping,
            ...appendableObj
        });
    }, [fullUsersList]);

    useEffect(() => {
        const newUsersPortion = usersNew?.results;

        if (newUsersPortion?.length > 0) {
            const makeSet = new Set([...fullUsersList, ...newUsersPortion]);
            const resArray = Array.from(makeSet);
            setFullUsersList(resArray);
        }

        const isNext = usersNew?.next?.length > 0;

        if (isNext) {
            setUsersPage(usersPage + 1);
        }
    }, [usersNew]);
    
    useEffect(() => {
        const reqBody = {
            page: usersPage
        };
        
        dispatch(getUsersList(reqBody));
    }, [usersPage]);
    

    const handleGetFields = () => {
        // const urlArr = selectedFieldOwner?.length > 0 ? selectedFieldOwner?.length === 1 ? [`"field_info.owner_user.id":${selectedFieldOwner}`] : [`"field_info.owner_user.id":[${selectedFieldOwner?.join(',')}]`] : [];
        const urlArr = [];
        
        // selectedFieldNum?.length > 0 ? urlArr.push(`"field_info.field_num.icontains":"${selectedFieldNum}"`) : '';
        
        selectedFieldCulture?.length > 0 ? urlArr.push(`"culture.name.icontains":"${selectedFieldCulture}"`) : '';
        selectedFieldYear ? urlArr.push(`"year": [${selectedFieldYear}]`) : '';

        const page_size_url = `page_size=${fieldsPageSize}`;
        const page_url = `page=${fieldsPage}`;

        dispatch(getFieldYears(`?${page_size_url}&${page_url}${urlArr?.length > 0 ? `&filter={${urlArr.join(',')}}` : ''}${(selectedFieldOwner && selectedFieldOwner > 0) ? `&owner=${userIdNameMapping[selectedFieldOwner]}` : ''}${selectedFieldNum?.length > 0 ? `&field_num=${selectedFieldNum}` : ''}`));
    };

    useEffect(() => {
        handleGetFields();
    }, [fieldsPage]);

    useEffect(() => {
        handleGetFields();
    }, [fieldsPageSize]);

    useEffect(() => {
        // console.log('SELECTED', selectedFieldCulture);
        if (fieldsPage === 1) {
            handleGetFields();
        } else {
            setFieldsPage(1);
        }
    }, [
        selectedFieldYear, selectedFieldNum,
        selectedFieldCulture, selectedFieldOwner
    ]);
    
    // const handlePageChange = (newPage, newPageSize) => {
    //     setFieldsPage(newPage);
    //     if (newPageSize !== fieldsPageSize) {
    //         setFieldsPageSize(newPageSize);
    //     }
    // };

    const handleClearFilters = () => {
        setSelectedFieldYear(null);
        setSelectedFieldNum(null);
        setSelectedFieldCulture(null);
        setSelectedFieldOwner([]);
    };

    const handleGenerateReport = (type, params = {}) => {
        setTriedGeneration(true);

        if (type === 'general') {
            dispatch(makeReportGeneral({
                vegaKey: params?.vega_key,
                fieldYear: params?.year
            }));
        }

        if (type === 'volume') {
            dispatch(makeReportVolume({
                values: {
                    field_years: params?.years
                }
            }));
        }

        if (type === 'norm') {
            dispatch(makeReportNorm({
                values: {
                    field_years: params?.years
                }
            }));
        }
    }

    const generateExcel = (type, reportData) => {
        const normHeader = {
            field: 'Номер поля',
            fertilizer: 'Название препарата',
            weight: 'Средняя норма внесения препарата, (кг(л)/га)'
        };

        const volumeHeader = {
            name: 'Название препарата',
            fields: 'Номер поля',
            weight: 'Физ. вес (кг(л)/га) всего',
            price: 'Стоимость за единицу',
            total_price: 'Итого'
        };

        const generalHeader = {
            name: 'Название препарата',
            vega_key: 'Номер поля',
            points: 'Точки',
            zones: 'Зоны продуктивности',
            area: 'Площадь точки',
            main_norm: 'норм физ. веса препарата (кг(л)/га) Основной',
            sowing_norm: 'норм физ. веса препарата (кг(л)/га) Посевной',
            total_norm: 'Общая норма препарата на площадь поля, кг',
            price: 'Цена за препарат, руб./кг(л)',
            total_price: 'Затраты по препарату на площадь поля, руб.',
        };
        
        if (type === 'general') {
            const generalData = reportData;
            const generalRows = [];
            generalData?.forEach(generalEntity => {
                generalEntity?.calculator?.distribution?.related_fertilizers?.forEach(fert => {
                    const insertableObject = {
                        vega_key: generalEntity?.vega_key,
                        name: fert?.fertilizer?.name,
                        points: generalEntity?.calculator?.points?.join(', '),
                        zones: generalEntity?.calculator?.zones?.join(', '),
                        area: '-',
                        main_norm: fert?.main,
                        sowing_norm: fert?.sowing,
                        ...fert?.feedings,
                        total_norm: fert?.total,
                        price: fert?.price,
                        total_price: fert?.total_price,
                    };
                    generalRows.push(insertableObject);
                });
            });
            const finalRows = generalRows.map(rw => Object.fromEntries(Object.entries(rw).map(entry => {
                if (!entry[0]?.toLowerCase()?.startsWith('подкормка')) {
                    entry[0] = generalHeader[entry[0]];
                }
                return entry;
            })));
            const worksheet = XLSX.utils.json_to_sheet(finalRows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "General report");
            XLSX.writeFile(workbook, "General_report.xlsx", { compression: true });
        }

        if (type === 'volume') {
            const volumeRows = reportData?.fertilizers?.map(fert => Object.fromEntries(Object.entries(fert).filter(x => x[0] !== 'id').map(entry => {
                if (entry[1] === 'high') {
                    entry[1] = 'Высокая';
                }
                if (entry[1] === 'middle') {
                    entry[1] = 'Средняя';
                }
                if (entry[1] === 'low') {
                    entry[1] = 'Низкая';
                }
                entry[0] = volumeHeader[entry[0]];
                return entry;
            })));
            const worksheet = XLSX.utils.json_to_sheet(volumeRows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Volume report");
            XLSX.writeFile(workbook, "Volume_report.xlsx", { compression: true });
        }

        if (type === 'norm') {
            const normRows = reportData?.fertilizers?.map(fert => Object.fromEntries(Object.entries(fert).map(entry => {
                entry[0] = normHeader[entry[0]];
                return entry;
            })));
            const worksheet = XLSX.utils.json_to_sheet(normRows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Norm report");
            XLSX.writeFile(workbook, "Norm_report.xlsx", { compression: true });
        }
    };

    useEffect(() => {
        if (generalReport?.results?.length > 0) {
            generateExcel('general', generalReport?.results);
        } else if (triedGeneration) {
            message.error('Недостаточно данных');
        }
    }, [generalReport]);

    useEffect(() => {
        if (normReport?.fertilizers?.length > 0) {
            generateExcel('norm', normReport);
        } else if (triedGeneration) {
            message.error('Недостаточно данных');
        }
    }, [normReport]);

    useEffect(() => {
        if (volumeReport?.fertilizers?.length > 0) {
            generateExcel('volume', volumeReport);
        } else if (triedGeneration) {
            message.error('Недостаточно данных');
        }
    }, [volumeReport]);
    

    return (
        <Layout className={cn()} style={{ minHeight: '100vh' }}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    {showFilter && (
                        <Row className="controls-row">
                            <Col span={4}>
                                {/* owner */}
                                <Select
                                    maxTagCount='responsive'
                                    style={{ maxHeight: '100%' }}
                                    // mode='multiple'
                                    placeholder={t('filter placeholder owner')}
                                    showSearch
                                    filterOption={(inputValue, option) =>
                                        option?.label?.toLowerCase().includes(inputValue?.toLowerCase())}
                                    value={selectedFieldOwner}
                                    onChange={setSelectedFieldOwner}
                                    options={fullUsersList?.map(x => {
                                        return {
                                            label: x?.name,
                                            value: x?.id
                                        };
                                    })} />
                            </Col>

                            <Col span={1} />

                            <Col span={4}>
                                <Select
                                    placeholder={t('filter placeholder year')}
                                    showSearch
                                    filterOption={(inputValue, option) =>
                                        option?.label?.toString()?.toLowerCase().includes(inputValue?.toLowerCase())}
                                    value={selectedFieldYear}
                                    onChange={setSelectedFieldYear}
                                    options={yearOptions.map(yearOption => {
                                        return {
                                            label: yearOption,
                                            value: yearOption
                                        }
                                    })} />
                            </Col>

                            <Col span={1} />

                            <Col span={4}>
                                {/* field num */}
                                <Input
                                    placeholder={t('filter placeholder num')}
                                    value={selectedFieldNum}
                                    onInput={(evt) => {
                                        console.log('PROVERKA EVENTA', evt?.target?.value);
                                        setSelectedFieldNum(evt?.target?.value);
                                    }} />
                            </Col>

                            <Col span={1} />

                            <Col span={4}>
                                {/* culture */}
                                <Input
                                    placeholder={t('filter placeholder culture')}
                                    value={selectedFieldCulture}
                                    onChange={evt => setSelectedFieldCulture(evt?.target?.value)} />
                            </Col>

                            <Col span={1} />

                            {/* {(selectedFieldYear || selectedFieldCulture || selectedFieldNum?.length > 0 || selectedFieldOwner?.length > 0) && (
                                <Col span={4}>
                                    <Button
                                        onClick={handleClearFilters}
                                        type="secondary"
                                        style={{ width: '100%' }}>
                                        {t('clear filters')}
                                    </Button>
                                </Col>
                            )} */}
                        </Row>
                    )}
                    <Row gutter={24} style={{ marginBottom: '15px' }}>
                        <Col span={8}>
                            <Button style={{ width: '100%' }} type="secondary" size="medium" onClick={() => setShowFilter(!showFilter)}>
                                <FilterOutlined />
                                {showFilter ? t('unshow filters') : t('show filters')}
                            </Button>
                        </Col>
                        {showFilter && (
                            <Col span={4}>
                                <Button style={{ width: '100%' }} onClick={handleClearFilters}>{t('clear')}</Button>
                            </Col>
                        )}
                    </Row>

                    <Modal
                        title={t('year modal title')}
                        visible={showYearModal}
                        onOk={() => {
                            history.push(`/representation/${specifiedYear}/${selectedVega}`);
                            // history.push(`/mordovia/${specifiedYear}/${selectedVega}`);
                            // setTimeout(() => window.location.reload(), 200);
                            setShowYearModal(false);
                        }}
                        onCancel={() => {
                            setSelectedVega(null);
                            setSpecifiedYear(null);
                            setFieldYears([]);
                            setShowYearModal(false);
                        }}>
                        <Select
                            onChange={setSpecifiedYear}
                            options={fieldYears.map(year => {
                                return {
                                    label: year,
                                    value: year
                                };
                            })} />
                    </Modal>
                    {/* <Row style={{ height: '100%' }}> */}
                        <RegistriesTable
                            getFields={handleGetFields}
                            generateReport={handleGenerateReport}
                            userNameMapping={userIdNameMapping}
                            page={fieldsPage}
                            setPage={setFieldsPage}
                            pageSize={fieldsPageSize}
                            setPageSize={setFieldsPageSize} />
                    {/* </Row> */}
                    {/* <RegistriesTable
                        getFields={handleGetFields}
                        generateReport={handleGenerateReport}
                        userNameMapping={userIdNameMapping}
                        page={fieldsPage}
                        setPage={setFieldsPage}
                        pageSize={fieldsPageSize}
                        setPageSize={setFieldsPageSize} /> */}
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default RecommendationRegistries;