import React, { Component, useState } from 'react';
import {
    MapContainer,
    TileLayer,
    LayersControl,
    LayerGroup,
    WMSTileLayer
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import { CustomWMSLayer } from '../../components/layers/custom-wms';

const center = [54.8883856, 52.3157811];

export default (props) => {
    const { getData } = props;
    const { t } = useTranslation('map');
    const cn = useClassName('page');

    return (
        <MapContainer center={center} zoom={12} scrollWheelZoom={false}>
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                {/* <LayersControl.Overlay name={t('2018_2020')}>
                    <LayerGroup>
                        <WMSTileLayer url="/geoserver/teleagronom/wms?"
                                      params={{
                                          layers: 'teleagronom:2018_2020',
                                          format: 'image/png',
                                          transparent: true
                                      }} />
                    </LayerGroup>
                </LayersControl.Overlay> */}
                <LayersControl.Overlay name={t('MERGED_Uklon(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Uklon(1)"
                            legend_url="teleagronom:Uklon"
                            layer="teleagronom:MERGED_Uklon(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_Ekspoziciya(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Ekspoziciya(1)"
                            legend_url="teleagronom:Exposition"
                            layer="teleagronom:MERGED_Ekspoziciya(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_Visota(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_Visota(1)"
                            legend_url="teleagronom:relief"
                            layer="teleagronom:MERGED_Visota(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_Pochvennij_sloj_Total')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_Pochvennij_sloj_Total"
                            layer="teleagronom:RT_Pochvennij_sloj_Total"
                            attributes={['Name']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2018')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2018"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2018"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2019')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2019"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2019"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('RT_analitica_po_maske_rasp_2020')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="RT_analitica_po_maske_rasp_2020"
                            legend_url="Sxema_raspashki"
                            layer="teleagronom:RT_analitica_po_maske_rasp_2020"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Zalesenie')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Zalesenie"
                            legend_url="teleagronom:Zalesenie"
                            layer="teleagronom:Zalesenie"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('rt_zones_2018(1)')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="rt_zones_2018(1)"
                            layer="teleagronom:rt_zones_2018(1)"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('vvod_v_oborot')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="vvod_v_oborot"
                            legend_url="teleagronom:vvod_v_oborot"
                            layer="teleagronom:vvod_v_oborot"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Plowed_agro')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Plowed_agro"
                            legend_url="teleagronom:Zalesenie"
                            layer="teleagronom:Plowed_agro"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('istory_district')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="istory_district"
                            layer="teleagronom:istory_district"
                            attributes={['District', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('MERGED_pereregistr_RT_s_rayonami')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="MERGED_pereregistr_RT_s_rayonami"
                            legend_url="teleagronom:vvod_v_oborot"
                            layer="teleagronom:MERGED_pereregistr_RT_s_rayonami"
                            attributes={['Cad_Number', 'S ga', 'Cad_EZ', 'District', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('vvod_v_oborot%20bez%20cad')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="vvod_v_oborot%20bez%20cad"
                            layer="teleagronom:vvod_v_oborot%20bez%20cad"
                            attributes={['Cad_Number', 'S ga', 'Cad_EZ', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Necelevie%20na%20sx')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Necelevie%20na%20sx"
                            layer="teleagronom:Necelevie%20na%20sx"
                            legend_url="teleagronom:vvod_v_oborot"
                            attributes={['Cad_Number', 'S ga', 'Cad_EZ', '1985', '2016', '2017', '2018', '2019']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('tatar')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="tatar"
                            layer="teleagronom:tatar"
                            attributes={[t('plat_1')]}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('30km_mete_agro_fields')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="30km_mete_agro_fields"
                            layer="teleagronom:30km_mete_agro_fields"
                            attributes={['S ga']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="NMDI">
                    <LayerGroup>
                        <CustomWMSLayer
                            name="NMDI"
                            checked
                            legend_url="ndvi_ndmi_base"
                            layer="teleagronom:ndmi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="NDVI">
                    <LayerGroup>
                        <CustomWMSLayer
                            name="NDVI"
                            checked
                            legend_url="ndvi_ndmi_base"
                            layer="teleagronom:ndvi"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name={t('schema')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            getData={getData}
                            name="schema"
                            checked
                            layer="teleagronom:agricultural_field_agriculturalfield"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Almetevskij_delenie')}>
                    <LayerGroup>
                        <WMSTileLayer url="/geoserver/teleagronom/wms?"
                            params={{
                                layers: 'teleagronom:Almetevskij_delenie',
                                format: 'image/png',
                                transparent: true
                            }} />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('ETALON_RT_precip')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="ETALON_RT_precip"
                            layer="teleagronom:ETALON_RT_precip"
                            legend_url="teleagronom:Precipitation"
                            attributes={['District', 'S ga', 'precmean', '_midTempme']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Almetevskij_zemli_trebuushie_pereregistracii')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Almetevskij_zemli_trebuushie_pereregistracii"
                            layer="teleagronom:Almetevskij_zemli_trebuushie_pereregistracii"
                            legend_url="teleagronom:vvod_v_oborot"
                            attributes={['Category', 'Cad_Number', 'S ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Verif_Almet_zabros_na_sx')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Verif_Almet_zabros_na_sx"
                            layer="teleagronom:Verif_Almet_zabros_na_sx"
                            attributes={['Category', 'Cad_Number', 'S ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Verif_Almet_necelev_na_sx1')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Verif_Almet_necelev_na_sx1"
                            layer="teleagronom:Verif_Almet_necelev_na_sx1"
                            attributes={['Category', 'Cad_Number', 'S ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>

                <LayersControl.Overlay name={t('Loss_pashnya_Almet')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Loss_pashnya_Almet"
                            layer="teleagronom:Loss_pashnya_Almet"
                            legend_url="teleagronom:vvod_v_oborot"
                            attributes={['Category', 'Cad_Number', 'S ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Verif_Almet_vvod_v_oborot_bez_cad')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Verif_Almet_vvod_v_oborot_bez_cad"
                            layer="teleagronom:Verif_Almet_vvod_v_oborot_bez_cad"
                            attributes={['Category', 'Cad_Number', 'S ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Almetevskij_zalesenie%20po%20tipy')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Almetevskij_zalesenie%20po%20tipy"
                            layer="teleagronom:Almetevskij_zalesenie%20po%20tipy"
                            attributes={['Category', 'Cad_Number', 'S ga', 'Cad_EZ']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Almetevskij_tochki_otbora')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Almetevskij_tochki_otbora"
                            layer="teleagronom:Almetevskij_tochki_otbora"
                            attributes={['id', 'xcoord', 'ycoord']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Almetevskij_cad')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Almetevskij_cad"
                            layer="teleagronom:Almetevskij_cad"
                            attributes={['Cad_Number', 'Cad_EZ', 'Category', 'Type_use', 'Type_doc']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Osobo%20cennie%20zemli%20RT_total')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name="Osobo%20cennie%20zemli%20RT_total"
                            layer="teleagronom:Osobo%20cennie%20zemli%20RT_total"
                            attributes={['Cad_Number', 'Category', 'Type_use', 'Analitica', 'S ga']}
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/teleagronom/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </MapContainer>
    );
};