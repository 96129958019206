import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getCalculatorsSoilDepth = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getCalculatorsSoilDepthItem = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});