import * as constants from './constants';

export const getMycologicalRegistry = payload => ({
    type: constants.getMycologicalRegistry.toString(),
    payload
});

export const getMycologicalRegistries = payload => ({
    type: constants.getMycologicalRegistries.toString(),
    payload
});

export const updateMycologicalRegistry = payload => ({
    type: constants.updateMycologicalRegistry.toString(),
    payload
});

export const uploadRegistryFile = payload => ({
    type: constants.uploadRegistryFile.toString(),
    payload
});

export const updateFileDescription = payload => ({
    type: constants.updateFileDescription.toString(),
    payload
});

export const addMycologicalRegistry = payload => ({
    type: constants.addMycologicalRegistry.toString(),
    payload
});

export const clearMycologicalRegistry = payload => ({
    type: constants.clearMycologicalRegistry.toString()
});

export const generateMycologicalRegistryDoc = payload => ({
    type: constants.generateMycologicalRegistryDoc.toString(),
    payload
});

export const getUsersList = payload => ({
    type: constants.getUsersList.toString(),
    payload
});

export const getFilteredFields = payload => ({
    type: constants.getFilteredFields.toString(),
    payload
});

export const deleteMycologicalRegistry = payload => ({
    type: constants.deleteMycologicalRegistry.toString(),
    payload
});
export const publishMycologicalRegistry = payload => ({
    type: constants.publishMycologicalRegistry.toString(),
    payload
});