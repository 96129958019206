import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './style.less';
import { Table } from 'antd';

const statusColor = {
    veryhigh: '#27AE60',
    high: '#6FCF97',
    optimal: '#D9D9D9',
    low: '#FF7E77',
    verylow: '#FF4D4F'
};

const gradationColor = {
    very_low: '#BB6BD9',
    low: '#1890FF',
    average: '#56CCF2',
    increased: '#03B575',
    high: '#F2C94C',
    very_high: '#F2994A',
    toxic: '#FF4D4F'
};

const ComparisonTable = ({ tableData }) => {

    const { t } = useTranslation('leaf diagnostics');

    const columns = [
        {
            title: t('colored table name header'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => t(`table element ${text}`),
        },
        {
            title: t('colored table gradation header'),
            dataIndex: 'gradation',
            key: 'gradation',
            render: (text, record) => {
                return (
                    <div
                        className="diagnostics__colored-table__status">
                        <div
                            style={{ background: gradationColor[record.gradation] }}
                            className="diagnostics__colored-table__status__indicator" />
                        <div
                            className="diagnostics__colored-table__status__text">
                            {text ? t(`gradation status ${text}`) : ''}
                        </div>
                    </div>
                );
            }
        },
        {
            title: t('colored table dev header'),
            dataIndex: 'deviation',
            key: 'deviation',
            render: (text, record) => {
                return (
                    <div
                        className="diagnostics__colored-table__status">
                        <div
                            style={{ background: statusColor[record.deviation] }}
                            className="diagnostics__colored-table__status__indicator" />
                        <div
                            className="diagnostics__colored-table__status__text">
                            {t(`chloroplast status ${text}`)}
                        </div>
                    </div>
                );
            }
        }
    ];

    return (
        <Table
            pagination={false}
            rowKey="name"
            columns={columns}
            dataSource={tableData} />
    );
};

export default ComparisonTable;