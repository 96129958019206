import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getReviewsBody = state => state.models[name].reviews;
export const getReviewsList = state => state.models[name].reviews.content;
export const getReviewsListCount = state => state.models[name].reviews.totalElements;
export const getCurrentReview = state => state.models[name].calculator;
export const getCurrentAverageRate = state => state.models[name].averageRate;
export const isReviewLoading = state => state.models[name].loading;
