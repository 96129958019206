import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, Form, Input
} from 'antd';

import cn from '../../../utils/cn';
import {
    Title, Button, Tabs, InfoMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

const {
    addDeterminant
} = actions;
const {
    getCurrentDeterminant,
    getUserId,
    getCurDeterminantTypes
} = selectors;

const mstp = (state) => ({
    determinant: getCurrentDeterminant(state),
    userId: getUserId(state),
    determinantTypes: getCurDeterminantTypes(state)
});
const mdtp = ({
    addDeterminant
});

const { TabPane } = Tabs;
const { Option } = Select;

@withRouter
@connect(mstp, mdtp)
@withTranslation('determinant detail')
@cn('determinant-detail')
export default class DeterminantData extends Component {
    formRef = React.createRef();

    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {}
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { determinant, history, match } = this.props;
        const { id, type } = match.params;
        if ((prevProps.determinant.id !== determinant.id)
            || (prevProps.match.params.id !== id && !id)
            || (prevProps.match.params.type !== type)) {
            this.initForm(determinant);
        }
    }

    closeDeterminant = () => {
        const { history } = this.props;
        history.push('/determinants/');
    };

    hideModal = () => {
        this.setState({ visible: false });
    };

    showModal = () => {
        this.setState({ visible: true });
    };

    onSubmit = (values) => {
        const {
            addDeterminant, userId, onTabChange, match, history, determinantType
        } = this.props;
        const { id, type } = match.params;

        values.user = userId;
        values.determinant = type || determinantType;
        !id && addDeterminant({ history, values });
        onTabChange('2');
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        const { determinant } = this.props;
        this.setState({
            form, formInit, formFieldsClear, formClear
        }, () => this.initForm(determinant));
    };

    initForm = (determinant) => {
        const { match } = this.props;
        const { id, type } = match.params;
        const {
            name,
            determinant: determinantType
        } = determinant;

        this.formRef.current.setFieldsValue({
            name,
            determinant: determinantType || type
        });
    };

    setDeterminantName = (e) => {
        const { setName } = this.props;
        setName(e.target.value);
    };

    render() {
        const { cn } = this;
        const { t, determinantTypes, match } = this.props;
        const { id, type } = match.params;
        const { visible, value } = this.state;

        return (
            <Form ref={this.formRef}
                onFinish={this.onSubmit}
                className={cn('data')}
                name="determinant-form">
                <div>
                    <Row gutter={20}>
                        <Col span={18}>
                            <div className="field" style={{ marginBottom: '24px' }}>
                                <Form.Item label={t('determinant title')}
                                    name="name"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Input
                                        label={t('determinant title')}
                                        name="name"
                                        onChange={this.setDeterminantName} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <div className="field">
                                <span>{t('culture type')}</span>
                                <div className={cn('type-title')}>
                                    {
                                        determinantTypes.find(item => item.id === Number(type))
                                        && determinantTypes.find(item => item.id === Number(type)).name
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row gutter={20}>
                    <Col span={24} className={cn('button-block')}>
                        <Button type="primary" htmlType="submit">{t('start')}</Button>
                        <Button type="default" onClick={this.showModal}>{t('cancel')}</Button>
                    </Col>
                </Row>
                <InfoMessage
                    visible={visible}
                    title="create determinant title"
                    text="create determinant description"
                    okBtn="create determinant ok"
                    cancelBtn="create determinant cancel"
                    onOk={this.closeDeterminant}
                    onCancel={this.hideModal} />
            </Form>


        );
    }
}