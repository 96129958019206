import * as constants from './constants';

export const getCalculatorsMordovia = payload => ({
    type: constants.getCalculatorsMordovia.toString(),
    payload
});

export const getCalculatorMordovia = payload => ({
    type: constants.getCalculatorMordovia.toString(),
    payload
});

export const getCalculatorsMordoviaShape = payload => ({
    type: constants.getCalculatorsMordoviaShape.toString(),
    payload
});

export const getCalculatorsMordoviaForecast = payload => ({
    type: constants.getCalculatorsMordoviaForecast.toString(),
    payload
});

export const updateFieldYield = payload => ({
    type: constants.updateFieldYield.toString(),
    payload
});

export const getFieldYears = payload => ({
    type: constants.getFieldYears.toString(),
    payload
});

export const updateCalculatorsMordoviaForecast = payload => ({
    type: constants.updateCalculatorsMordoviaForecast.toString(),
    payload
});

export const getPestProbabilityData = payload => ({
    type: constants.getPestProbabilityData.toString(),
    payload
});

export const calculatePestProbability = payload => ({
    type: constants.calculatePestProbability.toString(),
    payload
});

export const clearCalculatorMordovia = payload => ({
    type: constants.clearCalculatorMordovia.toString()
});

export const addGroupCalculate = payload => ({
    type: constants.addGroupCalculate.toString(),
    payload
});

export const setActiveCalculate = payload => ({
    type: constants.setActiveCalculate.toString(),
    payload
});

export const removeGroupCalculate = payload => ({
    type: constants.removeGroupCalculate.toString(),
    payload
});

export const updateFieldDistribution = payload => ({
    type: constants.updateFieldDistribution.toString(),
    payload
});

export const getFieldPoints = payload => ({
    type: constants.getFieldPoints.toString(),
    payload
});

export const updateDistributionGroupCalculate = payload => ({
    type: constants.updateDistributionGroupCalculate.toString(),
    payload
});