import { getToken } from '../models/account/selectors';
import { isValidToken } from './utils';

let isTokenUpdating = false;
let unreleasedQueue = [];

function saveToken({ access, refresh }) {
    localStorage.setItem('token', access);
    localStorage.setItem('refresh', refresh);
    isTokenUpdating = false;
}

function refreshToken(refresh) {
    return fetch('/auth/jwt/refresh/', {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            refresh
        })
    })
        .then(
            (result) => {
                if (!result.ok) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh');
                    return Promise.reject(result);
                }
                const content = isJSON(result) && result.json();
                return Promise.all([result.status, content]);
            },
        )
        .then(
            (result) => {
                const [status, content] = result;
                const tokenData = content.access;
                const refreshData = content.refresh;
                saveToken(content);
                if (status >= 200 && status <= 299) return Promise.resolve(content);
                localStorage.removeItem('token');
                localStorage.removeItem('refresh');
                return Promise.reject(content);
            },
        );
}

export async function fetchWithAuth(url, options) {
    const isInfinity = localStorage.getItem('isInfinity');
    const loginUrl = '/'; // url страницы для авторизации
    const refreshData = localStorage.refresh;
    let tokenData = null; // объявляем локальную переменную tokenData

    if (localStorage.getItem('token')) { // если в sessionStorage присутствует tokenData, то берем её
        tokenData = localStorage.token;
    }

    if (!options.headers) { // если в запросе отсутствует headers, то задаем их
        options.headers = {};
    }
    if (isInfinity === 'yes') {
        options.headers.append('Authorization', `Token ${tokenData}`);
    } else {
        if (tokenData && refreshData && isValidToken(tokenData)) {
            options.headers.append('Authorization', `JWT ${tokenData}`);
        }
    }
    return fetch(url, options); // возвращаем изначальную функцию, но уже с валидным токеном в headers
}


/* global fetch, Headers, FormData */
function getHeaders(headers = {}, token) {
    const { getState } = window.store;
    const requestHeaders = new Headers();
    const state = getState();

    const curToken = token || localStorage.token;
    if (curToken) {
        // requestHeaders.append('Authorization', `JWT ${curToken}`);
    }
    Object.keys(headers)
        .forEach(key => requestHeaders.append(key, headers[key]));
    return requestHeaders;
}

function getTypedRequestContent(type, headers = { 'Content-Type': 'application/json' }, body = {}) {
    const rawHeaders = { ...headers };
    if (type) {
        switch (type) {
            case 'formdata': {
                delete rawHeaders['Content-Type'];
                const form = new FormData();
                Object.keys(body).forEach((field) => {
                    if (body[field]?.name) {
                        form.append(field, body[field], body[field].name);
                    } else {
                        form.append(field, body[field]);
                    }
                });
                return { body: form, headers: rawHeaders };
            }
            case 'urlencoded': {
                rawHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
                const params = new URLSearchParams();
                Object.keys(body).forEach((field) => {
                    params.set(field, body[field]);
                });
                return { body: params, headers: rawHeaders };
            }
            default: {
                rawHeaders['Content-Type'] = 'application/json';
                return { body: JSON.stringify(body), headers: rawHeaders };
            }
        }
    }

    const contentType = headers['Content-Type'];
    switch (contentType) {
        case 'application/json': {
            return { body: JSON.stringify(body), headers };
        }
        case 'multipart/form-data': {
            return { body, headers };
        }
        default: {
            return { body, headers };
        }
    }
}

const get = ({
    url, body, headers = { Accept: '*/*' }, parser, token
}) => {
    const content = {
        // credentials: 'include',
        mode: 'cors',
        method: 'GET',
        headers: getHeaders(headers, token)
    };


    return request(`${url}${object2Url(body)}`, content, parser);
};

const post = ({
    url, body: rawBody, headers: rawHeaders, parser, token, type
}) => {
    const { body, headers } = getTypedRequestContent(type, rawHeaders, rawBody);
    const requestHeaders = getHeaders(headers, token);

    const content = {
        body,
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: requestHeaders
    };

    return request(url, content, parser);
};

const postXHR = ({
    body: rawBody,
    headers: rawHeaders,
    token,
    type,
    url
}) => {
    const { body, headers } = getTypedRequestContent(type, rawHeaders, rawBody);
    const requestHeaders = getHeaders(headers, token);

    const xhr = new window.XMLHttpRequest();
    Object.keys(requestHeaders).forEach((key) => {
        xhr.setRequestHeader(key, requestHeaders[key]);
    });
    xhr.withCredentials = true;

    xhr.open('POST', url, true);

    return xhr;
};

const put = ({
    url, body: ardBody, headers: argHeaders, parser, token, type
}) => {
    const { body, headers } = getTypedRequestContent(type, argHeaders, ardBody);
    const requestHeaders = getHeaders(headers, token);

    const content = {
        body,
        credentials: 'include',
        mode: 'cors',
        method: 'PUT',
        headers: requestHeaders
    };

    return request(url, content, parser);
};

const patch = ({
    url, body: ardBody, headers: argHeaders, parser, token, type
}) => {
    const { body, headers } = getTypedRequestContent(type, argHeaders, ardBody);
    const requestHeaders = getHeaders(headers, token);

    const content = {
        body,
        credentials: 'include',
        mode: 'cors',
        method: 'PATCH',
        headers: requestHeaders
    };

    return request(url, content, parser);
};

const remove = ({
    url, body, parser, headers, token
}) => {
    const requestHeaders = getHeaders(headers, token);
    const content = {
        credentials: 'include',
        mode: 'cors',
        method: 'DELETE',
        headers: requestHeaders
    };

    return request(`${url}${object2Url(body)}`, content, parser);
};

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * @param url
 * @param content
 * @param parser
 * @return {Promise<Response>}
 */
async function request(url, content, parser = false) {
    const refreshData = localStorage.refresh;
    let tokenData = null; // объявляем локальную переменную tokenData

    if (localStorage.getItem('token')) { // если в sessionStorage присутствует tokenData, то берем её
        tokenData = localStorage.token;
    }

    if (tokenData && refreshData && !isValidToken(tokenData)) {
        if (isTokenUpdating) {
            const cooldownTimer = await sleep(1500);
        } else {
            isTokenUpdating = true;
            try {
                if (isValidToken(refreshData)) {
                    const newToken = await refreshToken(refreshData); // если истек, то обновляем токен с помощью refresh_token
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh');
                }
            } catch (e) { // если тут что-то пошло не так, то перенаправляем пользователя на страницу авторизации
                isTokenUpdating = false;
                // if (!(location.pathname.includes('/mordovia'))) { return window.location.replace(loginUrl); }
            }
        }
    }

    return fetchWithAuth(url, content)
        .then(
            (result) => {
                if (!result.ok) {
                    return Promise.reject(result);
                }
                content = isJSON(result) ? result.json() : parser && result[parser]();
                return Promise.all([result.status, content]);
            },
        )
        .then(
            (result) => {
                const [status, content] = result;
                if (status >= 200 && status <= 299) return Promise.resolve(content);
                return Promise.reject(content);
            },
        )
        .catch(error => {
            if (error.status === 401) {
                window.location.replace('/');
            }
            Promise.reject(error);
        });
}

function object2Url(params = {}) {
    let param = '';
    Object.keys(params).forEach((key) => {
        if (params[key] !== undefined) (param += `&${key}=${params[key]}`);
    });
    param = param.replace('&', '?');
    return param;
}

function url2Object(url = '') {
    let queryArray = url.split('?')[1];
    queryArray = queryArray && queryArray.split('#')[0];
    queryArray = queryArray && queryArray.split('&');

    const queryObject = {};

    if (queryArray) {
        queryArray.forEach((field) => {
            const [key, value] = field.split('=');
            queryObject[key] = value;
        });
    }

    return queryObject;
}

function isJSON(response = '') {
    const contentType = response.headers.get('content-type');
    return contentType && contentType.indexOf('application/json') >= 0;
}

const defTypePrefix = {
    start: 'TRY',
    success: 'SUCCESS',
    failure: 'FAILED'
};

/**
 * Метод возвращает 3 callback на базе стандартных имен событий, с префиксами по умолчанию
 * @param type - базовое имя события
 * @param dispatch
 * @return {{start: *, success: *, failure: *}}
 */
function getDefaultActions(type, dispatch) {
    const start = `${type}_${defTypePrefix.start}`;
    const success = `${type}_${defTypePrefix.success}`;
    const failure = `${type}_${defTypePrefix.failure}`;
    return getActions(start, success, failure, dispatch);
}

/**
 * Метод возвращает 3 callback стандартных события для дейсвтий
 * @param startEvent
 * @param successEvent
 * @param failureEvent
 * @param dispatch
 * @return {{start: *, success: *, failure: *}}
 */
function getActions(startEvent, successEvent, failureEvent, dispatch) {
    const start = getCallActionCallback(startEvent, dispatch);
    const success = getCallActionCallback(successEvent, dispatch);
    const failure = getCallActionCallback(failureEvent, dispatch, false);
    return { start, success, failure };
}

function getCallActionCallback(type, dispatch, isSuccess = true) {
    return (payload) => {
        dispatch({ type, payload });
        return isSuccess ? Promise.resolve(payload) : Promise.reject(payload);
    };
}

const requestUtlis = {
    get,
    post,
    postXHR,
    put,
    remove,
    patch,
    getDefaultActions,
    getActions,
    getCallActionCallback,
    object2Url,
    url2Object
};

export default requestUtlis;