export default {
    name: 'diseases',
    serverName: '/api/v1/disease/disease/',
    apiUrl: '/api/v1/disease/disease/',
    apiDamageAreaUrl: '/api/v1/disease/damage_area/',
    apiSubGroupUrl: '/api/v1/disease/disease_subgroup/',
    apiGroupUrl: '/api/v1/disease/disease_group/',
    apiCausativeAgentUrl: '/api/v1/disease/causative_agent/',
    apiCulturesUrl: '/api/v1/culture/culture/?filter={"related_diseases.isnull":false}&distinct&page_size=100',
    apiDiseasesFileUrl: '/v1/disease/potato-disease-file/'
};
