import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation('mico-constants');
    return (
        {
            LOW: ['LOW', 'VERY_LOW'],
            cultureCompare: {
                cultures_match: t('cultures match'),
                groups_differ: t('groups differ'),
                groups_match: t('groups match')
            },
            mycobiotaCompare: {
                'phytopathogenic__very_high-suppressive__very_low': t('phytopathogenic very_high-suppressive very_low'),
                'phytopathogenic__high-suppressive__low': t('phytopathogenic high-suppressive low'),
                'phytopathogenic__normal-suppressive__normal': t('phytopathogenic normal-suppressive normal'),
                'phytopathogenic__normal-suppressive__low': t('phytopathogenic normal-suppressive low'),
                'phytopathogenic__low-suppressive__high': t('phytopathogenic low-suppressive high'),
                'phytopathogenic__very_low-suppressive__very_high': t('phytopathogenic very_low-suppressive very_high'),
                'phytopathogenic__very_low-suppressive__very_low': t('phytopathogenic very_low-suppressive very_low'),
                'phytopathogenic__low-suppressive__very_low': t('phytopathogenic low-suppressive very_low'),
                'phytopathogenic__very_low-suppressive__low': t('phytopathogenic very_low-suppressive low'),
                'phytopathogenic__low-suppressive__low': t('phytopathogenic low-suppressive low')
            },
            interpretation: {
                very_high: t('very_high'),
                high: t('high'),
                normal: t('normal'),
                low: t('low'),
                very_low: t('very_low')
            }
        }
    );
};
