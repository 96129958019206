import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Layout, Spin, Icon, Row, Col
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Title, Tabs, SideBar } from '../../components';
import FertilizerDetail from './components/fertilizer-detail';

import cn from '../../utils/cn';

import './style.less';


const { Content } = Layout;

@withTranslation('fertilizers')
@cn('fertilizers')
export default class Fertilizer extends Component {

    render() {
        const { cn } = this;

        const { t, history } = this.props;

        return (
            <Layout className={cn()}>
                <FertilizerDetail />
            </Layout>
        );
    }
}