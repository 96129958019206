import * as constants from './constants';

export const getCalculatorsAcidity = payload => ({
    type: constants.getCalculatorsAcidity.toString(),
    payload
});

export const getCalculatorsAcidityItem = payload => ({
    type: constants.getCalculatorsAcidityItem.toString(),
    payload
});