import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import './style.less';
import { Button, Select } from 'antd';
import ComparisonTable from './table';
import { curLeafResults } from '../../../../../models/leaf-diagnostics/selectors';
import { curInterpretation } from '../../../../../models/item-interpretation/selectors';
import { addGroupCalculate, getCalculatorMordovia, setActiveCalculate } from '../../../../../models/mordovia/actions';
import { calculateDiagnostics } from '../../../../../models/leaf-diagnostics/actions';
import {
    calculatorsGroup,
    getCurrentCalculatorMordovia
} from '../../../../../models/mordovia/selectors';

const deviationToGradationEl = {
    n: 'nh4',
    p: 'p2o5',
    ks: 'k2o',
    kcl: 's',
    ca: 'ca',
    mg: 'mg'
};

const Comparison = ({ selectedField = {}, isCalc = false, currentCalc, setSelectedFed = () => {} }) => {

    const { t } = useTranslation('leaf diagnostics');

    const match = useRouteMatch();
    const dispatch = useDispatch();

    const interpretation = useSelector(state => curInterpretation(state));
    const calcResults = useSelector(state => curLeafResults(state));
    const calcGroup = useSelector(state => calculatorsGroup(state));
    const currentCalculator = useSelector(state => getCurrentCalculatorMordovia(state));

    const [tableData, setTableData] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [selectedZone, setSelectedZone] = useState([]);
    const [fedPoints, setFedPoints] = useState([]);
    const [dateOptions, setDateOptions] = useState([]);

    useEffect(() => {
        if (currentCalc?.id) {
            const tempArray = [];

            const elementKeys = ['n', 'p', 'ks', 'kcl', 'ca', 'mg'];

            elementKeys.forEach(elKey => {
                tempArray.push({
                    name: elKey,
                    deviation: currentCalc[`h_${elKey}`],
                    coefficient: currentCalc[`e_${elKey}`]
                });
            });

            const stopMax = Math.max(...[...tempArray].map(x => x.deviation));
            const stopPositiveLow = Math.floor(stopMax / 3);
            const stopPositiveHigh = stopPositiveLow * 2;

            const stopMin = Math.min(...[...tempArray].map(x => x.deviation));
            const stopNegative = Math.floor(stopMin / 2);

            setTableData([...tempArray].map(x => {
                if (x.deviation > stopPositiveHigh) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'veryhigh'
                    };
                } else if (x.deviation > stopPositiveLow && x.deviation <= stopPositiveHigh) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'high'
                    };
                } else if (x.deviation >= 0 && x.deviation <= stopPositiveLow) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'optimal'
                    };
                } else if (x.deviation >= stopNegative && x.deviation < 0) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'low'
                    };
                } else if (x.deviation >= stopMin && x.deviation < stopNegative) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'verylow'
                    };
                }
            }));
        }
    }, [currentCalc]);

    useEffect(() => {
        if (calcResults?.id) {
            const tempArray = [];

            const elementKeys = ['n', 'p', 'ks', 'kcl', 'ca', 'mg'];

            elementKeys.forEach(elKey => {
                tempArray.push({
                    name: elKey,
                    deviation: calcResults[`h_${elKey}`],
                    coefficient: calcResults[`e_${elKey}`]
                });
            });

            const stopMax = Math.max(...[...tempArray].map(x => x.deviation));
            const stopPositiveLow = Math.floor(stopMax / 3);
            const stopPositiveHigh = stopPositiveLow * 2;

            const stopMin = Math.min(...[...tempArray].map(x => x.deviation));
            const stopNegative = Math.floor(stopMin / 2);

            setTableData([...tempArray].map(x => {
                if (x.deviation > stopPositiveHigh) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'veryhigh'
                    };
                } else if (x.deviation > stopPositiveLow && x.deviation <= stopPositiveHigh) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'high'
                    };
                } else if (x.deviation >= 0 && x.deviation <= stopPositiveLow) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'optimal'
                    };
                } else if (x.deviation >= stopNegative && x.deviation < 0) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'low'
                    };
                } else if (x.deviation >= stopMin && x.deviation < stopNegative) {
                    return {
                        name: x.name,
                        gradation: interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase(),
                        coefficient: x.coefficient,
                        deviation: 'verylow'
                    };
                }
            }));
        }
    }, [calcResults]);

    useEffect(() => {
        const pointsArray = [];
        const datesArr = [];
        currentCalculator.year_group?.years?.forEach(year => {
            year?.fed_points?.forEach(fedPoint => {
                datesArr.push(new Date(fedPoint.date));
                pointsArray.push({
                    id: fedPoint?.id,
                    point_number: fedPoint?.point_number,
                    date: fedPoint?.date
                });
            });
        });
        setFedPoints(pointsArray);
    }, [currentCalculator]);

    useEffect(() => {
        if (tableData?.length > 0) {
            setTableData(tableData.map(x => {
                const copyEl = x;
                copyEl.gradation = interpretation[`${deviationToGradationEl[x.name]}_interval`]?.toLowerCase();
                return copyEl;
            }));
        }
    }, [interpretation]);

    useEffect(() => {
        if (fedPoints?.length > 0) {
            const datesArray = [];
            fedPoints.forEach(x => {
                if (!datesArray.includes(x.date)) {
                    datesArray.push(x.date);
                }
            });
            setDateOptions(datesArray.map(x => {
                return {
                    label: x,
                    value: x
                };
            }));
        }
    }, [fedPoints]);

    const isSameContent = (arr1, arr2) => arr1.length === arr2.length && arr1.every((element, index) => element === arr2[index]);

    const onCalculate = () => {
        setSelectedFed({});

        let existingResults = calcGroup.filter(x => x.type === selectedMethod);

        const fldBody = {
            type: selectedMethod,
            method: 'kirsanov',
            year: selectedDate.split('-')[0]
        };
        const requestBody = {
            type: selectedMethod,
            date: selectedDate
        };

        if (selectedMethod === 'zones') {
            requestBody.zones = selectedZone;
            fldBody.zones = selectedZone;
            existingResults = existingResults.filter(x => isSameContent(x.zones, selectedZone));
        }

        dispatch(calculateDiagnostics({
            vega_key: match.params.id,
            body: requestBody
        }));

        if (existingResults.filter(x => x.name.split(' ')[0].toLowerCase() === 'calculator').length > 0) {
            dispatch(setActiveCalculate(existingResults.filter(x => x.name.split(' ')[0].toLowerCase() === 'calculator')[0]));
        } else {
            dispatch(addGroupCalculate({
                vega_key: match.params.id,
                body: fldBody
            }));
        }
        setTimeout(() => {
            const { id, year } = match.params;
            if (id && year) {
                dispatch(getCalculatorMordovia({ id, year }));
            }
        }, 1500);
    };

    const methodOptions = [
        {
            value: 'field',
            label: t('method select field')
        },
        {
            value: 'zones',
            label: t('method select zone')
        }
    ];

    const zoneOptions = [
        {
            label: t('zone select high'),
            value: 'high'
        },
        {
            label: t('zone select mid'),
            value: 'middle'
        },
        {
            label: t('zone select low'),
            value: 'low'
        }
    ];

    return (
        <div
            className="comparison">
            <div
                className="comparison__header">
                <div
                    className="comparison__header__controls">

                    <div className="comparison__header__controls__item">
                        <div>
                            {t('date label')}
                        </div>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(newDate) => setSelectedDate(newDate)}
                            options={dateOptions}
                            placeholder={t('date placeholder')} />
                    </div>

                    <div className="comparison__header__controls__item">
                        <div>
                            {t('method label')}
                        </div>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(newMethod) => setSelectedMethod(newMethod)}
                            options={methodOptions}
                            placeholder={t('method placeholder')} />
                    </div>

                    {selectedMethod === 'zones' && (
                        <div className="comparison__header__controls__item">
                            <div>
                                {t('zone label')}
                            </div>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: 'fit-content' }}
                                onChange={(newZone) => setSelectedZone(newZone)}
                                options={zoneOptions}
                                placeholder={t('zone placeholder')} />
                        </div>
                    )}

                </div>
                <Button
                    onClick={onCalculate}
                    type="primary">
                    {t('calculate button')}
                </Button>
            </div>

            <ComparisonTable
                tableData={tableData}
                className="comparison__table" />
        </div>
    );
};

export default Comparison;