import * as constants from './constants';

export const getDistribution = payload => ({
    type: constants.getDistribution.toString(),
    payload
});

export const getDistributions = payload => ({
    type: constants.getDistributions.toString(),
    payload
});

export const addDistribution = payload => ({
    type: constants.addDistribution.toString(),
    payload
});

export const updateDistribution = payload => ({
    type: constants.updateDistribution.toString(),
    payload
});

export const updateFeedingDistribution = payload => ({
    type: constants.updateFeedingDistribution.toString(),
    payload
});

export const clearDistribution = payload => ({
    type: constants.clearDistribution.toString()
});

export const updateFertilizerPrice = payload => ({
    type: constants.updateFertilizerPrice.toString(),
    payload
});
