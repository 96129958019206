import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getMeteodata = ({ vega_key, body }) => request.post({
    url: urlPath(`${vega_key}/dates/`),
    body
});
