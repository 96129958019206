import { combineReducers } from 'redux';

import calculator from './calculator';
import calculators from './calculators';
import loading from './loading';

export default combineReducers({
    calculators,
    calculator,
    loading
});
