import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Table, TableFooter, Tooltip } from '../../../components';
import cn from '../../../utils/cn';

const ROW_DIVIDER = 10;

@withRouter
@withTranslation('harmful objects')
@cn('pesticides')
export default class ObjectsTable extends Component {
    state = {
        value: undefined
    };

    get requestsColumns() {
        const { cn } = this;
        const {
            t, history
        } = this.props;

        return [
            {
                title: t('harmful table header title'),
                dataIndex: 'name',
                key: 'name',
                className: cn('name'),
                render: (value, record) => value && (<a className="highlighted-hyperlink" onClick={() => history.push(`/harmful-object/${record.id}`)}>{value}</a>)

            },
            {
                title: t('harmful table header culture'),
                dataIndex: 'cultures',
                key: 'cultures',
                className: cn('name'),
                render: (value) => value?.length > 0 ? value?.map(x => x?.name)?.join(', ') : '-'

            },
            {
                title: t('harmful table header latin'),
                dataIndex: 'latin_name',
                key: 'latin_name',
                className: cn('name')

            },
            {
                title: t('harmful table header epv'),
                dataIndex: 'economy',
                key: 'economy',
                className: cn('name')

            },
        ];
    }

    render() {
        const { cn } = this;
        const {
            history,
            onCurrentPageChange,
            onPageSizeChange,
            filterItems,
            pageSize,
            pageNum,
            dataSource,
            dataSize
        } = this.props;

        return (
            <div>

                <Table
                    // onRow={(record, rowIndex) => ({
                    //     onClick: () => history.push(`/harmful-object/${record.id}`)
                    // })}
                    dataSource={dataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
                {dataSize > 1 && (
                    <TableFooter
                        filterItems={filterItems}
                        showSizeChanger={false}
                        onSelect={onPageSizeChange}
                        onChange={onCurrentPageChange}
                        pageSize={pageSize}
                        current={pageNum}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                )}
            </div>
        );
    }
}