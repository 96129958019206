import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import { Button, Col, Form, Input, Row } from 'antd';
import { useRouteMatch } from 'react-router-dom';

const { TextArea } = Input;

const CalculatorName = ({ confirmName }) => {
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const match = useRouteMatch();

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={t('form label name')}
                        name="name"
                        rules={[{ required: true, message: t('form error name') }]}>
                        <TextArea
                            maxLength={1000}
                            placeholder={t('form placeholder name')}
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            {!match?.params?.id > 0 && (
                <Row>
                    <Col>
                        <Button type="primary" onClick={confirmName}>
                            {t('start calculation')}
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default CalculatorName;