import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form, Select, Row, Col, Button
} from 'antd';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import ItemInterpretation from '../../../../containers/item-interpretation';

import { actions, selectors } from '../../../../models';
import { getCurrentCalculatorMordovia } from '../../../../models/mordovia/selectors';
import { createRecommendationRegistry } from '../../../../models/recommendation-registries/actions';

const { addGroupCalculate } = actions;
const { curInterpretation, curPoints } = selectors;

const methods = ['field', 'points', 'zones'];

const Interpretation = ({ isCompleted, year }) => {
    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));
    const interpretation = useSelector(state => curInterpretation(state));
    const fieldCalc = useSelector(state => getCurrentCalculatorMordovia(state));
    const points = useSelector(state => curPoints(state));
    const dispatch = useDispatch();
    const [type, setType] = useState(interpretation.type || 'field');
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [filteredZones, setFilteredZones] = useState({});
    const [notFull, setNotFull] = useState(true);
    const [form] = Form.useForm();

    const { t } = useTranslation('interpretation');

    useEffect(() => {
        form.setFieldsValue({
            year: interpretation.year || year,
            points: interpretation.points,
            zones: interpretation.zones,
            type: interpretation.type || 'field'
        });
        points?.forEach((item) => {
            if (item => item.date.split('-')[0] === interpretation.year) {
                filteredPoints.push(item);
                filteredZones[item.productivity_zone] = item.productivity_zone;
            }
        });
        setFilteredPoints([...filteredPoints]);
        setFilteredZones({ ...filteredZones });
    }, []);

    const onValuesChange = (value, values) => {
        let tempNotFull = false;
        if (value.year) {
            const filteredPoints = [];
            const filteredZones = {};
            points?.forEach((item) => {
                if (item => item.date.split('-')[0] === value.year) {
                    filteredPoints.push(item);
                    filteredZones[item.productivity_zone] = item.productivity_zone;
                }
            });
            setFilteredPoints([...filteredPoints]);
            setFilteredZones({ ...filteredZones });
        }
        if (value.type === 'zones' || value.type === 'points') {
            setNotFull(true);
            form.setFieldsValue({ zones: undefined, points: undefined });
            return;
        }
        if (values.type === 'field' && values.year) {
            setNotFull(false);
            return;
        }
        Object.values(values).forEach(item => { if (!item) tempNotFull = true; });
        if (tempNotFull !== notFull) {
            setNotFull(tempNotFull);
        }
    };

    const onFinish = (values) => {
        // values.method = fieldCalc.method;
        values.year = year;
        const reqBody = {
            region: 10,
            culture: 23,
            preceding_culture: 13,
            mechanical_composition: 1,
            field_num: "aa",
            vega_key: "aa",
            planned_yield: 11.1,
            soil_subtype: 1,
            feeding_stages: [50, 50],
            sowing_date: "2022-01-01",
            year: year,
            public: false
        }
        dispatch(createRecommendationRegistry(reqBody));
    };

    const changeMethod = (type) => {
        setType(type);
    };

    const [pointDateOptions, setPointDateOptions] = useState([]);

    useEffect(() => {
        if (points?.length > 0) {
            const optionsArray = [];
            points?.forEach((item) => {
                if (!optionsArray.includes(item.date)) {
                    optionsArray.push(item.date);
                }
            });
            const reformatOptions = optionsArray?.map(x => {
                return {
                    label: moment(x)?.format('DD.MM.YYYY'),
                    value: x
                };
            });
            setPointDateOptions(reformatOptions);
        }
    }, [points]);
    
    const analysisMethods = [
        {
            label: t('kirsanov method'),
            value: 'kirsanov'
        },
        {
            label: t('chirikov method'),
            value: 'chirikov'
        },
        {
            label: t('machigin method'),
            value: 'machigin'
        },
    ]

    return (
        <div>
            {points && (
                <Form onValuesChange={onValuesChange} form={form} onFinish={onFinish} style={{ marginBottom: '30px' }}>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item name="analysis_date">
                                <Select placeholder={t('analysis_date placeholder')} options={pointDateOptions} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="type">
                                <Select onChange={changeMethod} disabled={!points?.length}>
                                    {methods.map(item => (
                                        <Select.Option key={item} value={item}>
                                            {t(item)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            {(type === 'points') && (
                                <Form.Item name="points">
                                    <Select mode="multiple">
                                        {filteredPoints.map(item => (
                                            <Select.Option key={item.id} value={item.point_number}>
                                                {item.point_number}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            {(type === 'zones') && (
                                <Form.Item name="zones">
                                    <Select mode="multiple">
                                        {Object.keys(filteredZones).map(item => (
                                            <Select.Option key={item} value={item}>
                                                {t(item)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </Col>
                        <Col span={6}>
                            <Form.Item name="method">
                                <Select options={analysisMethods} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={notFull}
                                htmlType="submit"
                                type="primary"
                                size="large">
                                {t('calculate')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
            {(!points || type) && (
                <ItemInterpretation isCompleted={isCompleted} />
            )}
        </div>
    );
};

export default Interpretation;