import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import DeterminantData from './determinant-data';
import DeterminantQuestions from './determinant-questions';
import { selectors, actions } from '../../../models';
import * as constants from '../../../constants';
import DeterminantDataComplete from './determinant-data-complete';

const {
    getDeterminant,
    clearDeterminant,
    clearQuestion
} = actions;
const {
    getCurrentDeterminant
} = selectors;

const { TabPane } = Tabs;
const { PanelHeader, PanelContent } = PanelLayout;

const mstp = (state) => ({
    determinant: getCurrentDeterminant(state)
});
const mdtp = ({
    getDeterminant,
    clearDeterminant,
    clearQuestion
});


@withRouter
@withTranslation('determinant detail')
@connect(mstp, mdtp)
@cn('determinant-detail')
export default class DeterminantDetail extends Component {
    state = {
        name: false,
        id: null,
        tab: '1'
    };

    componentDidMount() {
        const {
            getDeterminant, clearDeterminant, match
        } = this.props;
        const { id } = match.params;
        this.setState({ id });
        clearDeterminant();
        // clearQuestion();
        id && getDeterminant(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { determinant, match, clearDeterminant } = this.props;
        const { id } = match.params;
        if (prevProps.match.params.id !== id && !id) {
            clearDeterminant();
            this.onTabChange('1');
        }
    }

    setName = (name) => {
        this.setState({ name });
    };

    setId = (id) => {
        this.setState({ id });
    };

    onTabChange = (key) => {
        this.setState({ tab: key });
    };

    render() {
        const { cn } = this;
        const {
            t, determinant, loading, match
        } = this.props;
        const { name, tab } = this.state;
        const { id } = match.params;
        return (
            <PanelLayout loading={loading}>
                <PanelHeader>
                    <Title title={name || t('determinant name')} />
                </PanelHeader>
                <PanelContent>
                    <Tabs
                        onChange={this.onTabChange}
                        activeKey={tab}>
                        <TabPane
                            tab={(
                                <span>
                                    <Icon type="unordered-list" />
                                    {t('determinant data')}
                                </span>
                            )}
                            key="1">
                            {((determinant.disease && determinant.disease.id) && id)
                                ? (
                                    <DeterminantDataComplete
                                        determinant={determinant}
                                        title={determinant.title} />
                                ) : (
                                    <DeterminantData
                                        id={id}
                                        determinant={determinant}
                                        onTabChange={this.onTabChange}
                                        setName={this.setName} />
                                )}
                        </TabPane>
                        <TabPane
                            tab={(
                                <span>
                                    <Icon type="check-square" />
                                    {((determinant.disease && determinant.disease.id) && id)
                                        ? t('qualifier passed')
                                        : t('qualifier')}
                                </span>
                            )}
                            disabled={!id}
                            key="2">
                            <DeterminantQuestions onTabChange={this.onTabChange} />
                        </TabPane>
                        {/* <TabPane
                            tab={(
                                <span>
                                    <Icon type="info-circle" />
                                    {t('additional information')}
                                </span>
                            )}
                            key="3">
                            {t('additional information')}
                        </TabPane> */}
                    </Tabs>
                </PanelContent>
            </PanelLayout>
        );
    }
}