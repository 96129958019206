import { combineReducers } from 'redux';

import pesticide from './pesticide';
import pesticides from './pesticides';
import applicationArea from './application-area';
import chemicalClasses from './chemical-classes';
import group from './group';
import harmfulObject from './harmful-object';
import hazardClass from './hazard-class';
import mainDrug from './main-drug';
import placeOfProduction from './place-of-production';
import preparativeForm from './preparative-form';
import productApplication from './product-application';
import treatmentType from './treatment-type';

export default combineReducers({
    pesticide,
    pesticides,
    applicationArea,
    chemicalClasses,
    group,
    harmfulObject,
    hazardClass,
    mainDrug,
    placeOfProduction,
    preparativeForm,
    productApplication,
    treatmentType
});
