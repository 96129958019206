import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getCurNDMI = state => state.models[name].ndmi;
export const getCurNDVI = state => state.models[name].ndvi;
export const getCurNDMIandNDVI = state => state.models[name].ndMiVi;
export const getCurSWI = state => state.models[name].swi;
export const getCurUUID = state => state.models[name].uuid;