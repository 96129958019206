import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getCurCarouselData = state => state.models[name].data.results;
export const getCurCarouselCultures = state => state.models[name].cultures.results;
export const getCurCarouselSymptoms = state => state.models[name].symptoms.results;
export const isCarouselLoading = state => state.models[name].loading;
