import * as constants from './constants';

export const getInterpretation = payload => ({
    type: constants.getInterpretation.toString(),
    payload
});

export const getInterpretations = payload => ({
    type: constants.getInterpretations.toString(),
    payload
});

export const updateInterpretation = payload => ({
    type: constants.updateInterpretation.toString(),
    payload
});

export const addInterpretation = payload => ({
    type: constants.addInterpretation.toString(),
    payload
});

export const clearInterpretation = payload => ({
    type: constants.clearInterpretation.toString()
});
