import * as constants from './constants';

export const getNutrition = payload => ({
    type: constants.getNutrition.toString(),
    payload
});

export const getNutritions = payload => ({
    type: constants.getNutritions.toString(),
    payload
});

export const addNutrition = payload => ({
    type: constants.addNutrition.toString(),
    payload
});

export const updateNutrition = payload => ({
    type: constants.updateNutrition.toString(),
    payload
});

export const clearNutrition = payload => ({
    type: constants.clearNutrition.toString()
});
