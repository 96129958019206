import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import {
    Button, Col, Modal, Row
} from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';

const InfoModal = ({
    inspectableItem, downloadPDF, closeModal, isOpened
}) => {
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');

    return (
        <Modal
            onCancel={closeModal}
            width={800}
            footer={[
                <Button type="link" onClick={downloadPDF}>
                    <DownloadOutlined />
                    {t('infomodal button download')}
                </Button>,
                <Button type="primary" onClick={closeModal}>
                    {t('infomodal button close')}
                </Button>
            ]}
            title={inspectableItem?.name}
            visible={isOpened}>
            <Row className="modal-row__dark">
                <Col span={10}>
                    <div className="modal-cell">
                        {t('modal row 1')}
                    </div>
                </Col>
                <Col span={14}>
                    <div className="modal-cell">
                        {inspectableItem?.causative_agent?.name || '-'}
                    </div>
                </Col>
            </Row>
            <Row className="modal-row">
                <Col span={10}>
                    <div className="modal-cell">
                        {t('modal row 2')}
                    </div>
                </Col>
                <Col span={14}>
                    <div className="modal-cell">
                        {inspectableItem?.causative_agent?.causes_of_occurrence || '-'}
                    </div>
                </Col>
            </Row>
            <Row className="modal-row__dark">
                <Col span={10}>
                    <div className="modal-cell">
                        {t('modal row 3')}
                    </div>
                </Col>
                <Col span={14}>
                    <div className="modal-cell">
                        {inspectableItem?.pathogen_morphology || '-'}
                    </div>
                </Col>
            </Row>
            <Row className="modal-row">
                <Col span={10}>
                    <div className="modal-cell">
                        {t('modal row 4')}
                    </div>
                </Col>
                <Col span={14}>
                    <div className="modal-cell">
                        {inspectableItem?.biology_spread_pathogen}
                    </div>
                </Col>
            </Row>
            <Row className="modal-row__dark">
                <Col span={10}>
                    <div className="modal-cell">
                        {t('modal row 5')}
                    </div>
                </Col>
                <Col span={14}>
                    <div className="modal-cell">
                        {inspectableItem?.ecology_of_disease}
                    </div>
                </Col>
            </Row>
            <Row className="modal-row">
                <Col span={10}>
                    <div className="modal-cell">
                        {t('modal row 6')}
                    </div>
                </Col>
                <Col span={14}>
                    <div className="modal-cell">
                        {inspectableItem?.disease_harmfulness}
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default InfoModal;