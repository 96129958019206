import { login } from '../constants';

export default function reducer(state = false, action) {
    switch (action.type) {
        case login.error: {
            return true;
        }
        case login.success: {
            return false;
        }
        default: return state;
    }
}
