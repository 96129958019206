import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../button';

import cn from '../../utils/cn';
import './style.less';

@cn('round-button')
export default class RoundButton extends Component {
    render() {
        const { cn } = this;
        const {
            className: propClass,
            ...rest
        } = this.props;
        const className = `${cn()} ${propClass}`;

        return (
            <Button className={className} {...rest} />
        );
    }
}

RoundButton.propTypes = {
    className: PropTypes.string
};

RoundButton.defaultProps = {
    className: ''
};
