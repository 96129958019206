import moment from 'moment';
import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    _server2Client = (item = {}) => ({
        id: item.id,
        createDate: item.createDate,
        determinantStatus: item.determinantStatus,
        title: item.title,
        disease: item.disease && item.disease.name,
        questionLog: item.questionLog
    });

    list2Client = (items = []) => items.map(this._server2Client);

    determinantsFullInfo2Client = (rawObject = {}) => {
        const {
            content,
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        } = rawObject;

        return {
            content: this.list2Client(content),
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        };
    };

    removedDeterminantId2Client = id => ({ removedDeterminantId: id });

    questionsFinalPage2Client = (rawObject = {}) => rawObject;

    question2Client = (item = {}, i) => ({
        index: i + 1,
        number: item.question.number,
        title: item.question.title,
        answers: item.question.answers,
        answer: item.history_item.answer
    });

    questions2Client = (items = []) => items.map(this.question2Client);
}

export default new Converter();