import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import {
    Space, Button
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EventsTable from './events-table';
import EventsModal from './events-modal';
import {
    curAgroEventsErrors,
    curFieldEvents
} from '../../../../models/agrotechnical-events/selectors';
import {
    deleteAgrotechnicalEvent,
    getAgrotechnicalEventsField
} from '../../../../models/agrotechnical-events/actions';
import CommentModal from './comment-modal';

const AgrotechnicalEvents = ({ isLaborant = false }) => {
    const match = useRouteMatch();
    const { t } = useTranslation('accounting documents');
    const dispatch = useDispatch();

    const fieldEvents = useSelector(state => curFieldEvents(state));
    const eventsError = useSelector(state => curAgroEventsErrors(state));

    const [editableEvent, setEditableEvent] = useState(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
    const [commentMode, setCommentMode] = useState('');
    const [commentableEvent, setCommentableEvent] = useState({});
    const [currentComment, setCurrentComment] = useState('');
    const [currError, setCurrError] = useState('');

    useEffect(() => {
        if (eventsError[0]?.length > 0) {
            setCurrError(eventsError[0]);
        }
    }, [eventsError]);

    useEffect(() => {
        updateAgroEvents();
    }, []);

    const updateAgroEvents = () => {
        dispatch(getAgrotechnicalEventsField({
            vega_key: match.params.id,
            year: match.params.year
        }));
    };

    const handleEventEdition = (eventEntity) => {
        setEditableEvent(eventEntity);
        setIsModalOpen(true);
    };

    const handleEventComment = (commentType, recordItem) => {
        setCommentableEvent(recordItem);
        setCommentMode(commentType);
        setCommentModalIsOpen(true);
    };

    const handleEventDeletion = (eventEntity) => {
        dispatch(deleteAgrotechnicalEvent({
            eventId: eventEntity.id
        }));
        setTimeout(() => {
            updateAgroEvents();
        }, 1000);
    };

    return (
        <Space direction="vertical" style={{ position: 'relative', width: '100%' }}>
            {!isLaborant && (
                <Button
                    type="primary"
                    onClick={() => setIsModalOpen(true)}
                    disabled={false}
                    size="large">
                    {t('button add')}
                </Button>
            )}

            {currError?.length > 0 ? (
                <div>
                    {currError}
                </div>
            ) : (
                <EventsTable
                    editEvent={handleEventEdition}
                    commentEvent={handleEventComment}
                    deleteEvent={handleEventDeletion}
                    setCurrentComment={setCurrentComment}
                    rawData={fieldEvents} />
            )}

            <EventsModal
                fieldId={match.params.id}
                editableEvent={editableEvent}
                setEditableEvent={setEditableEvent}
                updateEvents={updateAgroEvents}
                setIsOpened={setIsModalOpen}
                isOpened={isModalOpen} />

            <CommentModal
                updateEvents={updateAgroEvents}
                commentableEvent={commentableEvent}
                commentMode={commentMode}
                isOpened={commentModalIsOpen}
                existingText={currentComment}
                setIsOpened={setCommentModalIsOpen} />
        </Space>
    );
};

export default AgrotechnicalEvents;