import React, { Component } from 'react';
import { Popover as AntPopover } from 'antd';

import cn from '../../utils/cn';
import './style.less';

@cn('popover')
export default class Popover extends Component {
    render() {
        const { cn } = this;
        const {
            ...rest
        } = this.props;

        return (
            <AntPopover className={cn()} {...rest} />
        );
    }
}