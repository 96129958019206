import React, { useEffect, useState, useRef } from 'react';
import {
    Table
} from 'antd';

import { useTranslation } from 'react-i18next';

import '../style.less';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { usrListResponse } from '../../../models/sample-log/selectors';
import { getUsersList } from '../../../models/sample-log/actions';
import SelectableTable from '../../../components/selectable-table';
import { getProtocolsPhyto } from '../../../models/calculators-phyto/actions';
import { currentProtocolsList } from '../../../models/calculators-phyto/selectors';
import { curNewInitialProtocol } from '../../../models/fed-registry/selectors';

const RegistriesTable = ({
    page, setPage, pageSize, setPageSize,
    selectedItems, setSelectedItems
}) => {
    const { t } = useTranslation('fsm registries');
    const history = useHistory();
    const dispatch = useDispatch();

    const users = useSelector(state => usrListResponse(state));
    const protocolItems = useSelector(state => currentProtocolsList(state));
    const newProtocol = useSelector(state => curNewInitialProtocol(state));

    const [sortingOrder, setSortingOrder] = useState(null);

    const [fullUsersList, setFullUsersList] = useState([]);
    const [usersPage, setUsersPage] = useState(1);

    useEffect(() => {
        if (sortingOrder?.length > 0) {
            dispatch(getProtocolsPhyto({
                page: page,
                pageSize: pageSize,
                ordering: sortingOrder?.[1] === 'ascend' ? `sort=["${sortingOrder[0]}"]` : `sort=["-${sortingOrder[0]}"]`
            }));
        }
    }, [sortingOrder]);

    useEffect(() => {
        if (newProtocol?.id && newProtocol?.id > 0) {
            dispatch(getProtocolsPhyto({
                page: page,
                pageSize: pageSize
            }));
        }
    }, [newProtocol]);

    useEffect(() => {
        if (sortingOrder?.length > 0) {
            dispatch(getProtocolsPhyto({
                page: page,
                pageSize: pageSize,
                ordering: sortingOrder?.[1] === 'ascend' ? `sort=["${sortingOrder[0]}"]` : `sort=["-${sortingOrder[0]}"]`
            }));
        } else {
            dispatch(getProtocolsPhyto({
                page: page,
                pageSize: pageSize
            }));
        }
        dispatch(getProtocolsPhyto({
            page: page,
            pageSize: pageSize
        }));
    }, [page]);

    useEffect(() => {
        if (page === 1) {
            if (sortingOrder?.length > 0) {
                dispatch(getProtocolsPhyto({
                    page: page,
                    pageSize: pageSize,
                    ordering: sortingOrder?.[1] === 'ascend' ? `sort=["${sortingOrder[0]}"]` : `sort=["-${sortingOrder[0]}"]`
                }));
            } else {
                dispatch(getProtocolsPhyto({
                    page: page,
                    pageSize: pageSize
                }));
            }
        } else {
            setPage(1);
        }
    }, [pageSize]);

    useEffect(() => {
        dispatch(getUsersList({
            page: usersPage,
            searchString: ''
        }));
    }, [usersPage]);

    useEffect(() => {
        const listPortion = users?.results;

        if (listPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...listPortion]);
        }

        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUsersPage(usersPage + 1);
        }
    }, [users]);

    const columns = [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('name'),
            dataIndex: ['fito_calculator', 'name'],
            key: 'name',
            sorter: (a, b) => a?.fito_calculator?.name?.localeCompare(b?.fito_calculator?.name),
            sortDirections: ['ascend', 'descend'],
            render: value => value || '-'
        },
        {
            title: t('sample date'),
            dataIndex: ['fito_calculator', 'sample_date'],
            key: 'sample_date',
            sorter: (a, b) => moment(a?.fito_calculator?.sample_date).unix() - moment(b?.fito_calculator?.sample_date).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (text) => text ? moment(text).format('DD.MM.YYYY') : '-'
        },
        {
            title: t('owner'),
            dataIndex: 'owner',
            key: 'owner',
            sorter: (a, b) => fullUsersList.filter(usr => usr.id === parseInt(a.owner, 10))[0]?.name.localeCompare(fullUsersList.filter(usr => usr.id === parseInt(b.owner, 10))[0]?.name),
            render: (val) => {
                return fullUsersList?.length > 0 ? fullUsersList.filter(usr => usr.id === parseInt(val, 10))[0]?.name : val
            }
        },
        {
            title: t('field'),
            dataIndex: 'vega_key',
            key: 'vega_key',
            sorter: (a, b) => a?.vega_key?.localeCompare(b?.vega_key),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: t('diseases'),
            dataIndex: 'diseases',
            key: 'diseases',
            render: value => value ? t('discovered') : t('undiscovered')
        },
        {
            title: t('culture'),
            dataIndex: ['fito_calculator', 'culture', 'name'],
            key: 'culture',
            sortDirections: ['descend', 'ascend']
        },
        {
            title: t('status'),
            dataIndex: 'archived',
            key: 'public',
            render: (val, record) => record?.archived ? (
                <div className="publication-status__archived">
                    {t('archived')}
                </div>
            ) : val ? (
                <div className="publication-status__published">
                    {t('published')}
                </div>
            ) : (
                <div className="publication-status__edition">
                    {t('edition')}
                </div>
            )
        }
    ];

    const handleOpenRegistry = (record) => {
        return {
            onClick: () => {
                history.push(`/fsm-registry/${record.id}`);
                setTimeout(() => window.location.reload(), 200);
            }
        };
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter?.order?.length > 0 && sorter?.columnKey?.length > 0) {
            setSortingOrder([sorter?.columnKey, sorter?.order]);
        }
    };

    return (
        <SelectableTable
            onChange={handleTableChange}
            isBordered={false}
            isLoading={protocolItems?.isLoading}
            onRowClick={handleOpenRegistry}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            tableColumns={columns}
            tablePage={page}
            tableTotal={protocolItems?.total}
            setTablePage={setPage}
            tablePageSize={pageSize}
            setTablePageSize={setPageSize}
            tableData={protocolItems?.results} />
    );
};

export default RegistriesTable;