import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    Col, Form, Input, Row
} from 'antd';
import { useClassName } from 'utils/cn';

import { actions, selectors } from '../../models';

import './style.less';
import { Button } from '../../components';

import * as constants from './constants';
import { setCurDistributionCalculate } from '../../reducers/view/actions';

const {
    curCalculate
} = selectors;

const {
    addCalculate
} = actions;

export default (props) => {
    const { type } = props;
    const calculator = useSelector(state => curCalculate(state));
    const dispatch = useDispatch();
    const { t } = useTranslation('calculators');
    const cn = useClassName('calculators');
    const match = useRouteMatch();
    const { id = 0 } = match.params;
    const history = useHistory();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ name: calculator.name || '' });
    }, [calculator]);

    const onFinish = (values) => {
        dispatch(addCalculate({
            history,
            values: { name: values.name, calculator_type: constants.CALCULATOR_TYPE[type] }
        }));
        dispatch(setCurDistributionCalculate(constants.DISTRIBUTION_TYPE[type]));
    };

    return (
        <div>
            <Form onFinish={onFinish}
                form={form}
                className={cn('data')}
                name="calculator-form">
                <div>
                    <Row gutter={20}>
                        <Col span={24}>
                            <div className="field" style={{ marginBottom: '24px' }}>
                                <Form.Item
                                    label={t('calculators:calculate:title')}
                                    placeholder={t('calculators:calculate:placeholder')}
                                    name="name"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Input
                                        disabled={calculator.isCompleted} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <div className={cn('table-result')}>
                                <div className={cn('table-info')}>
                                    <div>
                                        {t(type)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col span={24} className={cn('button')}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={calculator.isCompleted || id}>
                            {t('calculators:start')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};