import * as constants from './constants';

export const getMeteodata = payload => ({
    type: constants.getMeteodata.toString(),
    payload
});

export const getAnalysisData = payload => ({
    type: constants.getAnalysisMeteodata.toString(),
    payload
});

export const getEffectiveData = payload => ({
    type: constants.getEffectiveMeteodata.toString(),
    payload
});

export const getPrecipitationData = payload => ({
    type: constants.getPrecipitationMeteodata.toString(),
    payload
});