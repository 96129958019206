import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import '../style.less';
import { Table } from 'antd';
import moment from 'moment';

const Phyto = ({
    selectedPhytos, setSelectedPhytos, calculatorData, usersList
}) => {
    // const cn = useClassName('calculators');
    const { t } = useTranslation('general protocol');

    useEffect(() => {
        console.log('calculatorData PHYTO UPD', calculatorData);
    }, [calculatorData]);

    const getUserName = (uId) => {
        console.log('GOT UID', uId);
        if (!uId) { return '-'; }
        return usersList?.filter(x => x.id === uId)[0]?.name;
    };
    
    const columns = [
        {
            title: t('header num'),
            dataIndex: 'num',
            key: 'num',
            render: (value, record) => {
                console.log('record', record);
                return `${value ? value : '-'} / ${record?.fito_calculator?.name ? record?.fito_calculator?.name : '-'}`;
            }
        },
        {
            title: t('header vega_key'),
            dataIndex: 'vega_key',
            key: 'vega_key',
            render: value => value ? value : '-'
        },
        {
            title: t('header owner'),
            dataIndex: 'owner',
            key: 'owner',
            // render: (value) => value ? usersList?.filter(x => x.id === value)[0]?.name : '-'
            render: getUserName
        },
        {
            title: t('header acceptation_date'),
            dataIndex: ['fito_calculator', 'sample_date'],
            key: 'sample_date',
            render: value => moment(value).format('DD.MM.YYYY')
        },
        {
            title: t('header examination_date'),
            dataIndex: ['fito_calculator', 'analysis_date'],
            key: 'analysis_date',
            render: value => moment(value).format('DD.MM.YYYY')
        }
    ];

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedPhytos(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys: selectedPhytos?.map(item => item.id),
        onChange: onSelectChange
    };

    return (
        <div>
            {(calculatorData?.length > 0) ? (
                <Table
                    rowSelection={rowSelection}
                    rowKey="id"
                    columns={columns}
                    dataSource={calculatorData} />
            ) : (
                <div>{t('no protocols')}</div>
            )}
        </div>
    );
};

export default Phyto;