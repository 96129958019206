import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getHarmfulObjects = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getHarmfulObject = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`),
    body: {
        type: 'pest'
    }
});

export const getHarmfulObjectDivisions = filter => request.get({
    url: urlPath(options.divisionUrl, filter)
});