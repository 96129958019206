import * as constants from '../constants';
import converter from '../converter';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.addGroupCalculate.error: {
            return {
                error: action.payload
            };
        }

        default:
            return state;
    }
}
