import options from './options';

const { name } = options;

export const curProtocolList = state => state.models[name].protocolList;
export const curProtocol = state => state.models[name].protocolItem;
export const curNewInitialProtocol = state => state.models[name].protocolNew;
export const curNewInternalCalculator = state => state.models[name].calculatorNew;
export const curUpdatedCalculator = state => state.models[name].calculatorUpdated;
export const curUpdatedProtocol = state => state.models[name].protocolUpdated;
export const curRetrievedFertilizer = state => state.models[name].fertilizerItem;
export const curFertList = state => state.models[name].fertilizerList.results;
export const curFertListObj = state => state.models[name].fertilizerList;
export const curFertLength = state => state.models[name].fertilizerList.count;
export const curPublicationStatus = state => state.models[name].statusUpdate;
export const curDeletionStatus = state => state.models[name].protocolDeleted;