import options from './options';
import request from '../../utils/request';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getUsers = filter => request.get({
    url
});

export const resetPassword = email => request.post({
    url: urlPath(`resetPassword?email=${email}`)
});

export const savePassword = body => request.post({
    url: urlPath('savePassword'),
    body
});