import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Popover, Button } from '../../../components';

import cn from '../../../utils/cn';
import * as constants from '../../../constants';

@withRouter
@withTranslation('requests')
@cn('action-popover')
export default class ActionPopover extends Component {
    state = {
        visible: false
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
        setTimeout(() => this.setState({ visible: false }), 3000);
    };

    hide = () => {
        this.setState({
            visible: false
        });
    };

    closeRequest = () => {
        const { closeRequest } = this.props;

        this.hide();

        closeRequest();
    };

    sendRequest = () => {
        const { sendRequest } = this.props;

        this.hide();

        sendRequest();
    };

    goTo = () => {
        const { id, history, url } = this.props;
        history.push(`/${url}/${id}`);
    };

    content = () => {
        const { cn } = this;
        const {
            t, id, status, history
        } = this.props;

        return (
            <div className={cn()}>
                <div onClick={this.goTo}>{t('edit')}</div>
                <div onClick={this.closeRequest}>{t('close')}</div>
                <div onClick={this.sendRequest}>{t('send')}</div>
            </div>
        );
    };

    render() {
        const { t, ...rest } = this.props;
        const { visible } = this.state;
        return (
            <Popover
                content={this.content()}
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                {...rest}>
                <Button type="default"
                    size="middle">{t('action')}
                </Button>
            </Popover>
        );
    }
}