import { combineReducers } from 'redux';

import data from './data';
import symptoms from './symptoms';
import loading from './loading';
import cultures from './cultures';

export default combineReducers({
    data,
    symptoms,
    cultures,
    loading
});
