import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getCalculatorsFedBody = state => state.models[name].calculators;
export const getCalculatorsFedList = state => state.models[name].calculators.results;
export const getCalculatorsFedListCount = state => state.models[name].calculators.count;
export const getCurrentCalculatorFed = state => state.models[name].calculator;
export const isCalculatorFedLoading = state => state.models[name].loading;
