import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Col, Collapse, Form, Layout, Row, Spin
} from 'antd';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useClassName } from '../../utils/cn';
import Agrochemical from './components/agrochemical';
import Fed from './components/fed';
import Imap from './components/imap';
import Phyto from './components/phyto';

import './style.less';

import { PanelLayout, Title } from '../../components';
import ListIcon from '../../../public/images/calculator/list2_stroke.svg';
import CalculatorIcon from '../../../public/images/calculator/calculator2_stroke.svg';
import { curGeneralProtocol } from '../../models/general-protocol/selectors';
import { getUser } from '../../models/account/selectors';
import { getProtocol } from '../../models/general-protocol/actions';
import Parameters from './components/parameters';
import PreviewModal from './components/preview-modal';
import DetailsModal from './components/details-modal';
import { getUsersList } from '../../models/mycological-registry/actions';
import { all } from 'redux-saga/effects';

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;

const GeneralProtocol = () => {
    const dispatch = useDispatch();
    const cn = useClassName('general-protocol');
    const { t } = useTranslation('general protocol');

    const generalProto = useSelector(state => curGeneralProtocol(state));
    const currentUser = useSelector(state => getUser(state));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState(null);

    const [selectedAgros, setSelectedAgros] = useState([]);
    const [selectedFeds, setSelectedFeds] = useState([]);
    const [selectedImaps, setSelectedImaps] = useState([]);
    const [selectedPhytos, setSelectedPhytos] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [ownerName, setOwnerName] = useState(null);
    const [selectedProd, setSelectedProd] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedField, setSelectedField] = useState([]);

    const [currentAgrochemicalData, setCurrentAgrochemicalData] = useState([]);
    const [currentFedData, setCurrentFedData] = useState([]);
    const [currentImapData, setCurrentImapData] = useState([]);
    const [currentPhytoData, setCurrentPhytoData] = useState([]);
    const [triedProtocol, setTriedProtocol] = useState(false);

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [currentSettings, setCurrentSettings] = useState([]);
    const [currentFedParams, setCurrentFedParams] = useState([]);
    const [currentImapParams, setCurrentImapParams] = useState([]);
    const [currentAgroParams, setCurrentAgroParams] = useState([]);
    const [currentAgroElements, setCurrentAgroElements] = useState([]);
    const [currentPhytoParams, setCurrentPhytoParams] = useState([]);
    const [groupingMethod, setGroupingMethod] = useState('');

    const [selectedUserNames, setSelectedUserNames] = useState([]);
    const [selectedFieldNames, setSelectedFieldNames] = useState([]);
    const [selectedYearNames, setSelectedYearNames] = useState([]);
    const [selectedProductivityNames, setSelectedProductivityNames] = useState([]);

    useEffect(() => {
        const allImaps = [];
        const allFeds = [];
        const allAgros = [];
        const allPhytos = [];

        generalProto?.forEach(usrX => {
            usrX?.imaps?.forEach(imapX => {
                allImaps.push(imapX);
            });
            usrX?.agros?.forEach(agroX => {
                allAgros.push(agroX);
            });
            usrX?.feds?.forEach(fedX => {
                allFeds.push(fedX);
            });
            usrX?.fitos?.forEach(phytoX => {
                allPhytos.push(phytoX);
            });
        });

        // setCurrentAgrochemicalData(allAgros?.length > 0 ? allAgros : currentAgrochemicalData);
        // setCurrentImapData(allImaps?.length > 0 ? allImaps : currentImapData);
        // setCurrentFedData(allFeds?.length > 0 ? allFeds : currentFedData);
        // setCurrentPhytoData(allPhytos?.length > 0 ? allPhytos : currentPhytoData);

        setCurrentAgrochemicalData(allAgros);
        setCurrentImapData(allImaps);
        setCurrentFedData(allFeds);
        setCurrentPhytoData(allPhytos);
    }, [generalProto]);

    const [activeTabs, setActiveTabs] = useState([
        'parameters'
    ]);

    const handleDownloadProtocol = () => {
        const fedExclude = [...currentSettings, ...currentFedParams];
        const imapExclude = [...currentImapParams];
        const agroExclude = [...currentAgroParams, ...currentAgroElements];
        const phytoExclude = [...currentPhytoParams];
        
        const requestBody = {
            imaps: selectedImaps?.length > 0 ? selectedImaps?.map(item => item.id) : [],
            feds: selectedFeds?.length > 0 ? selectedFeds?.map(item => item.id) : [],
            agros: selectedAgros?.length > 0 ? selectedAgros?.map(item => item.id) : [],
            fitos: selectedPhytos?.length > 0 ? selectedPhytos?.map(item => item.id) : [],
            order_by: groupingMethod?.length > 0 ? groupingMethod : undefined,
            exclude_params_fed: fedExclude?.length > 0 ? fedExclude : [],
            exclude_params_agro: agroExclude?.length > 0 ? agroExclude : [],
            exclude_params_imap: imapExclude?.length > 0 ? imapExclude : [],
            exclude_params_fito: phytoExclude?.length > 0 ? phytoExclude : []
        };

        selectedOwner?.forEach(ownerId => {
            fetch(`/api/v1/teleagronom_user/owners/${ownerId}/download_general_protocol/`, {
                method: 'POST',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            }).then((resp) => {
                resp.blob().then((blb) => {
                    const url = window.URL.createObjectURL(blb);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `General_Protocol_${ownerId}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    // setIsRecordGenerating(false);
                });
            });
        });
    };

    return (
        <Layout className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <div className={cn()}>
                        <Collapse
                            onChange={setActiveTabs}
                            activeKey={activeTabs}
                            expandIconPosition="right"
                            className={cn('collapse')}>
                            <Panel
                                header={(
                                    <b>
                                        <Icon component={ListIcon} />
                                        {t('protocol panel parameters')}
                                    </b>
                                )}
                                key="parameters">
                                <Parameters
                                    setSelectedUserNames={setSelectedUserNames}
                                    setSelectedFieldNames={setSelectedFieldNames}
                                    setSelectedYearNames={setSelectedYearNames}
                                    setSelectedProductivityNames={setSelectedProductivityNames}
                                    setGroupingMethod={setGroupingMethod}
                                    triedProtocol={triedProtocol}
                                    setTriedProtocol={setTriedProtocol}
                                    setSelectedProd={setSelectedProd}
                                    setSelectedYear={setSelectedYear}
                                    setSelectedField={setSelectedField}
                                    setOwnerName={setOwnerName}
                                    updateParentUsers={setUsersList}
                                    setSelectedOwner={setSelectedOwner}
                                    activeTabs={activeTabs}
                                    setActiveTabs={setActiveTabs} />
                            </Panel>

                            <Panel
                                disabled={generalProto?.agros?.length === 0}
                                header={(
                                    <b>
                                        <Icon component={CalculatorIcon} />
                                        {t('protocol panel agrochemical')}
                                    </b>
                                )}
                                key="agro">
                                <Agrochemical
                                    usersList={usersList}
                                    ownerName={ownerName}
                                    selectedAgros={selectedAgros}
                                    setSelectedAgros={setSelectedAgros}
                                    calculatorData={currentAgrochemicalData} />
                            </Panel>

                            <Panel
                                disabled={generalProto?.feds?.length === 0}
                                header={(
                                    <b>
                                        <Icon component={ListIcon} />
                                        {t('protocol panel fed')}
                                    </b>
                                )}
                                key="fed">
                                <Fed
                                    selectedFeds={selectedFeds}
                                    setSelectedFeds={setSelectedFeds}
                                    calculatorData={currentFedData} />
                            </Panel>

                            <Panel
                                disabled={generalProto?.imaps?.length === 0}
                                header={(
                                    <b>
                                        <Icon component={ListIcon} />
                                        {t('protocol panel imap')}
                                    </b>
                                )}
                                key="imap">
                                <Imap
                                    usersList={usersList}
                                    ownerName={ownerName}
                                    selectedImaps={selectedImaps}
                                    setSelectedImaps={setSelectedImaps}
                                    calculatorData={currentImapData} />
                            </Panel>

                            <Panel
                                disabled={generalProto?.fitos?.length === 0}
                                header={(
                                    <b>
                                        <Icon component={ListIcon} />
                                        {t('protocol panel phyto')}
                                    </b>
                                )}
                                key="fito">
                                <Phyto
                                    usersList={usersList}
                                    selectedPhytos={selectedPhytos}
                                    setSelectedPhytos={setSelectedPhytos}
                                    calculatorData={currentPhytoData} />
                            </Panel>
                        </Collapse>

                        <Row style={{ marginTop: '25px' }} align="center">
                            <Button onClick={() => setShowDetailsModal(true)}>
                                {t('detailed download button')}
                            </Button>
                            <Col span={1} />
                            <Button onClick={() => setIsModalOpen(true)} type="primary">
                                {t('preview protocol')}
                            </Button>
                        </Row>

                        <DetailsModal
                            currentPhytoParams={currentPhytoParams}
                            setCurrentPhytoParams={setCurrentPhytoParams}
                            currentAgroElements={currentAgroElements}
                            setCurrentAgroElements={setCurrentAgroElements}
                            currentAgroParams={currentAgroParams}
                            setCurrentAgroParams={setCurrentAgroParams}
                            currentFedParams={currentFedParams}
                            setCurrentFedParams={setCurrentFedParams}
                            currentImapParams={currentImapParams}
                            setCurrentImapParams={setCurrentImapParams}
                            currentSettings={currentSettings}
                            setCurrentSettings={setCurrentSettings}
                            isOpen={showDetailsModal}
                            setIsOpen={setShowDetailsModal} />

                        <PreviewModal
                            selectedProd={selectedProductivityNames?.join(', ')}
                            selectedYear={selectedYearNames?.join(', ')}
                            selectedField={selectedFieldNames?.join(', ')}
                            ownerName={selectedUserNames?.join(', ')}
                            usersList={usersList}
                            selectedFeds={selectedFeds}
                            selectedAgros={selectedAgros}
                            selectedImaps={selectedImaps}
                            selectedPhytos={selectedPhytos}
                            generateProtocol={handleDownloadProtocol}
                            setIsOpen={setIsModalOpen}
                            isOpen={isModalOpen} />
                    </div>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default GeneralProtocol;