import { combineReducers } from 'redux';

import loading from './loading';
import protocolList from './protocol-list';
import protocolItem from './protocol-item';
import calculatorNew from './calculator-new';
import protocolNew from './protocol-new';
import calculatorUpdated from './calculator-updated';
import protocolUpdated from './protocol-updated';
import fertilizerItem from './fertilizer-item';
import fertilizerList from './fertilizer-list';
import statusUpdate from './status-update';
import protocolDeleted from './protocol-deleted';

export default combineReducers({
    protocolDeleted,
    statusUpdate,
    loading,
    protocolList,
    protocolItem,
    calculatorNew,
    protocolNew,
    calculatorUpdated,
    protocolUpdated,
    fertilizerItem,
    fertilizerList
});
