import * as constants from './constants';

export const getDrugsPhyto = payload => ({
    type: constants.getDrugsPhyto.toString(),
    payload
});
export const getSubgroupsPhyto = payload => ({
    type: constants.getSubgroupsPhyto.toString(),
    payload
});
export const getSymptomsPhyto = payload => ({
    type: constants.getSymptomsPhyto.toString(),
    payload
});
export const getVegPhasesPhyto = payload => ({
    type: constants.getVegPhasesPhyto.toString(),
    payload
});

export const newAgentPhyto = payload => ({
    type: constants.newAgentPhyto.toString(),
    payload
});
export const newKindPhyto = payload => ({
    type: constants.newKindPhyto.toString(),
    payload
});
export const newDiseasePhyto = payload => ({
    type: constants.newDiseasePhyto.toString(),
    payload
});
export const newConnectionPhyto = payload => ({
    type: constants.newConnectionPhyto.toString(),
    payload
});
export const updatePhotoDescription = payload => ({
    type: constants.updatePhotoDescription.toString(),
    payload
});

export const addExternalContamination = payload => ({
    type: constants.addExternalContamination.toString(),
    payload
});

export const updateExternalContamination = payload => ({
    type: constants.updateExternalContamination.toString(),
    payload
});

export const updateInternalContamination = payload => ({
    type: constants.updateInternalContamination.toString(),
    payload
});

export const addInternalContamination = payload => ({
    type: constants.addInternalContamination.toString(),
    payload
});

export const getCausativeAgents = payload => ({
    type: constants.getCausativeAgents.toString(),
    payload
});

export const getCalculatorPhyto = payload => ({
    type: constants.getCalculatorPhyto.toString(),
    payload
});

export const getCalculatorsPhyto = payload => ({
    type: constants.getCalculatorsPhyto.toString(),
    payload
});

export const createCalculatorPhyto = payload => ({
    type: constants.createCalculatorPhyto.toString(),
    payload
});

export const createRegistryPhyto = payload => ({
    type: constants.createRegistryPhyto.toString(),
    payload
});

export const updateRegistryPhyto = payload => ({
    type: constants.updateRegistryPhyto.toString(),
    payload
});

export const getReproductionsPhyto = payload => ({
    type: constants.getReproductionsPhyto.toString(),
    payload
});

export const getVarietiesPhyto = payload => ({
    type: constants.getVarietiesPhyto.toString(),
    payload
});

export const removeCalculatorPhyto = payload => ({
    type: constants.removeCalculatorPhyto.toString(),
    payload
});

export const getCulturesPhyto = payload => ({
    type: constants.getCulturesPhyto.toString(),
    payload
});

export const getFertilizersPhyto = payload => ({
    type: constants.getFertilizersPhyto.toString(),
    payload
});

export const getRegionsPhyto = payload => ({
    type: constants.getRegionsPhyto.toString(),
    payload
});

export const getPurposesPhyto = payload => ({
    type: constants.getPurposesPhyto.toString(),
    payload
});

export const updateCalculatorPhyto = payload => ({
    type: constants.updateCalculatorPhyto.toString(),
    payload
});

export const addCausativeAgent = payload => ({
    type: constants.addCausativeAgent.toString(),
    payload
});

export const deleteProtocolPhyto = payload => ({
    type: constants.deleteProtocolPhyto.toString(),
    payload
});

export const getCausativeAgentItems = payload => ({
    type: constants.getCausativeAgentItems.toString(),
    payload
});

export const uploadRegistryFile = payload => ({
    type: constants.uploadRegistryFile.toString(),
    payload
});

export const removeProtoPhyto = payload => ({
    type: constants.removeProtoPhyto.toString(),
    payload
});

export const updateRegistryFiles = payload => ({
    type: constants.updateRegistryFiles.toString(),
    payload
});

export const addDisease = payload => ({
    type: constants.addDisease.toString(),
    payload
});

export const getProtocolsPhyto = payload => ({
    type: constants.getProtocolsPhyto.toString(),
    payload
});

export const getProtocolPhyto = payload => ({
    type: constants.getProtocolPhyto.toString(),
    payload
});

export const getUsersPhyto = payload => ({
    type: constants.getUsersPhyto.toString(),
    payload
});

export const getDiseasesPhyto = payload => ({
    type: constants.getDiseasesPhyto.toString(),
    payload
});

export const removeInternalContamination = payload => ({
    type: constants.removeInternalContamination.toString(),
    payload
});

export const removeExternalContamination = payload => ({
    type: constants.removeExternalContamination.toString(),
    payload
});