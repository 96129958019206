import * as constants from './constants';

export const getRequests = payload => ({
    type: constants.getRequests.toString(),
    payload
});

export const getRequest = payload => ({
    type: constants.getRequest.toString(),
    payload
});

export const addRequest = payload => ({
    type: constants.addRequest.toString(),
    payload
});

export const updateRequest = payload => ({
    type: constants.updateRequest.toString(),
    payload
});

export const removeRequest = payload => ({
    type: constants.removeRequest.toString(),
    payload
});

export const saveAndSetStatusRequest = payload => ({
    type: constants.saveAndSetStatusRequest.toString(),
    payload
});

export const setStatusRequest = payload => ({
    type: constants.setStatusRequest.toString(),
    payload
});

export const clearRequest = payload => ({
    type: constants.clearRequest.toString()
});

export const getTasks = payload => ({
    type: constants.getTasks.toString(),
    payload
});

export const getTask = payload => ({
    type: constants.getTask.toString(),
    payload
});

export const addTask = payload => ({
    type: constants.addTask.toString(),
    payload
});

export const uploadTaskFile = payload => ({
    type: constants.uploadTaskFile.toString(),
    payload
});

export const removeTask = payload => ({
    type: constants.removeTask.toString(),
    payload
});