import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form, Layout, Select, Spin, Upload, Tooltip,
    Modal, Input, InputNumber, Row, Col, notification,
    Popover, DatePicker, Slider, Popconfirm
} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    ExportOutlined,
    FilterOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, PanelLayout, Title } from '../../components';
import PanelHeader from '../../components/panel-layout/panel-header';
import PanelContent from '../../components/panel-layout/panel-content';
import { useClassName } from '../../utils/cn';
import SampleTable from './components/sample-table';

import './style.less';
import {
    getSamplesList,
    createSampleRow,
    deleteSampleRow,
    uploadSampleFile, getSampleById, editSampleRow, manageSampleRowPublication,
    getDownloadableDataset,
    createRowCopy
} from '../../models/sample-log/actions';
import {
    curCopyStatus,
    curDeleteCount,
    curDownloadableDataset,
    curNewRow,
    curPublicationStatus,
    curSamplePage,
    curSampleRow,
    curSamplesList, curUpdatedRow,
    curUploadStatus
} from '../../models/sample-log/selectors';
import SampleModal from './components/sample-modal';
import { curMycoFields } from '../../models/mycological-registry/selectors';
import { makeRequest, makeResponse } from './components/utils';
import UploadStatus from './components/upload-status';
import { getUser } from '../../models/account/selectors';
import ExcelExport from './components/excel-export';
import NewUserModal from './components/new-user-modal';
import DefaultSettings from './components/default-settings';

const columnsDefaultSelect = [
    'productivity_zone', 'zone_area', 'owner', 'public',
    'vega_key', 'field_num', 'point', 'date', 'analysis_date',
    'K', 'A', 'method', 'ph_water', 'electrical_conductivity', 'organic_substances',
    // 'NH4', 'K20', 'Na', 'Mg', 'Ca', 'Cl', 'SO4', 'N', 'PO4',
    'Mg_result', 'Ca_result', 'Cl_result', 'SO4_result', 'N_result', 'PO4_result', 'NH4_result', 'Na_result', 'K20_result',
    'S',
    'NO3', 'P2O5', 'comment', 'num', 'ph_salt'
];

const { confirm } = Modal;

const { RangePicker } = DatePicker;

const SampleLog = () => {
    const [numsForm] = Form.useForm();
    const [defaultsForm] = Form.useForm();
    const [form] = Form.useForm();
    const [filtersForm] = Form.useForm();

    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const { t } = useTranslation('sample log');

    const currentDate = new Date();

    const samplesPage = useSelector(state => curSamplePage(state));
    const downloadableDataset = useSelector(state => curDownloadableDataset(state));
    const currentSampleRow = useSelector(state => curSampleRow(state));
    const fields = useSelector(state => curMycoFields(state));
    const uploadStatus = useSelector(state => curUploadStatus(state));
    const newCreatedRow = useSelector(state => curNewRow(state));
    const deletedRow = useSelector(state => curDeleteCount(state));
    const updatedRow = useSelector(state => curUpdatedRow(state));
    const currentUser = useSelector(state => getUser(state));
    const pubStat = useSelector(state => curPublicationStatus(state));
    const copyStat = useSelector(state => curCopyStatus(state));

    const [showModal, setShowModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [currentSample, setCurrentSample] = useState({});
    const [currentUpload, setCurrentUpload] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isReportGenerating, setIsReportGenerating] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [columnOptions, setColumnOptions] = useState([]);
    const [activeColumns, setActiveColumns] = useState([]);
    const [filteredSel, setFilteredSel] = useState([]);
    const [defaultModalOpen, setDefaultModalOpen] = useState(false);
    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
    const [fullUserList, setFullUserList] = useState([]);
    const [publishDisabled, setPublishDisabled] = useState(true);
    const [unpublishDisabled, setUnpublishDisabled] = useState(true);
    const [fullSampleList, setFullSampleList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [samplePageSize, setSamplePageSize] = useState(10);
    // const [reRequest, setReRequest] = useState(false);

    const [filteredDataSet, setFilteredDataSet] = useState([]);
    const [copyingRow, setCopyingRow] = useState({});
    const [numberOfCopies, setNumberOfCopies] = useState([]);
    const [numConfirmOpen, setNumConfirmOpen] = useState(false);
    const [newlyCreatedRows, setNewlyCreatedRows] = useState([]);
    // const [newlyCreatedStorage, setNewlyCreatedStorage] = useState([]);
    const [displayTableFilters, setDisplayTableFilters] = useState(false);
    const [newRecordEntity, setNewRecordEntity] = useState([]);

    const isLaborant = () => {
        return currentUser?.groups?.includes('Laborant');
    };

    const [ownerOptions, setOwnerOptions] = useState([]);
    const [numFilter, setNumFilter] = useState(null);
    const [pointFilter, setPointFilter] = useState(null);
    const [acceptFilter, setAcceptFilter] = useState(null);
    const [analysisFilter, setAnalysisFilter] = useState(null);

    useEffect(() => {
        if (pubStat?.responseStatus === 'success' && Object.entries(pubStat)?.length > 1) {
            notification.success({
                message: 'Публикация пробы',
                description: 'Статус публикации пробы обновлен успешно!',
            });
        }

        if (pubStat?.responseStatus === 'error') {
            notification.error({
                message: 'Публикация пробы',
                description: 'При обновлении статуса публикации пробы произошла ошибка!',
            });
        }
    }, [pubStat]);

    useEffect(() => {
        if (copyStat?.responseStatus === 'success' && Object.entries(copyStat)?.length > 1) {
            notification.success({
                message: 'Копирование пробы',
                description: 'Копия пробы создана успешно!',
            });
        }

        if (copyStat?.responseStatus === 'error') {
            notification.error({
                message: 'Копирование пробы',
                description: 'При создании пробы произошла ошибка!',
            });
        }
    }, [copyStat]);

    useEffect(() => {
        setOwnerOptions(fullUserList?.map(user => {
            return {
                label: user?.name,
                value: user?.id
            };
        }));
    }, [fullUserList]);

    const handleResetFilters = () => {
        filtersForm?.resetFields();
        setNumFilter(null);
        setPointFilter(null);
        setAcceptFilter(null);
        setAnalysisFilter(null);
        setAdvancedFilters({
            num: false,
            owner: false,
            field_num: false,
            point: false,
        });
        if (currentPage === 1) {
            handleGetSamples(1);
        } else {
            setCurrentPage(1);
        }
    }

    const handleGetSamples = (pageArg) => {
        const filtersFormResult = filtersForm?.getFieldsValue();
        const { A, K, method, owner, field_num, vega_key, acceptation_date, analysis_date, num } = filtersFormResult;
        let filterArray = [];

        if (vega_key?.length > 0) {
            filterArray?.push(`"vega_key.icontains": "${vega_key}"`);
        }
        if (acceptation_date?.length > 0) {
            filterArray?.push(`"date.gte": "${acceptation_date[0]?.format('YYYY-MM-DD')}", "date.lte": "${acceptation_date[1]?.format('YYYY-MM-DD')}"`);
        }
        if (analysis_date?.length > 0) {
            filterArray?.push(`"analysis_date.gte": "${analysis_date[0]?.format('YYYY-MM-DD')}", "analysis_date.lte": "${analysis_date[1]?.format('YYYY-MM-DD')}"`);
        }
        if (A?.length > 0) {
            filterArray?.push(`"A": [${A?.join(',')}]`);
        }
        if (K?.length > 0) {
            filterArray?.push(`"K": [${K?.join(',')}]`);
        }
        if (method?.length > 0) {
            filterArray?.push(`"method": [${method?.join(',')}]`);
        }

        if (advancedFilters?.num) {
            if (num) {
                filterArray?.push(`"num.gte": ${num[0]}, "num.lte": ${num[1]}`);
            }
        } else {
            if (num) {
                filterArray?.push(`"num.icontains":"${num}"`);
            }
        }

        if (advancedFilters?.owner) {
            if (owner?.length > 0) {
                filterArray?.push(`"owner":[${owner?.join(',')}]`);
            }
        } else {
            if (owner?.length > 0) {
                const findableId = ownerOptions?.filter(x => x?.label?.toLowerCase()?.includes(owner?.toLowerCase()))[0]?.value;
                if (findableId) {
                    filterArray?.push(`"owner":${findableId}`);
                }
            }
        }

        if (advancedFilters?.point) {
            if (pointFilter) {
                filterArray?.push(`"point.gte": ${pointFilter[1]}, "point.lte": ${pointFilter[0]}`);
            }
        } else {
            if (pointFilter) {
                filterArray?.push(`"point":[${pointFilter}]`);
            }
        }

        dispatch(getSamplesList({ page: pageArg, pageSize: samplePageSize, filterString: filterArray?.length > 0 ? filterArray.join(',') : ''}));
        dispatch(getDownloadableDataset({ filterString: filterArray?.length > 0 ? filterArray.join(',') : '' }));
    };

    useEffect(() => {
        handleGetSamples(currentPage);
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [samplePageSize]);

    useEffect(() => {
        const selectedRowsActive = selectedRows?.filter(x => !x?.deleted);
        setFilteredSel(selectedRowsActive);

        if (selectedRows?.length > 0) {
            let allNotPublic = true;
            let allPublic = true;

            selectedRows?.forEach(rowId => {
                if (fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0] && fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0].public) {
                    allNotPublic = false;
                }
                if (fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0] && !fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0].public) {
                    allPublic = false;
                }
            });

            allPublic && setUnpublishDisabled(false) && setPublishDisabled(true);
            allNotPublic && setPublishDisabled(false) && setUnpublishDisabled(true);
        } else {
            setPublishDisabled(true);
            setUnpublishDisabled(true);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (deletedRow?.isLoading) {
            setIsLoading(deletedRow.isLoading);
        } else if (deletedRow && typeof deletedRow === 'object' && !(Object.values(deletedRow)?.length === 0)) {
            setTimeout(() => {
                handleGetSamples(currentPage);
                setIsLoading(false);
            }, 3000);
        } else if (typeof deletedRow !== 'object' && !deletedRow) {
            setTimeout(() => {
                handleGetSamples(currentPage);
                setIsLoading(false);
            }, 3000);
        }
    }, [deletedRow]);

    useEffect(() => {
        let alreadyUpdated = false;
        if (updatedRow?.responseStatus === 'success') {
            notification.success({
                message: 'Обновление пробы',
                description: 'Проба обновлена успешно!',
            });

            if (newCreatedRow?.id || updatedRow?.id) {
                const renewDataTimeout = setTimeout(() => {
                    handleGetSamples(currentPage);
                    clearTimeout(renewDataTimeout);
                }, 800);
            }
            alreadyUpdated = true;
        }

        if (!alreadyUpdated && newCreatedRow?.responseStatus === 'success') {
            notification.success({
                message: 'Создание пробы',
                description: 'Проба создана успешно!',
            });

            if (newCreatedRow?.id || updatedRow?.id) {
                const renewDataTimeout = setTimeout(() => {
                    handleGetSamples(currentPage);
                    clearTimeout(renewDataTimeout);
                }, 800);
            }
        }
        if (!alreadyUpdated && newCreatedRow?.responseStatus === 'error') {
            notification.error({
                message: 'Создание пробы',
                description: 'При создании пробы произошла ошибка!',
            });
        }

        if (updatedRow?.responseStatus === 'error') {
            notification.error({
                message: 'Обновление пробы!',
                description: 'При обновлении пробы произошла ошибка!',
            });
        }
        if (updatedRow?.status === 400 || newCreatedRow?.status === 400) {
            notification.error({
                message: 'Создание пробы',
                description: 'Строка с этим номером пробы уже существует!',
            });
        }

        // if (newCreatedRow?.id || updatedRow?.id) {
        //     const renewDataTimeout = setTimeout(() => {
        //         handleGetSamples(currentPage);
        //         clearTimeout(renewDataTimeout);
        //     }, 800);
        // }
    }, [updatedRow, newCreatedRow]);

    useEffect(() => {
        if (currentUpload.code === 200) {
            notification.success({
                message: 'Успешно!',
                // description: 'Строка с этим номером пробы уже существует.',
            });
        }
        if (currentUpload.code === 400) {
            notification.error({
                message: 'Ошибка!',
                // description: 'Строка с этим номером пробы уже существует.',
            });
        }
        if (currentUpload && currentUpload.code) {
            setTimeout(() => setCurrentUpload({}), 4000);
        }
    }, [currentUpload]);

    useEffect(() => {
        if (uploadStatus?.payload?.error === "Did not upload file") {
            notification.error({
                message: 'Ошибка!',
                // description: 'При выполнении загрузки файла произошла ошибка. Попробуйте еще раз.',
            });
        }
        if (typeof uploadStatus === 'object' && typeof uploadStatus.then === 'function') {
            uploadStatus.then(x => {
                setCurrentUpload({
                    code: 400,
                    error: JSON.parse(x).error
                });
            });
        } else if (uploadStatus?.id) {
            dispatch(editSampleRow({
                sampleRowId: uploadStatus?.id,
                body: {
                    from_file: true
                }
            }))
            setCurrentUpload({
                code: 200,
                error: t('successful status report')
            });

            handleGetSamples(currentPage);
        }
    }, [uploadStatus]);

    useEffect(() => {
        if (currentSampleRow?.id) {
            setCurrentSample(currentSampleRow);
            const responseValues = makeResponse(currentSampleRow);
            form.setFieldsValue(responseValues);
            if (isFullScreen) {
                setIsFullScreen(false);
            }
            setShowModal(true);
        }
    }, [currentSampleRow]);

    const handleAddRow = () => {
        form?.resetFields();
        setCurrentSample({});
        setShowModal(true);
    };

    const handleRowClick = (sampleRowId) => {
        dispatch(getSampleById({
            sampleRowId: sampleRowId
        }));
    };

    const handleCreateRow = () => {
        const formValues = form.getFieldsValue();
        const requestValues = makeRequest(formValues);
        if (currentSample?.id) {
            dispatch(editSampleRow({
                body: requestValues,
                sampleRowId: currentSample?.id
            }));
        } else {
            dispatch(createSampleRow(requestValues));
        }
        setShowModal(false);
    };

    const handleDeleteRow = () => {
        filteredSel?.length > 0 && filteredSel.forEach((rowId) => {
            setTimeout(() => dispatch(deleteSampleRow({ rowId: rowId })), 500);
        });
    };

    const onFormChange = (value) => {
        const updatedField = Object.keys(value)[0];
        const updatedValue = value[updatedField];

        const fieldsListArr = [];
        const fldKeysArr = Object.keys(fields);

        fldKeysArr.forEach(key => {
            if (key !== 'next') {
                fieldsListArr.push(fields[key]);
            }
        });

        switch (updatedField) {
            case 'dilution_factor':
                if (currentSample?.id) {
                    form?.submit();
                }
                break;
            case 'dist_water_volume':
                if (currentSample?.id) {
                    form?.submit();
                }
                break;
            case 'air_weigth':
                if (currentSample?.id) {
                    form?.submit();
                }
                break;
            case 'field_num':
                const selectedNum = fieldsListArr?.filter(x => x.field_num === updatedValue)[0];
                form?.setFieldsValue({
                    vega_key: selectedNum?.vega_key
                });
                break;
            case 'vega_key':
                const selectedVega = fieldsListArr?.filter(x => x.vega_key === updatedValue)[0];
                form?.setFieldsValue({
                    field_num: selectedVega?.field_num
                });
                break;
            default:
                break;
        }
    };

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            const { file } = reqParams;
            const rowItem = selectedRecords[0];
            if (rowItem?.from_file) {
                confirm({
                    title: 'Заменить данные "Капель"?',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Для выбранной пробы уже загружены данные "Капель". Заменить?',
                    onOk() {
                        return dispatch(uploadSampleFile({
                            uploadableFile: file,
                            analysisId: selectedRows[0] ? selectedRows[0] : undefined,
                            type: 'formdata'
                        }));
                    },
                    onCancel() {
                        return null;
                    },
                });
            } else {
                return dispatch(uploadSampleFile({
                    uploadableFile: file,
                    analysisId: selectedRows[0] ? selectedRows[0] : undefined,
                    type: 'formdata'
                }));
            }
        }
    };

    const handleGenerateReport = () => {
        setIsReportGenerating(true);

        fetch('/api/v1/agrochemical_analysis/protocol/download_protocol/', {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                analysis_ids: [...filteredSel]
            })
        }).then((resp) => {
            resp.blob().then((blb) => {
                const url = window.URL.createObjectURL(blb);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `[${currentDate.getDate()}.${currentDate.getMonth() + 1}.${ currentDate.getFullYear()}]_SampleAnalysis.docx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setIsReportGenerating(false);
            });
        });
    };

    const handleColumnUpdate = (valueUpdated) => {
        const filteredColumns = columnOptions?.filter(colOpt => valueUpdated?.includes(colOpt?.value));
        setActiveColumns(filteredColumns);
    };

    const handlePublication = (toPublish) => {
        if (selectedRows?.length > 0) {
            setIsLoading(true);
            selectedRows?.forEach(rowId => {
                if (toPublish) {
                    setTimeout(() => {
                        dispatch(manageSampleRowPublication({ rowId: rowId, public: true }));
                    }, 100);
                } else {
                    setTimeout(() => {
                        dispatch(manageSampleRowPublication({ rowId: rowId, public: false }));
                    }, 100);
                }
            });

            setTimeout(() => {
                // setSamplePage(1);
                // if (samplePage === 1) {
                //     handleGetSamples(1);
                // } else {
                //     setSamplePage(1);
                // }
                handleGetSamples(currentPage);
                // dispatch(getSamplesList({ page: 1 }));
            }, 3000);
            setTimeout(() => {
                setUnpublishDisabled(true);
                setPublishDisabled(true);
                setIsLoading(false);
            }, 3500);
        }
    };

    useEffect(() => {
        // if (activeColumns?.length > 0) {
        if (activeColumns?.length > 0 && columnOptions?.length < 1) {
            setColumnOptions(activeColumns);
        }
    }, [activeColumns]);

    useEffect(() => {
        if (!numConfirmOpen && copyingRow?.owner && numberOfCopies?.length > 0) {
            setNumConfirmOpen(true);

            setTimeout(() => {
                confirm({
                    title: t('line copy description'),
                    icon: <QuestionCircleOutlined />,
                    content: (
                        <Form form={numsForm}>
                            {numberOfCopies?.map((elmnt, idx) => (
                                <Form.Item
                                    label={`${t('copy num')} ${idx + 1}`}
                                    name={`num-${idx}`}>
                                    <Input />
                                </Form.Item>
                            ))}
                        </Form>
                    ),
                    onOk() {
                        const formVals = numsForm?.getFieldsValue();
                        const numsKeys = Object.keys(formVals);
                        const formValKeys = Object.values(formVals);
                        setNewlyCreatedRows(formValKeys.map(x => parseInt(x, 10)).sort((a, b) => a - b));
                        numsKeys?.forEach((key, idx) => {
                            setTimeout(() => {
                                dispatch(createRowCopy({
                                    num: formVals[key],
                                    A: copyingRow?.A,
                                    K: copyingRow?.K,
                                    air_weigth: copyingRow?.air_weigth,
                                    analysis_date: copyingRow?.analysis_date,
                                    date: copyingRow?.date,
                                    dilution_factor: copyingRow?.dilution_factor,
                                    dist_water_volume: copyingRow?.dist_water_volume,
                                    electrical_conductivity: copyingRow?.electrical_conductivity,
                                    field_num: copyingRow?.field_num,
                                    method: copyingRow?.method,
                                    organic_substances: copyingRow?.organic_substances,
                                    owner: copyingRow?.owner,
                                    ph_salt: copyingRow?.ph_salt,
                                    ph_water: copyingRow?.ph_water,
                                    point: copyingRow?.point,
                                    productivity_zone: copyingRow?.productivity_zone,
                                    vega_key: copyingRow?.vega_key,
                                    zone_area: copyingRow?.zone_area,
                                    from_file: false
                                }));
                            }, 300);
                        });
                        setCopyingRow({});
                        setNumberOfCopies([]);
                        setNumConfirmOpen(false);
                        setTimeout(() => {
                            handleGetSamples(currentPage);
                        }, 2000);
                    },
                    onCancel() {
                        setCopyingRow({});
                        setNumberOfCopies([]);
                        setNumConfirmOpen(false);
                    }
                });
            }, 500);
        }
    }, [copyingRow, numberOfCopies]);

    const handleCopy = () => {
        const setCopies = (copiesNum) => {
            setNumberOfCopies(Array(copiesNum).fill(0));
        };

        confirm({
            title: t('line copy title'),
            icon: <QuestionCircleOutlined />,
            content: (
                <>
                    <div style={{ marginBottom: '15px' }}>
                        {t('line copy amount')}
                    </div>

                    <InputNumber
                        min={0}
                        onChange={(copiesNum) => setCopies(copiesNum)} />
                </>
            ),
            onOk() {
                const foundRow = samplesPage?.results?.filter(x => x?.id === selectedRows[0])[0];
                setCopyingRow(foundRow);
            },
            onCancel() {
                setCopyingRow({});
                setNumberOfCopies([]);
            }
        });
    };

    const createExcelFilename = () => {
        return `SampleLogExcel_[${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}]`;
    };

    const publicationOptions = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
            <Button
                type="primary"
                onClick={() => handlePublication(true)}
                style={{
                    marginBottom: '5px',
                    marginTop: '5px',
                    marginLeft: '5px',
                    marginRight: '5px'
                }}>
                    Опубликовать
                {/* {t('report type registration')} */}
            </Button>
            <Button
                type="secondary"
                onClick={() => handlePublication(false)}
                style={{
                    marginBottom: '5px',
                    marginLeft: '5px',
                    marginRight: '5px'
                }}>
                    Снять с публикации
                {/* {t('report type result')} */}
            </Button>
        </div>
    );

    const [advancedFilters, setAdvancedFilters] = useState({
        num: false,
        owner: false,
        field_num: false,
        point: false,
    });

    const toggleFilter = (filterKey) => {
        const filtersCopy = { ...advancedFilters };
        filtersCopy[filterKey] = !filtersCopy[filterKey];
        setAdvancedFilters(filtersCopy);
    };

    const applyFilters = () => {
        setCurrentPage(1);
    };
    
    useEffect(() => {
        dispatch(getDownloadableDataset({}));
    }, []);
    

    return !isLaborant() ? (
        <></>
    ) : (
        <Layout className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <NewUserModal
                        isOpened={isNewUserModalOpen}
                        setOpened={setIsNewUserModalOpen} />
                    {isLoading && (
                        <Spin spinning={isLoading} />
                    )}
                    {!isLoading && (
                        <div>
                            <Row gutter={6}>
                                <Col span={4}>
                                    <Tooltip title='Создать новую пробу'>
                                        <Button
                                            onClick={() => {
                                                setNewRecordEntity([{
                                                    type: 'new record',
                                                    key: 'test123'
                                                }]);
                                            }}
                                            size="medium"
                                            style={{ width: '100%' }}
                                            type="primary">
                                            <PlusCircleOutlined />
                                            Добавить пробу
                                        </Button>
                                    </Tooltip>
                                </Col>

                                <Col span={5}>
                                    <Tooltip title='Изменить параметры вычислений'>
                                        <Button
                                            size="medium"
                                            style={{ width: '100%' }}
                                            type="secondary"
                                            onClick={() => setDefaultModalOpen(true)}>
                                            <SettingOutlined />
                                            {t('default modal settings')}
                                        </Button>
                                    </Tooltip>
                                </Col>

                                <Col span={4}>
                                    <ExcelExport
                                        filteredDataSet={downloadableDataset}
                                        apiData={downloadableDataset}
                                        fileName={createExcelFilename()} />
                                </Col>

                                <Col span={3}>
                                    <Tooltip title={selectedRows?.length < 1 ? 'Для выгрузки отчета выберите пробы в таблице' : 'Загрузить отчет'} trigger={'hover'}>
                                        <span>
                                        <Button
                                            size="medium"
                                            style={{ width: '100%' }}
                                            type="secondary"
                                            disabled={selectedRows?.length < 1}
                                            onClick={handleGenerateReport}>
                                            {isReportGenerating && (
                                                <Spin />
                                            )}
                                            {!isReportGenerating && (
                                                <>
                                                    <DownloadOutlined
                                                        className="buttons-row__top__first__icon" />
                                                    {t('generate report')}
                                                </>
                                            )}
                                        </Button>
                                        </span>
                                    </Tooltip>
                                </Col>

                                <Col span={1} />

                                <Col span={4}>
                                    {selectedRows?.length !== 1 ? (
                                        <Tooltip title='Для загрузки данных с "Капель" выберите пробу в таблице'>
                                            <span>
                                            <Button size="medium" style={{ width: '98%' }} disabled>
                                                <PlusCircleOutlined />
                                                Данные с "Капель"
                                            </Button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title='Загрузить данные с "Капель" для выбранной пробы'>
                                            <Upload {...uploadProps} maxCount={2} showUploadList={false}>
                                                <Button size="medium" style={{ width: '98%' }}>
                                                    <PlusCircleOutlined />
                                                    Данные с "Капель"
                                                </Button>
                                            </Upload>
                                        </Tooltip>
                                    )}
                                </Col>

                                <Col span={1}>
                                    {selectedRows?.length !== 1 ? (
                                        <Tooltip title='Для копирования пробы выберите пробу в таблице'>
                                            <span>
                                            <Button
                                                size="medium"
                                                style={{ height: '100%' }}
                                                disabled={selectedRows?.length !== 1}
                                                icon={<CopyOutlined />}
                                                onClick={handleCopy}
                                                type="secondary">
                                                    {/* {t('copy row button text')} */}
                                            </Button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title='Копировать пробу'>
                                            <Button
                                                size="medium"
                                                style={{ height: '100%' }}
                                                disabled={selectedRows?.length !== 1}
                                                icon={<CopyOutlined />}
                                                onClick={handleCopy}
                                                type="secondary">
                                                    {/* {t('copy row button text')} */}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Col>

                                <Col span={1}>
                                    {selectedRows?.length < 1 ? (
                                        <Tooltip title='Для публикации/снятия с публикации проб выберите пробы в таблице'>
                                            <span>
                                            <Button
                                                size="medium"
                                                disabled={publishDisabled}
                                                icon={<ExportOutlined />}
                                                // onClick={() => handlePublication(true)}
                                                type="secondary" />
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Popover
                                            placement="bottom"
                                            content={publicationOptions}
                                            trigger="click">
                                                <Tooltip title='Опубликовать/Снять с публикации пробу'>
                                                    <Button
                                                        size="medium"
                                                        icon={<ExportOutlined />}
                                                        type="secondary" />
                                                </Tooltip>
                                        </Popover>
                                    )}
                                </Col>

                                <Col span={1}>
                                    {selectedRows?.length < 1 ? (
                                        <Popconfirm
                                            title="Подтвердите удаление выбранных проб"
                                            // onConfirm={handleDeleteRow}
                                            onConfirm={() => console.log('Confirm')}
                                            onCancel={() => {}}
                                            okText="Подтвердить"
                                            cancelText="Отмена"
                                        >
                                            <Tooltip title='Для удаления проб выберите пробы в таблице'>
                                                <span>
                                                <Button
                                                    size="medium"
                                                    danger
                                                    type="secondary"
                                                    disabled={selectedRows?.length < 1}
                                                    icon={<DeleteOutlined />} />
                                                {/* <Button
                                                    danger
                                                    type="secondary"
                                                    disabled={selectedRows?.length < 1}
                                                    icon={<DeleteOutlined />}
                                                    onClick={handleDeleteRow} /> */}
                                                </span>
                                            </Tooltip>
                                        </Popconfirm>
                                    ) : (
                                        <Popconfirm
                                            placement="rightBottom"
                                            title={`Подтвердите удаление ${filteredSel?.length} проб`}
                                            onConfirm={handleDeleteRow}
                                            onCancel={() => {}}
                                            okText="Подтвердить"
                                            cancelText="Отмена"
                                        >
                                            <Tooltip title='Удалить пробы'>
                                                <Button
                                                    size="medium"
                                                    danger
                                                    type="secondary"
                                                    disabled={selectedRows?.length < 1}
                                                    icon={<DeleteOutlined />} />
                                            </Tooltip>
                                        </Popconfirm>
                                    )}
                                </Col>
                            </Row>

                            <Form form={defaultsForm}>
                                <DefaultSettings
                                    setIsOpened={setDefaultModalOpen}
                                    form={defaultsForm}
                                    isOpened={defaultModalOpen} />
                            </Form>

                            <div style={{ marginBottom: '15px' }}>
                                
                            </div>
                            <div
                                style={{
                                    marginBottom: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <Button size="medium" ghost onClick={() => setDisplayTableFilters(!displayTableFilters)}>
                                        <FilterOutlined />
                                        {displayTableFilters ? 'Скрыть фильтры' : 'Показать фильтры'}
                                    </Button>

                                <div className="column-select">
                                    <div style={{ margin: 'auto', marginRight: '15px' }}>
                                        {t('column selector header')}
                                    </div>
                                    <Select
                                        style={{
                                            maxWidth: '135px'
                                        }}
                                        maxTagCount="responsive"
                                        onChange={handleColumnUpdate}
                                        mode="multiple"
                                        defaultValue={columnsDefaultSelect}>
                                            {columnOptions?.map(x => <Select.Option key={x?.value} value={x?.value}>{x?.label}</Select.Option>)}
                                    </Select>
                                </div>
                            </div>

                            {displayTableFilters && (
                                <>
                                    <Form form={filtersForm} labelCol={{ span: 24 }}>
                                        <Row gutter={24} style={{ marginBottom: '15px' }}>
                                            <Col span={6}>
                                                {/* Инпут + ренджпикер */}
                                                <Row gutter={12}>
                                                    <Col span={22}>
                                                        {advancedFilters?.num ? (
                                                            <Form.Item name="num" label="Номер пробы">
                                                                <Row align="middle">
                                                                    <Col span={2}>{numFilter?.[0] || 0}</Col>
                                                                    <Col span={20}>
                                                                        <Slider
                                                                            tooltip={{ open: true }}
                                                                            onChange={(rangeValues) => {
                                                                                setNumFilter(rangeValues);
                                                                            }}
                                                                            range
                                                                            min={0}
                                                                            max={1000}
                                                                            defaultValue={[0, 1000]}  />
                                                                    </Col>
                                                                    <Col span={2}>{numFilter?.[1] || 1000}</Col>
                                                                </Row>
                                                            </Form.Item>
                                                        ) : (
                                                            <Form.Item name="num" label="№ пробы">
                                                                <Input onChange={(evt) => setNumFilter(evt?.target?.value)} allowClear />
                                                            </Form.Item>
                                                        )}
                                                    </Col>
                                                    <Col span={2}>
                                                        <a style={{ fontSize: '12px' }} onClick={() => toggleFilter('num')}><SettingOutlined /></a>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={6}>
                                                {/* Инпут + select */}
                                                <Row gutter={12}>
                                                    <Col span={22}>
                                                        {advancedFilters?.owner ? (
                                                            <Form.Item name="owner" label="Заказчик">
                                                                <Select mode='multiple' allowClear options={ownerOptions}
                                                                    filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                // || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                } />
                                                            </Form.Item>
                                                        ) : (
                                                            <Form.Item name="owner" label="Заказчик">
                                                                <Input allowClear />
                                                            </Form.Item>
                                                        )}
                                                    </Col>
                                                    <Col span={2}>
                                                        <a style={{ fontSize: '12px' }} onClick={() => toggleFilter('owner')}><SettingOutlined /></a>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={6}>
                                                {/* Инпут */}
                                                <Form.Item name="vega_key" label="Поле (название)">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Row gutter={12}>
                                                    <Col span={22}>
                                                        <Form.Item name="field_num" label="Поле №">
                                                            <Input allowClear />
                                                        </Form.Item>
                                                        {/* {advancedFilters?.field_num ? (
                                                            <Form.Item name="field_num" label="Поле (номер)">
                                                                <Select allowClear options={[]} />
                                                            </Form.Item>
                                                        ) : (
                                                            <Form.Item name="field_num" label="Поле (номер)">
                                                                <Input allowClear />
                                                            </Form.Item>
                                                        )} */}
                                                    </Col>
                                                    {/* <Col span={2}>
                                                        <a style={{ fontSize: '12px' }} onClick={() => toggleFilter('field_num')}>&#10227;</a>
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                            <Col span={6}>
                                                <Row gutter={12}>
                                                    <Col span={22}>
                                                        {advancedFilters?.point ? (
                                                            <Form.Item name="point" label="№ точки">
                                                                <Row align="middle">
                                                                    <Col span={2}>{pointFilter?.[0] || 0}</Col>
                                                                    <Col span={20}>
                                                                        <Slider
                                                                            onChange={(rangeValues) => {
                                                                                setPointFilter(rangeValues);
                                                                            }}
                                                                            range
                                                                            min={0}
                                                                            max={1000}
                                                                            defaultValue={[0, 1000]}  />
                                                                    </Col>
                                                                    <Col span={2}>{pointFilter?.[1] || 1000}</Col>
                                                                </Row>
                                                            </Form.Item>
                                                        ) : (
                                                            <Form.Item name="point" label={t('table header point num')}>
                                                                <Input onChange={(evt) => setPointFilter(evt?.target?.value)} allowClear placeholder="Номер точки отбора" />
                                                            </Form.Item>
                                                        )}
                                                    </Col>
                                                    <Col span={2}>
                                                        <a style={{ fontSize: '12px' }} onClick={() => toggleFilter('point')}><SettingOutlined /></a>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={6}>
                                                {/* Дейтпикер от и до */}
                                                <Form.Item name="acceptation_date" label="Дата приема">
                                                    <RangePicker onChange={setAcceptFilter} format="DD.MM.YYYY" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                {/* Дейтпикер от и до */}
                                                <Form.Item name="analysis_date" label="Анализ на капель">
                                                    <RangePicker onChange={setAnalysisFilter} format="DD.MM.YYYY" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                {/* Селект */}
                                                <Form.Item name="K" label="K">
                                                    <Select
                                                        maxTagCount="responsive"
                                                        allowClear
                                                        mode='multiple'
                                                        options={[
                                                            {
                                                                label: '0',
                                                                value: 0
                                                            },
                                                            {
                                                                label: '1',
                                                                value: 1
                                                            },
                                                        ]} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                {/* Селект */}
                                                <Form.Item name="A" label="A">
                                                    <Select
                                                        maxTagCount="responsive"
                                                        allowClear
                                                        mode='multiple'
                                                        options={[
                                                            {
                                                                label: '0',
                                                                value: 0
                                                            },
                                                            {
                                                                label: '1',
                                                                value: 1
                                                            },
                                                        ]} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                {/* Селект */}
                                                <Form.Item name="method" label="Методика">
                                                    <Select
                                                        maxTagCount="responsive"
                                                        allowClear
                                                        mode='multiple'
                                                        options={[
                                                            {
                                                                label: 'Чириков',
                                                                value: 'chirikov'
                                                            },
                                                            {
                                                                label: 'Кирсанов',
                                                                value: 'kirsanov'
                                                            },
                                                            {
                                                                label: 'Мачигин',
                                                                value: 'machigin'
                                                            }
                                                        ]} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    
                                    <Row gutter={24} style={{ marginBottom: '15px' }}>
                                        <Col span={3}>
                                            <Button size="medium" onClick={applyFilters} type="primary">
                                                Применить
                                            </Button>
                                        </Col>
                                        <Col span={2}>
                                            <Button size="medium" onClick={handleResetFilters} type="secondary">
                                                Очистить
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Spin style={{ display: !samplesPage?.isLoading ? 'none' : '' }} spinning={samplesPage?.isLoading} />
                            <SampleTable
                                style={{ display: samplesPage?.isLoading ? 'none' : '' }}
                                fullUsersList={fullUserList}
                                setFullUsersList={setFullUserList}
                                newRecordEntity={newRecordEntity}
                                setNewRecordEntity={setNewRecordEntity}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                filteredDataSet={samplesPage?.results}
                                setUserList={setFullUserList}
                                setIsNewUserModalOpen={setIsNewUserModalOpen}
                                isFullScreen={isFullScreen}
                                setSelectedRecords={setSelectedRecords}
                                selectedRows={selectedRows}
                                selectRows={setSelectedRows}
                                columnOptions={columnOptions}
                                setColumnOptions={setColumnOptions}
                                tableData={samplesPage?.results}
                                dataLength={samplesPage?.count}
                                pageSize={samplePageSize}
                                setPageSize={setSamplePageSize}
                                activeColumnOptions={activeColumns}
                                setActiveColumnOptions={setActiveColumns}
                                clickRow={handleRowClick}
                                addRow={handleAddRow} />
                            <Form
                                form={form}
                                onFinish={handleCreateRow}
                                onValuesChange={onFormChange}>
                                <SampleModal
                                    fullUserList={fullUserList}
                                    currentSample={currentSample}
                                    form={form}
                                    handleOk={() => form?.submit()}
                                    handleCancel={() => {
                                        form.resetFields();
                                        setShowModal(false);
                                    }}
                                    isOpened={showModal} />
                            </Form>
                            {currentUpload?.code && (
                                <UploadStatus
                                    statusCode={currentUpload?.code}
                                    statusText={currentUpload?.error} />
                            )}
                        </div>
                    )}
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default SampleLog;