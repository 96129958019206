import React, { useEffect, useState, useRef } from 'react';
import {
    Spin
} from 'antd';

import { useTranslation } from 'react-i18next';

import '../style.less';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SelectableTable from '../../../components/selectable-table';
import { useDispatch, useSelector } from 'react-redux';
import { getProtocolList } from '../../../models/fed-registry/actions';
import { curNewInitialProtocol, curProtocolList, curPublicationStatus } from '../../../models/fed-registry/selectors';
import { isLoading } from '../../../models/account/selectors';

const RegistriesTable = ({
    selectedItems, setSelectedItems,
    currentPageSize, setCurrentPageSize,
    currentPage, setCurrentPage,
    sortingOrder, setSortingOrder
}) => {
    const { t } = useTranslation('fed registry');
    const history = useHistory();

    const protocolItems = useSelector(state => curProtocolList(state));

    const reformatDate = (inpDate) => {
        const dateSplit = inpDate?.length > 0 ? inpDate?.split('-') : [];
        return dateSplit?.length > 0 ? `${dateSplit[2]}.${dateSplit[1]}.${dateSplit[0]}` : '';
    };

    const handleOpenRegistry = (record) => {
        return {
            onClick: () => {
                history.push(`/fed-registry/${record?.id}`);
                // setTimeout(() => window.location.reload(), 200);
            }
        };
    };

    const columns = [
        {
            title: t('protocol num'),
            dataIndex: 'protocol_number',
            key: 'protocol_number',
            sorter: (a, b) => a.protocol_number - b.protocol_number,
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol creation date'),
            dataIndex: 'date_created',
            key: 'date_created',
            sorter: (a, b) => moment(a.date_created) - moment(b.date_created),
            render: (val) => reformatDate(val),
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol owner'),
            dataIndex: ['owner', 'name'],
            key: 'owner',
            // filters: getUserFilters,
            filterSearch: true,
            onFilter: (value, record) => record?.owner?.name?.toLowerCase().startsWith(value?.toLowerCase()),
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol points'),
            dataIndex: 'field_points',
            key: 'field_points',
            render: val => val?.length > 0 ? val?.join(', ') : '',
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol sample date'),
            dataIndex: 'acceptation_date',
            key: 'acceptation_date',
            sorter: (a, b) => moment(a.acceptation_date) - moment(b.acceptation_date),
            render: (val) => reformatDate(val),
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol examination date'),
            dataIndex: 'examination_date',
            key: 'examination_date',
            sorter: (a, b) => moment(a.examination_date) - moment(b.examination_date),
            render: (val) => reformatDate(val),
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol field num'),
            dataIndex: 'field_num',
            sorter: (a, b) => a.field_num - b.field_num,
            key: 'field_num',
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol culture'),
            dataIndex: ['culture', 'name'],
            sorter: (a, b) => a?.culture?.name - b?.culture?.name,
            key: '',
            onCell: (record, rowIndex) => handleOpenRegistry(record)
        },
        {
            title: t('protocol status'),
            dataIndex: '',
            key: 'status',
            render: (value, record) => {
                return record?.archived ? t('status archived') : record?.public ? t('status published') : t('status edited');
            }
        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter?.order?.length > 0 && (sorter?.columnKey?.length > 0 || sorter?.field?.length > 0)) {
            const constructKey = sorter?.columnKey?.length > 0 ? sorter?.columnKey : sorter?.field?.join('.');
            setSortingOrder([constructKey, sorter?.order]);
        } else if (!sorter?.order && (sorter?.columnKey?.length > 0 || sorter?.field?.length > 0)) {
            setSortingOrder([]);
        }
    };

    return (
        <SelectableTable
            tableScroll={{ y: 450, x: 1500 }}
            onChange={handleTableChange}
            // onRowClick={handleOpenRegistry}
            isBordered={false}
            isLoading={protocolItems?.isLoading}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            tableColumns={columns}
            tablePage={currentPage}
            tableTotal={protocolItems?.total}
            setTablePage={setCurrentPage}
            tablePageSize={currentPageSize}
            setTablePageSize={setCurrentPageSize}
            tableData={protocolItems?.results} />
    );
};

export default RegistriesTable;