import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import {
    Button, Dropdown, Input, Menu, Space
} from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Table } from '../../../components';

import { selectors } from '../../../models';

import '../style.less';
import * as constants from '../../../constants';

const { tableByFields, tableTotalByFields } = selectors;
export default ({ setCurField, curField }) => {
    const { t } = useTranslation('pivot table');
    const cn = useClassName('calculator');
    const field = useSelector(state => tableByFields(state));
    const total = useSelector(state => tableTotalByFields(state));

    const [dataSource, setDataSource] = useState([]);
    const [dataSourceTotal, setDataSourceTotal] = useState([]);
    const [rowSpan, setRowSpan] = useState({
        rowSpan0: [],
        rowSpanNot0: [],
        rowSpanCount: {}
    });


    const match = useRouteMatch();
    const history = useHistory();

    const getMacro = (fertilizer) => {
        const arr = [];
        constants.macroTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item, { value: fertilizer[item] })); }
        });
        const str = arr.join(', ');
        return str;
    };

    const setFieldNumber = (id) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
        setCurField(id);
    };

    const convertedFields = (data = []) => {
        const arr = [];
        const rowSpanInfo = {
            rowSpan0: [],
            rowSpanNot0: [],
            rowSpanCount: {}
        };
        let index = 0;
        data?.length > 0 && data.forEach(item => {
            const { fertilizers = [] } = item;
            fertilizers?.length > 0 && fertilizers.forEach((fertilizer, i) => {
                arr.push({
                    fertilizer: fertilizer?.fertilizer,
                    weight_norm: fertilizer.weight_norm,
                    weight_by_area: fertilizer.weight_by_area,
                    price: fertilizer.price,
                    total_price: fertilizer.total_price,
                    ...item
                });
                if (fertilizers.length > 1 && i > 0) {
                    rowSpanInfo.rowSpan0.push(index);
                }
                if (fertilizers.length > 1 && i === 0) {
                    rowSpanInfo.rowSpanNot0.push(index);
                    rowSpanInfo.rowSpanCount[index] = fertilizers.length;
                }
                index++;
            });
        });

        return { arr, rowSpanInfo };
    };

    useEffect(() => {
        const data = convertedFields(field);
        setDataSource(data.arr);
        setRowSpan(data.rowSpanInfo);
    }, [field]);

    useEffect(() => {
        const filteredTotal = total.filter(item => (item.area || item.weight));
        setDataSourceTotal(filteredTotal);
    }, [total]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    let searchInput;

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={t(`search ${dataIndex}`)}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }} />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 110 }}>
                        {t('search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 110 }}>
                        {t('reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            if (dataIndex === 'culture' || dataIndex === 'fertilizer') {
                return (record[dataIndex]?.name
                    ? record[dataIndex]?.name.toString().toLowerCase().includes(value.toLowerCase())
                    : '');
            }
            return (record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '');
        }
    });

    const requestsColumns = [
        {
            title: t('field number'),
            dataIndex: 'field_number',
            key: 'fields_number',
            fixed: 'left',
            render: (value, record, index) => {
                if (rowSpan.rowSpan0.includes(index)) {
                    return { props: { rowSpan: 0 } };
                }
                const props = rowSpan.rowSpanNot0.includes(index) ? { rowSpan: rowSpan.rowSpanCount[index] } : null;
                return {
                    children:
                        <div className={`${cn('link-item-pivot')} ${curField === value && cn('link-item-pivot-active')}`} id={value}>
                            {value}
                            <Dropdown overlay={(
                                <Menu>
                                    <Menu.Item onClick={() => setFieldNumber(value)}>
                                        {t('show on map')}
                                    </Menu.Item>
                                    {/* <Menu.Item onClick={() => history.push(`/mordovia/${value}`)}> */}
                                    <Menu.Item onClick={() => history.push(`/representation/${value}`)}>
                                        {t('go to field')}
                                    </Menu.Item>
                                </Menu>
                            )}>
                                <Button><DownOutlined /></Button>
                            </Dropdown>
                        </div>,
                    props
                };
            },
            ...getColumnSearchProps('field_number')
        },
        {
            title: t('culture'),
            dataIndex: 'culture',
            key: 'culture',
            render: (value, record, index) => {
                if (rowSpan.rowSpan0.includes(index)) {
                    return { props: { rowSpan: 0 } };
                }
                if (rowSpan.rowSpanNot0.includes(index)) {
                    return {
                        children: value?.name,
                        props: { rowSpan: rowSpan.rowSpanCount[index] }
                    };
                }
                return {
                    children: value?.name
                };
            },
            ...getColumnSearchProps('culture')
        },
        {
            title: t('area'),
            dataIndex: 'area',
            key: 'area',
            render: (value, record, index) => {
                if (rowSpan.rowSpan0.includes(index)) {
                    return { props: { rowSpan: 0 } };
                }
                if (rowSpan.rowSpanNot0.includes(index)) {
                    return {
                        children: value?.toLocaleString('ru-RU'),
                        props: { rowSpan: rowSpan.rowSpanCount[index] }
                    };
                }
                return {
                    children: value?.toLocaleString('ru-RU')
                };
            }
        },
        {
            title: t('fertilizer_name'),
            dataIndex: 'fertilizer',
            key: 'fertilizer_name',
            render: value => value.name,
            ...getColumnSearchProps('fertilizer')
        },
        {
            title: t('fertilizer_content'),
            dataIndex: 'fertilizer',
            key: 'fertilizer_content',
            render: (value, record) => getMacro(record.fertilizer)
        },
        {
            title: t('fertilizer_group'),
            dataIndex: 'fertilizer',
            key: 'fertilizer_group',
            render: value => ({
                children: value?.group?.name,
                props: {}
            })
        },
        {
            title: t('norm'),
            children: [
                {
                    title: t('weight_norm'),
                    dataIndex: 'weight_norm',
                    key: 'weight_norm',
                    render: value => ({
                        children: value?.toLocaleString('ru-RU'),
                        props: {}
                    })
                },
                {
                    title: t('weight_by_area'),
                    dataIndex: 'weight_by_area',
                    key: 'weight_by_area',
                    render: value => ({
                        children: value?.toLocaleString('ru-RU'),
                        props: {}
                    })
                }
            ]
        },
        {
            title: t('price'),
            dataIndex: 'price',
            key: 'price',
            render: value => ({
                children: value?.toLocaleString('ru-RU'),
                props: {}
            })
        },
        {
            title: t('total_price'),
            dataIndex: 'total_price',
            key: 'total_price',
            render: value => ({
                children: value?.toLocaleString('ru-RU'),
                props: {}
            })
        }
    ];

    const requestsColumnsTotal = [
        {
            title: t('total area'),
            dataIndex: 'area',
            render: value => value?.toLocaleString('ru-RU')
        },
        {
            title: t('fertilizer_name'),
            dataIndex: 'fertilizer',
            render: value => value?.name
        },
        {
            title: t('total weight'),
            dataIndex: 'weight',
            render: value => value?.toLocaleString('ru-RU')
        },
        {
            title: t('total field price'),
            dataIndex: 'price',
            render: value => value?.toLocaleString('ru-RU')
        }
    ];

    return (
        <div>
            <div className={cn('row-table')}>
                <Table
                    id="field-report"
                    scroll={{ y: 400, x: 2200 }}
                    dataSource={dataSource}
                    columns={requestsColumns}
                    pagination={false} />
            </div>
            <div className={cn('row-table')}>
                <Table
                    id="field-report-total"
                    dataSource={dataSourceTotal}
                    scroll={{ y: 240, x: 1300 }}
                    columns={requestsColumnsTotal}
                    pagination={false} />
            </div>
        </div>
    );
};