import React, { useState } from 'react';
import { Carousel } from 'antd';
import { useClassName } from 'utils/cn';

import './style.less';

export default React.forwardRef(({ children, ...props }, ref) => {
    const cn = useClassName('component-carousel');
    const [curIndex, setCurIndex] = useState(0);
    return (
        <div className={cn()}>
            <Carousel ref={ref}
                customPaging={i => (
                    <button
                        style={{
                            backgroundColor: curIndex > i ? '@primary' : '#E6F2EB'
                        }}>

                        {i + 1}
                    </button>
                )}
                afterChange={i => {
                    setCurIndex(i);
                }}
                {...props}>
                {children}
            </Carousel>
        </div>
    );
});
