import * as constants from '../constants';

const initialState = [1, 2, 3, 4];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
