import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, Table, Tooltip } from 'antd';


import './style.less';
import {
    calculateDiagnostics,
    getLeafFieldPoints
} from '../../../../models/leaf-diagnostics/actions';

import Diagnostics from './diagnostics';
import Comparison from './comparison';
import { addGroupCalculate } from '../../../../models/mordovia/actions';
import { getCurrentCalculatorMordovia } from '../../../../models/mordovia/selectors';
import { curLeafResults } from '../../../../models/leaf-diagnostics/selectors';
import moment from 'moment';
import { getProtocol } from '../../../../models/fed-registry/actions';
import { curProtocol } from '../../../../models/fed-registry/selectors';
import { toFixed } from '../../../../utils/utils';

const { TabPane } = Tabs;

const elementsArray = [
    'k2', 'n', 'p', 'ks', 'kcl', 'k3', 'ca', 'mg', 'b',
    'cu', 'k4', 'zn', 'mn', 'fe', 'mo', 'k5', 'co', 'i', 'k6'
];

const LeafDiagnostics = ({ selectedField = {}, isCalc = false, currentCalc, setCurrentCalc }) => {
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const currentProtocolItem = useSelector(state => curProtocol(state));

    const { t } = useTranslation('leaf diagnostics');

    const [fieldRequestDate, setFieldRequestDate] = useState('');
    const [fertilizerRecommendation, setFertilizerRecommendation] = useState([]);

    useEffect(() => {
        setFertilizerRecommendation(currentProtocolItem?.fed_fertilizers)
    }, [currentProtocolItem]);

    useEffect(() => {
        console.log('fertilizerRecommendation', fertilizerRecommendation);
    }, [fertilizerRecommendation]);
    
    

    useEffect(() => {
        if (isCalc) {
            dispatch(getLeafFieldPoints({
                vega_key: selectedField?.vega_key
            }));
            // dispatch(addGroupCalculate({
            //     vega_key: selectedField?.vega_key,
            //     body: {
            //         // year: selectedField?.years?.length > 0 ? selectedField?.years[0] : 2021,
            //         year: selectedField?.year_group,
            //         method: 'kirsanov',
            //         type: 'field'
            //     }
            // }));
        }
    }, [selectedField]);

    useEffect(() => {
        if (fieldRequestDate && fieldRequestDate.length > 0) {
            dispatch(calculateDiagnostics({
                vega_key: isCalc ? selectedField?.vega_key : match.params.id,
                body: {
                    type: 'field',
                    date: fieldRequestDate
                }
            }));
        }
    }, [fieldRequestDate]);

    useEffect(() => {
        if (!isCalc) {
            dispatch(getLeafFieldPoints({
                vega_key: match.params.id
            }));

            if (currentCalc?.fed_protocol && currentCalc?.fed_protocol > 0) {
                console.log('DISPATCHING WITH DATA', currentCalc?.fed_protocol);
                dispatch(getProtocol({
                    id: currentCalc?.fed_protocol
                }));
            }
        }
    }, []);

    const columns = [
        {
            title: t('name'),
            dataIndex: ['fertilizer', 'name'],
            key: 'name',
            render: (text, record) => (
                <Tooltip
                    placement="bottomLeft"
                    title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: t('table elements header group'),
            children: elementsArray?.map(x => {
                return {
                    title: x,
                    dataIndex: ['fertilizer', x?.toLowerCase()],
                    key: x?.toLowerCase(),
                    render: value => toFixed(value, 2)
                };
            })
        },
        {
            title: t('table fertilizer price group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: ['fertilizer', 'consumption_rate_min'],
                    key: 'consumption_rate_min'
                },
                {
                    title: t('table max'),
                    dataIndex: ['fertilizer', 'consumption_rate_max'],
                    key: 'consumption_rate_max'
                }
            ]
        },
        {
            title: t('avg_price'),
            dataIndex: ['price'],
            key: 'avg_price',
            width: 140,
            sortType: 'number'
        },
        {
            title: t('table consumption header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'calc_cons_rate_min',
                    key: 'calc_cons_rate_min',
                    width: 100
                },
                {
                    title: t('table max'),
                    dataIndex: 'calc_cons_rate_max',
                    key: 'calc_cons_rate_max',
                    width: 100
                },
                {
                    title: t('table opt'),
                    dataIndex: 'calc_cons_rate_opt',
                    key: 'calc_cons_rate_opt',
                    width: 100
                }
            ]
        },
        {
            title: t('table price header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'calc_price_min',
                    key: 'calc_price_min',
                    width: 100
                },
                {
                    title: t('table max'),
                    dataIndex: 'calc_price_max',
                    key: 'calc_price_max',
                    width: 100
                },
                {
                    title: t('table opt'),
                    dataIndex: 'calc_price_opt',
                    key: 'calc_price_opt',
                    width: 100
                }
            ]
        },
        {
            title: t('table price area header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'area_price_min',
                    key: 'area_price_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table max'),
                    dataIndex: 'area_price_max',
                    key: 'area_price_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table opt'),
                    dataIndex: 'area_price_opt',
                    key: 'area_price_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        {
            title: t('table norm area header group'),
            children: [
                {
                    title: t('table min'),
                    dataIndex: 'area_cons_rate_min',
                    key: 'area_cons_rate_min',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table max'),
                    dataIndex: 'area_cons_rate_max',
                    key: 'area_cons_rate_max',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                },
                {
                    title: t('table opt'),
                    dataIndex: 'area_cons_rate_opt',
                    key: 'area_cons_rate_opt',
                    width: 100,
                    render: (value) => toFixed(value, 2)
                }
            ]
        },
        {
            title: t('table rate'),
            dataIndex: 'rate',
            key: 'rate'
        }
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane
                    tab={t('diagnostics tab')}
                    key="1">
                    <Diagnostics
                        setSelectedFed={setCurrentCalc}
                        selectedFed={currentCalc}
                        isCalc={isCalc}
                        selectedField={selectedField}
                        setFieldRequestDate={setFieldRequestDate} />
                </TabPane>
                <TabPane
                    tab={t('comparison tab')}
                    key="2">
                    <Comparison
                        setSelectedFed={setCurrentCalc}
                        currentCalc={currentCalc}
                        isCalc={isCalc}
                        selectedField={selectedField} />
                </TabPane>
            </Tabs>
            <h3 style={{ marginTop: '25px' }}>{t('recommended fertilizers')}</h3>
            <Table
                pagination={false}
                rowKey="id"
                bordered
                scroll={{ x: 1500 }}
                style={{ marginTop: '25px' }}
                columns={columns}
                dataSource={fertilizerRecommendation} />
        </div>
    );
};

export default LeafDiagnostics;