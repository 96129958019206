import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';
import { getAgroEvents } from '../item-events/actions';
import { removeCalculate } from '../item-calculate/api';
import { updateDistribution } from '../item-distribution/api';

function* getCalculatorMordoviaSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getCalculatorMordovia.try,
        constants.getCalculatorMordovia.success,
        constants.getCalculatorMordovia.error
    );
    try {
        yield put(start());
        const dataRaw = yield call(api.getCalculatorMordovia, payload);
        const lastYear = Math.max(...dataRaw.year_group?.years.map(x => x.year));
        const data = converter._server2Client(dataRaw, payload.year);
        const yearData = data?.year_group?.years.find(item =>
            item.year?.toString() === payload.year?.toString());
        yield put(actions.getCalculatorsMordoviaShape(`?filter={"vega_key":"${data.vega_key}"}`));
        yield put(actions.getCalculatorsMordoviaForecast({ id: data.vega_key, year: payload.year || lastYear }));
        if (yearData?.id) {
            yield put(actions.getFieldPoints({ id: yearData.id, filter: '' }));
        }
        // yield put(get)
        yield put(getAgroEvents({ vega_key: data.vega_key, year: payload.year || lastYear }));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* updateFieldDistributionSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.updateFieldDistribution.try,
        constants.updateFieldDistribution.success,
        constants.updateFieldDistribution.error
    );
    try {
        yield put(start());
        yield call(api.updateFieldDistribution, payload);
        yield put(actions.getCalculatorMordovia({ id: payload.vega_key, year: payload.body.year }));
        yield put(success());
    } catch (error) {
        yield put(failure(error));
    }
}

function* calculatePestProbabilitySaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.calculatePestProbability.try,
        constants.calculatePestProbability.success,
        constants.calculatePestProbability.error
    );
    try {
        yield put(start());
        const { id, body } = payload;
        const payloadYear = body.year;
        yield console.log('payloadYear', payloadYear);
        // delete payload.body.year;
        const pestProb = yield call(api.calculatePestProbability, payload);
        yield console.log('pestProb', pestProb);
        // yield console.log("PAYLIOAD HERE IS", { id: id, body: pestReqBody });
        const forec = yield call(actions.getCalculatorsMordoviaForecast({ id: id, body: { year: payloadYear } }));
        yield console.log("Forecast", forec);
        yield put(success());
    } catch (error) {
        yield put(failure(error));
    }
}

function* addGroupCalculateSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addGroupCalculate.try,
        constants.addGroupCalculate.success,
        constants.addGroupCalculate.error
    );
    try {
        yield put(start());
        const addCalculateResult = yield call(api.addGroupCalculate, payload);
        if (addCalculateResult) {
            yield put(success(addCalculateResult));
        } else {
            throw "NO_RESULT";
        }
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorMordovia.toString(),
            getCalculatorMordoviaSaga
        ),
        yield takeLatest(
            constants.calculatePestProbability.toString(),
            calculatePestProbabilitySaga
        ),
        yield takeLatest(
            constants.updateFieldDistribution.toString(),
            updateFieldDistributionSaga
        ),
        yield takeLatest(
            constants.getCalculatorsMordovia.toString(),
            defaultSaga({
                constants: constants.getCalculatorsMordovia,
                api: api.getCalculatorsMordovia
            })
        ),
        yield takeLatest(
            constants.getCalculatorsMordoviaShape.toString(),
            defaultSaga({
                constants: constants.getCalculatorsMordoviaShape,
                api: api.getCalculatorsMordoviaShape
            })
        ),
        yield takeLatest(
            constants.getCalculatorsMordoviaForecast.toString(),
            defaultSaga({
                constants: constants.getCalculatorsMordoviaForecast,
                api: api.getCalculatorsMordoviaForecast
            })
        ),
        yield takeLatest(
            constants.updateCalculatorsMordoviaForecast.toString(),
            defaultSaga({
                constants: constants.updateCalculatorsMordoviaForecast,
                api: api.updateCalculatorsMordoviaForecast
            })
        ),
        yield takeLatest(
            constants.updateFieldYield.toString(),
            defaultSaga({
                constants: constants.updateFieldYield,
                api: api.updateFieldYield
            })
        ),
        yield takeLatest(
            constants.getFieldYears.toString(),
            defaultSaga({
                constants: constants.getFieldYears,
                api: api.getFieldYears
            })
        ),
        yield takeLatest(
            constants.getPestProbabilityData.toString(),
            defaultSaga({
                constants: constants.getPestProbabilityData,
                api: api.getPestProbabilityData
            })
        ),
        yield takeLatest(
            constants.addGroupCalculate.toString(),
            addGroupCalculateSaga
            // defaultSaga({
            //     constants: constants.addGroupCalculate,
            //     api: api.addGroupCalculate
            // })
        ),
        yield takeLatest(
            constants.removeGroupCalculate.toString(),
            defaultSaga({
                constants: constants.removeGroupCalculate,
                api: removeCalculate
            })
        ),
        yield takeLatest(
            constants.getFieldPoints.toString(),
            defaultSaga({
                constants: constants.getFieldPoints,
                api: api.getFieldPoints
            })
        ),
        yield takeLatest(
            constants.updateDistributionGroupCalculate.toString(),
            defaultSaga({
                constants: constants.updateDistributionGroupCalculate,
                api: updateDistribution
            })
        )
    ]);
}