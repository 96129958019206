import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';

import Diagnostics from '../../mordovia/components/leaf-diagnostics/diagnostics';
import { calculateDiagnostics } from '../../../models/leaf-diagnostics/actions';
import { Button, Row } from 'antd';

const CalculatorResults = ({ calculator, form, selectedField }) => {
    const dispatch = useDispatch();
    const cn = useClassName('calculator-detail-fed-params');
    const { t } = useTranslation('leaf diagnostics');

    const [fieldRequestDate, setFieldRequestDate] = useState('');

    useEffect(() => {
        if (fieldRequestDate && fieldRequestDate.length > 0) {
            dispatch(calculateDiagnostics({
                vega_key: selectedField?.vega_key,
                body: {
                    type: 'field',
                    date: fieldRequestDate
                }
            }));
        }
    }, [fieldRequestDate]);

    // const calculateFertilizers = () => {

    // };

    return (
        <div>
            <Diagnostics
                isCalc={true}
                selectedFed={calculator}
                selectedField={selectedField}
                setFieldRequestDate={setFieldRequestDate} />
            {/* <Row aling="center" style={{ marginTop: '15px' }}>
                <Button type="primary" onClick={calculateFertilizers}>{t('calculate fertilizers button')}</Button>
            </Row> */}
        </div>
    );
};

export default CalculatorResults;