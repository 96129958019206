import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col } from 'antd';

import { withTranslation } from 'react-i18next';

import { Chart } from '@antv/g2';
import moment from 'moment';
import * as Scroll from 'react-scroll';
import cn from '../../utils/cn';

import {
    PanelLayout, SideBar, Table, Title
} from '../../components';

import './style.less';
import { actions, selectors } from '../../models';

const {
    getProfileInfo,
    getNDMI,
    getNDVI,
    getNDVIandNDMI,
    getSWI
} = actions;
const {
    getUser,
    getCurNDMI,
    getCurNDVI,
    getCurSWI,
    getCurUUID,
    getCurNDMIandNDVI
} = selectors;

const mstp = (state) => ({
    user: getUser(state),
    uuid: getCurUUID(state),
    ndmi: getCurNDMI(state),
    ndvi: getCurNDVI(state),
    swi: getCurSWI(state),
    ndMiVi: getCurNDMIandNDVI(state)
});
const mdtp = ({
    getProfileInfo,
    getNDMI,
    getNDVI,
    getNDVIandNDMI,
    getSWI
});

let chart;

@withRouter
@connect(mstp, mdtp)
@withTranslation('map')
@cn('page')
export default class Profile extends Component {
    state = {
        data: [],
        columns: []
    }

    componentDidMount() {
        chart = new Chart({
            container: 'container',
            autoFit: true,
            height: 500
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { ndMiVi, swi, uuid } = this.props;
        if (prevProps.uuid !== uuid) {
            this.showGraph(uuid);
        }
        if (prevProps.ndMiVi !== ndMiVi) {
            this.drawPlot();
        }
        if (prevProps.swi !== swi) {
            this.drawTableSwi();
        }
    }

    get agregateNDMIandNDVI() {
        const { ndMiVi } = this.props;
        const { dataNDMI, dataNDVI } = ndMiVi;
        const dataArr = [];
        const data = [];
        dataNDVI.forEach(item => {
            dataArr.push(moment(item.date).format('DD-MM-YYYY hh:mm'));
        });
        dataNDMI.forEach(item => {
            if (!dataArr.includes(moment(item.date).format('DD-MM-YYYY hh:mm'))) {
                dataArr.push(moment(item.date).format('DD-MM-YYYY hh:mm'));
            }
        });
        dataArr.sort((a, b) =>
            new Date(b) - new Date(a));
        dataArr.forEach(item => {
            const ndvi = dataNDVI.find(i => moment(i.date).format('DD-MM-YYYY hh:mm') === item);
            const ndmi = dataNDMI.find(i => moment(i.date).format('DD-MM-YYYY hh:mm') === item);
            if (ndmi) data.push({ date: item, type: 'Индекс NDMI', value: Number(ndmi.value.toFixed(6)) });
            if (ndvi) data.push({ date: item, type: 'Индекс NDVI', value: Number(ndvi.value.toFixed(6)) });
        });
        return data;
    }

    get agregateSWI() {
        const { cn } = this;
        const { swi, t } = this.props;
        const dates = [];
        const obj = {};
        const data = [];
        const columns = [];
        swi.forEach((item) => {
            dates.push(item.date);
            obj[item.date] = {
                swi_001: item.swi_001,
                swi_005: item.swi_005,
                swi_010: item.swi_010,
                swi_015: item.swi_015,
                swi_020: item.swi_020,
                swi_040: item.swi_040,
                swi_060: item.swi_060,
                swi_100: item.swi_100
            };
        });
        columns.push({
            dataIndex: 'title',
            key: 'title',
            fixed: 'left',
            width: 80,
            render: (item) => (<>{t(item)}</>)

        });
        dates.forEach((item, i) => {
            const itemData = {
                title: item,
                dataIndex: item,
                key: `${item} ${i}`,
                className: cn('item'),
                render: (item) => (
                    <div className={cn('color', this.MyRound10(item))}>{item}</div>
                )
            };
            columns.push(itemData);
        });
        const items = [
            'swi_001',
            'swi_005',
            'swi_010',
            'swi_015',
            'swi_020',
            'swi_040',
            'swi_060',
            'swi_100'
        ];
        items.forEach((item) => {
            const itemData = {};
            itemData.title = item;
            dates.forEach(date => {
                itemData[date] = obj[date][item];
            });
            data.push(itemData);
        });
        return { data, columns };
    }

    showGraph = (id) => {
        const { getNDVIandNDMI, getSWI } = this.props;
        getNDVIandNDMI(id);
        getSWI(id);
    }

    drawTableSwi = () => {
        const { data, columns } = this.agregateSWI;
        this.setState({ data, columns });
    }

    drawPlot = () => {
        const data = this.agregateNDMIandNDVI;
        Scroll.animateScroll.scrollToBottom();
        chart.data(data);
        chart.scale({
            month: {
                range: [0, 1]
            },
            temperature: {
                nice: true
            }
        });

        chart.tooltip({
            showCrosshairs: true,
            shared: true
        });

        chart.axis('value', {
            label: {
                formatter: (val) => val
            }
        });

        chart
            .line()
            .position('date*value')
            .color('type')
            .shape('smooth');

        chart
            .point()
            .position('date*value')
            .color('type')
            .shape('circle')
            .style({
                stroke: '#fff',
                lineWidth: 1
            });

        chart.render();
    }

    MyRound10 = (val) => {
        const round = Math.floor(val / 10) * 10 < 150 ? Math.floor(val / 10) * 10 : 150;
        return round;
    }

    render() {
        const { cn } = this;
        const {
            t, user, ndMiVi
        } = this.props;
        const { data, columns } = this.state;
        const { id } = user;
        const opacity = Object.keys(ndMiVi).length ? 1 : 0;
        const height = Object.keys(ndMiVi).length ? 'auto' : 0;

        const flag = false;

        return (

            <div className={cn('monitoring')}>
                <div className={cn('monitoring', 'title')}>{t('monitoring')}</div>
                <div id="container" style={{ opacity, height }} />
                {data.length > 0 && flag && (
                    <>
                        <div className={cn('monitoring', 'title')}>
                            {t('deep')}
                        </div>
                        <Table
                            className={cn('table')}
                            scroll={{ x: 1300 }}
                            dataSource={data}
                            columns={columns}
                            pagination={false} />
                        <div className={cn('table-legend')}>
                            <div className={cn('monitoring', 'title-sm')}>
                                {t('deep legend')}
                            </div>
                            <Row>
                                <Col span={2}>
                                    <div className={cn('block-color', '0')} />
                                                    0 - 9
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '20')} />
                                                    20 - 29
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '40')} />
                                                    40 - 49
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '60')} />
                                                    60 - 69
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '80')} />
                                                    80 - 89
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '100')} />
                                                    100 - 109
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '120')} />
                                                    120 - 129
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '140')} />
                                                    140 - 149
                                </Col>
                            </Row>
                            <Row>
                                <Col span={2}>
                                    <div className={cn('block-color', '10')} />
                                                    10 - 19
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '30')} />
                                                    30 - 39
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '50')} />
                                                    50 - 59
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '70')} />
                                                    70 - 79
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '90')} />
                                                    90 - 99
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '110')} />
                                                    110 - 119
                                </Col>
                                <Col span={2}>
                                    <div className={cn('block-color', '130')} />
                                                    130 - 139
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </div>

        );
    }
}
