import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';

import { userActivation } from '../../models/account/actions';
import { isActivate } from '../../models/account/selectors';
import cn from '../../utils/cn';

import './style.less';
import { withTranslation } from 'react-i18next';

const { Content } = Layout;


const { SubMenu } = Menu;
const mstp = (state) => ({
    isActivate: isActivate(state)
});

const mdtp = ({
    userActivation
});

@withRouter
@withTranslation('auth-page')
@connect(mstp, mdtp)
export default class Error extends Component {
    componentDidMount() {
        const { userActivation, match } = this.props;
        const { uid, token } = match.params;
        localStorage.setItem('token', '');
        localStorage.setItem('refresh', '');
        userActivation({ uid, token });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isActivate, history } = this.props;
        if (isActivate && prevProps.isActivate !== isActivate) {
            history.push('/');
        }
    }

    render() {
        const { isActivate, t } = this.props;
        return (
            <Layout className={cn()}>
                <Content className={cn('content')}>
                    {isActivate && <>{t('is activated')}</>}
                </Content>
            </Layout>
        );
    }
}
