import React from 'react';
import {
    TileLayer,
    LayersControl,
    LayerGroup
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { CustomWMSLayer } from './custom-wms';

export default () => {
    const { t } = useTranslation('layers');
    return (
        <>
            <LayersControl position="bottomleft">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay checked name={t('Field scheme')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Field scheme')}
                            checked
                            isShow
                            legend_url="teleagronom:Saetovo_fields"
                            layer="teleagronom:Saetovo_field"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Sampling points, agrochemistry')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Sampling points, agrochemistry')}
                            legend_url="teleagronom:Saetovo_agrohim"
                            layer="teleagronom:Saetovo_point_agrohim"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Sampling points, FED')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Sampling points, FED')}
                            legend_url="teleagronom:Saetovo_fed_point"
                            layer="teleagronom:Saetovo_point_fed"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Productivity Zones')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Productivity Zones')}
                            legend_url="teleagronom:Saetovo_zones"
                            layer="teleagronom:Saetovo_zones"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Soils')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Soils')}
                            legend_url="teleagronom:Saetovo_soils"
                            layer="teleagronom:Saetovo_soil"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('slope')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('slope')}
                            legend_url="teleagronom:Pikaev_uklon"
                            layer="teleagronom:Saetovo_uklon"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('exposition')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('exposition')}
                            legend_url="teleagronom:Exposition"
                            layer="teleagronom:Saetovo_exposition"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Contours')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Contours')}
                            legend_url="teleagronom:Изолинии"
                            layer="teleagronom:Saetovo_izolones"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Erosion')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Erosion')}
                            legend_url="line"
                            layer="teleagronom:Saetovo_erosion"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={t('Relief')}>
                    <LayerGroup>
                        <CustomWMSLayer
                            name={t('Relief')}
                            legend_url="teleagronom:Saetovo_visota"
                            layer="teleagronom:Saetovo_srtm"
                            options={{
                                format: 'image/png',
                                transparent: 'true',
                                info_format: 'application/json'
                            }}
                            url="/geoserver/wms?" />
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </>
    );
};