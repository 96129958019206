import * as constants from './constants';

export const getCalculatorsPreceding = payload => ({
    type: constants.getCalculatorsPreceding.toString(),
    payload
});

export const getCalculatorsPrecedingItem = payload => ({
    type: constants.getCalculatorsPrecedingItem.toString(),
    payload
});