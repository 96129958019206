import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
    Col, Row, Collapse, Form, Select, DatePicker, Button, Input, InputNumber, message
} from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import './style.less';

import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '../../models';

import { PHASES, PROBABILITY } from './constants';
import { toFixed } from '../../utils/utils';
import { DATEPICKER_FORMAT } from '../../constants';
import { Table } from '../../components';
import { useHistory } from 'react-router-dom';

const {
    getCurrentForecastCalculatorMordovia,
    getCurrentForecastErrorCalculatorMordovia,
    getRegionNormCultureCatalog,
    curNormative,
    getProbabilityData,
    getCurrentVegaKey,
    getCurCalculatorField
} = selectors;

const {
    updateCalculatorsMordoviaForecast,
    calculatePestProbability,
    getPestProbabilityData
} = actions;

const { Panel } = Collapse;

export default ({ isCompleted, year, currentCulture, vegaKey, isLaborant }) => {
    const history = useHistory();
    const cn = useClassName('phases');
    const forecast = useSelector(state => getCurrentForecastCalculatorMordovia(state));
    const calculator = useSelector(state => getCurCalculatorField(state));
    const normative = useSelector(state => curNormative(state));
    const cultures = useSelector(state => getRegionNormCultureCatalog(state));
    const forecastError = useSelector(state => getCurrentForecastErrorCalculatorMordovia(state));
    const probabilityData = useSelector(state => getProbabilityData(state));

    useEffect(() => {
        if (forecast?.errors?.length > 0) {
            forecast?.errors?.forEach((error) => {
                message.error(error);
            });
        }
    }, [forecast]);
    

    const [hardcodedCulture, setHardcodedCulture] = useState(0);

    const { t } = useTranslation('phases');
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    useEffect(() => {
        const pestNewObject = {};
        const { pests } = forecast;
        pests?.forEach(pestItem => {
            if (pestNewObject?.[pestItem?.name] && pestNewObject[pestItem?.name]?.vegetation_phases?.includes(pestItem?.vegetation_phase)) {
                pestNewObject[pestItem?.name]?.vegetation_phases?.push(pestItem?.vegetation_phase);
            } else {
                pestNewObject[pestItem?.name] = {
                    probability: pestItem?.probability,
                    type: pestItem?.type,
                    vegetation_phases: [pestItem?.vegetation_phase]
                };
            }
        })
        setPestsObject(pestNewObject);
        if (forecast?.culture?.id) {
            form.setFieldsValue({
                culture: forecast?.culture?.id
            });
        }
    }, [forecast]);

    const [pestsObject, setPestsObject] = useState({});
    const [phase, setPhase] = useState(null);
    const [showSoil, setShowSoil] = useState(false);
    const [isFullFields, setFullFields] = useState(false);
    const [isCalculate, setCalculate] = useState(false);

    useEffect(() => {
        if (vegaKey) {
            const date = moment(Date.now()).format('YYYY-MM-DD');
            if (forecast?.culture?.id) {
                setHardcodedCulture(parseInt(forecast.culture.id, 10));
            }
            dispatch(getPestProbabilityData({ id: vegaKey, body: { date: date, culture: forecast?.culture?.id } }));
        }
    }, [vegaKey]);

    useEffect(() => {
        form.setFieldsValue({
            culture: normative?.culture?.name,
            sowing_date: moment(forecast.sowing_date, 'YYYY-MM-DD'),
            t_air: toFixed(probabilityData?.t_air, 0),
            h_air: toFixed(probabilityData?.h_air, 0),
            date: moment(Date.now())
        });

        if (forecast.sowing_date && (normative?.culture?.id || currentCulture || forecast?.culture?.id) && year && forecast?.sowing_date) {
            setFullFields(true);
        }

        if (normative?.culture?.id && forecast.sowing_date && probabilityData?.t_air && probabilityData?.h_air) {
            if (normative?.culture?.id) {
                setHardcodedCulture(parseInt(normative.culture.id, 10));
            }
            
            dispatch(calculatePestProbability(
                {
                    id: vegaKey,
                    body: {
                        t_air: toFixed(probabilityData?.t_air, 0),
                        h_air: toFixed(probabilityData?.h_air, 0),
                        culture: normative?.culture?.id ?? currentCulture,
                        sowing_date: moment(forecast.sowing_date).format('YYYY-MM-DD'),
                        date: moment(Date.now()).format('YYYY-MM-DD'),
                        year: parseInt(year, 10)
                    }
                }
            ));
            setCalculate(true);
            setFullFields(true);
        }
    }, [probabilityData]);

    useEffect(() => {
        const curPhase = forecast?.items?.find(
            (item, index) =>
                checkDateInPeriod(item.occurrence_date, forecast.items[index + 1]?.occurrence_date)
        );
        setPhase({ ...curPhase });
    }, [forecast]);

    const checkDateInPeriod = (dateBefore, dateAfter) => {
        const curDate = new Date().getTime();
        const prevDate = new Date(dateBefore).getTime();
        const nextDate = dateAfter ? new Date(dateAfter).getTime() : new Date().getTime();
        if (curDate >= prevDate && !dateAfter) {
            return true;
        }
        if (curDate < prevDate) {
            return false;
        }
        if (curDate >= prevDate && curDate < nextDate && dateAfter) {
            return true;
        }
        return false;
    };

    const onValuesChange = (value, values) => {
        console.log(year,
            calculator?.culture?.id,);
        if (Object.keys(value)[0] === 'sowing_date') {
            dispatch(updateCalculatorsMordoviaForecast(
                {
                    id: vegaKey,
                    body: {
                        year,
                        culture: forecast?.culture?.id || normative?.culture?.id || hardcodedCulture,
                        sowing_date: moment(values.sowing_date).format('YYYY-MM-DD')
                    }
                }
            ));
        }

        if ((values?.culture || forecast?.culture?.id || normative?.culture?.id || hardcodedCulture) && values?.sowing_date && values?.date && year) {
            setFullFields(true);
        } else {
            setFullFields(false);
        }
        // if ((!values?.culture && forecast?.culture?.id && normative?.culture?.id && !hardcodedCulture) || !values?.sowing_date || !values?.date || year) {
        //     setFullFields(false);
        // } else {
        //     setFullFields(true);
        // }
    };

    const onFinish = (values) => {
        dispatch(calculatePestProbability(
            {
                id: vegaKey,
                body: {
                    ...values,
                    // culture_id: normative?.culture?.id,
                    culture: forecast?.culture?.id ?? currentCulture ?? normative?.culture?.id,
                    sowing_date: moment(values?.sowing_date).format('YYYY-MM-DD'),
                    date: moment(values?.date).format('YYYY-MM-DD'),
                    year: parseInt(year, 10)
                }
            }
        ));
        setCalculate(true);
    };

    const toggleSoil = () => {
        setShowSoil(!showSoil);
    };

    const setToDefault = () => {
        setShowSoil(!showSoil);
        form.setFieldsValue({
            culture: normative?.culture?.name,
            sowing_date: moment(forecast.sowing_date, 'YYYY-MM-DD'),
            t_air: toFixed(probabilityData?.t_air, 0),
            h_air: toFixed(probabilityData?.h_air, 0),
            date: moment(Date.now())
        });

        if (normative?.culture?.id && forecast?.sowing_date && year) {
            setFullFields(true);
        }
    };

    const prognosisColumns = [
        {
            title: t('table occurrence date'),
            dataIndex: 'occurrence_date',
            render: value => value ? moment(value)?.format('DD.MM.YYYY') : null
        },
        {
            title: t('table vegetation phase'),
            dataIndex: 'vegetation_phase',
        },
        {
            title: t('table name'),
            // width: 140,
            dataIndex: 'name',
            render: (value, record) => record?.id && (<a className="highlighted-hyperlink" onClick={() => {
                if (record?.type === 'pest') {
                    history.push(`/harmful-object/${record?.id}/`);
                } else {
                    history.push(`/disease/${record?.id}/`);
                }
            }}>{value}</a>)
        },
        {
            title: t('table probability'),
            dataIndex: 'probability',
        },
    ]

    return (
        <div>
                <Collapse
                    defaultActiveKey={['1']}
                    expandIconPosition="right"
                    ghost
                    className={`${cn('collapse')} ${cn('inner-collapse')}`}>
                    <Panel
                        header={(
                            <b>
                                {t('user data')}
                            </b>
                        )}
                        key="user data">
                        <div className={cn('sowing-block')}>
                            <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
                                <Row gutter={20}>
                                    <Col span={5} className="field">
                                        <Form.Item name="date" label={t('date forecast')}>
                                            <DatePicker format={DATEPICKER_FORMAT} disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5} className="field">
                                        <Form.Item name="culture" label={t('culture')}>
                                            <Select
                                                disabled
                                                options={[
                                                    {
                                                        value: forecast?.culture?.id,
                                                        label: forecast?.culture?.name
                                                    }
                                                ]}
                                                defaultValue={forecast?.culture?.id}
                                                showSearch />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5} className="field">
                                        <Form.Item name="sowing_date" label={t('sowing date')}>
                                            <DatePicker disabled={isLaborant} format={DATEPICKER_FORMAT} allowClear={false} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={5} className="field">
                                        <Form.Item name="t_air" label={t('t air')}>
                                            <InputNumber disabled={isLaborant} allowClear={false} step={1} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5} className="field">
                                        <Form.Item name="h_air" label={t('h air')}>
                                            <InputNumber disabled={isLaborant} allowClear={false} step={1} />
                                        </Form.Item>
                                    </Col>
                                    {!showSoil && !isLaborant && (
                                        <Col span={14} className={`field ${cn('button')}`}>
                                            <Button type="link" onClick={toggleSoil}>
                                                {t('add soil info')}
                                            </Button>
                                        </Col>
                                    )}
                                    {showSoil && (
                                        <>
                                            <Col span={5} className="field">
                                                <Form.Item name="t_soil" label={t('t soil')}>
                                                    <InputNumber disabled={isLaborant} allowClear={false} step={1} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5} className="field">
                                                <Form.Item name="h_soil" label={t('h soil')}>
                                                    <InputNumber disabled={isLaborant} allowClear={false} step={1} />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                                {!isLaborant && (
                                    <Row gutter={20}>
                                        <Col span={5} className="field">
                                            <Button type="primary"
                                                disabled={!isFullFields}
                                                htmlType="submit">
                                                {showSoil ? t('recalculate') : t('calculate')}
                                            </Button>
                                        </Col>
                                        {showSoil && (
                                            <Col span={5} className="field">
                                                <Button type="default"
                                                    onClick={setToDefault}>
                                                    {t('to default')}
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                )}
                                
                                { phase?.pests?.length <= 0 && (
                                    <Row className={cn('info')}>
                                        <Col>
                                            <div className={cn('info__inner')}>
                                                {t('no info for phase')}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {phase?.pests?.length > 0 && isCalculate && (
                                    <div className="row-table" style={{ marginTop: '30px' }}>
                                        <Row className="row-head">
                                            <Col span={4} className="row-table__label">{t('name')}</Col>
                                            <Col span={4} className="row-table__description">{t('pathogen')}</Col>
                                            <Col span={5} className="row-table__description">{t('epv')}</Col>
                                            <Col span={5} className="row-table__description">{t('probability')}</Col>
                                            <Col span={6} className="row-table__description">{t('active substance')}</Col>
                                        </Row>
                                        {
                                            phase.pests.map((pest, i) => (
                                                <Row className="row-table__withborder" key={pest.name + i}>
                                                    <Col span={4} className="row-table__label">
                                                        {pest.name}
                                                    </Col>
                                                    <Col span={4} className="row-table__description">
                                                        {pest.pathogen}
                                                    </Col>
                                                    <Col span={5} className="row-table__description">
                                                        {pest.economic_harmfulness}
                                                    </Col>
                                                    <Col span={5} className={`row-table__description ${pest.pest_probability && cn(`phase-color-${PROBABILITY[pest.pest_probability]}`)}`}>
                                                        {pest.pest_probability ? t(PROBABILITY[pest.pest_probability]) : pest.occurrence_probability}
                                                    </Col>
                                                    <Col span={6} className="row-table__description" style={{ whiteSpace: 'pre-wrap' }}>
                                                        {pest.main_drugs?.join('\n')}
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </div>
                                )}
                            </Form>
                        </div>
                    </Panel>
                    {(forecast.errors?.length > 0 || forecastError) && (
                        <Row className={cn('info')}>
                            <Col>
                                <div className={cn('info__inner')}>
                                    {t('no info for culture')}
                                </div>
                            </Col>
                        </Row>
                    )}
                    {forecast?.items?.length > 0 && !forecastError && (
                        <Panel
                            header={(
                                <b>
                                    {t('calculate phases')}
                                </b>
                            )}
                            key="calculate phases">
                                <Table
                                    scroll={{ y: 500 }}
                                    dataSource={forecast?.pests}
                                    columns={prognosisColumns}
                                    pagination={false} />
                            {/* <Collapse defaultActiveKey={['1']} ghost className={cn('collapse')}>
                                {
                                    forecast.items.map((item, index) => index > 0
                                            && (
                                                <Panel
                                                    className={`${checkDateInPeriod(item.occurrence_date, forecast.items[index + 1]?.occurrence_date) && 'panel-grey'}`}
                                                    header={(
                                                        <b>
                                                            {moment(item.occurrence_date).format('DD.MM.YYYY')}&nbsp;
                                                            {t(PHASES[item.vegetation_phase_culture_name.replace(forecast?.culture?.name, '').trim('')])}
                                                        </b>
                                                    )}
                                                    key={item.vegetation_phase_culture_name}>
                                                    {item.pests?.length > 0 && (
                                                        <div className="row-table">
                                                            <Row className="row-head">
                                                                <Col span={5} className="row-table__label">{t('name')}</Col>
                                                                <Col span={5} className="row-table__description">{t('pathogen')}</Col>
                                                            </Row>
                                                            {
                                                                item.pests.map((pest, i) => (
                                                                    <Row className="row-table__withborder" key={pest.name + i}>
                                                                        <Col span={5} className="row-table__label">
                                                                            {pest.name}
                                                                        </Col>
                                                                        <Col span={5} className="row-table__description">
                                                                            {pest.pathogen}
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </div>
                                                    )}
                                                </Panel>
                                            ))
                                }
                            </Collapse> */}
                        </Panel>
                    )}
                </Collapse>
        </div>
    );
};
