import React, { Component } from 'react';
import { Checkbox } from 'antd';

import cn from '../../utils/cn';
import './style.less';

@cn('checkbox')
export default class AsfdCheckbox extends Component {
    render() {
        const { cn } = this;
        const {
            className: propClass, ...rest
        } = this.props;
        const className = `${cn()} ${propClass || ''}`;

        return (
            <Checkbox className={className} {...rest} />
        );
    }
}
