import * as constants from './constants';

export const getFertilizers = payload => ({
    type: constants.getFertilizers.toString(),
    payload
});

export const getFertilizer = payload => ({
    type: constants.getFertilizer.toString(),
    payload
});

export const clearFertilizer = () => ({
    type: constants.clearFertilizer.toString()
});


export const getFertilizersGroup = payload => ({
    type: constants.getFertilizersGroup.toString(),
    payload
});
export const getFertilizersGroupItem = payload => ({
    type: constants.getFertilizersGroupItem.toString(),
    payload
});

export const getFertilizersMainDrug = payload => ({
    type: constants.getFertilizersMainDrug.toString(),
    payload
});
export const getFertilizersMainDrugItem = payload => ({
    type: constants.getFertilizersMainDrugItem.toString(),
    payload
});

export const getFertilizersMainDrugSynonyms = payload => ({
    type: constants.getFertilizersMainDrugSynonyms.toString(),
    payload
});
export const getFertilizersMainDrugSynonymsItem = payload => ({
    type: constants.getFertilizersMainDrugSynonymsItem.toString(),
    payload
});

export const getFertilizersReleaseForm = payload => ({
    type: constants.getFertilizersReleaseForm.toString(),
    payload
});
export const getFertilizersReleaseFormItem = payload => ({
    type: constants.getFertilizersReleaseFormItem.toString(),
    payload
});

export const getFertilizersSubGroup = payload => ({
    type: constants.getFertilizersSubGroup.toString(),
    payload
});
export const getFertilizersSubGroupItem = payload => ({
    type: constants.getFertilizersSubGroupItem.toString(),
    payload
});

export const getFertilizersToMainDrug = payload => ({
    type: constants.getFertilizersToMainDrug.toString(),
    payload
});
export const getFertilizersToMainDrugItem = payload => ({
    type: constants.getFertilizersToMainDrugItem.toString(),
    payload
});

export const getFertilizersToRegistration = payload => ({
    type: constants.getFertilizersToRegistration.toString(),
    payload
});
export const getFertilizersToRegistrationItem = payload => ({
    type: constants.getFertilizersToRegistrationItem.toString(),
    payload
});

export const getFertilizersToSeller = payload => ({
    type: constants.getFertilizersToSeller.toString(),
    payload
});
export const getFertilizersToSellerItem = payload => ({
    type: constants.getFertilizersToSellerItem.toString(),
    payload
});

export const getFertilizersType = payload => ({
    type: constants.getFertilizersType.toString(),
    payload
});
export const getFertilizersTypeItem = payload => ({
    type: constants.getFertilizersTypeItem.toString(),
    payload
});