import * as constants from './constants';

export const getReviews = payload => ({
    type: constants.getReviews.toString(),
    payload
});

export const getReview = payload => ({
    type: constants.getReview.toString(),
    payload
});

export const addReview = payload => ({
    type: constants.addReview.toString(),
    payload
});

export const updateReview = payload => ({
    type: constants.updateReview.toString(),
    payload
});

export const removeReview = payload => ({
    type: constants.removeReview.toString(),
    payload
});

export const getAverageRate = payload => ({
    type: constants.getAverageRate.toString(),
    payload
});

export const clearReview = payload => ({
    type: constants.clearReview.toString()
});