/* eslint-disable */
import React, {Fragment, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as constantsCommon from '../../constants';
import { rus2Latin } from '../../utils/utils';
import { selectors } from '../../models';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {Col, Collapse, Row} from "antd";
import Icon, {DownloadOutlined} from "@ant-design/icons";
import {Button} from "../../components";

const STEPS = ['main', 'sowing', 'feeding'];
const methodsArr = { kirsanov: 'по Кирсанову', chirikov: 'по Чирикову', machigin: 'по Мачигину' };
const intervals = {
    VERY_LOW: 'Очень низкое',
    LOW: 'Низкое',
    AVERAGE: 'Среднее',
    INCREASED: 'Повышенное',
    HIGH: 'Высокое',
    VERY_HIGH: 'Очень высокое',
    TOXIC: 'Токсичное',
    VERY_STRONGLY_ACID: 'Очень сильнокислый',
    STRONG_ACID: 'Сильнокислый',
    MEDIUM_ACID: 'Среднекислый',
    SLIGHTLY_ACID: 'Слабокислый',
    CLOSE_TO_NEUTRAL: 'Близкие к нейтральным',
    NEUTRAL: 'Нейтральные',
    WEAKLY_ALKALINE: 'Слабощелочные',
    STRONG_ALKALINE: 'Сильнощелочные',
    SHARPLY_ALKALINE: 'Резкощелочные'
};
const {
    curCalculate,
    getCurrentForecastCalculatorMordovia
} = selectors;

export default () => {
    const calculator = useSelector(state => curCalculate(state));
    const forecast = useSelector(state => getCurrentForecastCalculatorMordovia(state));

    const { t } = useTranslation('calculators');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [agrochemicalBody, setAgrochemicalBody] = useState([]);
    const [normativeBody, setNormativeBody] = useState([]);
    const [paramsRecommendedNorm, setParamsRecommendedNorm] = useState([]);
    const [rbBody, setRbBody] = useState([]);
    const [paramsRecommendedRB, setParamsRecommendedRB] = useState([]);
    const [fertilizersBody, setFertilizersBody] = useState([]);
    const [distributionBody, setDistributionBody] = useState([]);
    const [totalDistributionBody, setTotalDistributionBody] = useState([]);
    const [fertilizers, setFertilizers] = useState([]);

    useEffect(() => {
        const {
            normative, agrochemical, rb, distribution
        } = calculator;
        if (agrochemical?.id) {
            setAgrochemicalBody([
                {
                    title: 'Азот аммиачный (аммонийный) NH4, мг / 100г',
                    value: agrochemical.nh4,
                    interval: intervals[agrochemical.nh4_interval]
                },
                {
                    title: 'Гумус',
                    value: agrochemical.organic_substances,
                    interval: intervals[agrochemical.organic_substances_interval]
                },
                {
                    title: 'Азот нитратный NO3, мг / 100г',
                    value: agrochemical.no3,
                    interval: intervals[agrochemical.no3_interval]
                },
                { title: 'Метод анализа', value: methodsArr[agrochemical.method], interval: '' },
                {
                    title: 'Фосфор подвижный P2O5, мг / 100г',
                    value: agrochemical.p2o5,
                    interval: intervals[agrochemical.p2o5_interval]
                },
                {
                    title: 'Калий обменный K2O, мг / 100г',
                    value: agrochemical.k2o,
                    interval: intervals[agrochemical.k2o_interval]
                },
                {
                    title: 'Сера подвижная S, мг / 100 г',
                    value: agrochemical.s,
                    interval: intervals[agrochemical.s_interval]
                },
                { title: 'Кальций Са, мг-экв / 100г', value: agrochemical.ca, interval: intervals[agrochemical.ca_interval] },
                { title: 'Магний Mg, мг-экв / 100г', value: agrochemical.mg, interval: intervals[agrochemical.mg_interval] },
                { title: 'Сульфаты SO4, мг / 100г', value: agrochemical.so4, interval: intervals[agrochemical.so4_interval] },
                { title: 'Хлориды Cl, мг / 100 г', value: agrochemical.cl, interval: intervals[agrochemical.cl_interval] },
                {
                    title: 'Натрий Na, ммоль / 100г (мг / 100 г)',
                    value: agrochemical.na,
                    interval: intervals[agrochemical.na_interval]
                },
                {
                    title: 'Электропроводность водной вытяжки почвы, мСм/см (дСм/м)',
                    value: agrochemical.salt_conductivity,
                    interval: intervals[agrochemical.salt_conductivity_interval]
                },
                {
                    title: 'pH водный, ед. pH',
                    value: agrochemical.ph_water,
                    interval: intervals[agrochemical.ph_water_interval]
                },
                {
                    title: 'pH солевой, ед. pH',
                    value: agrochemical.ph_salt,
                    interval: intervals[agrochemical.ph_salt_interval]
                }


            ]);
        }
        if (normative?.id) {
            setNormativeBody([
                { title: 'Планируемая урожайность, т/га', value: normative?.planned_yield },
                { title: 'Регион', value: normative?.region?.name },
                { title: 'Будущая культура', value: normative?.culture?.name },
                { title: 'Предшественник', value: normative?.preceding_culture?.name },
                { title: 'Группа кислотности почвы', value: normative?.ph_salt },
                { title: 'Гранулометрический состав почвы', value: normative?.mechanical_composition?.name }
            ]);
            setParamsRecommendedNorm([
                {
                    title: t('table name'),
                    autumn: t('table autumn'),
                    spring: t('table spring'),
                    feeding: t('table feeding'),
                    total: t('total')
                },
                {
                    title: 'N',
                    autumn: normative?.recommended_n_main,
                    spring: normative?.recommended_n_sowing,
                    feeding: normative?.recommended_n_feeding,
                    total: normative.recommended_n_main + normative.recommended_n_sowing + normative.recommended_n_feeding
                },
                {
                    title: 'P',
                    autumn: normative?.recommended_p_main,
                    spring: normative?.recommended_p_sowing,
                    feeding: normative?.recommended_p_feeding,
                    total: normative.recommended_p_main + normative.recommended_p_sowing + normative.recommended_p_feeding
                },
                {
                    title: 'K',
                    autumn: normative?.recommended_k_main,
                    spring: normative?.recommended_k_sowing,
                    feeding: normative?.recommended_k_feeding,
                    total: normative.recommended_k_main + normative.recommended_k_sowing + normative.recommended_k_feeding
                }
            ]);
        }

        if (rb?.id) {
            setRbBody([
                { title: 'Планируемая урожайность, т/га', value: rb?.planned_yield },
                { title: 'Регион', value: rb?.region?.name },
                { title: 'Будущая культура', value: rb?.culture?.name },
                { title: 'Степень увлажнения', value: rb?.degree_of_soil_moisture?.name },
                { title: 'Метод анализа почвы', value: rb?.analysis_method },
                { title: 'Глубина отбора почвы, см', value: rb?.soil_sampling_depth },
                { title: 'Год действия удобрения', value: rb?.fertilizer_action_year },
                { title: 'Глубина пахотного слоя, см', value: rb?.depth_of_arable_layer },
                { title: 'Механический состав почвы', value: rb?.mechanical_composition?.name },
                { title: 'Азот, мг/100 г', value: rb?.report_n },
                { title: 'Фосфор, мг/100 г', value: rb?.report_p },
                { title: 'Калий, мг/100 г', value: rb?.report_k }
            ]);
            setParamsRecommendedRB([
                {
                    title: t('table name'),
                    autumn: t('table autumn'),
                    spring: t('table spring'),
                    feeding: t('table feeding'),
                    total: t('total')
                },
                {
                    title: 'N',
                    autumn: rb?.recommended_n_main,
                    spring: rb?.recommended_n_sowing,
                    feeding: rb?.recommended_n_feeding,
                    total: rb.recommended_n_main + normative.recommended_n_sowing + normative.recommended_n_feeding
                },
                {
                    title: 'P',
                    autumn: rb?.recommended_p_main,
                    spring: rb?.recommended_p_sowing,
                    feeding: rb?.recommended_p_feeding,
                    total: rb.recommended_p_main + normative.recommended_p_sowing + normative.recommended_p_feeding
                },
                {
                    title: 'K',
                    autumn: rb?.recommended_k_main,
                    spring: rb?.recommended_k_sowing,
                    feeding: rb?.recommended_k_feeding,
                    total: rb.recommended_k_main + normative.recommended_k_sowing + normative.recommended_k_feeding
                }
            ]);
        }
        if (distribution?.id) {
            setFertilizersBody(distribution.related_fertilizers?.map(item => ({
                name: item.fertilizer.name,
                avg_price: item.fertilizer.avg_price,
                content_n: item.fertilizer.content_n,
                content_p: item.fertilizer.content_p,
                content_k: item.fertilizer.content_k
            })));

            const distributionFertilizers = [];
            const distributionBody = [];
            STEPS.forEach(step => {
                const total = { actual_n: 0, actual_p: 0, actual_k: 0 };
                const fertilizers = [];
                fertilizers.push({
                    id: 0,
                    name: t('recommended'),
                    actual_n: distribution[`recommended_n_${step}`],
                    actual_p: distribution[`recommended_p_${step}`],
                    actual_k: distribution[`recommended_k_${step}`],
                    total: ''
                });
                distribution.related_fertilizers?.forEach(fertilizer => {
                    const curFertilizer = fertilizer.fertilizer;
                    if (curFertilizer.id) {
                        const actual = {};
                        ['n', 'p', 'k'].forEach(item => {
                            actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer[step]) / 100) ?? 0;
                            total[`actual_${item}`] += actual[`actual_${item}`];
                        });
                        fertilizers.push({
                            id: curFertilizer.id,
                            name: curFertilizer.name,
                            total: fertilizer.total,
                            ...actual
                        });
                    }
                });
                fertilizers.push({
                    id: 0, name: t('total'), total: '', ...total
                });
                distributionFertilizers.push({ title: t(step), fertilizers });
            });
            distributionFertilizers?.forEach(fertilizer => {
                distributionBody.push(
                    {
                        title: fertilizer.title,
                        content_n: 'N',
                        content_p: 'P',
                        content_k: 'K',
                        value: 'Физический вес'
                    }
                );
                fertilizer.fertilizers?.forEach(item => {
                    distributionBody.push({
                        title: item.name,
                        content_n: item?.actual_n?.toFixed(2),
                        content_p: item?.actual_p?.toFixed(2),
                        content_k: item?.actual_k?.toFixed(2),
                        value: item.total
                    });
                });
            });

            setDistributionBody(distributionBody);

            const totalDistributionBody = [];
            const total = totalFertilizer(distribution);
            totalDistributionBody.push([
                {
                    title: t('total'), content_n: 'N', content_p: 'P', content_k: 'K', value: 'Физический вес'
                }
            ]);
            total.fertilizers?.forEach(item => {
                totalDistributionBody.push({
                    title: item.name,
                    content_n: item?.actual_n?.toFixed(2),
                    content_p: item?.actual_p?.toFixed(2),
                    content_k: item?.actual_k?.toFixed(2),
                    value: item.total
                });
            });

            setTotalDistributionBody(totalDistributionBody);

            const bodyFertilizer = [];
            distribution.related_fertilizers?.forEach(item => {
                const fertilizer = { ...item.fertilizer };
                const applicationRate = [];
                if (fertilizer.presowing_application_rate) {
                    applicationRate.push({
                        title: `${t('application rate')}. ${t('presowingApplicationRate')}`,
                        value: fertilizer.presowing_application_rate
                    });
                }
                if (fertilizer.sowing_application_rate) {
                    applicationRate.push({
                        title: `${t('application rate')}. ${t('sowingApplicationRate')}`,
                        value: fertilizer.sowing_application_rate
                    });
                }
                if (fertilizer.basic_application_rate) {
                    applicationRate.push({
                        title: `${t('application rate')}. ${t('basicApplicationRate')}`,
                        value: fertilizer.basic_application_rate
                    });
                }
                if (fertilizer.top_dressing_application_rate_open) {
                    applicationRate.push({
                        title: `${t('application rate')}. ${t('topDressingApplicationRateOpen')}`,
                        value: fertilizer.top_dressing_application_rate_open
                    });
                }
                if (fertilizer.top_dressing_application_rate_close) {
                    applicationRate.push({
                        title: `${t('application rate')}. ${t('topDressingApplicationRateClose')}`,
                        value: fertilizer.top_dressing_application_rate_close
                    });
                }
                bodyFertilizer.push([
                    { title: t('group fertilizers'), value: fertilizer.subgroup && fertilizer.subgroup?.group?.name },
                    { title: t('subgroup fertilizers'), value: fertilizer.subgroup?.name },
                    { title: t('culture group'), value: fertilizer.culture_description },
                    { title: t('economy type'), value: getEconomyType(fertilizer) },
                    { title: t('macrocells'), value: getMacro(fertilizer) },
                    { title: t('release form'), value: fertilizer.release_form && fertilizer.release_form?.name }
                ]);
                applicationRate.forEach(item =>
                    (bodyFertilizer.push(item)));
            });
            setFertilizers(bodyFertilizer);
        }
    },[calculator]);

    const getEconomyType = (fertilizer) => {
        const arr = [];
        constantsCommon.economyTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item)); }
        });
        const str = arr.join(', ');
        return str;
    };

    const getMacro = (fertilizer) => {
        const arr = [];
        constantsCommon.macroTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item, { value: fertilizer[item] })); }
        });
        const str = arr.join(', ');
        return str;
    };

    const totalFertilizer = (distribution) => {
        const fertilizers = [];
        const totalRecommended = { actual_n: 0, actual_p: 0, actual_k: 0 };
        const total = { actual_n: 0, actual_p: 0, actual_k: 0 };
        STEPS.forEach(step => {
            totalRecommended.actual_n += distribution[`recommended_n_${step}`];
            totalRecommended.actual_p += distribution[`recommended_p_${step}`];
            totalRecommended.actual_k += distribution[`recommended_k_${step}`];
        });
        fertilizers.push({
            id: 0,
            type: 'row-bold-green',
            name: t('recommended'),
            ...totalRecommended
        });
        distribution.related_fertilizers?.forEach(fertilizer => {
            const curFertilizer = fertilizer.fertilizer;
            if (curFertilizer.id) {
                const actual = {};
                ['n', 'p', 'k'].forEach(item => {
                    actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer.total) / 100) ?? 0;
                    total[`actual_${item}`] += actual[`actual_${item}`];
                });
                fertilizers.push({
                    id: curFertilizer.id,
                    name: curFertilizer.name,
                    total: fertilizer.total,
                    ...actual
                });
            }
        });
        fertilizers.push({
            id: 0, type: 'row-bold-green', name: t('total'), ...total
        });
        return { title: t('total'), fertilizers };
    };

    const fileName = rus2Latin(calculator.name?.substr(0, 50) ?? 'Calculator');

    const exportToXLSX = () => {
        const {
            normative, agrochemical, rb, distribution
        } = calculator;
        const agrochemicalTab = XLSX.utils.table_to_sheet(document.getElementById('agro-table'));
        agrochemicalTab['!cols'] = [{wch:50}, {wch:15}, {wch:15}, {wch:15}, {wch:15}];
        const paramsTab = XLSX.utils.table_to_sheet(document.getElementById('params-table'));
        paramsTab['!cols'] = [{wch:22}, {wch:22}, {wch:22}, {wch:22}, {wch:22}];
        const distributionTab = XLSX.utils.table_to_sheet(document.getElementById('distribution-table'));
        distributionTab['!cols'] = [{wch:55}, {wch:15}, {wch:15}, {wch:15}, {wch:15}];
        const phasesTab = XLSX.utils.table_to_sheet(document.getElementById('phases-table'));
        phasesTab['!cols'] = [{wch:40}, {wch:50}, {wch:50}, {wch:15}, {wch:50}];
        const SheetNames = [];
        agrochemical?.id && SheetNames.push('Агрохимические показатели');
        (normative?.id || rb?.id) && SheetNames.push('Параметры расчета');
        distribution?.id && SheetNames.push('Распределение по сезонам');
        forecast?.items?.length && SheetNames.push('Фазы вегетации')
        const wb = {
            Sheets: {
                'Агрохимические показатели': agrochemicalTab,
                'Параметры расчета': paramsTab,
                'Распределение по сезонам': distributionTab,
                'Фазы вегетации': phasesTab
            },
            SheetNames
        };
        //const wb1 = { Sheets: { data: ws }, SheetNames: ['Данные'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
        <>
            <table style={{ display: 'none' }} id="agro-table">
                <tbody>
                    <tr>
                        <td colSpan={2}>{calculator?.name}</td>
                        <td colSpan={2}>{calculator?.normative?.culture?.name || calculator?.rb?.culture?.name}</td>
                        <td>{calculator?.date}</td>
                    </tr>
                <tr><td colSpan={5}/></tr>

                {
                    calculator?.agrochemical?.id && (
                        <tr>
                            <td colSpan={5}>Агрохимические показатели</td>
                        </tr>
                    )
                }
                <tr><td colSpan={5}/></tr>
                {
                    agrochemicalBody.length > 0 && agrochemicalBody.map((item, i) => (
                        <tr key={item.title + i}>
                            <td colSpan={2}>{item.title}</td>
                            <td>{item.value}</td>
                            <td colSpan={2}>{item.interval}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <table style={{ display: 'none' }} id="params-table">
                <tbody>
                {
                    normativeBody.length > 0 && normativeBody.map((item, i) => (
                        <tr key={item.title + i}>
                            <td colSpan={3}>{item.title}</td>
                            <td colSpan={2}>{item.value}</td>
                        </tr>
                    ))
                }
                <tr><td colSpan={5}/></tr>
                {
                    paramsRecommendedNorm.length > 0 && paramsRecommendedNorm.map((item, i) => (
                        <tr key={item.title + i}>
                            <td>{item.title}</td>
                            <td>{item.autumn}</td>
                            <td>{item.spring}</td>
                            <td>{item.feeding}</td>
                            <td>{item.total}</td>
                        </tr>
                    ))
                }
                <tr><td colSpan={5}/></tr>
                {
                    rbBody.length > 0 && rbBody.map((item, i) => (
                        <tr key={item.title + i}>
                            <td colSpan={3}>{item.title}</td>
                            <td colSpan={2}>{item.value}</td>
                        </tr>
                    ))
                }
                <tr><td colSpan={5}/></tr>
                {
                    paramsRecommendedRB.length > 0 && paramsRecommendedRB.map((item, i) => (
                        <tr key={item.title + i}>
                            <td>{item.title}</td>
                            <td>{item.autumn}</td>
                            <td>{item.spring}</td>
                            <td>{item.feeding}</td>
                            <td>{item.total}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <table style={{ display: 'none' }} id="distribution-table">
                <tbody>
                {
                    fertilizersBody.length > 0 && fertilizersBody.map((item, i) => (
                        <tr key={item.name + i}>
                            <td>{item.name}</td>
                            <td>{item.avg_price}</td>
                            <td>{item.content_n}</td>
                            <td>{item.content_p}</td>
                            <td>{item.content_k}</td>
                        </tr>
                    ))
                }
                <tr><td colSpan={5}/></tr>
                {
                    distributionBody.length > 0 && distributionBody.map((item, i) => (
                        <tr key={item.title + i}>
                            <td>{item.title}</td>
                            <td>{item.content_n}</td>
                            <td>{item.content_p}</td>
                            <td>{item.content_k}</td>
                            <td>{item.value}</td>
                        </tr>
                    ))
                }
                <tr><td colSpan={5}/></tr>
                {
                    totalDistributionBody.length > 0 && totalDistributionBody.map((item, i) => (
                        <tr key={item.title + i}>
                            <td>{item.title}</td>
                            <td>{item.content_n}</td>
                            <td>{item.content_p}</td>
                            <td>{item.content_k}</td>
                            <td>{item.value}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <table style={{ display: 'none' }} id="phases-table">
                <tbody>
                {forecast?.items?.length > 0
                && forecast.items.map((item, index) => index > 0
                    && (
                        <Fragment key={item.occurrence_date + index}>
                            <tr><td colSpan={5}></td></tr>
                        <tr>
                            <td colSpan={5}>
                                {moment(item.occurrence_date).format('DD.MM.YYYY')} {item.vegetation_phase_culture_name.replace(forecast.culture, '')}
                            </td>
                        </tr>
                            {
                                item.pests?.length > 0 && (
                                    <Fragment>
                                        <tr>
                                            <td>{t('name')}</td>
                                            <td>{t('pathogen')}</td>
                                            <td>{t('epv')}</td>
                                            <td>{t('percent')}</td>
                                            <td>{t('active substance')}</td>
                                        </tr>
                                        {
                                            item.pests.map((pest, i) => (
                                                <tr key={pest.name + i}>
                                                    <td>{pest.name}</td>
                                                    <td>{pest.pathogen}</td>
                                                    <td>{pest.economic_harmfulness}</td>
                                                    <td>{pest.occurrence_probability}</td>
                                                    <td>{pest.main_drugs?.map(drug => (
                                                            <span key={drug}>{drug}, </span>
                                                        ))}</td>
                                                </tr>
                                            ))
                                        }
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    )
                )}
                </tbody>
            </table>
            <Button
                onClick={exportToXLSX}
                type="primary">
                <DownloadOutlined />
                {t('export to xlsx')}
            </Button>
        </>
    );
};