import * as constants from './constants';

export const getAgrotechnicalEventsField = payload => ({
    type: constants.getAgrotechnicalEventsField.toString(),
    payload
});

export const getAgrotechnicalEventsAccounting = payload => ({
    type: constants.getAgrotechnicalEventsAccounting.toString(),
    payload
});

export const updateAgrotechnicalEvents = payload => ({
    type: constants.updateAgrotechnicalEvents.toString(),
    payload
});

export const getVegetationPhases = payload => ({
    type: constants.getVegetationPhases.toString(),
    payload
});

export const getAgrotechnicalEventsList = payload => ({
    type: constants.getAgrotechnicalEventsList.toString(),
    payload
});

export const deleteAgrotechnicalEvent = payload => ({
    type: constants.deleteAgrotechnicalEvent.toString(),
    payload
});

export const patchAgrotechnicalEvent = payload => ({
    type: constants.patchAgrotechnicalEvent.toString(),
    payload
});