import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import FertilizerData from './fertilizer-data';
import FertilizerReview from './fertilizer-review';
import FertilizerPrice from './fertilizer-price';
import CircleInfoIcon from '../../../../public/images/catalog/circle-info.svg';
import PriceIcon from '../../../../public/images/catalog/price.svg';
import ReviewsIcon from '../../../../public/images/catalog/reviews.svg';
import { actions, selectors } from '../../../models';

const { TabPane } = Tabs;
const { PanelHeader, PanelContent } = PanelLayout;

const {
    getReviews,
    getAverageRate
} = actions;
const {
    getReviewsListCount,
    getCurrentAverageRate
} = selectors;

const mstp = (state) => ({
    count: getReviewsListCount(state),
    rate: getCurrentAverageRate(state)
});
const mdtp = ({
    getReviews,
    getAverageRate
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('fertilizer detail')
@cn('fertilizer')
export default class CalculatorDetail extends Component {
    state = {
        tab: '1',
        isCreateReview: false
    };

    componentDidMount() {
        const { getReviews, getAverageRate, match } = this.props;
        const { id } = match.params;
        getReviews({ fertilizerId: id });
        getAverageRate({ fertilizerId: id });
    }

    onTabChange = (key, isCreateReview) => {
        this.setState({ tab: key, isCreateReview });
    };

    render() {
        const { cn } = this;
        const { t, count } = this.props;
        const { tab, isCreateReview } = this.state;

        return (
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('fertilizer')} />
                </PanelHeader>
                <PanelContent>
                    <Tabs
                        onChange={this.onTabChange}
                        activeKey={tab}>
                        <TabPane
                            tab={(
                                <span>
                                    <Icon component={CircleInfoIcon} />
                                    {t('about')}
                                </span>
                            )}
                            key="1">
                            <FertilizerData
                                onTabChange={this.onTabChange} />
                        </TabPane>
                        <TabPane
                            tab={(
                                <span>
                                    <Icon component={PriceIcon} />
                                    {t('price')}
                                </span>
                            )}
                            key="2">
                            <FertilizerPrice />
                        </TabPane>
                        { /* <TabPane
                            disabled={!isCreateReview && !count}
                            tab={(
                                <span>
                                    <Icon component={ReviewsIcon} />
                                    {t('review')}
                                </span>
                            )}
                            key="3">
                            <FertilizerReview isCreateReview={isCreateReview} />
                        </TabPane> */ }
                    </Tabs>
                </PanelContent>
            </PanelLayout>
        );
    }
}