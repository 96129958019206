import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getHarmfulObjects.toString(),
            defaultSaga({
                constants: constants.getHarmfulObjects,
                api: api.getHarmfulObjects
            })
        ),
        yield takeLatest(
            constants.getHarmfulObject.toString(),
            defaultSaga({
                constants: constants.getHarmfulObject,
                api: api.getHarmfulObject
            })
        ),
        yield takeLatest(
            constants.getHarmfulObjectDivisions.toString(),
            defaultSaga({
                constants: constants.getHarmfulObjectDivisions,
                api: api.getHarmfulObjectDivisions
            })
        )
    ]);
}