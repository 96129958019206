import { all, takeLatest } from 'redux-saga/effects';
import { defaultSaga } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsNormRegionCulture.toString(),
            defaultSaga({
                constants: constants.getCalculatorsNormRegionCulture,
                api: api.getCalculatorsNormRegionCulture
            })
        ),
        yield takeLatest(
            constants.getCalculatorNormRegionCultureItem.toString(),
            defaultSaga({
                constants: constants.getCalculatorNormRegionCultureItem,
                api: api.getCalculatorNormRegionCultureItem
            })
        ),
        yield takeLatest(
            constants.getCalculatorsRBRegionCulture.toString(),
            defaultSaga({
                constants: constants.getCalculatorsRBRegionCulture,
                api: api.getCalculatorsRBRegionCulture
            })
        ),
        yield takeLatest(
            constants.getCalculatorRBRegionCultureItem.toString(),
            defaultSaga({
                constants: constants.getCalculatorRBRegionCultureItem,
                api: api.getCalculatorRBRegionCultureItem
            })
        )
    ]);
}