import { combineReducers } from 'redux';

import ndmi from './ndmi';
import ndMiVi from './ndmi-ndvi';
import ndvi from './ndvi';
import swi from './swi';
import uuid from './uuid';

export default combineReducers({
    ndmi,
    ndvi,
    ndMiVi,
    swi,
    uuid
});
