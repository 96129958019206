import { combineReducers } from 'redux';

import data from './data';
import list from './list';
import loading from './loading';

export default combineReducers({
    data,
    list,
    loading
});
