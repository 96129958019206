import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'antd';
import { useClassName } from '../../../../../utils/cn';
import {
    getGenusOfMicroCatalogCalculatorsMyco
} from '../../../../../models/calculators-myco/selectors';
import { toFixed } from '../../../../../utils/utils';
import ColumnGroup from 'antd/lib/table/ColumnGroup';

const RepetitionsTable = ({ microorganisms, resultImap }) => {
    const cn = useClassName('calculator-detail-params');
    const { t } = useTranslation('field representation mycological analysis');
    const micro = useSelector(state => getGenusOfMicroCatalogCalculatorsMyco(state));

    const [microDict, setmicroDict] = useState({});

    useEffect(() => {
        const processedMicro = {
            bacteria: microorganisms?.filter(x => !x.mycobiota_type)[0],
            neutral: microorganisms?.filter(x => x.mycobiota_type?.id === 1),
            suppressive: microorganisms?.filter(x => x.mycobiota_type?.id === 2),
            phytopathogenic: microorganisms?.filter(x => x.mycobiota_type?.id === 3)
        }
        setmicroDict(processedMicro);
    }, [microorganisms]);

    return (
        <div className="repetitions_table">
            <Row className={cn('row-header')} style={{ maxHeight: 'fit-content !important' }}>
                <Col className={cn('row-table__label')} span={8} />
                <Col className={cn('row-table__label')} span={microDict ? (2 * microDict[0]?.repetitions?.length) : 2}>
                    {t('povtornosti header')}
                </Col>
                <Col className={cn('row-table__label')} span={8} />
            </Row>
            <Row className={cn('row-header2')}>
                <Col className={cn('row-table__label')} span={8}>
                    {t('repetitions table col 1')}
                </Col>
                {microDict && microDict?.bacteria?.repetitions && microDict?.bacteria?.repetitions?.map((repetitionEl, idx) => (
                    <Col className={cn('row-table__label')} span={2} style={{ textAlign: 'center' }}>
                        {idx + 1}
                    </Col>
                ))}
                <Col className={cn('row-table__label')} span={4}>
                    {t('repetitions table col 3')}
                </Col>
                <Col className={cn('row-table__label')} span={4}>
                    {t('repetitions table col 4')}
                </Col>
            </Row>

            <Row className={cn('row-table__withborder')}>
                <Col className={cn('row-table__label')} span={8}>
                    {t('bacteria general name')}
                </Col>
                {microDict?.bacteria?.repetitions?.map(y => (
                    <Col className={cn('row-table__label')} span={2}>
                        {y}
                    </Col>
                ))}
                <Col className={cn('row-table__label')} span={4}>
                    {toFixed(resultImap && resultImap[' '] && resultImap[' '][40]?.mean, 2)}
                </Col>
                <Col className={cn('row-table__label')} span={4} />
            </Row>

            <Row className={cn('row-head')}>
                <Col className={cn('row-table__label')} span={8}>
                    {t('phytopathogenic soil mycobiota').toUpperCase()}
                </Col>
                <Col className={cn('row-table__label')} span={microDict ? 2 * microDict?.bacteria?.repetitions?.length : 2} />
                <Col span={4} />
                <Col className={cn('row-table__label')} span={4}>
                    {toFixed(resultImap?.phytopathogenic?.mycobiota_precentage)}
                </Col>
            </Row>
            {microDict?.phytopathogenic?.map(x => (
                <Row className={cn('row-table__withborder')}>
                    <Col className={cn('row-table__label')} span={8}>
                        {x?.genius?.name}
                    </Col>
                    {x?.repetitions?.map(z => (
                        <Col className={cn('row-table__label')} span={2}>
                            {z}
                        </Col>
                    ))}
                    <Col className={cn('row-table__label')} span={4}>
                        {toFixed(resultImap?.phytopathogenic?.mean_sum, 2)}
                    </Col>
                    <Col className={cn('row-table__label')} span={4} />
                </Row>
            ))}

            <Row className={cn('row-head')}>
                <Col className={cn('row-table__label')} span={8}>
                    {t('suppressive soil mycobiota').toUpperCase()}
                </Col>
                <Col className={cn('row-table__label')} span={microDict ? 2 * microDict?.bacteria?.repetitions?.length : 2} />
                <Col className={cn('row-table__label')} span={4} />
                <Col className={cn('row-table__label')} span={4}>
                    {toFixed(resultImap?.suppressive?.mycobiota_precentage)}
                </Col>
            </Row>
            {microDict?.suppressive?.map(x => (
                <Row className={cn('row-table__withborder')}>
                    <Col className={cn('row-table__label')} span={8}>
                        {x?.genius?.name}
                    </Col>
                    {x?.repetitions?.map(z => (
                        <Col className={cn('row-table__label')} span={2}>
                            {z}
                        </Col>
                    ))}
                    <Col className={cn('row-table__label')} span={4}>
                        {toFixed(resultImap?.suppressive?.mean_sum, 2)}
                    </Col>
                    <Col className={cn('row-table__label')} span={4} />
                </Row>
            ))}

            <Row className={cn('row-head')}>
                <Col className={cn('row-table__label')} span={8}>
                    {t('neutral soil mycobiota').toUpperCase()}
                </Col>
                <Col className={cn('row-table__label')} span={microDict ? 2 * microDict?.bacteria?.repetitions?.length : 2} />
                <Col span={4} />
                <Col className={cn('row-table__label')} span={4}>
                    {toFixed(resultImap?.neutral?.mycobiota_precentage)}
                </Col>
            </Row>
            {microDict?.neutral?.map(x => (
                <Row className={cn('row-table__withborder')}>
                    <Col className={cn('row-table__label')} span={8}>
                        {x?.genius?.name}
                    </Col>
                    {x?.repetitions?.map(z => (
                        <Col className={cn('row-table__label')} span={2}>
                            {z}
                        </Col>
                    ))}
                    <Col className={cn('row-table__label')} span={4}>
                        {toFixed(resultImap?.neutral?.mean_sum, 2)}
                    </Col>
                    <Col className={cn('row-table__label')} span={4} />
                </Row>
            ))}
        </div>
    );
};

export default RepetitionsTable;