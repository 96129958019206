import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import {
    Modal, Button, Row, Col, Spin, Select, Tabs
} from 'antd';
import Icon from '@ant-design/icons';
import LoginForm from '../../auth/components/login-form';
import RegForm from '../../auth/components/reg-form';
import Opportunity1 from '../../../../public/images/opportunity1.svg';
import Opportunity2 from '../../../../public/images/opportunity2.svg';
import Opportunity3 from '../../../../public/images/opportunity3.svg';
import Opportunity4 from '../../../../public/images/opportunity4.svg';
import BreadCrumbs from './breadcrumbs';

import { Slider } from '../../../components';
import SliderWrapper from './slider';

const { TabPane } = Tabs;


export default (props) => {
    const cn = useClassName('home-page');
    const { t } = useTranslation('home');
    const { setPageType } = props;
    const [activeTab, setActiveTab] = useState('before');
    const [active, setActive] = useState(false);
    const [isLogin, setIsLogin] = useState(true);

    const onTabClick = (type) => {
        setActiveTab(type);
    };

    const onCancel = () => {
        setActive(false);
    };

    const onStart = () => {
        setActive(true);
    };

    const setReg = () => {
        setIsLogin(false);
    };

    const setLogin = () => {
        setIsLogin(true);
    };

    const goToModule = (url) => {
        localStorage.setItem('prevPage', url);
        onStart();
    };

    return (
        <div className={cn()}>
            <Row className={cn('block-auto')}>
                <BreadCrumbs type="legal-entities" setPageType={setPageType} />
            </Row>
            <Row className={cn('block-auto')} style={{ marginTop: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}><h2>{t('teleagronom for legal entities')}</h2></Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h3>{t('work steps')}</h3>
                </Col>
            </Row>
            <Row className={cn('block-auto')} style={{ marginBottom: '60px' }}>
                <Col span={4} />
                <Col span={16}>
                    <div className={cn('tabs')}>
                        <div>
                            <div className={`${activeTab === 'before' && cn('tabs__active-tab')} ${cn('tabs__title')}`}
                                onClick={() => onTabClick('before')}>
                                <Trans i18nKey="home:before_company">
                                    .<b>.</b>
                                </Trans>
                            </div>
                            <div className={`${activeTab === 'in' && cn('tabs__active-tab')} ${cn('tabs__title')}`}
                                onClick={() => onTabClick('in')}>
                                <Trans i18nKey="home:in_company">
                                    .<b>.</b>
                                </Trans>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={4} />
            </Row>
            <Row className={`${activeTab === 'before' ? cn('block-auto') : cn('hide')}`} style={{ marginBottom: '60px' }}>
                <Col span={3} />
                <Col span={18}>
                    <div className={cn('tabs__step')}>
                        <img src="/images/nums/01.svg" className={cn('module-block__img-nums')} />
                        <div>{t('before step1')}</div>
                    </div>
                </Col>
                <Col span={3} />
                <Col span={3} />
                <Col span={18}>
                    <div className={cn('ipad')}>
                        <div className={cn('ipad-inner')}>
                            <img src="/images/home/legal/map.png" className={cn('ipad-inner__img')} />
                            <img src="/images/home/legal/map_info.svg" className={cn('ipad-inner__img-text')} />
                        </div>
                    </div>
                </Col>
                <Col span={3} />
                <Col span={3} />
                <Col span={18}>
                    <div className={cn('tabs__step')}>
                        <img src="/images/nums/02.svg" className={cn('module-block__img-nums')} />
                        <div>{t('before step2')}</div>
                    </div>
                </Col>
                <Col span={3} />
                <Col span={3} />
                <Col span={18}>
                    <div className={cn('tabs__step')}>
                        <img src="/images/nums/03.svg" className={cn('module-block__img-nums')} />
                        <div>{t('before step3')}</div>
                    </div>
                </Col>
                <Col span={3} />
            </Row>
            <Row className={`${activeTab === 'in' ? cn('block-auto') : cn('hide')}`} style={{ marginBottom: '60px' }}>
                <Col span={3} />
                <Col span={18}>
                    <div className={cn('tabs__step')}>
                        <img src="/images/nums/01.svg" className={cn('module-block__img-nums')} />
                        <div>{t('in step1')}</div>
                    </div>
                </Col>
                <Col span={3} />
                <Col span={3} />
                <Col span={18}>
                    <div className={cn('tabs__step')}>
                        <img src="/images/nums/02.svg" className={cn('module-block__img-nums')} />
                        <div>{t('in step2')}</div>
                    </div>
                </Col>
                <Col span={3} />
                <Col span={3} />
                <Col span={18}>
                    <div className={cn('tabs__step')}>
                        <img src="/images/nums/03.svg" className={cn('module-block__img-nums')} />
                        <div>{t('in step3')}</div>
                    </div>
                </Col>
                <Col span={3} />
            </Row>
            <Row className={cn('block-auto')} style={{ paddingBottom: '60px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h2>
                        {t('modules')}
                    </h2>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                    <Row className={`${cn('module-block')} ${cn('module-block-column')} ${cn('module-block-frame')}`}>
                        <Col>
                            <img src="/images/module_new1.svg" className={cn('module-block__img')} />
                        </Col>
                        <Col>
                            <h4>{t('module legal1')}</h4>
                            <p>{t('here you can')}:</p>
                            <p>
                                {t('module legal1 desc')}
                            </p>
                            <div className={cn('module-block__btn-block')}>
                                <Button type="primary"
                                    size="large"
                                    onClick={() => goToModule('/catalog-info')}>
                                    {t('go to module')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                    <Row className={`${cn('module-block')} ${cn('module-block-column')} ${cn('module-block-frame')}`}>
                        <Col>
                            <img src="/images/module_new2.svg" className={cn('module-block__img')} />
                        </Col>
                        <Col>
                            <h4>{t('module legal2')}</h4>
                            <p>{t('here you can')}:</p>
                            <p>
                                {t('module legal2 desc')}
                            </p>
                            <div className={cn('module-block__btn-block')}>
                                <Button type="primary"
                                    size="large"
                                    onClick={() => goToModule('/catalog-info')}>
                                    {t('go to module')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                    <Row className={`${cn('module-block')} ${cn('module-block-column')} ${cn('module-block-frame')}`}>
                        <Col>
                            <img src="/images/module_new3.svg" className={cn('module-block__img')} />
                        </Col>
                        <Col>
                            <h4>{t('module legal3')}</h4>
                            <p>{t('here you can')}:</p>
                            <p>
                                {t('module legal3 desc')}
                            </p>
                            <div className={cn('module-block__btn-block')}>
                                <Button type="primary"
                                    size="large"
                                    onClick={() => goToModule('/catalog-info')}>
                                    {t('go to module')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal
                width={420}
                footer={null}
                closable={false}
                destroyOnClose
                visible={active}
                className={cn('modal')}
                onCancel={onCancel}>
                {isLogin
                    ? <LoginForm setReg={setReg} onClose={onCancel} />
                    : <RegForm setLogin={setLogin} />}
            </Modal>
        </div>
    );
};
