import React, {
    useEffect, useState, useMemo
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    Button, Modal, Row, Col, Form, Input, Select, DatePicker, InputNumber
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import '../../style.less';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    getCurrentCalculatorMordovia
} from '../../../../models/mordovia/selectors';
import { toFixed } from '../../../../utils/utils';
import {
    getAgrotechnicalEventsList,
    getVegetationPhases,
    updateAgrotechnicalEvents
} from '../../../../models/agrotechnical-events/actions';
import PlantProtections from '../../../accounting-documents/components/accounting-modal/plant-protections/component';
import {
    curAgroEventsList, curVegetationPhases
} from '../../../../models/agrotechnical-events/selectors';
import { DATEPICKER_FORMAT } from '../../../../constants';

const EventsModal = ({
    fieldId, isOpened, setIsOpened, editableEvent, setEditableEvent
}) => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const { t } = useTranslation('accounting documents');
    const [form] = Form.useForm();

    const information = useSelector(state => getCurrentCalculatorMordovia(state));
    const vegetationPhaseList = useSelector(state => curVegetationPhases(state));
    const agroEventsList = useSelector(state => curAgroEventsList(state));

    const agroEventOptions = useSelector(state => curAgroEventsList(state));

    const [allowProtection, setAllowProtection] = useState(false);
    const [protectionMode, setProtectionMode] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [protectionList, setProtectionList] = useState([]);

    const [listPageSize, setListPageSize] = useState(15);
    const [listPage, setListPage] = useState(1);
    const [isAppendableList, setIsAppendableList] = useState(false);
    const [listOptions, setListOptions] = useState([]);

    useEffect(() => {
        dispatch(getAgrotechnicalEventsList({
            page: listPage,
            pageSize: listPageSize
        }));
    }, [listPage]);

    useEffect(() => {
        const resultsArray = agroEventOptions?.results;

        if (isAppendableList) {
            resultsArray?.length > 0 && setListOptions([...listOptions, ...resultsArray.map(x => {
                return {
                    label: x.name,
                    value: x.name
                };
            })]);
        } else {
            resultsArray?.length > 0 && setListOptions([...resultsArray.map(x => {
                return {
                    label: x.name,
                    value: x.name
                };
            })]);
        }

        if (agroEventOptions?.next?.length > 0) {
            setIsAppendableList(true);
            setListPage(listPage + 1);
        }
    }, [agroEventOptions]);

    const vegPhasesMemo = useMemo(() => {
        const optionEntries = [];
        if (vegetationPhaseList) {
            vegetationPhaseList.forEach((veg_phase) => {
                optionEntries.push({
                    label: veg_phase.name,
                    value: veg_phase.id
                });
            });
        }
        return optionEntries;
    }, [vegetationPhaseList]);

    useEffect(() => {
        if (isOpened) {
            dispatch(getAgrotechnicalEventsList());

            if (information?.culture?.id) {
                dispatch(getVegetationPhases({
                    culture: information.culture.id
                }));
            }

            form.setFieldsValue({
                vega_keys: information?.vega_key,
                culture: information?.culture?.id,
                field_area: information?.area
            });

            if (editableEvent?.id) {
                form.setFieldsValue({
                    plan_date: editableEvent?.plan_date ? moment(editableEvent?.plan_date) : null,
                    fact_date: editableEvent?.fact_date ? moment(editableEvent?.fact_date) : null,
                    vegetation_phase: editableEvent?.event_plan?.vegetation_phase?.name,
                    performer: editableEvent?.performer,
                    event: editableEvent?.event_plan?.event?.name
                });

                const eventName = editableEvent.event_plan?.event?.name;
                if (eventName === 'Внесение удобрений') {
                    setAllowProtection(true);
                    setProtectionMode('fertilizer');
                    setProtectionList(editableEvent.fertilizers);
                } else if (eventName === 'Обработка гербицидами' || eventName.toLowerCase().startsWith('обработка средствами защиты')) {
                    setAllowProtection(true);
                    setProtectionMode('protect');
                    setProtectionList(editableEvent.plant_protections);
                }
            }
        }
    }, [isOpened]);

    const clearModal = () => {
        setProtectionMode(undefined);
        setProtectionList([]);
        form.resetFields();
        setFormError(false);
        setAllowProtection(false);
        setEditableEvent(undefined);
    };

    const handleCancel = () => {
        clearModal();
        setIsOpened(false);
    };

    const onAgroEventSelect = (selectedEvent) => {
        if (selectedEvent === 'Обработка гербицидами' || selectedEvent.toLowerCase().includes('обработка средствами защиты')) {
            setAllowProtection(true);
            setProtectionMode('protect');
        } else if (selectedEvent === 'Внесение удобрений') {
            setAllowProtection(true);
            setProtectionMode('fertilizer');
        }
        setProtectionList([]);
    };

    const onFormFinish = (formValues) => {
        setConfirmLoading(true);
        setFormError(false);

        let requestBody;

        if (editableEvent?.id) {
            requestBody = editableEvent;
            requestBody.vega_keys = [requestBody.vega_key];
            requestBody.event = requestBody.event_plan?.event?.name;
            //
            delete requestBody.vega_key;
            delete requestBody.expert_comment;
            delete requestBody.user_comment;
            delete requestBody.event_plan;
        } else {
            requestBody = formValues;
            requestBody.vega_keys = [requestBody.vega_keys];
        }

        requestBody.performer = formValues?.performer;
        requestBody.plan_date = formValues?.plan_date?.format('YYYY-MM-DD');
        requestBody.fact_date = formValues?.fact_date?.format('YYYY-MM-DD');
        requestBody.year = match.params.year;

        if (protectionMode && protectionMode === 'protect') {
            requestBody.plant_protections = [...protectionList].map(x => {
                return {
                    fertilizer: x.fertilizer.id ? x.fertilizer.id : x.fertilizer,
                    cultivated_area: x.cultivated_area,
                    // ??
                    recommended_amount: x.fact_amount,
                    fact_amount: x.fact_amount
                    //
                };
            });
        }

        if (protectionMode && protectionMode === 'fertilizer') {
            requestBody.fertilizers = [...protectionList].map(x => {
                return {
                    fertilizer: x.fertilizer.id ? x.fertilizer.id : x.fertilizer,
                    cultivated_area: x.cultivated_area,
                    // ??
                    recommended_amount: x.fact_amount,
                    fact_amount: x.fact_amount,
                    //
                    stage: x.stage
                };
            });
        }

        dispatch(updateAgrotechnicalEvents({
            reqType: 'field',
            fieldParams: {
                vega_key: match.params.id,
                year: match.params.year
            },
            body: requestBody
        }));

        setConfirmLoading(false);
        handleCancel();
    };

    const handleDateChange = (a, b) => {
        if (information?.forecast?.items?.length > 0) {
            const vegetPhases = information?.forecast?.items?.sort(function (m, n) {
                return new Date(m.occurrence_date) - new Date(n.occurrence_date);
            });
            let selectedPhase;

            const newSelectedDate = new Date(b);

            vegetPhases.forEach((item) => {
                const startDate = new Date(item.occurrence_date);
                if (newSelectedDate >= startDate) {
                    selectedPhase = item.vegetation_phase_culture_name.replace(information?.culture?.name, '').trim();
                }
            });

            form.setFieldsValue({
                vegetation_phase: selectedPhase
            });
        }
    };

    return (
        <Modal
            className="modal"
            visible={isOpened}
            confirmLoading={confirmLoading}
            width={1136}
            onCancel={handleCancel}
            footer={[
                <div
                    key="footer">
                    <Button
                        key="cancel"
                        onClick={handleCancel}>
                        {t('modal button cancel')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => form.submit()}
                        htmlType="submit">
                        {t('modal button ok')}
                    </Button>
                </div>
            ]}
            title={t('modal add title')}>
            <Form
                form={form}
                onFinishFailed={() => setFormError(true)}
                onFinish={onFormFinish}
                name="add_event_form"
                labelCol={{ flex: '50%' }}
                labelAlign="left">
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form name')}
                            rules={[{ required: true }]}
                            name="vega_keys">
                            <Select
                                disabled
                                value={fieldId} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form vegetation phase')}
                            name="vegetation_phase">
                            <Select
                                allowClear
                                disabled
                                placeholder={t('modal add form vegetation phase placeholder')}
                                options={vegPhasesMemo} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form culture')}
                            rules={[{ required: true }]}
                            name="culture">
                            <Select
                                disabled
                                options={[{
                                    label: information?.culture?.name,
                                    value: information?.culture?.id
                                }]}
                                value={information?.culture?.id} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form plan date')}
                            name="plan_date">
                            <DatePicker
                                format={DATEPICKER_FORMAT}
                                style={{ width: '100%' }}
                                placeholder={t('modal add form fact date placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form culture area')}
                            rules={[{ required: true }]}
                            name="field_area">
                            <InputNumber
                                formatter={(val) => toFixed(val, 2)}
                                style={{ width: '100%' }}
                                placeholder={t('modal add form culture area placeholder')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form fact date')}
                            name="fact_date">
                            <DatePicker
                                format={DATEPICKER_FORMAT}
                                onChange={handleDateChange}
                                style={{ width: '100%' }}
                                placeholder={t('modal add form fact date placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form agrotechnical event')}
                            rules={[{ required: true }]}
                            name="event">
                            <Select
                                allowClear
                                placeholder={t('modal add form agrotechnical event placeholder')}
                                onChange={onAgroEventSelect}
                                // options={agroEventOptionsMemo}
                                options={listOptions} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t('modal add form performer')}
                            rules={[{ required: true }]}
                            name="performer">
                            <Input
                                placeholder={t('modal add form performer placeholder')}
                                allowClear />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {allowProtection && (
                <PlantProtections
                    relatedCulture={information?.culture?.id}
                    setProtectionList={setProtectionList}
                    protectionList={protectionList}
                    protectionMode={protectionMode} />
            )}

            {formError && (
                <div className="accounting-documents__error_message">
                    <CloseCircleOutlined />
                    <div>{t('modal error message')}</div>
                </div>
            )}
        </Modal>
    );
};

export default EventsModal;