import * as constants from '../constants';
import {
    clearCalculatorMordovia, getCalculatorMordovia, addGroupCalculate, setActiveCalculate
} from '../../mordovia/constants';
import { getNormative, addNormative, updateNormative } from '../../item-normative/constants';
import { getRB, addRB, updateRB } from '../../item-rb/constants';
import { getDistribution, addDistribution, updateDistribution } from '../../item-distribution/constants';
import { getInterpretation, addInterpretation, updateInterpretation } from '../../item-interpretation/constants';
import { addNutrition, getNutrition, updateNutrition } from '../../item-nutrition/constants';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculate.success:
        case constants.updateCalculate.success:
        case constants.addCalculate.success: {
            return action.payload;
        }

        case getCalculatorMordovia.success: {
            const calculator = { ...action.payload?.calculator };
            return calculator;
        }

        case addGroupCalculate.success: {
            const calculator = { ...action.payload?.calculator };
            return calculator;
        }

        case setActiveCalculate.toString(): {
            return action.payload;
        }

        case clearCalculatorMordovia.toString():
        case constants.clearCalculate.toString(): {
            return initialState;
        }

        case getNormative.success:
        case addNormative.success:
        case updateNormative.success: {
            return { ...state, normative: { ...action.payload } };
        }

        case getNutrition.success:
        case addNutrition.success:
        case updateNutrition.success: {
            return { ...state, nutrition: { ...action.payload } };
        }

        case getRB.success:
        case addRB.success:
        case updateRB.success: {
            return { ...state, rb: { ...action.payload } };
        }

        case getDistribution.success:
        case addDistribution.success:
        case updateDistribution.success: {
            return { ...state, distribution: { ...action.payload } };
        }
        case getInterpretation.success:
        case addInterpretation.success:
        case updateInterpretation.success: {
            return { ...state, agrochemical: { ...action.payload } };
        }

        default:
            return state;
    }
}
