import * as constants from './constants';

export const getHarmfulObjects = payload => ({
    type: constants.getHarmfulObjects.toString(),
    payload
});

export const getHarmfulObject = payload => ({
    type: constants.getHarmfulObject.toString(),
    payload
});

export const getHarmfulObjectDivisions = payload => ({
    type: constants.getHarmfulObjectDivisions.toString(),
    payload
});