import * as constants from './constants';

export const getRB = payload => ({
    type: constants.getRB.toString(),
    payload
});

export const getRBs = payload => ({
    type: constants.getRBs.toString(),
    payload
});

export const updateRB = payload => ({
    type: constants.updateRB.toString(),
    payload
});

export const addRB = payload => ({
    type: constants.addRB.toString(),
    payload
});

export const clearRB = payload => ({
    type: constants.clearRB.toString()
});
