import * as constants from './constants';

export const getCalculatorsFed = payload => ({
    type: constants.getCalculatorsFed.toString(),
    payload
});

export const getCalculatorFed = payload => ({
    type: constants.getCalculatorFed.toString(),
    payload
});

export const addCalculatorFed = payload => ({
    type: constants.addCalculatorFed.toString(),
    payload
});

export const setParametersCalculatorFed = payload => ({
    type: constants.setParametersCalculatorFed.toString(),
    payload
});

export const removeCalculatorFed = payload => ({
    type: constants.removeCalculatorFed.toString(),
    payload
});

export const clearCalculatorFed = payload => ({
    type: constants.clearCalculatorFed.toString()
});

export const closeCalculatorFed = payload => ({
    type: constants.closeCalculatorFed.toString(),
    payload
});