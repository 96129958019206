import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { Table, TableFooter, Tooltip } from '../../../components';
import cn from '../../../utils/cn';

const ROW_DIVIDER = 10;

@withRouter
@withTranslation('diseases')
@cn('diseases-table')
export default class DiseasesTable extends Component {
    get requestsColumns() {
        const { cn } = this;
        const {
            t, history, pageNum, pageSize, filterOptions
        } = this.props;

        return [
            {
                title: t('name'),
                dataIndex: 'name',
                key: 'name',
                className: cn('name'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}>
                        <Link
                            className={cn('link')}
                            to={{
                                pathname: `/disease/${record?.id}`,
                                pageNum,
                                pageSize,
                                filterOptions
                            }}>
                            {text?.trim()}
                        </Link>
                    </Tooltip>
                )

            },
            {
                title: t('cultures diseases'),
                dataIndex: 'cultures',
                key: 'culture',
                className: cn('culture'),
                render: value => value?.length > 0 ? value?.map(x => x?.culture?.name)?.sort((a,b)=>a?.localeCompare(b))?.join(', ') : '-'
            },
            {
                title: t('pathogen'),
                dataIndex: 'causative_agent',
                key: 'causative_agent',
                className: cn('pathogen'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text?.name}>
                        <Link
                            className={cn('link')}
                            to={{
                                pathname: `/disease/${record?.id}`,
                                pageNum,
                                pageSize,
                                filterOptions
                            }}>
                            {text?.name}
                        </Link>
                    </Tooltip>
                )
            },
            {
                title: t('economicThresholdOfHarmfulness'),
                dataIndex: 'economic_threshold_of_harmfulness',
                key: 'economic_threshold_of_harmfulness',
                className: cn('economicThresholdOfHarmfulness'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}>
                        <Link
                            className={cn('link')}
                            to={{
                                pathname: `/disease/${record?.id}`,
                                pageNum,
                                pageSize,
                                filterOptions
                            }}>
                            {text}
                        </Link>
                    </Tooltip>
                )
            }
        ];
    }

    render() {
        const { cn } = this;
        const {
            history,
            onCurrentPageChange,
            onPageSizeChange,
            filterItems,
            pageSize,
            pageNum,
            disease,
            dataSource,
            dataSize
        } = this.props;

        return (
            <div>

                <Table
                    dataSource={dataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
                {dataSize > 1 && (
                    <TableFooter
                        filterItems={filterItems}
                        showSizeChanger={false}
                        onSelect={onPageSizeChange}
                        onChange={onCurrentPageChange}
                        pageSize={pageSize}
                        current={pageNum}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                )}
            </div>
        );
    }
}