import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Spin } from 'antd';
import {
    InfoMessage, Table, TableFooter, Tooltip
} from '../../../components';
import Popover from './action-popover';
import cn from '../../../utils/cn';

import { selectors, actions } from '../../../models';

const {
    getCalculatorsFluid,
    removeCalculatorFluid,
    clearCalculatorFluid
} = actions;
const {
    getCalculatorsFluidList,
    getCalculatorsFluidListCount,
    isCalculatorFluidLoading
} = selectors;

const mstp = state => ({
    dataSource: getCalculatorsFluidList(state),
    dataSize: getCalculatorsFluidListCount(state),
    loading: isCalculatorFluidLoading(state)
});

const mdtp = {
    getCalculatorsFluid,
    removeCalculatorFluid,
    clearCalculatorFluid
};

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_TABLE_PAGE = 1;

@withRouter
@connect(mstp, mdtp)
@withTranslation('calculators table')
@cn('calculators-table')
export default class CalculatorsTable extends Component {
    state = {
        page: DEFAULT_TABLE_PAGE,
        page_size: ROW_DIVIDER,
        infoMessageVisible: false,
        deleteId: null
    };

    componentDidMount() {
        const { getCalculatorsFluid, clearCalculatorFluid } = this.props;
        clearCalculatorFluid();
        getCalculatorsFluid(this.initRequest);
    }

    onPageSizeChange = (page_size) => {
        const { getCalculatorsFluid } = this.props;
        this.setState({
            page_size
        }, () => getCalculatorsFluid(this.initRequest));
    };

    onCurrentPageChange = (page) => {
        const { getCalculatorsFluid } = this.props;

        this.setState({
            page
        }, () => getCalculatorsFluid(this.initRequest));
    };

    get initRequest() {
        const { page, page_size } = this.state;
        const { userId } = this.props;
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        return `?${page_size_url}&${page_url}`;
    }

    get filterItems() {
        const { dataSize } = this.props;
        const items = [];

        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }

        return items;
    }

    get pagesCount() {
        const { dataSize } = this.props;
        const { page_size } = this.state;

        if (!dataSize) {
            return 0;
        }

        return (dataSize / page_size) * ROW_DIVIDER;
    }

    get requestsColumns() {
        const { cn } = this;
        const { t, history } = this.props;

        return [
            {
                title: t('title'),
                dataIndex: 'name',
                key: 'name',
                className: cn('title'),
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['ascend', 'descend'],
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}
                        onClick={() => history.push(`/calculator-fluid/${record.id}`)}>
                        {text}
                    </Tooltip>
                )

            },
            {
                title: t('create date'),
                dataIndex: 'creation_date',
                key: 'creation_date',
                className: cn('createDate'),
                sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
                sortDirections: ['descend', 'ascend'],
                render: (text) => moment(text).format('DD.MM.YYYY')
            },
            {
                title: t('status'),
                dataIndex: 'is_done',
                key: 'is_done',
                className: cn('status'),
                render: (text) => (
                    <>
                        {text && t('COMPLETED')}
                        {!text && t('IN_PROGRESS')}
                    </>
                )
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: cn('action'),
                fixed: 'right',
                render: (text, record) => (
                    <div className={cn('button')}>
                        <Popover
                            status={record.status}
                            id={record.id}
                            placement="bottomRight"
                            url="calculator-fluid"
                            trigger="click"
                            deleteClick={() => this.showDeleteModal(record.id)} />
                    </div>
                )
            }
        ];
    }

    removeCalculator = () => {
        const { removeCalculatorFluid, getCalculatorsFluid } = this.props;
        const { deleteId } = this.state;

        removeCalculatorFluid(deleteId);

        this.hideDeleteModal();
    };

    hideDeleteModal = () => {
        this.setState({ infoMessageVisible: false });
    };

    showDeleteModal = id => {
        this.setState({
            deleteId: id,
            infoMessageVisible: true
        });
    };

    render() {
        const { cn } = this;
        const {
            dataSource,
            t,
            loading
        } = this.props;
        const { infoMessageVisible, page_size } = this.state;
        if (!dataSource.length) {
            return (
                <div className={cn('empty')}>
                    {loading ? <Spin /> : t('no')}
                </div>
            );
        }
        return (
            <div className={cn()}>
                <Table
                    dataSource={dataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
                <TableFooter
                    filterItems={this.filterItems}
                    showSizeChanger={false}
                    onSelect={this.onPageSizeChange}
                    onChange={this.onCurrentPageChange}
                    pageSize={page_size}
                    rowDivider={ROW_DIVIDER.toString()}
                    dataSize={this.pagesCount} />
                <InfoMessage
                    visible={infoMessageVisible}
                    title={t('delete title')}
                    okBtn={t('delete')}
                    cancelBtn={t('cancel')}
                    onOk={this.removeCalculator}
                    onCancel={this.hideDeleteModal} />
            </div>
        );
    }
}