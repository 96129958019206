import React, { Component } from 'react';
import { Pagination as AntPagination } from 'antd';

import cn from '../../utils/cn';

import './style.less';

@cn('pagination')
export default class Pagination extends Component {
    render() {
        const { cn } = this;
        const {
            ...rest
        } = this.props;

        return (
            <AntPagination className={cn()} {...rest} />
        );
    }
}
