import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import { getPesticideProductApplicationItem } from '../pesticides/api';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

function* removeCalculatorFluidSaga({ payload: id }) {
    const [start, success, failure] = getActions(
        constants.removeCalculatorFluid.try,
        constants.removeCalculatorFluid.success,
        constants.removeCalculatorFluid.error
    );
    try {
        yield put(start(id));
        yield call(api.removeCalculatorFluid, id);
        const obj = converter.removedCalculatorId2Client(id);
        yield put(success(obj));
    } catch (error) {
        yield put(failure(error));
    }
}

function* getCalculatorFluidSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getCalculatorFluid.try,
        constants.getCalculatorFluid.success,
        constants.getCalculatorFluid.error
    );
    try {
        yield put(start(payload));
        const data = yield call(api.getCalculatorFluid, payload);
        const { plant_protection_product_application } = data;
        if (plant_protection_product_application) {
            yield put(actions.getPlantProtectionProductCalculatorFluid(plant_protection_product_application));
        }
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsFluid.toString(),
            defaultSaga({
                constants: constants.getCalculatorsFluid,
                api: api.getCalculatorsFluid
            })
        ),
        yield takeLatest(
            constants.getCalculatorFluid.toString(),
            getCalculatorFluidSaga
        ),
        yield takeLatest(
            constants.addCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.addCalculatorFluid,
                api: api.addCalculatorFluid
            })
        ),
        yield takeLatest(
            constants.setParamsCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.setParamsCalculatorFluid,
                api: api.setParamsCalculatorFluid
            })
        ),
        yield takeLatest(
            constants.setPlantProtectionCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.setPlantProtectionCalculatorFluid,
                api: api.setPlantProtectionCalculatorFluid
            })
        ),
        yield takeLatest(
            constants.closeCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.closeCalculatorFluid,
                api: api.closeCalculatorFluid
            })
        ),
        yield takeLatest(
            constants.getRateNozzleCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.getRateNozzleCalculatorFluid,
                api: api.getRateNozzleCalculatorFluid
            })
        ),
        yield takeLatest(
            constants.getNozzleColorCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.getNozzleColorCalculatorFluid,
                api: api.getNozzleColorCalculatorFluid
            })
        ),
        yield takeLatest(
            constants.getNozzlePressureCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.getNozzlePressureCalculatorFluid,
                api: api.getNozzlePressureCalculatorFluid
            })
        ),
        yield takeLatest(
            constants.getPlantProtectionProductCalculatorFluid.toString(),
            defaultSaga({
                constants: constants.getPlantProtectionProductCalculatorFluid,
                api: getPesticideProductApplicationItem
            })
        ),
        yield takeLatest(
            constants.removeCalculatorFluid.toString(),
            removeCalculatorFluidSaga
        )
    ]);
}