import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Spin } from 'antd';

import cn from '../../utils/cn';

import { SideBar } from '../../components';

import './style.less';
import DeterminantDetail from './components/determinant-detail';
import { actions, selectors } from '../../models';

const { Content } = Layout;

const {
    getDeterminant,
    clearDeterminant
} = actions;
const {
    getCurrentDeterminant,
    isDeterminantLoading
} = selectors;

const mstp = (state) => ({
    determinant: getCurrentDeterminant(state),
    loading: isDeterminantLoading(state)
});
const mdtp = ({
    getDeterminant,
    clearDeterminant
});

@withRouter
@connect(mstp, mdtp)
@cn('all-determinants')
export default class Determinant extends Component {
    state = {
        id: null,
        name: false
    };

    componentDidMount() {
        const { getDeterminant, clearDeterminant, match } = this.props;
        const { id } = match.params;
        clearDeterminant();
        if (id) {
            getDeterminant(id);
            this.setState({ id });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { determinant, match, clearDeterminant } = this.props;
        const { id } = match.params;
        if (prevProps.determinant !== determinant) {
            this.setName(determinant.title);
        }
    }

    setName = (name) => {
        this.setState({ name });
    };

    render() {
        const { cn } = this;
        const { determinant, loading } = this.props;
        const { name, id } = this.state;

        return (
            <Layout className={cn()}>
                <DeterminantDetail
                    loading={loading}
                    determinant={determinant}
                    id={id}
                    name={name} />
            </Layout>
        );
    }
}
