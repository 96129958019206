import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, Icon, Form, InputNumber
} from 'antd';

import cn from '../../../utils/cn';
import {
    Title, Button, Tabs, InfoMessage, SaveMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

const {
    setParametersCalculatorFed
} = actions;
const {
    getCurrentCalculatorFed
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFed(state)
});
const mdtp = ({
    setParametersCalculatorFed
});

const { TabPane } = Tabs;
const { Option } = Select;

const REG_EXP = /^[0-9]*[.]?[0-9]+$/;

const elements = ['k2', 'n', 'p', 'kcl', 'ks', 'k3', 'ca', 'mg', 'b', 'cu', 'k4', 'zn', 'mn', 'fe', 'k5', 'mo', 'co', 'i', 'k6'];

@withRouter
@connect(mstp, mdtp)
@withTranslation('calculator params fed')
@cn('calculator-detail-fed-params')
export default class CalculatorParamsFed extends Component {
    formRef = React.createRef();

    state = {
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {}
    };

    componentDidMount() {
        const { calculator, history, match } = this.props;
        this.initForm(calculator);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calculator, history, match } = this.props;
        const { id } = match.params;
        if (prevProps.calculator.id !== calculator.id) {
            this.initForm(calculator);
        }
        if (prevProps.calculator.mean !== calculator.mean) {
            this.initForm(calculator);
        }
    }

    onSubmit = (values) => {
        const { setParametersCalculatorFed, match, onTabChange } = this.props;
        const { id } = match.params;
        const notEmptyValues = this.notEmptyValues(values);
        const newValues = { ...notEmptyValues };
        setParametersCalculatorFed({ id, values: newValues });
    };

    initForm = (calculator) => {
        const values = {};
        elements.forEach(elem => {
            values[`c_${elem}`] = calculator[`c_${elem}`] || 0;
            values[`d_${elem}`] = calculator[`d_${elem}`] || 0;
        });
        this.formRef.current.setFieldsValue(values);
    };

    closeCalculator = () => {
        const { history } = this.props;
        history.push('/calculators/');
    };

    notEmptyValues = (values) => {
        const result = {};
        elements.forEach(elem => {
            result[`c_${elem}`] = values[`c_${elem}`] || 0;
            result[`d_${elem}`] = values[`d_${elem}`] || 0;
        });
        return result;
    };


    onFinish = (values) => {
        const { setParametersCalculatorFed, match } = this.props;
        const { id } = match.params;
        const valuesToSend = {};
        elements.forEach(key => {
            valuesToSend[`c_${key}`] = values[`c_${key}`].value || 0;
            valuesToSend[`d_${key}`] = values[`d_${key}`].value || 0;
        });
        setParametersCalculatorFed({ id, values: valuesToSend });
    };

    resetFields = () => {
        this.formRef.current.resetFields();
    };

    render() {
        const { cn } = this;
        const { t, isCompleted, calculator } = this.props;

        return (
            <Form ref={this.formRef}
                onFinish={this.onSubmit}
                className={cn()}
                name="calculator-params-fed">
                <div>
                    <div className={cn('title')}>{t('title')}</div>
                    <Row>
                        <Col>
                            <div className={cn('green-border')}>
                                {t('title info')}
                            </div>
                        </Col>
                    </Row>
                    <div className={cn('subtitle')}>{t('subtitle')}</div>
                    <Row className={`${cn('title-line')} ${cn('border')}`}>
                        <Col span={4} />
                        <Col span={2} className={cn('gray-col')}>
                            {t('K2')}
                        </Col>
                        <Col span={2}>
                            {t('N')}
                        </Col>
                        <Col span={2}>
                            {t('P')}
                        </Col>
                        <Col span={2}>
                            {t('K')}
                        </Col>
                        <Col span={2}>
                            {t('S')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {t('K3')}
                        </Col>
                        <Col span={2}>
                            {t('Ca')}
                        </Col>
                        <Col span={2}>
                            {t('Mg')}
                        </Col>
                        <Col span={2}>
                            {t('B')}
                        </Col>
                        <Col span={2}>
                            {t('Cu')}
                        </Col>
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('results before')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="c_k2">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_n">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_p">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_ks">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="any"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_kcl">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="c_k3">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_ca">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_mg">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_b">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_cu">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('results after')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="d_k2">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_n">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_p">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="any"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_kcl">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_ks">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="d_k3">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_ca">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_mg">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_b">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_cu">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={`${cn('title-line')} ${cn('border')}`}>
                        <Col span={4} />
                        <Col span={2} className={cn('gray-col')}>
                            {t('K4')}
                        </Col>
                        <Col span={2}>
                            {t('Zn')}
                        </Col>
                        <Col span={2}>
                            {t('Mn')}
                        </Col>
                        <Col span={2}>
                            {t('Fe')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {t('K5')}
                        </Col>
                        <Col span={2}>
                            {t('Mo')}
                        </Col>
                        <Col span={2}>
                            {t('Co')}
                        </Col>
                        <Col span={2}>
                            {t('I')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {t('K6')}
                        </Col>
                        <Col span={2} />
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('results before')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="c_k4">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_zn">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_mn">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_fe">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="c_k5">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_mo">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_co">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="c_i">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="c_k6">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('results after')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="d_k4">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_zn">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_mn">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_fe">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="d_k5">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_mo">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_co">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name="d_i">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            <Form.Item name="d_k6">
                                <InputNumber
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                    </Row>

                </div>
                <Row className={cn('button')}>
                    <Col>
                        <Button
                            type="primary"
                            disabled={isCompleted}
                            htmlType="submit"
                            size="large">
                            {t('calculate')}
                        </Button>
                    </Col>
                    <Col span={1} />
                    <Col>
                        <Button
                            type="secondary"
                            onClick={this.resetFields}
                            size="large">
                            {t('clear')}
                        </Button>
                    </Col>
                </Row>
            </Form>


        );
    }
}