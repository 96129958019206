import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getMeteodata.try:
        case constants.getEffectiveMeteodata.try: {
            return true;
        }

        case constants.getMeteodata.success:
        case constants.getMeteodata.error:
        case constants.getEffectiveMeteodata.success:
        case constants.getEffectiveMeteodata.error: {
            return false;
        }

        default:
            return state;
    }
}