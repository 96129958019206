import * as constants from '../constants';
import converter from '../converter';

const initialState = converter._server2Client({});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorMyco.success:
        case constants.setParamsCalculatorMyco.success:
        case constants.addCalculatorMyco.success: {
            return action.payload;
        }

        case constants.closeCalculatorMyco.success: {
            const { is_done } = action.payload;
            return { ...state, is_done };
        }

        case constants.clearCalculatorMyco.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
