import { combineReducers } from 'redux';

import data from './data';
import list from './list';
import loading from './loading';
import registryDoc from './registry-doc';
import userList from './user-list';
import filteredFields from './filtered-fields';
import uploadedFile from './uploaded-file';
import newFilename from './new-filename';

export default combineReducers({
    data,
    list,
    loading,
    registryDoc,
    userList,
    filteredFields,
    uploadedFile,
    newFilename
});
