import {
    all, takeLatest
} from 'redux-saga/effects';
import { defaultSaga } from '../utils';

import * as constants from './constants';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getAgroEvents.toString(),
            defaultSaga({
                constants: constants.getAgroEvents,
                api: api.getAgroEvents
            })
        ),
        yield takeLatest(
            constants.updateAgroEvent.toString(),
            defaultSaga({
                constants: constants.updateAgroEvent,
                api: api.updateAgroEvent
            })
        )
    ]);
}