import React from 'react';
import moment from 'moment';
import {
    Col, Row, Collapse, Input, DatePicker, Form
} from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import './style.less';

import { useDispatch, useSelector } from 'react-redux';

import { CaretRightFilled } from '@ant-design/icons';
import { selectors, actions } from '../../models';
import {useRouteMatch} from "react-router-dom";


const {
    curEvents
} = selectors;

const {
    updateAgroEvent
} = actions;

const { Search } = Input;

export default ({ isCompleted }) => {
    const cn = useClassName('events');
    const match = useRouteMatch();
    const events = useSelector(state => curEvents(state));
    const dispatch = useDispatch();
    const { year } = match.params;
    const { t } = useTranslation('events');

    const eventInfoChange = (e, id) => {
        if (id && (typeof e === 'string')) {
            const user_comment = e;
            dispatch(updateAgroEvent({ id, year, body: { user_comment } }));
        }
        if (id && (typeof e !== 'string')) {
            const fact_date = moment(e).format('YYYY-MM-DD');
            dispatch(updateAgroEvent({ id, year, body: { fact_date } }));
        }
    };

    if (!events?.length) {
        return (
            <Row className={cn('info')}>
                <Col>
                    <div className={cn('info__inner')}>
                        Для культуры не указана схема вегетации
                    </div>
                </Col>
            </Row>
        );
    }
    return (
        <Form className={cn()}>
            <div className="row-table">
                <Row className="row-head">
                    <Col span={3} className="row-table__label">{t('phase')}</Col>
                    <Col span={3} className="row-table__description">{t('period')}</Col>
                    <Col span={5} className="row-table__description">{t('events')}</Col>
                    <Col span={4} className="row-table__description">{t('data plan')}</Col>
                    <Col span={4} className="row-table__description">{t('comment expert')}</Col>
                    <Col span={5} className="row-table__description">{t('comment user')}</Col>
                </Row>
                {
                    events?.map(phase => (
                        phase?.events?.length > 0 ? (
                            phase?.events.map(event => (
                                <RowTable phase={phase?.vegetation_phase}
                                    t={t}
                                    onChange={eventInfoChange}
                                    start_date={phase?.start_date}
                                    end_date={phase?.end_date}
                                    event={event} />
                            ))
                        ) : (
                            <RowTable phase={phase?.vegetation_phase}
                                t={t}
                                onChange={eventInfoChange}
                                start_date={phase?.start_date}
                                end_date={phase?.end_date}
                                event={null} />
                        )
                    ))
                }
            </div>
        </Form>
    );
};

const checkDateInPeriod = (dateBefore, dateAfter) => {
    const curDate = new Date().getTime();
    const prevDate = new Date(dateBefore).getTime();
    const nextDate = dateAfter ? new Date(dateAfter).getTime() : new Date().getTime();
    if (curDate >= prevDate && !dateAfter) {
        return true;
    }
    if (curDate < prevDate) {
        return false;
    }
    if (curDate >= prevDate && curDate < nextDate && dateAfter) {
        return true;
    }
    return false;
};

const RowTable = ({
    phase, start_date, end_date, event, isCompleted, t, onChange
}) => (
    <Row className={`row-table__withborder ${checkDateInPeriod(start_date, end_date) && 'row-green'}`}>
        <Col span={3} className="row-table__label">{phase}</Col>
        <Col span={3} className="row-table__description">
            {start_date && moment(start_date).format('DD.MM.YY')}
            -
            {end_date && moment(end_date).format('DD.MM.YY')}
        </Col>
        <Col span={5} className="row-table__description">{event?.event_plan?.event?.name}</Col>
        <Col span={4} className="row-table__description">{event?.planned_date}</Col>
        <Col span={4} className="row-table__description">{event?.expert_comment}</Col>
        <Col span={5} className="row-table__description">
            <Search
                disabled={isCompleted || !event?.id}
                placeholder={t('enter comment')}
                enterButton={<CaretRightFilled />}
                allowClear
                onSearch={(e) => onChange(e, event?.id)}
                defaultValue={event?.user_comment} />

        </Col>
    </Row>
);