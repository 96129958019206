import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private exact
        key="harmful-objects-route"
        path="/harmful-objects"
        component={Page} />
];

export default Routes;