import { isLoading } from '../../account/selectors';
import * as constants from '../constants';

const initialState = {
    isLoading: false,
    results: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getDownloadableDataset.success: {
            return { isLoading: false, results: action.payload.results };
        }

        case constants.getDownloadableDataset.error: {
            return initialState;
        }
        case constants.getDownloadableDataset.try: {
            return { isLoading: true, results: [] };
        }

        default:
            return state;
    }
}
