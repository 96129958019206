import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import { Table } from '../../../components';

import { selectors } from '../../../models';

import '../style.less';
import * as constants from '../../../constants';

const { tableByCulture, tableTotalByCulture } = selectors;
export default () => {
    const { t } = useTranslation('pivot table');
    const cn = useClassName('calculator');
    const field = useSelector(state => tableByCulture(state));
    const total = useSelector(state => tableTotalByCulture(state));

    const [dataSource, setDataSource] = useState([]);
    const [dataSourceTotal, setDataSourceTotal] = useState([]);
    const [rowSpan, setRowSpan] = useState({
        rowSpan0: [],
        rowSpanNot0: [],
        rowSpanCount: {}
    });

    const getMacro = (fertilizer) => {
        const arr = [];
        constants.macroTypes.forEach(item => {
            if (fertilizer[item]) { arr.push(t(item, { value: fertilizer[item] })); }
        });
        const str = arr.join(', ');
        return str;
    };

    const convertedFields = (data) => {
        const arr = [];
        const rowSpanInfo = {
            rowSpan0: [],
            rowSpanNot0: [],
            rowSpanCount: {}
        };
        let index = 0;
        data?.forEach(item => {
            const { fertilizers } = item;
            fertilizers?.forEach((fertilizer, i) => {
                arr.push({
                    fertilizer: fertilizer?.fertilizer,
                    weight_norm: fertilizer.weight_norm,
                    weight_by_area: fertilizer.weight_by_area,
                    price: fertilizer.price,
                    total_price: fertilizer.total_price,
                    ...item
                });
                if (fertilizers.length > 1 && i > 0) {
                    rowSpanInfo.rowSpan0.push(index);
                }
                if (fertilizers.length > 1 && i === 0) {
                    rowSpanInfo.rowSpanNot0.push(index);
                    rowSpanInfo.rowSpanCount[index] = fertilizers.length;
                }
                index++;
            });
        });

        return { arr, rowSpanInfo };
    };

    useEffect(() => {
        const data = convertedFields(field);

        setDataSource(data.arr);
        setRowSpan(data.rowSpanInfo);
    }, [field]);

    useEffect(() => {
        setDataSourceTotal(total);
    }, [total]);

    const requestsColumns = [
        {
            title: t('culture'),
            dataIndex: 'name',
            key: 'name',
            render: (value, record, index) => {
                if (rowSpan.rowSpan0.includes(index)) {
                    return { props: { rowSpan: 0 } };
                }
                if (rowSpan.rowSpanNot0.includes(index)) {
                    return {
                        children: value,
                        props: { rowSpan: rowSpan.rowSpanCount[index] }
                    };
                }
                return {
                    children: value
                };
            }
        },
        {
            title: t('area'),
            dataIndex: 'area',
            key: 'area',
            render: (value, record, index) => {
                if (rowSpan.rowSpan0.includes(index)) {
                    return { props: { rowSpan: 0 } };
                }
                if (rowSpan.rowSpanNot0.includes(index)) {
                    return {
                        children: value?.toFixed(2),
                        props: { rowSpan: rowSpan.rowSpanCount[index] }
                    };
                }
                return {
                    children: value?.toFixed(2)
                };
            }
        },
        {
            title: t('fertilizer_name'),
            dataIndex: 'fertilizer',
            key: 'fertilizer_name',
            render: value => value.name
        },
        {
            title: t('fertilizer_content'),
            dataIndex: 'fertilizer',
            key: 'fertilizer_content',
            render: (value, record) => getMacro(record.fertilizer)
        },
        {
            title: t('fertilizer_group'),
            dataIndex: 'fertilizer',
            key: 'fertilizer_group',
            render: value => ({
                children: value?.group?.name,
                props: {}
            })
        },
        {
            title: t('norm'),
            children: [
                {
                    title: t('weight_norm'),
                    dataIndex: 'weight_norm',
                    key: 'weight_norm',
                    render: value => ({
                        children: value?.toFixed(2),
                        props: {}
                    })
                },
                {
                    title: t('weight_by_area'),
                    dataIndex: 'weight_by_area',
                    key: 'weight_by_area',
                    render: value => ({
                        children: value?.toFixed(2),
                        props: {}
                    })
                }
            ]
        },
        {
            title: t('price'),
            dataIndex: 'price',
            key: 'price',
            render: value => ({
                children: value?.toFixed(2),
                props: {}
            })
        },
        {
            title: t('total_price'),
            dataIndex: 'total_price',
            key: 'total_price',
            render: value => ({
                children: value?.toFixed(2),
                props: {}
            })
        }
    ];

    const requestsColumnsTotal = [
        {
            title: t('total area'),
            dataIndex: 'area'
        },
        {
            title: t('fertilizer_name'),
            dataIndex: 'fertilizer',
            render: value => value?.name
        },
        {
            title: t('total weight'),
            dataIndex: 'weight'
        },
        {
            title: t('total field price'),
            dataIndex: 'price'
        }
    ];

    return (
        <div>
            <div className={cn('row-table')}>
                <Table
                    id="culture-report"
                    dataSource={dataSource}
                    scroll={{ y: 240, x: 1300 }}
                    columns={requestsColumns}
                    pagination={false} />
            </div>
            {/* <div className={cn('row-table')}>
                <Table
                    id="culture-report-total"
                    dataSource={dataSourceTotal}
                    columns={requestsColumnsTotal}
                    pagination={false} />
            </div> */}
        </div>
    );
};