import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';


import Page from './component';

export default
<Route
    exact
    key="logout"
    path="/user-activate/:uid/:token"
    component={Page} />;