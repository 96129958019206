import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntTabs } from 'antd';

import cn from '../../utils/cn';
import './style.less';

const { TabPane: AntTabPane } = AntTabs;

@cn('tabs')
class Tabs extends Component {
    render() {
        const { cn } = this;
        const {
            className, ...rest
        } = this.props;

        return (
            <AntTabs
                className={`${cn()} ${className}`}
                {...rest} />
        );
    }
}

Tabs.propTypes = {
    /** Дополнительное имя класса */
    className: PropTypes.string
};

Tabs.defaultProps = {
    className: ''
};

Tabs.TabPane = AntTabPane;

export default Tabs;