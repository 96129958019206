import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { patchAgrotechnicalEvent } from '../../../../models/agrotechnical-events/actions';
import './style.less';

const { TextArea } = Input;

const CommentModal = ({
    isOpened, setIsOpened, commentMode, commentableEvent, updateEvents, existingText
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('accounting documents');

    const [commentText, setCommentText] = useState('');

    useEffect(() => {
        if (existingText?.length > 0) {
            setCommentText(existingText);
        }
    }, [existingText]);

    const handleSubmit = () => {
        switch (commentMode) {
            case 'user':
                dispatch(patchAgrotechnicalEvent({
                    eventId: commentableEvent.id,
                    body: {
                        user_comment: commentText
                    }
                }));
                setTimeout(() => {
                    setCommentText('');
                    updateEvents();
                    setIsOpened(false);
                }, 1000);
                break;
            case 'expert':
                dispatch(patchAgrotechnicalEvent({
                    eventId: commentableEvent.id,
                    body: {
                        expert_comment: commentText
                    }
                }));
                setTimeout(() => {
                    setCommentText('');
                    updateEvents();
                    setIsOpened(false);
                }, 1000);
                break;
            default:
                break;
        }
    };

    const handleCancel = () => {
        setCommentText('');
        setIsOpened(false);
    };

    const stageConverter = {
        main: 'Основной',
        sowing: 'Припосевной',
        feeding: 'Подкормка'
    };

    return (
        <Modal
            className="comment-modal"
            visible={isOpened}
            width={640}
            onCancel={handleCancel}
            footer={[
                <div
                    key="footer">
                    <Button
                        key="cancel"
                        onClick={handleCancel}>
                        {t('modal button cancel')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleSubmit}
                        htmlType="submit">
                        {t('modal button ok')}
                    </Button>
                </div>
            ]}
            title={[
                <div className="comment-modal__section__header">
                    {commentableEvent?.event_plan?.event?.name || ''}
                </div>
            ]}>

            <div className="comment-modal__section">
                <div className="comment-modal__section__header">
                    Название препарата
                </div>
                <div className="comment-modal__section__content">
                    {commentableEvent?.fertilizers?.length > 0 && commentableEvent.fertilizers.map(x => x.fertilizer.name).join(', ')}
                    {commentableEvent?.plant_protections?.length > 0 && commentableEvent.plant_protections.map(x => x.fertilizer.name).join(', ')}
                </div>
            </div>

            <div className="comment-modal__section">
                <div className="comment-modal__section__header">
                    Этап
                </div>
                <div className="comment-modal__section__content">
                    {commentableEvent?.fertilizers?.length > 0 ? commentableEvent.fertilizers.map(x => stageConverter[x.stage]).join(', ') : '-'}
                </div>
            </div>

            <div className="comment-modal__section">
                <div className="comment-modal__section__header">
                    Комментарий пользователя
                </div>
                <TextArea
                    rows={4}
                    placeholder="Введите комментарий"
                    onChange={evt => setCommentText(evt.target.value)}
                    value={commentText} />
            </div>
        </Modal>
    );
};

export default CommentModal;