import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getCalculatorsMordovia = filter => request.get({
    url: urlPath(options.apiMordoviaUrl, filter)
});

export const getCalculatorMordovia = ({ id }) => request.get({
    url: urlPath(options.apiMordoviaUrl, `${id}/`)
});

export const getCalculatorsMordoviaShape = filter => request.get({
    url: urlPath(options.apiMordoviaShapeUrl, filter)
});

export const getCalculatorsMordoviaForecast = ({ id, year }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${id}/forecast/`),
    body: { year }
});

export const updateCalculatorsMordoviaForecast = ({ id, body }) => request.patch({
    url: urlPath(options.apiMordoviaUrl, `${id}/forecast/`),
    body
});

export const updateFieldYield = ({ yearId, body }) => request.patch({
    url: `/api/v1/mordovia/field_year/${yearId}/`,
    body
})

// export const getFieldYears = ({ page, pageSize, filterString = '' }) => request.get({
//     url: `/api/v1/mordovia/field_year/?page=${page}&page_size=${pageSize}${filterString?.length > 0 ? filterString : ''}`,
//     body
// });

export const getFieldYears = filter => request.get({
    url: urlPath('/api/v1/mordovia/field_year/', filter)
});

export const calculatePestProbability = ({ id, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${id}/pest_probability/`),
    body: body
});

export const getPestProbabilityData = ({ id, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${id}/field_forecast/`),
    body
});

export const addGroupCalculate = ({ vega_key, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${vega_key}/calculator/`),
    body
});

export const updateFieldDistribution = ({ vega_key, body }) => request.post({
    url: urlPath(options.apiMordoviaUrl, `${vega_key}/update_distribution/`),
    body
});

export const getFieldPoints = ({ id, filter }) => request.get({
    url: urlPath(options.apiMordoviaPointsUrl, `?filter={"field_year": "${id}"${filter}}`)
});
