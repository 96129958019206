import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

function* removeCalculatorFedSaga({ payload: id }) {
    const [start, success, failure] = getActions(
        constants.removeCalculatorFed.try,
        constants.removeCalculatorFed.success,
        constants.removeCalculatorFed.error
    );
    try {
        yield put(start(id));
        yield call(api.removeCalculatorFed, id);
        const obj = converter.removedCalculatorId2Client(id);
        yield put(success(obj));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsFed.toString(),
            defaultSaga({
                constants: constants.getCalculatorsFed,
                api: api.getCalculatorsFed
            })
        ),
        yield takeLatest(
            constants.getCalculatorFed.toString(),
            defaultSaga({
                constants: constants.getCalculatorFed,
                api: api.getCalculatorFed
            })
        ),
        yield takeLatest(
            constants.addCalculatorFed.toString(),
            defaultSaga({
                constants: constants.addCalculatorFed,
                api: api.addCalculatorFed
            })
        ),
        yield takeLatest(
            constants.setParametersCalculatorFed.toString(),
            defaultSaga({
                constants: constants.setParametersCalculatorFed,
                api: api.setParametersCalculatorFed
            })
        ),
        yield takeLatest(
            constants.closeCalculatorFed.toString(),
            defaultSaga({
                constants: constants.closeCalculatorFed,
                api: api.closeCalculatorFed
            })
        ),
        yield takeLatest(
            constants.removeCalculatorFed.toString(),
            removeCalculatorFedSaga
        )
    ]);
}