import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getNDVIandNDMI.success: {
            const { dataNDMI, dataNDVI } = action.payload;
            return action.payload;
        }

        default:
            return state;
    }
}
