import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import * as actions from './actions';
import converter from './converter';

function* getPesticideSaga({ payload: id }) {
    const [start, success, failure] = getActions(
        constants.getPesticideProductApplicationItem.try,
        constants.getPesticideProductApplicationItem.success,
        constants.getPesticideProductApplicationItem.error
    );
    try {
        yield put(start());
        const data = yield call(api.getPesticideProductApplicationItem, id);
        const {
            plant_protection_product
        } = data;
        yield put(actions.getPesticide(plant_protection_product.id));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getPesticides.toString(),
            defaultSaga({
                constants: constants.getPesticides,
                api: api.getPesticides
            })
        ),
        yield takeLatest(
            constants.getPesticide.toString(),
            defaultSaga({
                constants: constants.getPesticide,
                api: api.getPesticide
            })
        ),
        yield takeLatest(
            constants.getPesticideApplicationArea.toString(),
            defaultSaga({
                constants: constants.getPesticideApplicationArea,
                api: api.getPesticideApplicationArea
            })
        ),
        yield takeLatest(
            constants.getPesticideApplicationAreaItem.toString(),
            defaultSaga({
                constants: constants.getPesticideApplicationAreaItem,
                api: api.getPesticideApplicationAreaItem
            })
        ),
        yield takeLatest(
            constants.getPesticideChemicalClasses.toString(),
            defaultSaga({
                constants: constants.getPesticideChemicalClasses,
                api: api.getPesticideChemicalClasses
            })
        ),
        yield takeLatest(
            constants.getPesticideChemicalClassesItem.toString(),
            defaultSaga({
                constants: constants.getPesticideChemicalClassesItem,
                api: api.getPesticideChemicalClassesItem
            })
        ),
        yield takeLatest(
            constants.getPesticideHarmfulObject.toString(),
            defaultSaga({
                constants: constants.getPesticideHarmfulObject,
                api: api.getPesticideHarmfulObject
            })
        ),
        yield takeLatest(
            constants.getPesticideHarmfulObjectItem.toString(),
            defaultSaga({
                constants: constants.getPesticideHarmfulObjectItem,
                api: api.getPesticideHarmfulObjectItem
            })
        ),
        yield takeLatest(
            constants.getPesticideHazardClass.toString(),
            defaultSaga({
                constants: constants.getPesticideHazardClass,
                api: api.getPesticideHazardClass
            })
        ),
        yield takeLatest(
            constants.getPesticideHazardClassItem.toString(),
            defaultSaga({
                constants: constants.getPesticideHazardClassItem,
                api: api.getPesticideHazardClassItem
            })
        ),
        yield takeLatest(
            constants.getPesticidePlaceOfProduction.toString(),
            defaultSaga({
                constants: constants.getPesticidePlaceOfProduction,
                api: api.getPesticidePlaceOfProduction
            })
        ),
        yield takeLatest(
            constants.getPesticidePlaceOfProductionItem.toString(),
            defaultSaga({
                constants: constants.getPesticidePlaceOfProductionItem,
                api: api.getPesticidePlaceOfProductionItem
            })
        ),
        yield takeLatest(
            constants.getPesticideGroup.toString(),
            defaultSaga({
                constants: constants.getPesticideGroup,
                api: api.getPesticideGroup
            })
        ),
        yield takeLatest(
            constants.getPesticideGroupItem.toString(),
            defaultSaga({
                constants: constants.getPesticideGroupItem,
                api: api.getPesticideGroupItem
            })
        ),
        yield takeLatest(
            constants.getPesticideMainDrug.toString(),
            defaultSaga({
                constants: constants.getPesticideMainDrug,
                api: api.getPesticideMainDrug
            })
        ),
        yield takeLatest(
            constants.getPesticideMainDrugItem.toString(),
            defaultSaga({
                constants: constants.getPesticideMainDrugItem,
                api: api.getPesticideMainDrugItem
            })
        ),
        yield takeLatest(
            constants.getPesticideProductApplication.toString(),
            defaultSaga({
                constants: constants.getPesticideProductApplication,
                api: api.getPesticideProductApplication
            })
        ),
        yield takeLatest(
            constants.getPesticideProductApplicationItem.toString(),
            getPesticideSaga
        ),
        yield takeLatest(
            constants.getPesticidePreparativeForm.toString(),
            defaultSaga({
                constants: constants.getPesticidePreparativeForm,
                api: api.getPesticidePreparativeForm
            })
        ),
        yield takeLatest(
            constants.getPesticidePreparativeFormItem.toString(),
            defaultSaga({
                constants: constants.getPesticidePreparativeFormItem,
                api: api.getPesticidePreparativeFormItem
            })
        ),
        yield takeLatest(
            constants.getPesticideTreatmentType.toString(),
            defaultSaga({
                constants: constants.getPesticideTreatmentType,
                api: api.getPesticideTreatmentType
            })
        ),
        yield takeLatest(
            constants.getPesticideTreatmentTypeItem.toString(),
            defaultSaga({
                constants: constants.getPesticideTreatmentTypeItem,
                api: api.getPesticideTreatmentTypeItem
            })
        )
    ]);
}