import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = (url, path) => `${url}${path}`;

export const getCalculatorsFluid = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getCalculatorFluid = id => request.get({
    url: urlPath(options.apiUrl, id),
    id
});

export const addCalculatorFluid = body => request.post({
    url: urlPath(options.apiUrl, 'create_calculator/'),
    body
});

export const setParamsCalculatorFluid = body => request.post({
    url: urlPath(options.apiUrl, `${body.id}/set_calculation_parameters/`),
    body: body.values
});

export const setPlantProtectionCalculatorFluid = body => request.post({
    url: urlPath(options.apiUrl, `${body.id}/set_plant_protection/`),
    body: body.values
});

export const removeCalculatorFluid = id => request.remove({
    url: urlPath(options.apiUrl, id)
});

export const closeCalculatorFluid = id => request.post({
    url: urlPath(options.apiUrl, `${id}/close/`)
});

export const getRateNozzleCalculatorFluid = filter => request.get({
    url: urlPath(options.apiRateNozzleUrl, filter)
});

export const getNozzleColorCalculatorFluid = filter => request.get({
    url: urlPath(options.apiNozzleColorUrl, filter)
});

export const getNozzlePressureCalculatorFluid = filter => request.get({
    url: urlPath(options.apiNozzlePressureUrl, filter)
});