import * as constants from './constants';

export const getCalculatorsFluid = payload => ({
    type: constants.getCalculatorsFluid.toString(),
    payload
});

export const getCalculatorFluid = payload => ({
    type: constants.getCalculatorFluid.toString(),
    payload
});

export const addCalculatorFluid = payload => ({
    type: constants.addCalculatorFluid.toString(),
    payload
});

export const setParamsCalculatorFluid = payload => ({
    type: constants.setParamsCalculatorFluid.toString(),
    payload
});

export const setPlantProtectionCalculatorFluid = payload => ({
    type: constants.setPlantProtectionCalculatorFluid.toString(),
    payload
});

export const removeCalculatorFluid = payload => ({
    type: constants.removeCalculatorFluid.toString(),
    payload
});

export const getPlantProtectionProductCalculatorFluid = payload => ({
    type: constants.getPlantProtectionProductCalculatorFluid.toString(),
    payload
});

export const clearCalculatorFluid = payload => ({
    type: constants.clearCalculatorFluid.toString()
});

export const closeCalculatorFluid = payload => ({
    type: constants.closeCalculatorFluid.toString(),
    payload
});

export const getRateNozzleCalculatorFluid = payload => ({
    type: constants.getRateNozzleCalculatorFluid.toString(),
    payload
});

export const getNozzleColorCalculatorFluid = payload => ({
    type: constants.getNozzleColorCalculatorFluid.toString(),
    payload
});

export const getNozzlePressureCalculatorFluid = payload => ({
    type: constants.getNozzlePressureCalculatorFluid.toString(),
    payload
});
