import { getConstant } from '../utils';

export const getCalculatorsFluid = getConstant('GET_CALCULATORS_FLUID');
export const getCalculatorFluid = getConstant('GET_CALCULATOR_FLUID');
export const clearCalculatorFluid = getConstant('CLEAR_CALCULATOR_FLUID');
export const addCalculatorFluid = getConstant('ADD_CALCULATOR_FLUID');
export const setParamsCalculatorFluid = getConstant('SET_PARAMS_CALCULATOR_FLUID');
export const setPlantProtectionCalculatorFluid = getConstant('SET_PLANT_PROTECTION_CALCULATOR_FLUID');
export const removeCalculatorFluid = getConstant('REMOVE_CALCULATOR_FLUID');
export const closeCalculatorFluid = getConstant('CLOSE_CALCULATOR_FLUID');
export const getPlantProtectionProductCalculatorFluid = getConstant('GET_PLANT_PROTECTION_PRODUCT_CALCULATOR_FLUID');


export const getRateNozzleCalculatorFluid = getConstant('GET_RATE_NOZZLE_CALCULATOR_FLUID');
export const getNozzleColorCalculatorFluid = getConstant('GET_NOZZLE_COLOR_CALCULATOR_FLUID');
export const getNozzlePressureCalculatorFluid = getConstant('GET_NOZZLE_PRESSURE_CALCULATOR_FLUID');