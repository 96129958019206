import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Layout,
    Row
} from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from '../../utils/cn';

import {
    PanelLayout, Title
} from '../../components';

import RegistriesTable from './components/registries-table';

import './style.less';
import { deleteProtocolPhyto, getProtocolsPhyto, removeProtoPhyto } from '../../models/calculators-phyto/actions';

const { PanelHeader, PanelContent } = PanelLayout;

const FsmRegistries = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const { t } = useTranslation('fsm registries');

    const [selectedTableItems, setSelectedTableItems] = useState([]);
    const [protocolPage, setProtocolPage] = useState(1);
    const [protocolPageSize, setProtocolPageSize] = useState(10);

    const deleteSelectedProtocols = () => {
        selectedTableItems?.forEach(item => {
            setTimeout(() => {
                dispatch(removeProtoPhyto({
                    id: item?.id
                }));
            }, 150);
        });

        setTimeout(() => {
            dispatch(getProtocolsPhyto({
                page: protocolPage,
                pageSize: protocolPageSize
            }));
        }, 1000);
    };

    return (
        <Layout className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <Row className="controls-row">
                        <Col span={6}>
                            <Link
                                to="/fsm-registry/"
                                onClick={() => {
                                    setTimeout(() => window.location.reload(), 500);
                                }}>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary">
                                    <PlusCircleOutlined />
                                    {t('add protocol')}
                                </Button>
                            </Link>
                        </Col>
                        <Col span={12} />
                        <Col span={6}>
                            <Button onClick={deleteSelectedProtocols} disabled={selectedTableItems?.length < 1} style={{ width: '100%' }} type="secondary">
                                <DeleteOutlined />
                                {t('delete protocol')}
                            </Button>
                        </Col>
                    </Row>
                    <RegistriesTable
                        page={protocolPage}
                        pageSize={protocolPageSize}
                        setPage={setProtocolPage}
                        setPageSize={setProtocolPageSize}
                        selectedItems={selectedTableItems}
                        setSelectedItems={setSelectedTableItems} />
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FsmRegistries;