import request from '../../utils/request';
import options from './options';

const urlNDMI = options.apiNDMIUrl;
const urlNDVI = options.apiNDVIUrl;
const urlSWI = options.apiSWIUrl;
const urlPath = (url, path) => `${url}${path}`;

export const getNDMI = id => request.get({
    url: urlPath(urlNDMI, id)
});

export const getNDVI = id => request.get({
    url: urlPath(urlNDVI, id),
});

export const getSWI = id => request.get({
    url: urlPath(urlSWI, id)
});
