import React, { useEffect, useState } from 'react';
import {
    Col, Row, Select, Table, DatePicker, Tooltip
} from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { tableByAgroPeriod } from '../../../models/pivot-table/selectors';
import { useClassName } from '../../../utils/cn';

const { RangePicker } = DatePicker;

const AgroPeriodReport = () => {
    const data = useSelector(state => tableByAgroPeriod(state)) || [];
    const [filteredData, setFilteredData] = useState([]);
    const { t } = useTranslation('pivot table');
    const cn = useClassName('report');
    const [columns, setColumns] = useState([]);
    const [filteredColumns, setFilteredColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [filteredDataSource, setFilteredDataSource] = useState([]);

    const onPeriodChange = (value, dates) => {
        const filteredData = columns.filter(item => (
            item.key === 'name' || moment(item.key).isBetween(dates[0], dates[1]))) || [];
        setFilteredColumns([...filteredData]);
    };

    const onAgroChange = (value) => {
        if (!value) { setFilteredDataSource([...dataSource]); } else {
            const filteredData = dataSource.filter(item => item.id === Number(value));
            setFilteredDataSource([...filteredData]);
        }
    };

    const checkDate = (date, day) => {
        if (moment(day).isSame()) {
            return 'same';
        }
        if (moment(date).isBefore()) {
            return 'less';
        }
        return 'more';
    };

    useEffect(() => {
        const columns = [
            {
                title: t('agro'),
                dataIndex: 'name',
                key: 'name',
                width: 200,
                fixed: 'left'
            }
        ];
        const dataSource = [];
        const mapColumns = filteredData.periods ? [...filteredData?.periods] : [...data?.periods];
        const mapData = filteredData.events ? [...filteredData?.events] : [...data?.events];
        mapColumns?.sort().forEach(item => {
            columns.push(
                {
                    title: item,
                    dataIndex: item,
                    key: item,
                    width: 100,
                    render: value => value?.map(item => (
                        <Tooltip title={item.culture?.name}
                            color={item.culture?.color}
                            key={item.culture?.color}>
                            <span style={{ color: `${item.culture?.color}` }}
                                className={cn(checkDate(item.date, item.day))}>
                                {item.field_name}&nbsp;
                            </span>
                        </Tooltip>
                    ))
                }
            );
        });
        mapData.forEach(item => {
            const periods = {
                id: item.id,
                name: item.name
            };
            item.periods.forEach(el => {
                periods[el.date] = el.fields;
            });
            dataSource.push(periods);
        });
        setDataSource(dataSource);
        setFilteredDataSource(dataSource);
        setColumns(columns);
        setFilteredColumns(columns);
    }, [data, filteredData]);


    return (
        <div>
            <Row gutter={20} style={{ marginBottom: '30px', marginTop: '30px' }}>
                <Col span={7}>
                    <Select onChange={onAgroChange}
                        placeholder={t('choose agro')}
                        allowClear>
                        {data?.events.map(item => (
                            <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={7}>
                    <RangePicker onChange={onPeriodChange} />
                </Col>
                <Col span={10} className={cn('legend_wrapper')}>
                    <div className={cn('legend')}>
                        <div className={cn('legend_progress')} /><div>{t('agro in progress')}</div>
                    </div>
                    <div className={cn('legend')}>
                        <div className={cn('legend_complete')} /><div>{t('agro complete')}</div>
                    </div>
                    <div className={cn('legend')}>
                        <div className={cn('legend_future')} /><div>{t('agro will done')}</div>
                    </div>
                </Col>
            </Row>
            <Table columns={filteredColumns}
                dataSource={filteredDataSource}
                pagination={false}
                scroll={{ y: 300, x: 1300 }} />
        </div>
    );
};

export default AgroPeriodReport;