import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
    Rate, Row, Col, Icon, Collapse
} from 'antd';

import cn from '../../../utils/cn';
import Button from '../../../components/button';
import '../style.less';
import * as constants from '../../../constants';
import { selectors, actions } from '../../../models';
import { Title } from '../../../components';

const { Panel } = Collapse;
const {
    getCurrentFertilizer,
    getCurrentAverageRate
} = selectors;

const mstp = (state) => ({
    fertilizer: getCurrentFertilizer(state),
    rate: getCurrentAverageRate(state)
});

@withRouter
@connect(mstp, {})
@withTranslation('fertilizer modal')
@cn('fertilizer-detail')
export default class FertilizerPrice extends Component {
    goBack = () => {
        const { history } = this.props;
        history.push('/fertilizers');
    };

    render() {
        const { cn } = this;
        const {
            t, fertilizer,
            onTabChange, rate
        } = this.props;
        if (!fertilizer) return <div />;
        return (

            <div className={cn()}>
                <div className={cn('content')}>
                    <span className={cn('back')} onClick={this.goBack}>
                        {t('back')}
                    </span>
                    <div className={cn('title')}>
                        {fertilizer.name}
                    </div>
                    { /* <div className={cn('rate')}>
                        <Rate disabled allowHalf value={rate} />
                        <div className={cn('rate', 'value')}>{rate.toFixed(1)}</div>
                    </div> */ }
                    <Row gutter={20} style={{ marginTop: '45px' }}>
                        <Col span={6}>
                            {t('registrant')}
                        </Col>
                        <Col span={6}>
                            {fertilizer.registrant}
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginTop: '20px' }}>
                        <Col span={6}>
                            {t('price')}
                        </Col>
                        <Col span={6}>
                            {fertilizer.averagePrice}
                        </Col>
                    </Row>
                </div>
                <Row style={{ marginTop: '20px' }} className={cn('content-button')}>
                    <Col>
                        <Button type="default" disabled>
                            {t('buy')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}
