import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getReviews = filter => request.get({
    url,
    body: filter
});

export const getReview = id => request.get({
    url: urlPath(id),
    id
});

export const addReview = body => request.post({
    url,
    body
});

export const updateReview = body => request.put({
    url: urlPath(`?id=${body.id}`),
    body: body.values
});

export const removeReview = id => request.remove({
    url: urlPath(id)
});

export const getAverageRate = body => request.get({
    url: urlPath('average-rate'),
    body
});
