import * as constants from './constants';

export const getLeafFieldPoints = payload => ({
    type: constants.getLeafFieldPoints.toString(),
    payload
});

export const calculateDiagnostics = payload => ({
    type: constants.calculateDiagnostics.toString(),
    payload
});