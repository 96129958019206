import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.updateProtocolFertilizers.success:
        case constants.updateProtocol.success: {
            return action.payload;
        }


        case constants.updateProtocol.try:
        case constants.updateProtocol.error: {
            return {};
        }

        default:
            return state;
    }
}