import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getCalculatorsPreceding = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getCalculatorsPrecedingItem = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`)
});