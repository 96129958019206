import moment from 'moment';
import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    pesticidesFullInfo2Client = (rawObject = {}) => {
        const {
            content,
            pageable,
            totalElements,
            totalPages,
            last,
            size,
            number,
            sort,
            numberOfElements,
            first,
            empty
        } = rawObject;

        return {
            content,
            empty,
            first,
            last,
            number,
            numberOfElements,
            pageable,
            size,
            sort,
            totalElements,
            totalPages
        };
    };

    removedCalculatorId2Client = id => ({ removedCalculatorId: id });

    pesticidesFilter2Client = (data) => data.sort();
}

export default new Converter();