import * as constants from '../constants';

const initialState = {
    results: [],
    count: 0,
    isLoading: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getFieldYears.success: {
            return { count: action.payload.count, results: action.payload.results, isLoading: false };
        }

        case constants.getFieldYears.try: {
            return { count: 0, results: [], isLoading: true };
        }

        case constants.getFieldYears.error: {
            return initialState;
        }

        default:
            return state;
    }
}
