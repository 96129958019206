import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Table, Input, Row, Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { getFertilizers } from '../../../../models/fertilizers/actions';
import { getFertilizersBody } from '../../../../models/fertilizers/selectors';
import FertilizersTable from '../distribution/fertilizers-table';

const FertilizerModal = ({
    isOpen, setIsOpen, setFertilizerList, fertilizerList
}) => {
    const { t } = useTranslation('mordovia');
    
    return (
        <Modal
            title={t('fertilizer modal title')}
            visible={isOpen}
            width={1000}
            closable
            onCancel={() => setIsOpen(false)}>
            <FertilizersTable
                isOptional={true}
                setFertilizers={setFertilizerList}
                onCancel={() => setIsOpen(false)}
                showInfo={() => {}}
                selectedFertilizers={fertilizerList} />
        </Modal>
    );
};

export default FertilizerModal;