import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { Collapse } from 'antd';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import CalculatorData from './calculator-data';
import CalculatorParamsFluid from './fluid/calculator-params';
import CalculatorPecticide from './calculator-pesticide';
import { selectors, actions } from '../../../models';
import * as constants from '../../../constants';

import CalculatorIcon from '../../../../public/images/calculator/calculator2_stroke.svg';
import ApplicationIcon from '../../../../public/images/calculator/application_stroke.svg';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';


const {
    getCalculatorFluid,
    clearCalculatorFluid
} = actions;
const {
    getCurrentCalculatorFluid
} = selectors;

const { TabPane } = Tabs;
const { PanelHeader, PanelContent } = PanelLayout;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFluid(state)
});
const mdtp = ({
    getCalculatorFluid,
    clearCalculatorFluid
});

const { Panel } = Collapse;

@withRouter
@withTranslation('calculator detail')
@connect(mstp, mdtp)
@cn('calculator-detail')
export default class CalculatorDetail extends Component {
    state = {
        name: false,
        activeKeys: []
    };

    componentDidMount() {
        const { getCalculatorFluid, clearCalculatorFluid, match, calculator } = this.props;
        const { id } = match.params;
        const { activeKeys } = this.state;
        this.setState({ id });
        clearCalculatorFluid();
        const arr = [...activeKeys];
        this.setState({ activeKeys: [...arr] });
        id && getCalculatorFluid(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            calculator, history, match, clearCalculatorFluid
        } = this.props;
        const { id } = match.params;
        const { activeKeys } = this.state;

        if (!id && (prevProps.match.url !== match.url)) {
            clearCalculatorFluid();
            this.setName();
        }

        if (!id && (prevProps.calculator !== calculator) && !!calculator.id) {
            history.push(`/calculator-fluid/${calculator.id}`);
        }
    }

    setName = (name) => {
        this.setState({ name });
    };

    changeActiveKeys = (key) => {
        this.setState({ activeKeys: [...key] });
    };

    render() {
        const { cn } = this;
        const {
            t, calculator, loading, match
        } = this.props;
        const {
            name, activeKeys
        } = this.state;
        const { id } = match.params;

        const isCompleted = calculator.is_done;
        const isSetNorm = calculator.fluid_consumption_rate_for_rod;

        return (
            <PanelLayout loading={loading}>
                <PanelHeader>
                    <Title title={calculator?.name || t('calculator name')} />
                </PanelHeader>
                <PanelContent>
                    <Collapse activeKey={activeKeys}
                        onChange={this.changeActiveKeys}
                        expandIconPosition="right"
                        className={cn('collapse')}>
                        <Panel
                            header={(
                                <b>
                                    <Icon component={ListIcon} />
                                    {t('calculator data')}
                                </b>
                            )}
                            key="1">
                            <CalculatorData
                                id={id}
                                isCompleted={isCompleted}
                                calculator={calculator}
                                setName={this.setName} />
                        </Panel>
                        <Panel
                            disabled={!id}
                            header={(
                                <b>
                                    <Icon component={CalculatorIcon} />
                                    {t('calculator parameters')}
                                </b>
                            )}
                            key="2">
                            <CalculatorParamsFluid
                                id={id}
                                isCompleted={isCompleted}
                                calculator={calculator} />
                        </Panel>
                        <Panel
                            disabled={!isSetNorm}
                            header={(
                                <b>
                                    <Icon component={ApplicationIcon} />
                                    {t('choice pesticide')}
                                </b>
                            )}
                            key="3">
                            <CalculatorPecticide
                                id={id}
                                isCompleted={isCompleted}
                                calculator={calculator} />
                        </Panel>
                    </Collapse>
                </PanelContent>
            </PanelLayout>
        );
    }
}