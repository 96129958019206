import * as constants from './constants';

export const getCalculatorsDepth = payload => ({
    type: constants.getCalculatorsDepth.toString(),
    payload
});

export const getCalculatorsDepthItem = payload => ({
    type: constants.getCalculatorsDepthItem.toString(),
    payload
});