import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const curDistribution = state => state.models[name].data;
export const curDistributions = state => state.models[name].list;
export const isDistributionLoading = state => state.models[name].loading;
export const feedingDistributionResults = state => state.models[name].feedings.feeding_stages;
