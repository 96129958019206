import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table as AntTable } from 'antd';

import cn from '../../utils/cn';
import './style.less';


@cn('table')
export default class Table extends Component {
    render() {
        const { cn } = this;
        const { dataSource, columns, ...rest } = this.props;

        return (
            <div className={cn()}>
                <AntTable
                    dataSource={dataSource}
                    columns={columns}
                    {...rest} />
            </div>
        );
    }
}

Table.propTypes = {
    dataSource: PropTypes.array,
    columns: PropTypes.array
};
Table.defaultProps = {
    dataSource: [],
    columns: []
};