import { combineReducers } from 'redux';

import fertilizer from './fertilizer';
import fertilizers from './fertilizers';
import group from './group';
import mainDrug from './main-drug';
import releaseForm from './release-form';
import subgroup from './subgroup';
import toMainDrug from './to-main-drug';
import toRegistration from './to-registration';
import toSeller from './to-seller';
import type from './type';
import loading from './loading';

export default combineReducers({
    fertilizers,
    fertilizer,
    group,
    mainDrug,
    releaseForm,
    subgroup,
    toMainDrug,
    toRegistration,
    toSeller,
    type,
    loading
});
