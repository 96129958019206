import * as constants from './constants';

export const getCalculatorsPrecedingCategory = payload => ({
    type: constants.getCalculatorsPrecedingCategory.toString(),
    payload
});

export const getCalculatorsPrecedingCategoryItem = payload => ({
    type: constants.getCalculatorsPrecedingCategoryItem.toString(),
    payload
});