import {
    getProfile, login, getProfileInfo, updateProfile
} from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case getProfile.success:
        case updateProfile.success:
        case getProfileInfo.success: {
            return action.payload;
        }
        case getProfile.error:
        case getProfileInfo.error: {
            return initialState;
        }
        default: return state;
    }
}
