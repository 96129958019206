import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import converter from './converter';
import * as actions from './actions';

function* setAnswerSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.setAnswer.try,
        constants.setAnswer.success,
        constants.setAnswer.error
    );
    try {
        const { id } = payload;
        yield put(start());
        const data = yield call(api.setAnswer, payload);
        const { type } = data;
        if (type === 'disease') {
            yield put(actions.getDeterminant(id));
        }
        yield put(actions.getQuestions(id));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* addDeterminantSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addDeterminant.try,
        constants.addDeterminant.success,
        constants.addDeterminant.error
    );
    try {
        yield put(start());
        const { history, values } = payload;
        const data = yield call(api.addDeterminant, values);
        const { id, determinant } = data;
        yield put(actions.getQuestion(id));
        yield put(actions.getQuestions(id));
        yield put(success(data));
        history.push(`/determinant/${determinant.id}/${id}`);
    } catch (error) {
        yield put(failure(error));
    }
}


function* getDeterminantSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getDeterminant.try,
        constants.getDeterminant.success,
        constants.getDeterminant.error
    );
    try {
        yield put(start());
        const data = yield call(api.getDeterminant, payload);
        const { id } = data;
        yield put(actions.getQuestion(id));
        yield put(actions.getQuestions(id));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}


function* resetAnswerSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.resetAnswer.try,
        constants.resetAnswer.success,
        constants.resetAnswer.error
    );
    try {
        const { id } = payload;
        yield put(start());
        const data = yield call(api.resetAnswer, payload);
        yield put(actions.getQuestions(id));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getDeterminant.toString(),
            getDeterminantSaga
        ),
        yield takeLatest(
            constants.addDeterminant.toString(),
            addDeterminantSaga
        ),
        yield takeLatest(
            constants.addSimpleDeterminant.toString(),
            defaultSaga({
                constants: constants.addSimpleDeterminant,
                api: api.addDeterminant
            })
        ),
        yield takeLatest(
            constants.getDeterminants.toString(),
            defaultSaga({
                constants: constants.getDeterminants,
                api: api.getDeterminants
            })
        ),
        yield takeLatest(
            constants.removeDeterminant.toString(),
            defaultSaga({
                constants: constants.removeDeterminant,
                api: api.removeDeterminant
            })
        ),
        yield takeLatest(
            constants.getQuestion.toString(),
            defaultSaga({
                constants: constants.getQuestion,
                api: api.getQuestion
            })
        ),
        yield takeLatest(
            constants.setAnswer.toString(),
            setAnswerSaga
        ),
        yield takeLatest(
            constants.setSimpleAnswer.toString(),
            defaultSaga({
                constants: constants.setSimpleAnswer,
                api: api.setAnswer
            })
        ),
        yield takeLatest(
            constants.resetAnswer.toString(),
            resetAnswerSaga
        ),
        yield takeLatest(
            constants.getQuestions.toString(),
            defaultSaga({
                constants: constants.getQuestions,
                api: api.getQuestions,
                after: converter.questions2Client
            })
        )
    ]);
}