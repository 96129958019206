import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { useClassName } from 'utils/cn';
import './style.less';
import Icon from '@ant-design/icons';

import { useSelector } from 'react-redux';
import ArrowRight from '../../../../../public/images/arrow-right.svg';
import ArrowLeft from '../../../../../public/images/arrow-left.svg';

import { selectors, actions } from '../../../../models';


const { getCurCarouselData } = selectors;

export default ({ slides }) => {
    const cn = useClassName('slides');
    const [curr, setCurr] = useState(0);

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    const { length } = slides;

    const goToNext = () => {
        if (curr === length - 4) return;
        setCurr(curr === length - 1 ? 0 : curr + 1);
    };

    const goToPrev = () => {
        if (curr === 0) return;
        setCurr(curr === 0 ? length - 1 : curr - 1);
    };

    return (
        <div className={cn()}>
            <Row gutter={20} style={{ justifyContent: slides.length === 1 ? 'center' : 'flex-start' }}>
                {slides.map((slide, i) => (
                    <Col span={6} className={(i >= curr && i <= curr + 3) ? `${cn('item')} ${cn('item-active')}` : cn('item')} key={slide.title}>
                        <div className={cn('item__image')}>
                            <img src={slide?.disease?.related_images[0]?.image || '/images/home/card_noimg.svg'} alt={slide?.disease?.name} />
                        </div>
                        <p className={cn('item__text')}>{slide?.disease?.name}</p>
                    </Col>
                ))}
            </Row>
            {
                length >= 4 && (
                    <div className={cn('navigation')}>
                        <div className={curr === 0 ? `${cn('prev')} ${cn('prev-disable')}` : cn('prev')} onClick={goToPrev} />
                        <div className={curr === length - 4 ? `${cn('next')} ${cn('next-disable')}` : cn('next')} onClick={goToNext} />
                    </div>
                )
            }
        </div>
    );
};