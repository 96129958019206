import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const curCalculate = state => state.models[name].data;
export const curCalculates = state => state.models[name].list.results;
export const curCalculatesCount = state => state.models[name].list.count;
export const isCalculateLoading = state => state.models[name].loading;
export const isCalculateCompleted = state => state.models[name].data?.is_done;
