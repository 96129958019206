import * as constants from '../constants';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorFed.success:
        case constants.setParametersCalculatorFed.success:
        case constants.closeCalculatorFed.success:
        case constants.addCalculatorFed.success: {
            return action.payload;
        }

        case constants.clearCalculatorFed.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
