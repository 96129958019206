import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Table, Spin } from 'antd';

import './style.less';

const SelectableTable = ({
    tableData, tableColumns,
    selectedItems, setSelectedItems,
    isBordered = true, tablePage,
    setTablePage, tableTotal = 0,
    tablePageSize, setTablePageSize,
    onChange = () => {},
    tableKey = 'id', tableFooter = null,
    onRowClick = () => {}, isLoading = false,
    tableScroll = { x: 1500 }
}) => {
    const onPaginationChange = (page, pageSize) => {
        if (tablePage !== page) {
            setTablePage(page);
        }
        if (tablePageSize !== pageSize) {
            setTablePageSize(pageSize);
        }
    };

    const getCheckProp = (record) => {
        return {
            disabled: record?.deleted,
        };
    };

    const getSelectedRows = () => [...selectedItems];

    const handleRowSelectionChange = (rowKeys, rowItems) => {
        setSelectedItems(rowItems);
    };

    const rowSelection = {
        getCheckboxProps: getCheckProp,
        getSelectedRows,
        preserveSelectedRowKeys: true,
        hideSelectAll: true,
        onChange: handleRowSelectionChange
    };

    return (
        <Table
            onChange={onChange}
            onRow={onRowClick}
            footer={tableFooter}
            scroll={tableScroll}
            rowKey={tableKey}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection
            }}
            columns={tableColumns}
            pagination={{
                onChange: onPaginationChange,
                current: tablePage,
                pageSize: tablePageSize,
                total: tableTotal,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100']
            }}
            bordered={isBordered}
            dataSource={tableData} />
    );
};

export default SelectableTable;