import request from '../../utils/request';
import options from './options';

const urlPath = (url, path) => `${url}${path}`;

export const getDiseases = filter => request.get({
    url: urlPath(options.apiUrl, filter)
});

export const getDisease = id => request.get({
    url: urlPath(options.apiUrl, `${id}/`),
    id
});

export const getDiseasesCausativeAgent = filter => request.get({
    url: urlPath(options.apiCausativeAgentUrl, filter)
});

export const getDiseasesCulture = filter => request.get({
    url: urlPath(options.apiCulturesUrl, filter)
});

export const getDiseasesDamageArea = filter => request.get({
    url: urlPath(options.apiDamageAreaUrl, filter)
});

export const getDiseasesSubGroup = filter => request.get({
    url: urlPath(options.apiSubGroupUrl, filter)
});

export const getDiseasesGroup = filter => request.get({
    url: urlPath(options.apiGroupUrl, filter)
});
