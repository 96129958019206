import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Row, Col, Select, Icon, Form, InputNumber
} from 'antd';
import { Chart } from '@antv/g2';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import moment from 'moment';
import cn from '../../../utils/cn';
import {
    Title, Button, Tabs, InfoMessage, SaveMessage
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';
import * as constants from '../../../constants';
import { rus2Latin } from '../../../utils/utils';

const {
    setParametersCalculatorFed
} = actions;
const {
    getCurrentCalculatorFed
} = selectors;

const mstp = (state) => ({
    calculator: getCurrentCalculatorFed(state)
});
const mdtp = ({
    setParametersCalculatorFed
});

const { TabPane } = Tabs;
const { Option } = Select;

const REG_EXP = /^[0-9]*[.]?[0-9]+$/;

const elements = ['n', 'p', 'ks', 'kcl', 'ca', 'mg', 'b', 'cu', 'zn', 'mn', 'fe', 'mo', 'co', 'i'];
let chart;

@withRouter
@connect(mstp, mdtp)
@withTranslation('calculator params fed')
@cn('calculator-detail-fed-params')
export default class CalculatorCalcFed extends Component {
    formRef = React.createRef();

    state = {
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {}
    };

    componentDidMount() {
        chart = new Chart({
            container: 'plot',
            autoFit: true,
            height: 500,
            padding: [50, 50, 50, 50]
        });
        this.drawPLOT();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calculator, history, match } = this.props;
        const { id } = match.params;
        if (prevProps.calculator.id !== calculator.id) {
            this.initForm(calculator);
        }
        if (prevProps.calculator.stress_level !== calculator.stress_level) {
            chart.clear();
            this.drawPLOT();
        }
    }

    initForm = (calculator) => {
        const values = {};
        elements.forEach(elem => {
            values[`e_${elem}`] = calculator[`e_${elem}`] || 0;
            values[`h_${elem}`] = calculator[`h_${elem}`] || 0;
        });
        this.formRef.current.setFieldsValue(values);
    };


    drawPLOT = () => {
        const { calculator, t } = this.props;
        const data = [];

        elements.forEach(elem => {
            let type = elem;
            if (elem === 'ks') type = 'k';
            if (elem === 'kcl') type = 's';
            data.push({ type, value: calculator[`h_${elem}`] });
        });

        chart.data(data);
        chart.scale('value', {
            alias: t('percent'),
            nice: true
        });
        chart.axis('type', {
            tickLine: null
        });

        chart.axis('value', {
            title: {
                offset: 30
            }
        });


        chart.tooltip({
            showMarkers: false
        });
        chart.interaction('legend-highlight');

        chart.legend(false);

        chart
            .interval()
            .position('type*value')
            .color('value', (val) => {
                if (val >= 0) {
                    return '#36c361';
                }
                return '#ff5957';
            })
            .label('value', {
                offset: 10,
                style: {
                    fill: '#595959',
                    fontSize: 12
                }
            });

        chart.render();
    }

    closeCalculator = () => {
        const { history } = this.props;
        history.push('/calculators/');
    };

    get getStressGroup() {
        const { t, calculator } = this.props;
        const { stress_level } = calculator;
        if (stress_level < 0) return t('high stress interval');
        if (stress_level >= 0 && stress_level <= 10) return t('stress interval');
        if (stress_level >= 11 && stress_level <= 70) return t('middle stress interval');
        if (stress_level > 70) return t('low stress interval');
        return '';
    }

    get getStressInfo() {
        const { t, calculator } = this.props;
        const { stress_level } = calculator;
        if (stress_level < 0) return t('high stress');
        if (stress_level >= 0 && stress_level <= 10) return t('stress');
        if (stress_level >= 11 && stress_level <= 70) return t('middle stress');
        if (stress_level > 70) return t('low stress');
        return '';
    }

    jsPdfGenerator = () => {
        const { t, calculator } = this.props;
        const canvas = document.querySelector('#plot canvas');
        const canvasImg = canvas.toDataURL('image/png', 1.0);
        const hratio = canvas.height / canvas.width;
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        doc.setFontSize(14);

        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageWidthTable = pageSize.width ? pageSize.width - 80 : pageSize.getWidth() - 80;
        const pageHeightTable = pageSize.height ? pageSize.height - 80 : pageSize.getHeight() - 80;
        const text = doc.splitTextToSize(calculator.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        doc.setFontSize(12);
        doc.text(t('common stress', { value: calculator.stress_level.toFixed(2) || 0 }), 40, finalY + 60);
        doc.setFontSize(12);
        const body = [
            [t('group stress'), t('info stress')],
            [this.getStressGroup, this.getStressInfo]
        ];
        const body1 = [
            [
                '',
                t('K2'),
                t('N'),
                t('P'),
                t('K'),
                t('S'),
                t('K3'),
                t('Ca'),
                t('Mg'),
                t('B'),
                t('Cu')
            ],
            [
                t('chloroplast coeff'),
                calculator.e_k2,
                calculator.e_n,
                calculator.e_p,
                calculator.e_ks,
                calculator.e_kcl,
                calculator.e_k3,
                calculator.e_ca,
                calculator.e_mg,
                calculator.e_b,
                calculator.e_cu
            ],
            [
                t('chloroplast optimum'),
                '',
                calculator.h_n,
                calculator.h_p,
                calculator.h_ks,
                calculator.h_kcl,
                '',
                calculator.h_ca,
                calculator.h_mg,
                calculator.h_b,
                calculator.h_cu
            ]
        ];
        const body2 = [
            [
                '',
                t('K4'),
                t('Zn'),
                t('Mn'),
                t('Fe'),
                t('K5'),
                t('Mo'),
                t('Co'),
                t('I'),
                t('K6'),
                ''
            ],
            [
                t('chloroplast coeff'),
                calculator.e_k4,
                calculator.e_zn,
                calculator.e_mn,
                calculator.e_fe,
                calculator.e_k5,
                calculator.e_mo,
                calculator.e_co,
                calculator.e_i,
                calculator.e_k6,
                ''
            ],
            [
                t('chloroplast optimum'),
                '',
                calculator.h_zn,
                calculator.h_mn,
                calculator.h_fe,
                '',
                calculator.h_mo,
                calculator.h_co,
                calculator.h_i,
                '',
                ''
            ]
        ];
        doc.text(t('calculate stress subtitle'), 40, finalY + 100);
        doc.autoTable({
            startY: finalY + 120,
            styles: {
                fontSize: 11,
                cellWidth: 'wrap'
            },
            columnStyles: {
                0: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 }
            },
            body
        });
        doc.text(t('calculate subtitle'), 40, doc.previousAutoTable.finalY + 60);
        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 80,
            styles: {
                fontSize: 8,
                cellWidth: 'wrap'
            },
            didParseCell(data) {
                if (data.column.index !== 0) {
                    data.cell.styles.halign = 'center';
                    data.cell.styles.valign = 'middle';
                    data.cell.styles.cellPadding = 2;
                    data.cell.styles.font = 'Arial';
                    data.cell.styles.cellWidth = pageWidthTable / 12;
                }
            },
            columnStyles: {
                0: {
                    font: 'Arial',
                    cellPadding: 10,
                    valign: 'middle',
                    cellWidth: pageWidthTable / 6
                },
                1: {
                    fillColor: '#efefef'
                },
                2: {
                    fillColor: calculator.h_n >= 0 ? '#b6e7ca' : '#f5abac'
                },
                3: {
                    fillColor: calculator.h_p >= 0 ? '#b6e7ca' : '#f5abac'
                },
                4: {
                    fillColor: calculator.h_ks >= 0 ? '#b6e7ca' : '#f5abac'
                },
                5: {
                    fillColor: calculator.h_kcl >= 0 ? '#b6e7ca' : '#f5abac'
                },
                6: {
                    fillColor: '#efefef'
                },
                7: {
                    fillColor: calculator.h_ca >= 0 ? '#b6e7ca' : '#f5abac'
                },
                8: {
                    fillColor: calculator.h_mg >= 0 ? '#b6e7ca' : '#f5abac'
                },
                9: {
                    fillColor: calculator.h_b >= 0 ? '#b6e7ca' : '#f5abac'
                },
                10: {
                    fillColor: calculator.h_cu >= 0 ? '#b6e7ca' : '#f5abac'
                }
            },
            body: body1
        });
        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 20,
            styles: {
                fontSize: 8,
                cellWidth: 'wrap'
            },
            didParseCell(data) {
                if (data.column.index !== 0) {
                    data.cell.styles.halign = 'center';
                    data.cell.styles.valign = 'middle';
                    data.cell.styles.cellPadding = 2;
                    data.cell.styles.font = 'Arial';
                    data.cell.styles.cellWidth = pageWidthTable / 12;
                }
            },
            columnStyles: {
                0: {
                    font: 'Arial',
                    cellPadding: 10,
                    valign: 'middle',
                    cellWidth: pageWidthTable / 6
                },
                1: {
                    fillColor: '#efefef'
                },
                2: {
                    fillColor: calculator.h_zn >= 0 ? '#b6e7ca' : '#f5abac'
                },
                3: {
                    fillColor: calculator.h_mn >= 0 ? '#b6e7ca' : '#f5abac'
                },
                4: {
                    fillColor: calculator.h_fe >= 0 ? '#b6e7ca' : '#f5abac'
                },
                5: {
                    fillColor: '#efefef'
                },
                6: {
                    fillColor: calculator.h_mo >= 0 ? '#b6e7ca' : '#f5abac'
                },
                7: {
                    fillColor: calculator.h_co >= 0 ? '#b6e7ca' : '#f5abac'
                },
                8: {
                    fillColor: calculator.h_i >= 0 ? '#b6e7ca' : '#f5abac'
                },
                9: {
                    fillColor: '#efefef'
                },
                10: {
                    cellPadding: 10
                }
            },
            body: body2
        });
        doc.addPage('a4', 'landscape');
        doc.setFontSize(12);
        doc.text(t('plot title'), 40, 40);
        doc.addImage(canvasImg, 'PNG', 40, 80, pageHeightTable, pageHeightTable * hratio);
        const fileName = rus2Latin(calculator.name);
        doc.save(`${fileName}.pdf`);
    };

    render() {
        const { cn } = this;
        const { t, isCompleted, calculator } = this.props;

        return (
            <Form
                className={cn()}
                ref={this.formRef}
                name="calculator-params-fed">
                <div>
                    <div className={cn('title')}>{t('calculate title')}</div>
                    <Row>
                        <Col span={24}>
                            <div className={cn('green')}>
                                {t('common stress', { value: calculator.stress_level || 0 })}
                            </div>
                        </Col>
                    </Row>
                    <div className={cn('subtitle')}>{t('calculate stress subtitle')}</div>
                    <Row className={`${cn('light-gray-col')} ${cn('thin-border')}`}>
                        <Col span={12}>{t('group stress')}</Col>
                        <Col span={12}>{t('info stress')}</Col>
                    </Row>
                    <Row className={cn('thin-border')}>
                        <Col span={12}>{this.getStressGroup}</Col>
                        <Col span={12}>{this.getStressInfo}</Col>
                    </Row>
                    <div className={cn('subtitle')}>{t('calculate subtitle')}</div>
                    <Row className={`${cn('title-line')} ${cn('border')}`}>
                        <Col span={4} />
                        <Col span={2} className={cn('gray-col')}>
                            {t('K2')}
                        </Col>
                        <Col span={2} className={calculator.h_n >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('N')}
                        </Col>
                        <Col span={2} className={calculator.h_p >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('P')}
                        </Col>
                        <Col span={2} className={calculator.h_kcl >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('K')}
                        </Col>
                        <Col span={2} className={calculator.h_ks >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('S')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {t('K3')}
                        </Col>
                        <Col span={2} className={calculator.h_ca >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Ca')}
                        </Col>
                        <Col span={2} className={calculator.h_mg >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Mg')}
                        </Col>
                        <Col span={2} className={calculator.h_b >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('B')}
                        </Col>
                        <Col span={2} className={calculator.h_cu >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Cu')}
                        </Col>
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('chloroplast coeff')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {calculator.e_k2}
                        </Col>
                        <Col span={2} className={calculator.h_n >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_n}
                        </Col>
                        <Col span={2} className={calculator.h_p >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_p}
                        </Col>
                        <Col span={2} className={calculator.h_ks >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_ks}
                        </Col>
                        <Col span={2} className={calculator.h_kcl >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_kcl}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {calculator.e_k3}
                        </Col>
                        <Col span={2} className={calculator.h_ca >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_ca}
                        </Col>
                        <Col span={2} className={calculator.h_mg >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_mg}
                        </Col>
                        <Col span={2} className={calculator.h_b >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_b}
                        </Col>
                        <Col span={2} className={calculator.h_cu >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_cu}
                        </Col>
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('chloroplast optimum')}
                        </Col>
                        <Col span={2} className={cn('gray-col')} />
                        <Col span={2} className={calculator.h_n >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_n}
                        </Col>
                        <Col span={2} className={calculator.h_p >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_p}
                        </Col>
                        <Col span={2} className={calculator.h_ks >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_ks}
                        </Col>
                        <Col span={2} className={calculator.h_kcl >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_kcl}
                        </Col>
                        <Col span={2} className={cn('gray-col')} />
                        <Col span={2} className={calculator.h_ca >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_ca}
                        </Col>
                        <Col span={2} className={calculator.h_mg >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_mg}
                        </Col>
                        <Col span={2} className={calculator.h_b >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_b}
                        </Col>
                        <Col span={2} className={calculator.h_cu >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_cu}
                        </Col>
                    </Row>
                    <Row className={`${cn('title-line')} ${cn('border')}`}>
                        <Col span={4} />
                        <Col span={2} className={cn('gray-col')}>
                            {t('K4')}
                        </Col>
                        <Col span={2} className={calculator.h_zn >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Zn')}
                        </Col>
                        <Col span={2} className={calculator.h_mn >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Mn')}
                        </Col>
                        <Col span={2} className={calculator.h_fe >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Fe')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {t('K5')}
                        </Col>
                        <Col span={2} className={calculator.h_mo >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Mo')}
                        </Col>
                        <Col span={2} className={calculator.h_co >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('Co')}
                        </Col>
                        <Col span={2} className={calculator.h_i >= 0 ? cn('green-col') : cn('red-col')}>
                            {t('I')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {t('K6')}
                        </Col>
                        <Col span={2} />
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('chloroplast coeff')}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {calculator.e_k4}
                        </Col>
                        <Col span={2} className={calculator.h_zn >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_zn}
                        </Col>
                        <Col span={2} className={calculator.h_mn >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_mn}
                        </Col>
                        <Col span={2} className={calculator.h_fe >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_fe}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {calculator.e_k5}
                        </Col>
                        <Col span={2} className={calculator.h_mo >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_mo}
                        </Col>
                        <Col span={2} className={calculator.h_co >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_co}
                        </Col>
                        <Col span={2} className={calculator.h_i >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.e_i}
                        </Col>
                        <Col span={2} className={cn('gray-col')}>
                            {calculator.e_k6}
                        </Col>
                        <Col span={2} />
                    </Row>
                    <Row className={cn('border')}>
                        <Col span={4} className={cn('title-col')}>
                            {t('chloroplast optimum')}
                        </Col>
                        <Col span={2} className={cn('gray-col')} />
                        <Col span={2} className={calculator.h_zn >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_zn}
                        </Col>
                        <Col span={2} className={calculator.h_mn >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_mn}
                        </Col>
                        <Col span={2} className={calculator.h_fe >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_fe}
                        </Col>
                        <Col span={2} className={cn('gray-col')} />
                        <Col span={2} className={calculator.h_mo >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_mo}
                        </Col>
                        <Col span={2} className={calculator.h_co >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_co}
                        </Col>
                        <Col span={2} className={calculator.h_i >= 0 ? cn('green-col') : cn('red-col')}>
                            {calculator.h_i}
                        </Col>
                        <Col span={2} className={cn('gray-col')} />
                        <Col span={2} />
                    </Row>
                    <div className={cn('subtitle')}>{t('plot title')}</div>
                    <div id="plot" />
                </div>
                <Row className={cn('button')}>
                    <Col>
                        { /* <Button
                            type="primary"
                            disabled={isCompleted}
                            onClick={this.saveCurValues}
                            htmlType="submit"
                            size="large">
                            {t('save calculate')}
                        </Button> */ }
                        <Button
                            type="default"
                            disabled={isCompleted}
                            onClick={this.jsPdfGenerator}
                            size="large">
                            {t('generate pdf')}
                        </Button>
                        { /* <Button
                            type="default"
                            disabled={isCompleted}
                            size="large">
                            {t('go to')}
                        </Button> */ }
                    </Col>
                </Row>
            </Form>


        );
    }
}