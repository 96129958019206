import React, { Component } from 'react';
import Slider from 'react-slick';
import { Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import cn from '../../utils/cn';
import './style.less';

@cn('images')
export default class ImagesSlider extends Component {
    state = {
        showModal: false,
        image: ''
    }

    onClick = (image) => {
        this.setState({ showModal: true, image });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    render() {
        const { cn } = this;
        const { showModal, image } = this.state;
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0
        };
        const { images, onRemove, isRemove = false } = this.props;
        return (
            <>
                <div className={cn()}>
                    {images.map((image, i) => (
                        <div key={image + i} onClick={() => this.onClick(image)} className={cn('item')}>
                            <img src={image} alt={image} />
                        </div>
                    ))}
                </div>
                <Modal centered
                    closable
                    destroyOnClose
                    visible={showModal}
                    footer={null}
                    width={900}
                    onCancel={this.closeModal}
                    onHide={this.closeModal}>
                    <div className={cn('modal')}>
                        <img src={image} alt={image} />
                        {isRemove && (
                            <div className={cn('remove')}>
                                <DeleteOutlined onClick={() => onRemove(image)} />
                            </div>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}