import * as constants from '../constants';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getDistribution.try:
        case constants.addDistribution.try:
        case constants.updateFeedingDistribution.try:
        case constants.updateDistribution.try: {
            return true;
        }

        case constants.getDistribution.success:
        case constants.addDistribution.success:
        case constants.updateDistribution.success:
        case constants.updateFeedingDistribution.success:
        case constants.getDistribution.error:
        case constants.addDistribution.error:
        case constants.updateDistribution.error: {
            return false;
        }

        default:
            return state;
    }
}