import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from '@ant-design/icons';
import { Radio, Rate } from 'antd';
import { Table, TableFooter, Tooltip } from '../../../../components';
import cn from '../../../../utils/cn';
import { regions } from '../../../../regions';

import * as constants from '../../../../constants';
import ListLine from '../../../../../public/images/calculator/list_line.svg';
import Pagination from '../../../../components/pagination';

const ROW_DIVIDER = 10;

@withRouter
@withTranslation('fertilizer review')
@cn('fertilizer-reviews-table')
export default class ReviewsTable extends Component {
    state = {
        value: undefined,
        dataSize: 1,
        dataSource: []
    };

    componentDidMount() {
        this.setDataSource();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { dataSource } = this.props;
        if (prevProps.dataSource !== dataSource) {
            this.setDataSource();
        }
    }


    get requestsColumns() {
        const { cn } = this;
        const { t, showInfo } = this.props;

        return [
            {
                title: t('rate'),
                dataIndex: 'rate',
                key: 'rate',
                className: cn('rate'),
                render: (value) => (
                    <Rate value={value} disabled />
                )

            },
            {
                title: t('user name'),
                dataIndex: 'name',
                key: 'name',
                className: cn('name'),
                render: () => (t('admin'))
            },
            {
                title: t('year'),
                dataIndex: 'year',
                key: 'year',
                className: cn('year')
            },
            {
                title: t('region'),
                dataIndex: 'region',
                key: 'region',
                className: cn('region'),
                render: (value) => (
                    regions.find((item) => item.code === Number(value)).name
                )
            },
            {
                title: t('create date'),
                dataIndex: 'createDate',
                key: 'createDate',
                className: cn('createDate'),
                render: (text) => moment(text).format('DD.MM.YYYY')
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: cn('action'),
                render: (text, record) => (
                    <div className={cn('button')}>
                        <Icon component={ListLine} onClick={() => showInfo(record)} />
                    </div>
                )
            }
        ];
    }

    setDataSource = () => {
        const {
            dataSource, dataSize
        } = this.props;
        this.setState({ dataSource, dataSize });
    };

    render() {
        const { cn } = this;
        const {
            history,
            onCurrentPageChange,
            onPageSizeChange,
            filterItems,
            pageSize,
            pageNum
        } = this.props;
        const {
            dataSource,
            dataSize
        } = this.state;

        return (
            <div className={cn()}>
                <Table
                    dataSource={dataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
                {dataSize > 1 && (
                    <TableFooter
                        style={{ marginBottom: '20px' }}
                        filterItems={filterItems}
                        showSizeChanger={false}
                        onSelect={onPageSizeChange}
                        onChange={onCurrentPageChange}
                        pageSize={pageSize}
                        current={pageNum + 1}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                )}
            </div>
        );
    }
}

ReviewsTable.propTypes = {
    dataSource: PropTypes.array
};
ReviewsTable.defaultProps = {
    dataSource: []
};