import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Row, Input, Form, Button, Upload, Table, Image, Popover, InputNumber
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useClassName } from '../../../utils/cn';
import { TextArea } from '../../../components';
import {
    getMycologicalRegistry, updateFileDescription,
    updateMycologicalRegistry, uploadRegistryFile
} from '../../../models/mycological-registry/actions';
import { curNewFilename } from '../../../models/mycological-registry/selectors';
import moment from 'moment';
import { toFixed } from '../../../utils/utils';

const MUSHROOM_TYPES = [
    { value: 'bipolaris', title: 'bipolaris' },
    { value: 'pythium', title: 'pythium' },
    { value: 'alternaria', title: 'alternaria' },
    { value: 'cladosporium', title: 'cladosporium' },
    { value: 'blumeria_graminis', title: 'blumeria_graminis' },
    { value: 'fusarium', title: 'fusarium' },
    { value: 'phoma', title: 'phoma' },
    { value: 'murogenella', title: 'murogenella' }
];

export const Results = ({
    registry, t, form,
    setNoLoader, onFormFinish, isBlocked
}) => {
    const dispatch = useDispatch();
    const cn = useClassName('calculator-detail-params');
    const match = useRouteMatch();
    const { params: { id } } = match;

    const removeUploadedFile = (val1) => {
        const formValues = form.getFieldsValue();
        formValues.pictures2 = registry?.pictures2?.filter(x => x.id !== val1.id).map(y => y.id);
        onFormFinish(formValues);
    };

    const valueFormatter = (value) => {
        if (!value) {
            return 0;
        }
        const numberVal = parseFloat(value);
        if (numberVal < 0.1) {
            let offsetIterator = 1;
            let numberCopy = numberVal;
            while (numberCopy < 0) {
                offsetIterator += 1;
                numberCopy *= 10;
            }
            return toFixed(numberVal, 1);
        } else {
            return toFixed(numberVal, 1);
        }
    };

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `2/File №${registry?.pictures2?.length + 1}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    const onDataInputChange = (evt) => {
        const inputValue = evt?.target?.value;

        if (id && (inputValue?.length > 0 || inputValue > 0)) {
            setNoLoader(true);
            form.submit();
            setTimeout(() => setNoLoader(false), 2500);
        }
    };

    const clearFormFields = () => {
        form.setFieldsValue({
            ...MUSHROOM_TYPES.reduce((acc, item) => {
                acc[item.value] = undefined;
                return acc;
            }, {})
        });
    };

    return (
        <div className={cn('row-table')}>
            <Row className={cn('row-head')}>
                <Col span={4} className={cn('row-table__label')}>
                    {t('mushroom genus')}
                </Col>
                <Col span={4} className={cn('row-table__label')}>
                    {t('mushroom count')}
                </Col>
                <Col span={8} className={cn('row-table__label')}>
                    {t('soil group')}
                </Col>
                <Col span={8} className={cn('row-table__label')}>
                    {t('characteristics')}
                </Col>
            </Row>
            {
                MUSHROOM_TYPES.map(item => (
                    <Row className={cn('row-table__withborder')}>
                        <Col span={4} className={cn('row-table__label')}>
                            <span style={{ color: 'red' }}>*</span>{t(item.title)}
                        </Col>
                        <Col span={4} className={cn('row-table__label')}>
                            <Form.Item name={item.value} rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber disabled={isBlocked} decimalSeparator="," onBlur={onDataInputChange} style={{ marginTop: '25px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} className={cn('row-table__label')}>
                            {registry[item.value]
                                && registry[item.value].soil_type}
                        </Col>
                        <Col span={8} className={cn('row-table__label')}>
                            {registry[item.value]
                            && registry[item.value].characteristic}
                        </Col>
                    </Row>
                ))
            }

            <Row>
                <Col span={4}>
                    <Button disabled={isBlocked} type="secondary" onClick={clearFormFields}>
                        {t('clear')}
                    </Button>
                </Col>
            </Row>

            <Row style={{ backgroundColor: 'white', border: 'none' }}>
                <p>{t('comment')}</p>
                <Form.Item name="comment2">
                    <TextArea
                        disabled={isBlocked}
                        maxLength={99999}
                        rows={4} />
                </Form.Item>
            </Row>

            {registry?.id && (
                <Row>
                    <Col>
                        <p>{t('upload file')}</p>
                        <Upload {...uploadProps}>
                            <Button disabled={isBlocked} icon={<UploadOutlined />}>
                                {t('press to upload')}
                            </Button>
                        </Upload>
                    </Col>
                </Row>
            )}

            {registry?.pictures2?.length > 0 && (
                <Row style={{ backgroundColor: 'white', border: 'none', marginTop: '15px' }}>
                    <Col span={24}>
                        <p>{t('uploaded files')}</p>
                        <Table
                            dataSource={registry?.pictures2}
                            pagination={false}
                            columns={[
                                {
                                    dataIndex: 'picture_name',
                                    title: t('pic table filename'),
                                    key: 'picture_name',
                                    render: (val, rec) => {
                                        const [isImgVisible, setIsImgVisible] = useState(false);
                                        return (
                                            <>
                                                <Image
                                                    style={{
                                                        width: '100%',
                                                        maxHeight: '100px'
                                                    }}
                                                    src={rec?.picture_file}
                                                    preview={{
                                                        isImgVisible,
                                                        src: rec?.picture_file,
                                                        onVisibleChange: (value) => {
                                                            setIsImgVisible(value);
                                                        }
                                                    }} />
                                                <div>
                                                    {val}
                                                </div>
                                            </>
                                        );
                                    }
                                },
                                {
                                    dataIndex: 'uploaded',
                                    title: t('pic table date'),
                                    key: 'uploaded',
                                    render: (value) => {
                                        const dateStr = value?.split('T')[0];
                                        const dateArray = dateStr ? dateStr.split('-') : [];
                                        return dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : value;
                                    }
                                },
                                {
                                    dataIndex: 'picture_desc',
                                    title: t('pic table description'),
                                    key: 'picture_desc',
                                    render: (val, rec) => {
                                        const [isRenameOpen, setIsRenameOpen] = useState(false);
                                        const [photoComment, setPhotoComment] = useState(val);

                                        const newFileName = useSelector(state => curNewFilename(state));

                                        useEffect(() => {
                                            if (newFileName?.id === rec.id && newFileName?.picture_desc !== photoComment && id) {
                                                dispatch(getMycologicalRegistry(id));
                                            }
                                        }, [newFileName]);

                                        const hide = () => {
                                            setIsRenameOpen(false);
                                            setPhotoComment(val);
                                        };
                                        const handleOpenChange = (newOpen) => {
                                            setIsRenameOpen(newOpen);
                                        };
                                        const saveNewComment = () => {
                                            if (photoComment !== val) {
                                                dispatch(updateFileDescription({
                                                    pictureId: rec.id,
                                                    values: {
                                                        picture_desc: photoComment
                                                    }
                                                }));
                                            }
                                        };

                                        return (
                                            <Popover
                                                content={(
                                                    <>
                                                        <TextArea
                                                            style={{ width: '350px', height: '150px' }}
                                                            maxLength={99999}
                                                            onChange={(evt) => setPhotoComment(evt?.target?.value)}
                                                            defaultValue={photoComment} />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-around'
                                                            }}>
                                                            <a onClick={hide}>
                                                                Отмена
                                                            </a>
                                                            <a onClick={() => {
                                                                saveNewComment();
                                                                hide();
                                                            }}>
                                                                {t('save')}
                                                            </a>
                                                        </div>
                                                    </>
                                                )}
                                                trigger="click"
                                                visible={isRenameOpen}
                                                onVisibleChange={handleOpenChange}>
                                                {val}
                                            </Popover>
                                        );
                                    }
                                },
                                {
                                    key: 'actions',
                                    dataIndex: 'actions',
                                    render: (value, record) => !isBlocked ? (
                                        <DeleteOutlined
                                            onClick={() => removeUploadedFile(record)}
                                            style={{ cursor: 'pointer' }} />
                                    ) : (<></>)
                                }
                            ]} />
                    </Col>
                </Row>
            )}
        </div>
    );
};