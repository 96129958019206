import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import { Col, Collapse, Row } from 'antd';

import { useDispatch } from 'react-redux';
import {
    Title, PanelLayout
} from '../../../components';

import Map from './map-wrapper';
import FieldInfo from './field';
import FieldReport from './field-report';
import CultureReport from './culture-report';
import AgroFieldsReport from './agro-fields-report';
import AgroPeriodReport from './agro-period-report';

import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';

import { actions } from '../../../models';

const {
    getPivotTableMapData,
    getPivotTableByField,
    getPivotTableByFieldTotal,
    getPivotTableByCulture,
    getPivotByAgroTechicalPeriod,
    getPivotByAgroTechicalFields
} = actions;
const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;


export default () => {
    const cn = useClassName('calculators');
    const { t } = useTranslation('pivot table');
    const dispatch = useDispatch();
    const [curField, setCurField] = useState(undefined);

    useEffect(() => {
        dispatch(getPivotTableMapData());
        dispatch(getPivotTableByField());
        dispatch(getPivotTableByFieldTotal());
        dispatch(getPivotTableByCulture());
        dispatch(getPivotByAgroTechicalPeriod());
        // dispatch(getPivotByAgroTechicalFields());
    }, []);

    return (
        <PanelLayout>
            <PanelHeader>
                <Title title={t('title')} />
            </PanelHeader>
            <PanelContent className={cn()}>
                <Row gutter={20}>
                    <Col span={12}>
                        <div id="map-pivot">
                            <Map curField={curField} />
                        </div>
                    </Col>
                    <Col span={12}>
                        <FieldInfo setCurField={setCurField} curField={curField} />
                    </Col>
                </Row>
                <Collapse
                    expandIconPosition="left"
                    className={cn('collapse')}>
                    <Panel header={<><Icon component={ListIcon} />{t('title by field')}</>}
                        key="field">
                        <FieldReport setCurField={setCurField} curField={curField} />
                    </Panel>
                    <Panel header={<><Icon component={ListIcon} />{t('title by culture')}</>}
                        key="culture">
                        <CultureReport />
                    </Panel>
                    <Panel header={<><Icon component={ListIcon} />{t('title by agro period')}</>}
                        key="agro_period">
                        <AgroPeriodReport />
                    </Panel>
                    {/* <Panel header={<><Icon component={ListIcon} />{t('title by agro fields')}</>}
                        key="agro_fields">
                        <AgroFieldsReport />
                    </Panel> */}
                </Collapse>
            </PanelContent>
        </PanelLayout>
    );
};