import React, { useEffect, useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';

import { useClassName } from 'utils/cn';

import { Button, Collapse, Form } from 'antd';
import CalculatorName from './calculator-name';
import CalculatorData from './calculator-data';
import SeedQualityIndicator from './seed-quality-indicator';
import ExternalContamination from './external-contamination';
import InternalContamination from './internal-contamination';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';

import '../style.less';
import {
    getCulturesPhyto, getDrugsPhyto, getFertilizersPhyto,
    getReproductionsPhyto,
    getVarietiesPhyto
} from '../../../models/calculators-phyto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../models/account/selectors';
import LaborantQualityIndicator from '../../../pages/fsm-registry/components/laborant-quality-indicator';
import { drugsPhyto } from '../../../models/calculators-phyto/selectors';

const { Panel } = Collapse;

const CalculatorDetails = ({
    form, detailsNextStepDisabled,
    internalPathogenStage, setInternalPathogenStage,
    internalPathogensList, externalPathogensList,
    externalImages, internalImages, calculatorId
}) => {
    const cn = useClassName('calculator-detail');
    const { t } = useTranslation('phyto calculator');
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => getUser(state));
    const fertilizersList = useSelector(state => drugsPhyto(state));

    const [sortList, setSortList] = useState([]);
    const [cultureList, setCultureList] = useState([]);

    const [seedsMassOne, setSeedsMassOne] = useState(0);
    const [seedsMassTwo, setSeedsMassTwo] = useState(0);
    const [seedsNumOne, setSeedsNumOne] = useState(500);
    const [seedsNumTwo, setSeedsNumTwo] = useState(500);

    const [fertilizersFullList, setFertilizersFullList] = useState([]);
    const [fertilizersPage, setFertilizersPage] = useState(1);

    useEffect(() => {
        fertilizersPage && fertilizersPage > 0 && dispatch(getDrugsPhyto({ page: fertilizersPage }));
    }, [fertilizersPage]);
    useEffect(() => {
        const dataPortion = fertilizersList?.results?.length > 0 ? fertilizersList?.results : [];

        if (dataPortion?.length > 0) {
            const addableFertilizers = [];
            dataPortion?.forEach((fertilizerData) => {
                const founderEl = fertilizersFullList?.filter(x => x?.name === fertilizerData?.name);
                if (!founderEl[0]) {
                    addableFertilizers.push(fertilizerData);
                }
            });
            setFertilizersFullList(addableFertilizers);
        }

        if (fertilizersList?.next?.length > 0) {
            setFertilizersPage(fertilizersPage + 1);
            // setTimeout(() => {
            //     setFertilizersPage(fertilizersPage + 1);
            // }, 300);
        }
    }, [fertilizersList]);

    const [activeTabs, setActiveTabs] = useState(match?.params?.id > 0 ? [
        'calculator_data',
        'calculator_name',
        'calculator_quality',
        // 'calculator_external',
        // 'calculator_internal'
    ] : [
        'calculator_name'
    ]);

    const isLaborant = () => currentUser?.groups?.includes('Laborant');

    const calculatorNameMemo = useMemo(() => {
        const formVals = form?.getFieldsValue();
        return formVals?.name;
    }, [form?.getFieldsValue()]);

    const confirmCalculatorName = () => {
        calculatorNameMemo?.length > 0 && setActiveTabs(['calculator_data']);
    };

    const panelHeader = (headerName) => (
        <div className="collapse-header">
            <b>
                <Icon component={ListIcon} />
                {t(`panel header ${headerName}`)}
            </b>
        </div>
    );

    useEffect(() => {
        if (match?.params?.id) {
            setActiveTabs([
                'calculator_data',
                'calculator_name',
                'calculator_quality',
                'calculator_external',
                'calculator_internal'
            ]);
        }
    }, [match])
    

    return (
        <Collapse
            onChange={setActiveTabs}
            bordered={false}
            activeKey={activeTabs}
            defaultActiveKey={activeTabs}
            expandIconPosition="right"
            className={cn('collapse')}>
            <Panel
                header={panelHeader('name')}
                key="calculator_name">
                <CalculatorName confirmName={confirmCalculatorName} />
            </Panel>
            <Panel
                disabled={!calculatorNameMemo?.length > 0}
                header={panelHeader('data')}
                key="calculator_data">
                <CalculatorData
                    fertilizersFullList={fertilizersFullList}
                    seedsMass={((seedsMassOne + seedsMassTwo) * 1000) / (seedsNumOne + seedsNumTwo)}
                    setCultures={setCultureList}
                    setSorts={setSortList}
                    buttonDisabled={detailsNextStepDisabled}
                    form={form} />
            </Panel>
            <Panel
                // disabled={!match?.params?.id > 0}
                header={panelHeader('quality')}
                key="calculator_quality">
                {isLaborant() ? (
                    <LaborantQualityIndicator
                        setSeedsMassOne={setSeedsMassOne}
                        setSeedsMassTwo={setSeedsMassTwo}
                        seedsMassOne={seedsMassOne}
                        seedsNumOne={seedsNumOne}
                        seedsMassTwo={seedsMassTwo}
                        seedsNumTwo={seedsNumTwo}
                        culturesList={cultureList}
                        varietiesList={sortList}
                        form={form} />
                ) : (
                    <SeedQualityIndicator
                        calculatorId={calculatorId}
                        culturesList={cultureList}
                        varietiesList={sortList}
                        form={form} />
                )}
            </Panel>
            <Panel
                disabled={!(match?.params?.id > 0)}
                header={panelHeader('external')}
                key="calculator_external">
                <ExternalContamination
                    fertilizersFullList={fertilizersFullList}
                    form={form}
                    activeTabs={activeTabs}
                    setActiveTabs={setActiveTabs}
                    pathogens={externalPathogensList}
                    images={externalImages}
                    culturesList={cultureList}
                    calculatorId={calculatorId} />
            </Panel>
            <Panel
                disabled={!(match?.params?.id > 0)}
                header={panelHeader('internal')}
                key="calculator_internal">
                <InternalContamination
                    fertilizersFullList={fertilizersFullList}
                    form={form}
                    activeTabs={activeTabs}
                    setActiveTabs={setActiveTabs}
                    internalPathogenStage={internalPathogenStage}
                    setInternalPathogenStage={setInternalPathogenStage}
                    pathogens={internalPathogensList}
                    images={internalImages}
                    culturesList={cultureList}
                    calculatorId={calculatorId} />
            </Panel>
        </Collapse>
    );
};

export default CalculatorDetails;
