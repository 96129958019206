import * as constants from './constants';

export const getSellers = payload => ({
    type: constants.getSellers.toString(),
    payload
});

export const getSeller = payload => ({
    type: constants.getSeller.toString(),
    payload
});
