export const PHASES = {
    Посев: 'Sowing',
    Всходы: 'Sprouts',
    Кущение: 'Tiller',
    'Выход в трубку': 'Exit to the phone',
    Колошение: 'Earing',
    Цветение: 'Bloom',
    'Молочная спелость': 'Milky ripeness',
    'Восковая спелость': 'Wax ripeness',
    'Полная спелость': 'Full Ripeness',
    'Выметывание метелки': 'Brooming',
    '5-6 листьев': '5-6 leaves',
    '9 лист/трубкование': '9 leaf/piping',
    'Трубкование до 9 узла': 'Tubing up to 9 knots',
    'Молочно-восковая спелость': 'Milky Wax Ripeness',
    'Возобновление кущения': 'Resumption of tillering',
    'Кущение после зимы': 'Tillering after winter',
    'Первая пара настоящих листьев': 'First Pair of True Leaves',
    'Розетка (первичная)': 'Outlet (Primary)',
    'Возобновление вегетации (вторичная розетка)': 'Vegetation Resuming (Secondary Rosette)',
    'Начало стеблевания (ветвления)': 'The Beginning of the Stem (Branch)',
    'Образование соцветий': 'Formation of inflorescences',
    'Начало бутонизации': 'The Beginning of Budding',
    'Образование первых стручков': 'Formation of the first pods'
};

export const PROBABILITY = {
    'Развитие невозможно': 'impossible',
    'Низкая вероятность возникновения': 'low_chance',
    'Риск начала заражения': 'risk_infection',
    'Риск развития эпифитотии': 'risk_epiphytosis',
    'Недостаточно данных': 'no_data',
    'Экспертные данные не найдены': 'data_not_found',
    'no data': 'no_data'
};