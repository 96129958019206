import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getCultures = (filter = '') => request.get({
    url: urlPath(filter)
});

export const getCulture = id => request.get({
    url: urlPath(`${id}/`)
});