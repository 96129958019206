import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getInterpretation = id => request.get({
    url: urlPath(id),
    id
});

export const getInterpretations = () => request.get({
    url
});

export const addInterpretation = body => request.post({
    url: urlPath('create_interpretation/'),
    body
});

export const updateInterpretation = body => request.post({
    url: urlPath(`${body.id}/update_interpretation/`),
    body: body.values
});
