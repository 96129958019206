import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorsSoilType.toString(),
            defaultSaga({
                constants: constants.getCalculatorsSoilType,
                api: api.getCalculatorsSoilType
            })
        ),
        yield takeLatest(
            constants.getCalculatorsSoilTypeItem.toString(),
            defaultSaga({
                constants: constants.getCalculatorsSoilTypeItem,
                api: api.getCalculatorsSoilTypeItem
            })
        )
    ]);
}